/// <reference path="../../../_app.ts" />

namespace app.functionality.common.session {
  import SessionService = app.functionality.common.session.SessionService;
  // import Member = app.model.Member;

  export class LoginController implements angular.IController {
    $onInit() {}
    public languages = ["NL", "FR", "EN"];
    public password: string;
    public username: string;
    public badAuth = false;
    public btnBlocked = false;
    public serverError = false;
    public resetPwdMail: string;
    public resetPwdMailSentTo: string;
    public versionNumber: string;
    public resetPwdSuccess: boolean;
    public resetPwdError: boolean;

    public expiredUser: boolean;

    public newPasswordSentSuccess: boolean;
    public newPasswordSentError: boolean;

    public logoSkwarelCanBeShown: boolean;
    public logoHomepage: string =
      "../../../assets/img/logos/skwarel-logo-png-white2.png";

    static $inject = [
      "$translate",
      "SessionService",
      "$window",
      "I18NCONSTANTS",
      "ENV",
      "r_sessionID",
      "$http",
      "$translatePartialLoader",
      "$state",
      "tmhDynamicLocale",
    ];

    public sideImageUrl: string =
      "../../../assets/img/logos/Login-skwarel-en.png";

    constructor(
      private $translate: ngt.ITranslateService,
      public sessionService: SessionService,
      public $window: any,
      i18NCONSTANTS: app.config.constants.I18NConstants,
      public ENV: app.config.constants.environment.ConstantEnv,
      public r_sessionID: any,
      private $http: ng.IHttpService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $state: ng.ui.IState,
      private tmhDynamicLocale
    ) {
      $translatePartialLoader.addPart("common/login");
      this.checkURLForIcon();

      // use the language of the browser for the login page
      let navigator: string = "navigator";
      let language: string =
        window[navigator].userLanguage || window.navigator.language;

      let langToUse = i18NCONSTANTS.i18nDefaultLanguage;
      for (let lang of this.languages) {
        if (language.toLowerCase().search(lang.toLowerCase()) !== -1) {
          langToUse = lang;
          break;
        }
      }

      switch (langToUse.toLowerCase()) {
        case "fr":
          this.sideImageUrl = "../../../assets/img/logos/Login-skwarel-fr.png";
          break;
        case "nl":
          this.sideImageUrl = "../../../assets/img/logos/Login-skwarel-nl.png";
          break;
        case "en":
          this.sideImageUrl = "../../../assets/img/logos/Login-skwarel-en.png";
          break;
        default:
          this.sideImageUrl = "../../../assets/img/logos/Login-skwarel-fr.png";
          break;
      }

      this.$translate.use(langToUse.toLowerCase());
      this.tmhDynamicLocale.set(langToUse.toLowerCase());

      if (
        r_sessionID !== null &&
        r_sessionID !== undefined &&
        r_sessionID !== ""
      ) {
        // SessionID given, we need to get that session
        this.submitSessionID(r_sessionID);
      }

      this.expiredUser = false;
      this.resetPwdSuccess = false;
      this.resetPwdError = false;
      this.versionNumber = ENV.getVersion();
      this.initSentPasswordNotification();
    }

    public checkURLForIcon() {
      let url = window.location.href;
      url = url.replace("http://", "");
      url = url.replace("https://", "");
      url = url.replace("www.", "");
      url = url.substring(0, url.indexOf("."));
      if (url !== "" && url !== "app") {
        this.logoSkwarelCanBeShown = false;
        this.logoHomepage =
          "https://s3-eu-west-1.amazonaws.com/" +
          "sk-homepage-logo" +
          "/" +
          url +
          "/homepage.png";
      } else {
        this.logoSkwarelCanBeShown = true;
        this.logoHomepage = "../../../assets/img/logos/skwarel-logo-png.png";
      }
    }

    public initSentPasswordNotification() {
      if (
        this.$state.params.Mode === "SendNewPwd" &&
        this.$state.params.result === "true"
      ) {
        this.newPasswordSentSuccess = true;
      } else if (
        this.$state.params.Mode === "SendNewPwd" &&
        this.$state.params.result === "false"
      ) {
        this.newPasswordSentError = true;
      } else {
        this.newPasswordSentSuccess = false;
        this.newPasswordSentError = false;
      }
    }

    public submitLoginData() {
      this.gtag();

      this.btnBlocked = true;

      this.badAuth = false;
      this.serverError = false;

      this.expiredUser = false;

      if (this.username && this.password) {
        this.sessionService.setUsername(this.username);
        // @ts-ignore
        this.sessionService.setPassword(
          CryptoJS.SHA256(this.password).toString()
        );

        this.sessionService
          .getCSRF()
          .then(() => {
            return this.sessionService.getSession(false);
          })
          .then((response) => {
            if (response.status === 401) {
              this.badAuth = true;
              this.password = "";
              this.btnBlocked = false;
            } else if (response.status === 402) {
              this.expiredUser = true;
              this.password = "";
              this.btnBlocked = false;
            } else {
              this.sessionService.manageBetaTester(response); // also this function check the branding url
            }
          })
          .catch((error) => {
            console.error("ERROR DURING LOGIN", error);
            // this.logger.error("",error,this.sessionService.session.member");
            this.serverError = true;
            this.btnBlocked = false;
          });
      }
    }

    public resetPwd() {
      let self = this;

      self.resetPwdSuccess = false;
      if (angular.isDefined(this.resetPwdMail) && this.resetPwdMail !== "") {
        this.sessionService
          .resetPwd(this.resetPwdMail)
          .then(function (response) {
            self.resetPwdSuccess = true;
          })
          .catch(function (err) {
            self.resetPwdError = true;
          });
      }
    }

    public resetFlags() {
      this.resetPwdError = false;
      this.resetPwdSuccess = false;
      this.resetPwdMailSentTo = this.resetPwdMail;
      this.resetPwdMail = "";
    }

    public submitSessionID(sessionID: string) {
      this.sessionService
        .getCSRF()
        .then(function (res) {})
        .catch(function (error) {});
    }

    public gtag() {
      (<any>window).gtag("event", "conversion", {
        send_to: "AW-937282162/gsIcCIOMzPEBEPKU974D",
        value: 1.0,
        currency: "EUR",
        aw_remarketing_only: true,
      });
    }
  }
}

angular
  .module("app")
  .controller(
    "LoginController",
    app.functionality.common.session.LoginController
  );
