/// <reference path="../../_app.ts" />

module app.model.interface {

    export interface IContact {

        key: string;
        companyKey: string;

        reference: string;
        fullName: string;

        address: string;
        zip: string;
        city: string;
        country: string;

        contactFirstName: string;
        contactLastName: string;
        contactEmail: string;
        contactPhone: string;
    }

}