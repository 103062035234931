/// <reference path="../../../_app.ts" />

module app.functionality.upload {
  import SessionService = app.functionality.common.session.SessionService;
  export class ResultUploadModalController {
    public mapUploadError: any;
    public listUploadErrorJson = [];
    public duplicateFilesList = [];
    public uigridDuplicates: any;
    public uigridErrors: any;
    private me: any;
    private fromMessages: any;

    static $inject = [
      "$uibModalInstance",
      "r_data",
      "$uibModalStack",
      "$rootScope",
      "SessionService",
      "$translate",
    ];
    constructor(
      public $uibModalInstance: any,
      r_data: any,
      public $uibModalStack: any,
      private $rootScope: ng.IRootScopeService,
      public sessionService: SessionService,
      private $translate: ngt.ITranslateService
    ) {
      this.mapUploadError = r_data.mapUploadError;

      if (this.sessionService.session) {
        this.me = this.sessionService.session.member;
      }
      if (r_data.fromMessages) {
        this.fromMessages = r_data.fromMessages;
      }
      let key: any;
      for (key in this.mapUploadError) {
        if (
          key !== "size" &&
          key !== "filesArray" &&
          key !== "duplicateFiles"
        ) {
          let fileArr: Array<File> = this.mapUploadError[key];
          fileArr.forEach((item: any) => {
            this.listUploadErrorJson.push({
              file: item,
              cause: key,
            });
          });
        }
      }

      this.uigridErrors = {
        enableRowSelection: false,
        enableSelectAll: false,
        enableSorting: false,
        enableColumnMenus: false,
        selectionRowHeaderWidth: 40,
        rowHeight: 40,
        minRowsToShow:
          this.listUploadErrorJson.length < 10
            ? this.listUploadErrorJson.length + 1
            : 10,
      };
      if (this.listUploadErrorJson.length > 0) {
        this.uigridErrors.data = this.listUploadErrorJson;
      }

      if (this.mapUploadError["duplicateFiles"].length > 0) {
        this.duplicateFilesList = this.mapUploadError["duplicateFiles"];
      }

      this.uigridDuplicates = {
        enableRowSelection: true,
        enableSelectAll: true,
        enableSorting: false,
        enableColumnMenus: false,
        selectionRowHeaderWidth: 40,
        rowHeight: 40,
        minRowsToShow:
          this.duplicateFilesList.length < 10
            ? this.duplicateFilesList.length + 1
            : 10,
      };
      if (this.duplicateFilesList.length > 0) {
        this.uigridDuplicates.data = this.duplicateFilesList;
      }
      let self = this;
      this.uigridDuplicates.onRegisterApi = function (gridApi) {
        this.gridApi = gridApi;
        for (let i = 0; i < self.uigridDuplicates.data.length; i++) {
          const element = self.uigridDuplicates.data[i];
          self.uigridDuplicates.gridApi.grid.modifyRows(
            self.uigridDuplicates.data
          );
          self.uigridDuplicates.gridApi.selection.selectRow(element);
        }
      };

      this.initGridDuplicates();
      this.initGridErrors();
    }

    private initGridDuplicates() {
      this.uigridDuplicates.columnDefs = [
        {
          name: "name",
          field: "name",
          displayName: "UPLOAD.MODAL.resultUpload.name",
          cellTemplate:
            "tpl/grid/upload/resultUploadModal/duplicates/cellTemplateName.html",
          headerCellFilter: "translate",
          headerCellClass: "header-title text-left",
          cellClass: "duplicate-cell",
        },
        {
          name: "cause",
          field: "cause",
          displayName: "UPLOAD.MODAL.resultUpload.cause",
          cellTemplate:
            "tpl/grid/upload/resultUploadModal/duplicates/cellTemplateCause.html",
          headerCellFilter: "translate",
          headerCellClass: "header-title text-left",
          cellClass: "duplicate-cell",
        },
      ];
    }

    private initGridErrors() {
      this.uigridErrors.columnDefs = [
        {
          field: "name",
          displayName: "UPLOAD.MODAL.resultUpload.name",
          cellTemplate:
            "tpl/grid/upload/resultUploadModal/errors/cellTemplateName.html",
          headerCellFilter: "translate",
          headerCellClass: "header-title text-left",
          cellClass: "error-cell",
        },
        {
          field: "cause",
          displayName: "UPLOAD.MODAL.resultUpload.cause",
          cellTemplate:
            "tpl/grid/upload/resultUploadModal/errors/cellTemplateCause.html",
          headerCellFilter: "translate",
          headerCellClass: "header-title text-left",
          cellClass: "error-cell",
          cellFilter: "translate",
        },
      ];
    }

    showSendAgain() {
      if (
        this.uigridDuplicates.gridApi.selection.getSelectedRows().length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }

    sendDuplicatesAgain() {
      let selectedRows =
        this.uigridDuplicates.gridApi.selection.getSelectedRows();

      let filesToSendAgain = [];
      for (let i = 0; i < selectedRows.length; i++) {
        const row = selectedRows[i];
        filesToSendAgain.push(row.file);
      }
      let payload = { forceUpload: true, files: filesToSendAgain };
      this.$rootScope.$broadcast("clearMapUploadError");
      this.$rootScope.$broadcast("sendAnyway", payload);
      this.$uibModalInstance.close();
    }

    getDuplicateReason(
      memberKey: string,
      memberFullName: string,
      uploadDate: any
    ) {
      if (this.me.key === memberKey) {
        return this.$translate.instant(
          "UPLOAD.MODAL.resultUpload.duplicateByMe",
          { uploadDate: uploadDate }
        );
      } else {
        return this.$translate.instant(
          "UPLOAD.MODAL.resultUpload.duplicateByOther",
          { uploadDate: uploadDate, memberFullName: memberFullName }
        );
      }
    }

    closeModal() {
      //RESET mapUploadError
      this.$rootScope.$broadcast("clearMapUploadError");

      //CLOSE ALL MODALS
      if (!this.fromMessages) {
        this.$uibModalStack.dismissAll();
      }
      this.$uibModalInstance.close();
    }
  }
}

angular
  .module("app")
  .controller(
    "ResultUploadModalController",
    app.functionality.upload.ResultUploadModalController
  );
