/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.controllers {
  import IContact = app.model.interface.IContact;
  import ThirdWithOperations = app.model.invoiceReminder.ThirdWithOperations;

  export class ContactListController implements angular.IController {
    public pageTitle: string;

    /**
     * This array contains all the contacts of the third
     */
    private allContactsData: IContact[];

    /**
     * This array contains only the contacts that correspond to the filter
     */
    public contactsData: IContact[];

    /**
     * Contains only the contacts that are late
     */
    private lateContact: IContact[];

    /**
     * Contains the value of the filter based on the type of the third (if he's late or not)
     */
    public thirdTypeSelect;

    /**
     * Contains the value of the filter based on the missing email
     */
    public emailSelect;

    public thirdTypeSelectValues = ["all", "late"];
    public emailSelectValues = ["all", "missing"];

    public isLoading: boolean;

    $onInit() {}

    static $inject = [
      "r_contacts",
      "r_dataThirdsWithOperations",
      "r_title",
      "InvoiceReminderService",
      "SessionService",
      "$translatePartialLoader",
      "$translate",
      "Notification",
      "ModalQuickstartService",
    ];

    constructor(
      r_contacts: IContact[],
      r_dataThirdsWithOperations: ThirdWithOperations[],
      r_title: string,
      private invoiceReminderService: app.functionality.invoiceReminder.services.InvoiceReminderService,
      private sessionService: app.functionality.common.session.SessionService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $translate: ngt.ITranslateService,
      private notification: any,
      modalQuickstartService: app.functionality.invoiceReminder.services.ModalQuickstartService
    ) {
      $translatePartialLoader.addPart("customer/invoiceReminder");
      this.pageTitle = r_title;
      this.allContactsData = r_contacts;
      this.contactsData = this.allContactsData;
      this.thirdTypeSelect = this.thirdTypeSelectValues[0];
      this.emailSelect = this.emailSelectValues[0];
      if (!this.sessionService.isInvoiceRemindersInit())
        this.sessionService.setInvoiceReminders(r_dataThirdsWithOperations);
      modalQuickstartService.showDialogIfQuickstart();

      this.getContactListFromThirdsWithOperations(
        r_dataThirdsWithOperations,
        r_contacts
      );
    }

    /**
     * This function is used to get the list of the contacts representing the thirds that have due sales (i.e the thirds displayed in the overview view)
     * @param {app.model.invoiceReminder.ThirdWithOperations[]} thirds the late thirds
     * @param {app.model.interface.IContact[]} contacts all the contacts of the current user
     */
    private getContactListFromThirdsWithOperations(
      thirds: ThirdWithOperations[],
      contacts: IContact[]
    ) {
      let self = this;
      let associativeArrayAllContact = [];

      /* On va construire un tableau qui va contenir uniquement les contacts en retard.
                Afin d'éviter de faire un forEach sur les clients en retard qui contient un forEach sur les contact (complexité n²),
                On va d'abord créer un tableau associatif avec pour clé la référence du third et en valeur le contact (et ce pour tout les contact)
                On est en complexité n
             */
      self.allContactsData.forEach(function (value) {
        associativeArrayAllContact[value.reference] = value;
      });

      /*
                Ensuite, on va parcourir notre tableau de third et récupérer le contact qui lui est associé en se basant sur la clé de notre précédent
                tableau associatif
                Par exemple pour ATHES Fiduciaire, on va récupérer le contact en faisant associativeArrayAllContact['ATHES']
                On récupère nos contacts en un tour de boucle
                On a donc une complexité finale de 2n
             */
      self.lateContact = [];
      thirds.forEach(function (value) {
        if (value.getMaxDueAmount(new Date()) > 0) {
          self.lateContact.push(
            associativeArrayAllContact[value.third.reference]
          );
        }
      });
    }

    /**
     * This function is used to update the model based on the filters on the email (missing of not) and on the third type (late or not)
     */
    public updateFilters() {
      /*
                Si le filtre sur les retards est choisi, on se base sur notre tableau contenant uniquement les contacts en retard
                (qu'on a récupéré plus tot avec getContactListFromThirdsWithOperations
             */
      let contactToRestrict =
        this.thirdTypeSelect == this.thirdTypeSelectValues[1]
          ? this.lateContact
          : this.allContactsData;
      this.contactsData = [];

      /*
                Ensuite on teste le filtre sur l'email manquant
             */
      for (let i = 0; i < contactToRestrict.length; i++) {
        if (
          (this.emailSelect == this.emailSelectValues[1] &&
            (contactToRestrict[i].contactEmail == "" ||
              contactToRestrict[i].contactEmail == null)) ||
          this.emailSelect == this.emailSelectValues[0]
        ) {
          this.contactsData.push(contactToRestrict[i]);
        }
      }
    }

    /**
     * This function is used to update the email of a contact
     * @param {app.model.interface.IContact} contact the informations of the contact
     */
    public updateEmail(contact: IContact) {
      let self = this;

      if (contact.contactEmail == undefined) {
        self.notification.primary({
          title: self.$translate.instant(
            "NOTIFICATIONS.INVOICEREMINDER.UPDATECONTACTFORM.errorUpdateContact"
          ),
          message: self.$translate.instant(
            "NOTIFICATIONS.INVOICEREMINDER.CONTACTLISTUPDATE.malformedEmail"
          ),
          // templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
          delay: null,
        });
      } else {
        self.isLoading = true;

        this.invoiceReminderService
          .updateContactInfo(this.sessionService.session.sessionID, contact)
          .then(function (response) {
            self.isLoading = false;

            if (response.status == 0) {
              self.notification.primary({
                title: self.$translate.instant(
                  "NOTIFICATIONS.INVOICEREMINDER.updateDone"
                ),
                message:
                  self.$translate.instant(
                    "NOTIFICATIONS.INVOICEREMINDER.CONTACTLISTUPDATE.contactUpdate1"
                  ) +
                  contact.fullName +
                  self.$translate.instant(
                    "NOTIFICATIONS.INVOICEREMINDER.CONTACTLISTUPDATE.contactUpdate2"
                  ),
                // templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html",
              });
            } else {
              self.notification.primary({
                title: self.$translate.instant(
                  "NOTIFICATIONS.INVOICEREMINDER.UPDATECONTACTFORM.errorUpdateContact"
                ),
                message: self.$translate.instant(
                  "NOTIFICATIONS.INVOICEREMINDER.UPDATECONTACTFORM.updateFailedContact"
                ),
                // templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
                delay: null,
              });
            }
          });
      }
    }

    /**
     * This function is used to check if an attribute of the contact is defined or not.
     * @param {string} attribute the value of the attribute we want to check
     * @returns {boolean} true if the attribute is not undefined and empty
     */
    public isAttributeDefined(attribute: string) {
      return attribute != undefined && attribute.trim().length > 0;
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "ContactListController",
    app.functionality.invoiceReminder.controllers.ContactListController
  );
