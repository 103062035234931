/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {
  import SessionService = app.functionality.common.session.SessionService;
  import ENV = app.config.constants.environment.ConstantEnv;

  export class RegulationModalController {
    public language: any;
    public user_data: any;
    public data: any;
    public response: any;
    public force: any;
    public languages = ["NL", "FR", "EN"];
    static $inject = [
      "r_data",
      "$uibModalInstance",
      "$translate",
      "ModalUtil",
      "$uibModal",
      "SessionService",
      "$state",
      "$window",
      "Notification",
      "ENV",
      "$translatePartialLoader",
      "tmhDynamicLocale",
    ];

    constructor(
      r_data,
      public $uibModalInstance: any,
      private $translate: ngt.ITranslateService,
      private modalUtil: app.functionality.common.modals.ModalUtil,
      private $uibModal: any,
      private sessionService: SessionService,
      private $state: ng.ui.IStateService,
      private $window: ng.IWindowService,
      private notification: any,
      private env: ENV,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private tmhDynamicLocale
    ) {
      $translatePartialLoader.addPart("customer/modals");
      $translatePartialLoader.addPart("customer/notifications");
      $translatePartialLoader.addPart("customer/parameters");
      this.language = this.sessionService.session.member.language;
      this.data = r_data.data;
      this.response = r_data.response;
      this.force = r_data.force;
    }

    public changeLanguage() {
      this.$translate.use(this.data.member.language.toLowerCase());
      this.tmhDynamicLocale.set(this.data.member.language.toLowerCase());
      //update the typeahead with the appropriate expression (client, provider)
      // this.thirdsList = this.updateThirdListI18n(this.thirdsList);
    }

    public send() {
      let self = this;
      this.sessionService
        .confirmRegulation(
          this.sessionService.session.sessionID,
          this.data.member.firstname,
          this.data.member.lastname,
          this.data.member.mail,
          this.data.member.language,
          self.data.member.password,
          this.data.member.oldPassword
        )
        .then(function (data) {
          let back = data.plain();
          if (back.status === 0) {
            // update session
            self.sessionService.session.member.firstname =
              self.data.member.firstname;
            self.sessionService.session.member.lastname =
              self.data.member.lastname;
            self.sessionService.session.member.mail = self.data.member.mail;
            self.sessionService.session.member.language =
              self.data.member.language;
            self.sessionService.session.member.regulation = true;
            self.sessionService.session.member.accept_regulation = new Date();
            self.sessionService.session.member.password =
              self.data.member.password;
            self.sessionService.session.member.isAllowed = 100;
            self.$uibModalInstance.close();
            return self.sessionService.manageBetaTester(
              self.response,
              self.force
            );
          } else {
            self.notification.error(
              self.$translate.instant("NOTIFICATIONS.ERROR.serverError")
            );
            self.$uibModalInstance.close();
            self.close();
          }
        })
        .catch(function (error) {
          self.$uibModalInstance.close();
          self.close();
          console.error(error);
          console.error("error during log off");
        });
    }

    public close() {
      this.sessionService.authenticated = false;

      let self = this;
      this.sessionService
        .logOut(this.sessionService.session.sessionID)
        .then(function () {
          self.sessionService.mapUploadFolder = undefined;
          self.sessionService.session = undefined;
          self.sessionService.authenticated = false;
          document.title = "Skwarel";
          self.$state.go("login", {}, { reload: true }); // to reload the page
          self.$uibModalInstance.close();
        })
        .catch(function (error) {
          self.$uibModalInstance.close();
          self.sessionService.mapUploadFolder = undefined;
          self.sessionService.session = undefined;
          self.sessionService.authenticated = false;
          document.title = "Skwarel";
          self.$state.go("login", {}, { reload: true });
          console.error(error);
          console.error("error during log off");
        });
    }

    public modalInstance: any;
    public openPWD() {
      let self = this;
      this.modalInstance = this.$uibModal.open({
        templateUrl: "tpl/website/modals/changePwdModal.html",
        controller: "PasswordModalController",
        controllerAs: "passwordModal",
        size: "sm",
        resolve: {
          passed_user_data: function () {
            return self.data.member;
          },
        },
      });
    }

    checkFields() {
      let firstname = this.data.member.firstname;
      let lastname = this.data.member.lastname;
      let mail = this.data.member.mail;
      let language = this.data.member.language;
      let regulation = this.data.member.regulation;
      if (
        regulation &&
        firstname !== "" &&
        lastname !== "" &&
        mail !== "" &&
        language !== ""
      ) {
        return false;
      } else {
        return true;
      }
    }
  }
}

angular
  .module("app")
  .controller(
    "RegulationModalController",
    app.functionality.common.modals.RegulationModalController
  );
