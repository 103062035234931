/// <reference path="../../../_app.ts" />

module app.modules.tablePaging.directive {

    /**
     * This directive is used to display tables with paging.
     * To use it, declare a tag <table-paging> with three attributes :
     * - data : which contains all the data that you want to display
     * - sort: a default sort value (can be an attribute of your data or a function)
     * - store: which is used to store the treatment done by the directive
     * - number: the default number of data to display by page (optional, if this attribute is not specified, the default value is 10, if the value is -1, display all the data on the same page)
     * - element: the name of the elements you want to display (for exemple Customers, Invoices...). This will be displayed next to the pagination
     *            as, for example, "10 Customers by pages"
     * - descending : true if you want the table to be descending sorted by default (optional, if this attribute is not specified, the table is ascending sorted
     * Then, declare your classic HTML table and close the table-paging tag.
     *
     * To display your data with a ng-repeat, loop on the value of the store attribute.
     * It contains only the data that should be displayed on the current page.
     *
     * For example :
     * <table-paging store="myList" data="customersList" sort="customer.name">
     *     <table>
     *          <th>
     *              <td ng-click="tablePagingController.sort('client.name')>Customer's name</td>
     *          </th>
     *          <tr ng-repeat="customer in myList">
     *              <td>
     *                  {{ customer.name }}
     *              </td>
     *          </tr>
     *     </table>
     * </table-paging>
     */

    export class TablePagingDirective implements ng.IDirective {

        restrict = "E";
        templateUrl = "js/modules/tablePaging/directive/tablePagingDirective.html";
        transclude = true;

        controller =  app.modules.tablePaging.controller.TablePagingController;
        controllerAs = "tablePagingController";

        scope: {[boundProperty: string]: string} = {
            allData: "=data",
            sortValue: "@sort",
            defaultDisplay: "@?number",
            elementName: "=element",
            descending: "=descending"
        };

        bindToController : {[boundProperty: string]: string} = {
            allData: "=data",
            sortValue: "@sort",
            data: "=store",
            defaultDisplay: "@?number",
            elementName: "=element",
            descending: "=descending"
        };

        link(scope, element, attrs, ctrl, transclude) {
            scope.$parent.tablePagingController = ctrl;
            scope.$watch("allData",function(newValue,oldValue) {
                ctrl.resetData();
            });
        }

        constructor(private $translatePartialLoader: ngt.ITranslatePartialLoaderService) {
        }

        static factory(): ng.IDirectiveFactory {
            const directive = ($translatePartialLoader: ngt.ITranslatePartialLoaderService) => new TablePagingDirective($translatePartialLoader);
            directive.$inject = ["$translate"];

            return directive;
        }
    }
}

angular.module("tablePagingModule").directive("tablePaging", app.modules.tablePaging.directive.TablePagingDirective.factory());