/// <reference path="../../_app.ts" />

namespace app.functionality.IPPForm {
  import IPPFormService = app.functionality.IPPForm.IPPFormService;
  import ModalUtil = app.functionality.common.modals.ModalUtil;
  import Member = app.model.Member;

  export class IPPFormControllerIndex implements angular.IController {
    static $inject = [
      "$translate",
      "$scope",
      "$uibModal",
      "IPPFormService",
      "r_data",
      "$timeout",
      "$state",
      "$window",
      "$http",
      "SessionService",
      "$translatePartialLoader",
      "$stateParams",
      "RESTAPI",
      "Notification",
      "ENV",
      "LoggerService",
      "ModalUtil",
    ];
    /**
     * On définit ici tous les champs possibles pour chaque vue (pour le formulaire le plus complet)
     */
    public allFields;
    /**
     * La page actuell du formulaire
     */
    public activeStep: number;

    /**
     * Ensemble des vues du formulaire
     */

    public data;
    public model;
    public responsable: Member;
    public responsableName: string = undefined;
    public isAccounter: Boolean = false;
    public closed: Boolean = false;
    public begin: Boolean = false;
    public username: string;
    public showBackDashboard: boolean;

    public modeSelected: string;

    public statusState: number;
    public infoText: number;

    public legislation: string;
    // variable that we use to show an absolute number instead of a negative number
    public absolutePropositionValue: number;
    // boolean result that represent if the user accept the proposition made by the accounter, or not
    public propositionAccepted: boolean;

    // boolean to show the grid to add annexes to the declaration
    public enableAnnexes: boolean = false;

    // we set this boolean to true to not add another event listener if we upload something again with the same input element
    public alreadyUploadedSomething: boolean = false;

    public headerURL: string = "../../assets/img/logos/skwarel-header.svg";

    constructor(
      private $translate: ngt.ITranslateService,
      public $scope: ng.IScope,
      private $uibModal: any,
      private iPPService: IPPFormService,
      private r_data: any,
      private $timeout: ng.ITimeoutService,
      private $state: ng.ui.IStateService,
      public $window,
      protected $http: any,
      private sessionService: app.functionality.common.session.SessionService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      $stateParams: ng.ui.IStateParamsService,
      private RESTAPI: app.config.constants.ConstantRestApi,
      public notification: any,
      private env: ENV,
      public logger: app.functionality.common.logger.LoggerService,
      private modalUtil: ModalUtil
    ) {
      $translatePartialLoader.addPart("ipp");
      $translatePartialLoader.addPart("customer/upload");
      $translatePartialLoader.addPart("customer/header");
      this.data = r_data[0];
      this.legislation = "BE";
      if (this.sessionService.session.member.legislation) {
        this.legislation = this.sessionService.session.member.legislation;
      }
      this.model = r_data[0];
      this.responsable = r_data[1];
      if (
        this.responsable !== null &&
        this.responsable !== undefined &&
        this.responsable.firstname !== null &&
        this.responsable.lastname !== null
      ) {
        this.responsableName =
          this.responsable.firstname + " " + this.responsable.lastname;
      }
      this.activeStep = this.iPPService.activeStep;

      // to show the username in the layout header
      this.username = this.sessionService.session.member.fullName;
      this.showBackDashboard =
        this.sessionService.session.member.relatedCompaniesArr != null;
      this.isAccounter =
        this.sessionService.session.member.accesLevel !== 0 ? true : false;
      this.begin =
        this.model !== null &&
        this.model !== undefined &&
        this.model.key != null
          ? true
          : false;
      this.closed = this.begin && this.model.closed === true ? true : false;

      this.updateTemplateConditions();

      if (
        this.sessionService.session.accountingFirmBranding &&
        this.sessionService.session.accountingFirmBranding > 0 &&
        this.sessionService.session.brandingData &&
        this.sessionService.session.brandingData.iconPath
      ) {
        this.headerURL =
          "https://s3-eu-west-1.amazonaws.com/" +
          this.sessionService.session.brandingData.iconPath;
      }
    }

    $onInit() {}

    /**
     * Mettre à jour les données sur le serveur
     * @param data les données à mettre  à jour
     * @param nextView la vue suivante
     */
    public updateData(data, nextView) {
      this.iPPService.updateData(data, data.fiscalYear, nextView);
    }

    public getColor(step) {
      this.iPPService.getColor(step);
    }

    public updateTemplateConditions() {
      if (
        this.model.status_declaration >= 2 &&
        this.model.depot_accounter != null
      ) {
        this.infoText = 6;
        this.statusState = 1; // Déposée sur tax on web
      } else if (this.model.status_declaration === 0) {
        this.statusState = 2; // Non commencée
        this.infoText = 1;
      } else if (this.model.status_declaration === 1) {
        this.statusState = 3; // En cours de complétion
        this.infoText = 1;
      } else if (
        this.model.status_declaration === 2 &&
        !this.model.proposition_amount
      ) {
        this.statusState = 4; // Proposition en attente
        this.infoText = 2;
        this.enableAnnexes = true;
      } else if (
        this.model.status_declaration === 3 &&
        this.model.proposition_amount != null &&
        this.model.proposition_date != null &&
        this.model.deny_message == null
      ) {
        this.absolutePropositionValue = Math.abs(this.model.proposition_amount);
        this.statusState = 5; // Proposition à traiter
        this.infoText = 3;
      } else if (
        this.model.status_declaration === 3 &&
        this.model.proposition_amount != null &&
        this.model.proposition_date != null &&
        this.model.deny_message != null
      ) {
        this.absolutePropositionValue = Math.abs(this.model.proposition_amount);
        this.statusState = 6; // Proposition refusée
        this.infoText = 4;
      } else if (
        this.model.status_declaration === 4 &&
        this.model.proposition_amount != null &&
        this.model.proposition_accepted_date != null
      ) {
        this.absolutePropositionValue = Math.abs(this.model.proposition_amount);
        this.statusState = 7; // Proposition acceptée
        this.infoText = 5;
      } else {
        // send by client
        if (this.model.depot_accounter == null) {
          this.statusState = 5; // Proposition à traiter
          this.infoText = 3;
          // not sended to ToW
          // stat = 1;
        } else {
          // sended to ToW
          this.infoText = 4;
          this.statusState = 1; // Déposée sur tax on web
          // stat = 2;
          // dateSub = this.data.depot_accounter;
        }
      }
    }

    public viewDocument(keyIPP: string, filename: string, type: number) {
      // même logique de ToW dans la vue archive
      this.$uibModal.open({
        templateUrl: "tpl/website/modals/pdfViewerModal.html",
        controller: "PdfViewerModalController",
        controllerAs: "pdfViewer",
        size: "lg",
        keyboard: "false",
        resolve: {
          r_dataPMF: () => {
            return (
              this.env.baseUrl +
              "/" +
              this.RESTAPI.services.IPPForm.baseurl +
              "/" +
              this.sessionService.session.member.key +
              "/" +
              this.RESTAPI.services.IPPForm.ipps +
              "/" +
              keyIPP +
              "/" +
              this.RESTAPI.services.IPPForm.annexes +
              "?filename=" +
              filename +
              "&type=" +
              type +
              "&sessionID=" +
              this.sessionService.session.sessionID
            );
          },
          r_data: null,
        },
      });
    }

    public logout() {
      this.sessionService.authenticated = false;
      this.sessionService
        .logOut(this.sessionService.session.sessionID)
        .then(() => {
          // we make sure to empty the data from the service so there's no data left if we switch from ipp account to one another (known bug)
          this.iPPService.data = "";
          this.sessionService.session = undefined;
          this.sessionService.authenticated = false;
          this.$state.go("login");
          // self.$window.location.reload();
        })
        .catch(function (error) {
          console.error(error);
          console.error("error during log off");
        });
    }

    public updateFormMode() {
      if (this.modeSelected === "reading") {
        this.iPPService.readingMode = true;
        this.iPPService.validationMode = false;
      } else if (this.modeSelected === "normal") {
        this.iPPService.readingMode = false;
        this.iPPService.validationMode = false;
      } else if (this.modeSelected === "validation") {
        this.iPPService.validationMode = true;
        this.iPPService.readingMode = false;
      }
    }

    public addFile() {
      let fileInput: any = null;
      fileInput = document.querySelector("#fileInputAnnexe");
      if (this.alreadyUploadedSomething === false) {
        fileInput.addEventListener("change", this.launchUploadModal(fileInput));
      }
      document.getElementById("fileInputAnnexe").click();
      // we set this boolean to true to not add another event listener if we upload something again.
      this.alreadyUploadedSomething = true;
    }

    public submitApproval() {
      this.iPPService
        .acceptProposition(this.model.key, this.model.acceptation_message)
        .then((data) => {
          return this.iPPService.getDataFor(this.model.key).then((newData) => {
            this.model = newData; // the ipp
            this.updateTemplateConditions();
          });
        })
        .catch((err) => console.error("submit approval err", err));
    }

    public submitRefusal() {
      this.iPPService
        .denyProposition(this.model.key, encodeURI(this.model.deny_message))
        .then((data) => {
          return this.iPPService.getDataFor(this.model.key).then((newData) => {
            this.model = newData;
            this.updateTemplateConditions();
          });
        })
        .catch((err) => console.error("submit refusal err", err));
    }

    public launchUploadModal(fileInput: any) {
      let listener = this.$scope.$on(
        "ipp_" + this.model.key,
        (event, filename: string) => {
          if (this.model.annexe_files == null) {
            this.model.annexe_files = [];
          }
          this.model.annexe_files.push(filename);
        }
      );

      return (e): void => {
        if (fileInput.files.length > 0) {
          this.$uibModal
            .open({
              templateUrl: "tpl/website/modals/uploadModal.html",
              controller: "UploadModalController",
              controllerAs: "uploadModalController",
              //Permet de bloquer le click en dehors du modal
              backdrop: "static",
              size: "md",
              resolve: {
                ipp_data: () => {
                  return {
                    fileInput: fileInput,
                    typeFile: 1,
                    ippKey: this.model.key,
                    fiscalYear: this.model.fiscalYear,
                    accFirmkey: this.model.accFirmKey,
                  };
                },
                r_data: undefined,
                pmf_data: undefined,
              },
            })
            .result.then(() => {
              if (fileInput.files.length > 1) {
                this.notification.primary({
                  title: this.$translate.instant(
                    "IPP_FORM.NOTIFICATIONS.successUploadsTitle"
                  ),
                  message: this.$translate.instant(
                    "IPP_FORM.NOTIFICATIONS.successUploadsMessage"
                  ),
                  templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html",
                });
              } else {
                this.notification.primary({
                  title: this.$translate.instant(
                    "IPP_FORM.NOTIFICATIONS.successUploadTitle"
                  ),
                  message: this.$translate.instant(
                    "IPP_FORM.NOTIFICATIONS.successUploadMessage"
                  ),
                  templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html",
                });
              }
            })
            .catch((err) => {
              if (err.status === 413) {
                this.notification.primary({
                  title: this.$translate.instant(
                    "IPP_FORM.NOTIFICATIONS.fileTooBigErrorTitle"
                  ),
                  message: this.$translate.instant(
                    "IPP_FORM.NOTIFICATIONS.fileTooBigErrorMessage"
                  ),
                  templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
                });
                this.logger.error(
                  "file too large for annexe",
                  err,
                  this.sessionService.session.member
                );
              }
            });

          this.warnAccounter(this.model.key);
        }
      };
    }

    public warnAccounter(ippKey: string) {
      this.iPPService
        .warnResponsableAnnexe(ippKey)
        .then(function (data) {})
        .catch(function (error) {
          // TODO
        });
    }

    public removeAnnexe(annexe: string, index: number) {
      this.iPPService
        .confirmDocumentDelete()
        .then((result) => {
          if (result === "confirm") {
            return this.iPPService.deleteAnnexe(this.model.key, annexe, 1);
          }
        })
        .then(() => {
          // notification of success deletion
          this.notification.primary({
            title: "Annexe supprimée",
            message: `L'annexe <i>'${this.truncate(
              annexe
            )}'</i> a été supprimée.`,
            templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html",
          });
          // delete the file from the list
          this.model.annexe_files.splice(index, 1);
        })
        .catch((error) => {
          // ajouter notification d'erreur
          if (error !== "cancel") {
            this.notification.error({
              message: `Erreur : ${error.status} - ${error.statusText}`,
            });
            console.error("error while deleting the annexe", error);
          } else {
            console.error("suppression canceled");
          }
        });
    }

    // method used to add ellpsis to the file name, used in the notification
    public truncate(string) {
      if (string.length > 20) {
        return string.substring(0, 20) + "...";
      } else {
        return string;
      }
    }

    public showFile(keyIPP: String, filename: String, type: Number) {
      this.$uibModal.open({
        templateUrl: "tpl/website/modals/pdfViewerModal.html",
        controller: "PdfViewerModalController",
        controllerAs: "pdfViewer",
        size: "lg",
        keyboard: "false",
        resolve: {
          r_dataPMF: () => {
            return (
              this.env.baseUrl +
              "/" +
              this.RESTAPI.services.IPPForm.baseurl +
              "/" +
              this.sessionService.session.member.key +
              "/" +
              this.RESTAPI.services.IPPForm.ipps +
              "/" +
              keyIPP +
              "/" +
              this.RESTAPI.services.IPPForm.annexes +
              "?filename=" +
              filename +
              "&type=" +
              type +
              "&sessionID=" +
              this.sessionService.session.sessionID
            );
          },
          r_data: null,
        },
      });
    }

    public launchSupportModal() {
      this.modalUtil.support();
    }
  }
}

angular
  .module("app")
  .controller(
    "IPPFormControllerIndex",
    app.functionality.IPPForm.IPPFormControllerIndex
  );
