/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.services {

    import ICompanyAdditionalInformation = app.model.interface.ICompanyAdditionalInformation;

    /**
     * This service is used to display a modal if the user has not configured his company information yet
     * The aim of the modal is to guide the user to use the functionality
     */
    export class ModalQuickstartService {

        public companyInfo: ICompanyAdditionalInformation;

        static $inject = [
            "SessionService",
            "InvoiceReminderService",
            "$uibModal"
        ];

        constructor(private sessionService: app.functionality.common.session.SessionService,
                    private invoiceReminderService: InvoiceReminderService,
                    private $uibModal) {
        }

        /**
         * This function is used to show the quickstart modal if the user has not configured his parameters yet
         */
        public showDialogIfQuickstart() {
            let self = this;

            self.invoiceReminderService.getCompanyAdditionalInformation(self.sessionService.session.sessionID)
                .then(function (data) {
                    self.companyInfo = data.plain().data;

                    if (!self.isCompleted(data.plain().data)) {
                        self.openModal();
                    }
                });
        }

        /**
         * This function is used to test if the user has already configure his information
         * @param companyInformation the user company additional information
         * @returns {boolean} false if the user just started to use the functionality
         */
        private isCompleted(companyInformation: ICompanyAdditionalInformation): boolean {
            return companyInformation.fullName != undefined && companyInformation.fullName.trim().length > 0
                && companyInformation.senderName  != undefined && companyInformation.senderName.trim().length  > 0
                && companyInformation.senderMail  != undefined && companyInformation.senderMail.trim().length  > 0
                && companyInformation.address  != undefined && companyInformation.address.trim().length  > 0
                && companyInformation.zip      != undefined && companyInformation.zip.trim().length      > 0
                && companyInformation.city     != undefined && companyInformation.city.trim().length     > 0
                && companyInformation.country  != undefined && companyInformation.country.trim().length  > 0
                && companyInformation.iban     != undefined && companyInformation.iban.trim().length     > 0 ;
        }

        /**
         * This function is used to display the modal
         */
        private openModal() {
            this.$uibModal.open({
                templateUrl: "tpl/website/invoiceReminder/modal/quickstartModal.html",
                controller: "ModalQuickstartController",
                controllerAs: "mqC",
                size: "md",
                backdrop: "static",
                keyboard: false
            });
        }

    }

    angular.module("app").service("ModalQuickstartService",  app.functionality.invoiceReminder.services.ModalQuickstartService);
}