/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.services {

    import IContact = app.model.interface.IContact;
    import Reminder = app.model.invoiceReminder.Reminder;
    import ITemplateReminder = app.model.interface.ITemplateReminder;
    import ICompanyAdditionalInformation = app.model.interface.ICompanyAdditionalInformation;

    export class InvoiceReminderService {
        private rest: restangular.IService;

        static $inject = ["RestService", "RESTAPI"];

        constructor(restService: app.functionality.common.rest.RestService, private RESTAPI: app.config.constants.ConstantRestApi) {
            this.rest = restService.getRoot();
        }

        /**
         * This function is used to get all the information of a third contact
         * @param {string} sessionID the current user of the application
         * @param {string} reference the reference of the desired third
         * @returns {angular.IPromise<any>} a JSON
         */
        getContactInfo = (sessionID: string, reference: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.contact)
                .all(this.RESTAPI.services.invoiceReminder.getContactInfo)
                .one(sessionID + "/" + reference).get();
        }

        /**
         * This function is used to update the contact data of a third
         * @param sessionID the current user of the application
         * @param contact the new values to be saved
         * @returns {angular.IPromise<any>} update if the update is done
         */
        updateContactInfo = (sessionID: string, contact: IContact): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.contact)
                .all(this.RESTAPI.services.invoiceReminder.updateContactInfo)
                .one(sessionID).customPUT(contact);
        }

        /**
         * This function is used to send a reminder by email
         * @param sessionID the current user of the application
         * @param reminder the reminder data that should be sent
         * @returns {angular.IPromise<any>} SUCCESS if the reminder is sent
         */
        sendReminder = (sessionID: string, reminder: Reminder): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.emailReminder)
                .all(this.RESTAPI.services.invoiceReminder.sendReminder)
                .one(sessionID).customPUT(reminder);
        }

        /**
         * This function is used to send a test reminder by email
         * @param sessionID the current user of the application
         * @param reminder the reminder data that should be sent
         * @param email the email address to send a message to
         * @returns {angular.IPromise<any>} SUCCESS if the reminder is sent
         */
        sendTestReminder = (sessionID: string, reminder: Reminder, email: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.emailReminder)
            .all(this.RESTAPI.services.invoiceReminder.sendReminder)
            .one(sessionID+"?email="+encodeURI(email).replace("+","%2B")).customPUT(reminder);
        }
        
        /**
         * This function is used to send a default test reminder by email
         * @param sessionID the current user of the application
         * @param template the template data that should be sent
         * @param email the email address to send a message to
         * @returns {angular.IPromise<any>} SUCCESS if the reminder is sent
         */
        sendDefaultTestReminder = (sessionID: string, template: ITemplateReminder, email: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.emailReminder)
                .all(this.RESTAPI.services.invoiceReminder.sendReminder)
                .one(sessionID+"?email="+encodeURI(email).replace("+","%2B")+"&noParsing=true").customPUT(template);
        }

        /**
         * This function is used to get a user's reminder templates
         * @param {string} sessionID the current user
         * @returns {angular.IPromise<any>} a list of all the templates of the user
         */
        getUserTemplates = (sessionID: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.templateReminder)
                .all(this.RESTAPI.services.invoiceReminder.getUserTemplates)
                .one(sessionID).get();
        }

        /**
         * This function is used to update a given template
         * @param {string} sessionID the current user
         * @param {app.model.interface.ITemplateReminder} template the template to update
         * @returns {angular.IPromise<any>} a HTTP response that can corresponds to a success or an error (see back for more details)
         */
        updateTemplate = (sessionID: string, template: ITemplateReminder): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.templateReminder)
                .all(this.RESTAPI.services.invoiceReminder.updateTemplate)
                .one(sessionID).customPUT(template);
        }

        /**
         * This function is used to get the additional information (like adress, iban) for the user's company
         * @param {string} sessionID the current user
         * @returns {angular.IPromise<any>} the additional information (like adress, iban) for the user's company
         */
        getCompanyAdditionalInformation = (sessionID: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.companyAdditionalInformation)
                .all(this.RESTAPI.services.invoiceReminder.getCompanyAdditionalInformation)
                .one(sessionID).get();
        }

        /**
         * This function is used to update the user company's additional information
         * @param {string} sessionID the current user
         * @param {app.model.interface.ICompanyAdditionalInformation} companyAdditionalInfo the informations to update
         * @returns {angular.IPromise<any>} a HTTP response that can corresponds to a success or an error (see back for more details)
         */
        updateCompanyAdditionalInformation = (sessionID: string, companyAdditionalInfo: ICompanyAdditionalInformation): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.companyAdditionalInformation)
                .all(this.RESTAPI.services.invoiceReminder.updateCompanyAdditionalInformation)
                .one(sessionID).customPUT(companyAdditionalInfo);
        }

        /**
         * This function is used to get the contact of all the thirds the user is related to
         * @param {string} sessionID the current user
         * @returns {angular.IPromise<any>} an array of Contact as a JSON
         */
        getAllContactsOfUser = (sessionID: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.contact)
                .all(this.RESTAPI.services.invoiceReminder.getAllContactsOfUser)
                .one(sessionID).get();
        }

        /**
         * This function is used to get all the thirds related to the user and their operations
         * @param {string} sessionID the current user
         * @returns {angular.IPromise<any>} an array of ThirdWithOperations as a JSON
         */
        getAllThirdsWithOperations = (sessionID: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.invoiceReminderOperations)
                .all(this.RESTAPI.services.invoiceReminder.getAllThirdsWithOperations)
                .one(sessionID).get();
        }

        /**
         * This function is used to get a specific third and its operations
         * @param {string} sessionID the current user
         * @param {string} reference the reference of the third we want its operations
         * @returns {angular.IPromise<any>} a ThirdWithOperations as a JSON
         */
        getThirdWithOperations = (sessionID: string, reference: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.invoiceReminderOperations)
                .all(this.RESTAPI.services.invoiceReminder.getThirdWithOperations)
                .one(sessionID + "/" + reference).get();
        }

        /**
         * This function is used to get a specific sale
         * @param {string} sessionID the current user
         * @param {string} key the key of the sale we want (the key in the database)
         * @returns {angular.IPromise<any>} an ISale object as a json
         */
        getSale = (sessionID: string, key: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.invoiceReminderOperations)
                .all(this.RESTAPI.services.invoiceReminder.getSale)
                .one(sessionID + "/" + key).get();
        }

        /**
         * This function is used to get all the reminders that contains a specified sale
         * @param {string} sessionID the current user
         * @param {string} operationKey the operation key of the sale we want the related reminders
         * @returns {angular.IPromise<any>} An array of IReminder as JSON
         */
        getRemindersByOperationKey = (sessionID: string, operationKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.emailReminder)
                .all(this.RESTAPI.services.invoiceReminder.getRemindersByOperationKey)
                .one(sessionID + "/" + operationKey).get();
        }

        /**
         * This function is used to gets all the reminders that are related to a specified third
         * @param {string} sessionID the current user
         * @param {string} reference the reference of the third we want the related reminders
         * @returns {angular.IPromise<any>}  an Array of IReminder as JSON
         */
        getRemindersByReference = (sessionID: string, reference: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoiceReminder.emailReminder)
                .all(this.RESTAPI.services.invoiceReminder.getRemindersByReference)
                .one(sessionID + "/" + reference).get();
        }

    }
}

angular.module("app").service("InvoiceReminderService",  app.functionality.invoiceReminder.services.InvoiceReminderService);