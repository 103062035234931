/// <reference path="../../../../_app.ts" />

module app.functionality.invoiceReminder.controllers.modal {

  /**
   * This modal is used to warn the user that the selected date is not good
   * and what are the rules to follow to chose a good one.
   */

  export class ErrorSelectedDateModalController  implements angular.IController {

      $onInit() {
      }

      static $inject = [
          "$uibModalInstance"
      ];

      constructor(private $uibModalInstance) { }

      /**
       * This function is used to make the modal disappeared
       */
      public cancel() {
          this.$uibModalInstance.dismiss();
      }

  }

  //noinspection TypeScriptValidateTypes
  angular.module("app").controller("ErrorSelectedDateModalController", app.functionality.invoiceReminder.controllers.modal.ErrorSelectedDateModalController);
}