/// <reference path="../../../_app.ts" />

module app.functionality.invoicing.controllers {
  import InvoicingClient = app.model.invoicing.InvoicingClient;
  import Invoice = app.model.invoicing.Invoice;
  import InvoiceLine = app.model.invoicing.InvoiceLine;
  import Company = app.model.Company;
  import InvoicingInformation = app.model.invoicing.InvoicingInformation;
  import InvoicingInformationIncrement = app.model.invoicing.InvoicingInformationIncrement;
  import InvoicingInformationVatRate = app.model.invoicing.InvoicingInformationVatRate;

  export class InvoicingEditionController implements angular.IController {
    public clients: InvoicingClient[]; // The clients of the user's company
    public invgInfo: InvoicingInformation; // The invoicing informations of the user

    public increments: InvoicingInformationIncrement[]; // Only increments of the current type (invoice/credit note)
    public selectedIncrement: InvoicingInformationIncrement; // The selected increment for numbering
    public selectedVatRates: InvoicingInformationVatRate[] = []; // Array of selected vat rates (one entry per line)

    private invoice: Invoice; // The current editing invoice
    private client: InvoicingClient; // The recipient client
    private company: Company; // The user's company

    private isEditing: boolean; // True if the invoice is being edited, false if it only is visualized
    private isModifyingFooter: boolean = false;
    private isModifyingFilename: boolean = false;
    private isNewInvoice: boolean;
    private intraComCpt = 0; // The amount of lines being an intracommunautary sale (known via the VAT rate selection)
    private exportCpt = 0; // The amount of lines being an intracommunautary sale (known via the VAT rate selection)
    private lineCpt: number = 0; // The invoice's amount of lines

    private popupInvoiceDate = { opened: false };
    private vatRatesData: Object[] = []; // Object containing rates and values for each unique vat rate (to display in the table summing up vat rates)
    private popupDueDate = { opened: false };

    private accFirmIsFrench: boolean;

    private typeahead = {
      search: "",
      noResult: false,
    };

    private invalidInputBooleans = {
      invoiceNumber: false,
      invoiceDate: false,
      clientName: false,
      filename: false,
    };

    private dueDateUsed: boolean = false;

    private invoiceLinesTbody = document.getElementById("invoiceLines");

    /* these are used for the invoice translations in the HMTL */
    /* greyed out variables are used! */
    private from_Translation: string = "";
    private vat_Translation: string = "";
    private siret_Translation: string = "";
    private legalForm_Translation: string = "";
    private legalFormSentence1_Translation: string = "";
    private legalFormSentence2_Translation: string = "";
    private to_Translation: string = "";
    private invoiceNumber_Translation: string = "";
    private creditNoteNumber_Translation: string = "";
    private invoiceDate_Translation: string = "";
    private dueDate_Translation: string = "";
    private description_Translation: string = "";
    private quantity_Translation: string = "";
    private unitPrice_Translation: string = "";
    private vatPercent_Translation: string = "";
    private exclVat_Translation: string = "";
    private inclVat_Translation: string = "";
    private exclVatTotal_Translation: string = "";
    private vatTotal_Translation: string = "";
    private inclVatTotal_Translation: string = "";

    private invoiceTranslationArray = [
      "from",
      "vat",
      "siret",
      "legalForm",
      "legalFormSentence1",
      "legalFormSentence2",
      "to",
      "invoiceNumber",
      "creditNoteNumber",
      "invoiceDate",
      "dueDate",
      "description",
      "quantity",
      "unitPrice",
      "vatPercent",
      "exclVat",
      "inclVat",
      "exclVatTotal",
      "vatTotal",
      "inclVatTotal",
    ];

    $onInit() {}

    static $inject = [
      "r_invoice",
      "r_clients",
      "r_invgInfo",
      "$compile",
      "$filter",
      "$scope",
      "$state",
      "$stateParams",
      "$translate",
      "$translatePartialLoader",
      "$uibModal",
      "$window",
      "ENV",
      "InvoicingService",
      "ClientService",
      "Notification",
      "SessionService",
    ];

    constructor(
      r_invoice: Invoice,
      r_clients: InvoicingClient[],
      r_invgInfo: InvoicingInformation,
      private $compile: ng.ICompileService,
      private $filter: ng.IFilterService,
      private $scope: ng.IScope,
      private $state: ng.ui.IStateService,
      private $stateParams: ng.ui.IStateParamsService,
      private $translate: ngt.ITranslateService,
      private $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $uibModal,
      private $window,
      private ENV: app.config.constants.environment.ConstantEnv,
      private invoicingService: services.InvoicingService,
      private clientService: services.ClientService,
      private notification: any,
      private sessionService: common.session.SessionService
    ) {
      $translatePartialLoader.addPart("customer/invoicing");

      this.company = sessionService.session.company;

      this.clients = r_clients.filter((client) => {
        return !client.archived;
      }); // Only retrieves clients not archived

      this.invgInfo = new InvoicingInformation();
      angular.copy(r_invgInfo, this.invgInfo);
      this.invgInfo.increments = [];
      r_invgInfo.increments.forEach((increment) => {
        this.invgInfo.increments.push(
          angular.copy(increment, new InvoicingInformationIncrement())
        );
      });
      this.invgInfo.vatRates = [];
      r_invgInfo.vatRates.forEach((rate) => {
        this.invgInfo.vatRates.push(
          angular.copy(rate, new InvoicingInformationVatRate())
        );
      });

      this.invoice = new Invoice();
      if (
        (this.$stateParams.invoice != null || r_invoice != null) &&
        !this.$stateParams.isCopy
      ) {
        // If we are viewing an existing invoice
        let invoiceSrc: Invoice;
        if (this.$stateParams.invoice != null) {
          invoiceSrc = this.$stateParams.invoice;
        } else {
          invoiceSrc = r_invoice;
        }
        angular.copy(invoiceSrc, this.invoice);
        this.invoice.line = [];
        invoiceSrc.line.forEach((line) => {
          this.invoice.line.push(
            angular.copy(line as InvoiceLine, new InvoiceLine())
          );
        });
        this.invoice.invoiceDate = new Date(this.invoice.invoiceDate);
        if (this.invoice.dueDate != null) {
          this.invoice.dueDate = new Date(this.invoice.dueDate);
        }
        this.increments = this.invgInfo.increments.filter((increment) => {
          increment.type == this.invoice.type;
        });
        this.computeIntraComCpt();
      } else if (this.$stateParams.isCopy) {
        // If we are creating a copy/a credit note from an existing invoice
        let invoiceSrc: Invoice;
        invoiceSrc = this.$stateParams.invoice;
        angular.copy(invoiceSrc, this.invoice);

        this.isEditing = true;
        this.isNewInvoice = true;
        this.invoice.type = this.$stateParams.type;
        this.invoice.invoiceDate = new Date();
        this.invoice.dueDate = null;

        this.invoice.line = [];
        invoiceSrc.line.forEach((line) => {
          this.invoice.line.push(
            angular.copy(line as InvoiceLine, new InvoiceLine())
          );
        });

        this.computeDefaultDueDate();

        this.increments = this.invgInfo.increments.filter((increment) => {
          return increment.type == this.invoice.type;
        });
        this.selectIncrement(this.increments[0]);
        this.computeIntraComCpt();
      } else {
        // If we are creating a new invoice
        this.isEditing = true;
        this.isNewInvoice = true;
        if (this.$stateParams.type) {
          this.invoice.type = this.$stateParams.type;
        }
        this.invoice.language =
          this.sessionService.session.member.language.toLowerCase();
        this.computeDefaultDueDate();
        this.changeLanguage(false);

        this.increments = this.invgInfo.increments.filter((increment) => {
          return increment.type == this.invoice.type;
        });
        this.selectIncrement(this.increments[0]);
      }

      if (
        this.invoice.clientKey != "" &&
        this.invoice.clientKey != undefined &&
        this.invoice.clientKey != null
      ) {
        this.client = this.clientFromKey(this.invoice.clientKey);
        this.typeahead.search = this.client.fullName;
      }

      $translatePartialLoader.addPart("customer/invoicing");

      // Add a new line to the document
      // If the line property of the invoice object isn't empty, we add a line for each line in line property
      // If the line property is empty, we only add one line to the document, and one InvoiceLine to the Invoice object
      do {
        this.addNewLine(this.invoice.line.length == 0);
      } while (this.lineCpt < this.invoice.line.length);

      this.invoice.updateTotals(); // Update the totals of the invoice, both in model and document
      this.sumVatRates(); // Compute the vat rates values

      this.compileInputVar("footerText");
      this.compileInputVar("filename");

      if (this.company.country.toLowerCase() === "france") {
        this.accFirmIsFrench = true;
      } else {
        this.accFirmIsFrench = false;
      }

      this.translateInvoices();
    }

    /**
     * Calculates the due date using the configured default delay following the invoice date
     */
    computeDefaultDueDate(): void {
      let duedate: Date;
      if (this.invoice.type == "invoice" && this.isNewInvoice) {
        this.invoice.dueDate = new Date(this.invoice.invoiceDate.toISOString());

        this.invoice.dueDate.setDate(
          this.invoice.dueDate.getDate() + this.invgInfo.defaultDaysToDueDate
        );
        duedate = new Date(this.invoice.dueDate.toISOString());

        /* this.invoice.dueDate.setMonth(
            this.invoice.invoiceDate.getMonth() +
              this.invgInfo.defaultMonthsToDueDate
          ); */
        this.invoice.dueDate.setMonth(
          duedate.getMonth() + this.invgInfo.defaultMonthsToDueDate
        );
      }
    }

    /**
     * Selects an increment for the invoice/credit note number
     * @param increment The selected increment
     */
    selectIncrement(increment: InvoicingInformationIncrement): void {
      this.selectedIncrement = increment;
      this.invoice.invoiceNumber = increment.lastUsedValue + 1;
      this.invoice.computeSC();
    }

    /**
     * Selects a vat rate for a given invoice line
     * @param vatRate The selected VAT rate
     * @param lineIdx The index of the line
     */
    selectVatRate(vatRate: InvoicingInformationVatRate, lineIdx: number): void {
      if (this.invoice.line[lineIdx].vatRateName == "_intraCom") {
        this.intraComCpt--;
      } else if (vatRate.name == "_intraCom") {
        this.intraComCpt++;
      }

      if (this.invoice.line[lineIdx].vatRateName == "_export") {
        this.exportCpt--;
      } else if (vatRate.name == "_export") {
        this.exportCpt++;
      }

      this.selectedVatRates[lineIdx] = vatRate;
      this.invoice.line[lineIdx].vatRate = vatRate.rate;
      this.invoice.line[lineIdx].vatRateName = vatRate.name;
      this.invoice.updateTotals();
      this.sumVatRates();
    }

    /**
     * Compute the amount of line containing the intracommunautary vat rate
     */
    computeIntraComCpt() {
      this.intraComCpt = 0;

      this.invoice.line.forEach((aLine) => {
        if (aLine.vatRateName == "_intraCom") {
          this.intraComCpt++;
        }

        if (aLine.vatRateName == "_export") {
          this.exportCpt++;
        }
      });
    }

    /**
     * This function add a new InvoiceLine object to this.invoice (except if the added line exist already in this.invoice) and then add a new line to the form
     * @param doAddInvoiceLine True if an InvoiceLine object should be added to the line property of the Invoice object, false otherwise (already existing line in object but not in template)
     */
    addNewLine(doAddInvoiceLine: boolean = true): void {
      if (doAddInvoiceLine) {
        this.invoice.addNewLine(this.lineCpt);
      }

      let row = document.createElement("tr"); // The row
      row.id = "line" + this.lineCpt;

      let description = document.createElement("td"); // The description input
      description.className += " cellDescription";
      description.setAttribute(
        "ng-class",
        "{ 'has-error': invgEdCtrl.invalidInputBooleans.lineDescription" +
          this.lineCpt +
          " }"
      );
      description.innerHTML =
        '<input ng-class="{\'ng-hide\': !invgEdCtrl.isEditing }" ng-model="invgEdCtrl.invoice.line[' +
        this.lineCpt +
        '].description" ng-change="invgEdCtrl.invalidInputBooleans.lineDescription' +
        this.lineCpt +
        ' = false" class="form-control" id="lineDescriptionInput' +
        this.lineCpt +
        '"/>' +
        "<span ng-class=\"{'ng-hide': invgEdCtrl.isEditing }\">{{ invgEdCtrl.invoice.line[" +
        this.lineCpt +
        "].description }}</span>";

      let quantity = document.createElement("td"); // The quantity input
      quantity.className += " cellQuantity";
      quantity.innerHTML =
        '<input ng-class="{ \'ng-hide\': !invgEdCtrl.isEditing }" ng-model="invgEdCtrl.invoice.line[' +
        this.lineCpt +
        '].quantity" ng-change="invgEdCtrl.invoice.updateTotals(); invgEdCtrl.sumVatRates()" type="number" class="form-control"/>' +
        "<span ng-class=\"{ 'ng-hide': invgEdCtrl.isEditing }\">{{ invgEdCtrl.invoice.line[" +
        this.lineCpt +
        "].quantity | number }}</span>";

      let price = document.createElement("td"); // The price input
      price.className += " cellPrice";
      price.innerHTML =
        '<input ng-class="{ \'ng-hide\': !invgEdCtrl.isEditing }" ng-model="invgEdCtrl.invoice.line[' +
        this.lineCpt +
        '].price" ng-change="invgEdCtrl.invoice.updateTotals(); invgEdCtrl.sumVatRates()" type="number" class="form-control"/>' +
        "<span ng-class=\"{ 'ng-hide': invgEdCtrl.isEditing }\">{{ invgEdCtrl.invoice.line[" +
        this.lineCpt +
        "].price | number:2 }}</span>";

      let vat = document.createElement("td"); // The VAT rate input
      vat.className += " cellVat";
      vat.innerHTML =
        `<div class="btn-group cellVatBtnGroup" uib-dropdown ng-class="{ 'ng-hide': !invgEdCtrl.isEditing }">
                    <input disabled type="text" class="form-control vatRateInput" ng-model="invgEdCtrl.invoice.line[` +
        this.lineCpt +
        `].vatRate" min="0" />
                    <button type="button" class="btn" uib-dropdown-toggle style="float: right;">
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu" uib-dropdown-menu role="menu">
                        <li role="menuitem"
                            ng-repeat="vatRate in invgEdCtrl.invgInfo.vatRates"
                            ng-class="{ 'selected': invgEdCtrl.selectedVatRates[` +
        this.lineCpt +
        `] == vatRate }"
                            ng-click="invgEdCtrl.selectVatRate(vatRate, ` +
        this.lineCpt +
        `)"
                            >
                            <a class="dropdownItem">
                                <span>{{ ["_intraCom", "_export"].indexOf(vatRate.name) != -1 ? ('INVOICING.SHARED.' + vatRate.name | translate) : vatRate.name }}</span>  <span>{{ vatRate.rate }}</span>  <span><helper ng-if="vatRate.description" position="bottom" content='{{ ["_intraComTip", "_exportTip"].indexOf(vatRate.description) != -1 ? ("INVOICING.SHARED." + vatRate.description | translate) : vatRate.description }}'></helper></span>
                            </a>
                        </li>
                    </ul>
                </div>` +
        "<span ng-class=\"{ 'ng-hide': invgEdCtrl.isEditing }\">{{ invgEdCtrl.invoice.line[" +
        this.lineCpt +
        "].vatRate | number:2 }}</span>";

      let total = document.createElement("td"); // The line's total display
      total.className += " cellTotal";
      total.innerHTML =
        "{{ invgEdCtrl.invoice.line[" + this.lineCpt + "].total | number:2 }}";

      let vatTotal = document.createElement("td"); // The line's total including VAT
      vatTotal.className += " cellVatTotal";
      vatTotal.innerHTML =
        "{{ invgEdCtrl.invoice.line[" +
        this.lineCpt +
        "].vatTotal | number:2 }}";

      let removeLine = document.createElement("td"); // The button to remove the line
      removeLine.className +=
        " ng-class: { 'ng-hide': !invgEdCtrl.isEditing }; removeBtn";
      removeLine.innerHTML =
        "<img src='assets/img/icons/delete_grey_16x16.png'/>";
      if (this.lineCpt == 0) {
        removeLine.setAttribute("style", "visibility: hidden;");
      } else {
        removeLine.setAttribute(
          "ng-click",
          "invgEdCtrl.removeLine(" + this.lineCpt + ")"
        );
      }

      // Adds the HTML elements to the row element
      angular.element(row).append(description);
      angular.element(row).append(quantity);
      angular.element(row).append(price);
      angular.element(row).append(vat);
      angular.element(row).append(total);
      angular.element(row).append(vatTotal);
      angular.element(row).append(removeLine);

      // Compiles the element in order to be able to use Angular JS with the element and his children
      angular
        .element(this.invoiceLinesTbody)
        .append(this.$compile(row)(this.$scope));

      if (doAddInvoiceLine) {
        // Select the first vat rate
        this.selectVatRate(this.invgInfo.vatRates[0], this.lineCpt);
      }

      this.lineCpt++;
    }

    /**
     * Remove the specified line both from document and invoice object
     * @param idx The index of the line to remove
     */
    removeLine(idx: number): void {
      // Removes the element from the DOM
      let lineToRemove = document.getElementById("line" + idx);
      this.invoiceLinesTbody.removeChild(lineToRemove);

      this.selectedVatRates[idx] = null;
      if (this.invoice.line[idx].vatRateName == "_intraCom") {
        this.intraComCpt--;
      }

      if (this.invoice.line[idx].vatRateName == "_export") {
        this.exportCpt--;
      }

      // For each line after the deleted line, we decrease the number of the line (in order to keep a continuous serie of lines' number)
      for (let i = idx + 1; i < this.lineCpt; i++) {
        let line = document.getElementById("line" + i);
        line.innerHTML = line.innerHTML
          .replace(
            new RegExp("invoice.line\\[" + i, "g"),
            "invoice.line[" + (i - 1)
          )
          .replace(
            new RegExp("removeLine\\(" + i, "g"),
            "removeLine(" + (i - 1)
          );
        line.id = "line" + (i - 1);
        this.$compile(line)(this.$scope);
      }

      // Removes the line from the invoice object and updates the data
      this.invoice.removeLine(idx);
      this.invoice.updateTotals();
      this.sumVatRates();
      this.lineCpt--;
    }

    /**
     * Function called when the user types in the client name input or select an option from the typeahead
     * The function nullify the client in the controller and in the invoice when the client from the typeahead isn't valid (avoiding invalid value in the invoice object and thus in the DB)
     * @param selectedClient Optional parameter, the selected client from the typeahead options (note: when the input exactly matches an option, the latter is automatically selected)
     */
    typeaheadChanged(selectedClient?: InvoicingClient): void {
      if (selectedClient) {
        this.client = selectedClient;
        this.invoice.clientKey = this.client.key;
      } else {
        this.client = null;
        this.invoice.clientKey = null;
      }
    }

    /**
     * Returns the client's fullname from its key
     * @param key The client's key
     */
    clientFromKey(key: string): InvoicingClient {
      return this.clients.filter((client) => {
        return client.key == key;
      })[0];
    }

    /**
     * Redirects to clientEdition ; asks confirmation before doing so
     */
    newClient(): void {
      let self = this;
      this.$uibModal
        .open({
          templateUrl: "tpl/website/invoicing/modal/invgConfModal.html",
          controller: "InvgConfModalController",
          controllerAs: "confMdCtrl",
          backdrop: "static",
          resolve: {
            r_title: () => {
              return self.$translate.instant("INVOICING.MODAL.quitPage");
            },
            r_content: () => {
              return (
                "<p>" +
                self.$translate.instant("INVOICING.EDITION.quittingMsg1") +
                "</p>" +
                "<p>" +
                self.$translate.instant("INVOICING.EDITION.quittingMsg2") +
                "</p>"
              );
            },
            r_buttons: () => {
              return {
                cancel: {
                  text: self.$translate.instant("INVOICING.MODAL.cancel"),
                  class: "btn-default",
                },
                confirm: {
                  text: self.$translate.instant("INVOICING.MODAL.quitPage"),
                  class: "btn-warning",
                },
              };
            },
          },
        })
        .result.then((confirmation) => {
          if (confirmation) {
            this.$state.go(
              "websitelayout.headerandmenu.invoicingClientEdition"
            );
          }
        });
    }

    /**
     * Called when the user selects a language, changes the invoice's language
     * @param doCompile If true, compiles footerText and filename divs
     */
    changeLanguage(doCompile: boolean = true): void {
      if (this.invoice.language) {
        this.invoice.description =
          this.invgInfo.localizedValues[this.invoice.language].headingText;
        this.invoice.footerText =
          this.invgInfo.localizedValues[this.invoice.language].footerText;
        this.invoice.filename =
          this.invgInfo.localizedValues[this.invoice.language].filename;
      }

      if (doCompile) {
        this.compileInputVar("footerText");
        this.compileInputVar("filename");
      }
    }

    /**
     * Takes the value of an input with variables, replace variables with AngularJS interpolation and add the result within the corresponding div
     * @param inputName The name of the input (the input, the invoice's property and the target div MUST have the same name)
     */
    compileInputVar(inputName: string): void {
      if (inputName == "footerText" && this.invoice.type != "invoice") {
        return;
      }

      const variables = {
        // Allowed variables for each input
        footerText: [
          "exclVatTotal",
          "inclVatTotal",
          "structuredCommunication",
          "invoiceDate",
          "dueDate",
          "iban",
          "bic",
        ],
        filename: ["type", "invoiceNumber", "creditNoteNumber", "clientName"],
      };
      const re = new RegExp("\\[([a-zA-z]+)\\]", "g");

      let div = document.getElementById(inputName + "Div");
      let matchArr;
      let replacedText = this.invoice[inputName];
      if (inputName == "footerText") {
        this.dueDateUsed = false;
      }

      while ((matchArr = re.exec(this.invoice[inputName])) !== null) {
        if (variables[inputName].indexOf(matchArr[1]) != -1) {
          let value;
          switch (matchArr[1]) {
            case "exclVatTotal":
            case "inclVatTotal":
              value =
                "{{ invgEdCtrl.invoice." + matchArr[1] + " | number : 2 }}";
              break;
            case "structuredCommunication":
              value = "{{ invgEdCtrl.invoice.formatSC() }}";
              break;
            case "dueDate":
              this.dueDateUsed = true;
            case "invoiceDate":
              value =
                "{{ invgEdCtrl.invoice." +
                matchArr[1] +
                " | date : 'shortDate' }}";
              break;
            case "iban":
              value = "{{ invgEdCtrl.invgInfo.iban }}";
              break;
            case "bic":
              value = "{{ invgEdCtrl.invgInfo.bic }}";
              break;
            case "type":
              value =
                "{{ 'INVOICING.SHARED.' + invgEdCtrl.invoice.type | translate }}";
              break;
            case "invoiceNumber":
            case "creditNoteNumber":
              value = "{{ invgEdCtrl.invoice.invoiceNumber }}";
              break;
            case "clientName":
              value = "{{ invgEdCtrl.client.fullName }}";
              break;
          }
          replacedText = replacedText.replace(matchArr[0], value);
        }
      }
      div.innerHTML = replacedText;
      this.$compile(div)(this.$scope);

      if (!this.isNewInvoice && inputName == "filename") {
        let title = document.getElementById("editionTitle");
        title.innerHTML = replacedText;
        this.$compile(title)(this.$scope);
      }
    }

    /**
     * Adds a given variable to the given input, then compiles this input
     * @param variable Name of the variable
     * @param inputName Name of the input
     */
    addVariableToInput(variable: string, inputName: string) {
      let input: HTMLTextAreaElement = document.getElementById(
        inputName + "Input"
      ) as HTMLTextAreaElement;
      if (input.value[input.selectionStart - 1] != undefined) {
        variable = " " + variable;
      }
      if (
        input.value[input.selectionEnd] != undefined &&
        input.value[input.selectionEnd] != " "
      ) {
        variable += " ";
      }

      if (this.invoice[inputName]) {
        let before = this.invoice[inputName].substring(0, input.selectionStart);
        let after = this.invoice[inputName].substring(input.selectionEnd);
        this.invoice[inputName] = before + variable + after;
      } else {
        this.invoice[inputName] = variable;
      }
      this.compileInputVar(inputName);
    }

    /**
     * For each unique vat rates within the invoice lines, sums the values and stores it in the vatRatesData array
     */
    sumVatRates() {
      this.vatRatesData = [];
      let ratesDictionnary = {};
      let cpt = 0;

      this.invoice.line.forEach((aLine) => {
        if (ratesDictionnary[aLine.vatRate] == undefined) {
          this.vatRatesData[cpt] = {
            rate: aLine.vatRate,
            total: 0.0,
            vatTotal: 0.0,
          };
          ratesDictionnary[aLine.vatRate] = cpt;
          cpt++;
        }

        this.vatRatesData[ratesDictionnary[aLine.vatRate]].total += aLine.total;
        this.vatRatesData[ratesDictionnary[aLine.vatRate]].vatTotal +=
          aLine.vatTotal;
      });

      this.vatRatesData.sort((a, b) => a.rate - b.rate); // Sort the array following an ascending order of the vat rates
    }

    /**
     * Checks the validity of most of the form's inputs, calls sendInvoice() when everything's fine, otherwise highlights inputs with an error
     */
    validateForm() {
      if (this.invoice.type == "invoice" || this.invoice.type == "creditNote") {
        if (
          this.invoice.invoiceNumber != null &&
          new RegExp("^[0-9]+$").test(this.invoice.invoiceNumber.toString())
        ) {
          if (this.invoice.invoiceDate != null) {
            if (this.invoice.clientKey) {
              if (this.invoice.line.length > 0) {
                let errorFlag = false;
                this.invoice.line.forEach((aLine, idx) => {
                  if (aLine.description == "" || aLine.description == null) {
                    if (!errorFlag) {
                      // We only focus the first error {
                      document
                        .getElementById("lineDescriptionInput" + idx)
                        .focus();
                    }
                    this.invalidInputBooleans["lineDescription" + idx] = true;
                    errorFlag = true;
                  }
                });

                if (!errorFlag) {
                  if (this.invoice.filename) {
                    this.invoice.invoiceDate.setHours(12, 0, 0, 0); // not zero h ,the back as issue with the save and sometimes save it one hour before ==> so the day before.
                    if (
                      this.invoice.type !== "creditNote" &&
                      this.invoice.dueDate
                    ) {
                      this.invoice.dueDate.setHours(12, 0, 0, 0); // not zero h ,the back as issue with the save and sometimes save it one hour before ==> so the day before.
                    }
                    this.sendInvoice();
                  } else {
                    document.getElementById("filenameInput").focus();
                    this.invalidInputBooleans.filename = true;
                  }
                }
              }
            } else {
              document.getElementById("clientNameInput").focus();
              this.invalidInputBooleans.clientName = true;
            }
          } else {
            this.invalidInputBooleans.invoiceDate = true;
          }
        } else {
          document.getElementById("invoiceNumberInput").focus();
          this.invalidInputBooleans.invoiceNumber = true;
        }
        this.translateInvoices();
      }
    }

    /**
     * Sends the invoice to store in the DB to the back-end
     * Calls addInvoice when the invoice is a new one
     * Calls updateInvoice when the invoice already exists in the DB
     */
    sendInvoice() {
      if (this.invoice.key) {
        // If the "key" property is present, then the invoice already exists in the DB
        this.invoicingService
          .updateInvoice(this.sessionService.session.sessionID, this.invoice)
          .then((response) => {
            this.notification.success(
              this.$translate.instant(
                "INVOICING.EDITION." + this.invoice.type + "Saved"
              )
            );
            this.isEditing = false;
          })
          .catch((error) => {
            this.notification.error(
              this.$translate.instant("INVOICING.EDITION.savingError")
            );
          });
      } else {
        this.invoicingService
          .addInvoice(
            this.sessionService.session.sessionID,
            this.sessionService.session.company.key,
            this.invgInfo.increments.indexOf(this.selectedIncrement),
            this.invoice
          )
          .then((response) => {
            this.notification.success(
              this.$translate.instant(
                "INVOICING.EDITION." + this.invoice.type + "Saved"
              )
            );
            if (!this.$stateParams.isCopy) {
              this.$state.go("websitelayout.headerandmenu.invoicingEdition", {
                type: response.data.type,
                invoice: response.data,
                invoiceKey: response.data.key,
              });
            } else {
              window.location.href =
                "#/invoicing/overview/" + this.invoice.type;
            }
          })
          .catch((error) => {
            this.notification.error(
              this.$translate.instant("INVOICING.EDITION.savingError")
            );
          });
      }
    }

    /**
     * Opens a modal allowing the user to specify mailing information and then sending the invoice by mail
     */
    sendByMail(): void {
      let self = this;
      this.$uibModal
        .open({
          templateUrl: "tpl/website/invoicing/modal/sendMailModal.html",
          controller: "SendMailModalController",
          controllerAs: "mailMdCtrl",
          backdrop: "static",
          resolve: {
            r_client: () => {
              return self.client;
            },
            r_invgInfo: () => {
              return self.invgInfo;
            },
            r_invoiceKey: () => {
              return self.invoice.key;
            },
            r_invoice: () => {
              return self.invoice;
            },
          },
        })
        .result.then((res) => {
          if (res != false) {
            if (res.status == 0) {
              this.notification.success(
                this.$translate.instant("INVOICING.EDITION.invoiceSent")
              );
            } else {
              this.notification.error(
                this.$translate.instant("INVOICING.EDITION.mailError")
              );
            }
          }
        });
    }

    /**
     * Opens the invoice as a PDF in a new tab
     */
    openPDF(): void {
      this.$window.open(
        this.ENV.baseUrl +
          "/invoicingPDFMgmt/getInvoicePDF" +
          "/" +
          this.sessionService.session.sessionID +
          "&" +
          this.invoice.key
      );
    }

    /**
     * Changes the status of the invoice (open, paid)
     */
    updateStatus(): void {
      try {
        let futureStatus = this.invoice.status == 1 ? 2 : 1;
        this.invoicingService
          .updateStatus(
            this.sessionService.session.sessionID,
            this.invoice.key,
            futureStatus
          )
          .then((response) => {
            this.invoice.status = futureStatus;
            this.notification.success(
              this.$translate.instant(
                "INVOICING.EDITION." + this.invoice.type + "Saved"
              )
            );
          });
      } catch (e) {
        console.warn(e);
        this.notification.error(
          this.$translate.instant("INVOICING.EDITION.savingError")
        );
      }
    }

    /**
     * Creates a new credit note with the current invoice's information (except dates and number)
     */
    createCreditNote(): void {
      let toCreditNote = new Invoice();
      angular.copy(this.invoice, toCreditNote);
      toCreditNote.line = [];
      this.invoice.line.forEach((line) => {
        toCreditNote.line.push(
          angular.copy(line as InvoiceLine, new InvoiceLine())
        );
      });
      (toCreditNote.key = null), (toCreditNote.status = 1);
      toCreditNote.invoiceNumber = null;
      toCreditNote.footerText = null;
      this.$state.go("websitelayout.headerandmenu.invoicingEdition", {
        invoiceKey: null,
        type: "creditNote",
        invoice: toCreditNote,
        isCopy: true,
      });
    }

    /**
     * Creates a invoice with the current invoice's information (except dates and number)
     */
    createCopy(): void {
      let toCopy = new Invoice();
      angular.copy(this.invoice, toCopy);
      toCopy.line = [];
      this.invoice.line.forEach((line) => {
        toCopy.line.push(angular.copy(line as InvoiceLine, new InvoiceLine()));
      });
      toCopy.key = null;
      toCopy.status = 1;
      toCopy.invoiceNumber = null;
      this.$state.go("websitelayout.headerandmenu.invoicingEdition", {
        invoiceKey: null,
        type: toCopy.type,
        invoice: toCopy,
        isCopy: true,
      });
    }

    checkFRValues() {
      if (
        (this.invgInfo.legalForm != null ||
          this.invgInfo.legalForm != undefined) &&
        (this.invgInfo.capital != null || this.invgInfo.capital != undefined) &&
        (this.invgInfo.rcs != null || this.invgInfo.rcs != undefined)
      ) {
        return true;
      } else {
        return false;
      }
    }
    checkForSiretValue() {
      if (this.invgInfo.siret != null || this.invgInfo.siret != undefined) {
        return true;
      } else {
        return false;
      }
    }

    /**
     * force the translation of the invoice preview
     * and then set the language to default again to prevent the whole page to be translated
     */
    translateInvoices() {
      //set the language (mandatory)
      this.$translate.use(this.invoice.language).then(() => {
        //for each element of the invoice preview
        for (let i = 0; i < this.invoiceTranslationArray.length; i++) {
          //we force the translation instant in the language and put it in a variable
          this[this.invoiceTranslationArray[i] + "_Translation"] =
            this.$translate.instant(
              "INVOICING.EDITION." + this.invoiceTranslationArray[i],
              null,
              null,
              this.invoice.language
            );
        }
      });
      //after the translation we reestablish the default language
      this.$translate.use(
        this.sessionService.session.member.language.toLowerCase()
      );
    }
  }
}
angular
  .module("app")
  .controller(
    "InvoicingEditionController",
    app.functionality.invoicing.controllers.InvoicingEditionController
  );
