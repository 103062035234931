/// <reference path="../_app.ts" />

module app.model {

    export class Attachement {

        constructor() {
        }

        public name : string;

        public fileKey : string;

        public senderKey : string;

        public sendedToStorages : boolean;

    }

}
