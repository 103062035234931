/// <reference path="../../../_app.ts" />

module app.modules.accountList.controller {

    export class AccountListController {

        protected data: any;
        protected translateKey: string;
        protected childrenExpanded: boolean;

        static $inject = ["$scope", "$translate", "$rootScope"];
        constructor(isolateScope: modules.accountList.directive.IAccountListDirectiveScope,
                    $translate: ngt.ITranslateService, $rootScope) {
           this.childrenExpanded = false;
            this.data = isolateScope.data;
            if (_.isUndefined(isolateScope.translateKey) || isolateScope.translateKey === null) {
                this.translateKey = "";
            } else if ( (_.isUndefined(isolateScope.translateKey) === false && isolateScope.translateKey !== null) && (_.isUndefined(isolateScope.data.keyLanguage) || isolateScope.data.keyLanguage === null ) ) {
                this.translateKey = isolateScope.translateKey;
            }  else if ( (_.isUndefined(isolateScope.translateKey) || isolateScope.translateKey === null ) &&  (_.isUndefined(isolateScope.data.keyLanguage) === false && isolateScope.data.keyLanguage !== null) ) {
                this.translateKey = isolateScope.data.keyLanguage;
            }  else {
                this.translateKey = isolateScope.translateKey + "." + isolateScope.data.keyLanguage;
            }
        }

        expandChildren(): void {
            if(this.data !== null && this.data.accountArr  !== null && this.data.accountArr.length > 0 ) { // cannot expand if no children
                this.childrenExpanded = !this.childrenExpanded;
            }
        }

        showChildren(): boolean {
            return this.childrenExpanded;
        }
    }
}

angular.module("accountListModule").controller("AccountListController", app.modules.accountList.controller.AccountListController);