/// <reference path="../../../_app.ts" />

module app.functionality.upload {

    // to not get typescript error with Document and Window
    interface Document {
        documentMode?: any;
    }
    interface Window {
        StyleMedia?: any;
    }

    import Invoice = app.model.Invoice;
    import SessionService = app.functionality.common.session.SessionService;
    import ENV = app.config.constants.environment.ConstantEnv;
    import REST = app.config.constants.ConstantRestApi;
    import ModalUtil = app.functionality.common.modals.ModalUtil;

    export class PdfViewerModalController {

        public pdfLink: string;
        public showNote: boolean;
        public pdfData: any;
        public pdfTitle: string;
        public test: any;
        private invoice: Invoice;
        private note: string;
        private maxHeightEmbed: any;
        private maxWidthEmbed: any;
        private isIE = false;
        private isEdge = false;
        private nav = "other";
        public editableNote: boolean;

        static $inject = [
            "$uibModalInstance",
            "$window",
            "r_data",
            "r_dataPMF",
            "UploadService",
            "$rootScope",
            "SessionService",
            "ENV",
            "RESTAPI",
            "$http",
            "$sce",
            "ModalUtil",
            "Notification",
            "$sanitize",
            "$translate"
        ];

        constructor(public $uibModalInstance: any,
                    public $window: angular.IWindowService,
                    r_data: any,
                    r_dataPMF: any,
                    private uploadService: app.functionality.upload.UploadService,
                    private $rootScope: ng.IRootScopeService,
                    private sessionService: SessionService,
                    private env: ENV,
                    private restApi: REST,
                    private $http: ng.IHttpService,
                    private $sce: ng.ISCEService,
                    private modalUtil: ModalUtil,
                    private notification: any,
                    private $sanitize: ng.sanitize.ISanitizeService,
                    private $translate: ngt.ITranslateService) {
            let self = this;
            let navigator: string = "navigator";
            if(window[navigator].msSaveOrOpenBlob) {
                this.isIE = true;
            }

            // if we access the pdf from toSend, we can edit the note. if not, the note textArea is disabled
            if (r_data != null && r_data.invoice != null && r_data.invoice.editableNote != null) {
                this.editableNote = true;
            } else {
                this.editableNote = false;
            }

            let height = $window.innerHeight;
            height = height * 15;
            height = height / 100;
            this.maxHeightEmbed = $window.innerHeight - height;

            let width = $window.innerWidth;
            width = width * 40;
            width = width / 100;
            this.maxWidthEmbed = $window.innerWidth - width;

            $window.addEventListener("resize", this.resize());

            // Internet Explorer 6-11
            let documentMode: string = "documentMode";
            let ie = document[documentMode];
            // Edge 20+
            let styleMedia = "StyleMedia";
            this.isEdge = !ie && window[styleMedia];

            let userAgent = $window.navigator.userAgent;
            let browsers = {android: /android/i,chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /internet explorer/i};

            for(let key in browsers) {
                if (browsers[key].test(userAgent)) {
                    this.nav = key;
                    break;
                }
            }

            if(r_dataPMF !== null && r_dataPMF !== undefined) {
                // PMF FILE
               // self.pdfData = r_dataPMF.data;
                this.pdfLink =  r_dataPMF;
                this.showNote = false;
                //this.isIE = true;
            } else {
                // INVOICE FILE
                this.showNote = true;
                this.invoice = r_data.invoice;
                this.pdfTitle = this.invoice.name;

                //Create another version to compare the old and new note
                this.note = this.invoice.note;

                // if invoice is already sent, use GDriveFileMgr instead of dbFileMgr
                if (this.invoice.sentStatus >= 2 || angular.isDefined(this.invoice.thumbnailLink) || angular.isDefined(this.invoice.thumbnailS3Link) || (angular.isDefined(this.invoice.s3Path)&&this.invoice.s3Path !== null ) ) {
                    // why thumbnailLink and thumbnailS3Link ? they are specified in the search result, and S3Link has a different structure
                    if(angular.isDefined(this.invoice.thumbnailS3Link)) {
                        this.pdfLink = env.baseUrl +
                            "/" + restApi.services.GDriveFileMgr +
                            "?sessionID=" + sessionService.session.sessionID +
                            "&companyKey=" + sessionService.session.company.key +
                            "&fileName=" + encodeURI(this.invoice.title) +
                            "&invoiceKey=" + this.invoice.invoiceKey +
                            "&isAttachment=false";
                    } else {
                        this.pdfLink = env.baseUrl +
                            "/" + restApi.services.GDriveFileMgr +
                            "?sessionID=" + sessionService.session.sessionID +
                            "&companyKey=" + sessionService.session.company.key +
                            "&fileName=" + encodeURI(this.invoice.name) +
                            "&fileKey=" + this.invoice.fileKey +
                            "&isAttachment=false";
                    }

                } else if(this.isIE === true || this.nav === "safari" || this.nav === "android") {
                    this.pdfLink = env.baseUrl +
                        "/" + restApi.services.DBFile +
                        "?sessionID=" + sessionService.session.sessionID +
                        "&companyKey=" + sessionService.session.company.key +
                        "&fileKey=" + this.invoice.fileKey +
                        "&fileName=" + encodeURI(this.invoice.name)+
                        "&isIE=false";
                } else {
                    this.pdfLink = env.baseUrl +
                        "/" + restApi.services.DBFile +
                        "?sessionID=" + sessionService.session.sessionID +
                        "&companyKey=" + sessionService.session.company.key +
                        "&fileKey=" + this.invoice.fileKey +
                        "&fileName=" + encodeURI(this.invoice.name)+
                        "&isIE=false";

                    $http({
                        method: "GET",
                        url: this.pdfLink,
                        headers: { "Accept": "application/pdf" },
                        responseType: "arraybuffer"
                    }).then(function(response) {

                        self.pdfData = response.data;
                    }).catch(function(error) {
                        self.modalUtil.status("MODAL.PDF_ERROR.notAvailable", "MODAL.PDF_ERROR.notAvailableTitle");
                        self.pdfData = "Error";
                        self.close();
                    });
                }
            }
        }

        /**
         * If the user change the note,
         * ask to the server is the note is updated
         */
        public saveTextArea() {
            let self = this;
            if ( this.note !== this.invoice.note ) {
                if (this.note === "") {
                    self.uploadService.deleteNote(self.invoice.key).then(function(response) {
                        self.invoice.note = undefined;
                        self.note = undefined;
                        return;
                    }).catch(function(error) {
                        console.error(error);
                    });
                } else {
                        self.uploadService.updateNote(this.invoice.key, encodeURI(this.note))
                        .then(function(data) {
                            if ( data.plain().status === 0 ) {
                                if ( self.note === "" ) {
                                    self.invoice.note = undefined;
                                    self.notification.primary(self.$translate.instant("NOTIFICATIONS.UPLOAD.deletedNote"));
                                } else {
                                    //Update note
                                    self.invoice.note = self.note;
                                    self.notification.primary(self.$translate.instant("NOTIFICATIONS.UPLOAD.savedNote"));
                                }
                            } else {
                                self.notification.error(self.$translate.instant("NOTIFICATIONS.UPLOAD.errorOnNote"));
                                throw new Error(status);
                            }
                        })
                        .catch(function(error) {
                            console.error("error ocurred => " + error);
                        });
                }
            }
        }

        public close() {
            this.$uibModalInstance.close();
        }

        private resize() {
            let self = this;
            return function (e): void {
                let height = self.$window.innerHeight;
                height = height * 15;
                height = height / 100;
                self.maxHeightEmbed = self.$window.innerHeight - height;

                let width = self.$window.innerWidth;
                width = width * 20;
                width = width / 100;
                self.maxWidthEmbed = self.$window.innerWidth - width;
            };
        }
    }
}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("PdfViewerModalController", app.functionality.upload.PdfViewerModalController);
