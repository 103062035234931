/// <reference path="../../_app.ts" />

module app.functionality.ticket {

    export class TicketService {
        private rest: restangular.IService;

        static $inject =  [
            "RestService",
            "RESTAPI",
            "$sanitize",
            "$q"
        ];

        constructor(restService: app.functionality.common.rest.RestService,
                    private RESTAPI: app.config.constants.ConstantRestApi,
                    private $sanitize: ng.sanitize.ISanitizeService,
                    private $q: ng.IQService) {
            this.rest = restService.getRoot();
        }

        //************ REAL SERVER METHODS ************

        getAllTicketSenders = (sessionID: string, companyKey: string, fromStatus: number, toStatus: number,
                               fromProcessStatus: number, toProcessStatus: number): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.ticket.community)
                .all(this.RESTAPI.services.ticket.ticketSenders)
                .one(sessionID + "&" + companyKey + "&" + fromStatus + "&" + toStatus + "&" + fromProcessStatus + "&" + toProcessStatus)
                .get();
        }

        getTickets = (sessionID: string, companyKey: string, fromStatus: number, toStatus: number,
                      fromProcessStatus: number, toProcessStatus: number, nbTicketsToSkip: number,
                      nbTicketsToView: number, ascending: boolean): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.ticket.community)
                .all(this.RESTAPI.services.ticket.getTickets)
                .one(sessionID + "&" + companyKey + "&" + fromStatus + "&" + toStatus + "&" +
                    fromProcessStatus + "&" + toProcessStatus + "&" + nbTicketsToSkip + "&" +
                    nbTicketsToView + "&" + ascending)
                .get();
        }

        filterTicketsByUser = (sessionID: string, companyKey: string, fromStatus: number, toStatus: number,
                      fromProcessStatus: number, toProcessStatus: number, nbTicketsToSkip: number,
                      nbTicketsToView: number, ascending: boolean, showAnsweredCat: number, userKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.ticket.community)
                .all(this.RESTAPI.services.ticket.fitlerTickets)
                .one(sessionID + "&" + companyKey + "&" + fromStatus + "&" + toStatus + "&" +
                    fromProcessStatus + "&" + toProcessStatus + "&" + nbTicketsToSkip + "&" +
                    nbTicketsToView + "&" + ascending + "&" + showAnsweredCat + "&" + userKey)
                .get();
        }

        getTicketAnswers = (sessionID: string, companyKey: string,
                               ticketKey: number, ticketAnswerKeyArr: Array<string>): ng.IPromise<any> => {
            let request = this.rest.all(this.RESTAPI.services.ticket.community)
                .all(this.RESTAPI.services.ticket.ticketAnswers);

            return request.customPOST({
                    sessionID: sessionID,
                    companyKey: companyKey,
                    ticketKey: ticketKey,
                    ticketAnswerKeyArr: ticketAnswerKeyArr
            });
        }

        getTicket = (sessionID: string, companyKey: string,
                            ticketKey: number): ng.IPromise<any> => {
                       return this.rest.all(this.RESTAPI.services.ticket.community)
                .all(this.RESTAPI.services.ticket.getTicket)
                .one(sessionID + "&" + companyKey + "&" + ticketKey)
                .get();

        }

        updateStatusTickets = (sessionID: string, companyKey: string,
                               newStatus: number, listTicketSelected: any): ng.IPromise<any> => {
            let request = this.rest.all(this.RESTAPI.services.ticket.community)
                .all(this.RESTAPI.services.ticket.statusTicket);

            return request.customPUT({
                sessionID: sessionID,
                companyKey: companyKey,
                newStatus: newStatus,
                listTicketSelected: listTicketSelected
            });
        }

        addTicketAnswer = (sessionID: string, companyKey: string,
                               ticketKey: string, ticketAnswer: any): ng.IPromise<any> => {
            let request = this.rest.all(this.RESTAPI.services.ticket.community)
                .all(this.RESTAPI.services.ticket.addAnswer);

            return request.customPUT({
                sessionID: sessionID,
                companyKey: companyKey,
                ticketKey: ticketKey,
                ticketAnswer: ticketAnswer
            });
        }

        addTicket = (sessionID: string, ticket: any): ng.IPromise<any> => {
            let request = this.rest.all(this.RESTAPI.services.ticket.community)
                .all(this.RESTAPI.services.ticket.addTicket);

            return request.customPUT({
                sessionID: sessionID,
                ticket: ticket
            });
        }

        updateNotifyUser = (sessionID: string, ticketKey: string, memberKey: string, remove: boolean): ng.IPromise<any> => {
            let request = this.rest.all(this.RESTAPI.services.ticket.community)
                .all(this.RESTAPI.services.ticket.updateNotifyUser)
                .one(sessionID + "&" + ticketKey + "&" + memberKey + "&" + remove);

            // why is it a POST on server side? we pass the data on the URL
            // return request.customPOST({
            //     sessionID: sessionID,
            //     ticketKey: ticketKey,
            //     memberKey: memberKey,
            //     remove: remove
            // });

            return request.customPOST({});
        }
        
        getNbTicketClientHasToProcess = (sessionID: string, companyKey: string, status: number,
                                         accFlag: number, userFlag: number): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.ticket.community)
                .all(this.RESTAPI.services.ticket.getNbTicketClientHasToProcess)
                .one(sessionID + "&" + companyKey + "&" + status + "&" + accFlag + "&" + userFlag)
                .get();
        }

        sendFeedback = (sessionID: string, feedback: string): ng.IPromise<any> => {
            console.error(feedback);
            let request = this.rest.all(this.RESTAPI.services.scenario.base)
                .all(this.RESTAPI.services.scenario.feedback).one("?sessionID="+sessionID + "&feedback=" + encodeURI(feedback));
            return request.customPUT({});
        }
    }
}

angular.module("app").service("TicketService",  app.functionality.ticket.TicketService);
