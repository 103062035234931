/// <reference path="../../../_app.ts" />

module app.modules.lineargraphs.util {

    interface EventWithCurrentTarget extends Event, d3.BaseEvent {
        currentTarget: any;
    }
    
    export class LineChartServiceUtil {

        static $inject = [
            "$filter"
        ];

        constructor(private $filter: ng.IFilterService) {
        }
    
        getDateFormatter = () => {
            return d3.time.format("%b %Y");
        }
                
        getFirstDayOfMonth = (year: number, month: number) => {
            return new Date(year, month, 1);
        }

        getLastDayOfMonth = (year: number, month: number) => {
            return new Date(year, month + 1, 0);
        }

        getDateByDay = (year: number, month: number, day: number) => {
            return new Date(year, month, day);
        }

        removeParentSvg = (svg: d3.Selection<d3.selection.Group>, elem) => {
            svg.remove();
        }
        
        cleanSvg = (svg: d3.Selection<d3.selection.Group>) => {
            svg.selectAll("*").remove();
        }

        cleanChartTitle = (svg: d3.Selection<d3.selection.Group>) => {
            svg.select(".xAxisTitle").remove();
        }

        // setTextXAxis = (svg: d3.Selection<d3.selection.Group>, width: number, height: number, text: string) => {
        //
        //     svg.append("text")
        //         .attr("x", width )
        //         .attr("y", height)
        //         .attr("class", "xAxisTitle")
        //         .style("text-anchor", "middle")
        //         .text(text);
        // }
            
        getTimeScale = (): d3.time.Scale<any, any> => {
            return d3.time.scale();
        }
        
        getLinearScale = (): d3.scale.Linear<number, number> => {
            return d3.scale.linear();
        }

        rangeScale = (scale: any, lowerRange: number, upperRange: number) => {
            return scale.range([lowerRange, upperRange]);
        }

        getAxis = (scale: any, orientation: string, ticks: any): d3.svg.Axis => {
            let axis: d3.svg.Axis = d3.svg.axis().scale(scale).orient(orientation);
            if ( ticks >= 0 ) {
                axis.ticks(ticks);
            }
            return axis;
        }
        
        setAxisTickFormat = (axis: d3.svg.Axis, formatter: any) => {
            // axis.tickFormat(formatter).tickPadding(10);
            axis.tickFormat(formatter);
        }

        defineLine = (xScale, yScale, propertyX, propertyY) => {
            return  d3.svg.line()
                        .defined((d) => { 
                            return !isNaN(d[propertyY]);
                        })
                        .x((d) => {
                            return xScale(new Date(d[propertyX]));
                        })
                        .y((d) => {
                            return yScale(d[propertyY]);
                        });
        }
        
        defineArea = (xScale, yScale, propertyX: string, propertyY: string, height: number) => {
            return d3.svg.area()
                    .defined((d) => { 
                        return !isNaN(d[propertyY]); 
                    })
                    .x((d) => { 
                        return xScale(d[propertyX]); 
                    })
                    .y0(height)
                    .y1((d) => { 
                        return yScale(d[propertyY]); 
                    });
        }
        
        defineSvg = (svg: d3.Selection<d3.selection.Group>, elem): d3.Selection<d3.selection.Group> => {
            if ( _.isUndefined(svg)) {
                svg = d3.select(elem[0]).append("svg");
            } else {
                this.cleanSvg(svg);
            }

            return  svg;
        }
        
        defineCanvas = (svg: d3.Selection<d3.selection.Group>, 
                        width: number, height: number, margin: {x: number, y: number})
                        : d3.Selection<d3.selection.Group> => {
                      
            return  svg.attr("width", width)
                       .attr("height", height)
                       .append("g")
                       .attr("transform", "translate(" + margin.x + "," + margin.y + ")");
        }

        setDomainTimeScale = (scale, data, propertyDate: string) => {
            scale.domain(d3.extent(data,  (d) => {
                return new Date(d[propertyDate]);
            }));
        }

        setDomainInLinearScale = (scale, data, property: string) => {
            scale.domain([d3.min(data, (d) => {
                if (isNaN(d[property])) {
                    return 0;
                }
                if(d[property] > 0) {
                    return 0;
                } else {
                    return d[property];
                }

            }), d3.max(data, (d) => {
                if (isNaN(d[property])) {
                    return -3;
                }

                return d[property];
            })]);
        }

        // addTickSpacing = (svg: d3.Selection<d3.selection.Group>) => {
        //     sv
        // }

        addCircleAndTooltip = (selectorTooltip: string, svg: d3.Selection<d3.selection.Group>, 
                               data, xScale, yScale, propertyX: string, propertyY: string, dateFormat) => {
            let self = this;
            svg
            .selectAll("dot")
            .data(data)
            .enter().append("circle")
            .attr("r", 8)
            .style("cursor","pointer")
            .style("visibility", (d) => {
                if (isNaN(d[propertyY])) {
                    return "hidden";   
                }

                return "";
            })
            .attr("cx", (d) => {
                return xScale(new Date(d[propertyX]));
            })
            .attr("cy", (d) => {
                if (isNaN(d[propertyY])) {
                    return 0;   
                }
                return yScale(d[propertyY]);
            })
            .on("mouseover", () => {
                return d3.select(selectorTooltip).style("visibility", "visible"); //making the tooltip visible
            })
            .on("mousemove", (d: {date: Date, amount: number})  => {
                let evt = <EventWithCurrentTarget>d3.event;

                d3.select(selectorTooltip)
                    .style("top",  (d3.mouse(evt.currentTarget)[1] - 15) + "px")
                    .style("left", (d3.mouse(evt.currentTarget)[0] + 0) + "px");
                d3.select(selectorTooltip).select("#ttcomment").text( () => {
                    return  self.$filter("date")(d.date,"dd/MM/yyyy") + " : " + self.$filter("number")(d.amount,0) + " €";
                });

                return;
            })
            .on("mouseout", () => {
                return d3.select(selectorTooltip).style("visibility", "hidden"); //hidding the tooltip
            });
        }

        addXAxis = (svg: d3.Selection<d3.selection.Group>, xAxis, translateY, translateX) => {
            return svg.append("g")
                .attr("class", "x axis")
                .attr("y", 13)
                .attr("transform", "translate(0," + translateX + "),translate(0," + translateY + ")")
                .style("font-weight", "bold")
                .call(xAxis);
        }

        addXAxisText = ( callxAxis, text: string, translateX: number) => {
            if (translateX !== 0) {
                callxAxis.append("text")
                    .attr("x", -12)
                    .attr("y", 0)
                    .attr("dy", ".71em")
                    .style("text-anchor", "end")
                    .style("font-weight", "bold")
                    .text("0");
            }
            callxAxis.append("text")
                .attr("x", -12)
                .attr("y", -translateX)
                .attr("dy", ".71em")
                .style("text-anchor", "end")
                .style("font-weight", "bold")
                .text(text);
        }

        addYAxis = (svg: d3.Selection<d3.selection.Group>, yAxis) => {
            return svg.append("g")
                .attr("class", "y axis")
                .call(yAxis);
        }

        addYAxisText = (callyAxis, text: string) => {
            callyAxis.append("text")
            .attr("x", -12)
            .attr("y", 6)
            .attr("dy", ".71em")
            .style("text-anchor", "end")
            .style("font-weight", "bold")
            .text(text);
        }

        //	return first letter of month in uppercase
        getFormaterAxisX = (dataIn, propertyX: string, propertyY: string) => {
            return (d) => {
                let amount = 0;
                let dM = moment(d);

                _(dataIn).forEach((data) => {
                    let dateM = moment(data[propertyX] );
                    if (dM.isSame(dateM)) {
                        amount = data[propertyY];
                    }
                });

                /*if (isNaN(amount)) {
                    return "";
                }*/

                // commented line to delete month letter on the x axis
                // return dateFormat(d).charAt(0).toUpperCase();
                return "";
            };
        }

        maxAmount = (dataIn) => {
            let maxAmount = 0;

            _(dataIn).forEach((data) => {
                if (data.amount !== undefined && data.amount > maxAmount) {
                    maxAmount = data.amount;
                }
            });

            return maxAmount;
        }

        minAmount = (dataIn) => {
            let maxAmount = 0;

            _(dataIn).forEach((data) => {
                if (data.amount !== undefined && data.amount < maxAmount) {
                    maxAmount = data.amount;
                }
            });

            return maxAmount;
        }

        createGapInDataAtStart = (data) => {
            if (angular.isUndefined(data) || data === null || angular.isUndefined(data[0])) {
            } else {
                let dateFirstM = moment(data[0].date);
                let dateOneMonthBeforeM = dateFirstM.clone().subtract(1, "months").toDate();
                data.unshift({date: dateOneMonthBeforeM, amount: "gap"});
            }
            return data;
        }

        drawLineChart = (svg, lineDef, data) => {
            svg.append("path")
                .attr("class", "line")
                .attr("d", lineDef(data));
        }

        drawLineAreaChart = (svg, areaDef, data) => {
            svg.append("path")
                .datum(data)
                .attr("class", "area")
                .attr("d", areaDef);
        }

        getWidthAndHeightFromElementById = (elementId: string) => {
            let element: d3.Selection<any> = d3.select("#" + elementId);

            let htmlElement: HTMLElement = <HTMLElement>element[0][0];
            if (!_.isUndefined(htmlElement) && !_.isNull(htmlElement)) {
                let withAndHeight = {id: elementId, width: 0, height: 0};
                withAndHeight.width = htmlElement.clientWidth;
                withAndHeight.height = htmlElement.clientHeight;
                return withAndHeight;
            }
        }

        getWidthFromElementById = (elementId: string) => {
            return this.getWidthAndHeightFromElementById(elementId).width;
        } 

        getHeightFromElementById = (elementId: string) => {
            return this.getWidthAndHeightFromElementById(elementId).height;
        }

    }

}

angular.module("linearChartModule").service("LineChartServiceUtil", app.modules.lineargraphs.util.LineChartServiceUtil);