/// <reference path="../../_app.ts" />

module app.functionality.treasury {
  
  export class TreasuryService {
    private rest: restangular.IService;
    
    static $inject =  ["RestService", "RESTAPI"];
    constructor(restService: app.functionality.common.rest.RestService, private RESTAPI: app.config.constants.ConstantRestApi) {
      this.rest = restService.getRoot();
    }
    
    getTreasuryByCompanyKey = (companyKey: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.treasury.base).one("companyKey", companyKey).get();
    }

    getWcrByCompanyKey = (companyKey: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.treasury.wcr).one("companyKey", companyKey).get();
    }

    getWcByCompanyKey = (companyKey: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.treasury.wc).one("companyKey", companyKey).get();
    }

    getBankBalanceByCompanyKey = (companyKey: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.treasury.bankBalance).one("companyKey", companyKey).get();
    }

    getCashburnByCompanyKey = (companyKey: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.treasury.cashburn).one("companyKey", companyKey).get();
    }

    getBankBalanceDataBySelect = (account: any, year: any, month: any): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.treasury.bankBalance).one("account", account)
          .one("year", year).one("month", month).get();
    }

    getDetailWCR = (sessionID: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.treasury.treasuryKPI).all(this.RESTAPI.services.treasury.wcr)
          .one(sessionID).get();
    }

    getDetailWC = (sessionID: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.treasury.treasuryKPI).all(this.RESTAPI.services.treasury.wc)
          .one(sessionID).get();
    }

    getDetailCashburn = (sessionID: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.treasury.cashburn).all(this.RESTAPI.services.treasury.getDetail)
          .one(sessionID).get();
    }

    getBankGraph = (sessionID: string, destAccount: string, requestedYear: number): ng.IPromise<any> => {
      let destAccountCust = destAccount;
      if(destAccount === undefined) {
        return this.rest.all(this.RESTAPI.services.treasury.bank).all(this.RESTAPI.services.treasury.getGraphData)
            .one(sessionID + "&&" + requestedYear).get();
      } else {
        return this.rest.all(this.RESTAPI.services.treasury.bank).all(this.RESTAPI.services.treasury.getGraphData)
            .one(sessionID + "&" + destAccountCust + "&" + requestedYear).get();
      }
    }

    getBankAccounts = (sessionID: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.treasury.bank).all(this.RESTAPI.services.treasury.bankAccounts)
          .one(sessionID).get();
    }

    getDashboardTreasury = (sessionID: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.treasury.controller)
          .all(this.RESTAPI.services.treasury.dashboardTreasury)
          .one(sessionID).get();
    }
     
  }
}

angular.module("app").service("TreasuryService",  app.functionality.treasury.TreasuryService);