/// <reference path="../../_app.ts" />

module app.functionality.search {
  export class SearchService {
    private rest: restangular.IService;
    public clientsThird;
    public suppliersThird;
    public iconBranding;

    static $inject = ["RestService", "RESTAPI"];
    constructor(
      restService: app.functionality.common.rest.RestService,
      private RESTAPI: app.config.constants.ConstantRestApi
    ) {
      this.rest = restService.getRoot();
    }

    getIconBranding = (
      sessionID: string,
      accFirmKey: string
    ): ng.IPromise<any> => {
      this.iconBranding = this.rest
        .all(this.RESTAPI.services.branding.base)
        .all(this.RESTAPI.services.branding.getIcon)
        .one(sessionID + "&" + accFirmKey)
        .get();
      return this.iconBranding;
    };

    getAllThirds = (
      sessionID: string,
      type: number,
      mustHadBudgetThird: boolean
    ): ng.IPromise<any> => {
      if (type === 1) {
        // clients
        if (this.clientsThird === undefined) {
          this.clientsThird = this.rest
            .all(this.RESTAPI.services.search.cashflow)
            .all(this.RESTAPI.services.search.allThirds)
            .one(sessionID + "&" + type + "&" + mustHadBudgetThird)
            .get();
          return this.clientsThird;
        } else {
          return this.clientsThird;
        }
      } else if (type === 2) {
        // supplier
        if (this.suppliersThird === undefined) {
          this.suppliersThird = this.rest
            .all(this.RESTAPI.services.search.cashflow)
            .all(this.RESTAPI.services.search.allThirds)
            .one(sessionID + "&" + type + "&" + mustHadBudgetThird)
            .get();
          return this.suppliersThird;
        } else {
          return this.suppliersThird;
        }
      } else if (type === 3) {
        let allThird;
        if (this.clientsThird === undefined) {
          this.clientsThird = this.rest
            .all(this.RESTAPI.services.search.cashflow)
            .all(this.RESTAPI.services.search.allThirds)
            .one(sessionID + "&" + type + "&" + mustHadBudgetThird)
            .get();
          allThird = this.clientsThird.plain().data;
        } else {
          allThird = this.clientsThird.plain().data;
        }

        if (this.suppliersThird === undefined) {
          this.suppliersThird = this.rest
            .all(this.RESTAPI.services.search.cashflow)
            .all(this.RESTAPI.services.search.allThirds)
            .one(sessionID + "&" + type + "&" + mustHadBudgetThird)
            .get();
          allThird += this.suppliersThird.plain().data;
        } else {
          allThird += this.suppliersThird.plain().data;
        }
        return allThird;
      }
    };

    getAccountingEntriesByThird = (
      sessionID: string,
      isfirstSearch: boolean,
      type: number,
      reference: string,
      vcs: string,
      minAmount: number,
      maxAmount: number,
      startDate: any,
      endDate: any,
      bookyear: number
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.search.historic)
        .one(this.RESTAPI.services.search.accountingEntries)
        .get({
          sessionID: sessionID,
          isfirstSearch: isfirstSearch,
          type: type,
          reference: reference,
          vcs: vcs ? vcs : "",
          minAmount: minAmount,
          maxAmount: maxAmount,
          startDateStr: startDate,
          endDateStr: endDate,
          bookyear: bookyear,
        });
    };

    getAccountingEntriesWithAdvancedSearch = (
      sessionID: string,
      idQuery: boolean,
      type: number,
      reference: string,
      vcs: string,
      minAmount: number,
      maxAmount: number,
      startDate: any,
      endDate: any,
      bookyear: number
    ): ng.IPromise<any> => {
      if (!angular.isDefined(startDate)) {
        startDate = "";
      }
      if (!angular.isDefined(endDate)) {
        endDate = "";
      }
      if (!angular.isDefined(reference)) {
        reference = "";
      }
      if (!angular.isDefined(vcs)) {
        vcs = "";
      }
      if (!angular.isDefined(minAmount)) {
        minAmount = 0;
      }
      if (!angular.isDefined(maxAmount)) {
        maxAmount = 0;
      }

      return this.rest
        .all(this.RESTAPI.services.search.historic)
        .one(this.RESTAPI.services.search.advancedAccountingEntries)
        .get({
          sessionID: sessionID,
          idQuery: idQuery ? idQuery : "",
          type: type,
          third: reference,
          vcs: vcs,
          minAmount: minAmount,
          maxAmount: maxAmount,
          startDateStr: startDate,
          endDateStr: endDate,
          bookyear: bookyear,
        });
    };
  }
}

angular
  .module("app")
  .service("SearchService", app.functionality.search.SearchService);
