/// <reference path="../../_app.ts" />

module app.functionality.liquidity {
  export class ReceivableAndDebtorController {
    public gridData: any;
    public r_third: any;
    public r_typeOfThird: any;
    public pageTitle: string;
    public gridOptions: any;

    private sessionID: string;
    private companyKey: string;

    public SumRange015;
    public SumRange1530;
    public SumRange3090;
    public SumRange90;
    public SumAmountTotal;

    static $inject = [
      "r_gridData",
      "r_title",
      "r_thirds",
      "r_typeOfThird",
      "$scope",
      "ModalService",
      "$window",
      "SessionService",
      "ENV",
      "$uibModal",
      "$translatePartialLoader",
      "AccountTools",
    ];

    constructor(
      r_gridData: any,
      r_title: string,
      r_thirds: string,
      r_typeOfThird: string,
      public $scope: ng.IScope,
      private modalService: app.functionality.common.modals.ModalService,
      public $window: angular.IWindowService,
      private sessionService: app.functionality.common.session.SessionService,
      private ENV: app.config.constants.environment.ConstantEnv,
      private $uibModal: any,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private accountTools: app.functionality.common.utils.AccountTools
    ) {
      $translatePartialLoader.addPart("customer/liquidity");
      this.gridData = r_gridData;
      this.r_third = r_thirds;
      this.r_typeOfThird = r_typeOfThird;
      this.updateOperationTotal();
      this.pageTitle = r_title;
      this.gridOptions = this.setGridOptions(
        this.gridData,
        this.pageTitle,
        $scope
      );
      this.sessionID = this.sessionService.session.sessionID;
      this.companyKey = this.sessionService.session.company.key;
    }

    /*
     *
     * Sums on each line inside the grid
     *
     * */
    public updateOperationTotal() {
      let totalToUpdate = 0;
      let totalRange015 = 0;
      let totalRange1530 = 0;
      let totalRange3090 = 0;
      let totalRange90 = 0;
      let amountTotal = 0;

      for (let i = 0; i < this.gridData.length; i++) {
        // if category
        if (this.gridData[i].thirdCat === true) {
          this.gridData[totalToUpdate].range015 = totalRange015;
          this.gridData[totalToUpdate].range1530 = totalRange1530;
          this.gridData[totalToUpdate].range3090 = totalRange3090;
          this.gridData[totalToUpdate].range90 = totalRange90;
          this.gridData[totalToUpdate].amount = amountTotal;

          totalRange015 = 0;
          totalRange1530 = 0;
          totalRange3090 = 0;
          totalRange90 = 0;
          amountTotal = 0;
          totalToUpdate = i;
        }
        totalRange015 = totalRange015 + this.gridData[i].range015;
        totalRange1530 = totalRange1530 + this.gridData[i].range1530;
        totalRange3090 = totalRange3090 + this.gridData[i].range3090;
        totalRange90 = totalRange90 + this.gridData[i].range90;
        amountTotal = amountTotal + this.gridData[i].amount;

        // if we're at the end of the grid, we update the last cat
        if (i === this.gridData.length - 1) {
          this.gridData[totalToUpdate].range015 = totalRange015;
          this.gridData[totalToUpdate].range1530 = totalRange1530;
          this.gridData[totalToUpdate].range3090 = totalRange3090;
          this.gridData[totalToUpdate].range90 = totalRange90;
          this.gridData[totalToUpdate].amount = amountTotal;
        }
      }
    }

    /*
     *
     * Sums on bottom line of the grid
     *
     * */
    private updateGridSum(data) {
      this.SumRange015 = 0;
      this.SumRange1530 = 0;
      this.SumRange3090 = 0;
      this.SumRange90 = 0;
      this.SumAmountTotal = 0;

      for (let p of data) {
        if (p.$$treeLevel === 0) {
          this.SumRange015 = this.SumRange015 + p.range015;
          this.SumRange1530 = this.SumRange1530 + p.range1530;
          this.SumRange3090 = this.SumRange3090 + p.range3090;
          this.SumRange90 = this.SumRange90 + p.range90;
          this.SumAmountTotal = this.SumAmountTotal + p.amount;
        }
      }
    }

    public launchInfoModal(row) {
      this.modalService.createInfoModal(
        row,
        "tpl/website/modals/infoModal.html",
        "InfoModalController",
        "info",
        "sm"
      );
    }

    public launchDetailModal(row) {
      this.modalService.createDetailModal(
        row,
        "tpl/website/modals/detailModal.html",
        "DetailModalController",
        "detail"
      );
    }

    public openPDF(row) {
      let sessionID = this.sessionService.session.sessionID;
      let companyKey = this.sessionService.session.company.key;
      let accConcerned = row.accountingEntryArr[row.idAcc];

      this.$window.open(
        this.ENV.baseUrl +
          "/FileMgr?sessionID=" +
          sessionID +
          "&companyKey=" +
          companyKey +
          "&journal=" +
          accConcerned.journalType +
          "&period=" +
          accConcerned.period +
          "&doc=" +
          accConcerned.docNumber +
          "&bookyear=" +
          accConcerned.bookyear,
        "_blank"
      );
    }

    public launchMessageModal(row) {
      this.modalService.createMessageModal(
        row,
        "tpl/website/modals/messageModal.html",
        "MessageModalController",
        "message"
      );
    }

    public newTicket(row) {
      // to change... obliged introduction of "entity" attribute to not make breaking change in newTicketModalController.
      let acc = {
        entity: row,
      };
      if (angular.isDefined(this.r_typeOfThird)) {
        acc.entity.typeOfThird = this.r_typeOfThird;
      }
      if (angular.isDefined(acc.entity.thirdFullName) == false) {
        for (let i = 0; i < this.r_third.length - 1; i++) {
          if (this.r_third[i].reference === acc.entity.third) {
            acc.entity.thirdFullName = this.r_third[i].fullName;
            break;
          }
        }
      }

      this.$uibModal.open({
        templateUrl: "tpl/website/modals/newTicketModal.html",
        controller: "NewTicketModalController",
        controllerAs: "ticket",
        size: "md",
        backdrop: "static",
        keyboard: false,
        resolve: {
          r_data: function () {
            return {
              row: acc,
            };
          },
        },
      });
    }

    //1, vente, 2 achat, 3 mouvement financier, (else) 4 op diverse
    public getIconType(accountingEntry) {
      if (
        this.accountTools.isIncludeInRootArr(accountingEntry.account, [
          "600",
          "440",
        ])
      ) {
        return 2;
      } else if (
        this.accountTools.isIncludeInRootArr(accountingEntry.account, [
          "700",
          "400",
        ])
      ) {
        return 1;
      } else if (
        this.accountTools.isIncludeInRootArr(accountingEntry.account, [
          "55",
          "57",
        ])
      ) {
        return 3;
      } else {
        return 4;
      }
    }

    public setGridOptions(data, title, $scope) {
      this.updateGridSum(data);

      return {
        columnDefs: [
          {
            displayName: title,
            headerCellFilter: "translate",
            field: "comment",
            headerCellClass: "header-title text-center",
            headerCellTemplate:
              '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
              "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
              "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
              '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" ' +
              'col-index="renderIndex" title="TOOLTIP"><span class="ui-grid-header-cell-label" ' +
              "ui-grid-one-bind-id-grid=\"col.uid + '-header-text'\">{{ col.displayName CUSTOM_FILTERS }}</span>" +
              "</div><div ui-grid-filter></div></div>",
            cellTemplate: "tpl/grid/receivableAndDebtor/comment.html",
            footerCellTemplate:
              "<div class='ui-grid-cell-contents header-title'>{{'LIQUIDITY.total' | translate}}</div>",
          },
          {
            displayName: "LIQUIDITY.OVERVIEW.range015",
            headerCellFilter: "translate",
            width: "12%",
            field: "range015",
            headerCellClass: "header-title text-right",
            headerCellTemplate:
              '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
              "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
              "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
              '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" ' +
              'title="TOOLTIP"><span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ col.displayName CUSTOM_FILTERS }}</span>' +
              "</div><div ui-grid-filter></div></div>",
            cellFilter: "number:2",
            cellTemplate:
              "<div class='ui-grid-cell-contents text-right' ng-class='row.entity.thirdCat ? \"rd-category\" : \"rd-element\"'>" +
              "<span ng-show='row.entity.range015 !== 0 || row.entity.thirdCat === true'>{{COL_FIELD CUSTOM_FILTERS}}</span></div>",
            footerCellTemplate:
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange015 < 0 ' " +
              "style='color: #cb4437;font-weight: bold;'>{{grid.appScope.rd.SumRange015 | number:2}}</div>" +
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange015 > 0' " +
              "style='color: #86BE46;font-weight: bold;'>{{grid.appScope.rd.SumRange015 | number:2}}</div>" +
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange015 === 0'>{{grid.appScope.rd.SumRange015 | number:2}}</div>",
          },
          {
            displayName: "LIQUIDITY.OVERVIEW.range1530",
            headerCellFilter: "translate",
            width: "12%",
            field: "range1530",
            headerCellClass: "header-title text-right",
            headerCellTemplate:
              '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
              "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
              "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
              '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" title="TOOLTIP">' +
              '<span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ col.displayName CUSTOM_FILTERS }}</span>' +
              "</div><div ui-grid-filter></div></div>",
            cellFilter: "number:2",
            cellTemplate:
              "<div class='ui-grid-cell-contents text-right' ng-class='row.entity.thirdCat ? \"rd-category\" : \"rd-element\"'>" +
              "<span ng-show='row.entity.range1530 !== 0 || row.entity.thirdCat === true'>{{COL_FIELD CUSTOM_FILTERS}}</span></div>",
            footerCellTemplate:
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange1530 < 0 ' style='color: #cb4437; font-weight: bold;'>" +
              "{{grid.appScope.rd.SumRange1530 | number:2}}</div>" +
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange1530 > 0 ' " +
              "style='color: #86BE46; font-weight: bold;'>{{grid.appScope.rd.SumRange1530 | number:2}}</div>" +
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange1530 === 0 '>{{grid.appScope.rd.SumRange1530 | number:2}}</div>",
          },
          {
            displayName: "LIQUIDITY.OVERVIEW.range3090",
            headerCellFilter: "translate",
            headerCellClass: "header-title text-right",
            headerCellTemplate:
              '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
              "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
              "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
              '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" title="TOOLTIP">' +
              '<span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ col.displayName CUSTOM_FILTERS }}</span>' +
              "</div><div ui-grid-filter></div></div>",
            width: "12%",
            field: "range3090",
            cellFilter: "number:2",
            cellTemplate:
              "<div class='ui-grid-cell-contents text-right' ng-class='row.entity.thirdCat ? \"rd-category\" : \"rd-element\"'>" +
              "<span ng-show='row.entity.range3090 !== 0 || row.entity.thirdCat === true'>{{COL_FIELD CUSTOM_FILTERS}}</span></div>",
            footerCellTemplate:
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange3090 < 0 ' " +
              "style='color: #cb4437;font-weight: bold;'>{{grid.appScope.rd.SumRange3090 | number:2}}</div>" +
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange3090 > 0 ' " +
              "style='color: #86BE46;font-weight: bold;'>{{grid.appScope.rd.SumRange3090 | number:2}}</div>" +
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange3090 === 0 '>{{grid.appScope.rd.SumRange3090 | number:2}}</div>",
          },
          {
            displayName: "LIQUIDITY.OVERVIEW.range90",
            headerCellFilter: "translate",
            headerCellClass: "header-title text-right",
            headerCellTemplate:
              '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
              "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\"" +
              " aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
              '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" ' +
              'title="TOOLTIP"><span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ col.displayName CUSTOM_FILTERS }}</span>' +
              "</div><div ui-grid-filter></div></div>",
            width: "12%",
            field: "range90",
            cellFilter: "number:2",
            cellTemplate:
              "<div class='ui-grid-cell-contents text-right' ng-class='row.entity.thirdCat ? \"rd-category\" : \"rd-element\"'>" +
              "<span ng-show='row.entity.range90 !== 0 || row.entity.thirdCat === true'>{{COL_FIELD CUSTOM_FILTERS}}</span></div>",
            footerCellTemplate:
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange90 < 0 ' " +
              "style='color: #cb4437;font-weight: bold;'>{{grid.appScope.rd.SumRange90 | number:2}}</div>" +
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange90 > 0 ' " +
              "style='color: #86BE46;font-weight: bold;'>{{grid.appScope.rd.SumRange90 | number:2}}</div>" +
              "<div class='ui-grid-cell-contents header-title text-right' ng-show='grid.appScope.rd.SumRange90 === 0 '>{{grid.appScope.rd.SumRange90 | number:2}}</div>",
          },
          {
            displayName: "LIQUIDITY.OVERVIEW.totalDue",
            headerCellFilter: "translate",
            headerCellClass: "header-title text-right",
            headerCellTemplate:
              '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
              "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
              "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
              '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" title="TOOLTIP">' +
              '<span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ col.displayName CUSTOM_FILTERS }}</span></div>' +
              "<div ui-grid-filter></div></div>",
            width: "13%",
            field: "amount",
            cellFilter: "number:2",
            cellTemplate:
              "<div class='ui-grid-cell-contents text-right' ng-class='row.entity.thirdCat ? \"rd-category\" : \"rd-element\"'>" +
              "<span ng-show='row.entity.amount > 0' style='color: #86BE46'>{{COL_FIELD CUSTOM_FILTERS}}</span>" +
              "<span ng-show='row.entity.amount < 0' style='color: #cb4437'>{{COL_FIELD CUSTOM_FILTERS}}</span>" +
              "<span ng-show='row.entity.amount === 0' >{{COL_FIELD CUSTOM_FILTERS}}</span></div>",
            footerCellTemplate:
              "<div ng-show='grid.appScope.rd.SumAmountTotal < 0 ' class='ui-grid-cell-contents header-title text-right' " +
              "style='color: #cb4437;font-weight: bold;'>{{grid.appScope.rd.SumAmountTotal | number:2}}</div>" +
              "<div ng-show='grid.appScope.rd.SumAmountTotal > 0 ' class='ui-grid-cell-contents header-title text-right' " +
              "style='color: #86BE46;font-weight: bold;'>{{grid.appScope.rd.SumAmountTotal | number:2}}</div>" +
              "<div ng-show='grid.appScope.rd.SumAmountTotal === 0' class='ui-grid-cell-contents header-title text-right' >{{grid.appScope.rd.SumAmountTotal | number:2}}</div>",
          },
        ],
        data: data,
        enableFiltering: false,
        enableColumnMenus: false,
        enableSorting: true,
        showTreeExpandNoChildren: false,
        showColumnFooter: true,
        showTreeRowHeader: false,
        rowHeight: 40,
        columnFooterHeight: 40,
        virtualizationThreshold: data.length,
        flatEntityAccess: false,
        onRegisterApi: (gridApi) => {
          $scope.gridApi = gridApi;
        },
      };
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "ReceivableAndDebtorController",
    app.functionality.liquidity.ReceivableAndDebtorController
  );
