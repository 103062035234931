/// <reference path="../../_app.ts" />

namespace app.functionality.upload {
	export class ConfirmExportModalController implements angular.IController {
		$onInit() {}

		static $inject = ["$uibModalInstance"];

		constructor(public $uibModalInstance: any) {
		}

		public cancel() {
			this.$uibModalInstance.dismiss("cancel");
		}

		public confirm() {
			this.$uibModalInstance.close("");
		}
	}
}

angular.module("app").controller("ConfirmExportModalController", app.functionality.upload.ConfirmExportModalController);
