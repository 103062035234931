/// <reference path="../_app.ts" />

module app.model {
    
    export class Period {

        public key: string;

        public monthIndex: number; //for january = 0, february = 1
        public periodIndex: number; // period : 1,2,3,...

        public year: number;

        public bookyear: number;

        constructor() {
        }

        // public getLabel():String {
        //     return MyDateFormatter.getMonthName(monthIndex) + " (" + year + ")";
        // }
    }
}