/// <reference path="../../_app.ts" />
module app.functionality.liquidity {
  import IOperation = app.model.interface.IOperation;
  import IAccountingEntry = app.model.interface.IAccountingEntry;

  export class LiquidityConverter {
    static $inject = [
      "SessionService",
      "AccountTools",
      "BookHistoricConst",
      "$translatePartialLoader",
      "$translate",
    ];

    constructor(
      public sessionService: app.functionality.common.session.SessionService,
      public accountTools: app.functionality.common.utils.AccountTools,
      public bookHistoricConst = new app.model.constants.BookHistoricConst(),
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $translate: ngt.ITranslateService
    ) {
      $translatePartialLoader.addPart("customer/liquidity");
    }

    public getMainMeaning(acc, c, op, type): IAccountingEntry {
      // Purchase
      if (
        this.accountTools.isIncludeInRootArr(
          acc.account,
          c.supplier_AccountArr
        ) &&
        (acc.dbtype === this.bookHistoricConst.PURCHASES ||
          acc.dbtype === this.bookHistoricConst.NC_PURCHASES) &&
        acc.journalType !== null
      ) {
        if (type === 1) {
          op.amount = -op.amount;
        }
        acc.iconType = 2;
        return acc;
      }

      // Sale
      if (
        this.accountTools.isIncludeInRootArr(
          acc.account,
          c.customer_AccountArr
        ) &&
        (acc.dbtype === this.bookHistoricConst.SALES ||
          acc.dbtype === this.bookHistoricConst.NC_SALES) &&
        acc.journalType !== null
      ) {
        acc.iconType = 1;
        return acc;
      }

      // OPDIV
      if (
        (type === 0 &&
          this.accountTools.isIncludeInRootArr(
            acc.account,
            c.customer_AccountArr
          ) &&
          acc.dbtype === this.bookHistoricConst.OPD) ||
        (type === 1 &&
          this.accountTools.isIncludeInRootArr(
            acc.account,
            c.supplier_AccountArr
          ) &&
          acc.dbtype === this.bookHistoricConst.OPD &&
          acc.journalType != null)
      ) {
        acc.lostPaiement = true;
        op.lostPaiement = true;
        op.amount = -op.amount;
        acc.iconType = 4;
        return acc;
      }

      // Lost Paiement
      if (
        (type === 0 &&
          this.accountTools.isIncludeInRootArr(
            acc.account,
            c.customer_AccountArr
          ) &&
          acc.dbtype === this.bookHistoricConst.BANK) ||
        (type === 1 &&
          this.accountTools.isIncludeInRootArr(
            acc.account,
            c.supplier_AccountArr
          ) &&
          acc.dbtype === this.bookHistoricConst.BANK &&
          acc.journalType !== null)
      ) {
        acc.lostPaiement = true;
        op.lostPaiement = true;
        op.amount = -op.amount;
        acc.iconType = 3;
        return acc;
      }

      return null;
    }

    // convert the operations array into a ui-grid dataset
    public convert(dataSet, type, today?, r_third?) {
      let self = this;
      // sort the operations array by third name
      dataSet.sort(function (a, b) {
        if (a.third == null) {
          return -1;
        } else if (b.third == null) {
          return 1;
        } else {
          let nameA = self.getFullnameThird(a.third, r_third);
          let nameB = self.getFullnameThird(b.third, r_third);
          if (nameA < nameB) {
            //sort thirds ascending
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; //default return value (no sorting)
        }
      });

      let resultTab = [];
      let precedentCat = "";

      for (let op of dataSet) {
        // new attribute to fast check if op is lost paiement or not
        op.lostPaiement = false;
        let idAcc = 0;
        for (let acc of op.accountingEntryArr) {
          let cashEntry = self.getMainMeaning(
            acc,
            self.sessionService.session.company,
            op,
            type
          );
          if (cashEntry) {
            this.setOperationRange(op, today);
            if (cashEntry.third !== precedentCat) {
              let com = this.getFullnameThird(cashEntry.third, r_third);
              if (com === undefined) {
                com = cashEntry.third;
              }
              let cat = {
                comment: com,
                $$treeLevel: 0,
                amount: 0,
                thirdCat: true,
                range015: 0,
                range1530: 0,
                range3090: 0,
                range90: 0,
              };
              resultTab.push(cat);
              precedentCat = cashEntry.third;
            }
            this.generateComment(op, acc, type);
            op.iconType = cashEntry.iconType;
            op.idAcc = idAcc;
            op.cashEntry = cashEntry;
            resultTab.push(op);
            break;
          }
          idAcc++;
        }
      }
      return resultTab;
    }

    /**
     * This function is used to generate a comment for an operation based on the due date
     * @param {app.model.interface.IOperation} op the operation concerned
     * @param {app.model.interface.IAccountingEntry} an accounting entry related to the operation
     * @param {number} type
     */
    public generateComment(
      op: IOperation,
      acc: IAccountingEntry,
      type: number
    ) {
      let self = this;
      if (
        op.comment === null ||
        op.comment === undefined ||
        op.comment === ""
      ) {
        if (acc.vcs !== null && acc.vcs !== undefined && acc.vcs !== "") {
          op.comment = acc.vcs;
        } else if (op.dueDate !== null && op.dueDate !== undefined) {
          if (
            (op.type === type &&
              this.accountTools.isIncludeInRootArr(
                acc.account,
                self.sessionService.session.company.customer_AccountArr
              ) &&
              acc.dbtype === this.bookHistoricConst.BANK) ||
            (op.type === type &&
              this.accountTools.isIncludeInRootArr(
                acc.account,
                self.sessionService.session.company.supplier_AccountArr
              ) &&
              acc.dbtype === this.bookHistoricConst.BANK &&
              acc.journalType !== null)
          ) {
            op.comment =
              "Le " +
              new Date(op.dueDate).getDate() +
              "/" +
              (new Date(op.dueDate).getMonth() + 1) +
              "/" +
              new Date(op.dueDate).getFullYear();
          } else if (
            (type === 0 &&
              this.accountTools.isIncludeInRootArr(
                acc.account,
                self.sessionService.session.company.customer_AccountArr
              ) &&
              acc.dbtype === this.bookHistoricConst.OPD) ||
            (type === 1 &&
              this.accountTools.isIncludeInRootArr(
                acc.account,
                self.sessionService.session.company.supplier_AccountArr
              ) &&
              acc.dbtype === this.bookHistoricConst.OPD &&
              acc.journalType != null)
          ) {
            op.comment =
              this.$translate.instant("LIQUIDITY.deadline") +
              " " +
              new Date(op.dueDate).getDate() +
              "/" +
              (new Date(op.dueDate).getMonth() + 1) +
              "/" +
              new Date(op.dueDate).getFullYear();
          } else {
            op.comment =
              this.$translate.instant("LIQUIDITY.deadline") +
              " " +
              new Date(op.dueDate).getDate() +
              "/" +
              (new Date(op.dueDate).getMonth() + 1) +
              "/" +
              new Date(op.dueDate).getFullYear();
          }
        }
      }
    }

    public getFullnameThird(reference, r_third) {
      for (let third of r_third) {
        if (third.reference === reference) {
          return (
            third.fullName.charAt(0).toUpperCase() +
            third.fullName.slice(1).toLowerCase()
          );
        }
      }
      if (reference != null) {
        return (
          reference.charAt(0).toUpperCase() + reference.slice(1).toLowerCase()
        );
      } else {
        return "";
      }
    }

    public removeDays(date, days) {
      let result = new Date(date);
      result.setDate(result.getDate() - days);
      return result.getTime();
    }

    public setOperationRange(op, today?) {
      op.range015 = 0;
      op.range1530 = 0;
      op.range3090 = 0;
      op.range90 = 0;

      if (today === undefined) {
        today = Date.now();
      }
      // if that's a tranfer, it should not appear in the ranges column
      if (op.lostPaiement) {
        return;
      }

      let range015 = this.removeDays(today, 14);
      let range1530 = this.removeDays(today, 29);
      let range3090 = this.removeDays(today, 89);
      if (op.dueDate > range015) {
        op.range015 = op.amount;
      } else if (op.dueDate > range1530) {
        op.range1530 = op.amount;
      } else if (op.dueDate > range3090) {
        op.range3090 = op.amount;
      } else {
        op.range90 = op.amount;
      }
    }
  }
}

angular
  .module("app")
  .service(
    "LiquidityConverter",
    app.functionality.liquidity.LiquidityConverter
  );
