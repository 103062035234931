/// <reference path="../../../_app.ts" />

module app.functionality.common.interceptors {
  import ModalUtil = app.functionality.common.modals.ModalUtil;
  import SessionService = app.functionality.common.session.SessionService;

  export class ResponseHandlerService {
    static $inject = [
      "ModalUtil",
      "$state",
      "SessionService",
      "$q",
      "$http",
      "$window",
      "Notification",
      "$translatePartialLoader",
      "$translate",
    ];

    public tmpResponse;
    public expiredModalLaunched = false;

    constructor(
      private modalUtil: ModalUtil,
      public $state: ng.ui.IStateService,
      public sessionService: SessionService,
      private $q: ng.IQService,
      private $http: ng.IHttpService,
      private $window: ng.IWindowService,
      private notification: any,
      private $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $translate: ngt.ITranslateService
    ) {
      $translatePartialLoader.addPart("customer/notifications");
    }

    redirectToLogin = (response) => {
      let self = this;

      self.tmpResponse = response;
      return () => {
        // self.$state.go("login");
        console.log("redirectToLogin second step CALLED", self.tmpResponse);
        // self.$state.go("login");
        // return self.tmpResponse;
      };
    };

    public serverResponse(response) {
      let returnResult: any;

      switch (response.data.status) {
        case 601:
          {
            let modalOptions = {
              actionButtonText: "LOGIN.outdatedVersionConfirm",
              headerText: "LOGIN.outdatedVersionTitle",
              bodyText: "LOGIN.outdatedVersionBodyText",
            };
            returnResult = this.modalUtil.showConfirm(
              {},
              modalOptions,
              this.redirectToLogin,
              this.redirectToLogin
            );
          }
          break;
        case 1001:
          {
            this.notification.error(
              this.$translate.instant("NOTIFICATIONS.ERROR.serverError")
            );
            returnResult = response;
          }
          break;
        default:
          {
            returnResult = response;
          }
          break;
      }
      return returnResult;
    }

    public refreshAccessToken(rejection) {
      let self = this;

      // return this.$http.get("/api/CheckConnectivityServlet")
      return this.sessionService.refreshAccessToken().then(function () {
        // retry the exact same initial call
        // self.$state.go("websitelayout.headerandmenu.debtor");
        console.error("442 - trying this call again", rejection.config);
        return self.$http(rejection.config);
      });
      // .catch(function(error) {
      //     if (error.status === "401") {
      //         // delete session
      //
      //         // get to login
      //         self.$state.go("login");
      //     } else {
      //         console.error(error);
      //     }
      //     return rejection;
      // });
    }

    public disconnectAndRedirect(rejection) {
      let self = this;
      let defered = this.$q.defer();

      if (self.expiredModalLaunched === false) {
        self.expiredModalLaunched = true;
        self.sessionService.authenticated = false;
        self.sessionService.session = undefined;
        self.$state.go("login");
        self.expiredModalLaunched = false;
        defered.resolve();
        // self.$window.location.reload();
        return rejection;
        /*return this.modalUtil.expiredRefreshToken()
                    .then(function() {
                        self.sessionService.authenticated = false;
                        self.sessionService.session = undefined;
                        self.$state.go("login");
                        self.expiredModalLaunched = false;
                        defered.resolve();
                        // self.$window.location.reload();
                        return rejection;
                    })
                    .catch(function(error) {
                        defered.reject(error);
                    });*/
      } else {
        return rejection;
      }
    }
  }
}

angular
  .module("app")
  .service(
    "ResponseHandlerService",
    app.functionality.common.interceptors.ResponseHandlerService
  );

angular.module("app").config([
  "$httpProvider",
  ($httpProvider: ng.IHttpProvider) => {
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.interceptors.push(function ($q, $injector) {
      return {
        request: function (config) {
          return config;
        },

        requestError: function (config) {
          console.error("requestError", config);
          return $q.reject(config);
        },

        response: function (response) {
          let handler = $injector.get("ResponseHandlerService");
          return handler.serverResponse(response);
        },

        responseError: function (rejection) {
          let handler = $injector.get("ResponseHandlerService");
          if (rejection.status === 442) {
            console.error("Access Token Expired");
            return handler.refreshAccessToken(rejection);
          } else if (rejection.status === 418) {
            console.error(
              "Refresh Token expired, disconnected and forced to login again."
            );
            return handler.disconnectAndRedirect(rejection);
          }
          return $q.reject(rejection);
        },
      };
    });
  },
]);
