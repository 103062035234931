/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.controllers {

    import IContact = app.model.interface.IContact;

    export class ContactUpdateInfoController  implements angular.IController {

        public pageTitle: string;

        /**
         * Contains the current contact data
         */
        public contactData: IContact;

        /**
         * Used to display it in the title of the view
         * We can't use contactData.fullName because if the user change it in the form, it would be changed in the title
         */
        public thirdName;
        public isLoading: boolean;

        private previousPage;

        $onInit() { }

        static $inject = [
            "r_contactData",
            "r_title",
            "InvoiceReminderService",
            "SessionService",
            "$translatePartialLoader",
            "$state",
            "$translate",
            "Notification",
            "r_previousPage"
        ];

        constructor(r_contactData: IContact,
                    r_title: string,
                    private invoiceReminderService: app.functionality.invoiceReminder.services.InvoiceReminderService,
                    private sessionService: app.functionality.common.session.SessionService,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService,
                    private $state,
                    private $translate: ngt.ITranslateService,
                    private notification: any,
                    r_previousPage) {

            $translatePartialLoader.addPart("customer/invoiceReminder");
            this.pageTitle = r_title;
            this.contactData = r_contactData;
            this.thirdName = this.contactData.fullName;
            this.previousPage = r_previousPage;
        }

        /**
         * This function is used to check if the form is complete
         * @returns true if some fields of the form are incomplete
         */
        public incompleteForm(): boolean {
            return  this.contactData.fullName == undefined || this.contactData.fullName.trim().length == 0;
        }

        /**
         * this function is used to update the contact data of a third
         * If the update is done without problems, it redirects the user to detailed page of the third
         * Otherwise, it shows a notification explaining the error
         */
        public updateData() {
            let self = this;

            if (this.incompleteForm()) {
                return;
            }

            self.isLoading = true;
            this.invoiceReminderService.updateContactInfo(this.sessionService.session.sessionID, this.contactData).then(function(response) {
                self.isLoading = false;
                if (response.status == 0) {
                    // self.previousPage.url != null ? self.$state.go(self.previousPage.name, self.previousPage.params) : self.$state.go("websitelayout.headerandmenu.invoiceReminderContactList");
                    self.notification.primary({
                        title: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.updateDone"),
                        message: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATECOMPANYADDITIONALINFORMATIONFORM.updateCompanyAdditionalInfoOK")
                        // templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html"
                    });
                }
                else {
                    if (response.status == 408) {
                        self.notification.primary({
                            title: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATECONTACTFORM.errorUpdateContact"),
                            message: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATECONTACTFORM.missingFieldsContact"),
                            // templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
                            delay: null
                        });
                    }
                    else {
                        self.notification.primary({
                            title: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATECONTACTFORM.errorUpdateContact"),
                            message: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATECONTACTFORM.updateFailedContact"),
                            // templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
                            delay: null
                        });
                    }
                }
            });

        }

    }

}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("ContactUpdateInfoController", app.functionality.invoiceReminder.controllers.ContactUpdateInfoController);