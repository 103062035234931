/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {

    // import ToSendController = app.functionality.upload.ToSendController;

    export class ModalService {

        private rest: restangular.IService;
        public detailModal: any;
        public messageModal: any;
        public infoModal: any;
        public pdfViewerModal: any;
        public feedBackMessageModal: any;
        public keepNoteModal: any;
        public resultUploadModal: any;
        public deleteInvoiceModal: any;
        public newTicketModalVar: any;
        public sessionID: string;

        // for confirm modal
        private modalDefaults: any;
        private modalOptions: any;

        static $inject = [
            "RestService",
            "RESTAPI",
            "ProfitService",
            "$uibModal",
            "$location",
            "$anchorScroll",
            "SessionService"
        ];

        constructor(
            restService: app.functionality.common.rest.RestService,
            private RESTAPI: app.config.constants.ConstantRestApi,
            private profitService: app.functionality.profit.ProfitService,
            public  $uibModal: any,
            private location: ng.ILocationService,
            private anchorScroll: ng.IAnchorScrollService,
            private sessionService: app.functionality.common.session.SessionService
        ) {
            this.rest = restService.getRoot();

            this.modalDefaults = {
                backdrop: true,
                keyboard: true,
                modalFade: true,
                templateUrl: "tpl/website/modals/confirm.html"
            };

            this.modalOptions = {
                closeButtonText: "Annuler",
                actionButtonText: "Confirmer",
                headerText: "Continuer?",
                bodyText: "Effectuer cette action?"
            };

        }

        public getSessionID() {
            if (angular.isDefined(this.sessionService.session.sessionID) || this.sessionID === "") {
                this.sessionID = this.sessionService.session.sessionID;
            } else {
                this.sessionID = "";
            }
            return this.sessionID;
        }

        getLiquidityByCompanyKey = (companyKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.liquidity.base).one("companyKey", companyKey).get();
        }

        public createInfoModal(row, templateUrl, controller, controllerAs, modalSize) {
            this.infoModal = this.$uibModal.open({
                templateUrl: templateUrl,
                controller: controller,
                controllerAs: controllerAs,
                size: modalSize,
                resolve: {
                    "r_data": function () {
                        return {
                            "row": row
                        };
                    }
                }
            });
        }

        public status(message, title, alreadyTranslate = false ) {
            this.$uibModal.open({
                templateUrl: "tpl/website/modals/statusModal.html",
                controller: "StatusModalController",
                controllerAs: "status",
                size: "sm",
                backdrop  : "static",
                keyboard  : false,
                resolve: {
                    "r_data": function () {
                        return {
                            "message": message,
                            "title": title,
                            "alreadyTranslated": alreadyTranslate
                        };
                    }
                }
            });
        }

        public showConfirm(customModalDefaults, customModalOptions) {
            if (!customModalDefaults) {
                customModalDefaults = {};
            }
            customModalDefaults.backdrop = "static";
            return this.confirm(customModalDefaults, customModalOptions);
        }

        private confirm(customModalDefaults, customModalOptions) {
            //Create temp objects to work with since we're in a singleton service
            let tempModalDefaults = <any>{};
            let tempModalOptions = <any>{};

            //Map angular-ui modal custom defaults to modal defaults defined in service
            angular.extend(tempModalDefaults, this.modalDefaults, customModalDefaults);

            //Map modal.html $scope custom properties to defaults defined in service
            angular.extend(tempModalOptions, this.modalOptions, customModalOptions);

            if (!tempModalDefaults.controller) {
                tempModalDefaults.controller = function ($scope, $uibModalInstance) {
                    $scope.modalOptions = tempModalOptions;
                    $scope.modalOptions.ok = function (result) {
                        $uibModalInstance.close(result);
                    };
                    $scope.modalOptions.close = function (result) {
                        $uibModalInstance.dismiss();
                    };
                };
            }

            return this.$uibModal.open(tempModalDefaults).result;
        }

        public createMultiCompanyModal(data,switchCompany = false) {
            // not sure if using a service as a controller is a good idea
            // move all the manipulation of the data from SessionService to LoginController

            this.$uibModal.open({
                templateUrl: "tpl/website/modals/companyList.html",
                controller: "MultiCompanyModalController",
                controllerAs: "mc",
                size: "sm",
                windowClass: "companylist-modal",
                resolve: {
                    "r_data": function () {
                        return {
                            "data": data
                        };
                    }, "switchCompany" : switchCompany
                }
            });
        }

        // to close any modal, if possible. maybe use $uibInstance instead of $uibModal
        // public closeModal(){
        //     this.$uibModal.close();
        // }

        public createDetailModal(row, templateUrl, controller, controllerAs) {

            let self = this;
            // self.profitService.getCategoryDetailsByCompanyKey(123, 123)
            //     .then(function(data) {
                    self.detailModal = self.$uibModal.open({
                        templateUrl: templateUrl,
                        controller: controller,
                        controllerAs: controllerAs,
                        size: "lg",
                        resolve: {
                            "gridData": function () {
                                return {
                                    "sessionID": self.getSessionID(),
                                    "row": row,
                                    // "detailGrid": data.plain()
                                };
                            }
                        }
                    });
                // })
                // .catch(function(error) {
                //     console.error("error ocurred");
                // });
        }

        public createSmallDetailModal(grid, row, templateUrl, controller, controllerAs) {
            let self = this;
                this.detailModal = self.$uibModal.open({
                    templateUrl: templateUrl,
                    controller: controller,
                    controllerAs: controllerAs,
                    size: "lg",
                    resolve: {
                        "gridData": function () {
                            return {
                                "sessionID": self.getSessionID(),
                                "grid": grid,
                                "row": row
                            };
                        }
                    }
                });
        }

        // public createTicketModalOld(grid, row, templateUrl, controller, controllerAs) {
        //
        //     let self = this;
        //     this.messageModal = this.$uibModal.open({
        //         templateUrl: templateUrl,
        //         controller: controller,
        //         controllerAs: controllerAs,
        //         size: "lg",
        //         backdrop  : 'static',
        //         keyboard  : false,
        //         resolve: {
        //             "gridData": function () {
        //                 return {
        //                         "sessionID": self.getSessionID(),
        //                     "grid": grid,
        //                     "row": row
        //                 };
        //             }
        //         }
        //     }).closed.then(function () {
        //         //to remove the #modalBottom from the url
        //         self.location.hash("");
        //         self.anchorScroll();
        //     });
        // }

        // now the modal is created inside the controller

        // public createTicketModal($stateParams, templateUrl, controller, controllerAs) {
        //
        //     let self = this;
        //     this.messageModal = this.$uibModal.open({
        //         templateUrl: templateUrl,
        //         controller: controller,
        //         controllerAs: controllerAs,
        //         size: "lg",
        //         resolve: {
        //             "r_data": function () {
        //                 return {
        //                         "sessionID": self.getSessionID(),
        //                     "$stateParams": $stateParams
        //                 };
        //             }
        //         }
        //     }).closed.then(function () {
        //         //to remove the #modalBottom from the url
        //         self.location.hash("");
        //         self.anchorScroll();
        //     });
        // }

        //not the one from principal message view, it's the one used to launch
        public createMessageModal(row, templateUrl, controller, controllerAs) {
            let self = this;
            this.messageModal = this.$uibModal.open({
                templateUrl: templateUrl,
                    // controller: controller,
                    // controllerAs: controllerAs,
                size: "md",
                resolve: {
                    "gridData": function () {
                        return {
                            "sessionID": self.getSessionID(),
                            "row": row
                        };
                    }
                }
            });
        }

        // the modal for the new ticket button on ticket.html

        // public newTicketModal(templateUrl, controller, controllerAs) {
        //     this.newTicketModalVar = this.$uibModal.open({
        //         templateUrl: templateUrl,
        //         controller: controller,
        //         controllerAs: controllerAs,
        //         size: "md"
        //     });
        // }

        public createFeedBackMessage(templateUrl, controller, controllerAs) {
            this.feedBackMessageModal = this.$uibModal.open({
                    templateUrl: templateUrl,
                    controller: controller,
                    controllerAs: controllerAs,
                    size: "lg",
                    resolve: {}
                }
            );
        }

        public createKeepNoteModal(templateUrl, controller, controllerAs, splitOrMerge: string) {
            let self = this;
            this.keepNoteModal = this.$uibModal.open({
                templateUrl: templateUrl,
                controller: controller,
                controllerAs: controllerAs,
                size: "md",
                resolve: {
                    "r_data": function () {
                        return {
                            "splitOrMerge" : splitOrMerge,
                            "sessionID": self.getSessionID()
                        };
                    }
                }
            });
        }

        public createIPPUploadModal(templateUrl, controller, controllerAs,
                                    fileInput, uploadFolderSelected, model, key, map) {
            this.keepNoteModal = this.$uibModal.open({
                templateUrl: templateUrl,
                controller: controller,
                controllerAs: controllerAs,
                //Permet de bloquer le click en dehors du modal
                backdrop: "static",
                size: "md",
                resolve: {
                    "r_data": function () {
                        return {
                            "fileInput": fileInput,
                            "uploadFolderKey": uploadFolderSelected,
                            "model": model,
                            "key": key,
                            "map": map
                        };
                    }
                }
            });
        }

        public createResultUploadModal(templateUrl, controller, controllerAs, mapUploadError, fromMessages?) {
            let self = this;
            this.resultUploadModal = this.$uibModal.open({
                templateUrl: templateUrl,
                controller: controller,
                controllerAs: controllerAs,
                backdrop: "static",
                size: "lg",
                resolve: {
                    "r_data": function () {
                        return {
                            "mapUploadError" : mapUploadError,
                            "sessionID": self.getSessionID(),
                            "fromMessages": fromMessages
                        };
                    }
                }
            });
        }

        public createDeleteInvoiceModal(templateUrl, controller, controllerAs, nbInvoiceSelect: number) {
            
        }

    }
}

angular.module("app").service("ModalService", app.functionality.common.modals.ModalService);