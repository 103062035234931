/// <reference path="../../../_app.ts" />

import ENV = app.config.constants.environment.ConstantEnv;

namespace app.functionality.common.logger {
  export class LoggerService {
    static $inject = ["ENV"];

    constructor(private env: ENV) {}

    public error(className, msg, member) {
      let error = {
        message: "fichier : " + className + "url : " + msg.config.url,
        type: "error",
        name: msg.status + msg.statusText,
        file: className,
      };
      this.notifyException(error, member);
    }

    public customError(className, customMsg, member) {
      let error = {
        message: customMsg,
        type: "error",
        name: "fichier : " + className,
        file: className,
      };
      this.notifyException(error, member);
    }

    public info(className, msg) {}

    public warning(className, msg) {}

    public notifyException(error, member) {
      if (Bugsnag) {
        Bugsnag.apiKey = this.env.getBugnsagApiKey();
        Bugsnag.notifyReleaseStages = [this.env.environment];
        Bugsnag.user = {
          id: member.key,
          name: member.fullName,
          email: member.mail,
        };
        Bugsnag.releaseStage = this.env.environment;
        Bugsnag.appVersion = this.env.getVersion();

        Bugsnag.notify(error.name, error.message, error.file, error.type);
      } else console.error("bugsnag not init");
    }
  }
}

angular
  .module("app")
  .service("LoggerService", app.functionality.common.logger.LoggerService);
