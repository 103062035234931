/// <reference path="../../_app.ts" />

module app.model.interface {

    export interface IOperation {

        key: string;

        status: number;

        accountingEntryArr: IAccountingEntry[];

        parentBudgetKey: string;

        amount: number;
        encodingDate: string;
        dueDate: string;
        third: string;
        comment: string;
        lostPaiement: boolean;
        iconType: number;
        type: number;

        isSelected: boolean;

        reminderNumber: number;

        operationKey: string;

    }
}