/// <reference path="../../_app.ts" />

module app.functionality.user {
  import IHttpService = angular.IHttpService;
  import Member = app.model.Member;

  export class UserService {
    private rest: restangular.IService;
    public languages = ["NL", "FR", "EN"];

    static $inject = ["RestService", "RESTAPI", "$http", "$q"];

    constructor(
      restService: app.functionality.common.rest.RestService,
      private RESTAPI: app.config.constants.ConstantRestApi,
      public $http: IHttpService,
      private $q: ng.IQService
    ) {
      this.rest = restService.getRoot();
    }

    getUserByKey = (sessionID: string, key: string): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.user.community)
        .all(this.RESTAPI.services.user.getMember)
        .one(sessionID + "&" + key)
        .get();
    };

    updateUser(sessionID: string, userData: any) {
      return this.updateUserPassword(sessionID, userData);
    }

    updateUserPassword = (
      sessionID: String,
      newMember: Member
    ): ng.IPromise<any> => {
      let defered = this.$q.defer();

      let request = this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .all(this.RESTAPI.services.session.editMember);

      request
        .customPOST({
          member: newMember,
          sessionID: sessionID,
        })
        .then(function (data) {
          defered.resolve(data);
        })
        .catch(function (error) {
          console.error(error);
          console.error("error ocurred on updateUserPassword");
          defered.reject(error);
        });

      return defered.promise;
    };

    getUserNotifications = (userKey: string): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.user.notifications)
        .one("id", userKey)
        .get();
    };
  }
}

angular
  .module("app")
  .service("UserService", app.functionality.user.UserService);
