/// <reference path="../_app.ts" />

module app.model {

    import Company = app.model.Company;
    import Member = app.model.Member;
    import Branding = app.model.branding.Branding;

    export class UserSession extends Session {

        classStructure : any = {
            company: app.model.Company,
            responsableCompany: Member,
            member: Member
        };

        public company: Company;
        public responsableCompany: Member;
        public primitiveOpeArrSessionKey: string;
        public accountingSituationSessionKey: string;
        public periodArrSessionKey: string;
        public structAccountSessionKey: string;
        public currAccountsSituationsMapKey: string;
        public isMultiCompany: boolean;
        public updateDataDate: Date;

        //accounting firm data
        public accountingFirmKey: string;
        public accountingFirmName: string;
        public brandingData: Branding;
        public accountingFirmBranding: number;
        public subscription_plan: any;

        constructor() {
            super();
        }

    }

}
