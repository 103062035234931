/// <reference path="../../../_app.ts" />

module app.functionality.treasury.wc {

    export class WcController {

        protected data: any;
        public total = 0;

        static $inject = [
            "r_wc",
            "$translatePartialLoader"
        ];

        constructor(r_wc: any,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {
            $translatePartialLoader.addPart("customer/treasury");
            this.initTotal(r_wc.data);
            this.data = r_wc.data;
        }

        public initTotal(data) {
            for (let i = 0; i < data.length; i++) {
                if (!isNaN(data[i].solde)) {
                    this.total = this.total + data[i].solde;
                }
            }
        }
    }
}

angular.module("app").controller("WcController", app.functionality.treasury.wc.WcController);
