module app.config.socket {
  import SessionService = app.functionality.common.session.SessionService;

  export class SocketService {
    static $inject = ["$q", "$rootScope", "$stomp", "SessionService"];

    constructor(
      private $q: ng.IQService,
      private $rootScope: ng.IRootScopeService,
      private $stomp: any,
      private sessionService: SessionService
    ) {}

    public sendMessage(url: String, message: String) {
      let self = this;
      self.$stomp.send(url, message);
    }

    public init() {
      let self = this;

      self.$stomp.connect("/api/client", {}).then(function (frame) {
        self.$stomp.subscribe(
          "/topic/connection/" + self.sessionService.session.company.key,
          function (payload, headers, res) {
            if (payload.message === "New Challenger") {
            } else if (payload.feature === "SPLIT") {
              self.$rootScope.$broadcast("splitInvoiceResponse", payload);
            } else if (payload.feature === "MERGE") {
              self.$rootScope.$broadcast("mergeInvoiceResponse", payload);
            } else if (payload.feature === "SEARCH") {
              self.$rootScope.$broadcast("search", payload);
            } else if (payload.feature === "INVOICE_SENT") {
              let invoice = JSON.parse(payload.result);
              // we want the update only if the invoice is really "sent"
              self.$rootScope.$broadcast("invoice_sent", invoice);
            } else if (payload.feature === "NEW TICKET") {
              self.$rootScope.$broadcast("new ticket", payload);
            } else {
              console.error("event websocket inconnu: ", payload);
            }
          }
        );

        self.$stomp.subscribe(
          "/topic/state/" + self.sessionService.session.sessionID,
          function (payload, headers, res) {
            console.error("event websocket inconnu: ", payload);
          }
        );
        // self.$stomp.send("/app/sendState/" + self.sessionService.session.member.key, "new connection");

        self.$stomp.subscribe(
          "/topic/memberConnection/" +
            self.sessionService.session.member.key +
            "&" +
            self.sessionService.session.accesLevel,
          function (payload, headers, res) {
            if (payload.feature === "DISCONNECTION") {
              self.$rootScope.$broadcast(
                "DISCONNECTION",
                payload.sessionIdOriginal
              );
            } else {
              //   console.error("event websocket inconnu: ", payload);
            }
          }
        );
        //self.$stomp.send("/app/memberConnection/" + self.sessionService.session.member.key+"&"+self.sessionService.session.accesLevel, "new connection for member "+self.sessionService.session.member.username);
      });
    }
  }
}

angular.module("app").service("SocketService", app.config.socket.SocketService);
