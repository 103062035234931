/// <reference path="../../_app.ts" />

module app.functionality.permanentfolder {

    import SessionService = app.functionality.common.session.SessionService;

    export class AddPMFFolderController {

        public context: any;

        public folderName: string;

        static $inject = [
            "$uibModalInstance",
            "SessionService",
            "$translatePartialLoader",
            "$filter"
        ];

        constructor(private $uibModalInstance: any,
                    public sessionService: SessionService,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService,
                    public $filter: any) {
            $translatePartialLoader.addPart("customer/permanentFolder");
        }
        ok = () => {
            this.$uibModalInstance.close(this.folderName);
        }

        cancel = () => {
            this.$uibModalInstance.dismiss();
        }
    }
}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("AddPMFFolderController", app.functionality.permanentfolder.AddPMFFolderController);
