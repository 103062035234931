/// <reference path="../_app.ts" />

module app.model {

    // import Company = app.model.Company;
    // import AccountingEntry = app.model.AccountingEntry;

    // import model.AccountingEntry;
    // import model.BookHistoric;
    // import model.Company;
    //
    // import tools.AccountTools;

    import Operation = app.model.Operation;
    import AccountingEntry = app.model.AccountingEntry;

    export class Purchase extends Operation {

        public amount: number;
        public encodingDate: Date;
        public dueDate: Date;
        public third: string;
        public vat: number;
        public comment: string;

        // paiement mode
        public percentCash: number;
        public percentCB: number;
        public percentCreditCard: number;
        public percentVirement: number;

        constructor() {
            super();
        }

        // public hasAttachedDoc(c:Company):Boolean {
        //     if (this.accountingEntryArr) {
        //         for each(acc:AccountingEntry in this.accountingEntryArr) {
        //             if (AccountTools.isIncludeInRootArr(acc.account,c.supplier_AccountArr)  && acc.dbtype == BookHistoric.PURCHASES && acc.isDoc)
        //                 return true;
        //         }
        //     }
        //
        //     return false;
        // }
        //
        // public getAttachedAcc(c:Company):AccountingEntry {
        //     if (this.accountingEntryArr) {
        //         for each(let acc:AccountingEntry in this.accountingEntryArr) {
        //             if (AccountTools.isIncludeInRootArr(acc.account,c.supplier_AccountArr) && acc.dbtype == BookHistoric.PURCHASES && acc.isDoc)
        //                 return acc;
        //         }
        //     }
        //
        //     return null;
        // }
        //

        public getMainMeaningAcc(c: Company): AccountingEntry {
            if (this.accountingEntryArr) {
                this.accountingEntryArr.forEach(function(acc) {
                    if (this.accountTools.isIncludeInRootArr(acc.account, c.supplier_AccountArr) && acc.dbtype === this.BookHistoric.PURCHASES && acc.journalType !== null) {
                        return acc;
                    }
                });
            }
            return null;
        }

        public specialAccountNumber: number;

        public getAmount(): number {
            return this.amount;
        }

        public setAmount(amount: number): void {
            this.amount = amount;
        }

        public getDueDate(): Date {
            return this.dueDate;
        }

        public setDueDate(dueDate: Date): void {
            this.dueDate = dueDate;
        }

        public getThird(): string {
            return this.third;
        }

        public setThird(third: string): void {
            this.third = third;
        }

        public getVat(): number {
            return this.vat;
        }

        public setVat(vat: number): void {
            this.vat = vat;
        }

        public getComment(): string {
            return this.comment;
        }

        public setComment(comment: string): void {
            this.comment = comment;
        }

        public updateDueDate(newOp: Operation): void {
            let p: Purchase = newOp as Purchase;
            this.dueDate = p.dueDate;
            this.accountingEntryArr = p.accountingEntryArr;
        }

    }

}