/// <reference path="../../../_app.ts" />

module app.modules.lineargraphs.chart {

    export class LineChart extends LineChartBase {
        
        defineChartGraph() {
            return this.lineChartServiceUtil.defineLine(this.xScale, this.yScale, this.propertyX, this.propertyY);
        }
        
        drawLineChart() {
            this.lineChartServiceUtil.drawLineChart(this.canvas, this.geometryDef, this.data);
        }
    }
    
}