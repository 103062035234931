/// <reference path="../../_app.ts" />

module app.model.invoicing {
  export class InvoicingClient {
    key: string;
    importReference: string;
    companyKey: string;

    fullName: string;
    vat: string;
    emailAddress: string;
    address: string;
    zip: string;
    city: string;
    country: string;
    isoCountry: string;

    archived: boolean;

    constructor() {}
  }
}
