/// <reference path="../../_app.ts" />

import ngt = angular.translate;

angular
	.module("app")
	.config([
		"$translateProvider",
		"I18NCONSTANTS",
		($translateProvider: ngt.ITranslateProvider, i18NCONSTANTS: app.config.constants.I18NConstants) => {
			$translateProvider
				.useLoader("$translatePartialLoader", {
					urlTemplate: "/assets/translations/{part}/{lang}.json"
				})
				.preferredLanguage(i18NCONSTANTS.i18nDefaultLanguage)
				.fallbackLanguage(i18NCONSTANTS.i18nDefaultLanguage)
				.useMissingTranslationHandler("missingTranslationHandler")
				.useSanitizeValueStrategy("escaped");
		}
	])
	.factory("missingTranslationHandler", () => {
		return function(translationID, uses) {
			return "?" + translationID + "?";
		};
	});
