/// <reference path="../../_app.ts" />

module app.model.interface {

    export interface IAccountingEntry {

        key: string;

        status: number ; // 0 == from export file;1 == generated
        /**
         * Description*
         */

        journalType: string ;// Description du journal (Nom)
        dbtype: number ;//type du journal
        docNumber: number ;
        docOrder: string ; //never use during generation, only for imorted acc

        period: number ;
        account: number ;
        bookyear: number ;

        /**
         * Dates *
         */
        operationDate: Date ;
        encodingDate: Date ;
        dueDate: Date ;

        /**
         * third*
         */
        third: string ;
        comment: string ;
        vcs: string ;

        /**
         * amount*
         */
        amount: number ;
        vatAmount: number ;
        vat_name: string ;

        isMatched: boolean ;
        isDoc: boolean ;
        matchID: string ;

        documentID: string ;

        /**
         * Match the operation Key*
         */
        parentBudgetKey: string ;

        iconType;

    }
}