/// <reference path="../../_app.ts" />

namespace app.functionality.IPPForm {
    import UploadService = app.functionality.upload.UploadService;

    export class IPPFormArchiveController implements angular.IController {
        static $inject = [
            "r_data",
            "$uibModal",
            "SessionService",
            "RESTAPI",
            "UploadService",
            "$scope",
            "ENV",
            "$translate",
            "IPPFormService",
            "$translatePartialLoader",
            "Notification",
            "LoggerService"
        ];

        /*
         let param:String = this.ENV.baseUrl +;
         param += "/IPPFormMgr?sessionID=" + this.sessionID;
         param += "&filename=" + this.value; // only the name with extension
         param += "&ippKey=" + ippKey;
         param += "&type=" + IPPFormData.ANNEXE; (ANNEXE = 1, DECLARATION = 0)
         */
        public archives: any;
        public archiveStateName: any;
        public datas: any;
        public legislation: string;
        public uploadIPPSelected: any;
        public sessionID: any;
        // we set this boolean to true to not add another event listener if we upload something again with the same input element
        public alreadyUploadedSomething: boolean = false;

        constructor(
            r_data: any,
            public $uibModal: any,
            public sessionService: app.functionality.common.session.SessionService,
            private RESTAPI: app.config.constants.ConstantRestApi,
            private uploadService: UploadService,
            protected $scope: any,
            private env: ENV,
            private $translate: ngt.ITranslateService,
            private iPPService: IPPFormService,
            $translatePartialLoader: ngt.ITranslatePartialLoaderService,
            public notification: any,
            public logger: app.functionality.common.logger.LoggerService
        ) {
            $translatePartialLoader.addPart("ipp");
            this.legislation = "BE";
            if(this.sessionService.session.member.legislation) {
                this.legislation = this.sessionService.session.member.legislation;
            }
            this.archiveStateName = ["IPP_FORM.ARCHIVES.notSubmitted", "IPP_FORM.ARCHIVES.waiting", "IPP_FORM.ARCHIVES.submitted"];
            this.datas = r_data;
            this.archives = [];
            r_data.forEach(data => {
                let stat = 0;
                let dateSub;
                if (data.status_declaration == 0) {
                    // not begin
                    stat = 0;
                } else if (data.status_declaration == 1) {
                    // started
                    stat = 0;
                } else {
                    // send by client
                    if (data.depot_accounter == null) {
                        // not sended to ToW
                        stat = 1;
                    } else {
                        // sended to ToW
                        stat = 2;
                        dateSub = data.depot_accounter;
                    }
                }
                let linkArr = [];
                if (data.annexe_files != null) {
                    data.annexe_files.forEach(fileName => {
                        linkArr.push({
                            name: fileName,
                            from: 0
                        });
                    });
                }
                this.archives.push({
                    key: data.key,
                    memberKey: data.memberKey,
                    fiscalYear: data.fiscalYear,
                    status: stat,
                    dateSubmitted: dateSub,
                    linkedDoc: linkArr,
                    towFile: data.declaration_files,
                    status_declaration: data.status_declaration,
                    accFirmKey: data.accFirmKey,
                    depot_accounter: data.depot_accounter,
                    depot_client: data.depot_client,
                    proposition_amount: data.proposition_amount,
                    missingAnnexesList: data.missingAttachments,
                    alreadyUploadedSomething: false,
                    closed: data.closed
                });

                this.sessionID = this.sessionService.session.sessionID;

                let self = this;
                $scope.$on("ipp_" + data.key, function (event, filename: String) {
                    // to update the list when annexe files are added
                    self.archives.forEach(elem => {
                        if (elem.key == data.key) {
                            console.error(filename + " true");
                            elem.linkedDoc.push({
                                name: filename,
                                from: 0
                            });
                        }
                    });
                });
            });
        }

        $onInit() {
        }

        public open(keyIPP: String) {
            this.iPPService
                .getDataFor(keyIPP)
                .then(function (data) {
                })
                .catch(function (error) {
                    // TODO
                });
        }

        public showFile(keyIPP: String, filename: String, type: Number) {
            let self = this;
            this.$uibModal.open({
                templateUrl: "tpl/website/modals/pdfViewerModal.html",
                controller: "PdfViewerModalController",
                controllerAs: "pdfViewer",
                size: "lg",
                keyboard: "false",
                resolve: {
                    r_dataPMF: function () {
                        return (
                            self.env.baseUrl +
                            "/" +
                            self.RESTAPI.services.IPPForm.baseurl +
                            "/" +
                            self.sessionService.session.member.key +
                            "/" +
                            self.RESTAPI.services.IPPForm.ipps +
                            "/" +
                            keyIPP +
                            "/" +
                            self.RESTAPI.services.IPPForm.annexes +
                            "?filename=" +
                            filename +
                            "&type=" +
                            type +
                            "&sessionID=" +
                            self.sessionService.session.sessionID
                        );
                    },
                    r_data: null
                }
            });
        }

        public updateIPPSelected(archive: any) {
            this.uploadIPPSelected = archive.key;
            let fileInput: any = null;
            fileInput = document.querySelector("#fileInput_" + archive.key);
            // if the eventListener has already been added one time, we do not add it a second time.
            if (archive.alreadyUploadedSomething === false) {
                fileInput.addEventListener("change", this.launchUploadModal(fileInput));
            }
            document.getElementById("fileInput_" + archive.key).click();
            // we set the element to true to not add another event listener.
            archive.alreadyUploadedSomething = true;
        }

        public launchUploadModal(fileInput: any) {
            return (e): void => {
                if (fileInput.files.length > 0) {
                    let ippSelected;
                    this.datas.forEach(data => {
                        if (data.key == this.uploadIPPSelected) {
                            ippSelected = data;
                        }
                    });
                    this.$uibModal
                        .open({
                            templateUrl: "tpl/website/modals/uploadModal.html",
                            controller: "UploadModalController",
                            controllerAs: "uploadModalController",
                            //Permet de bloquer le click en dehors du modal
                            backdrop: "static",
                            size: "md",
                            resolve: {
                                ipp_data: () => {
                                    return {
                                        fileInput: fileInput,
                                        typeFile: 1,
                                        ippKey: this.uploadIPPSelected,
                                        fiscalYear: ippSelected.fiscalYear,
                                        accFirmkey: ippSelected.accFirmKey
                                    };
                                },
                                r_data: undefined,
                                pmf_data: undefined
                            }
                        })
                        .result.then(() => {
                        if (fileInput.files.length > 1) {
                            this.notification.primary({
                                title: this.$translate.instant("IPP_FORM.NOTIFICATIONS.successUploadsTitle"),
                                message: this.$translate.instant("IPP_FORM.NOTIFICATIONS.successUploadsMessage"),
                                templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html"
                            });
                        } else {
                            this.notification.primary({
                                title: this.$translate.instant("IPP_FORM.NOTIFICATIONS.successUploadTitle"),
                                message: this.$translate.instant("IPP_FORM.NOTIFICATIONS.successUploadMessage"),
                                templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html"
                            });
                        }
                    })
                        .catch(err => {
                            if (err.status === 413) {
                                this.notification.primary({
                                    title: this.$translate.instant("IPP_FORM.NOTIFICATIONS.fileTooBigErrorTitle"),
                                    message: this.$translate.instant("IPP_FORM.NOTIFICATIONS.fileTooBigErrorMessage"),
                                    templateUrl: "tpl/IPPForm/notificationErrorTemplate.html"
                                });
                                this.logger.error("file too large for annexe", err, this.sessionService.session.member);
                            }
                        });
                    this.warnAccounter(this.uploadIPPSelected);
                }
            };
        }

        public warnAccounter(ippKey: string) {
            this.iPPService
                .warnResponsableAnnexe(ippKey)
                .then(function (data) {
                })
                .catch(function (error) {
                    // TODO
                });
        }
    }
}
angular.module("app").controller("IPPFormArchiveController", app.functionality.IPPForm.IPPFormArchiveController);
