/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.controllers {

    import ICompanyAdditionalInformation = app.model.interface.ICompanyAdditionalInformation;
    import ModalQuickstartService = app.functionality.invoiceReminder.services.ModalQuickstartService;

    export class UpdateConfigurationController  implements angular.IController {

        public pageTitle: string;
        public isLoading: boolean;

        private companyInfo: ICompanyAdditionalInformation;

        $onInit() { }

        static $inject = [
            "r_companyAdditionalData",
            "r_title",
            "InvoiceReminderService",
            "SessionService",
            "$translatePartialLoader",
            "$translate",
            "Notification",
            "ModalQuickstartService"
        ];

        constructor(r_companyAdditionalData: ICompanyAdditionalInformation,
                    r_title: string,
                    private invoiceReminderService: app.functionality.invoiceReminder.services.InvoiceReminderService,
                    private sessionService: app.functionality.common.session.SessionService,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService,
                    private $translate: ngt.ITranslateService,
                    private notification: any,
                    private modalQuickstartService: ModalQuickstartService) {

            $translatePartialLoader.addPart("customer/invoiceReminder");
            this.pageTitle = r_title;
            this.companyInfo = r_companyAdditionalData;
        }

        /**
         * This function is used to verify the form
         * @returns {boolean} true if the form is completed
         */
        public isCompleted(): boolean {
            return this.companyInfo.fullName != undefined && this.companyInfo.fullName.trim().length > 0
                && this.companyInfo.senderName  != undefined && this.companyInfo.senderName.trim().length  > 0
                && this.companyInfo.senderMail  != undefined && this.companyInfo.senderMail.trim().length  > 0
                && this.companyInfo.address  != undefined && this.companyInfo.address.trim().length  > 0
                && this.companyInfo.zip      != undefined && this.companyInfo.zip.trim().length      > 0
                && this.companyInfo.city     != undefined && this.companyInfo.city.trim().length     > 0
                && this.companyInfo.country  != undefined && this.companyInfo.country.trim().length  > 0
                && this.companyInfo.iban     != undefined && this.companyInfo.iban.trim().length     > 0 ;
        }

        /**
         * This function is used to update the configuration data
         * It shows a notification to explain if the update is done or if any problem happened
         */
        public updateData() {
            let self = this;

            if (! this.isCompleted()) {
                return;
            }

            self.isLoading = true;
            this.invoiceReminderService.updateCompanyAdditionalInformation(self.sessionService.session.sessionID, this.companyInfo).then(function(response) {
                self.isLoading = false;
                if (response.status == 0) {
                    self.notification.primary({
                        title: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.updateDone"),
                        message: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATECOMPANYADDITIONALINFORMATIONFORM.updateCompanyAdditionalInfoOK")
                        // templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html"
                    });

                    self.modalQuickstartService.companyInfo = this.companyInfo;

                }
                else {
                    if (response.status == 408) {
                        self.notification.primary({
                            title: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATECOMPANYADDITIONALINFORMATIONFORM.errorUpdateCompanyAdditionalInfo"),
                            message: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATECOMPANYADDITIONALINFORMATIONFORM.missingFieldsCompanyAdditionalInfo"),
                            // templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
                            delay: null
                        });
                    }
                    else {
                        self.notification.primary({
                            title: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATECOMPANYADDITIONALINFORMATIONFORM.errorUpdateCompanyAdditionalInfo"),
                            message: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATECOMPANYADDITIONALINFORMATIONFORM.updateCompanyAdditionalInfoFailed"),
                            // templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
                            delay: null
                        });
                    }
                }
            });
        }
    }
}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("UpdateConfigurationController", app.functionality.invoiceReminder.controllers.UpdateConfigurationController);