/// <reference path="../../_app.ts" />

module app.functionality.treasury {
  import ModalUtil = app.functionality.common.modals.ModalUtil;
  export class BankBalanceController {
    public dataForLineChart: any;
    public bankBalance: any;
    public bankAccounts: any;
    public yearSelected: any;
    public monthSelected: any;
    public accountSelected: any;
    public exerciceArr: any;
    public yearList = [];
    public monthList: any;

    static $inject = [
      "r_bankBalance",
      "r_bankAccounts",
      "LineChartConverterForDashboard",
      "TreasuryService",
      "SessionService",
      "$translatePartialLoader",
      "ModalUtil",
    ];

    constructor(
      r_bankBalance: any,
      r_bankAccounts: any,
      private lineChartConverterForDashboard: app.modules.lineargraphs.converter.LineChartConverterForDashboard,
      private treasuryService: TreasuryService,
      private sessionService: app.functionality.common.session.SessionService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private modalUtil: ModalUtil
    ) {
      $translatePartialLoader.addPart("customer/treasury");

      if (
        this.sessionService.session.company.importInfo !== null &&
        this.sessionService.session.company.importInfo.importDate !== null
      ) {
        this.bankBalance = r_bankBalance.data;
        this.initYearSelect();
        this.initMonthSelect(r_bankBalance.data[0].monthArr);
        this.initAccountSelect(r_bankAccounts.data);
        this.dataForLineChart =
          lineChartConverterForDashboard.convertForMainTreasury(
            this.bankBalance[0].monthArr,
            this.bankBalance[0].year,
            new Date(this.sessionService.session.company.importInfo.importDate)
          );
      } else if (this.sessionService.session.company.importInfo.state !== 0) {
        this.bankBalance = r_bankBalance.data;
        this.initYearSelect();
        this.initMonthSelect(r_bankBalance.data[0].monthArr);
        this.initAccountSelect(r_bankAccounts.data);
        this.dataForLineChart =
          lineChartConverterForDashboard.convertForMainTreasury(
            this.bankBalance[0].monthArr,
            this.bankBalance[0].year
          );
      } else {
        this.initYearSelect();
        this.initMonthSelect(r_bankBalance.data[0].monthArr);
        this.initAccountSelect(r_bankAccounts.data);
        this.bankBalance = r_bankBalance.data;
        this.dataForLineChart =
          lineChartConverterForDashboard.convertForMainTreasury(
            this.bankBalance[0].monthArr,
            this.bankBalance[0].year
          );
      }
    }

    public initMonthSelect(monthArr) {
      this.monthList = monthArr;
      let allMonthSelection = {
        month: 12,
      };
      this.monthList.push(allMonthSelection);
      this.monthSelected = this.monthList[this.monthList.length - 1];
    }

    public initAccountSelect(bankAccounts) {
      //workaround to get the "all" selector the right way
      let allAccounts = {
        key: "all",
      };
      bankAccounts.push(allAccounts);
      this.bankAccounts = bankAccounts;
      this.accountSelected = this.bankAccounts[this.bankAccounts.length - 1];
      //this.updateChartDataAccount(allAccounts,this.yearSelected);
    }

    public initYearSelect() {
      //TODO : what if quarterly mode
      let yearMap = {};
      let exerciceArr = this.sessionService.session.company.exerciceArr;

      for (let i = 0; i < exerciceArr.length; i++) {
        let ex = exerciceArr[i];
        if (ex.isQuarterlyMode) {
          let quarterlyArr = ex.quarterlyArr;
          for (let j = 0; j < quarterlyArr.length; j++) {
            let q = quarterlyArr[j];
            let periodArr = q.periodArr;
            for (let k = 0; k < periodArr.length; k++) {
              let p = periodArr[k];
              if (yearMap["" + p.year] == null) {
                yearMap["" + p.year] = p.year;
                this.yearList.push(p.year);
              }
            }
          }
        } else {
          let periodArr = ex.periodArr;
          for (let j = 0; j < periodArr.length; j++) {
            let p = periodArr[j];
            if (yearMap["" + p.year] == null) {
              yearMap["" + p.year] = p.year;
              this.yearList.push(p.year);
            }
          }
        }
      }
      this.yearSelected = this.yearList[this.yearList.length - 1];
    }
    // we don't need to make a request because all month are already in self.bankBalance
    public filterByMonth(monthSelected) {
      let monthData = this.bankBalance[0].monthArr[monthSelected.month];
      if (monthSelected.month === 12) {
        // all month
        if (
          this.sessionService.session.company.importInfo !== null &&
          this.sessionService.session.company.importInfo.importDate !== null
        ) {
          this.dataForLineChart =
            this.lineChartConverterForDashboard.convertForMainTreasury(
              this.bankBalance[0].monthArr,
              this.bankBalance[0].year,
              new Date(
                this.sessionService.session.company.importInfo.importDate
              )
            );
        } else {
          this.dataForLineChart =
            this.lineChartConverterForDashboard.convertForMainTreasury(
              this.bankBalance[0].monthArr,
              this.bankBalance[0].year
            );
        }
        return;
      } else if (monthData.dayArr == null) {
        // month in the future
        this.modalUtil.status(
          "TREASURY.BANKBALANCE.MODAL.NOMONTH.description",
          "TREASURY.BANKBALANCE.MODAL.NOMONTH.title"
        );
        this.monthSelected = this.monthList[this.monthList.length - 1];
        this.updateChartData(this.accountSelected, this.bankBalance[0].year);
        return;
      }
      this.dataForLineChart =
        this.lineChartConverterForDashboard.convertForMonthSelection(
          monthData.dayArr,
          this.bankBalance[0].year,
          monthSelected.month
        );
    }

    public updateChartDataYear(account, year) {
      this.monthSelected = this.monthList[this.monthList.length - 1];
      this.updateChartData(account, year);
    }

    public updateChartDataAccount(account, year) {
      this.monthSelected = this.monthList[this.monthList.length - 1];
      this.yearSelected = this.yearList[this.yearList.length - 1];
      this.updateChartData(account, this.yearSelected);
    }

    public updateChartData(account, year) {
      let self = this;
      self.treasuryService
        .getBankGraph(
          self.sessionService.session.sessionID,
          account.destAccount,
          year
        )
        .then(function (data) {
          let newData = data.plain();
          self.bankBalance = newData.data;
          if (
            self.sessionService.session.company.importInfo !== null &&
            self.sessionService.session.company.importInfo.importDate !== null
          ) {
            self.dataForLineChart =
              self.lineChartConverterForDashboard.convertForMainTreasury(
                self.bankBalance[0].monthArr,
                self.bankBalance[0].year,
                new Date(
                  self.sessionService.session.company.importInfo.importDate
                )
              );
          } else {
            self.dataForLineChart =
              self.lineChartConverterForDashboard.convertForMainTreasury(
                self.bankBalance[0].monthArr,
                self.bankBalance[0].year
              );
          }
        })
        .catch(function (error) {
          console.error("error ocurred");
          console.error(error);
        });
    }
  }
}

angular
  .module("app")
  .controller(
    "BankBalanceController",
    app.functionality.treasury.BankBalanceController
  );
