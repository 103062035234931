/// <reference path="../../_app.ts" />

module app.functionality.treasury {
  export class TreasuryController implements angular.IController {
    $onInit() {}

    public wcr: any;
    public dataForLineChart: any;
    public wc: any;
    public cashburn: any;
    public bankBalance: any;
    public cashburnTotal: any;
    public cashburnShown: any;
    public wcTotal = 0;
    public wcrTotal = 0;
    public cashburnDate: any;
    public country: string;

    static $inject = [
      "r_wcr",
      "r_wc",
      "r_cashburn",
      "r_bankBalance",
      "SessionService",
      "LineChartConverterForDashboard",
      "$translatePartialLoader",
    ];

    constructor(
      r_wcr: any,
      r_wc: any,
      r_cashburn: any,
      r_bankBalance: any,
      private sessionService: app.functionality.common.session.SessionService,
      lineChartConverterForDashboard: app.modules.lineargraphs.converter.LineChartConverterForDashboard,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService
    ) {
      $translatePartialLoader.addPart("customer/treasury");

      if (sessionService.session.company.country != null) {
        this.country = sessionService.session.company.country;
      } else {
        console.error("no country found");
      }
      if (
        this.sessionService.session.company.importInfo !== null &&
        this.sessionService.session.company.importInfo.importDate !== null
      ) {
        this.wcr = r_wcr.data;
        this.initWCRTotal(this.wcr);
        this.wc = r_wc.data;
        this.initWCTotal(this.wc);
        this.cashburn = r_cashburn.data;
        this.initCashburnTotal(this.cashburn);
        this.cashburnShown = this.initCashburnShown(this.cashburn);
        this.bankBalance = r_bankBalance.data;
        this.cashburnDate = this.sessionService.session.company.importInfo.importDate;
        this.dataForLineChart = lineChartConverterForDashboard.convertForMainTreasury(
          this.bankBalance[0].monthArr,
          this.bankBalance[0].year,
          new Date(this.cashburnDate)
        );
      } else if (this.sessionService.session.company.importInfo.state !== 0) {
        this.wcr = r_wcr.data;
        this.initWCRTotal(this.wcr);
        this.wc = r_wc.data;
        this.initWCTotal(this.wc);
        this.cashburn = r_cashburn.data;
        this.initCashburnTotal(this.cashburn);
        this.cashburnShown = this.initCashburnShown(this.cashburn);
        this.bankBalance = r_bankBalance.data;
        this.dataForLineChart = lineChartConverterForDashboard.convertForMainTreasury(
          this.bankBalance[0].monthArr,
          this.bankBalance[0].year
        );
        this.cashburnDate = undefined;
      }
    }

    // to strip the third unecessary option from initial cashburn array
    public initCashburnShown(cashburn) {
      let newCashburn = [];
      for (let i = 0; i < 2; i++) {
        newCashburn.push(cashburn[i]);
      }
      return newCashburn;
    }

    public initWCTotal(wc) {
      for (let i = 0; i < wc.length; i++) {
        if (!isNaN(wc[i].solde)) {
          this.wcTotal = this.wcTotal + wc[i].solde;
        }
      }
    }

    public initWCRTotal(wcr) {
      for (let i = 0; i < wcr.length; i++) {
        if (!isNaN(wcr[i].solde)) {
          this.wcrTotal = this.wcrTotal + wcr[i].solde;
        }
      }
    }

    public initCashburnTotal(cashburn) {
      if (cashburn[2] === true) {
        this.cashburnTotal = cashburn[0].solde + cashburn[1].solde;
      } else {
        this.cashburnTotal = " / ";
      }
    }
  }
}

angular
  .module("app")
  .controller(
    "TreasuryController",
    app.functionality.treasury.TreasuryController
  );
