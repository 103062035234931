/// <reference path="../../../_app.ts" />

module app.config.constants.roles {
  export class ConstantFeatures {
    public DASHBOARD01 = "DASHBOARD01"; // DASHBOARD

    public PROFIT = "PROFIT"; // PROFIT : RENTABILITE
    public PROFIT01 = "PROFIT01"; //Vue d'ensemble
    public PROFIT02 = "PROFIT02"; //Bilan
    public PROFIT03 = "PROFIT03"; //Compte de résultat
    public PROFIT04 = "PROFIT04"; //Chiffre d'affaires
    public PROFIT05 = "PROFIT05"; //Comparaison
    public PROFIT06 = "PROFIT06"; //Facture d'achat
    public PROFIT07 = "PROFIT07"; //Facture de vente

    public TREASURY = "TREASURY"; // TREASURY : TRESORERIE
    public TREASURY01 = "TREASURY01"; //Vue d'ensemble
    public TREASURY02 = "TREASURY02"; //Besoin en fonds de roulement
    public TREASURY03 = "TREASURY03"; //Fonds de roulement
    public TREASURY04 = "TREASURY04"; //Solde bancaire
    public TREASURY05 = "TREASURY05"; //Simulation

    public LIQUIDITY = "LIQUIDITY"; // LIQUIDITY : LIQUIDITE
    public LIQUIDITY01 = "LIQUIDITY01"; //Vue d'ensemble
    public LIQUIDITY02 = "LIQUIDITY02"; //Créances
    public LIQUIDITY03 = "LIQUIDITY03"; //Dettes
    public LIQUIDITY04 = "LIQUIDITY04"; //TVA

    public INVOICEREMINDER = "INVOICEREMINDER"; // INVOICEREMINDER : RAPPEL DE PAIMENT
    public INVOICEREMINDER01 = "INVOICEREMINDER01"; //Vue d'ensemble

    public INVOICING = "INVOICING"; // INVOICING : FACTURATION
    public INVOICING01 = "INVOICING01"; // Vue d'ensemble

    // OTHER : AUTRES
    public OTHERU = "OTHERU"; //Upload
    public OTHERARCH = "OTHERARCH"; //Archive
    public OTHERCONF = "OTHERCONF"; //Configuration
    public OTHERM = "OTHERM"; //Message
    public OTHERRS = "OTHERRS"; //Recherche avancée

    public OTHERPMF = "OTHERPMF";
    public PM = "PMF"; // Feature Added from V2 (based on subscription plan)
    // IPP
    public IPP = "IPP"; // IPP
  }
}

angular
  .module("app")
  .constant("FEATURES", new app.config.constants.roles.ConstantFeatures());
