/// <reference path="../../../_app.ts" />

module app.modules.lineargraphs.directive {

    import LineChartServiceUtil = app.modules.lineargraphs.util.LineChartServiceUtil;
    import LineChartAreaBuilder = app.modules.lineargraphs.builder.LineChartAreaBuilder;

    export class LineChartAreaDirective extends LineChartBaseDirective {
        
        static factory(): ng.IDirectiveFactory {
            const directive = ($window, chartBuilder: LineChartAreaBuilder, lineChartServiceUtil: LineChartServiceUtil,
                               $translate: ngt.ITranslateService, $rootScope) =>
                new LineChartAreaDirective($window, chartBuilder, lineChartServiceUtil, $translate, $rootScope);
            directive.$inject = ["$window", "LineChartBuilder", "LineChartServiceUtil", "$translate", "$rootScope"];

            return directive;
        }
        
    }
}

angular.module("linearChartModule").directive("linearChartArea", app.modules.lineargraphs.directive.LineChartAreaDirective.factory());
