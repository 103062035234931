/// <reference path="../../_app.ts" />`

module app.functionality.upload {
  import Invoice = app.model.Invoice;
  import UploadFolder = app.model.UploadFolder;
  import SessionService = app.functionality.common.session.SessionService;
  import TreeLevelConverter = app.functionality.common.converter.TreeLevelConverter;
  import UploadService = app.functionality.upload.UploadService;

  export class ArchiveController {
    public months: Array<boolean>;
    public uploadFolderActivate = true;
    public uigrid: any;
    public yearsData = [];
    public currentYear: any;
    public keyWord: string;
    public searchResult: any;
    public mapUploadFolder: any;
    private uploadFolderList: Array<UploadFolder>;
    private grid: any;
    private isFreemium: boolean = false;

    static $inject = [
      "r_invoicesByMonth",
      "$scope",
      "$uibModal",
      "UploadService",
      "TreeLevelConverter",
      "SessionService",
      "$translatePartialLoader",
      "$translate",
      "Notification",
      "$rootScope",
      "$window",
      "ENV",
      "RESTAPI",
    ];

    constructor(
      r_invoicesByMonth: any,
      public $scope: ng.IScope,
      public $uibModal: any,
      private uploadService: UploadService,
      private treeLevelConverter: TreeLevelConverter,
      private sessionService: SessionService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      public $translate: ngt.ITranslateService,
      public notification: any,
      public $rootScope: ng.IRootScopeService,
      public $window: angular.IWindowService,
      private ENV: app.config.constants.environment.ConstantEnv,
      private restApi: app.config.constants.ConstantRestApi
    ) {
      $translatePartialLoader.addPart("customer/upload");

      let self = this;

      $rootScope.$on("invoice_sent", function (event, invoice: Invoice) {
        self.updateInvoiceStatus(invoice);
      });

      //Init mapUploadFolder uploadFolderKey => uploadFolderName
      if (this.uploadFolderActivate) {
        this.initUploadFolder();
      }

      //Init for the select box
      let creationYear = new Date(
        <string>(<any>sessionService.session.company.creationDate)
      ).getFullYear();

      let thisYear = moment().year();
      while (creationYear <= thisYear) {
        this.yearsData.push(creationYear);
        creationYear++;
      }
      this.currentYear = this.yearsData[this.yearsData.length - 1];

      this.uigrid = this.setGridOptions($scope);
      //create the data with months and count of invoice
      this.uigrid.data = this.initMonths(r_invoicesByMonth.data);

      this.uigrid.virtualizationThreshold = this.uigrid.data.length;

      this.uigrid.columnDefs = [
        {
          field: "numMonth",
          displayName: "numMonth",
          enableHiding: true,
          width: "70%",
          cellTemplate: "tpl/grid/upload/archives/cellTemplate.html",
        },
        {
          field: "sendDate",
          displayName: "senddate",
          cellTemplate: "tpl/grid/upload/archives/cellDateTemplate.html",
        },
        {
          field: "uploader",
          displayName: "uploader",
          cellTemplate:
            "<div class='ui-grid-cell-contents text-center'>" +
            " <span class='invoiceWithoutUploadFolderKey'>" +
            " {{COL_FIELD}}</span></div>",
        },
      ];

      //Usefull for the lazy loading
      this.months = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      this.keyWord = "";
      this.searchResult = null;

      let subscription_plan = this.sessionService.getSubscriptionPlan();
      if (subscription_plan.plan.freemium) {
        this.isFreemium = subscription_plan.plan.freemium;
        this.uploadFolderActivate = false;
      } else {
        this.isFreemium = false;
      }
    }

    /**
     * Download folders available for the user
     */
    private initUploadFolder() {
      let self = this;

      this.uploadService
        .initUploadFolder(true)
        .then(function (data) {
          let tmpData = data.plain();
          self.uploadFolderList = tmpData.data;
          self.mapUploadFolder = {};
          for (let uploadFolder of self.uploadFolderList) {
            self.mapUploadFolder[uploadFolder.key] = uploadFolder.name;
          }
        })
        .catch(function (error) {
          console.error("error ocurred");
          console.error(error);
        });
    }

    public updateInvoiceStatus(invoice: Invoice) {
      let found: boolean = false;
      let rowMonth: any = null;
      let monthRowIndex: any;
      let monthUploadFolderRowIndex: any;

      let date: Date;
      if (invoice.sendDate != null) {
        date = new Date(<string>(<any>invoice.sendDate));
      } else {
        date = new Date(<string>(<any>invoice.uploadDate));
      }

      let numMonth = date.getMonth();

      if (this.months[numMonth - 1] === true) {
        //month alreadyLoaded
        for (let i = 0; i < this.uigrid.data.length; i++) {
          let row = this.uigrid.data[i];
          if (angular.isDefined(row.key)) {
            if (row.key === invoice.key) {
              row.sentStatus = invoice.sentStatus;
              if (invoice.sentStatus === 2) {
                row.fileKey = invoice.fileKey;
              }
              found = true;
              break;
            }
          }
          if (angular.isDefined(row.numMonth) && row.numMonth == numMonth) {
            monthRowIndex = i;
            rowMonth = row;
          } else if (
            this.uploadFolderActivate &&
            angular.isDefined(row.uploadFolderKey) &&
            row.uploadFolderKey === invoice.uploadFolderKey
          ) {
            monthUploadFolderRowIndex = i;
          } else if (
            angular.isDefined(row.numMonth) &&
            rowMonth != null &&
            row.numMonth === numMonth + 1
          ) {
            break;
          }
        }
        if (!found) {
          invoice.uploader = this.getNameUploader(invoice.uploaderKey);
          rowMonth.count = rowMonth.count + 1;
          if (this.uploadFolderActivate) {
            this.uigrid.data.splice(monthUploadFolderRowIndex + 1, 0, invoice);
          } else {
            this.uigrid.data.splice(monthRowIndex + 1, 0, invoice);
          }
        }
        this.grid.core.refresh();
      } else {
        let invoiceArrayData: any;
        let self = this;
        this.uploadService
          .getInvoice(numMonth, this.currentYear)
          .then(function (data) {
            invoiceArrayData = <Array<Invoice>>data.plain();
            for (let i = 0; i < self.uigrid.data.length; i++) {
              let row = self.uigrid.data[i];
              if (
                angular.isDefined(row.numMonth) &&
                row.numMonth === numMonth
              ) {
                row.count = invoiceArrayData.data.length + 1 + "";

                self.grid.core.refresh();
                break;
              }
            }
          })
          .catch(function (error) {
            console.error("error ocurred");
            console.error(error);
          });
      }
    }

    public newTicket(invoice: Invoice) {
      let self = this;
      this.$uibModal.open({
        templateUrl: "tpl/website/modals/newTicketModal.html",
        controller: "NewTicketModalController",
        controllerAs: "ticket",
        size: "md",
        backdrop: "static",
        keyboard: false,
        resolve: {
          r_data: function () {
            if (self.mapUploadFolder !== undefined) {
              return {
                invoice: invoice,
                name: self.getNameUploader(invoice.uploaderKey),
                uploadFolderName: self.mapUploadFolder[invoice.uploadFolderKey],
              };
            } else {
              return {
                invoice: invoice,
                name: self.getNameUploader(invoice.uploaderKey),
              };
            }
          },
        },
      });
    }

    public downloadInvoices(month: number, folderKey: string) {
      let self = this;
      this.$uibModal
        .open({
          templateUrl: "tpl/website/upload/confirmExportModal.html",
          controller: "ConfirmExportModalController",
          controllerAs: "confExportModalCtrl",
          //Permet de bloquer le click en dehors du modal
          backdrop: "static",
          //Permet de bloquer le escape
          keyboard: false,
          //modal-fit est une class définit dans le sass
          windowClass: "modal-fit",
          size: "sm",
          resolve: {},
        })
        .result.then((data) => {
          this.uploadService
            .downloadInvoices(this.currentYear + "", month + "", folderKey)
            .then(function (data) {
              // show message
              self.notification(
                self.$translate.instant("UPLOAD.ARCHIVES.mailSend")
              );
            })
            .catch(function (error) {
              console.error("error ocurred");
              console.error(error);
            });
        })
        .catch((error) => {
          console.error("send canceled", error);
        });
    }

    /**
     * Create the first data for the uigrid
     * @param countData : array of number of invoice per month
     * @returns {Array} : Array for uigrid data. Example => {"numMonth" : "01", "treeLevel": "0", "count" : "4"}
     */
    private initMonths(countData: any) {
      let monthData = [
        { numMonth: "0", treeLevel: "0", count: "0" },
        { numMonth: "1", treeLevel: "0", count: "0" },
        { numMonth: "2", treeLevel: "0", count: "0" },
        { numMonth: "3", treeLevel: "0", count: "0" },
        { numMonth: "4", treeLevel: "0", count: "0" },
        { numMonth: "5", treeLevel: "0", count: "0" },
        { numMonth: "6", treeLevel: "0", count: "0" },
        { numMonth: "7", treeLevel: "0", count: "0" },
        { numMonth: "8", treeLevel: "0", count: "0" },
        { numMonth: "9", treeLevel: "0", count: "0" },
        { numMonth: "10", treeLevel: "0", count: "0" },
        { numMonth: "11", treeLevel: "0", count: "0" },
      ];
      for (let i = 0; i < 12; i++) {
        monthData[i].count = countData[i];
      }
      monthData = this.treeLevelConverter.convert(monthData);
      return monthData;
    }

    /**
     * Init the uigrid options
     * @param $scope
     * @returns {
     * showTreeExpandNoChildren: boolean,
     * treeIndent: number,
     * enableFiltering: boolean,
     * enableColumnMenus: boolean,
     * enableSorting: boolean,
     * showHeader: boolean,
     * showFooter: boolean,
     * showTreeRowHeader: boolean,
     * onRegisterApi: ((gridApi:any)=>undefined)}
     */

    public setGridOptions($scope) {
      let self = this;
      return {
        showTreeExpandNoChildren: false,
        treeIndent: 1,
        enableFiltering: false,
        enableColumnMenus: false,
        enableSorting: false,
        showHeader: false,
        showFooter: false,
        showTreeRowHeader: false,
        rowHeight: 40,
        columnFooterHeight: 40,
        onRegisterApi: (gridApi) => {
          self.grid = gridApi;
          $scope.gridApi = gridApi;
        },
      };
    }

    /**
     *
     */
    public updateTable() {
      this.searchResult = null;
    }

    /**
     * Reset field keyword when delete the keyword in the search bar
     */
    public deleteKeywork() {
      this.keyWord = "";
    }

    /**
     * Update the uigrid data when a user
     * Go back in initMonth to update the count of invoices for each month
     */
    public updateYear() {
      this.months = [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ];
      let self = this;
      let r_uigrid_data: any;

      self.uploadService
        .getSentInvoiceByMonth(this.currentYear)
        .then(function (data) {
          r_uigrid_data = data.plain();
          self.uigrid.data = self.initMonths(r_uigrid_data.data);
        })
        .catch(function (error) {
          console.error("error ocurred");
          console.error(error);
        });

      if (this.keyWord !== "") {
        self.searchInvoice();
      }
    }

    /**
     * Send keywords and the year selected in the selectbox to the server to recover a result
     * The GUI will automatically hide the uigrid and display the search result
     * This automatic system compare if the field "searchResult" is !== null => archive.html
     */
    public searchInvoice() {
      let newData: any = null;
      let self = this;

      if (this.keyWord === undefined || this.keyWord === "") {
        return;
      }

      self.uploadService
        .searchForFiles(this.keyWord)
        .then(function (data) {
          console.error("data", data);
          newData = data.plain();

          self.searchResult = newData.data;
        })
        .catch(function (error) {
          console.error("error ocurred => search invoice for " + this.keyWord);
          console.error(error);
        });
    }

    /**
     * When a user click on a pdf to view it, the first step is to determine if he is on android, iphone, ... or PC
     * @param invoice selected
     */
    public detectDevicePdfViewer(invoice: Invoice) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        //URL PDF
        window.location.replace(invoice.url);
      } else {
        let fileExtension = "";
        if (invoice.name) {
          fileExtension = invoice.name.substring(invoice.name.lastIndexOf("."));
        } else if (invoice.title) {
          fileExtension = invoice.title.substring(
            invoice.title.lastIndexOf(".")
          );
        }

        if (fileExtension.toLowerCase() === ".pdf") {
          this.launchPdfViewerModal(invoice);
        } else {
          let fileLink =
            this.ENV.baseUrl +
            "/" +
            this.restApi.services.GDriveFileMgr +
            "?sessionID=" +
            this.sessionService.session.sessionID +
            "&companyKey=" +
            this.sessionService.session.company.key +
            "&fileName=" +
            encodeURI(invoice.name) +
            "&invoiceKey=" +
            invoice.key +
            "&isAttachment=false";

          window.open(fileLink, "_blank");
        }
      }
    }

    /**g
     *
     * @param invoice selected
     */
    private launchPdfViewerModal(invoice: Invoice) {
      this.$uibModal.open({
        templateUrl: "tpl/website/modals/pdfViewerModal.html",
        controller: "PdfViewerModalController",
        controllerAs: "pdfViewer",
        size: "lg",
        keyboard: "false",
        resolve: {
          r_data: function () {
            return {
              invoice: invoice,
            };
          },
          r_dataPMF: null,
        },
      });
    }

    public getNameUploader(key: string) {
      if (this.sessionService.session.member.key === key) {
        return this.$translate.instant("UPLOAD.TOSEND.me");
        // return this.sessionService.session.member.firstname + " " + this.sessionService.session.member.lastname;
      }

      for (let row of this.sessionService.accountantList) {
        if (row.key === key) {
          if (row.firstname == null && row.lastname == null) {
            return row.username;
          } else {
            return row.firstname + " " + row.lastname;
          }
        }
      }

      for (let row of this.sessionService.clientList) {
        if (row.key === key) {
          if (row.firstname == null && row.lastname == null) {
            return row.username;
          } else {
            return row.firstname + " " + row.lastname;
          }
        }
      }
    }

    /**
     * Recover invoices of a month selected by the user
     * @param numMonth : number of the month selected
     */
    public getInvoiceOfMonth(numMonth: number) {
      let self = this;

      //Check if the month selected was already selected
      //If it is already selected, the data are in the uigrid-data
      if (this.months[numMonth - 1] === true) {
        return;
      }

      //Update the array of months
      this.months[numMonth - 1] = true;

      let invoice: Invoice;
      let month: any;
      let invoiceArrayData: any;

      //For each uploadFolder.key => arrayInvoice
      let mapFolderKeyArrInvoices = {};
      let arrUploadFolderKey = Array<string>();
      let arrInvoiceWithoutUploadFolderKey = Array<Invoice>();

      self.uploadService
        .getInvoice(numMonth, this.currentYear)
        .then(function (data) {
          invoiceArrayData = data.plain();
          invoiceArrayData = <Array<Invoice>>invoiceArrayData.data;

          if (self.uploadFolderActivate) {
            //Dispatch each invoice in the good collection
            //invoice with uploadeFolderKey => mapFolderKeyArrInvoices
            //invoice without uploadFolderKey => arrInvoiceWithoutUploadFolderKey
            for (invoice of invoiceArrayData) {
              // why <string><any> ? --> http://stackoverflow.com/questions/34032303/typescript-type-casting-not-working
              invoice.sendDate = new Date(<string>(<any>invoice.sendDate));
              invoice.uploader = self.getNameUploader(invoice.uploaderKey);

              if (invoice.uploadFolderKey !== undefined) {
                //Recover the array of invoices inside the map

                let arrInvoice: Array<Invoice> =
                  mapFolderKeyArrInvoices[invoice.uploadFolderKey];

                //If the array doesn't exist, that means the invoice with its invoiceFolderKey is the first
                if (arrInvoice === undefined) {
                  arrInvoice = Array<Invoice>();
                  arrInvoice.push(invoice);
                  mapFolderKeyArrInvoices[invoice.uploadFolderKey] = arrInvoice;
                  arrUploadFolderKey.push(invoice.uploadFolderKey);
                } else {
                  //The array of invoices exists already, just push the invoice
                  arrInvoice.push(invoice);
                }
              } else {
                //Array for the invoices without uploadFolderKey
                arrInvoiceWithoutUploadFolderKey.push(invoice);
              }
            }

            //To keep the same order of uploadFolder name each display, sort array
            arrUploadFolderKey.sort(function (a, b) {
              if (a < b) {
                return -1;
              }
              if (a > b) {
                return 1;
              }
              return 0;
            });

            //Find index of the month in the uigridData to insert data in the right place
            let index = 0;
            for (month of self.uigrid.data) {
              if (month.numMonth === numMonth) {
                index = self.uigrid.data.indexOf(month);
              }
            }
            index++;

            //Display invoices with uploadFolderKey in the uigrid data
            let key = null;
            for (key of arrUploadFolderKey) {
              //First, display the folder name and add the treeLevel
              self.uigrid.data.splice(index, 0, {
                numMonth: numMonth,
                uploadFolderKey: key,
                $$treeLevel: "1",
              });
              index++;

              //Sort invoices by date
              let arrInvoice: Array<Invoice> = <Array<Invoice>>(
                mapFolderKeyArrInvoices[key]
              );
              arrInvoice.sort(function (a: any, b: any) {
                return a.sendDate - b.sendDate;
              });

              //display invoices
              for (invoice of arrInvoice) {
                self.uigrid.data.splice(index, 0, invoice);
                index++;
              }
            }

            let invoiceTmp: any = null;
            //sort arrInvoiceWithoutUploadFolderKey
            arrInvoiceWithoutUploadFolderKey.sort(function (a: any, b: any) {
              return a.sendDate - b.sendDate;
            });
            //Display invoices without uploadFolderKey
            for (invoiceTmp of arrInvoiceWithoutUploadFolderKey) {
              invoiceTmp.$$treeLevel = "1";
              self.uigrid.data.splice(index, 0, invoiceTmp);
              index++;
            }
            // !UploadFolderActivate
          } else {
            for (invoice of invoiceArrayData) {
              invoice.sendDate = new Date(<string>(<any>invoice.sendDate));
              arrInvoiceWithoutUploadFolderKey.push(invoice);
            }

            //Find index of the month in the uigridData
            let index = 0;
            for (month of self.uigrid.data) {
              if (month.numMonth === numMonth) {
                index = self.uigrid.data.indexOf(month);
              }
            }
            index++;

            //Sort invoices by date
            arrInvoiceWithoutUploadFolderKey.sort(function (a: any, b: any) {
              return a.sendDate - b.sendDate;
            });
            //display invoices
            for (invoice of arrInvoiceWithoutUploadFolderKey) {
              self.uigrid.data.splice(index, 0, invoice);
              index++;
            }
          }
        })
        .catch(function (error) {
          console.error("error ocurred");
          console.error(error);
        });
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller("ArchiveController", app.functionality.upload.ArchiveController);
