/// <reference path="../../../_app.ts" />

namespace app.config.constants.model {
	export class BookHistoricConst {
		public SALES = 1;
		public PURCHASES = 2;
		public BANK = 3;
		public OTHER = 4;
		public OPD = 5;
	}
}

angular.module("app").constant("BookHistoricConst", new app.config.constants.model.BookHistoricConst());
