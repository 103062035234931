/// <reference path="../_app.ts" />

module app.model {
  export class TicketAnswer {
    public ACCOUNTER_TYPE = 0;
    public CUSTOMER_TYPE = 1;
    public SYSTEM_TYPE = 2;

    public EDIT_CAT = 1001;
    public DELETE_CAT = 1002;
    public CLOSED_CAT = 1199;

    public key: string;
    public answererType: number;

    public answererKey: string;

    public authorKey: string;

    public freeAnswer: string;

    public answerDate: Date;

    public attachmentArr: Array<any>;

    public answerCategory: number;

    public visibleSenderKey?: string;

    constructor() {}
  }
}
