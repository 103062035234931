/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.services {
  import Reminder = app.model.invoiceReminder.Reminder;
  import ThirdWithOperations = app.model.invoiceReminder.ThirdWithOperations;
  import IReminder = app.model.interface.IReminder;
  import ITemplateReminder = app.model.interface.ITemplateReminder;
  import IPromise = angular.IPromise;

  /**
   * This type is used to map a reminder with the error returned by the server when we try send it
   * It is used to show an error to the user
   */
  export type ReminderWithErrorCode = { reminder: IReminder; code: number };

  /**
   * This service is used to encapsulate all the operations that are made to send a reminder by email
   * It goes from the creation of the reminders to the display of the errors that occured
   */

  export class SendReminderService {
    private userTemplates;
    private sessionID;
    private companyKey;
    private testingThirds: ThirdWithOperations[];
    private defaultEmail: string;

    static $inject = [
      "SessionService",
      "Notification",
      "$uibModal",
      "InvoiceReminderService",
      "$translate",
      "$state",
      "$q",
      "FilterService",
    ];

    constructor(
      private sessionService: app.functionality.common.session.SessionService,
      private notification,
      private $uibModal,
      private invoiceReminderService: InvoiceReminderService,
      private $translate: ngt.ITranslateService,
      private $state,
      private $q,
      private filterService: FilterService
    ) {
      this.defaultEmail = sessionService.session.member.mail;
      this.sessionID = sessionService.session.sessionID;
      this.companyKey = sessionService.session.company.key;
      this.getTemplates();
    }

    /**
     * This is the main function of the service
     * It takes an array of third with operations and send to each contact related to the third a reminder on the selected sales and lostPaiements
     * @param {app.model.invoiceReminder.ThirdWithOperations[]} thirdsToSent all the thirds we want to send a reminder to
     * @returns {any} An array of promise which is used to test if we got a response from the server for each reminder sent
     */
    public sendEmail(thirdsToSent: ThirdWithOperations[]) {
      let self = this;
      let reminders: Reminder[] = [];
      let promise;

      let hasNegativeAmount = self.createReminders(reminders, thirdsToSent);

      if (reminders.length == 0) {
        self.notification.primary({
          title: self.$translate.instant(
            "NOTIFICATIONS.INVOICEREMINDER.SENDINGREMINDER.errorSend"
          ),
          message: self.$translate.instant(
            "NOTIFICATIONS.INVOICEREMINDER.SENDINGREMINDER.emptyReminder"
          ),
          // templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
          delay: null,
        });
      } else {
        let warningMessage = hasNegativeAmount
          ? "MODAL.INVOICEREMINDER.SENDCONFIRMATION.negativeAmount"
          : "";
        let thirdNames: string[] = [];
        thirdsToSent.forEach((third) => thirdNames.push(third.third.fullName));

        promise = self
          .createInvoiceReminderSendingConfirmationModal(
            thirdNames,
            self.userTemplates,
            warningMessage
          )
          .then(function (template) {
            if (template != undefined) {
              return self.sendReminders(reminders, template);
            }
          });
      }
      return self.$q.all([promise]);
    }

    /**
     * It takes an array of third with operations and send to each contact related to the third a reminder on the selected sales and lostPaiements
     * @param {app.model.invoiceReminder.ThirdWithOperations[]} thirdsToSent all the thirds we want to send a reminder to
     * @returns {any} An array of promise which is used to test if we got a response from the server for each reminder sent
     */
    public sendTestEmail(
      thirdToSent: ThirdWithOperations,
      template: ITemplateReminder,
      errorMessage?: string
    ) {
      let self = this;
      let reminders: Reminder[] = [];
      let promise;
      let thirdsToSent: ThirdWithOperations[] = [];
      thirdsToSent.push(thirdToSent);
      this.testingThirds = thirdsToSent;
      let warningMessage = errorMessage ? errorMessage : "";
      let thirdNames: string[] = [];

      self.createReminders(reminders, thirdsToSent);

      if (reminders.length == 0) {
        promise = self
          .createInvoiceReminderTestModal(thirdNames, template, warningMessage)
          .then(function (email) {
            if (email != undefined && email != "") {
              return self.invoiceReminderService
                .sendDefaultTestReminder(
                  self.sessionService.session.sessionID,
                  template,
                  email
                )
                .then(function (response) {
                  if (response.status == 0) {
                    self.notification.primary({
                      title: self.$translate.instant(
                        "NOTIFICATIONS.INVOICEREMINDER.SENDINGREMINDER.sendOK"
                      ),
                      message: self.$translate.instant(
                        "NOTIFICATIONS.INVOICEREMINDER.SENDINGREMINDER.reminderSent"
                      ),
                      // templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html"
                    });
                  } else {
                    self.sendTestEmail(
                      thirdToSent,
                      template,
                      "MODAL.INVOICEREMINDER.TESTMODAL.detailsInvalidEmailAddress"
                    );
                  }
                });
            }
          });
      } else {
        thirdsToSent.forEach((third) => thirdNames.push(third.third.fullName));

        promise = self
          .createInvoiceReminderTestModal(thirdNames, template, warningMessage)
          .then(function (email) {
            if (email != undefined && email != "") {
              return self.sendReminders(reminders, template, email);
            }
          });
      }
      return self.$q.all([promise]);
    }

    /**
     * This function is used to create all the reminders that should be send
     * @param {app.model.interface.IReminder[]} reminders the array where the reminders created are stored
     * @param {app.model.invoiceReminder.ThirdWithOperations[]} thirdsToSent The thirds we want to send a reminder to
     * @returns {boolean} true if the total amount of the reminder is negative (if the amount of the selected payments is higher than the amount of the selected sales)
     */
    private createReminders(
      reminders: IReminder[],
      thirdsToSent: ThirdWithOperations[]
    ): boolean {
      let self = this;

      let hasNegativeAmount: boolean = false;
      thirdsToSent.forEach(function (tWO: ThirdWithOperations) {
        if (tWO) {
          let reminder: Reminder = new Reminder(
            self.sessionService.session.company.key,
            tWO.third.reference
          );
          tWO.sales.forEach(function (value) {
            if (value.isSelected) {
              reminder.addSale(value);
            }
          });
          tWO.lostPaiements.forEach(function (value) {
            if (value.isSelected) {
              reminder.addPayment(value);
            }
          });
          hasNegativeAmount = reminder.getAmountLeft() < 0;
          if (!reminder.isEmpty()) {
            reminders.push(reminder);
          }
        }
      });

      return hasNegativeAmount;
    }

    /**
     * This function is used to send more than one reminder when we know the template to use
     * @param {app.model.interface.IReminder[]} reminders an array that contains reminders
     * @param {app.model.interface.ITemplateReminder} template the template to use for all the reminders
     * @returns {any} An array of promise which is used to test if we got a response from the server for each reminder sent
     */
    public sendReminders(
      reminders: IReminder[],
      template: ITemplateReminder,
      email?: string
    ) {
      let self = this;
      let promises = [];
      let errorMessage: ReminderWithErrorCode[] = [];
      if (email) {
        reminders.forEach(function (reminder: IReminder) {
          promises.push(
            self.sendReminder(reminder, template, errorMessage, email)
          );
          return self.$q.all(promises).then(function () {
            self.$state.reload();
            self.displayTestSendingResult(errorMessage);
          });
        });
      } else {
        reminders.forEach(function (reminder: IReminder) {
          promises.push(self.sendReminder(reminder, template, errorMessage));
        });
        self.$q.all(promises).then(function () {
          self.displaySendingResult(errorMessage);
          self.filterService.updateMaxReminderNumber();
        });
      }
    }

    /**
     * This function is used to send one reminder
     * @param {app.model.interface.IReminder} reminderToSend the reminder to send
     * @param {app.model.interface.ITemplateReminder} templateChosen the template chosen for the reminder
     * @param {app.functionality.invoiceReminder.services.ReminderWithErrorCode[]} errorsToStore an array to store the error if we get one from the server
     * @returns {angular.IPromise<any>} The proof that we got a response from the server (use in the others functions to synchronise them all)
     */
    private sendReminder(
      reminderToSend: IReminder,
      templateChosen: ITemplateReminder,
      errorsToStore: ReminderWithErrorCode[],
      email?: string
    ): IPromise<any> {
      let self = this;
      reminderToSend.template = templateChosen;
      if (email) {
        let promise = self.invoiceReminderService
          .sendTestReminder(
            self.sessionService.session.sessionID,
            reminderToSend,
            email
          )
          .then(function (response) {
            if (response.status != 0) {
              errorsToStore.push({
                reminder: reminderToSend,
                code: response.status,
              });
            }
          })
          .catch(function () {
            errorsToStore.push({ reminder: reminderToSend, code: 1001 });
          });
        return promise;
      } else {
        let promise = self.invoiceReminderService
          .sendReminder(self.sessionService.session.sessionID, reminderToSend)
          .then(function (response) {
            if (response.status != 0) {
              errorsToStore.push({
                reminder: reminderToSend,
                code: response.status,
              });
            }
          })
          .catch(function () {
            errorsToStore.push({ reminder: reminderToSend, code: 1001 });
          });
        return promise;
      }
    }

    /**
     * This function is used to inform the user if everything was alright
     * If yes, there is pop up like "Ok, everything is fine"
     * If not, we display an error modal
     * @param {app.functionality.invoiceReminder.services.ReminderWithErrorCode[]} errors
     */
    private displaySendingResult(errors: ReminderWithErrorCode[]) {
      let self = this;

      if (errors.length == 0) {
        self.notification.primary({
          title: self.$translate.instant(
            "NOTIFICATIONS.INVOICEREMINDER.SENDINGREMINDER.sendOK"
          ),
          message: self.$translate.instant(
            "NOTIFICATIONS.INVOICEREMINDER.SENDINGREMINDER.reminderSent"
          ),
          // templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html"
        });
      } else {
        self.createInvoiceReminderErrorModal(errors);
      }
    }

    /**
     * This function is used to inform the user if everything was alright
     * If yes, there is pop up like "Ok, everything is fine"
     * If not, we display the same madal with an error message
     * @param {app.functionality.invoiceReminder.services.ReminderWithErrorCode[]} errors
     */
    private displayTestSendingResult(errors: ReminderWithErrorCode[]) {
      let self = this;
      if (errors.length == 0) {
        self.notification.primary({
          title: self.$translate.instant(
            "NOTIFICATIONS.INVOICEREMINDER.SENDINGREMINDER.sendOK"
          ),
          message: self.$translate.instant(
            "NOTIFICATIONS.INVOICEREMINDER.SENDINGREMINDER.reminderSent"
          ),
          // templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html"
        });
      } else {
        this.sendTestEmail(
          this.testingThirds[0],
          errors[0].reminder.template,
          "MODAL.INVOICEREMINDER.TESTMODAL.detailsInvalidEmailAddress"
        );
      }
    }

    /**
     * This function is used to show a modal asking the user which template he wants to use
     * @param thirdNames the name of the Third you wants to send a reminder to
     * @param templates all the templates of the current user
     * @param warningMessage a message that can be displayed in the modal to warn the user
     * @returns {any} The template chose by the user
     */
    public createInvoiceReminderSendingConfirmationModal(
      thirdNames: string[],
      templates,
      warningMessage: string
    ) {
      return this.$uibModal
        .open({
          templateUrl:
            "tpl/website/invoiceReminder/modal/sendingConfirmationModal.html",
          controller: "SendingConfirmationModalController",
          controllerAs: "scmC",
          size: "md",
          resolve: {
            thirdNames: function () {
              return thirdNames;
            },
            templates: function () {
              return templates;
            },
            warningMessage: function () {
              return warningMessage;
            },
          },
        })
        .result.then(function (data) {
          return data;
        });
    }

    /**
     * This function is used to show a modal asking the address to send a test email to
     * @param thirdNames the name of the Third you want to send a reminder to
     * @param template the current editing template
     * @param warningMessage a message that can be displayed in the modal to warn the user
     * @returns {any} The test email address
     */
    public createInvoiceReminderTestModal(
      thirdNames: string[],
      template,
      warningMessage: string
    ) {
      let self = this;
      return this.$uibModal
        .open({
          templateUrl: "tpl/website/invoiceReminder/modal/testModal.html",
          controller: "TestModalController",
          controllerAs: "tmC",
          size: "md",
          resolve: {
            thirdNames: function () {
              return thirdNames;
            },
            template: function () {
              return template;
            },
            warningMessage: function () {
              return warningMessage;
            },
            defaultEmail: function () {
              return self.sessionService.session.member.mail;
            },
          },
        })
        .result.then(function (data) {
          return data;
        });
    }

    /**
     * Create an error modal which inform the user of the error for each reminder
     * @param {app.functionality.invoiceReminder.services.ReminderWithErrorCode[]} errorsToDisplay
     */
    public createInvoiceReminderErrorModal(
      errorsToDisplay: ReminderWithErrorCode[]
    ) {
      this.$uibModal.open({
        templateUrl:
          "tpl/website/invoiceReminder/modal/errorSendingReminderModal.html",
        controller: "InvoiceReminderErrorSendingReminderModalController",
        controllerAs: "iresrmC",
        size: "md",
        resolve: {
          errorsToDisplay: function () {
            return errorsToDisplay;
          },
        },
      });
    }

    /**
     * This method is used to get the users templates.
     * It is called at the creation of the service and when the user update one of his template.
     */
    public getTemplates() {
      this.userTemplates = this.invoiceReminderService
        .getUserTemplates(this.sessionService.session.sessionID)
        .then(function (data) {
          return data.plain().data;
        });
    }
  }

  angular
    .module("app")
    .service(
      "SendReminderService",
      app.functionality.invoiceReminder.services.SendReminderService
    );
}
