/// <reference path="../../_app.ts" />

module app.model.parameter {
    import GroupAccount = app.model.exchange.GroupAccount;

    export class DashboardReport {

        classStructure : any = {
            vatToPaid: app.model.exchange.GroupAccount,
            vatToRetrieve: app.model.exchange.GroupAccount,
            vatBalance: app.model.exchange.GroupAccount,
            regul: app.model.exchange.GroupAccount,
            periodArr: app.model.Period
        };
        public key: string;
        public companyKey: string;
        public start: Date;
        public solde: number;
        public liquidityClients: Array <number>;
        public liquiditySuppliers: Array <number>;
        public turnover: number;
        public costs: number;
        public bfr: number;
        public fr: number;

        public vatToPaid: GroupAccount;
        public vatToRetrieve: GroupAccount;
        public vatBalance: GroupAccount;
        public regul: GroupAccount;

        public vat: number;
        public deadline: Date;
        public cashburn: number;
        public foundPeriodCashBurn: boolean;
        public periodArr: Array <Period>;
        public accountArr: Array <number>;
        public thirdArr: Array <String>;

        constructor() {
        }

    }
}