/// <reference path="../../_app.ts" />

module app.functionality.permanentfolder {

    import SessionService = app.functionality.common.session.SessionService;

    export class WarnClientPMFController {

        public context: any;

        public folderName: string;
        public contentMail: string;
        public titleMail: string;
        public showFile: boolean;
        public path: string;

        static $inject = [
            "$uibModalInstance",
            "SessionService",
            "$translatePartialLoader",
            "$filter",
            "r_data",
            "$translate"
        ];

        constructor(private $uibModalInstance: any,
                    public sessionService: SessionService,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService,
                    public $filter: any,
                    public r_data: any,
                    private $translate: ngt.ITranslateService) {
            $translatePartialLoader.addPart("customer/permanentFolder");
            if(r_data !== undefined && r_data.insideData !== undefined && r_data.insideData !== {}) {
                if(r_data.insideData.path !== undefined) {
                    if(!r_data.insideData.data) {
                        this.path = r_data.insideData.path;
                        this.showFile = true;
                        this.titleMail = this.sessionService.session.company.name+""+
                            this.$translate.instant("PERMANENTFOLDER.MODAL.titleGenerated_file");
                        this.contentMail = this.$translate.instant("PERMANENTFOLDER.MODAL.hello")+"\n"+this.$translate.instant("PERMANENTFOLDER.MODAL.contentGenerated_file_1")
                            +" "+this.path+""+r_data.insideData.name+" "
                            +this.$translate.instant("PERMANENTFOLDER.MODAL.contentGenerated_file_2")+"\n"+this.$translate.instant("PERMANENTFOLDER.MODAL.contentGenerated_file_3");
                    } else {
                        this.showFile = false;
                        this.path = r_data.insideData.path;
                        this.titleMail = this.sessionService.session.company.name+""+
                            this.$translate.instant("PERMANENTFOLDER.MODAL.titleGenerated_folder");
                        this.contentMail = this.$translate.instant("PERMANENTFOLDER.MODAL.hello")+"\n"+this.$translate.instant("PERMANENTFOLDER.MODAL.contentGenerated_folder_1")
                            +" "+r_data.insideData.path+" "
                            +this.$translate.instant("PERMANENTFOLDER.MODAL.contentGenerated_folder_2")+"\n"+this.$translate.instant("PERMANENTFOLDER.MODAL.contentGenerated_folder_3");
                    }
                } else {
                    this.titleMail= "";
                    this.contentMail= "";
                }
            } else {
                this.titleMail= "";
                this.contentMail= "";
            }
        }

    ok = () => {
        this.$uibModalInstance.close({content: this.contentMail,title: this.titleMail,treeLevel: this.r_data.insideData.treeLevel,isFolder: this.r_data.insideData.data,path: this.r_data.insideData.path,filename: this.r_data.insideData.name});
    }

    cancel = () => {
        this.$uibModalInstance.dismiss();
    }
}
}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("WarnClientPMFController", app.functionality.permanentfolder.WarnClientPMFController);
