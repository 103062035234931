/// <reference path="../../../_app.ts" />

namespace app.functionality.common.modals {
  export class ModalUtil {
    // for confirm modal
    private modalDefaults: any;
    private modalOptions: any;

    static $inject = ["$uibModal", "$translatePartialLoader", "$state"];
    constructor(
      private $uibModal,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $state: ng.ui.IStateService
    ) {
      // "$translate",
      // private $translate: ngt.ITranslateService,
      $translatePartialLoader.addPart("customer/modals");
      this.modalDefaults = {
        backdrop: true,
        keyboard: true,
        modalFade: true,
        size: "sm",
        templateUrl: "tpl/website/modals/confirm.html",
      };

      this.modalOptions = {
        closeButtonText: "MODAL.DEFAULT.cancelBtn", // Annuler
        actionButtonText: "MODAL.DEFAULT.confirmBtn", // Confimer
        headerText: "MODAL.DEFAULT.continue", // Continuer?
        bodyText: "MODAL.DEFAULT.confirmAction", // Effectuer cette action?
      };
    }

    public openModal = (
      size: string,
      context: any,
      templateUrl: string,
      controller: string,
      controllerAs: string,
      callbackSuccess: Function,
      callbackError: Function,
      type: string
    ) => {
      let modalInstance = this.$uibModal.open({
        animation: true,
        backdrop: true,
        templateUrl: templateUrl,
        controller: controller,
        size: size,
        resolve: {
          r_context: function () {
            return context;
          },
          r_type: function () {
            return type;
          },
        },
        controllerAs: controllerAs,
      });

      modalInstance.result.then(
        function (result) {
          callbackSuccess(result);
        },
        function (result) {
          callbackError(result);
        }
      );
    };

    public showConfirm(
      customModalDefaults,
      customModalOptions,
      callbackSuccess: Function,
      callbackError: Function,
      modalObject?
    ) {
      if (!customModalDefaults) {
        customModalDefaults = {};
      }
      customModalDefaults.backdrop = "static";

      if (modalObject) {
        return this.confirm(
          customModalDefaults,
          customModalOptions,
          modalObject
        ).then(
          function (result) {
            callbackSuccess(result);
          },
          function (result) {
            callbackError(result);
          }
        );
      } else {
        return this.confirm(customModalDefaults, customModalOptions).then(
          function (result) {
            callbackSuccess(result);
          },
          function (result) {
            callbackError(result);
          }
        );
      }
    }

    private confirm(customModalDefaults, customModalOptions, modalObject?) {
      //Create temp objects to work with since we're in a singleton service
      let tempModalDefaults = <any>{};
      let tempModalOptions = <any>{};

      //Map angular-ui modal custom defaults to modal defaults defined in service
      angular.extend(
        tempModalDefaults,
        this.modalDefaults,
        customModalDefaults
      );

      //Map modal.html $scope custom properties to defaults defined in service
      angular.extend(tempModalOptions, this.modalOptions, customModalOptions);

      if (!tempModalDefaults.controller) {
        tempModalDefaults.controller = function ($scope, $uibModalInstance) {
          $scope.modalOptions = tempModalOptions;

          if (modalObject) {
            $scope.modalOptions.object = modalObject;
          }

          $scope.modalOptions.ok = function (result) {
            $uibModalInstance.close(result);
          };
          $scope.modalOptions.close = function (result) {
            $uibModalInstance.dismiss("canceled");
          };
        };
      }

      return this.$uibModal.open(tempModalDefaults).result;
    }

    // id of status message to translate, title of the modal, and optional parameters to cancel the automatic translate of the message
    public status(message, title, alreadyTranslated?) {
      let statusModal = this.$uibModal.open({
        templateUrl: "tpl/website/modals/statusModal.html",
        controller: "StatusModalController",
        controllerAs: "status",
        size: "sm",
        backdrop: "static",
        keyboard: false,
        resolve: {
          r_data: function () {
            return {
              message: message,
              title: title,
              alreadyTranslated: alreadyTranslated,
            };
          },
        },
      });
      return statusModal.result;
    }

    public support() {
      this.$uibModal.open({
        templateUrl: "tpl/website/modals/supportModal.html",
        controller: "SupportModalController",
        controllerAs: "support",
        size: "md",
      });
    }

    public detail(data, row, col, state) {
      this.$uibModal.open({
        templateUrl: "tpl/website/modals/detailModal.html",
        controller: "DetailModalController",
        controllerAs: "detail",
        size: "lg",
        resolve: {
          r_data: function () {
            return {
              gridData: data,
              row: row,
              col: col,
              state: state,
            };
          },
        },
      });
    }

    public checkInfo(data, response, force) {
      return this.$uibModal.open({
        templateUrl: "tpl/website/modals/regulationModal.html",
        controller: "RegulationModalController",
        controllerAs: "regulation",
        size: "lg",
        resolve: {
          r_data: function () {
            return {
              data: data,
              response: response,
              force: force,
            };
          },
        },
      });
    }

    public message(row) {
      this.$uibModal.open({
        templateUrl: "tpl/website/modals/newTicketModal.html",
        controller: "NewTicketModalController",
        controllerAs: "ticket",
        size: "md",
        backdrop: "static",
        resolve: {
          r_data: function () {
            return {
              row: row,
            };
          },
        },
      });
    }

    // public goToIppModal() {
    // 	return this.$uibModal.open({
    // 		templateUrl: "tpl/IPPForm/confirmGoToIpp.html",
    // 		controller: "ConfirmModalController",
    // 		controllerAs: "confModalCtrl",
    // 		//Permet de bloquer le click en dehors du modal
    // 		backdrop: "static",
    // 		//Permet de bloquer le escape
    // 		keyboard: false,
    // 		//modal-fit est une class définit dans le sass
    // 		windowClass: "adjust-ipp-modal",
    // 		size: "sm",
    // 		resolve: {
    // 			data: function() {
    // 				retrun
    // 			}
    // 		}
    // 	});
    // }

    /*public expiredRefreshToken() {
		 let self = this;
		 let expired = this.$uibModal.open({
		 controller: function($scope, $uibModalInstance) {
		 $scope.ok = function() {
		 $uibModalInstance.close();
		 };
		 },
		 template:
		 "<div class='modal-header'>" +
		 "    <span class='modal-title'>" +
		 "        {{'MODAL.EXPIRED_TOKEN.title' | translate}}" +trad+
		 "    </span>" +
		 "</div>" +
		 "<div class='modal-body'>" +
		 "    <p>{{'MODAL.EXPIRED_TOKEN.description' | translate}}</p>" +
		 "</div>" +
		 "<div class='modal-footer'>" +
		 "    <button class='btn btn-primary'" +
		 "            ng-click='ok()'" +
		 "            data-dismiss='modal'>" +
		 "        {{'MODAL.EXPIRED_TOKEN.confirmBtn' | translate}}" +
		 "    </button>" +
		 "</div>",
		 size: "sm",
		 backdrop: "static",
		 keyboard: false,
		 animation: false
		 });

		 return expired.result;
		 }*/
  }
}

angular
  .module("app")
  .service("ModalUtil", app.functionality.common.modals.ModalUtil);
