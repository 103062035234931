/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.controllers {
  import ThirdWithOperations = app.model.invoiceReminder.ThirdWithOperations;
  import FilterService = app.functionality.invoiceReminder.services.FilterService;
  import SessionService = app.functionality.common.session.SessionService;

  export class InvoiceReminderOverviewController
    implements angular.IController
  {
    public pageTitle: string;

    /**
     * Represents the thirds with operations that should be displayed (used with the filter system)
     */
    public thirdsWithOperations: ThirdWithOperations[];
    public selectedThirds: ThirdWithOperations[];
    public allThirdsChecked: boolean;
    public dueAmountSum: number;

    public isLoading: boolean;

    $onInit() {}

    static $inject = [
      "r_dataThirdsWithOperations",
      "r_title",
      "$translatePartialLoader",
      "$filter",
      "$translate",
      "ModalQuickstartService",
      "SendReminderService",
      "FilterService",
      "SessionService",
      "$scope",
    ];

    constructor(
      r_dataThirdsWithOperations: ThirdWithOperations[],
      r_title: string,
      private $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $filter,
      private $translate: ngt.ITranslateService,
      modalQuickstartService: app.functionality.invoiceReminder.services.ModalQuickstartService,
      private sendReminderService: app.functionality.invoiceReminder.services.SendReminderService,
      private filterService: FilterService,
      private sessionService: SessionService,
      $scope
    ) {
      let self = this;
      $translatePartialLoader.addPart("customer/invoiceReminder");
      this.pageTitle = r_title;

      if (!this.sessionService.isInvoiceRemindersInit())
        this.sessionService.setInvoiceReminders(r_dataThirdsWithOperations);
      self.thirdsWithOperations = [];
      self.selectedThirds = [];
      self.allThirdsChecked = false;
      self.dueAmountSum = 0;

      $scope.$watch(
        () => this.filterService.thirdsWithOperations,
        (newValue: ThirdWithOperations[]) => {
          self.thirdsWithOperations = newValue;
          this.checkAllThirds(true);
          // this.checkAllSelectedThirdsOnChanges();
          this.allThirdsChecked = self.verifyAllTirdsChecked();
        }
      );
      this.filterService.initService(
        r_dataThirdsWithOperations,
        true,
        function () {
          self.checkAllThirds(true);
          // this.checkAllSelectedThirdsOnChanges();
          self.allThirdsChecked = self.verifyAllTirdsChecked();
        }
      );
      modalQuickstartService.showDialogIfQuickstart();

      this.isLoading = false;
    }

    /**
     * This function is used to get the tooltip text for the sales
     * @param {app.model.invoiceReminder.ThirdWithOperations} third the current third in the row
     * @returns {string} a formatted string that represents all the sales the third made
     */
    public getFormatedSalesTooltip(third: ThirdWithOperations): string {
      let self = this;
      let sRet = "";

      sRet +=
        "<b>" +
        self.$translate.instant("INVOICEREMINDER.OVERVIEW.unpaid") +
        "</b><br/>";

      third.sales.forEach(function (value) {
        if (third.isSaleDue(value, self.filterService.selectedDate)) {
          sRet +=
            value.comment +
            " : " +
            '<span class="positiveAmountDisplayIR">' +
            self.$filter("number")(value.amount, 2) +
            " €</span><br/>";
        }
      });

      return sRet.replace("'", " ");
    }

    /**
     * This function is used to get the tooltip text for the lost payments
     * @param {app.model.invoiceReminder.ThirdWithOperations} third the current third in the row
     * @returns {string} a formatted string that represents all the payments the third made
     */
    public getFormatedLostPaiementsTooltip(third: ThirdWithOperations): string {
      let self = this;

      if (third.lostPaiements.length > 0) {
        let sRet = "<br/>";
        sRet +=
          "<b>" +
          self.$translate.instant("INVOICEREMINDER.OVERVIEW.lostPaiements") +
          "</b><br/>";
        third.lostPaiements.forEach(function (value) {
          sRet +=
            value.comment +
            " : " +
            '<span class="negativeAmountDisplayIR">' +
            self.$filter("number")(value.amount, 2) +
            " €</span><br/>";
        });
        return sRet.replace("'", " ");
      }
    }

    /**
     * This function is called when the user wants to send a reminder
     * It selects all the sales and payments of the thirds the user wants to send a reminder to
     * Then it calls the SendReminderService in order to send the reminder
     */
    public sendEmail() {
      this.sendReminderService.sendEmail(this.selectedThirds);

      this.selectedThirds.forEach((selectedThird) => {
        this.thirdsWithOperations[
          this.thirdsWithOperations.indexOf(selectedThird)
        ].sales.forEach((sale) => sale.reminderNumber++);
      });
      this.sessionService.setInvoiceReminders([]);
    }

    /**
     * This function is used to check all the thirds (in order to send them in a reminder)
     * @param {boolean} selected : True if the third should be selected
     */
    public checkAllThirds(selected: boolean) {
      let self = this;
      this.selectedThirds = [];
      this.dueAmountSum = 0;
      this.thirdsWithOperations.forEach(function (value) {
        value.isSelected = selected;
        self.checkThird(value);
      });
    }

    /**
     * This function is used to check a third in order to send a reminder
     * @param {app.model.invoiceReminder.ThirdWithOperations} third the third the user wants to send a reminder to
     */
    public checkThird(third: ThirdWithOperations) {
      let alreadySelected = false;
      this.selectedThirds.forEach((selectedThird) => {
        if (selectedThird.third.key === third.third.key) {
          alreadySelected = true;
        }
      });
      if (third.isSelected) {
        if (!alreadySelected) {
          third.dueAmountSum = third.getMaxDueAmount(
            this.filterService.selectedDate
          );
          this.dueAmountSum = this.dueAmountSum + third.dueAmountSum;
          this.selectedThirds.push(third);
        }
      } else {
        if (alreadySelected) {
          for (let item of this.selectedThirds) {
            if (item.third.key === third.third.key) {
              third.dueAmountSum = item.dueAmountSum;
              break;
            }
          }

          this.dueAmountSum = this.dueAmountSum - third.dueAmountSum;
          this.selectedThirds = this.selectedThirds.filter(function (item) {
            return item.third.key !== third.third.key;
          });
        }
      }
      this.allThirdsChecked = this.verifyAllTirdsChecked();
    }

    public updateData() {
      this.filterService.restrictData();
    }

    public verifyAllTirdsChecked(): boolean {
      for (let i = 0; i < this.thirdsWithOperations.length; i++) {
        if (!this.thirdsWithOperations[i].isSelected) {
          return false;
        }
      }
      return true;
    }

    public setScrollPosition(position: number) {
      localStorage.setItem("scrollPosition", position.toString());
    }

    public getScrollPosition(): number {
      return parseInt(localStorage.getItem("scrollPosition"), 10);
    }

    public scroll() {
      document.getElementById("tbody").scrollTop = this.getScrollPosition();
    }

    public adjustScrollPosition() {
      let self = this;
      setTimeout(() => {
        let timer = null;
        document.getElementById("tbody").addEventListener(
          "scroll",
          function () {
            if (timer !== null) {
              clearTimeout(timer);
            }
            timer = setTimeout(function () {
              self.setScrollPosition(
                document.getElementById("tbody").scrollTop
              );
            }, 150);
          },
          false
        );
        self.scroll();
      });
    }
  }
}
angular
  .module("app")
  .controller(
    "InvoiceReminderOverviewController",
    app.functionality.invoiceReminder.controllers
      .InvoiceReminderOverviewController
  );
