/// <reference path="../../_app.ts" />

namespace app.functionality.IPPForm {
  import IPPFormService = app.functionality.IPPForm.IPPFormService;
  import SessionService = app.functionality.common.session.SessionService;
  import Member = app.model.Member;

  export class IPPFormFactory {
    static $inject = [
      "$translate",
      "$stateParams",
      "IPPFormService",
      "$timeout",
      "$state",
      "$window",
      "$http",
      "$translatePartialLoader",
      "SessionService",
      "$uibModal",
    ];

    public m: Member;
    public childrenToClean;

    constructor(
      public $translate: ngt.ITranslateService,
      protected $stateParams: ng.ui.IStateParamsService,
      public IPPService: IPPFormService,
      private $timeout: ng.ITimeoutService,
      private $state: ng.ui.IStateService,
      public $window,
      protected $http: any,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private sessionService: app.functionality.common.session.SessionService,
      private $uibModal: any
    ) {
      this.m = sessionService.getCurrentSessionMember();
      this.childrenToClean = [];
    }

    public factoryFields(step, fieldsManager, fields, data) {
      angular.forEach(step.fields, (field) => {
        //si le fieldsManager est null ou undefined alors on push. Utilisé pour les anciens dossiers sans fieldsManager
        //temporary solution for the step 310 not showing the country as it's Belgium
        if (fieldsManager == null) {
          fields.push(this.fieldFill(field, data, fieldsManager, step));
        } else {
          //on attribue l'index à une variable si elle existe en base de donnée et est activée
          let indexField = fieldsManager.fields.findIndex(function (element) {
            return element.key_field === field.key_field && element.active;
          });
          //TODO check si l'insertion se fait correctement en cas de changement d'ordre
          if (indexField >= 0) {
            //on insère l'objet grâce à splice et une valeur de suppression de 0
            fields.splice(
              indexField,
              0,
              this.fieldFill(field, data, fieldsManager, step)
            );
          }
        }
      });
      return fields;
    }

    /**
     * Permet de remplir les champs du formly avec les attributs du field
     * @param field, le champs à insérer
     */
    public fieldFill(field, data, fieldsManager, step) {
      let fieldToReturn = {};
      let expressionProperties = {};

      fieldToReturn.key = field.key_field;
      fieldToReturn.type = field.type;
      //cas particulier où on a besoin de l'année dans le label
      if (field.key_field === "status") {
        let year = data.fiscalYear + 1;
        if (step.legislation === "LU") {
          year = data.fiscalYear;
        }
        expressionProperties["templateOptions.label"] = () =>
          this.getTranslation(field.label) + " " + year + " ?";
      } else if (field.key_field === "statusChanged") {
        expressionProperties["templateOptions.label"] = () =>
          this.getTranslation(field.label) + " " + data.fiscalYear + " ?";
      } else {
        expressionProperties["templateOptions.label"] = () =>
          this.getTranslation(field.label);
      }

      expressionProperties["templateOptions.required"] = () => field.required;

      if (field.type === "radioInline" || field.type === "horizontalSelect") {
        expressionProperties["templateOptions.pattern"] = () => field.pattern;
      }
      if (field.options != null) {
        for (let i = 0; i < field.options.length; i++) {
          expressionProperties["templateOptions.options[" + i + "].name"] =
            () => this.getTranslation(field.options[i].name);
          expressionProperties["templateOptions.options[" + i + "].value"] =
            () => field.options[i].value;
        }
      }
      if (field.description != null) {
        expressionProperties["templateOptions.descriptions"] = () =>
          this.getTranslation(field.description);
      }

      if (field.type === "buttonFile") {
        fieldToReturn.templateOptions = {
          ctrl: this,
          modelKeyArray: field.key_field,
          sessionID: this.sessionService.session.sessionID,
          data: data,
        };
        fieldToReturn.link = ($scope, element) => {
          element.on("change", (changeEvent) =>
            this.IPPService.launchIPPUploadModal(
              changeEvent.target,
              field.key_field,
              data
            )
          );
        };
      }

      if (field.tooltip) {
        expressionProperties["templateOptions.tooltip.content"] = () =>
          field.tooltip;
      }

      if (field.type === "grid") {
        fieldToReturn.noFormControl = true;
        fieldToReturn.templateOptions = {
          labelKeyArr: field.labelKeyArr,
          ctrl: this,
          modelKeyArray: field.modelKeyArr,
          hasSubKey: field.hasSubKey,
          variableObject: field.variableObject,
          step: field.subStep,
          data: data,
          fieldsManager: fieldsManager,
          icon: field.icon,
          ancestor: field.condition.key_condition,
        };
      }

      if (field.type === "horizontalInputGrid") {
        this.childrenToClean.push(field.modelKeyArr);

        fieldToReturn.noFormControl = true;
        fieldToReturn.templateOptions = {
          labelKeyArr: field.labelKeyArr,
          ctrl: this,
          modelKeyArr: field.modelKeyArr,
          variableObject: field.variableObject,
          step: field.subStep,
          data: data,
          hasSubKey: field.hasSubKey,
          fieldsManager: fieldsManager,
        };
      }

      fieldToReturn.expressionProperties = expressionProperties;

      if (field.condition != null) {
        fieldToReturn.hideExpression = () =>
          this.IPPService.resolveSetOfConditionsModal(field.condition, data);
      }

      if (field.pattern) {
        fieldToReturn["ng-pattern"] = "/^[0-9]*$/";
      }
      /*
            if (field.pattern) {
                fieldToReturn["validators"] = {
                    number: {
                        expression: (viewValue, modelValue, theScope) => {
                            let value = modelValue || viewValue;
                            // let pattern: string = this.iPPService.getField(this.step310.fields, this.fields.step310.percentage_property_rented.key).pattern;
                            let correctPattern = `(^\\d{1,3}[.,]\\d{1,2}$)|(^\\d{1,3}$)`;
                            let reg: RegExp = new RegExp(correctPattern);
                            let result: boolean = reg.test(value);
                            return result;
                        },
                        //à remplacer par this.getTranslation(this.m,quelque chose)
                            message: () => this.$translate.instant("IPP_FORM.mustBeANumber")
                    }
                };
            }
            */
      if (field.children != null) {
        fieldToReturn.watcher = {
          listener: (theField) => {
            if (theField.value !== undefined) {
              //pour ne pas lancer à l'initialsation
              this.IPPService.reinitializeChildrenModal(field, data);
              if (field.subStep !== 0) {
                //on va chercher en data si le champs
                if (data[field.key_field] === 1) {
                  this.edit(
                    {},
                    true,
                    { keyModel: field.children[0] },
                    field.subStep,
                    data,
                    fieldsManager,
                    field.key_field,
                    field.labelKeyArr
                  );
                }
              }
            }
          },
        };
      }
      //TODO manque les validators avec regex
      if (field.validator != null) {
        fieldToReturn.validator = {
          checkDefaultValue: {
            expression: ($viewValue, $modelValue) => {
              let value = $modelValue || $viewValue;
              return value !== field.validator;
            },
          },
        };
      }

      if (field.link != null) {
        fieldToReturn.expressionProperties["templateOptions.link"] =  () => field.link;
        console.log(fieldToReturn);
      }
      
      return fieldToReturn;
    }

    /**
     * Ouvre un modal pour éditer le tableau de la vue famille
     * Le model en paramètre = un objet vide (dependent)
     */
    public edit(
      itemToEdit,
      isNew,
      variable,
      step,
      data,
      fieldsManager,
      trigger,
      labelKeyArr
    ) {
      this.IPPService.addNewElementDuringValidationMode =
        isNew && this.IPPService.validationMode ? true : false;
      let result = this.$uibModal.open({
        templateUrl: "tpl/IPPForm/formModal.html",
        controller: "IPPFormSubStepsController",
        controllerAs: "ctrl",
        //Permet de bloquer le click en dehors du modal
        backdrop: "static",
        //Permet de bloquer le escape
        keyboard: false,
        windowClass: "adjust-ipp-modal",
        //modal-fit est une class définit dans le sass
        // windowClass: "modal-fit",
        size: "md",
        resolve: {
          formData: () => {
            return {
              data: data,
              itemToEdit: itemToEdit,
              isNew: isNew,
              step: step,
              trigger: trigger,
              labelKeyArr: labelKeyArr,
              variable: variable,
            };
          },
          r_step: [
            "IPPFormService",
            "SessionService",
            "$stateParams",
            function (
              IPPFormSer: IPPFormService,
              sessionService: SessionService,
              $stateParams: ng.ui.IStateParamsService
            ) {
              let parameters = <any>$stateParams;
              let ippKey = parameters.ippKey;
              return IPPFormSer.getStep(step, ippKey)
                .then(function (d) {
                  return d.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          // TODO problème avec l'assignation dynamique
          r_fieldsManager: () => {
            return {
              fieldsManager: fieldsManager,
            };
          },
        },
      }).result;
      if (isNew) {
        result.then((newItem) => {
          if (data[variable.keyModel] == null) {
            data[variable.keyModel] = [newItem];
          } else {
            data[variable.keyModel].push(newItem);
          }
          this.updateData(data, null);
          this.IPPService.addNewElementDuringValidationMode = false;
        });
      }
      //edit
      if (!isNew) {
        result.then((itemEdited) => {
          let index = data[variable.keyModel].indexOf(itemToEdit);
          data[variable.keyModel][index] = itemEdited;
          this.updateData(data, null);
        });
      }
    }

    /** update the data
     *
     * @param data
     * @param nextView
     */
    public updateData(data, nextView) {
      //method to delete the rows of a horizontalInputGrid that are empty
      //for each key of children in the var
      this.childrenToClean.forEach(function (childKey) {
        //for each child of that key
        data[childKey].forEach(function (child) {
          let isEmpty = true;
          //for each element of this child
          Object.values(child).forEach(function (childElement) {
            //set the bool to false if the element must not be deleted
            if (childElement && childElement.trim() !== "") {
              isEmpty = false;
            }
          });
          if (isEmpty) {
            let index = data[childKey].indexOf(child);
            data[childKey].splice(index, 1);
          }
        });
      });

      return this.IPPService.updateData(data, data.fiscalYear, nextView);
    }

    public getTranslation(label): string {
      return label[this.m.language];
    }

    public delete(item, step, data, ancestor, modelKeyArray) {
      this.$uibModal
        .open({
          templateUrl: "tpl/IPPForm/confirmDeletionModal.html",
          controller: "ConfirmModalController",
          controllerAs: "confModalCtrl",
          //Permet de bloquer le click en dehors du modal
          backdrop: "static",
          //Permet de bloquer le escape
          keyboard: false,
          //modal-fit est une class définit dans le sass
          windowClass: "adjust-ipp-modal",
          size: "sm",
          resolve: {
            data: data,
          },
        })
        .result.then((data) => {
          //we delete the object of the data using his place in the index and setting his
          // ancestor aka the question to "no" with the value 2
          let index = data[modelKeyArray].indexOf(item);
          this.IPPService.removeMissingAttachement(index, ancestor);
          data[modelKeyArray].splice(index, 1);
          if (data[modelKeyArray].length === 0) {
            data[ancestor] = 2;
          }

          this.updateData(data, null);
        });
    }
  }
}

angular
  .module("app")
  .service("IPPFormFactory", app.functionality.IPPForm.IPPFormFactory);
