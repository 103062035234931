/// <reference path="../../../_app.ts"/>

module app.functionality.invoicing.services {
    
    import InvoicingClient = app.model.invoicing.InvoicingClient;

    export class ClientService {

        private rest: restangular.IService;

        static $inject = [
            "RestService",
            "RESTAPI",
            "$sanitize"
        ];

        constructor (
            restService: app.functionality.common.rest.RestService,
            private RESTAPI: app.config.constants.ConstantRestApi,
            private $sanitize: ng.sanitize.ISanitizeService
        ) {
            this.rest = restService.getRoot();
        }

        /**
         * Gets all invoicing clients of the current user
         * @param sessionID The current user
         * @param companyID The user's company
         * @returns An array of clients
         */
        getAllInvoicingClients = (sessionID: string, companyID: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoicing.client)
                .all(this.RESTAPI.services.invoicing.getAllInvoicingClients)
                .one(sessionID + "&" + companyID)
                .get();
        }

        /**
         * Gets a specific invoicing client
         * @param sessionID The current user
         * @param clientKey The wanted client's key
         * @returns An InvoicingClient as an Object
         */
        getInvoicingClient = (sessionID: string, clientKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoicing.client)
                .all(this.RESTAPI.services.invoicing.getInvoicingClient)
                .one(sessionID + "&" + clientKey)
                .get();
        }

        /**
         * Adds an invoicing client to the DB
         * @param sessionID The current user
         * @param companyID The user's current company
         * @param client The client to add
         */
        addInvoicingClient = (sessionID: string, companyID: string, client: InvoicingClient): ng.IPromise<any> => {
            let clientToSend = new InvoicingClient();
            angular.copy(client, clientToSend);

            let properties = ["fullName", "emailAddress", "address", "city", "country"];
            properties.forEach(property => {
                if (clientToSend[property]) {
                    clientToSend[property] = this.enhancedEncoding(clientToSend[property]);
                }
            });

            return this.rest.all(this.RESTAPI.services.invoicing.client)
                .all(this.RESTAPI.services.invoicing.addInvoicingClient)
                .one(sessionID + "&" + companyID)
                .customPOST(clientToSend);
        }

        /**
         * Updates an invoicing client already existing in the DB
         * @param sessionID The current user
         * @param client The client to update
         */
        updateInvoicingClient = (sessionID: string, client: InvoicingClient): ng.IPromise<any> => {
            let clientToSend = new InvoicingClient();
            angular.copy(client, clientToSend);

            let properties = ["fullName", "emailAddress", "address", "city", "country"];
            properties.forEach(property => {
                if (clientToSend[property]) {
                    clientToSend[property] = this.enhancedEncoding(clientToSend[property]);
                }
            });

            return this.rest.all(this.RESTAPI.services.invoicing.client)
                .all(this.RESTAPI.services.invoicing.updateInvoicingClient)
                .one(sessionID)
                .customPOST(clientToSend);
        }
        
        /**
         * Deletes an invoicing client from the DB
         * @param sessionID The current user
         * @param companyID The user's current company
         * @param clientKey The client to delete
         */
        deleteInvoicingClient = (sessionID: string, companyID: string, clientKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoicing.client)
                .all(this.RESTAPI.services.invoicing.deleteInvoicingClient)
                .one(sessionID + "&" + companyID + "&" + clientKey)
                .remove();
        }

        /**
         * Archives an invoicing client
         * @param sessionID The current user
         * @param clientKey The target client
         * @param doArchive True if the client should be archived, false if the client is already archived and should be restored
         */
        archiveInvoicingClient = (sessionID: string, clientKey: string, doArchive: boolean): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoicing.client)
                .all(this.RESTAPI.services.invoicing.archiveInvoicingClient)
                .one(sessionID + "&" + clientKey + "&" + doArchive)
                .patch();
        }

        /**
         * Makes a string safe to send to the back-end by encoding it
         * @param strToEncode The string which has to be encoded
         */
        enhancedEncoding(strToEncode: string): string {
            strToEncode = this.$sanitize(encodeURI(strToEncode));
            strToEncode = strToEncode.replace(/\&amp;/gi, "%26");
            strToEncode = strToEncode.replace(/\&/gi, "%26");
            strToEncode = strToEncode.replace(/\+/gi, "%2B");
            return strToEncode;
        }
    }
}
angular.module('app').service('ClientService', app.functionality.invoicing.services.ClientService);