/// <reference path="../../_app.ts" />

namespace app.functionality.dashboard {
  export class DashboardController {
    public profit: any;
    public liquidity: any;
    public treasury: any;
    public dataForLineChart: any;
    public dataForLineChart1: any;
    public profitData: any;
    public vatText: any;
    public dateTranslated: any;
    public dateFormatedDay: any;
    public dateFormatedYear: any;
    public country: string;

    public helperTest = "DASHBOARD.HELPER.FIRST.content";

    static $inject = [
      "r_turnover",
      "r_profit",
      "r_treasury",
      "r_liquidity",
      "LineChartConverterForDashboard",
      "SessionService",
      "$translatePartialLoader",
      "$translate",
      "$filter",
    ];

    constructor(
      r_turnover: any,
      r_profit: any,
      r_treasury: any,
      r_liquidity: any,
      lineChartConverterForDashboard: app.modules.lineargraphs.converter.LineChartConverterForDashboard,
      public sessionService: app.functionality.common.session.SessionService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $translate: ngt.ITranslateService,
      private $filter: ng.IFilterService
    ) {
      $translatePartialLoader.addPart("customer/profit");
      $translatePartialLoader.addPart("customer/dashboard");
      $translatePartialLoader.addPart("ipp");

      // BUG RESOLVE WHEN NO IMPORT ON TREASURY
      if (!angular.isDefined(r_treasury) || r_treasury === null) {
        r_treasury = {
          status: 0,
          data: {
            cashburn: {
              amount: 0,
            },
            solde: 0,
            wc: 0,
            wcn: 0,
          },
        };
      }

      if (sessionService.session.company.dashboardReport != null) {
        if (
          angular.isDefined(
            sessionService.session.company.dashboardReport.periodArr
          )
        ) {
          r_turnover.data = {
            periodArr: sessionService.session.company.dashboardReport.periodArr,
          };
        }

        if (
          sessionService.session.company.dashboardReport.start != null &&
          sessionService.session.company.dashboardReport.turnover != null &&
          sessionService.session.company.dashboardReport.cost != null
        ) {
          r_profit.data = {
            maj: sessionService.session.company.dashboardReport.start,
            turnover: -sessionService.session.company.dashboardReport.turnover,
            costs: -sessionService.session.company.dashboardReport.cost,
            pnl:
              -sessionService.session.company.dashboardReport.turnover +
              -sessionService.session.company.dashboardReport.cost,
          };
        }

        if (
          sessionService.session.company.dashboardReport.liquiditySuppliers !=
            null &&
          sessionService.session.company.dashboardReport.liquidityClients !=
            null &&
          sessionService.session.company.dashboardReport.vat != null
        ) {
          let soldeDelaySupplier =
            sessionService.session.company.dashboardReport
              .liquiditySuppliers[0];
          let soldeInOrderSupplier =
            sessionService.session.company.dashboardReport
              .liquiditySuppliers[1];
          let soldeDelayCustomer =
            sessionService.session.company.dashboardReport.liquidityClients[0];
          let soldeInOrderCustomer =
            sessionService.session.company.dashboardReport.liquidityClients[1];
          r_liquidity.data = {
            creance: {
              lateCreancePercent:
                (Math.abs(soldeDelaySupplier) /
                  (Math.abs(soldeInOrderSupplier) +
                    Math.abs(soldeDelaySupplier))) *
                100,
              amount: soldeDelaySupplier + soldeInOrderSupplier,
            },
            debt: {
              lateDebtPercent:
                (Math.abs(soldeDelayCustomer) /
                  (Math.abs(soldeInOrderCustomer) +
                    Math.abs(soldeDelayCustomer))) *
                100,
              amount: soldeDelayCustomer + soldeInOrderCustomer,
            },
            vat: sessionService.session.company.dashboardReport.vat,
          };
        }

        if (
          sessionService.session.company.dashboardReport.solde != null &&
          sessionService.session.company.dashboardReport.cashburn != null &&
          sessionService.session.company.dashboardReport.bfr != null &&
          sessionService.session.company.dashboardReport.fr != null
        ) {
          r_treasury.data = {
            solde: sessionService.session.company.dashboardReport.solde,
            cashburn: {
              amount: sessionService.session.company.dashboardReport.cashburn,
            },
            wcn: sessionService.session.company.dashboardReport.bfr,
            wc: sessionService.session.company.dashboardReport.fr,
          };
        }
      }

      this.profit = r_turnover.data;
      this.profitData = r_profit.data;
      this.liquidity = r_liquidity.data;
      this.treasury = r_treasury.data;

      if (
        this.sessionService.session.company.importInfo != null &&
        this.sessionService.session.company.importInfo.importDate != null
      ) {
        this.profitData.maj = this.sessionService.session.company.importInfo.importDate;
        if (angular.isDefined(this.profitData.maj) && this.profitData.maj) {
          this.dataForLineChart = lineChartConverterForDashboard.convert(
            this.profit.periodArr,
            new Date(this.profitData.maj)
          );
        } else {
          this.dataForLineChart = [{ date: moment().toDate(), amount: 0 }];
        }
      } else if (
        angular.isDefined(this.profit.periodArr) &&
        this.profit.periodArr.length !== 0
      ) {
        this.dataForLineChart = lineChartConverterForDashboard.convert(
          this.profit.periodArr
        );
        this.profitData.maj = undefined;
      } else {
        this.dataForLineChart = [{ date: moment().toDate(), amount: 0 }];
      }

      if (this.liquidity.vat >= 0) {
        this.vatText = "DASHBOARD.vatToGet";
      } else {
        this.liquidity.vat = Math.abs(this.liquidity.vat);
        this.vatText = "DASHBOARD.vatToPay";
      }

      if (sessionService.session.company.country != null) {
        this.country = sessionService.session.company.country;
      } else {
        console.error("no country found");
      }

      // Done that to translate month in dashboard, filter and translate and filter doesn't work due to the fact that we are in controller
      this.dateTranslated = this.$filter("dateLabel")(
        new Date(this.profitData.maj)
      );
      this.dateFormatedDay = new Date(this.profitData.maj).getDate();
      this.dateFormatedYear = new Date(this.profitData.maj).getFullYear();
    }

    public isUndefined(varToCheck) {
      return typeof varToCheck === "undefined";
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "DashboardController",
    app.functionality.dashboard.DashboardController
  );
