/// <reference path="../../_app.ts" />

module app.model.parameter {
    import PNLColumnConfig = app.model.parameter.PNLColumnConfig;
    import CategoryDefinition = app.model.parameter.CategoryDefinition;

    export class DashBoardParameter {

        classStructure : any = {
            categoryIncomeArr: {
                array: app.model.parameter.CategoryDefinition
            },
            categoryActiveBalanceSheetArr: {
                array: app.model.parameter.CategoryDefinition
            },
            categoryPassiveBalanceSheetArr: {
                array: app.model.parameter.CategoryDefinition
            },
            pnlColumnConfigArr: {
                array: app.model.parameter.PNLColumnConfig
            }
        };

        public key: string;
        public timeSimulation: number;

        public categoryIncomeArr: Array <CategoryDefinition>;
        public categoryActiveBalanceSheetArr: Array <CategoryDefinition>;
        public categoryPassiveBalanceSheetArr: Array <CategoryDefinition>;
        public pnlColumnConfigArr: Array <PNLColumnConfig>;

        constructor() {
        }

        public stringifyPnlArr(): string {
            let strArr = "[";
            this.pnlColumnConfigArr.forEach(function( pnlCol: PNLColumnConfig): void {
                strArr += pnlCol.key + ",";
            });

            strArr = _.trimEnd(strArr, ",");
            strArr += "]";

            return strArr;
        }

        public stringifyPnlArrOnParams(columns): string {
            let strArr = "[";
            columns.forEach(function( pnlCol: PNLColumnConfig): void {
                strArr += pnlCol.key + ",";
            });

            strArr = _.trimEnd(strArr, ",");
            strArr += "]";

            return strArr;
        }
    }
}