/// <reference path="../_app.ts" />

module app.model {

    export class AccountingSession extends Session {

        // TODO TO IMPORT
        // public company: AccountingFirm;
        public vatRulesKey: string;
        public thirdRulesKey: string;
        public outdatedArrKey: string;
        public thresholdMathcing: string;

        //TODO
        // public featureDir:FeatureDirectory;

        constructor() {
            super();
        }

    }

}
