/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.controllers {
  import ThirdWithOperations = app.model.invoiceReminder.ThirdWithOperations;
  import FilterService = app.functionality.invoiceReminder.services.FilterService;

  export class InvoicesAndPaymentsController implements angular.IController {
    /**
     * Contains only the operations that are due at the specified date
     */
    public operationsData: ThirdWithOperations;

    public isLoading: boolean;

    $onInit() {}

    static $inject = [
      "r_dataThirdWithOperations",
      "SessionService",
      "$translatePartialLoader",
      "ModalQuickstartService",
      "SendReminderService",
      "FilterService",
      "$scope",
    ];

    constructor(
      r_dataThirdWithOperations: ThirdWithOperations,
      private sessionService: app.functionality.common.session.SessionService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      modalQuickstartService: app.functionality.invoiceReminder.services.ModalQuickstartService,
      private sendReminderService: app.functionality.invoiceReminder.services.SendReminderService,
      private filterService: FilterService,
      $scope
    ) {
      $translatePartialLoader.addPart("customer/invoiceReminder");

      let self = this;
      $scope.$watch(
        () => this.filterService.thirdsWithOperations,
        (newValue: ThirdWithOperations[], oldValue: ThirdWithOperations[]) => {
          self.operationsData = newValue[0];
        }
      );

      // the updateFiltersValues parameter is false because we want to keep the filters of the previous page when loading this one
      this.filterService.initService(
        [r_dataThirdWithOperations],
        false,
        function () {
          if (self.operationsData) {
            self.operationsData.checkAllSales(true);
            self.operationsData.checkAllPayments(true);
          }
        }
      );

      this.isLoading = false;

      modalQuickstartService.showDialogIfQuickstart();
    }

    /**
     * Create a date object to be displayed in the view
     * @param {string} date The date to format
     * @returns {Date} An object that can be displayed in the view
     */
    public getFormatedDate(date: string): Date {
      return new Date(date);
    }

    /**
     * Create the reminder and send a request to the webservice to send the emails
     */
    private sendEmail() {
      let self = this;
      self.sendReminderService.sendEmail([self.operationsData]);
      this.operationsData.sales.forEach((sale) => {
        if (sale.isSelected) sale.reminderNumber++;
      });
      this.sessionService.setInvoiceReminders([]);
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "InvoicesAndPaymentsController",
    app.functionality.invoiceReminder.controllers.InvoicesAndPaymentsController
  );
