/// <reference path="../_app.ts" />

module app.model {

    import Member = app.model.Member;

    export class Session {

        classStructure : any = {
            member: app.model.Member
        };

        public sessionID: string;
        public accesLevel: number;
        public member: Member;
        public connectionTime: number;
        public lastActionTime: number;
        public lastConnectionTime: number; //last connection time before this time

        public timeZoneName: string; //Europe/Brussels

        constructor() {
        }

    }
}