/// <reference path="../../_app.ts" />`

module app.functionality.upload {
  import UploadFolder = app.model.UploadFolder;
  import UploadService = app.functionality.upload.UploadService;
  import SessionService = app.functionality.common.session.SessionService;

  export class ConfigurationController {
    private mailsWhitelist = [];
    private newMail = "";
    private uploadFolderList: Array<UploadFolder> = [];
    private isEmailValid = true;
    private mailsAWS: Array<any> = [];
    private directoryMailAWS: string;
    private folderMap = [];
    private accountingFirm: string;
    private customerMail: string = "";

    static $inject = [
      "r_uploadFolders",
      "r_mailAWS",
      "$scope",
      "UploadService",
      "SessionService",
      //   "$uibModal",
      "$translatePartialLoader",
    ];

    constructor(
      r_uploadFolders: any,
      r_mailAWS: any,
      public $scope: ng.IScope,
      // private $document: ng.IDocumentService,
      private uploadService: UploadService,
      private sessionService: SessionService,
      public $translatePartialLoader: ngt.ITranslatePartialLoaderService
    ) {
      this.uploadFolderList =
        r_uploadFolders.data != null ? r_uploadFolders.data : [];
      this.mailsAWS = r_mailAWS.data;
      $translatePartialLoader.addPart("customer/upload");
      // there is always at least one mailAWS and each one contains the whitelist so we take the first
      this.mailsWhitelist = r_mailAWS.data[0].whiteList.emailWhiteList;
      // create a map associating the names of the uploadFolders to their mail
      r_mailAWS.data.forEach((mail) => {
        let folder = r_uploadFolders.data.find(
          (folder) => folder.key === mail.uploadFolderKey
        );
        if (folder != null) {
          this.folderMap[folder.name] = mail.mail;
        }
      });

      this.accountingFirm =
        this.sessionService.session.company.accoutingFirmDesc.relatedName;

      this.customerMail = this.sessionService.session.member.mail;

      //Bad practice but Arnaud wanted it
      this.directoryMailAWS = this.mailsAWS.find((mail) => {
        return mail.uploadFolderKey == null;
      });
    }

    /**
     * get the mail associated to an uploadFolder
     * Not used anymore
     * @param uploadFolderKey the key of the uploadFolder
     * @returns the mail
     */
    public getMailAWS(uploadFolderKey: string) {
      const mail = this.mailsAWS.filter(
        (mail) => mail.uploadFolderKey === uploadFolderKey
      );
      return mail[0].mail;
    }

    /**
     * add an element to the whitelist if he checks
     */
    public addToWhiteList() {
      //if the element is correct
      if (this.checkMail())
        //call the back-end and when call resolves we check the answers
        this.uploadService.addToWhiteList(this.newMail).then((result) => {
          // if call is successfull the front is updated and newmail is reset
          if (result.status === 200) {
            this.mailsWhitelist.push(this.newMail);
            this.newMail = "";
          } else if (result.status >= 400) {
          }
        });
    }
    /**
     * delete an element from the whitelist
     * @param mail the mail to delete
     */
    public deleteFromWhitelist(mail: string) {
      this.uploadService.deleteFromWhiteList(mail).then((result) => {
        if (result.status === 200) {
          this.mailsWhitelist.splice(this.mailsWhitelist.indexOf(mail), 1);
        } else if (result.status >= 400) {
          console.error("Async: Could not delete mail");
        }
      });
    }
    /**
     * copy the link into the clipboard to make the paste easier
     * @param link the link to copy
     */
    public copyLink(link: string) {
      navigator.clipboard.writeText(link).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    }

    /**
     * check if the newMail in input is correct
     * @returns true if newMail is correct
     */
    private checkMail(): boolean {
      if (this.newMail)
        this.isEmailValid =
          /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
            this.newMail.trim()
          );
      else this.isEmailValid = false;
      return this.isEmailValid;
    }
  }
}

angular
  .module("app")
  .controller(
    "ConfigurationController",
    app.functionality.upload.ConfigurationController
  );
