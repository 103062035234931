/// <reference path="../../_app.ts" />

module app.model.interface {

    export interface IThird {

        key: string;
        companyKey: string;

        type: number;
        from: number;

        reference: string;
        fullName: string;

        adresse: string;
        zip: string;
        city: string;
        isoCountry: string;

        vatCategory: string;
        vat: string;

        vatControl: number;

    }

}