/// <reference path="../../_app.ts" />

module app.model.interface {

    export interface ITemplateReminder {

        key: string;
        companyKey: string;
        templateNumber: number;
        name: string;
        subject: string;
        message: string;
    }

}