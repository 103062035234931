/// <reference path="../_app.ts" />

module app.model {

    export class Quarterly {

        classStructure : any = {
            periodArr: {
                array: app.model.Period
            }
        };
        
        public key: string;

        public periodArr: Array<Period>;
        public nbrPeriod: number;
        public bookyear: number;

        constructor() {
        }

    //     public function getYear(): number {
    //      var firstPeriod:Period = periodArr.getItemAt(0) as Period;
    //
    //     return firstPeriod.year;
    // }
    }
}