/// <reference path="../../_app.ts" />

module app.model.constants {

    export class BookHistoricConst {
        public SALES = 1;
        public PURCHASES = 2;
        public BANK = 3;
        public OTHER = 4;
        public OPD = 5;
        public NC_SALES = 6;
        public NC_PURCHASES = 7;

    }
}

angular.module("app").
constant("BookHistoricConst", new app.model.constants.BookHistoricConst());