/// <reference path="../../../_app.ts"/>

module app.functionality.invoicing.services {
    
    import Invoice = app.model.invoicing.Invoice;
    import InvoicingInformation = app.model.invoicing.InvoicingInformation;
    import InvoicingClient = app.model.invoicing.InvoicingClient;

    export class InvoicingService {

        private rest: restangular.IService;

        static $inject = [
            "RestService", 
            "RESTAPI",
            "$sanitize"
        ];

        constructor(
            restService: app.functionality.common.rest.RestService, 
            private RESTAPI: app.config.constants.ConstantRestApi,
            private $sanitize: ng.sanitize.ISanitizeService
        ) {
            this.rest = restService.getRoot();
        }

        /**
         * Gets all invoices of the current user
         * @param sessionID The current user
         * @param companyID The user's company
         * @param type Optional, 0 retrieves all, 1 retrieves only invoices and 2 only credit notes
         * @returns An array of invoices
         */
        getAllInvoices = (sessionID: string, companyID: string, type: number): ng.IPromise<any> => {
            if (type < 0 || type > 2) {
                type = 0;
            }

            return this.rest.all(this.RESTAPI.services.invoicing.invoicing)
                .all(this.RESTAPI.services.invoicing.getAllInvoices)
                .one(sessionID + "&" + companyID + "&" + type)
                .get();
        }

        /**
         * Gets a specific invoice
         * @param sessionID The current user
         * @param invoiceKey The wanted invoice's key
         * @returns An invoice as an Object
         */
        getInvoice = (sessionID: string, invoiceKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoicing.invoicing)
                .all(this.RESTAPI.services.invoicing.getInvoice)
                .one(sessionID + "&" + invoiceKey)
                .get();
        }

        /**
         * Adds an invoice to the DB
         * @param sessionID The current user
         * @param invoice The invoice to add
         * @returns SUCCESS is the invoice is added to the DB
         */
        addInvoice = (sessionID: string, companyID: string, counterUsed: number, invoice: Invoice): ng.IPromise<any> => {
            let invoiceToSend = new Invoice();
            angular.copy(invoice, invoiceToSend);

            invoiceToSend.structuredCommunication = this.$sanitize(encodeURI(invoiceToSend.structuredCommunication));
            invoiceToSend.line.forEach((aLine) => {
                aLine.description = this.enhancedEncoding(aLine.description);
            });
            ["description", "footerText", "filename"].forEach(property => {
                if (invoiceToSend[property]) {
                    invoiceToSend[property] = this.enhancedEncoding(invoiceToSend[property]);
                }
            });

            return this.rest.all(this.RESTAPI.services.invoicing.invoicing)
                .all(this.RESTAPI.services.invoicing.addInvoice)
                .one(sessionID + "&" + companyID + "&" + counterUsed)
                .customPOST(invoiceToSend);
        }

        /**
         * Updates an invoice already existing in the DB
         * @param sessionID The current user
         * @param invoice The invoice to update
         * @returns SUCCESS if the invoice is updated
         */
        updateInvoice = (sessionID: string, invoice: Invoice): ng.IPromise<any> => {
            let invoiceToSend = new Invoice();
            angular.copy(invoice, invoiceToSend);
            
            invoiceToSend.structuredCommunication = this.$sanitize(encodeURI(invoiceToSend.structuredCommunication));
            invoiceToSend.line.forEach((aLine) => {
                aLine.description = this.enhancedEncoding(aLine.description);
            });
            ["description", "footerText", "filename"].forEach(property => {
                if (invoiceToSend[property]) {
                    invoiceToSend[property] = this.enhancedEncoding(invoiceToSend[property]);
                }
            });

            return this.rest.all(this.RESTAPI.services.invoicing.invoicing)
                .all(this.RESTAPI.services.invoicing.updateInvoice)
                .one(sessionID)
                .customPOST(invoiceToSend);
        }
        
        /**
         * Deletes an invoice from the DB
         * @param sessionID The current user
         * @param invoiceKey The invoice to delete
         * @returns SUCCESS if the invoice is deleted from the DB
         */
        deleteInvoice = (sessionID: string, invoiceKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoicing.invoicing)
                .all(this.RESTAPI.services.invoicing.deleteInvoice)
                .one(sessionID + "&" + invoiceKey)
                .remove();
        }

        /**
         * Changes the status of an invoice : open or paid
         * @param sessionID The current user
         * @param invoiceKey The target invoice
         * @param status The target status : 1 = open, 2 = paid
         */
        updateStatus = (sessionID: string, invoiceKey: string, status: number): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoicing.invoicing)
                .all(this.RESTAPI.services.invoicing.updateStatus)
                .one(sessionID + "&" + invoiceKey + "&" + status)
                .patch();
        }

        /**
         * Send the Userinvoice to the accounter
         * @param sessionID The current user
         * @param invoiceKey The target invoice
         * @param status The target status : 1 = open, 2 = paid
         */
        sendToAcc = (sessionID: string, userInvoiceKeys: Array<String>): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoicing.invoicing)
                .all(this.RESTAPI.services.invoicing.sendInvoiceToAccounter)
                .customPOST({
                    sessionID: sessionID,
                    userInvoiceKeys: userInvoiceKeys
                });
        }

        /**
         * Gets the invoice as a PDF
         */
        getInvoicePDF = (sessionID: string, invoiceKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoicing.invoicingPDF)
                .all(this.RESTAPI.services.invoicing.getInvoicePDF)
                .one(sessionID + "&" + invoiceKey)
                .get();
        }

        /**
         * Gets the user's company invoicing information
         */
        getInformation = (sessionID: string, companyKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoicing.invoicing)
                .all(this.RESTAPI.services.invoicing.getInformation)
                .one(sessionID + "&" + companyKey)
                .get();
        }

        /**
         * If the user's company doesn't have an invoicing information document in the DB, asks the back-end to create one
         */
        createInformation = (sessionID: string, companyKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.invoicing.invoicing)
                .all(this.RESTAPI.services.invoicing.createInformation)
                .one(sessionID + "&" + companyKey)
                .get();
        }

        /**
         * Updates the user's company invoicing information 
         */
        updateInformation = (sessionID: string, information: InvoicingInformation): ng.IPromise<any> => {
            let toSend = new InvoicingInformation();
            angular.copy(information, toSend);

            toSend.increments.forEach(increment => {
                increment.name = this.enhancedEncoding(increment.name);
            });

            toSend.vatRates.forEach(rate => {
                rate.name = this.enhancedEncoding(rate.name);
                rate.description = this.enhancedEncoding(rate.description);
            });

            let properties = ["companyName", "address", "city", "country", "iban", "bic", "vatNumber", "sendingEmail", "sendingName"];
            properties.forEach(property => {
                if (toSend[property]) {
                    toSend[property] = this.enhancedEncoding(toSend[property]);
                }
            });

            ["fr", "nl", "en"].forEach(lang => {
                if (toSend.localizedValues[lang]) {
                    ["headingText", "footerText", "filename", "mailSubject", "mailBody"].forEach(property => {
                        if (toSend.localizedValues[lang][property]) {
                            toSend.localizedValues[lang][property] = this.enhancedEncoding(toSend.localizedValues[lang][property]);
                        }
                    });
                }
            });

            return this.rest.all(this.RESTAPI.services.invoicing.invoicing)
                .all(this.RESTAPI.services.invoicing.updateInformation)
                .one(sessionID)
                .customPOST(toSend);
        }

        /**
         * Makes a string safe to send to the back-end by encoding it
         * @param strToEncode The string which has to be encoded
         */
        enhancedEncoding(strToEncode: string): string {
            strToEncode = this.$sanitize(encodeURI(strToEncode));
            strToEncode = strToEncode.replace(/\&amp;/gi, "%26");
            strToEncode = strToEncode.replace(/\&/gi, "%26");
            strToEncode = strToEncode.replace(/\+/gi, "%2B");
            return strToEncode;
        }
    }
}

angular.module('app').service('InvoicingService', app.functionality.invoicing.services.InvoicingService);