/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {
  // import CryptoJS = require("crypto-js");

  import UserService = app.functionality.user.UserService;
  export class PasswordModalController {
    public form_data: any;
    public wrongPwFlag: boolean;
    public userService: any;

    static $inject = [
      "$uibModalInstance",
      "passed_user_data",
      "UserService",
      "SessionService",
    ];

    constructor(
      public $uibModalInstance: any,
      public passed_user_data: any,
      userService: UserService,
      public sessionService: app.functionality.common.session.SessionService
    ) {
      this.form_data = passed_user_data;
      this.userService = userService;
      this.form_data.oldPassword = "";
      this.form_data.pass1 = "";
      this.form_data.pass2 = "";
      this.wrongPwFlag = false;
    }

    public updatePassword() {
      let self = this;
      this.form_data.password = CryptoJS.SHA256(
        this.form_data.pass1
      ).toString();

      this.form_data.oldPassword = CryptoJS.SHA256(
        this.form_data.oldPassword
      ).toString();

      this.form_data.pass1 = "";
      this.form_data.pass2 = "";
      if (this.form_data.accessLevel == 0) {
        self.userService
          .updateUserPassword(
            self.sessionService.session.sessionID,
            self.form_data
          )
          .then(function (data) {
            self.sessionService.session.member.password =
              self.form_data.password;
          })
          .catch(function (error) {
            console.error("erreur lors de l'update du membre ");
          });
      } else {
        // do nothing, it means it's an accounter and we have no jwt to send
      }

      this.$uibModalInstance.close();
    }

    cancel() {
      // to cancel eventuel modification made by the user but not confirm
      this.$uibModalInstance.dismiss();
    }
  }
}

angular
  .module("app")
  .controller(
    "PasswordModalController",
    app.functionality.common.modals.PasswordModalController
  );
