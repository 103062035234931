/// <reference path="../../_app.ts" />

namespace app.functionality.IPPForm {
  export class ConfirmModalController implements angular.IController {
    static $inject = ["$uibModalInstance", "data", "SessionService"];

    constructor(
      public $uibModalInstance: any,
      private data: any,
      private sessionService: app.functionality.common.session.SessionService
    ) {
      this.data = data;
    }

    $onInit() {}

    public cancel() {
      this.$uibModalInstance.dismiss("cancel");
    }

    public confirm() {
      this.$uibModalInstance.close(this.data);
    }
  }
}

angular
  .module("app")
  .controller(
    "ConfirmModalController",
    app.functionality.IPPForm.ConfirmModalController
  );
