/// <reference path="../_app.ts" />

module app.model {

    export class Member {

        NEW_IMPORT() : number { return 0; }

        ADMIN() : number { return 1000; }
        ACCOUNTER() : number { return 200; }
        ACCOUNTER_ADMIN() : number { return 299; }
        ACCOUNTER_AS_USER() : number { return 250; }
        ACCOUNTER_AS_IPP() : number { return 275; }
        GLOBAL_USER_API() : number { return 400; }
        CUSTOMER() : number { return 0; }

        //FREQUENCY OF THE ACTIVITY MAIL
        NEVER_MAIL() : number { return -1; }
        DAILY_MAIL() : number { return 0; }
        WEEKLY_MAIL() : number { return 1; }
        MONTHLY_MAIL() : number { return 2; }

        // ALLOWED LEVEL
        WAITING_STATUS() : number { return 0; }
        CORRECT_STATUS() : number { return 100; }
        DEACTIVATE_STATUS() : number { return 999; }

        public key: string;

        public username: string;
        public password: string;
        public isAllowed: number;
        public accesLevel: number; //role
        public permission: Array<any>; //premission given
        public language: string;
        public firstname: string;
        public lastname: string;
        public mobile: string;
        public fixTel: string;
        public role: string;
        public mail: string;
        public accountingFirmKey: string;
        public creationDate: Date;
        public lastTicketViewDate: Date;
        public lastConnectionDate: Date;
        public betaTester: boolean;
        public accountantBetaTester: boolean;
        public tax_form: boolean;
        public discreetMenu: boolean;
        public regulation: boolean;
        public accept_regulation: Date;

        /* MAIL PREFERENCE */
        public mailPreferenceClose;
        public mailPreferenceNewAnswer;
        public mailPreferenceNewMessage;
        public  mailPreferenceUpdate;
        public  mailPreferenceActivity: number = this.WEEKLY_MAIL();

        public mailAccounterPreferenceActivity: number = this.DAILY_MAIL();
        public mailAccounterAllActivity = true; // true : all company, false : all company where i'm responsable

        public isLogged: boolean;

        public relatedCompaniesArr: Array<any>;

        public featuresByComp: any;
        public defaultHomePage: any;
        public authorizedDirectory: Array<any>;

        // REMINDER PREFERENCE
        public numberOfDataToDisplay: number;

        constructor() {
        }

    }

}
