/// <reference path="../../../_app.ts" />

module app.functionality.invoicing.controllers {
  import Third = app.model.Third;
  import InvoicingClient = app.model.invoicing.InvoicingClient;

  export class ClientEditionController implements angular.IController {
    // Localized country names for the Country typeahead, they MUST have the strictly same order
    private countries: Object = {
      iso: [
        "AF",
        "ZA",
        "AX",
        "AL",
        "DZ",
        "DE",
        "AD",
        "AO",
        "AI",
        "AQ",
        "AG",
        "SA",
        "AR",
        "AM",
        "AW",
        "AU",
        "AT",
        "AZ",
        "BS",
        "BH",
        "BD",
        "BB",
        "BY",
        "BE",
        "BZ",
        "BJ",
        "BM",
        "BT",
        "BO",
        "BQ",
        "BA",
        "BW",
        "BV",
        "BR",
        "BN",
        "BG",
        "BF",
        "BI",
        "KY",
        "KH",
        "CM",
        "CA",
        "CV",
        "CF",
        "CL",
        "CN",
        "CX",
        "CY",
        "CC",
        "CO",
        "KM",
        "CG",
        "CD",
        "CK",
        "KR",
        "KP",
        "CR",
        "CI",
        "HR",
        "CU",
        "CW",
        "DK",
        "DJ",
        "DO",
        "DM",
        "EG",
        "SV",
        "AE",
        "EC",
        "ER",
        "ES",
        "EE",
        "US",
        "ET",
        "FK",
        "FO",
        "FJ",
        "FI",
        "FR",
        "GA",
        "GM",
        "GE",
        "GS",
        "GH",
        "GI",
        "GR",
        "GD",
        "GL",
        "GP",
        "GU",
        "GT",
        "GG",
        "GN",
        "GW",
        "GQ",
        "GY",
        "GF",
        "HT",
        "HM",
        "HN",
        "HK",
        "HU",
        "IM",
        "UM",
        "VG",
        "VI",
        "IN",
        "ID",
        "IR",
        "IQ",
        "IE",
        "IS",
        "IL",
        "IT",
        "JM",
        "JP",
        "JE",
        "JO",
        "KZ",
        "KE",
        "KG",
        "KI",
        "KW",
        "LA",
        "LS",
        "LV",
        "LB",
        "LR",
        "LY",
        "LI",
        "LT",
        "LU",
        "MO",
        "MK",
        "MG",
        "MY",
        "MW",
        "MV",
        "ML",
        "MT",
        "MP",
        "MA",
        "MH",
        "MQ",
        "MU",
        "MR",
        "YT",
        "MX",
        "FM",
        "MD",
        "MC",
        "MN",
        "ME",
        "MS",
        "MZ",
        "MM",
        "NA",
        "NR",
        "NP",
        "NI",
        "NE",
        "NG",
        "NU",
        "NF",
        "NO",
        "NC",
        "NZ",
        "IO",
        "OM",
        "UG",
        "UZ",
        "PK",
        "PW",
        "PS",
        "PA",
        "PG",
        "PY",
        "NL",
        "PE",
        "PH",
        "PN",
        "PL",
        "PF",
        "PR",
        "PT",
        "QA",
        "RE",
        "RO",
        "GB",
        "RU",
        "RW",
        "EH",
        "BL",
        "KN",
        "SM",
        "MF",
        "SX",
        "PM",
        "VC",
        "SH",
        "LC",
        "SB",
        "WS",
        "AS",
        "ST",
        "SN",
        "RS",
        "SC",
        "SL",
        "SG",
        "SK",
        "SI",
        "SO",
        "SD",
        "SS",
        "LK",
        "SE",
        "CH",
        "SR",
        "SJ",
        "SZ",
        "SY",
        "TJ",
        "TW",
        "TZ",
        "TD",
        "CZ",
        "TF",
        "TH",
        "TL",
        "TG",
        "TK",
        "TO",
        "TT",
        "TN",
        "TM",
        "TC",
        "TR",
        "TV",
        "UA",
        "UY",
        "VU",
        "VE",
        "VN",
        "WF",
        "YE",
        "ZM",
        "ZW",
      ],
      fr: [
        "Afghanistan",
        "Afrique du Sud",
        "Åland",
        "Albanie",
        "Algérie",
        "Allemagne",
        "Andorre",
        "Angola",
        "Anguilla",
        "Antarctique",
        "Antigua-et-Barbuda",
        "Arabie saoudite",
        "Argentine",
        "Arménie",
        "Aruba",
        "Australie",
        "Autriche",
        "Azerbaïdjan",
        "Bahamas",
        "Bahreïn",
        "Bangladesh",
        "Barbade",
        "Biélorussie",
        "Belgique",
        "Belize",
        "Bénin",
        "Bermudes",
        "Bhoutan",
        "Bolivie",
        "Bonaire, Saint-Eustache et Saba",
        "Bosnie-Herzégovine",
        "Botswana",
        "Île Bouvet",
        "Brésil",
        "Brunei",
        "Bulgarie",
        "Burkina Faso",
        "Burundi",
        "Îles Caïmans",
        "Cambodge",
        "Cameroun",
        "Canada",
        "Cap-Vert",
        "République centrafricaine",
        "Chili",
        "Chine",
        "Île Christmas",
        "Chypre",
        "Îles Cocos",
        "Colombie",
        "Comores",
        "République du Congo",
        "République démocratique du Congo",
        "Îles Cook",
        "Corée du Sud",
        "Corée du Nord",
        "Costa Rica",
        "Côte d’Ivoire",
        "Croatie",
        "Cuba",
        "Curaçao",
        "Danemark",
        "Djibouti",
        "République dominicaine",
        "Dominique",
        "Égypte",
        "Salvador",
        "Émirats arabes unis",
        "Équateur",
        "Érythrée",
        "Espagne",
        "Estonie",
        "États-Unis",
        "Éthiopie",
        "Îles Malouines",
        "Îles Féroé",
        "Fidji",
        "Finlande",
        "France",
        "Gabon",
        "Gambie",
        "Géorgie",
        "Géorgie du Sud-et-les Îles Sandwich du Sud",
        "Ghana",
        "Gibraltar",
        "Grèce",
        "Grenade",
        "Groenland",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernesey",
        "Guinée",
        "Guinée-Bissau",
        "Guinée équatoriale",
        "Guyana",
        "Guyane",
        "Haïti",
        "Îles Heard-et-MacDonald",
        "Honduras",
        "Hong Kong",
        "Hongrie",
        "Île de Man",
        "Îles mineures éloignées des États-Unis",
        "Îles Vierges britanniques",
        "Îles Vierges des États-Unis",
        "Inde",
        "Indonésie",
        "Iran",
        "Irak",
        "Irlande",
        "Islande",
        "Israël",
        "Italie",
        "Jamaïque",
        "Japon",
        "Jersey",
        "Jordanie",
        "Kazakhstan",
        "Kenya",
        "Kirghizistan",
        "Kiribati",
        "Koweït",
        "Laos",
        "Lesotho",
        "Lettonie",
        "Liban",
        "Liberia",
        "Libye",
        "Liechtenstein",
        "Lituanie",
        "Luxembourg",
        "Macao",
        "Macédoine",
        "Madagascar",
        "Malaisie",
        "Malawi",
        "Maldives",
        "Mali",
        "Malte",
        "Îles Mariannes du Nord",
        "Maroc",
        "Marshall",
        "Martinique",
        "Maurice",
        "Mauritanie",
        "Mayotte",
        "Mexique",
        "Micronésie",
        "Moldavie",
        "Monaco",
        "Mongolie",
        "Monténégro",
        "Montserrat",
        "Mozambique",
        "Birmanie",
        "Namibie",
        "Nauru",
        "Népal",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Île Norfolk",
        "Norvège",
        "Nouvelle-Calédonie",
        "Nouvelle-Zélande",
        "Territoire britannique de l’océan Indien",
        "Oman",
        "Ouganda",
        "Ouzbékistan",
        "Pakistan",
        "Palaos",
        "Autorité palestinienne",
        "Panama",
        "Papouasie-Nouvelle-Guinée",
        "Paraguay",
        "Pays-Bas",
        "Pérou",
        "Philippines",
        "Îles Pitcairn",
        "Pologne",
        "Polynésie française",
        "Porto Rico",
        "Portugal",
        "Qatar",
        "Réunion",
        "Roumanie",
        "Royaume-Uni",
        "Russie",
        "Rwanda",
        "Sahara occidental",
        "Saint-Barthélemy",
        "Saint-Christophe-et-Niévès",
        "Saint-Marin",
        "Saint-Martin (Antilles françaises)",
        "Saint-Martin",
        "Saint-Pierre-et-Miquelon",
        "Saint-Vincent-et-les-Grenadines",
        "Sainte-Hélène, Ascension et Tristan da Cunha",
        "Sainte-Lucie",
        "Salomon",
        "Samoa",
        "Samoa américaines",
        "Sao Tomé-et-Principe",
        "Sénégal",
        "Serbie",
        "Seychelles",
        "Sierra Leone",
        "Singapour",
        "Slovaquie",
        "Slovénie",
        "Somalie",
        "Soudan",
        "Soudan du Sud",
        "Sri Lanka",
        "Suède",
        "Suisse",
        "Suriname",
        "Svalbard et île Jan Mayen",
        "Swaziland",
        "Syrie",
        "Tadjikistan",
        "Taïwan / République de Chine",
        "Tanzanie",
        "Tchad",
        "République tchèque",
        "Terres australes et antarctiques françaises",
        "Thaïlande",
        "Timor oriental",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinité-et-Tobago",
        "Tunisie",
        "Turkménistan",
        "Îles Turques-et-Caïques",
        "Turquie",
        "Tuvalu",
        "Ukraine",
        "Uruguay",
        "Vanuatu",
        "Venezuela",
        "Viêt Nam",
        "Wallis-et-Futuna",
        "Yémen",
        "Zambie",
        "Zimbabwe",
      ],
      en: [
        "Afghanistan",
        "South Africa",
        "Åland",
        "Albania",
        "Algeria",
        "Germany",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Saudi Arabia",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cayman Islands",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Central African Republic",
        "Chile",
        "China",
        "Christmas Island",
        "Cyprus",
        "Cocos Islands",
        "Colombia",
        "Comoros",
        "Congo Republic",
        "Democratic Republic of Congo",
        "Cook Islands",
        "South Korea",
        "North Korea",
        "Costa Rica",
        "Ivory Coast",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Denmark",
        "Djibouti",
        "Dominican Republic",
        "Dominica",
        "Egypt",
        "El Salvador",
        "United Arab Emirates",
        "Ecuador",
        "Eritrea",
        "Spain",
        "Estonia",
        "United States",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "The Gambia",
        "Georgia",
        "South Georgia and the South Sandwich Islands",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Granada",
        "Greenland",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Equatorial Guinea",
        "Guyana",
        "French Guiana",
        "Haiti",
        "Heard-and-MacDonald Islands",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Isle of Man",
        "United States Minor Outlying Islands",
        "British Virgin Islands",
        "United States Virgin Islands",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Iceland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kyrgyzstan",
        "Kiribati",
        "Kuwait",
        "Laos",
        "Lesotho",
        "Latvia",
        "Lebanon",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Macedonia",
        "Madagascar",
        "Malaysia",
        "Malawi",
        "Maldives",
        "Mali",
        "Malta",
        "Northern Mariana Islands",
        "Morocco",
        "Marshall",
        "Martinique",
        "Mauritius",
        "Mauritania",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Mozambique",
        "Burma",
        "Namibia",
        "Nauru",
        "Nepal",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Norway",
        "New Caledonia",
        "New Zealand",
        "British Indian Ocean Territory",
        "Oman",
        "Uganda",
        "Uzbekistan",
        "Pakistan",
        "Palau",
        "Palestinian Authority",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "The Netherlands",
        "Peru",
        "Philippines",
        "Pitcairn Islands",
        "Poland",
        "French Polynesia",
        "Puerto Rico",
        "Portugal",
        "Qatar",
        "Meeting",
        "Romania",
        "United Kingdom",
        "Russia",
        "Rwanda",
        "Western Sahara",
        "Saint-Barthélemy",
        "Saint Kitts and Nevis",
        "San Marino",
        "Sint Maarten (French West Indies)",
        "Sint Maarten",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Lucia",
        "Salomon",
        "Samoa",
        "American Samoa",
        "Sao Tome and Principe",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Somalia",
        "Sudan",
        "South Sudan",
        "Sri Lanka",
        "Sweden",
        "Switzerland",
        "Suriname",
        "Svalbard and Jan Mayen Island",
        "Swaziland",
        "Syria",
        "Tajikistan",
        "Taiwan / Republic of China",
        "Tanzania",
        "Chad",
        "Czech Republic",
        "French Southern and Antarctic Lands",
        "Thailand",
        "East Timor",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Turkey",
        "Tuvalu",
        "Ukraine",
        "Uruguay",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Wallis and Futuna",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],
      nl: [
        "Afghanistan",
        "Zuid-Afrika",
        "Åland",
        "Albanië",
        "Algerije",
        "Duitsland",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua en Barbuda",
        "Saoedi-Arabië",
        "Argentinië",
        "Armenië",
        "Aruba",
        "Australië",
        "Oostenrijk",
        "Azerbeidzjan",
        "Bahama's",
        "Bahrein",
        "Bangladesh",
        "Barbados",
        "Wit-Rusland",
        "België",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire, Sint Eustatius en Saba",
        "Bosnië-Herzegovina",
        "Botswana",
        "Bouvet Eiland",
        "Brazilië",
        "Brunei",
        "Bulgarije",
        "Burkina Faso",
        "Burundi",
        "Kaaimaneilanden",
        "Cambodja",
        "Kameroen",
        "Canada",
        "Kaapverdië",
        "Centraal-Afrikaanse Republiek",
        "Chili",
        "China",
        "Kerstmis Eiland",
        "Cyprus",
        "Cocos Eilanden",
        "Colombia",
        "Comoren",
        "Congolese Republiek",
        "Democratische Republiek Congo",
        "Cookeilanden",
        "Zuid-Korea",
        "Noord-Korea",
        "Costa Rica",
        "Ivoorkust",
        "Kroatië",
        "Cuba",
        "Curaçao",
        "Denemarken",
        "Djibouti",
        "Dominicaanse Republiek",
        "Dominica",
        "Egypte",
        "El Salvador",
        "Verenigde Arabische Emiraten",
        "Ecuador",
        "Eritrea",
        "Spanje",
        "Estland",
        "Verenigde Staten",
        "Ethiopië",
        "Falklandeilanden",
        "Faeröer",
        "Fiji",
        "Finland",
        "Frankrijk",
        "Gabon",
        "Gambia",
        "Georgië",
        "South Georgia en de South Sandwich Eilanden",
        "Ghana",
        "Gibraltar",
        "Griekenland",
        "Granada",
        "Groenland",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinee",
        "Guinee-Bissau",
        "Equatoriaal-Guinea",
        "Guyana",
        "Frans-Guyana",
        "Haïti",
        "Heard-and-MacDonald Eilanden",
        "Honduras",
        "Hongkong",
        "Hongarije",
        "Eiland Man",
        "Kleine afgelegen eilanden van de Verenigde Staten",
        "Britse Maagdeneilanden",
        "Verenigde Staten Maagdeneilanden",
        "India",
        "Indonesië",
        "Iran",
        "Irak",
        "Ierland",
        "IJsland",
        "Israël",
        "Italië",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordanië",
        "Kazachstan",
        "Kenia",
        "Kirgizië",
        "Kiribati",
        "Koeweit",
        "Laos",
        "Lesotho",
        "Letland",
        "Libanon",
        "Liberia",
        "Libië",
        "Liechtenstein",
        "Litouwen",
        "Luxemburg",
        "Macau",
        "Macedonië",
        "Madagaskar",
        "Maleisië",
        "Malawi",
        "Malediven",
        "Mali",
        "Malta",
        "Noordelijke Marianen",
        "Marokko",
        "Marshall",
        "Martinique",
        "Mauritius",
        "Mauritanië",
        "Mayotte",
        "Mexico",
        "Micronesië",
        "Moldavië",
        "Monaco",
        "Mongolië",
        "Montenegro",
        "Montserrat",
        "Mozambique",
        "Birma",
        "Namibië",
        "Nauru",
        "Nepal",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolkseiland",
        "Noorwegen",
        "Nieuw-Caledonië",
        "Nieuw-Zeeland",
        "Brits gebied in de Indische Oceaan",
        "Oman",
        "Oeganda",
        "Oezbekistan",
        "Pakistan",
        "Palau",
        "Palestijnse Autoriteit",
        "Panama",
        "Papoea-Nieuw-Guinea",
        "Paraguay",
        "Nederland",
        "Peru",
        "Filippijnen",
        "Pitcairneilanden",
        "Polen",
        "Frans-Polynesië",
        "Puerto Rico",
        "Portugal",
        "Qatar",
        "Vergadering",
        "Roemenië",
        "Verenigd Koninkrijk",
        "Rusland",
        "Rwanda",
        "Westelijke Sahara",
        "Saint-Barthélemy",
        "Saint Kitts en Nevis",
        "San Marino",
        "Sint Maarten (Frans West-Indië)",
        "Sint Maarten",
        "Saint-Pierre en Miquelon",
        "Sint Vincent en de Grenadines",
        "Sint Helena, Hemelvaart en Tristan da Cunha",
        "Sint Lucia",
        "Salomon",
        "Samoa",
        "Amerikaans-Samoa",
        "Sao Tomé en Principe",
        "Senegal",
        "Servië",
        "Seychellen",
        "Sierra Leone",
        "Singapore",
        "Slowakije",
        "Slovenië",
        "Somalië",
        "Soedan",
        "Zuid-Soedan",
        "Sri Lanka",
        "Zweden",
        "Zwitserland",
        "Suriname",
        "Spitsbergen en Jan Mayen Eiland",
        "Swaziland",
        "Syrië",
        "Tadzjikistan",
        "Taiwan / Republiek China",
        "Tanzania",
        "Tsjaad",
        "Tsjechische Republiek",
        "Franse Zuidelijke en Antarctische landen",
        "Thailand",
        "Oost-Timor",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad en Tobago",
        "Tunesië",
        "Turkmenistan",
        "Turks- en Caicoseilanden",
        "Turkije",
        "Tuvalu",
        "Oekraïne",
        "Uruguay",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Wallis en Futuna",
        "Jemen",
        "Zambia",
        "Zimbabwe",
      ],
    };

    private extClients: Third[]; // The clients retrieved from the accountant side (all clients of the company, not invoicing-only clients)

    private client: InvoicingClient; // The currently edited InvoicingClient

    private newClient: boolean = true; // True if we are creating a new client, false if we are modifying an already existing one

    private invalidInputBooleans = {
      fullName: false,
      vat: false,
      emailAddress: false,
      address: false,
      zip: false,
      city: false,
      country: false,
    };

    $onInit() {}

    static $inject = [
      "r_client",
      "r_clients",
      "$scope",
      "$stateParams",
      "$translate",
      "$translatePartialLoader",
      "$uibModal",
      "InvoicingService",
      "ClientService",
      "Notification",
      "SessionService",
    ];

    constructor(
      private r_client: InvoicingClient,
      private r_clients: Third[],
      private $scope,
      private $stateParams: ng.ui.IStateParamsService,
      private $translate: ngt.ITranslateService,
      private $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $uibModal,
      private invoicingService: services.InvoicingService,
      private clientService: services.ClientService,
      private notification: any,
      private sessionService: common.session.SessionService
    ) {
      this.extClients = r_clients;

      $translatePartialLoader.addPart("customer/invoicing");

      this.client = new InvoicingClient();
      if (this.$stateParams.client != null || r_client != null) {
        // If the client already exists
        this.newClient = false;
        let clientSrc: InvoicingClient;
        if (this.$stateParams.client != null) {
          clientSrc = this.$stateParams.client;
        } else {
          clientSrc = r_client;
        }
        angular.copy(clientSrc, this.client);
      }
    }

    /**
     * Invoked whenever the typeahead changes, imports informations from an existing client when one is selected in the typeahead
     * @param selectedClient The currently selected client in the import typeahead
     */
    // typeaheadChanged(selectedClient?: Third): void {
    //     const property = ["importReference", "fullName", "vat", "address", "zip", "city"]
    //     if (selectedClient) {
    //         property.forEach(aProp => {
    //             if (aProp == "importReference") {
    //                 this.client[aProp] = selectedClient.reference;
    //             }
    //             else if (aProp == "address") {
    //                 this.client[aProp] = selectedClient.adresse;
    //             }
    //             else {
    //                 this.client[aProp] = selectedClient[aProp];
    //             }
    //         });
    //     }
    //     else {
    //         property.forEach(aProp => {
    //             this.client[aProp] = null;
    //         })
    //     }
    // }

    /**
     * Called when the client selects a country in the country typeahead
     * @param item The selected country
     */
    countryTypeaheadChanged(item): void {
      let idx =
        this.countries[
          this.sessionService.session.member.language.toLowerCase()
        ].indexOf(item);
      this.client.isoCountry = this.countries.iso[idx];
    }

    /**
     * Checks the validity of most of the form's inputs, calls sendClient() when everything's fine, otherwise highlights inputs with an error
     */
    validateForm() {
      let errorField: any = null;

      if (this.client.fullName) {
        if (this.client.vat) {
          if (document.querySelector("#emailAddressInput.ng-valid-pattern")) {
            if (this.client.address) {
              if (this.client.zip) {
                if (this.client.city) {
                  if (this.client.country) {
                    this.sendClient();
                  } else {
                    errorField = "country";
                  }
                } else {
                  errorField = "city";
                }
              } else {
                errorField = "zip";
              }
            } else {
              errorField = "address";
            }
          } else {
            errorField = "emailAddress";
          }
        } else {
          errorField = "vat";
        }
      } else {
        errorField = "fullName";
      }

      if (errorField) {
        this.invalidInputBooleans[errorField] = true;
        let errorFieldInput = document.getElementById(errorField + "Input");
        if (errorFieldInput) {
          errorFieldInput.focus();
        }
      }
    }

    /**
     * Sends the client to store in the DB to the back-end
     * Calls addInvoicingClient when the client is a new one
     * Calls updateInvoicingClient when the client already exists in the DB
     */
    sendClient() {
      if (this.newClient) {
        this.clientService
          .addInvoicingClient(
            this.sessionService.session.sessionID,
            this.sessionService.session.company.key,
            this.client
          )
          .then((response) => {
            if (response.status == 0) {
              this.notification.success(
                this.$translate.instant("INVOICING.CLIENT.EDITION.clientSaved")
              );
            } else {
              throw new Error("Server response different than 0");
            }
          })
          .catch((error) => {
            console.error(error);
            this.notification.error(
              "Erreur lors de l'enregistrement du client"
            );
          });
      } else {
        this.clientService
          .updateInvoicingClient(
            this.sessionService.session.sessionID,
            this.client
          )
          .then((response) => {
            if (response.status == 0) {
              this.notification.success(
                this.$translate.instant("INVOICING.CLIENT.EDITION.clientSaved")
              );
            } else {
              throw new Error("Server response different than 0");
            }
          })
          .catch((error) => {
            console.error(error);
            this.notification.error(
              "Erreur lors de l'enregistrement du client"
            );
          });
      }
    }

    /**
     * Deletes an invoice ; asks for confirmation, then gets back clients from the server to refresh the view
     */
    delete() {
      try {
        let client = this.client;
        let self = this;
        this.$uibModal
          .open({
            templateUrl: "tpl/website/invoicing/modal/invgConfModal.html",
            controller: "InvgConfModalController",
            controllerAs: "confMdCtrl",
            backdrop: "static",
            resolve: {
              r_title: () => {
                return self.$translate.instant("INVOICING.MODAL.delete");
              },
              r_content: () => {
                return (
                  "<p>" +
                  self.$translate.instant(
                    "INVOICING.CLIENT.EDITION.deleteMsg1",
                    { clientName: client.fullName }
                  ) +
                  ".</p>" +
                  "<p><u>" +
                  self.$translate.instant(
                    "INVOICING.CLIENT.EDITION.deleteMsg2"
                  ) +
                  "</u></p>"
                );
              },
              r_buttons: () => {
                return {
                  cancel: {
                    text: self.$translate.instant("INVOICING.MODAL.cancel"),
                    class: "btn-default",
                  },
                  confirm: {
                    text: self.$translate.instant("INVOICING.MODAL.delete"),
                    class: "btn-danger",
                  },
                };
              },
            },
          })
          .result.then((confirmation) => {
            if (confirmation) {
              this.clientService
                .deleteInvoicingClient(
                  this.sessionService.session.sessionID,
                  this.sessionService.session.company.key,
                  client.key
                )
                .then((responseDelete) => {
                  if (responseDelete.status == 0) {
                    this.notification.success(
                      this.$translate.instant(
                        "INVOICING.CLIENT.EDITION.clientDeleted"
                      )
                    );
                  } else {
                    throw new Error("Server response different than 0");
                  }
                });
            }
          });
      } catch (e) {
        console.warn(e);
        this.notification.error("Erreur lors de la suppression du client");
      }
    }
  }
}
angular
  .module("app")
  .controller(
    "ClientEditionController",
    app.functionality.invoicing.controllers.ClientEditionController
  );
