/// <reference path="../../../_app.ts" />

module app.functionality.common.utils {
  import SessionService = app.functionality.common.session.SessionService;

  export class MimeTypes {
    static $inject = ["SessionService"];

    constructor(private sessionService: SessionService) {}

    public mimeTypes: Map<string, string> = new Map([
      ["ogg", "application/ogg"],
      ["pdf", "application/pdf"],
      ["xhtml+xml", "application/xhtml+xml"],
      ["json", "application/json"],
      ["ld+json", "application/ld+json"],
      ["xml", "application/xml"],
      ["zip", "application/zip"],
      ["odt", "application/application/vnd.oasis.opendocument.text"],
      ["ods", "application/application/vnd.oasis.opendocument.spreadsheet"],
      ["odp", "application/vnd.oasis.opendocument.presentation"],
      ["7z", "application/x-7z-compressed"],
      ["xul", "application/vnd.mozilla.xul+xml"],
      ["xls", "application/vnd.ms-excel"],
      [
        "xlsx",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
      ["doc", "application/msword"],
      [
        "docx",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ],
      ["ppt", "application/vnd.ms-powerpoint"],
      [
        "pptx",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      ],
      ["arc", "application/x-freearc"],
      ["rar", "application/vnd.rar"],
      ["tar", "application/x-tar"],
      ["rtf", "application/rtf"],
      ["bin", "application/octet-stream"],
      ["bz", "application/x-bzip"],
      ["bz2", "application/x-bzip2"],
      ["gz", "application/gzip"],
      ["gif", "image/gif"],
      ["jpeg", "image/jpeg"],
      ["jpg", "image/jpeg"],
      ["png", "image/png"],
      ["tiff", "image/tiff"],
      ["tif", "image/tiff"],
      ["djvu", "image/vnd.djvu"],
      ["djv", "image/vnd.djvu"],
      ["svg", "image/svg+xml"],
      ["csv", "text/csv"],
      ["txt", "text/plain"],
    ]);

    public getMimeType(extension: string) {
      /* check if the first char is a point */
      if (extension.substring(0, 1) === ".") {
        /* remove that point */
        extension = extension.substring(1);
      }
      /* check if the extension is actually in the map */
      if (this.mimeTypes.has(extension.toLowerCase())) {
        /* return the correct mime-type */
        return this.mimeTypes.get(extension.toLowerCase());
      } else {
        /* if it is not in the map return an empty string */
        return "";
      }
    }
  }
}

angular
  .module("app")
  .service("MimeTypes", app.functionality.common.utils.MimeTypes);
