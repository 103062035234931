/// <reference path="../../../_app.ts" />


module app.config.constants.environment {

    export class ConstantEnv {
        public environment: string = "TEST";
        public baseUrl: string = "api";
        public mainUrl: string = "http://localhost:8080/";
        public redirectUrl: string = "http://localhost:8080/";

        private BugnsagApiKey: string = "65aac105848620aedda96d7bf7b858ce";
        private appVersion: string = "4.4.4.02.28";

        public getBugnsagApiKey() {
            return this.BugnsagApiKey;
        }

        public getVersion() {
            return this.appVersion;
        }

        public removeSpecialCharFromFilename(filename: string): string {
            let editedName = filename;
            editedName = filename.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, "");
            editedName = editedName.replace(/à/g,"a");
            editedName = editedName.replace(/ê/g,"e");
            editedName = editedName.replace(/è/g,"e");
            editedName = editedName.replace(/é/g,"e");
            editedName = editedName.replace(/ù/g,"u");
            return editedName;
        }
    }
}

angular.module("app")
    .constant("ENV", new app.config.constants.environment.ConstantEnv());