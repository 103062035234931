/// <reference path="../../_app.ts" />

module app.model.parameter {

    export class PNLColumnConfig {
        public key: string;
        public indexPosition: number;

        public title: string;

        public dataSource: number;

        public typeView: number;

        public currentViewEnabled: boolean;

        public key_PNLColumnConfigCompared: string;

        // OPTION
        public bookyear: number;

        public periodArr: Array <number>;

        //if we are in quarterly view, index will be equal to the number of quarter.
        //If we are in monthly view, index will be equal to the month number

        /**
         * Data never stored, only use during the session
         */
        public computedBookyear: number;
        public computedPerriodArr: Array<number>;

        static get ACCOUNTING(): number {return 1; }
        static get BUDGET(): number {return 2; }

        static get YEARLY(): number {return 1; }
        static get QUARTERLY(): number {return 2; }
        static get MONTHLY(): number {return 3; }

        constructor() {
        }

        public static getDefaultPNL( bookyear: number): PNLColumnConfig {
            let pnlCfgBase: PNLColumnConfig = new PNLColumnConfig();
            pnlCfgBase.currentViewEnabled = false;
            pnlCfgBase.dataSource = this.ACCOUNTING;
            pnlCfgBase.indexPosition = 0;
            pnlCfgBase.typeView = this.YEARLY; // monthly : 3

            pnlCfgBase.computedBookyear = bookyear;
            pnlCfgBase.bookyear = bookyear; // bookyear 4 pour 2016
            pnlCfgBase.computedPerriodArr = null; // add periodArr, ind 8,7,6

            pnlCfgBase.title =  "Solde";

            return pnlCfgBase;
        }

        // public getCompanyDataKey(): string {
            // var companyDatakey:String = "";
            //
            // if (dataSource == ACCOUNTING)
            //     companyDatakey += CompanyData.BALANCE_KEY;
            // else
            //     companyDatakey += CompanyData.BUDGET_KEY;
            //
            // if (this.currentViewEnabled)
            //     companyDatakey += "CURR";
            //
            // if (typeView == YEARLY) {
            //     companyDatakey += "YEARLY" + bookyear;
            //
            // }
            // else if (typeView == QUARTERLY) {
            //     companyDatakey += "QUARTERLY" + bookyear;
            //
            //     if (periodArr) {
            //         for (var i:int = 0; i < periodArr.length; i++)
            //             companyDatakey += periodArr[i];
            //     }
            // }
            // else {
            //     /**MONTHLY VIEW**/
            //     companyDatakey += "MONTHLY" + bookyear;
            //
            //     if (periodArr) {
            //         for (var j:int = 0; j < periodArr.length; j++)
            //             companyDatakey += periodArr[j];
            //     }
            // }
            // companyDatakey += "1"; //one for type of DATA PNL;
            //
            // if (key_PNLColumnConfigCompared)
            //     companyDatakey += key_PNLColumnConfigCompared;
            //
            // return companyDatakey;
        // }
    }
}