/// <reference path="../../_app.ts" />

module app.model.exchange {

    export class ResponseObject {

        public status: number;
        public data: any;

        constructor() {
        }

    }

}
