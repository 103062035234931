/// <reference path="../../../_app.ts" />

module app.modules.tablePaging.controller {
  export class TablePagingController implements angular.IController {
    /**
     * Represents all the data that we get from the server
     */
    public allData: Object[];

    /**
     * Represents only the data to display in the view. It is used to display the data on different pages in
     * the table.
     */
    public dataToDisplay: Object[];

    /**
     * Represents only the data to display in the current page
     */
    public data;

    /**
     * The possibilities of number of data that can be displayed on the same page.
     * @type {number[]}
     */
    public numberOfDataAllowedToDisplay: number[] = [5, 10, 15, 20, 25];

    /**
     * The current number of data that can be displayed on the same page.
     * @type {number}
     */
    public numberOfDataToDisplay;

    /**
     * The current page shown in the table
     */
    public pageNumber;

    /**
     * The current value used to sort the table
     */
    public sortValue: string;

    /**
     * The order used to sort the table (ascending = false, descending = true)
     */
    public order: boolean;

    public push;
    public length;

    public scope;

    public defaultDisplay;

    public sortFunctionParameter: string;

    public elementName: string;

    public descending: boolean;

    $onInit() {}

    static $inject = ["$translatePartialLoader", "SessionService"];
    constructor(
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private sessionService: app.functionality.common.session.SessionService
    ) {
      $translatePartialLoader.addPart("common/tablePaging");
      this.order = this.descending != undefined ? this.descending : false;
      this.dataToDisplay = this.allData;
      if (this.defaultDisplay !== "-1") {
        this.defaultDisplay =
          this.sessionService.session.member.numberOfDataToDisplay;
      }
      this.numberOfDataToDisplay =
        this.defaultDisplay != undefined
          ? this.defaultDisplay
          : this.numberOfDataAllowedToDisplay[4];
      this.displayDataInPage(0);
    }

    /**
     * This function is used by the user to sort the data on the view table
     * It keeps the previous sort value in order to know if the user wants to decreasingly sort the data
     * @param {string} newValue the new column value that should be used to sort the data
     */
    public sortingValue(newValue: string, parameter: string) {
      if (newValue == this.sortValue) {
        this.order = !this.order;
      } else {
        this.order = false;
        this.sortValue = newValue;
      }

      this.sortFunctionParameter = parameter;
      this.sortData();
      this.displayDataInPage((this.pageNumber = 0));
    }

    /**
     * This function is used to sort the data based on the type of the sort value (if it is an attribute or a function)
     */
    private sortData() {
      let self = this;

      let functionTest = self.getCompareFunction(self.sortValue);
      if (functionTest != undefined) {
        this.dataToDisplay.sort(function (a: Object, b: Object) {
          if (functionTest(a) < functionTest(b)) {
            return !self.order ? -1 : 1;
          }
          if (functionTest(a) > functionTest(b)) {
            return !self.order ? 1 : -1;
          }
          return 0;
        });
      }
    }

    /**
     * This function is used to refresh the view when the data binded are modified
     */
    public resetData() {
      this.dataToDisplay = this.allData;
      this.sortData();
      this.displayDataInPage(0);
    }

    /**
     * This function returns the function that should be executed as a comparator for the data
     * @param argCompare the value that we want to compare or sort
     * @returns {any} undefined if no data are displayed
     */
    private getCompareFunction(argCompare) {
      let self = this;
      if (this.allData.length > 0) {
        if (this.allData[0][argCompare] != undefined) {
          if (typeof this.allData[0][argCompare] == "function") {
            return function (a) {
              return self.sortFunctionParameter == undefined
                ? a[argCompare]()
                : a[argCompare](self.sortFunctionParameter);
            };
          } else {
            return function (a) {
              return a[argCompare];
            };
          }
        } else {
          return function (a) {
            return _.get(a, argCompare);
          };
        }
      }
      return undefined;
    }

    /**
     * This function is used to display the data in the selected page
     * @param {number} pageNumber the page that should be displayed
     */
    public displayDataInPage(pageNumber: number) {
      if (this.numberOfDataToDisplay === "-1") {
        this.data = this.dataToDisplay;
      } else {
      }
      if (pageNumber >= 0 && pageNumber <= this.getMaxPage()) {
        let start = pageNumber * this.numberOfDataToDisplay;
        let end;
        start + this.numberOfDataToDisplay > this.dataToDisplay.length
          ? (end = this.dataToDisplay.length)
          : (end = start + this.numberOfDataToDisplay);
        this.data = this.dataToDisplay.slice(start, end);
        this.pageNumber = pageNumber;
      }
    }

    /**
     * This function is used to know the number of page that the table contains
     * @returns {number} the number of page that the table contains
     */
    public getMaxPage(): number {
      return Math.floor(this.dataToDisplay.length / this.numberOfDataToDisplay);
    }

    /**
     * This function is used for the search filters on the table
     * Never used, may not work
     * @param {string} newValue The current value of the search filter
     * @param {string} fieldToRestrict The field on which the filter is used
     * @param {string} type < > or =
     */
    public restrictData(
      newValue: string,
      fieldToRestrict: string,
      type: string
    ) {
      let self = this;

      let functionTest = self.getCompareFunction(fieldToRestrict);

      if (functionTest != undefined) {
        if (type == "<") {
          self.dataToDisplay = self.allData.filter(function (a) {
            return (
              functionTest(a).toLowerCase().indexOf(newValue.toLowerCase()) !=
              -1
            );
          });
        } else if (type == "=") {
          self.dataToDisplay = self.allData.filter(function (a) {
            return functionTest(a) == newValue;
          });
        } else if (type == ">") {
          self.dataToDisplay = self.allData.filter(function (a) {
            return functionTest(a) > newValue;
          });
        }
        self.displayDataInPage(0);
      }
    }

    updateNumberOfDataToDisplay(number: number) {
      this.sessionService.updateNumberOfDataToDisplay(number);
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "TablePagingController",
    app.modules.tablePaging.controller.TablePagingController
  );
