/// <reference path="../../_app.ts" />

namespace app.functionality.upload {
  import Invoice = app.model.Invoice;
  import SessionService = app.functionality.common.session.SessionService;
  import ConstantEnv = app.config.constants.environment.ConstantEnv;
  import ModalService = app.functionality.common.modals.ModalService;

  export class UploadService {
    private rest: restangular.IService;

    public fileInput: any;
    public uploadFolderKey;

    public valueNow = 0;
    public valueMax = 0;
    public percent = 0;

    public totalFiles: number;
    public countFile: number;

    public mapFiles: any;

    public mapUploadError: any;

    public sessionID: string;
    private companyKey: string;
    private memberKey: string;

    public model;
    public key;
    public map;

    static $inject = [
      "RestService",
      "RESTAPI",
      "$rootScope",
      "SessionService",
      "$sanitize",
      "$http",
      "ENV",
      "ModalService",
      "$q",
    ];

    constructor(
      restService: app.functionality.common.rest.RestService,
      private RESTAPI: app.config.constants.ConstantRestApi,
      private $rootScope: ng.IRootScopeService,
      private sessionService: SessionService,
      private $sanitize: ng.sanitize.ISanitizeService,
      private $http: ng.IHttpService,
      private ENV: ConstantEnv,
      private modalService: ModalService,
      private $q: ng.IQService
    ) {
      this.rest = restService.getRoot();

      // init for the two "maps" which contains all the files characteristics
      this.mapUploadError = {};
      this.mapUploadError.size = 0;
      this.mapUploadError.filesArray = [];
      this.mapUploadError.duplicateFiles = [];
      this.mapFiles = {};
      this.mapFiles.size = 0;
      this.countFile = 0;
      this.totalFiles = 0;

      // this.sessionID = this.sessionService.session.sessionID;
      this.companyKey = this.sessionService.session.company.key;
      this.memberKey = this.sessionService.session.member.key;

      this.$rootScope.$on("clearMapUploadError", (event) => {
        this.clearUploadData();
      });

      // still temporary, have to figure out why we change that
      // if (angular.isDefined(this.fileInput)) {
      //     let arrayFiles: Array<File> = this.fileInput.files;
      //     this.totalFiles = arrayFiles.length;
      //     for (file of arrayFiles) {
      //
      //         // this.sendFile(file);
      //     }
      // }
    }

    initArchive = (year: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.upload.base).one(year).get();
    };

    // /getSentInvoiceByMonth/{sessionID}&{companyKey}&{year}
    getSentInvoiceByMonth = (year: number): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .all(this.RESTAPI.services.upload.getSentInvoiceByMonth)
        .one(
          this.sessionService.session.sessionID +
            "&" +
            this.sessionService.session.company.key +
            "&" +
            year
        )
        .get();
    };

    // getInvoice = (numMonth: number, year: string): ng.IPromise<any> => {
    //     return this.rest.all(this.RESTAPI.services.upload.base).one(numMonth.toString()).one(year).get();
    // };

    getInvoice = (numMonth: number, year: string): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .all(this.RESTAPI.services.upload.getInvoiceForMonth)
        .one(
          this.sessionService.session.sessionID +
            "&" +
            this.sessionService.session.company.key +
            "&" +
            numMonth +
            "&" +
            year
        )
        .get();
    };

    // {sessionID}&{companyKey}&{query}&{uuid}
    searchForFiles = (keyWord: string): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .all(this.RESTAPI.services.upload.searchForFiles)
        .one(
          this.sessionService.session.sessionID +
            "&" +
            this.sessionService.session.company.key +
            "&" +
            keyWord +
            "&0&"
        )
        .get();
    };

    // Fin archives
    initToSend = (): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.upload.base)
        .one("toSend")
        .get();
    };

    initUploadFolder = (showArchived: boolean): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.upload.accounting)
        .all(this.RESTAPI.services.upload.getUploadFolder)
        .one(
          this.sessionService.session.sessionID +
            "&" +
            this.sessionService.session.company.key +
            "&" +
            showArchived
        )
        .get();
    };

    // WHY A POST WITH PARAMETERS ON THE URL?
    // {sessionID}&{invoiceKey}&{companyKey}&{keepAnnotation}
    splitInvoice = (
      invoiceKey: String,
      keepNote: boolean
    ): ng.IPromise<any> => {
      // let postData = {
      //     sessionID: this.sessionService.session.sessionID,
      //     invoiceKey: invoiceKey,
      //     companyKey: this.companyKey,
      //     keepNote: keepNote
      // };
      return this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .all(this.RESTAPI.services.upload.splitInvoice)
        .one(
          this.sessionService.session.sessionID +
            "&" +
            invoiceKey +
            "&" +
            this.sessionService.session.company.key +
            "&" +
            keepNote
        )
        .customPOST();
    };

    // (String) data.sessionID, (ArrayList<String>) data.invKeyArr, (String) data.companyKey, (String) data.filename, (Boolean) data.keepAnnotation
    mergeInvoices = (
      invKeyArr: Array<String>,
      filename: string,
      keepNote: boolean,
      uploadFolderKey: string
    ): ng.IPromise<any> => {
      let postData = {
        sessionID: this.sessionService.session.sessionID,
        invKeyArr: invKeyArr,
        companyKey: this.sessionService.session.company.key,
        filename: encodeURI(this.ENV.removeSpecialCharFromFilename(filename)),
        keepAnnotation: keepNote,
        idFolder: uploadFolderKey,
      };
      return this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .all(this.RESTAPI.services.upload.mergeInvoicesInFolder)
        .post(postData);
    };

    addInvoice = (sessionID: string, invoice: Invoice): ng.IPromise<any> => {
      let request = this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .all(this.RESTAPI.services.upload.addInvoice);
      return request.customPUT({
        sessionID: sessionID,
        invoice: invoice,
      });
    };

    deleteInvoice = (invKeyArr: string[]): ng.IPromise<any> => {
      let promises = [];
      // loop for promises : http://stackoverflow.com/questions/41759375/restangular-post-in-loop
      for (let invKey of invKeyArr) {
        let request = this.rest
          .all(this.RESTAPI.services.upload.invoice)
          .all(this.RESTAPI.services.upload.deleteInvoice)
          .one(this.sessionService.session.sessionID + "&" + invKey)
          .remove();
        promises.push(request);
      }
      return this.$q.all(promises);
    };

    renameInvoice = (
      invoiceKey: string,
      newFileName: string
    ): ng.IPromise<any> => {
      let data = {
        sessionID: this.sessionService.session.sessionID,
        invoiceKey: invoiceKey,
        newFileName: encodeURI(
          this.ENV.removeSpecialCharFromFilename(newFileName)
        ),
      };
      let request = this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .all(this.RESTAPI.services.upload.renameInvoice);
      return request.customPOST(data);
    };

    deleteFileKey = (fileKey: string): ng.IPromise<any> => {
      let promises = [];
      // loop for promises : http://stackoverflow.com/questions/41759375/restangular-post-in-loop
      let request = this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .all(this.RESTAPI.services.upload.deleteFileKey)
        .one(this.sessionService.session.sessionID + "&" + fileKey)
        .remove();
      promises.push(request);
      return this.$q.all(promises);
    };

    updateNote = (invoiceKey: string, note: string): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .one(this.RESTAPI.services.upload.setNote)
        .customPOST({
          sessionID: this.sessionService.session.sessionID,
          invoiceKey: invoiceKey,
          note: note,
        });
    };

    downloadInvoices = (
      year: string,
      month: string,
      folderKey: string
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .one(this.RESTAPI.services.upload.downloadInvoices)
        .customPOST({
          sessionID: this.sessionService.session.sessionID,
          companyKey: this.sessionService.session.company.key,
          year: year,
          month: month,
          folderKey: folderKey,
        });
    };

    deleteNote = (invoiceKey: string): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .all(this.RESTAPI.services.upload.deleteNote)
        .one(this.sessionService.session.sessionID + "&" + invoiceKey)
        .remove();
    };

    getInvoiceArr = () => {
      //return { status: 0, data: [] };
      //UNCOMMENT
      return this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .all(this.RESTAPI.services.upload.getInvoiceArr)
        .one(
          this.sessionService.session.sessionID +
            "&" +
            this.sessionService.session.company.key
        )
        .get();
    };

    sendInvoice = (invKeyArr: string[]): ng.IPromise<any> => {
      let data = {
        sessionID: this.sessionService.session.sessionID,
        invKeyArr: invKeyArr,
        memberKey: this.memberKey,
      };
      return this.rest
        .all(this.RESTAPI.services.upload.invoice)
        .all(this.RESTAPI.services.upload.sendInvoice)
        .post(data);
    };

    // ******************** First Refactor ********************

    uploadFile = (form, file, allowedExtraExtension): ng.IPromise<any> => {
      let self = this;
      this.mapFiles[file.name] = 0;
      this.valueMax = this.valueMax + file.size;
      return this.$http({
        method: "put",
        url: self.ENV.baseUrl + "/RESTUploadMgr",
        data: form,
        headers: {
          "Content-Type": undefined,
        },
        uploadEventHandlers: {
          progress: self.updateProgressBar(file),
          abort: self.transferAbort(file),
          error: self.transferError(file),
        },
      });
    };

    //url: self.ENV.baseUrl + "/Companies/"+self.sessionService.session.company.key+"/PMF",
    uploadPMFFile = (form, file): ng.IPromise<any> => {
      let self = this;
      this.mapFiles[file.name] = 0;
      this.valueMax = this.valueMax + file.size;
      return this.$http({
        method: "put",
        url: self.ENV.baseUrl + "/PMF_Upload_Service",
        data: form,
        headers: {
          "Content-Type": undefined,
        },
        uploadEventHandlers: {
          progress: self.updateProgressBar(file),
          abort: self.transferAbort(file),
          error: self.transferError(file),
        },
      });
    };

    uploadIPPAnnexeFile = (form, file): ng.IPromise<any> => {
      let self = this;
      this.mapFiles[file.name] = 0;
      this.valueMax = this.valueMax + file.size;
      return this.$http({
        method: "put",
        //url: self.ENV.baseUrl+"/"+self.RESTAPI.services.IPPForm.baseurl+"/"+self.sessionService.session.member.key + "/" + self.RESTAPI.services.IPPForm.ipps+"/"+keyIPP,
        url: self.ENV.baseUrl + "/IPPFormUploadMgr",
        data: form,
        headers: {
          "Content-Type": undefined,
        },
        uploadEventHandlers: {
          progress: self.updateProgressBar(file),
          abort: self.transferAbort(file),
          error: self.transferError(file),
        },
      });
    };

    /**
     * Before the upload, the file to send is checked with FileReader.
     * Before the reading by FileReader, we check the MIME of the file.
     * FileReader read the file like a text. Every pdf file begin with "%PDF"
     * If the file doesn't meet these two conditions, the upload (XHR) is abort.
     * The following of the events is in the method "transferAbort"
     * @param file
     * @returns {(e:any)=>undefined}
     */
    public checkFile(file: File, allowedExtraExtension: Array<String>) {
      return function (e) {
        try {
          let xhr: XMLHttpRequest = e.target;
          let reader = new FileReader();
          if (!allowedExtraExtension) {
            allowedExtraExtension = [];
          }
          reader.addEventListener("loadstart", function () {
            let fileExtension = "";
            if (file.name) {
              fileExtension = file.name
                .substring(file.name.lastIndexOf("."))
                .toLowerCase();
            } else {
              if (xhr && xhr.readyState < 3) {
                //xhr.abort();
              }
            }
            if (
              file.type === "" ||
              (file.type !== "text/xml" &&
                file.type !== "application/xml" &&
                file.type !== "application/pdf" &&
                file.type !== "image/png" &&
                file.type !== "image/jpeg" &&
                file.type !== "image/pjpeg" &&
                file.type !== "image/jpg" &&
                file.type !== "application/msword" &&
                file.type !==
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
                allowedExtraExtension.indexOf(fileExtension) === -1)
            ) {
              if (xhr && xhr.readyState < 3) {
                //xhr.abort();
              }
            }
          });

          // Commenting this part because some pdf are displaying correctly but are not conform with the standard
          // We need to find a way to detect this and inform the user before integrating this again
          // if (file.type === "application/pdf") {
          //   reader.addEventListener("load", function () {
          //     // do a loop to avoid line breaker in first... position until reach letters
          //     let index = 0;
          //     while (reader.result.substring(index, index + 1) === "\n") {
          //       index++;
          //     }
          //     let data: string = reader.result.substring(index, index + 4);
          //     let regex: RegExp = new RegExp("%PDF");
          //     if (!data.match(regex)) {
          //       xhr.abort();
          //     }
          //   });
          // }

          reader.readAsText(file, "UTF-8");
        } catch (error) {
          console.error(error);
        }
      };
    }

    /**
     * When a file doesn't meet the method "checkFile", it is stored in the error map.
     * The key is the file and the value is the reason. Here the reason is the file isn't a pdf file.
     * @param file : file to store
     * @returns {(e:any)=>undefined}
     */
    public transferAbort(file: File) {
      let self = this;
      let errorType = "UPLOAD.TOSEND.ERROR_UPLOAD.type";
      return function (e) {
        if (self.mapUploadError[errorType] == null) {
          self.mapUploadError[errorType] = [];
        }
        self.mapUploadError[errorType].push(file);
        self.mapUploadError.size++;
      };
    }

    /**
     * When a problem is generated during the upload (internet problem),
     * the file is stored in the error map
     * @param file : file to store in the error map
     * @returns {(e:any)=>undefined}
     */
    public transferError(file: File) {
      let self = this;
      let errorType = "UPLOAD.TOSEND.ERROR_UPLOAD.internet";
      return function (e) {
        if (self.mapUploadError[errorType] == null) {
          self.mapUploadError[errorType] = [];
        }
        self.mapUploadError[errorType].push(file);
        self.mapUploadError.size++;
      };
    }

    public printError(file: File) {
      let self = this;
      let errorTypes = [
        "UPLOAD.TOSEND.ERROR_UPLOAD.internet",
        "UPLOAD.TOSEND.ERROR_UPLOAD.type",
        "UPLOAD.TOSEND.ERROR_UPLOAD.server",
      ];
      for (let errorType of errorTypes) {
        if (self.mapUploadError[errorType] != null) {
          for (let errorFile of self.mapUploadError[errorType]) {
            if (errorFile === file) {
              let index = self.mapUploadError[errorType].indexOf(errorFile);
              self.mapUploadError[errorType].splice(index, 1);
              self.mapUploadError.size--;
              return errorType;
            }
          }
        }
      }
    }

    public sendIPPFileHandler(fileToSend: any, error: any) {
      if (!error) {
        this.countFile++;
      }
      if (this.totalFiles === this.countFile) {
        this.clearUploadData;
      }
      // CATCH ERROR HERE
      if (error && error.status !== -1) {
        this.errorHandler(fileToSend, error);
      }
    }
    /**
     * When an error is generated by the server,
     * the progress bar is updated like the file is finished,
     * the file is stored in the map error
     * @param file to handle
     * @returns {(response:any)=>undefined}
     */
    public errorHandler(file: File, error?: any) {
      this.mapFiles[file.name] = undefined;
      this.valueNow = this.valueNow + file.size;
      this.percent = Math.round((this.valueNow / this.valueMax) * 100);

      if (this.percent >= 100) {
        this.percent = 100;
        let progressBarUpload = document.querySelector("#progressBarUpload");
        let angularProgressBar = angular.element(progressBarUpload);
        angularProgressBar.removeClass("active");
      }

      let errorType = "";
      if (error) {
        switch (error.status) {
          case 409:
            errorType = "UPLOAD.TOSEND.ERROR_UPLOAD.alreadyExists";
            break;
          case 406:
            errorType = "UPLOAD.TOSEND.ERROR_UPLOAD.type";
            break;
          default:
            errorType = "UPLOAD.TOSEND.ERROR_UPLOAD.server";
            break;
        }
      } else {
        errorType = "UPLOAD.TOSEND.ERROR_UPLOAD.server";
      }

      if (error && error.status !== -1) {
        //if it's really an error and not a ERR_INCOMPLETE_CHUNKED_ENCODING
        if (this.mapUploadError[errorType] === undefined) {
          this.mapUploadError[errorType] = [];
          if (error.status != 409) {
            this.mapUploadError[errorType].push(file);
          }
          this.mapUploadError.size++;
          this.mapUploadError.filesArray.push(file);
          if (error.status === 409) {
            let duplicate = {
              file: file,
              uploadDate: error.headers("uploadDate"),
              memberFullName: error.headers("memberFullName"),
              memberKey: error.headers("memberKey"),
            };
            this.mapUploadError.duplicateFiles.push(duplicate);
          }
        } else {
          let alreadyInError = false;
          this.mapUploadError[errorType].forEach(function (
            currentValue,
            index,
            arr
          ): void {
            if (currentValue === file) {
              alreadyInError = true;
              return;
            }
          });

          if (!alreadyInError) {
            if (this.mapUploadError[errorType] == null) {
              this.mapUploadError[errorType] = [];
            }
            if (error.status != 409) {
              this.mapUploadError[errorType].push(file);
            }
            this.mapUploadError.size++;
            this.mapUploadError.filesArray.push(file);
            if (error.status === 409) {
              let duplicate = {
                file: file,
                uploadDate: error.headers("uploadDate"),
                memberFullName: error.headers("memberFullName"),
                memberKey: error.headers("memberKey"),
              };
              this.mapUploadError.duplicateFiles.push(duplicate);
            }
          }
        }
      }
      //Check it was the last one
      this.displayResultUpload();
    }

    /**
     * When an event progress is received of an upload,
     * the values are update
     * @param file that has progressed
     * @param newValue : number of byte completed for the file
     */
    public updateMap(file: File, newValue: any) {
      let oldValue: any = this.mapFiles[file.name];
      this.valueNow = this.valueNow - oldValue;
      this.valueNow = this.valueNow + newValue;
      this.mapFiles[file.name] = newValue;
    }

    /**
     * When an event progress is received of an upload,
     * the map that contains the number of byte completed for the file is update
     * The progress bar is also updated with the field "percent". This field is bind in the html
     * @param file
     * @returns {(e:any)=>undefined}
     */
    public updateProgressBar(file: File) {
      let self = this;
      // e = progress event

      return function (e) {
        try {
          if (e.lengthComputable) {
            self.updateMap(file, e.loaded);
            self.percent = Math.round((self.valueNow / self.valueMax) * 100);

            let progressBarUpload =
              document.querySelector("#progressBarUpload");
            let angularProgressBar = angular.element(progressBarUpload);
            angularProgressBar.attr("style", "width: " + self.percent + "%");
            angularProgressBar.val(self.percent + "%");

            if (self.percent >= 100) {
              self.percent = 100;
              angularProgressBar.removeClass("active");
            }
          } else {
            //Non calculable
            //LoggerError
          }
        } catch (error) {
          console.error(error);
        }
      };
    }

    /**
     * For each end of an upload,
     * we will see if it is the last one and if the percent of the progress bar is at 100%
     * (sometimes the percent > 100% because the file.size total is different of the file.size total from the event progress)
     * If it is the last one, the modal is closed
     * If the error map contains a file, the map is sent to ToSendController and display with another modal
     */

    public displayResultUpload() {
      this.countFile++;
      let defered = this.$q.defer();
      if (this.countFile === this.totalFiles && this.percent >= 100) {
        if (this.mapUploadError.size >= 1) {
          this.$rootScope.$broadcast(
            "displayResultUpload",
            this.mapUploadError
          );
          defered.reject();
        } else {
          let fileInputAngular = angular.element(this.fileInput);
          //Reset the fileInput for the next upload by the user
          fileInputAngular.val("");
          let progressBarUpload = document.querySelector("#progressBarUpload");
          let angularProgressBar = angular.element(progressBarUpload);
          angularProgressBar.removeClass("active");
          defered.resolve(true);
          this.$rootScope.$broadcast(
            "allUploadsCompleted",
            this.mapUploadError
          );
        }
      }
      return defered.promise;
    }

    public handleUploadSuccess(response, modalInstance) {
      this.countFile++;
      if (this.totalFiles === this.countFile && this.mapUploadError.size == 0) {
        this.clearUploadData();
        modalInstance.close();
      } else if (
        this.totalFiles === this.countFile &&
        this.mapUploadError.size >= 1
      ) {
        /* IF THERE IS A WRONG FILE */
        this.$rootScope.$broadcast("displayResultUpload", this.mapUploadError);
      }
    }

    /**
     * Clear/reset all data related to the uploading (success & error) process
     */
    clearUploadData() {
      this.mapUploadError = {};
      this.mapUploadError.size = 0;
      this.mapUploadError.filesArray = [];
      this.mapUploadError.duplicateFiles = [];
      this.mapFiles = {};
      this.mapFiles.size = 0;
      this.countFile = 0;
      this.totalFiles = 0;
      this.percent = 0;
      this.valueNow = 0;
      this.valueMax = 0;
    }
    /** get the mailAWS element
     * MailAWS connect the uploadFolders to his linked mail address and each one contains the whitelist
     * @returns the mailAWS
     */
    public getMailAWS() {
      return this.$http.get(
        `/api/mailAws/${this.sessionService.session.company.key}`
      );
    }

    /** delete an element from the whitelist
     *
     * @param name the mail address to delete
     * @returns the mailAWS
     */
    public deleteFromWhiteList(name: string) {
      return this.$http.delete(
        `/api/mailAws/${this.sessionService.session.company.key}`,
        { params: { mail: name } }
      );
    }

    /** add an element to the whitelist
     *
     * @param name the mail adress to add
     * @returns the mailAWS
     */
    public addToWhiteList(name: string) {
      const data = { mail: name };
      return this.$http.put(
        `/api/mailAws/${this.sessionService.session.company.key}`,
        data
      );
    }
  }
}

angular
  .module("app")
  .service("UploadService", app.functionality.upload.UploadService);
