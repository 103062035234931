/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.controllers {

    import ISale = app.model.interface.ISale;
    import IReminder = app.model.interface.IReminder;
    import InvoiceReminderService = app.functionality.invoiceReminder.services.InvoiceReminderService;
    import Reminder = app.model.invoiceReminder.Reminder;

    export class SaleDetailController implements angular.IController {

        private sale: ISale;
        private reminders: IReminder[];
        private pageTitle: string;

        $onInit() {
        }

        static $inject = [
            "r_saleData",
            "r_remindersData",
            "r_title",
            "$translatePartialLoader",
            "InvoiceReminderService",
            "SessionService",
            "$window",
            "ENV"
        ];

        constructor(r_saleData: ISale,
                    r_remindersData: IReminder[],
                    r_title: string,
                    $translatePartialLoader,
                    private invoiceReminderService: InvoiceReminderService,
                    private sessionService: app.functionality.common.session.SessionService,
                    private $window,
                    private ENV: app.config.constants.environment.ConstantEnv) {

            $translatePartialLoader.addPart("customer/invoiceReminder");
            this.sale = r_saleData;
            this.reminders = r_remindersData;
            this.pageTitle = r_title;

        }

        /**
         * Create a date object to be displayed in the view
         * @param {string} date The date to format
         * @returns {Date} An object that can be displayed in the view
         */
        public getFormatedDate(date: string): Date {
            return new Date(date);
        }

        /**
         * This function is used to create a new window to display the reminder as a pdf in it
         * @param {app.model.invoiceReminder.Reminder} reminder the reminder to display
         */
        public displayPDF(reminder: Reminder) {
            this.$window.open(this.ENV.baseUrl
                + "/reminderHistory/getReminderPDF"
                + "/" + this.sessionService.session.sessionID
                + "/" + reminder.key);
        }
    }
}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("SaleDetailController", app.functionality.invoiceReminder.controllers.SaleDetailController);