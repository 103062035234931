/// <reference path="../../../_app.ts" />

namespace app.functionality.upload {
  import Invoice = app.model.Invoice;
  import UploadService = app.functionality.upload.UploadService;
  import ConstantEnv = app.config.constants.environment.ConstantEnv;
  import SessionService = app.functionality.common.session.SessionService;
  import ModalService = app.functionality.common.modals.ModalService;

  export class UploadModalController {
    private fileInput;
    private uploadFolderKey;
    public valueNow: any;
    public valueMax: any;
    public percent: any;
    public totalFiles: number;
    public countFile: number;
    public mapFiles: any;
    private mapUploadError: any;
    public accFirmKey: any;
    public typeFile: any;
    public ippKey: any;
    public fiscalYear: any;

    //pmf
    private level: number;
    private path: string;

    static $inject = [
      "$uibModalInstance",
      "r_data",
      "pmf_data",
      "ipp_data",
      "$http",
      "UploadService",
      "$rootScope",
      "ENV",
      "$sanitize",
      "SessionService",
      "$scope",
    ];

    constructor(
      public $uibModalInstance: any,
      r_data: any,
      pmf_data: any,
      ipp_data: any,
      protected $http: any,
      private uploadService: UploadService,
      private $rootScope: ng.IRootScopeService,
      private ENV: ConstantEnv,
      private $sanitize: ng.sanitize.ISanitizeService,
      private sessionService: SessionService,
      private $scope: ng.IScope
    ) {
      if (r_data !== undefined && r_data != null) {
        // Invoice Upload
        this.uploadFolderKey = r_data.uploadFolderKey;
        this.initValues(r_data, "toSend");
      } else if (pmf_data !== undefined && pmf_data !== null) {
        // PMF Upload
        this.fileInput = pmf_data.fileInput;
        this.level = pmf_data.level;
        this.path = pmf_data.path;
        if (this.level === 0) {
          this.path = "";
        }
        this.initValues(pmf_data, "pmf");
      } else if (ipp_data !== undefined && ipp_data !== null) {
        // IPP Upload
        this.fileInput = ipp_data.fileInput;
        this.typeFile = ipp_data.typeFile;
        this.ippKey = ipp_data.ippKey;
        this.fiscalYear = ipp_data.fiscalYear;
        this.accFirmKey = ipp_data.accFirmkey;

        this.initValues(ipp_data, "ipp");
      }

      this.$scope.$on("sendAnyway", (event, payload: any) => {
        //this.test(forceUpload);
        this.totalFiles = payload.files.length;
        this.uploadService.totalFiles = payload.files.length;
        this.resetValues();

        for (let file of payload.files) {
          this.mapFiles[file.name] = 0;
          this.valueMax = this.valueMax + file.size;
          this.sendFile(file, payload.forceUpload);
        }
      });
    } //END CONSTRUCTOR

    private initValues(data: any, destination: string) {
      this.valueMax = this.uploadService.valueMax;
      this.valueNow = this.uploadService.valueNow;
      this.percent = this.uploadService.percent;
      this.countFile = this.uploadService.countFile;
      this.mapUploadError = this.uploadService.mapUploadError;
      // this.mapUploadError.size = 0;
      let arrayFiles: Array<File> = [];
      if (destination === "toSend") {
        arrayFiles = data.fileInput;
      } else if (destination === "pmf") {
        arrayFiles = data.fileInput.files;
      } else if (destination === "ipp") {
        arrayFiles = data.fileInput.files;
      }
      this.totalFiles = arrayFiles.length;
      this.mapFiles = this.uploadService.mapFiles;
      let file: File = null;
      this.uploadService.totalFiles = this.totalFiles;
      for (file of arrayFiles) {
        this.mapFiles[file.name] = 0;
        this.valueMax = this.valueMax + file.size;
        if (destination === "toSend") {
          this.sendFile(file);
        } else if (destination === "pmf") {
          this.sendPMFFile(file);
        } else if (destination === "ipp") {
          this.sendIPPFile(file);
        }
      }
    }
    private sendIPPFile(fileToSend: File) {
      let self = this;
      let form: any = new FormData();
      form.append(
        "filenameURI",
        this.$sanitize(
          encodeURI(this.ENV.removeSpecialCharFromFilename(fileToSend.name))
        )
      );
      form.append("accFirmKey", this.accFirmKey);
      form.append("typeFile", this.typeFile);
      form.append("sessionID", this.sessionService.session.sessionID);
      form.append("ippKey", this.ippKey);
      form.append("fiscalYear", this.fiscalYear);
      form.append("file", fileToSend);
      return this.uploadService
        .uploadIPPAnnexeFile(form, fileToSend)
        .then(function (data) {
          let oParser = new DOMParser();
          let returnData = data.data;
          let xmlDocument: Document = oParser.parseFromString(
            returnData,
            "text/xml"
          );

          let fileNameReturned =
            xmlDocument.getElementsByTagName("FileName")[0].childNodes[0]
              .nodeValue;
          self.$rootScope.$broadcast(
            "ipp_" + self.ippKey,
            self.$sanitize(encodeURI(fileNameReturned))
          );
          self.uploadService.sendIPPFileHandler(fileToSend, null);
        })
        .catch((error) => {
          self.$uibModalInstance.dismiss(error);
          self.uploadService.sendIPPFileHandler(fileToSend, error);
        });
    }

    /**
     * Create the form for the server and send to the server
     * Each events are handle in this method
     * @param file
     */
    private sendPMFFile(file: File) {
      let self = this;
      let form: any = new FormData();
      form.append(
        "filenameURI",
        this.$sanitize(
          encodeURI(this.ENV.removeSpecialCharFromFilename(file.name))
        )
      );
      form.append("level", this.level);
      form.append("path", this.path);
      form.append("sessionID", this.sessionService.session.sessionID);
      form.append("companyKey", this.sessionService.session.company.key);
      form.append("type", false);
      form.append("file", file);
      this.uploadService
        .uploadPMFFile(form, file)
        .then(function (data) {
          self.uploadService.percent = 0;
          self.uploadService.countFile = 0;
          self.$rootScope.$broadcast("addPMFFile", true);
          self.$uibModalInstance.close();
        })
        .catch(function (error) {
          if (error.status !== -1) {
            self.uploadService.errorHandler(file, error);
          }
        });
    }

    /**
     * Create the form for the server and send to the server
     * Each events are handle in this method
     * @param file
     */
    private sendFile(file: File, forceUpload?: boolean) {
      let self = this;
      let form: any = new FormData();
      form.append(
        "filenameURI",
        encodeURI(this.ENV.removeSpecialCharFromFilename(file.name))
      );
      form.append("convertToSWF", "false");
      form.append("sessionID", this.sessionService.session.sessionID);
      form.append("companyKey", this.sessionService.session.company.key);
      form.append("typeUpload", "0");

      if (forceUpload) {
        form.append("forceUpload", true); //if we want to send a duplicate file again -> true
      } else {
        form.append("forceUpload", false);
      }
      if (
        angular.isDefined(this.uploadFolderKey) &&
        this.uploadFolderKey != null &&
        this.uploadFolderKey !== ""
      ) {
        form.append("uploadFolderKey", this.uploadFolderKey);
      }
      form.append("file", file);
      this.uploadService
        .uploadFile(
          form,
          file,
          this.sessionService.session.company.allowedUploadExtension
        )
        .then(function (data) {
          let returnData = data.data;
          self.completeHandler(returnData);
        })
        .catch(function (error) {
          // CATCH ERROR HERE
          self.uploadService.errorHandler(file, error);
        });
    }

    /**
     * When an invoice is correctly sent to the server,
     * the server send back an XML file with the invoice data.
     * The new invoice is created but a confirmation has to be received by the sever.
     * The confirmation contains the key of the new invoice.
     * The new invoice is sent to ToSendController to update the ui-grid data via $rootscope
     * @returns {(response:any)=>undefined}
     */
    private completeHandler(response) {
      let oParser = new DOMParser();
      let xmlDocument: Document = oParser.parseFromString(response, "text/xml");
      let newInvoice: Invoice = new Invoice();
      // let newInvoice: any = {};
      newInvoice.name =
        xmlDocument.getElementsByTagName("FileName")[0].childNodes[0].nodeValue;

      newInvoice.fileKey =
        xmlDocument.getElementsByTagName("FileKey")[0].childNodes[0].nodeValue;
      newInvoice.mongoFileKey =
        xmlDocument.getElementsByTagName("FileKey")[0].childNodes[0].nodeValue;
      newInvoice.uploadDate = new Date();
      newInvoice.companyKey = this.sessionService.session.company.key;
      newInvoice.uploaderKey = this.sessionService.session.member.key;
      newInvoice.key =
        xmlDocument.getElementsByTagName(
          "invoiceKey"
        )[0].childNodes[0].nodeValue;
      if (this.uploadFolderKey !== "") {
        newInvoice.uploadFolderKey =
          xmlDocument.getElementsByTagName(
            "UploadFolderKey"
          )[0].childNodes[0].nodeValue;
      }
      // newInvoice.sentStatus = Invoice.NOT_SENT;
      newInvoice.sentStatus = 0;
      this.$rootScope.$broadcast("addInvoice", newInvoice);

      //this.uploadService.percent = 0;
      this.uploadService.handleUploadSuccess(response, this.$uibModalInstance);
    }

    resetValues() {
      this.uploadService.percent = 0;
      this.percent = 0;
      this.countFile = 0;
      this.mapUploadError = {};
      this.mapUploadError.size = 0;
      this.mapUploadError.filesArray = [];
      this.mapUploadError.duplicateFiles = [];
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "UploadModalController",
    app.functionality.upload.UploadModalController
  );
