/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {

    // import IWindowService = angular.IWindowService

    export class CsvModalController {

        public bookyear: number;
        public toPeriod: number;
        public csvExportForm: any;
        public actif = true;
        public passive = true;
        public income = true;
        public detail = true;
        private sessionID: any;
        public language: string;
        public excelFormat = false;
        
        static $inject = [
            "r_fromTo",
            "$uibModalInstance",
            "$window",
            "$translate",
            "ENV",
            "$translatePartialLoader"
        ];

        public helperText = "MODAL.CSV_EXPORT.detailHelperText";
        public excelFormatHelperText = "MODAL.CSV_EXPORT.excelFormatHelperText";

        constructor(r_fromTo: any,
                    public $uibModalInstance: any,
                    public $window: angular.IWindowService,
                    private $translate: ngt.ITranslateService,
                    private ENV: app.config.constants.environment.ConstantEnv,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {
            $translatePartialLoader.addPart("customer/modals");
            this.sessionID = r_fromTo.sessionID;
            this.bookyear = r_fromTo.bookyear;
            this.toPeriod = r_fromTo.toPeriod;
            this.language = r_fromTo.language;
        }

        public export() {

            // ADD ENV VARIABLES
            this.$window.open(this.ENV.baseUrl+"/CSVGenerator?sessionID=" + this.sessionID +
                "&actif=" + this.actif +
                "&passive=" + this.passive +
                "&income=" + this.income +
                "&detail=" + this.detail +
                "&bookyear=" + this.bookyear +
                "&toPeriod=" + this.toPeriod +
                "&excelFormat=" + this.excelFormat +
                "&language=" + this.language, "_blank");
            this.$uibModalInstance.close();
        }

        public cancel() {
            this.$uibModalInstance.close();
        }

    }

}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("CsvModalController", app.functionality.common.modals.CsvModalController);
