/// <reference path="../../_app.ts" />

namespace app.functionality.IPPForm {
  import IPPFormService = app.functionality.IPPForm.IPPFormService;
  import ModalUtil = app.functionality.common.modals.ModalUtil;
  import Member = app.model.Member;
  import UserService = app.functionality.user.UserService;
  import LoggerService = app.functionality.common.logger.LoggerService;

  //export class IPPFormAccountController implements angular.IController {
  export class IPPFormAccountController {
    public user_data: any;
    public password_form_data: any;
    public modalInstance: any;

    public languages = ["NL", "FR", "EN"];

    public language: any;

    public showPWD: boolean = false;
    public wrongPwFlag: boolean;

    static $inject = [
      "r_user_data",
      "UserService",
      "$uibModal",
      "$translate",
      "SessionService",
      "I18NCONSTANTS",
      "$translatePartialLoader",
      "Notification",
      "LoggerService",
      "tmhDynamicLocale",
      /**
      "$translate",
      "$scope",
      "$uibModal",
      "IPPFormService",
      "r_data",
      "$timeout",
      "$state",
      "$window",
      "$http",
      "SessionService",
      "$translatePartialLoader",
      "$stateParams",
      "RESTAPI",
      "Notification",
      "ENV",
      "LoggerService",
      "ModalUtil",
       */
    ];

    public headerURL: string = "../../assets/img/logos/skwarel-header.svg";

    public phonePattern = /^\+(\d{2,3})\d{9}$/;

    constructor(
      r_user_data: any,
      private userService: UserService,
      public $uibModal: any,
      private $translate: ngt.ITranslateService,
      public sessionService: app.functionality.common.session.SessionService,
      i18NCONSTANTS: app.config.constants.I18NConstants,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private notification: any,
      private logger: LoggerService,
      private tmhDynamicLocale /**
      private $translate: ngt.ITranslateService,
      public $scope: ng.IScope,
      private $uibModal: any,
      private iPPService: IPPFormService,
      private r_data: any,
      private $timeout: ng.ITimeoutService,
      private $state: ng.ui.IStateService,
      public $window,
      protected $http: any,
      private sessionService: app.functionality.common.session.SessionService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      $stateParams: ng.ui.IStateParamsService,
      private RESTAPI: app.config.constants.ConstantRestApi,
      public notification: any,
      private env: ENV,
      public logger: app.functionality.common.logger.LoggerService,
      private modalUtil: ModalUtil
      */
    ) {
      $translatePartialLoader.addPart("ipp");
      $translatePartialLoader.addPart("customer/upload");
      $translatePartialLoader.addPart("customer/header");

      $translatePartialLoader.addPart("customer/parameters");
      this.user_data = r_user_data.data;
      this.language = this.sessionService.session.member.language;
      this.password_form_data = this.user_data;
      this.password_form_data.oldPassword = "";
      this.password_form_data.pass1 = "";
      this.password_form_data.pass2 = "";
      this.wrongPwFlag = false;

      if (
        this.sessionService.session.accountingFirmBranding &&
        this.sessionService.session.accountingFirmBranding > 0 &&
        this.sessionService.session.brandingData &&
        this.sessionService.session.brandingData.iconPath
      ) {
        this.headerURL =
          "https://s3-eu-west-1.amazonaws.com/" +
          this.sessionService.session.brandingData.iconPath;
      }
    }

    //$onInit() {}

    updateUserAccount() {
      let self = this;
      this.userService
        .updateUser(this.sessionService.session.sessionID, this.user_data)
        .then(function (data) {
          self.sessionService.session.member.language =
            self.user_data.language.toLowerCase();
          self.notification(
            self.$translate.instant("NOTIFICATIONS.ACCOUNT.changeSuccess")
          );
        })
        .catch(function (error) {
          self.notification.error(
            self.$translate.instant("NOTIFICATIONS.ACCOUNT.changeError")
          );
          self.logger.error(
            "UserController.ts",
            error,
            this.sessionService.session.member
          );
        });
    }

    public changeLanguage() {
      this.$translate.use(this.user_data.language.toLowerCase());
      this.tmhDynamicLocale.set(this.user_data.language.toLowerCase());
      //update the typeahead with the appropriate expression (client, provider)
      // this.thirdsList = this.updateThirdListI18n(this.thirdsList);
    }

    openpwd() {
      this.showPWD = !this.showPWD;
      /* let self = this;
              this.modalInstance = this.$uibModal.open({
                  templateUrl: "tpl/website/modals/changePwdModal.html",
                  controller: "PasswordModalController",
                  controllerAs: "passwordModal",
                  size: "sm",
                  resolve: {
                      passed_user_data: function () {
                          return self.user_data;
                      }
                  }
              }); */
    }

    closepwd() {
      this.showPWD = false;
      this.password_form_data.oldPassword = "";
      this.password_form_data.pass1 = "";
      this.password_form_data.pass2 = "";
    }

    //PASSWORD FUNCTIONS
    public updatePassword() {
      let self = this;
      this.password_form_data.password = CryptoJS.SHA256(
        this.password_form_data.pass1
      ).toString();
      this.password_form_data.oldPassword = CryptoJS.SHA256(
        this.password_form_data.oldPassword
      ).toString();
      this.password_form_data.pass1 = undefined;
      this.password_form_data.pass2 = undefined;
      if (this.password_form_data.accesLevel == 0) {
        self.userService
          .updateUserPassword(
            self.sessionService.session.sessionID,
            self.password_form_data
          )
          .then(function (data) {
            self.sessionService.session.member.password =
              self.password_form_data.password;
            self.notification(
              self.$translate.instant("NOTIFICATIONS.ACCOUNT.changePassSuccess")
            );
            console.error("mdp modifié : " + data);
          })
          .catch(function (error) {
            self.notification.error(
              self.$translate.instant("NOTIFICATIONS.ACCOUNT.changePassError")
            );
            console.error("erreur lors de l'update du membre ");
          });
      } else {
        // do nothing, it means it's an accounter and we have no jwt to send
      }
      this.showPWD = false;
    }

    public checkPassInputs() {
      if (
        this.password_form_data.oldPassword === "" ||
        this.password_form_data.pass1 === "" ||
        this.password_form_data.pass2 === "" ||
        this.password_form_data.pass1 != this.password_form_data.pass2
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
}
angular
  .module("app")
  .controller(
    "IPPFormAccountController",
    app.functionality.IPPForm.IPPFormAccountController
  );
