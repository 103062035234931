/// <reference path="../../_app.ts" />

namespace app.functionality.IPPForm {
  import UploadService = app.functionality.upload.UploadService;
  import ConstantEnv = app.config.constants.environment.ConstantEnv;
  import SessionService = app.functionality.common.session.SessionService;

  export class UploadIPPModalController implements angular.IController {
    static $inject = [
      "$uibModalInstance",
      "r_data",
      "$http",
      "UploadService",
      "$rootScope",
      "ENV",
      "IPPFormService",
      "Notification",
      "$translate",
      "SessionService",
      "$sanitize",
      "$translatePartialLoader",
      "LoggerService",
    ];
    public valueNow: any;
    public valueMax: any;
    public percent: any;
    public totalFiles: number;
    public countFile: number;
    public mapFiles: any;
    private fileInput;
    private fieldKey;
    private model;
    private mapUploadError: any;

    constructor(
      public $uibModalInstance: any,
      r_data: any,
      protected $http: any,
      private uploadService: UploadService,
      private $rootScope: ng.IRootScopeService,
      private ENV: ConstantEnv,
      private iPPService: IPPFormService,
      private notification: any,
      private $translate: ngt.ITranslateService,
      private sessionService: SessionService,
      private $sanitize: ng.sanitize.ISanitizeService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private logger: app.functionality.common.logger.LoggerService
    ) {
      $translatePartialLoader.addPart("customer/modals");
      $translatePartialLoader.addPart("customer/upload"); // TODO : deplace error message in common

      this.fileInput = r_data.fileInput;
      this.fieldKey = r_data.fieldKey;
      this.model = r_data.model;
      this.valueMax = this.uploadService.valueMax;
      this.valueNow = this.uploadService.valueNow;
      this.percent = this.uploadService.percent;
      this.countFile = this.uploadService.countFile;
      this.mapUploadError = this.uploadService.mapUploadError;

      let arrayFiles: Array<File> = this.fileInput.files;
      this.totalFiles = arrayFiles.length;
      this.mapFiles = this.uploadService.mapFiles;
      let file: File = null;
      this.uploadService.totalFiles = this.totalFiles;
      for (file of arrayFiles) {
        this.mapFiles[file.name] = 0;
        this.valueMax = this.valueMax + file.size;
        this.sendFile(file);
      }
    } //END CONSTRUCTOR

    $onInit() {}

    /**
     * Create the form for the server and send to the server
     * Each events are handle in this method
     * @param file
     */
    private sendFile(file: File) {
      let self = this;
      let form: any = new FormData();
      form.append(
        "filenameURI",
        this.$sanitize(
          encodeURI(this.ENV.removeSpecialCharFromFilename(file.name))
        )
      );
      form.append("memberKey", this.sessionService.session.member.key);
      form.append("file", file);
      form.append("sessionID", this.sessionService.session.sessionID);
      form.append("typeUpload", "2");
      self.uploadService
        .uploadFile(form, file, [])
        .then(function (data) {
          let returnData = data.data;
          self.completeHandler(returnData);
          self.countFile--;
        })
        .catch(function (error) {
          let errorCode = self.uploadService.printError(file);
          self.uploadService.errorHandler(file);
          self.countFile--;
          if (errorCode != null) {
            self.notification.primary({
              title: self.$translate.instant(
                "IPP_FORM.NOTIFICATIONS.uploadFormatErrorTitle"
              ),
              message: self.$translate.instant(errorCode) + " : " + file.name,
              templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
              delay: false,
            });
            self.logger.customError(
              "IPP FORM UPLOAD SERVICE",
              "error in upload format",
              self.sessionService.session.member
            );
            self.$uibModalInstance.close();
          } else {
            self.notification.primary({
              title: self.$translate.instant(
                "IPP_FORM.NOTIFICATIONS.uploadFormatErrorTitle"
              ),
              message: self.$translate.instant(
                "IPP_FORM.NOTIFICATIONS.uploadFormatErrorMessage"
              ),
              templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
              delay: false,
            });
            self.logger.customError(
              "IPP FORM UPLOAD SERVICE",
              "error in upload format",
              self.sessionService.session.member
            );
            // self.notification.error({ message: self.$translate.instant("IPP_FORM.ERROR_UPLOAD.server") + " : ", delay: false });
            self.$uibModalInstance.close();
          }
        });
    }

    private completeHandler(response) {
      let self = this;
      let oParser = new DOMParser();
      let xmlDocument: Document = oParser.parseFromString(response, "text/xml");
      let mongoFileKey = xmlDocument.getElementsByTagName("FileKey")[0]
        .childNodes[0].nodeValue;
      let fileName = xmlDocument.getElementsByTagName("FileName")[0]
        .childNodes[0].nodeValue;

      this.iPPService.completeUploadIPP(
        this.fieldKey,
        mongoFileKey,
        fileName,
        this.model
      );
      this.uploadService.displayResultUpload().then(function (boolResponse) {
        if (boolResponse === true) {
          self.$uibModalInstance.close();
          self.uploadService.percent = 0;
          self.uploadService.countFile = 0;
        }
      });
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "UploadIPPModalController",
    app.functionality.IPPForm.UploadIPPModalController
  );
