/// <reference path="../../_app.ts" />

module app.functionality.budgetInsight {

    import AccountingEntry = app.model.AccountingEntry;
    import ModalUtil = app.functionality.common.modals.ModalUtil;
    import LoggerService = app.functionality.common.logger.LoggerService;
    import ENV = app.config.constants.environment.ConstantEnv;

    export class BudgetInsightController {

        public accountSelected: app.model.budgetInsight.BIAccount;
        public showMultiResults: boolean = true;
        public totalAmount: number = 0;
        public resultsAccounts: any;
        public gridOptions: any;
        public results = false;
        public grid = false;
        public bankList: any;

        static $inject = [
            "$stateParams",
            "BudgetInsightService",
            "SessionService",
            "$window",
            "$uibModal",
            "ENV",
            "ModalUtil",
            "$translatePartialLoader",
            "AccountTools",
            "ModalService",
            "LoggerService",
            "r_accounts",
            "ENV"
        ];

        constructor(public $stateParams: ng.ui.IStateParamsService,
                    public budgetInsightService: app.functionality.budgetInsight.BudgetInsightService,
                    public sessionService : app.functionality.common.session.SessionService,
                    public $window: angular.IWindowService,
                    public  $uibModal: any,
                    private ENV: app.config.constants.environment.ConstantEnv,
                    private modalUtil: ModalUtil,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService,
                    private accountTools: app.functionality.common.utils.AccountTools,
                    private modalService: app.functionality.common.modals.ModalService,
                    private logger: LoggerService,
                    public r_accounts: any
        ) {
            $translatePartialLoader.addPart("customer/budgetInsight");

            //TODO :
            let allAcc = r_accounts.data;
            this.bankList = [];
            let accountSorted = {};
            for (let account of allAcc) {
                if(this.bankList.indexOf(account.bankName) > -1) {
                    accountSorted[account.bankName].push(account);
                } else {
                    this.bankList.push(account.bankName);
                    accountSorted[account.bankName] = [account];
                }
            }
            this.resultsAccounts = accountSorted;

            //this.accountSorted = r_accounts.data;
            this.gridOptions = this.getGridData(null);
            this.totalAmount = 0;
        }

        public selectAccount(account) {
            this.getGridData(null);
            this.accountSelected = account;
            this.totalAmount = account.amount;
            this.getTransactions();
        }

        public getTransactions() {
            let self = this;
            self.budgetInsightService.getTransactionsForAccount(self.sessionService.session.sessionID, self.accountSelected.companyKey,self.accountSelected.key)
                .then(function(data) {
                    self.gridOptions = self.getGridData(data.plain().data);
                })
                .catch(function(error) {
                    self.logger.error("budgetInsightController.ts", error, self.sessionService.session.member);
                });
        }

        public openBIWindow() {
            if(this.sessionService.session.company.budgetInsightCredential == null || this.sessionService.session.company.budgetInsightCredential == undefined ) {
                let url = this.ENV.baseUrlBI+"/auth/webview/connect?client_id="+this.ENV.IDBI+"&redirect_uri="+this.ENV.returnURLBI+"&response_type=code&state=&types=banks";
                window.open(url, "_blank");
            } else {
                let self = this;
                this.sessionService
                    .getBIToken(this.sessionService.session.sessionID)
                    .then(function (data) {
                        let url = self.ENV.baseUrlBI+"/auth/webview/fr/manage?client_id="+self.ENV.IDBI+"&redirect_uri="+self.ENV.returnURLBI+"&state=&code="+encodeURIComponent(data.data);
                        window.open(url, "_blank");
                        return;
                    })
                    .catch(function (error) {
                        // console.error("NewTicketModalController.ts", error,"");
                        console.error(error);
                    });
            }
        }

        public checkAccounts() {
            let self = this;
            self.budgetInsightService.updateData(self.sessionService.session.sessionID, self.sessionService.session.company.key)
                .then(function(data) {
                    //boutonUpdateData.enabled = false;
                })
                .catch(function(error) {
                    self.logger.error("budgetInsightController.ts", error, self.sessionService.session.member);
                });
        }

        // ******************** GRID CONFIGURATION ********************

        public getGridData(entries) {
            return {
                data: entries,
                enableFiltering: false,
                enableColumnMenus: false,
                enableSorting: false,
                rowHeight: 40,
                columnFooterHeight: 40,
                columnDefs: [
                    {
                        displayName: "BUDGETINSIGHT.TABLE.description",
                        headerCellFilter: "translate",
                        headerCellClass: "header-title",
                        field: "description",
                        cellFilter: "vcsStruct",
                        cellTemplate: "tpl/grid/budgetInsight/descriptionCell.html"
                    },
                    {
                        displayName: "BUDGETINSIGHT.TABLE.date",
                        headerCellFilter: "translate",
                        headerCellClass: "header-title",
                        field: "transactionTime",
                        width: "17%",
                        cellFilter: "date:'dd/MM/yyyy'"
                    },
                    {
                        displayName: "BUDGETINSIGHT.TABLE.amount",
                        headerCellFilter: "translate",
                        headerCellClass: "header-title text-right",
                        headerCellTemplate: "<div role=\"columnheader\" ng-class=\"{ \'sortable\': sortable }\" " +
                        "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + \'-header-text \' + col.uid + \'-sortdir-text\'\" " +
                        "aria-sort=\"{{col.sort.direction == asc ? \'ascending\' : ( col.sort.direction == desc ? \'descending\' : (!col.sort.direction ? \'none\' : \'other\'))}}\">" +
                        "<div role=\"button\" tabindex=\"0\" class=\"ui-grid-cell-contents ui-grid-header-cell-primary-focus\" col-index=\"renderIndex\" title=\"TOOLTIP\">" +
                        "<span class=\"ui-grid-header-cell-label\" ui-grid-one-bind-id-grid=\"col.uid + \'-header-text\'\">{{ col.displayName CUSTOM_FILTERS }}</span>" +
                        "</div><div ui-grid-filter></div></div>",
                        width: "15%",
                        cellFilter: "number:2",
                        cellTemplate: "tpl/grid/budgetInsight/amount.html",
                        field: "amount"
                    }
                ]
            };

        }

    }

}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("BudgetInsightController", app.functionality.budgetInsight.BudgetInsightController);