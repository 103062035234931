/// <reference path="../../_app.ts" />

module app.model.invoicing {

    export class InvoiceLine {

        description: string;
        private _quantity: number = 1;
        get quantity() { return this._quantity; }
        set quantity(newValue: number) { 
            this._quantity = newValue;
            this.updateTotal();
        }
        private _price: number;
        get price() { return this._price; }
        set price(newValue: number) {
            this._price = newValue;
            this.updateTotal();
        }
        private _vatRate: number;
        get vatRate() { return this._vatRate; }
        set vatRate(newValue: number) {
            this._vatRate = newValue;
            this.updateTotal();
        }
        vatRateName: string;
        total: number;
        vatTotal: number;

        constructor (description: string = "", quantity: number = 1, price: number = 1, vatRate: number = 0) {
            this.description = description;
            this.quantity = quantity;
            this.price = price;
            this.vatRate = vatRate;
        }

        /**
         * Updates the totals of the line
         */
        updateTotal() {
            this.total =  Math.round((((this.quantity * this.price)))* 100) / 100; ;
            this.vatTotal = Math.round((1 + (this.vatRate / 100)) * this.total * 100) / 100;
        }
    }
    
    export class Invoice {

        key: string;
        memberKey: string;
        companyKey: string;

        status: number;
        type: "invoice" | "creditNote";
        language: "fr" | "nl" | "en" | "de";
        filename: string;
        invoiceNumber: number;
        description: string;
        structuredCommunication: string;
        clientKey: string;
        invoiceDate: Date;
        dueDate: Date;
        footerText: string;
        invoiceKey: string;
        line: InvoiceLine[];

        exclVatTotal: number;
        vatTotals: Object;
        inclVatTotal: number;

        constructor (
            status: number = 1, 
            type: "invoice" | "creditNote" = "invoice",
            filename: string = "[type] [invoiceNumber] - [clientName]",
            number: number = 0, 
            description: string = "",
            structuredCommunication?: string,
            clientKey: string = "",
            invoiceDate: Date = new Date(),
            dueDate?: Date,
            footerText: string = "",
            line: InvoiceLine[] = []
        ) {
            this.status = status;
            this.type = type;
            this.filename = filename;
            this.invoiceNumber = number;
            this.description = description;
            this.structuredCommunication = structuredCommunication;
            this.clientKey = clientKey;
            this.invoiceDate = invoiceDate;
            this.dueDate = dueDate;
            this.footerText = footerText;
            this.line = line;

            if (line != []) {
                this.updateTotals();
            }
        }

        /**
         * Adds a new line to the current Invoice object
         * @param idx The index of the new line
         */
        addNewLine(idx: number): void {
            this.line[idx] = new InvoiceLine();
            this.updateTotals();
        }

        /**
         * Removes a line from the current Invoice object
         * @param idx The index of the line to remove
         */
        removeLine(idx: number): void {
            this.line.splice(idx, 1);
        }

        /**
         * Updates the totals of the invoice (but not the totals of each line !)
         */
        updateTotals() {
            this.exclVatTotal = this.inclVatTotal = 0;
            this.vatTotals = {};
            
            this.line.forEach(aLine => {
                this.exclVatTotal += aLine.total;
                if (aLine.vatRate > 0) {
                    this.vatTotals[aLine.vatRate] = (this.vatTotals[aLine.vatRate] == undefined) ? 0 : this.vatTotals[aLine.vatRate];
                    this.vatTotals[aLine.vatRate] = (parseFloat(this.vatTotals[aLine.vatRate]) + Math.round((aLine.vatTotal - aLine.total) * 100) / 100).toFixed(2);
                }
                this.inclVatTotal += aLine.vatTotal;
            });
        }

        computeSC(): void {
            this.structuredCommunication = "";
            let maxInvoiceNumberlength = 8;
            
            if (this.invoiceNumber.toString().length <= 8) {
                this.structuredCommunication += (new Date()).getFullYear().toString().substring(2, 4);
            }
            else {
                maxInvoiceNumberlength = 10;
            }
                
            for (let i = 0 ; i < (maxInvoiceNumberlength - this.invoiceNumber.toString().length) ; i++) {
                this.structuredCommunication += "0";
            }

            this.structuredCommunication += this.invoiceNumber.toString();

            let control = parseInt(this.structuredCommunication) % 97;
            control = (control == 0) ? 97 : control;
            this.structuredCommunication += (control < 10) ? "0" + control.toString() : control.toString();
        }

        /**
         * Returns a string corresponding to the formatted structured communication (--- xxx / xxxx / xxxxx ---)
         */
        formatSC(): string {
            if (this.structuredCommunication != undefined) {
                return "+++ " + this.structuredCommunication.substring(0, 3) + " / " + this.structuredCommunication.substring(3, 7) + " / " + this.structuredCommunication.substring(7, 12) + " +++";
            }
        }
    }
}
