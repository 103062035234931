/// <reference path="../../../_app.ts"/>

module app.functionality.invoicing.services {

    export class InvoicingEmailService {

        private rest: restangular.IService;

        static $inject = [
            "RestService",
            "RESTAPI",
            "$sanitize"
        ];

        constructor (
            restService: app.functionality.common.rest.RestService,
            private RESTAPI: app.config.constants.ConstantRestApi,
            private $sanitize: ng.sanitize.ISanitizeService
        ) {
            this.rest = restService.getRoot();
        }

        /**
         * Sends an invoice by mail
         * @param invoiceKey The key of the invoice to send
         * @param fields The mailing data (sending name, sending email, mail subject, ...)
         */
        sendInvoice = (sessionID: string, invoiceKey: string, fields: Object): ng.IPromise<any> => {
            let toSend = new Object();
            angular.copy(fields, toSend);

            ["sendingEmail", "sendingName", "emailAddress", "mailSubject", "mailBody"].forEach(property => {
                toSend[property] = this.enhancedEncoding(toSend[property]);
            });

            return this.rest.all(this.RESTAPI.services.invoicing.email)
                .all(this.RESTAPI.services.invoicing.sendInvoice)
                .one(sessionID + "&" + invoiceKey)
                .customPOST(toSend);
        }

        sendTestMail = (sessionID: string, fields: Object): ng.IPromise<any> => {
            let toSend = new Object();
            angular.copy(fields, toSend);

            ["sendingEmail", "sendingName", "emailAddress", "mailSubject", "mailBody"].forEach(property => {
                toSend[property] = this.enhancedEncoding(toSend[property]);
            });

            return this.rest.all(this.RESTAPI.services.invoicing.email)
                .all(this.RESTAPI.services.invoicing.sendTestMail)
                .one(sessionID)
                .customPOST(toSend);
        }

        /**
         * Makes a string safe to send to the back-end by encoding it
         * @param strToEncode The string which has to be encoded
         */
        enhancedEncoding(strToEncode: string): string {
            strToEncode = this.$sanitize(encodeURI(strToEncode));
            strToEncode = strToEncode.replace(/\&amp;/gi, "%26");
            strToEncode = strToEncode.replace(/\&/gi, "%26");
            strToEncode = strToEncode.replace(/\+/gi, "%2B");
            return strToEncode;
        }
    }
}
angular.module("app").service("InvoicingEmailService", app.functionality.invoicing.services.InvoicingEmailService);