/// <reference path="../../_app.ts" />

module app.model.invoiceReminder {

    import IThird = app.model.interface.IThird;
    import ILostPaiement = app.model.interface.ILostPaiement;
    import ISale = app.model.interface.ISale;
    import IOperation = app.model.interface.IOperation;

    export class ThirdWithOperations {

        public third: IThird;
        public sales: ISale[];
        public lostPaiements: ILostPaiement[];
        public allSalesChecked: boolean;
        public allPaymentsChecked: boolean;
        public nbSelectedSale: number;
        public nbSelectedPayment: number;
        public noSelectedSale: boolean;
        public noSelectedPayment: boolean;
        public dueAmountSum: number;
        public isSelected: boolean;

        constructor(third: IThird) {
            this.third = third;
            this.sales = [];
            this.lostPaiements = [];
            this.allSalesChecked = false;
            this.allPaymentsChecked = false;
            this.nbSelectedSale = 0;
            this.nbSelectedPayment = 0;
            this.noSelectedSale = true;
            this.noSelectedPayment = true;
            this.dueAmountSum = 0;
        }

        /**
         * This function is used to get the total amount due by a third (all the sales which are due - all the payments)
         * @param {Date} date The date used to determine if the sale is due or not
         * @returns {number} the total amount of a third
         */
        public getMaxDueAmount(date: Date): number {
            let self = this;
            let amount = 0;
            this.sales.forEach(function(value) {
                if(self.isSaleDue(value, date)) {
                    amount += value.amount;
                }
            });
            this.lostPaiements.forEach(function(value) {
                amount += value.amount;
            });
            return amount;
        }

        /**
         * This function is used to get the amount which is not due yet
         * @param {Date} date The date used to determine if the sale is due or not
         * @returns {number} the amount which is not due yet
         */
        public getAmountOnGoing(date: Date): number {
            let self = this;
            let amount = 0;
            this.sales.forEach(function(value) {
                if(! self.isSaleDue(value, date)) {
                    amount += value.amount;
                }
            });
            return amount;
        }

        /**
         * This function is used to get the max number of days late for all the sales of a third
         * @param {Date} date The date used to determine if the sale is due or not
         * @returns {number} the max number of days late for all the sales of a third
         */
        public getMaxDaysLate(date: Date): number {
            let self = this;
            let maxDaysLate = 0;
            this.sales.forEach(function(value) {
                let daysLateSale = self.getDaysLate(value, date);
                if(daysLateSale > maxDaysLate) {
                    maxDaysLate = daysLateSale;
                }
            });
            return maxDaysLate;
        }

        /**
         * This function is used to get the max number of reminder for all the sales of a third
         * @returns {number} the max number of reminder for all the sales of a third
         */
        public getMaxReminder(): number {
            let maxReminder = 0;
            this.sales.forEach(function(value) {
                if(value.reminderNumber > maxReminder) {
                    maxReminder = value.reminderNumber;
                }
            });
            return maxReminder;
        }

        /**
         * This function is used to get the number of days late for an operation
         * @param {app.model.interface.IOperation} op the concerned operation
         * @param {Date} date The date used to determine if the sale is due or not
         * @returns {number} the number of days late for the operation based of today's date
         */
        public getDaysLate(op: IOperation, date: Date): number {
            return Math.floor(Math.abs(new Date(op.dueDate).getTime() - date.getTime()) / (1000 * 3600 * 24));
        }

        /**
         * This function is used to know if a third has a sale which is expired
         * @param {Date} date The date used to determine if the sale is due or not
         * @returns {boolean} true if a third has a sale which is expired
         */
        public hasDueSale(date: Date): boolean {
            for (let i = 0; i < this.sales.length; i++) {
                if (this.isSaleDue(this.sales[i], date)) {
                    return true;
                }
            }
            return false;
        }

        /**
         * This function is used to check if a sale is expired
         * @param {app.model.interface.ISale} sale the concerned saled
         * @param {Date} date The date used to determine if the sale is due or not
         * @returns {boolean} true if the dueDate of the sale is expired
         */
        public isSaleDue(sale: ISale, date: Date): boolean {
            return new Date(sale.dueDate) < date;
        }

        /**
         * This function is used to check all the sales of the third (in order to include them in a reminder)
         * @param selected : True if the sale should be selected
         */
        public checkAllSales(selected: boolean) {
            let self = this;
            if (selected) {
                this.nbSelectedSale = 0;
                this.dueAmountSum = 0;
            } else {
                this.nbSelectedSale = this.sales.length;
                this.dueAmountSum = 0;
                this.sales.forEach(function (value) {
                    self.dueAmountSum = self.dueAmountSum + value.amount;
                });
            }
            this.sales.forEach(function (value) {
                value.isSelected = selected;
                self.checkSale(value);
            });
        }

        /**
         * This function is used to check a sale in order to send a reminder
         * @param {app.model.invoiceReminder.ThirdWithOperations} third the third the user wants to send a reminder to
         */
        public checkSale(sale: ISale) {
            if (sale.isSelected) {
                this.nbSelectedSale = this.nbSelectedSale+ 1;
                this.dueAmountSum = this.dueAmountSum + sale.amount;
            } else {
                this.nbSelectedSale = this.nbSelectedSale - 1;
                this.dueAmountSum = this.dueAmountSum - sale.amount;
            }
            this.noSelectedSale = this.nbSelectedSale === 0;
            this.allSalesChecked = this.nbSelectedSale === this.sales.length;
        }

        /**
         * This function is used to check all the payments of the third (in order to include them in a reminder)
         * @param selected : True if the payment should be selected
         */
        public checkAllPayments(selected: boolean) {
            let self = this;
            if (selected) {
                this.nbSelectedPayment = 0;
            } else {
                this.nbSelectedPayment = this.lostPaiements.length;
            }
            this.lostPaiements.forEach(function (value) {
                value.isSelected = selected;
                self.checkPayment(value);
            });
        }

        /**
         * This function is used to check a payment in order to send a reminder
         * @param {app.model.invoiceReminder.ThirdWithOperations} third the third the user wants to send a reminder to
         */
        public checkPayment(payment: ILostPaiement) {
            if (payment.isSelected) {
                this.nbSelectedPayment = this.nbSelectedPayment+ 1;
                this.dueAmountSum = this.dueAmountSum + payment.amount;
            } else {
                this.nbSelectedPayment = this.nbSelectedPayment - 1;
                this.dueAmountSum = this.dueAmountSum - payment.amount;
            }
            this.noSelectedPayment = this.nbSelectedPayment === 0;
            this.allPaymentsChecked = this.nbSelectedPayment === this.lostPaiements.length;
        }

    }
}