/// <reference path="../../_app.ts" />

module app.model.interface {

    /**
     * This interface is used to store the data returned by the database
     *
     */
    export interface IReminder {

        key: string;
        date: Date;
        companyKey: string;
        sales: ISale[];
        payments: ILostPaiement[];
        // reference on the Third
        reference: string;
        template: ITemplateReminder;
        contact: IContact;

        //level: number;

        addSale(sale: ISale);

        addPayment(payment: ILostPaiement);

        getAmountLeft();

        isEmpty();

        //setLevel();

    }
}