/// <reference path="../../_app.ts" />

module app.functionality.profit {
  import Exercice = app.model.Exercice;
  import Period = app.model.Period;
  import ModalUtil = app.functionality.common.modals.ModalUtil;

  export class BalanceController {
    public balanceData: any;
    public passiveData: any;
    public activeData: any;

    public balanceTotal = 0;

    public exerciceSelected: Exercice;

    public pdfExportModal: any;
    public csvExportModal: any;
    public exerciceArr: Array<Exercice>;
    public periodArr: Array<app.model.Period>;
    public periodSelected: Period;

    public activeMode = true;
    public passiveMode = false;

    static $inject = [
      "ProfitService",
      "$uibModal",
      "$scope",
      "ModalUtil",
      "SessionService",
      "r_balance",
      "ProfitConverter",
      "$translatePartialLoader",
    ];

    constructor(
      public profitService: ProfitService,
      public $uibModal: any,
      public $scope: ng.IScope,
      public modalUtil: ModalUtil,
      public sessionService: app.functionality.common.session.SessionService,
      public r_balance,
      private profitConverter: app.functionality.common.converter.ProfitConverter,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService
    ) {
      $translatePartialLoader.addPart("customer/profit");
      this.activeData = this.profitConverter.convertBalance(
        this.r_balance.data,
        0
      );
      this.passiveData = this.profitConverter.convertBalance(
        this.r_balance.data,
        1
      );
      this.balanceData = this.setGridOptions(
        this.activeData,
        $scope,
        "PROFIT.BALANCE.activeTitle"
      );
      this.initFilters();
    }

    public pdfExport() {
      let self = this;

      if (this.sessionService.session.company.importInfo.state !== 0) {
        this.pdfExportModal = this.$uibModal.open({
          templateUrl: "tpl/website/modals/pdfModal.html",
          controller: "PdfModalController",
          controllerAs: "pdf",
          size: "sm",
          resolve: {
            r_fromTo: function () {
              return {
                sessionID: self.sessionService.session.sessionID,
                bookyear: self.exerciceSelected.bookyear,
                toPeriod: self.periodSelected.periodIndex,
                language: self.sessionService.session.member.language,
              };
            },
          },
        });
      }
    }

    public initFilters() {
      //to init the selections available on the two filters
      if (this.sessionService.session.company.importInfo.state !== 0) {
        this.exerciceArr = this.sessionService.session.company.exerciceArr;
        this.exerciceSelected = this.exerciceArr[this.exerciceArr.length - 1];
        this.periodArr = this.getPeriodsArr(this.exerciceSelected);
        this.periodSelected = this.periodArr[this.periodArr.length - 1];
      }
    }

    public filterByBookyear() {
      let self = this;
      this.periodArr = this.getPeriodsArr(this.exerciceSelected);
      this.periodSelected = this.periodArr[this.periodArr.length - 1];

      this.profitService
        .getBalance(
          this.sessionService.session.sessionID,
          this.exerciceSelected.bookyear
        )
        .then(function (data) {
          let newData = data.plain();
          if (self.activeMode) {
            self.activeData = self.profitConverter.convertBalance(
              newData.data,
              0
            );
            self.passiveData = self.profitConverter.convertBalance(
              newData.data,
              1
            );
            self.balanceData = self.setGridOptions(
              self.activeData,
              self.$scope,
              "PROFIT.BALANCE.activeTitle"
            );
          } else if (self.passiveMode) {
            self.activeData = self.profitConverter.convertBalance(
              newData.data,
              0
            );
            self.passiveData = self.profitConverter.convertBalance(
              newData.data,
              1
            );
            self.balanceData = self.setGridOptions(
              self.passiveData,
              self.$scope,
              "PROFIT.BALANCE.passiveTitle"
            );
          }
        })
        .catch(function (error) {
          console.error("error ocurred during getBalance in filterByPeriod");
          console.error(error);
        });
    }

    private updateGridSum(data) {
      let sum = 0;
      _.forEach(data, function (p) {
        if (p.$$treeLevel === 0) {
          sum = sum + p.solde;
          // console.error(sum);
        }
      });
      return sum;
    }

    public filterByPeriod() {
      let self = this;

      let periodArr = [0];
      for (let i = 0; i < this.periodSelected.periodIndex; i++) {
        periodArr.push(i + 1);
      }

      this.profitService
        .getBalanceByPeriod(
          this.sessionService.session.sessionID,
          this.exerciceSelected.bookyear,
          periodArr
        )
        .then(function (data) {
          let newData = data.plain();
          if (self.activeMode) {
            self.activeData = self.profitConverter.convertBalance(
              newData.data,
              0
            );
            self.passiveData = self.profitConverter.convertBalance(
              newData.data,
              1
            );
            self.balanceData = self.setGridOptions(
              self.activeData,
              self.$scope,
              "PROFIT.BALANCE.activeTitle"
            );
          } else if (self.passiveMode) {
            self.activeData = self.profitConverter.convertBalance(
              newData.data,
              0
            );
            self.passiveData = self.profitConverter.convertBalance(
              newData.data,
              1
            );
            self.balanceData = self.setGridOptions(
              self.passiveData,
              self.$scope,
              "PROFIT.BALANCE.passiveTitle"
            );
          }
        })
        .catch(function (error) {
          console.error("error ocurred during getBalance in filterByPeriod");
          console.error(error);
        });
    }

    private getPeriodsArr(ex: Exercice): any {
      let copy = [];
      let y = 0;
      if (ex.isQuarterlyMode) {
        _.forEach(ex.quarterlyArr, function (q) {
          // copy.push(q);
          let pArr = q.periodArr;
          let p1 = pArr[pArr.length - 1];
          y = p1.year;
          copy.push(p1);
        });

        let p2 = new app.model.Period();
        p2.monthIndex = -1;
        p2.periodIndex = 99;
        p2.year = y;
        copy.push(p2);
      } else {
        _.forEach(ex.periodArr, function (p3) {
          y = p3.year;
          copy.push(p3);
        });
        let p4 = new app.model.Period();
        p4.monthIndex = -1;
        p4.periodIndex = 99;
        p4.year = y;
        copy.push(p4);
      }

      return copy;
    }

    public setGridOptions(data, $scope, title) {
      this.balanceTotal = this.updateGridSum(data);

      return {
        columnDefs: [
          {
            field: "label",
            displayName: title,
            headerCellFilter: "translate",
            headerCellClass: "balance-grid-header-title",
            cellFilter: "translate",
            enableHiding: true,
            cellTemplate: "tpl/grid/balance/keyTemplate.html",
            footerCellTemplate:
              "<div class='ui-grid-cell-contents balance-grid-header-title'>{{'PROFIT.BALANCE.total' | translate}}</div>",
          },
          {
            field: "solde",
            displayName: "PROFIT.BALANCE.amount",
            headerCellFilter: "translate",
            headerCellClass: "balance-grid-header-title",
            cellFilter: "number:2",
            enableHiding: true,
            width: "20%",
            cellTemplate: "tpl/grid/balance/valueTemplate.html",
            footerCellTemplate:
              "<div class='ui-grid-cell-contents balance-grid-header-title'><span class='pull-right'>{{grid.appScope.balance.balanceTotal | number:2}}</span></div>",
          },
        ],
        data: data,
        showTreeExpandNoChildren: false,
        treeIndent: 1,
        showColumnFooter: true,
        enableFiltering: false,
        enableColumnMenus: false,
        enableSorting: false,
        virtualizationThreshold: data.length,
        flatEntityAccess: false,
        rowHeight: 40,
        columnFooterHeight: 40,
        showTreeRowHeader: false,
        onRegisterApi: (gridApi) => {
          $scope.gridApi = gridApi;
        },
      };
    }

    public getFooterAggregationFunction = (
      totalizerBottomRowData
    ): Function => {
      return (visibleRows, row) => {
        return totalizerBottomRowData[row.field];
      };
    };

    // to launch modal window for having more detail about a category cell
    public detail(col, row) {
      let self = this;

      // we use JSON.stringify to output the array with the brackets, into a string. necessary for backend call
      let accounts = angular.isDefined(row.entity.accountArr)
        ? JSON.stringify(row.entity.accountArr)
        : JSON.stringify(row.entity.accNum);

      // we send the sum amount, to check the correctness of the value on backend
      let totalAccounts = 0;
      if (angular.isDefined(row.entity.solde)) {
        totalAccounts = row.entity.solde;
      }

      let lastIndex: number;
      if (this.periodSelected.periodIndex === 99) {
        lastIndex = 99;
      } else {
        lastIndex = this.periodSelected.periodIndex;
      }

      let periodArr = [0];
      for (let i = 0; i < lastIndex; i++) {
        periodArr.push(i + 1);
      }

      if (this.periodSelected.periodIndex === 99) {
        periodArr.push(99);
      }

      this.profitService
        .getDetailAccount(
          this.sessionService.session.sessionID,
          this.sessionService.session.company.key,
          accounts,
          this.exerciceSelected.bookyear,
          JSON.stringify(periodArr),
          totalAccounts
        )
        .then(function (response) {
          if (
            angular.isDefined(response.data[0]) &&
            response.data[0].length === 0
          ) {
            self.modalUtil.status(
              "MODAL.COMPARISON_NO_DATA.message",
              "MODAL.COMPARISON_NO_DATA.title"
            );
          } else {
            let state = 0;
            if (self.passiveMode) {
              state = 1;
            }
            // console.error(state);
            self.modalUtil.detail(response.data, row, col, state);
          }
        })
        .catch(function (error) {
          console.error("error on detail modal creation", error);
        });
    }

    public message(row) {
      this.modalUtil.message(row);
    }

    public setActiveData() {
      this.balanceData = this.setGridOptions(
        this.activeData,
        this.$scope,
        "PROFIT.BALANCE.activeTitle"
      );
      this.passiveMode = false;
      this.activeMode = true;
    }

    public setPassiveData() {
      this.balanceData = this.setGridOptions(
        this.passiveData,
        this.$scope,
        "PROFIT.BALANCE.passiveTitle"
      );
      this.passiveMode = true;
      this.activeMode = false;
    }

    /*
     * To launch csv generator modal
     * */
    public csvExport() {
      let self = this;
      if (this.sessionService.session.company.importInfo.state !== 0) {
        this.csvExportModal = this.$uibModal.open({
          templateUrl: "tpl/website/modals/csvModal.html",
          controller: "CsvModalController",
          controllerAs: "csv",
          size: "sm",
          resolve: {
            r_fromTo: function () {
              return {
                sessionID: self.sessionService.session.sessionID,
                bookyear: self.exerciceSelected.bookyear,
                toPeriod: self.periodSelected.periodIndex,
                language: self.sessionService.session.member.language,
              };
            },
          },
        });
      }
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller("BalanceController", app.functionality.profit.BalanceController);
