/// <reference path="../../../_app.ts" />

namespace app.functionality.common.i18n {
  import Third = app.model.Third;
  import SocketService = app.config.socket.SocketService;
  import ModalUtil = app.functionality.common.modals.ModalUtil;
  import ENV = app.config.constants.environment.ConstantEnv;

  export class HeaderController implements angular.IController {
    $onInit() {}

    public languages = ["NL", "FR", "EN"];
    public languageSelected = "FR";
    public thirdsList;
    public companyName: string;
    public updateDate: Date;
    public features: string;
    public tabFeatures: any;
    public multiCompany: Boolean;
    public showIPP: Boolean;
    public showBI: Boolean = false;
    public headerURL: String = "assets/img/logos/skwarel-header.svg";
    public showAccount: Boolean = true;
    public showSearch: Boolean = false;
    public selectedSearch = "";
    private importAcknowledged: boolean = false;

    private showAccountantName: boolean = false;
    private accountantName: string = "John Doe";
    private accountantRole: string = "Votre comptable";

    static $inject = [
      "$uibModal",
      "$translate",
      "$scope",
      "$state",
      "I18NCONSTANTS",
      "r_clients",
      "r_suppliers",
      "r_branding",
      "SessionService",
      "SearchService",
      "ModalService",
      "$window",
      "SocketService",
      "$translatePartialLoader",
      "FEATURES",
      "ModalUtil",
      "tmhDynamicLocale",
      "ENV",
    ];

    constructor(
      public $uibModal: any,
      private $translate: ngt.ITranslateService,
      public $scope: ng.IScope,
      public $state: ng.ui.IStateService,
      i18NCONSTANTS: app.config.constants.I18NConstants,
      r_clients: Array<Third>,
      r_suppliers: Array<Third>,
      r_branding: any,
      private sessionService: app.functionality.common.session.SessionService,
      public searchService: app.functionality.search.SearchService,
      private modalService: app.functionality.common.modals.ModalService,
      private $window: ng.IWindowService,
      public socketService: SocketService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private FEATURES: app.config.constants.roles.ConstantFeatures,
      private modalUtil: ModalUtil,
      private tmhDynamicLocale,
      private ENV: app.config.constants.environment.ConstantEnv
    ) {
      $translatePartialLoader.addPart("customer/header");
      $translatePartialLoader.addPart("customer/modals");
      $translatePartialLoader.addPart("customer/notifications");
      $translatePartialLoader.addPart("customer/profit");
      $translatePartialLoader.addPart("common/login");

      this.features = this.sessionService.session.member.featuresByComp[
        this.sessionService.session.company.key
      ];
      this.tabFeatures = FEATURES;

      socketService.init();

      //HIGHLIGHT ACCOUNTANT
      if (
        this.sessionService.session != null &&
        this.sessionService.session.brandingData != null
      ) {
        let subscription_plan = this.sessionService.session.subscription_plan;
        if (
          this.sessionService.session.brandingData.showAccountantName &&
          subscription_plan.plan.features["PERSO"]
        ) {
          this.showAccountantName = this.sessionService.session.brandingData.showAccountantName;
        } else {
          this.showAccountantName = false;
        }
      }
      if (
        this.sessionService.session != null &&
        this.sessionService.session.responsableCompany != null
      ) {
        this.accountantName = this.sessionService.session.responsableCompany.fullName;
      }

      if (
        this.sessionService.session != null &&
        this.sessionService.session.member != null
      ) {
        if (
          this.sessionService.session.company.country.toLowerCase() ===
            "france" &&
          this.sessionService.session.member.language.toLowerCase() === "fr"
        ) {
          this.languageSelected = "fr_fr";
          this.changeLanguage();
        }
        if (
          this.sessionService.session.member.language.toLowerCase() === "fr"
        ) {
          if (
            this.sessionService.session != null &&
            this.sessionService.session.brandingData != null &&
            this.sessionService.session.brandingData.role
          ) {
            this.accountantRole = this.sessionService.session.brandingData.role[
              "FR"
            ];
          } else {
            this.accountantRole = "Votre comptable";
          }
        } else if (
          this.sessionService.session.member.language.toLowerCase() === "nl"
        ) {
          if (
            this.sessionService.session != null &&
            this.sessionService.session.brandingData != null &&
            this.sessionService.session.brandingData.role
          ) {
            this.accountantRole = this.sessionService.session.brandingData.role[
              "NL"
            ];
          } else {
            this.accountantRole = "Uw boekhouder";
          }
        } else if (
          this.sessionService.session.member.language.toLowerCase() === "en"
        ) {
          if (
            this.sessionService.session != null &&
            this.sessionService.session.brandingData != null &&
            this.sessionService.session.brandingData.role
          ) {
            this.accountantRole = this.sessionService.session.brandingData.role[
              "EN"
            ];
          } else {
            this.accountantRole = "Your accountant";
          }
        } else {
          this.accountantRole = "Votre comptable";
        }
        //this.languageSelected = this.sessionService.session.member.language;
      } else {
        this.languageSelected = i18NCONSTANTS.i18nDefaultLanguage;
      }

      //this.changeLanguage();

      this.multiCompany = this.sessionService.session.isMultiCompany;
      this.showIPP = this.sessionService.session.member.tax_form;
      if (
        this.sessionService.session.accountingFirmBranding &&
        this.sessionService.session.accountingFirmBranding > 0 &&
        this.sessionService.session.brandingData &&
        this.sessionService.session.brandingData.iconPath
      ) {
        this.headerURL =
          "https://s3-eu-west-1.amazonaws.com/" +
          this.sessionService.session.brandingData.iconPath;
      }
      this.showAccount = this.sessionService.session.member.accesLevel === 0; // Only the member can see the account/notif menu

      let combinedArrays = r_clients.concat(r_suppliers);
      this.initThirdsList(combinedArrays);
      this.companyName = this.sessionService.session.company.name;
      if (
        this.sessionService.session.company.importInfo != null &&
        this.sessionService.session.company.importInfo.importDate != null
      ) {
        this.updateDate = this.sessionService.session.company.importInfo.importDate;
      } else {
        this.updateDate = undefined;
      }

      if (this.sessionService.session.member.showBI == true) {
        this.showBI = true;
      } else {
        this.showBI = false;
      }

      // newImport, show message asking for accountant to make the first import
      if (
        this.sessionService.session.company.importInfo.state === 0 &&
        this.sessionService.session.member.accesLevel !== 250
      ) {
        let permissions = this.sessionService.session.member.featuresByComp[
          this.sessionService.session.company.key
        ];
        if (
          permissions.indexOf("PROFIT") !== -1 ||
          permissions.indexOf("TREASURY") !== -1 ||
          permissions.indexOf("LIQUIDITY") !== -1 ||
          permissions.indexOf("DASHBOARD") !== -1 ||
          permissions.indexOf("OTHERRS") !== -1
        ) {
          this.showSearch = true;
          if (!this.importAcknowledged) {
            this.modalUtil
              .status("MODAL.NEW_IMPORT.bodyMessage", "MODAL.NEW_IMPORT.title")
              .then(() => {
                this.importAcknowledged = true;
              });
          }
        }
      }

      let permissions = this.sessionService.session.member.featuresByComp[
        this.sessionService.session.company.key
      ];
      if (
        permissions.indexOf("PROFIT") !== -1 ||
        permissions.indexOf("TREASURY") !== -1 ||
        permissions.indexOf("LIQUIDITY") !== -1 ||
        permissions.indexOf("DASHBOARD") !== -1 ||
        permissions.indexOf("OTHERRS") !== -1
      ) {
        this.showSearch = true;
      } else {
        this.showSearch = false;
      }

      let self = this;

      $scope.$on("DISCONNECTION", function (event, sessionIdOriginal: String) {
        if (self.sessionService.session.sessionID === sessionIdOriginal) {
          self.wsDisconnect();
        } else {
          self.disconnect();
        }
      });
    }

    public redirectToHome() {
      this.$state.go(
        this.sessionService.getTransitionToHome(
          this.sessionService.session.member.defaultHomePage,
          this.sessionService.session.company.key
        )
      );
    }

    public initThirdsList(r_thirds) {
      this.thirdsList = this.updateThirdListI18n(r_thirds);
    }

    /**
     * With the third's typeahead, we can't update the list with $translate
     * this function does it manually
     */
    public updateThirdListI18n(thirdList) {
      let self = this;
      _.forEach(thirdList, function (element) {
        if (element.type === 1) {
          switch (self.languageSelected.toLowerCase()) {
            case "nl":
              element.displayName =
                element.fullName.charAt(0).toUpperCase() +
                element.fullName.slice(1).toLowerCase() +
                " (Klant)";
              break;
            case "fr":
              element.displayName =
                element.fullName.charAt(0).toUpperCase() +
                element.fullName.slice(1).toLowerCase() +
                " (Client)";
              break;
            case "en":
              element.displayName =
                element.fullName.charAt(0).toUpperCase() +
                element.fullName.slice(1).toLowerCase() +
                " (Customer)";
              break;
            case "de":
              element.displayName =
                element.fullName.charAt(0).toUpperCase() +
                element.fullName.slice(1).toLowerCase() +
                " (Kunde)";
              break;
            default:
              element.displayName =
                element.fullName.charAt(0).toUpperCase() +
                element.fullName.slice(1).toLowerCase();
          }
        } else {
          switch (self.languageSelected.toLowerCase()) {
            case "nl":
              element.displayName =
                element.fullName.charAt(0).toUpperCase() +
                element.fullName.slice(1).toLowerCase() +
                " (Leverancier)";
              break;
            case "fr":
              element.displayName =
                element.fullName.charAt(0).toUpperCase() +
                element.fullName.slice(1).toLowerCase() +
                " (Fournisseur)";
              break;
            case "en":
              element.displayName =
                element.fullName.charAt(0).toUpperCase() +
                element.fullName.slice(1).toLowerCase() +
                " (Provider)";
              break;
            case "de":
              element.displayName =
                element.fullName.charAt(0).toUpperCase() +
                element.fullName.slice(1).toLowerCase() +
                " (Anbieter)";
              break;
            default:
              element.displayName =
                element.fullName.charAt(0).toUpperCase() +
                element.fullName.slice(1).toLowerCase();
          }
        }
      });
      return thirdList;
    }

    public wsDisconnect() {
      this.sessionService.authenticated = false;
      this.sessionService.session = undefined;
      this.sessionService.authenticated = false;
      this.$state.go("login");
    }

    public disconnect() {
      this.sessionService.authenticated = false;

      let self = this;
      this.sessionService
        .logOut(this.sessionService.session.sessionID)
        .then(function () {
          self.sessionService.mapUploadFolder = undefined;
          self.sessionService.session = undefined;
          self.sessionService.authenticated = false;
          self.searchService.clientsThird = undefined;
          self.searchService.suppliersThird = undefined;
          self.$state.go("login");
          // self.$window.location.reload();
        })
        .catch(function (error) {
          console.error(error);
          console.error("error during log off");
        });
    }

    public launchSupportModal() {
      this.modalUtil.support();
    }

    public chooseCompany() {
      let self = this;
      self.sessionService
        .createMultiCompanyModal()
        .then(function (data) {
          self.modalService.createMultiCompanyModal(data.data, true);
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    public changeLanguage() {
      this.$translate.use(this.languageSelected.toLowerCase());
      this.tmhDynamicLocale.set(this.languageSelected.toLowerCase());
      this.thirdsList = this.updateThirdListI18n(this.thirdsList);
      this.$translate.refresh(this.languageSelected.toLowerCase());
    }

    public emptySearchInput() {
      if (angular.isDefined(this.selectedSearch)) {
        if (this.selectedSearch.length !== 0) {
          this.selectedSearch = "";
        }
      }
    }

    public advancedSearch(selectedEntity) {
      if (angular.isDefined(selectedEntity)) {
        if (selectedEntity.length !== 0) {
          this.selectedSearch = "";
          this.$state.go("websitelayout.headerandmenu.search", {
            third: selectedEntity,
          });
        }
      }
    }

    public showModalToGoIpp() {
      this.$uibModal
        .open({
          templateUrl: "tpl/IPPForm/confirmGoToIpp.html",
          controller: "ConfirmModalController",
          controllerAs: "confModalCtrl",
          windowClass: "ipp-transition-modal-size",
          resolve: {
            data: () => "",
          },
        })
        .result.then((data) => {
          this.$state.go("ipp.form.home");
        });
    }

    public showModalBI() {
      if (
        this.sessionService.session.company.budgetInsightCredential == null ||
        this.sessionService.session.company.budgetInsightCredential == undefined
      ) {
        let url =
          this.ENV.baseUrlBI +
          "/auth/webview/connect?client_id=" +
          this.ENV.IDBI +
          "&redirect_uri=" +
          this.ENV.returnURLBI +
          "&response_type=code&state=&types=banks";
        window.open(url, "_blank");
      } else {
        this.$state.go("websitelayout.headerandmenu.budgetInsight");
      }
    }

    public openAccountantModal() {
      let self = this;

      let modalInstance = this.$uibModal.open({
        templateUrl: "tpl/website/modals/accountantModal.html",
        controller: "AccountantModalController",
        controllerAs: "accountantModal",
        size: "md",
        backdrop: "static",
        windowClass: "accountant-modal",
        resolve: {
          r_data: function () {
            return {
              accountingFirmName:
                self.sessionService.session.accountingFirmName,
            };
          },
        },
      });

      modalInstance.result.then(function () {});
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "HeaderController",
    app.functionality.common.i18n.HeaderController
  );
