/// <reference path="../../_app.ts" />

namespace app.functionality.upload {
  import Invoice = app.model.Invoice;
  import UploadFolder = app.model.UploadFolder;
  import ModalService = app.functionality.common.modals.ModalService;
  import UploadService = app.functionality.upload.UploadService;
  import SessionService = app.functionality.common.session.SessionService;
  import MimeTypes = app.functionality.common.utils.MimeTypes;

  export class ToSendController {
    public uigrid: any;
    private mapUploadError: any;
    public uploadFolderSelected: string;
    public uploadFolderActivate: boolean;
    private uploadFolderList: Array<UploadFolder>;
    public mapUploadFolder: any;
    public collection: any;

    public droppedFiles: any;
    private allowedExtraExtension: Array<string> = [];

    private nrOfSelectedRows: number = 0;

    private fileAcceptedString: string =
      "application/pdf,image/png,image/jpeg,image/pjpeg,application/xml,text/xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    public invoices: Array<any> = [];

    private thumbnailPopover: any = {
      title: "Hello there",
      imgUrl: "",
      position: "auto right-top",
    };
    static $inject = [
      "r_invoiceList",
      "$scope",
      "$uibModal",
      "ModalService",
      "UploadService",
      "$translate",
      "$rootScope",
      "$timeout",
      "SessionService",
      "Notification",
      "$translatePartialLoader",
      "ENV",
      "RESTAPI",
      "MimeTypes",
      "$state",
    ];

    constructor(
      r_invoiceList: any,
      public $scope: ng.IScope,
      public $uibModal: any,
      private modalService: ModalService,
      private uploadService: UploadService,
      private $translate: ngt.ITranslateService,
      private $rootScope: ng.IRootScopeService,
      private $timeout: ng.ITimeoutService,
      public sessionService: SessionService,
      public notification: any,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private ENV: app.config.constants.environment.ConstantEnv,
      private restApi: app.config.constants.ConstantRestApi,
      private mime: MimeTypes,
      private $state: ng.ui.IStateService
    ) {
      $translatePartialLoader.addPart("customer/upload");
      this.uploadFolderActivate =
        this.sessionService.session.company.multiFolderEnable;
      if (this.sessionService.session.company.allowedUploadExtension) {
        this.allowedExtraExtension =
          this.sessionService.session.company.allowedUploadExtension;
        for (let i = 0; i < this.allowedExtraExtension.length; i++) {
          const extraExtension = this.allowedExtraExtension[i];
          this.fileAcceptedString +=
            "," + this.mime.getMimeType(extraExtension);
        }
      }

      let self = this;
      //Récupère le fileInput caché pour générer la fenetre de selection de fichier
      let fileInput: any = null;

      if (this.uploadFolderActivate === false) {
        fileInput = document.querySelector("#fileInput");
      } else {
        fileInput = document.querySelector("#fileInputUploadFolder");
      }
      fileInput.onclick = (e) => {
        //For CHROME -> if the value is not set to this it is not possible to upload the same file again
        e.target.value = "";
      };
      fileInput.addEventListener("change", this.launchUploadModal(fileInput));
      this.uploadService.fileInput = fileInput;

      //Init uigrid
      this.uigrid = {
        enableRowSelection: true,
        enableSelectAll: true,
        enableColumnMenus: false,
        selectionRowHeaderWidth: 40,
        rowHeight: 40,
      };

      //Ajoute les listeners des actions en provenance de uploadModalController.ts

      /* WHEN TRYING TO NAVIGATE AWAY FROM THIS PAGE AND THERE ARE ROWS SELECTED, 
      SHOW A CONFIRMATION MODAL 
       
      */
      let canNav = false;
      $rootScope.$on(
        "$stateChangeStart",
        function (event, toState, toParams, fromState, fromParams, options) {
          let nrOfSelectedItems = self.getNrOfSelectedItems();
          if (nrOfSelectedItems && nrOfSelectedItems > 0 && !canNav) {
            self.$uibModal
              .open({
                templateUrl: "tpl/website/modals/confirmLeaveUploadPage.html",
                controller: "ConfirmLeaveUploadPageController",
                controllerAs: "confirmController",
                backdrop: "static",
                resolve: {
                  r_data: function () {
                    return {
                      //note: note,
                      title: "",
                    };
                  },
                },
              })
              .result.then((confirmation) => {
                if (confirmation) {
                  nrOfSelectedItems = 0;
                  canNav = true;
                  self.$state.go(toState.name, toParams, { reload: true });
                }
              });
            event.preventDefault();
          }
        }
      );

      $scope.$on("addInvoice", function (event, invoice: Invoice) {
        self.addInvoice(invoice);
      });

      $scope.$on("displayResultUpload", function (event, mapUploadError: any) {
        self.launchResultUploadModal(mapUploadError);
      });

      //Ajoute les listeners des actions en provenance de keepNoteModalController.ts
      $scope.$on("merge", function (event, keepNote: boolean) {
        self.mergeInvoice(keepNote);
      });

      $scope.$on("split", function (event, keepNote: boolean) {
        self.splitInvoice(keepNote);
      });

      $scope.$on("deleteInvoice", function (event, choice: boolean) {
        if (choice === true) {
          self.deleteInvoice();
        } else {
          self.uigrid.gridApi.selection.clearSelectedRows();
        }
      });

      $scope.$on("splitInvoiceResponse", function (event, response: any) {
        self.refreshInvoices();
      });

      $scope.$on("mergeInvoiceResponse", function (event, response: any) {
        self.refreshInvoices();
      });

      $scope.$on("allUploadsCompleted", function (event, response: any) {
        self.refreshInvoices();
      });

      //Init champ pour uploadFolder
      this.uploadFolderSelected = "";

      //Init uigrid avec les données en provenance du router.ts
      if (angular.isDefined(r_invoiceList)) {
        for (let invoice of r_invoiceList.data) {
          invoice.editNameFlag = false;
          if (invoice.uploaderKey === this.sessionService.session.member.key) {
            invoice.uploaderName = $translate.instant("UPLOAD.TOSEND.me");
            // invoice.uploaderName = this.sessionService.session.member.firstname + " " + this.sessionService.session.member.lastname;
          } else {
            this.sessionService
              .getUserName(invoice.uploaderKey)
              .then(function (data) {
                invoice.uploaderName = data;
              });
          }
        }

        this.uigrid.data = r_invoiceList.data;
        this.invoices = r_invoiceList.data;

        if (this.uploadFolderActivate) {
          this.initGridUploadFolder();
        } else {
          this.initGrid();
        }
      }

      this.uigrid.onRegisterApi = function (gridApi) {
        this.gridApi = gridApi;
      };
      this.refreshInvoices();

      let rect = document
        .getElementById("invoice-table")
        .getBoundingClientRect();

      let droppingzone = document.getElementById("droppingZone");
      let invoiceTable = document.getElementById("myTable");

      let inputFromPC: any = null;
      inputFromPC = document.getElementById("inputFromPC");
      inputFromPC.onclick = (e) => {
        //For CHROME -> if the value is not set to this it is not possible to upload the same file again
        e.target.value = "";
      };
      inputFromPC.addEventListener(
        "change",
        this.handleUploadFromComputer(inputFromPC)
      );

      if (r_invoiceList.data.length == 0) {
        droppingzone.style.display = "flex";
      }
      document
        .getElementById("zone-wrapper")
        .addEventListener("drop", (event) => {
          if (event) {
            event.preventDefault();
            event.stopPropagation();
          }
          this.notification.error(
            this.$translate.instant("UPLOAD.MODAL.dragDrop.dropSquareZone")
          );
          if (r_invoiceList.data.length == 0) {
            droppingzone.style.display = "flex";
          } else {
            droppingzone.style.display = "none";
          }
        });
      document
        .getElementById("inner-block")
        .addEventListener("dragover", (event) => {
          if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.dataTransfer.effectAllowed = "copy";
          }
        });
      document
        .getElementById("inner-block")
        .addEventListener("dragenter", (event) => {
          if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.dataTransfer.effectAllowed = "copy";
            if (
              event.dataTransfer.types &&
              event.dataTransfer.types.indexOf("Files") > -1
            ) {
              droppingzone.style.display = "flex";
            }
          }
        });
      document
        .getElementById("inner-block")
        .addEventListener("dragleave", (event) => {
          if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.dataTransfer.effectAllowed = "copy";
            if (
              event.dataTransfer.types &&
              event.dataTransfer.types.indexOf("Files") > -1
            ) {
              if (
                event.x > rect.left + rect.width ||
                event.x < rect.left ||
                event.y > rect.top + rect.height ||
                event.y < rect.top
              ) {
                if (r_invoiceList.data && r_invoiceList.data.length == 0) {
                  droppingzone.style.display = "flex";
                } else {
                  droppingzone.style.display = "none";
                }
              }
            }
          }
        });

      $scope.name = "Bob";
    }

    /**
     * Initialise ui-grid si l'option UploadFolder est désactivée
     */
    private initGrid() {
      this.uigrid.columnDefs = [
        {
          field: "name",
          displayName: "UPLOAD.TOSEND.title",
          cellTemplate: "tpl/grid/upload/toSend/cellTemplateName.html",
          headerCellFilter: "translate",
          headerCellClass: "header-title text-left",
          headerCellTemplate:
            '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
            "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
            "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
            '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" ' +
            'col-index="renderIndex" title="TOOLTIP"><span class="ui-grid-header-cell-label" ' +
            "ui-grid-one-bind-id-grid=\"col.uid + '-header-text'\">{{ col.displayName CUSTOM_FILTERS }}</span></div><div ui-grid-filter></div></div>",
        },
        {
          field: "uploadDate",
          displayName: "UPLOAD.TOSEND.date",
          cellTemplate: "tpl/grid/upload/archives/cellDateTemplate.html",
          width: "15%",
          headerCellFilter: "translate",
          headerCellClass: "header-title text-center",
          headerCellTemplate:
            '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
            "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
            "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
            '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" ' +
            'col-index="renderIndex" title="TOOLTIP"><span class="ui-grid-header-cell-label" ' +
            "ui-grid-one-bind-id-grid=\"col.uid + '-header-text'\">{{ col.displayName CUSTOM_FILTERS }}</span></div><div ui-grid-filter></div></div>",
          sort: {
            direction: "desc",
            priority: 0,
          },
        },
        {
          field: "uploaderName",
          displayName: "UPLOAD.TOSEND.by",
          width: "20%",
          headerCellFilter: "translate",
          cellClass: "text-center",
          headerCellClass: "header-title text-center",
          headerCellTemplate:
            '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
            "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
            "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
            '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" ' +
            'col-index="renderIndex" title="TOOLTIP"><span class="ui-grid-header-cell-label" ' +
            "ui-grid-one-bind-id-grid=\"col.uid + '-header-text'\">{{ col.displayName CUSTOM_FILTERS }}</span></div><div ui-grid-filter></div></div>",
        },
      ];
    }

    /**
     * Si l'option uploadFolder est activé, on récupère la liste des uploadFolder depuis le serveur +
     * ajout de la colonne "upload_folder" dans l'ui-grid
     * On ajoute chaque uploadFolder dans une map {uploadFolder.key => uploadFolder.name}
     * Cette map permet de faciliter l'affichage au niveau html
     */

    private initGridUploadFolder() {
      let self = this;

      this.uploadService
        .initUploadFolder(false)
        .then(function (data) {
          let tmpData = data.plain();
          self.uploadFolderList = tmpData.data;
          self.mapUploadFolder = {};

          //To keep the same order of uploadFolder name each display
          self.uploadFolderList.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });

          let uploadFolder: UploadFolder = null;
          for (uploadFolder of self.uploadFolderList) {
            self.mapUploadFolder[uploadFolder.key] = uploadFolder.name;
          }
        })
        .catch(function (error) {
          console.error("error ocurred");
          console.error(error);
        });

      this.uigrid.columnDefs = [
        {
          field: "name",
          displayName: "UPLOAD.TOSEND.title",
          cellTemplate: "tpl/grid/upload/toSend/cellTemplateName.html",
          headerCellFilter: "translate",
          headerCellClass: "header-title text-left",
          headerCellTemplate:
            '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
            "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
            "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
            '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" ' +
            'title="TOOLTIP"><span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ col.displayName CUSTOM_FILTERS }}</span>' +
            "</div><div ui-grid-filter></div></div>",
        },
        {
          field: "uploadDate",
          displayName: "UPLOAD.TOSEND.date",
          cellTemplate: "tpl/grid/upload/archives/cellDateTemplate.html",
          width: "10%",
          headerCellFilter: "translate",
          headerCellClass: "header-title text-center",
          headerCellTemplate:
            '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
            "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
            "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
            '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" ' +
            'title="TOOLTIP"><span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ col.displayName CUSTOM_FILTERS }}</span>' +
            "</div><div ui-grid-filter></div></div>",
          sort: {
            direction: "desc",
            priority: 0,
          },
        },
        {
          field: "uploadFolderKey",
          displayName: "UPLOAD.TOSEND.folder",
          cellTemplate: "tpl/grid/upload/toSend/cellUploadFolder.html",
          width: "20%",
          headerCellFilter: "translate",
          headerCellClass: "header-title text-center",
          headerCellTemplate:
            '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
            "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
            "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
            '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" title="TOOLTIP">' +
            '<span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ col.displayName CUSTOM_FILTERS }}</span>' +
            "</div><div ui-grid-filter></div></div>",
        },
        {
          field: "uploaderName",
          displayName: "UPLOAD.TOSEND.by",
          width: "20%",
          cellClass: "text-center",
          headerCellFilter: "translate",
          headerCellClass: "header-title text-center",
          headerCellTemplate:
            '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
            "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
            "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
            '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" title="TOOLTIP">' +
            '<span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ col.displayName CUSTOM_FILTERS }}</span>' +
            "</div><div ui-grid-filter></div></div>",
        },
      ];
    }

    /**
     * Lorsqu'un dossier est sélectionné, on retient sa clé pour qu'elle soit envoyée au serveur
     * @param key :  clé de l'uploadFolder
     */
    public updateUploadFolderSelected(key: string) {
      this.uploadFolderSelected = key;
      document.getElementById("fileInputUploadFolder").click();
    }

    /**
     * Affichage de erreurs de l'upload grâce à un modal.
     */
    public launchResultUploadModal(mapUploadError: any) {
      this.mapUploadError = mapUploadError;
      this.modalService.createResultUploadModal(
        "tpl/website/modals/resultUploadModal.html",
        "ResultUploadModalController",
        "resultUploadModalController",
        this.mapUploadError
      );
    }

    /**
     * PROGRESS BAR
     * @param fileInput :  liste des file à envoyer
     * @returns {(e: any)=>void}
     */
    public launchUploadModal(fileInput: any) {
      let self = this;
      return function (e): void {
        let arrayFiles: Array<File> = fileInput.files;
        let regexName = /([\&|$*<>=~\\#"§\/])/;
        let testok: boolean = true;
        let listFile: string = "";
        let file: File = null;
        let succesArrayFiles: Array<File> = new Array<File>();
        for (file of arrayFiles) {
          if (
            regexName.test(
              file.name.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, "")
            )
          ) {
            listFile += file.name + "<br>";
            testok = false;
          } else {
            succesArrayFiles.push(file);
          }
        }

        if (testok === false) {
          self.modalService.status(
            self.$translate.instant("MODAL.INVOICE_ERROR.correctName") +
              " " +
              listFile,
            "MODAL.INVOICE_ERROR.title",
            true
          );
        }
        if (succesArrayFiles.length > 0) {
          self.mapUploadError = null;
          self.$uibModal.open({
            templateUrl: "tpl/website/modals/uploadModal.html",
            controller: "UploadModalController",
            controllerAs: "uploadModalController",
            //Permet de bloquer le click en dehors du modal
            //backdrop: "static",
            size: "md",
            resolve: {
              r_data: function () {
                return {
                  fileInput: succesArrayFiles,
                  uploadFolderKey: self.uploadFolderSelected,
                };
              },
              pmf_data: undefined,
              ipp_data: undefined,
            },
          });
        }
      };
    }

    /**
     *
     * Met à jour l'ui-grid data avec la nouvelle invoice SI le serveur à renvoyer un statut "OK". Voir UploadModalController
     * @param invoice
     */
    public addInvoice(invoice: Invoice) {
      let index = this.uigrid.data.push(invoice);
      this.uigrid.gridApi.grid.modifyRows(this.uigrid.data);
      this.uigrid.gridApi.selection.selectRow(this.uigrid.data[--index]);
      //this.notification.primary("Upload du document \"" + invoice.name + "\" réussi!");
      this.refreshInvoices();
    }

    // /**
    //  * /!\ NOT WORKING WIP /!\
    //  * Reset FileInput Element
    //  */
    // private resetFileInput(){
    //
    //     let fileInput: JQuery = null;
    //
    //     if (this.uploadFolderActivate === false) {
    //         fileInput = angular.element(document).find("#fileInput");
    //     } else {
    //         fileInput = angular.element(document).find("#fileInputUploadFolder");
    //     }
    //
    //     fileInput.val(null);
    // }

    /**
     * Modal pour vérifier si l'utilisateur est sur de supprimer le ou les invoices
     */
    public deleteInvoiceModal() {
      let self = this;
      this.$uibModal.open({
        templateUrl: "tpl/website/modals/deleteInvoiceModal.html",
        size: "sm",
        resolve: {
          r_data: function () {
            return {
              nbInvoiceSelect:
                self.uigrid.gridApi.selection.getSelectedRows().length,
            };
          },
        },
        controllerAs: "delInv",
        controller: [
          "$uibModalInstance",
          "r_data",
          "$rootScope",
          function ($uibModalInstance, r_data, $rootScope) {
            this.nbInvoiceSelect = r_data.nbInvoiceSelect;
            this.yes = function () {
              $rootScope.$broadcast("deleteInvoice", true);
              $uibModalInstance.close();
            };
            this.no = function () {
              $rootScope.$broadcast("deleteInvoice", false);
              $uibModalInstance.close();
            };
          },
        ],
      });
    }

    /**
     * Met à jour l'ui-grid lorsque l'utilisateur souhaite supprimer l' ou les invoice
     * Une liste de clés est envoyée au serveur. Si le status est "OK", les invoices sont supprimées grâce à leur index dans l'ui-grid data
     */
    public deleteInvoice() {
      let self = this;

      let index: number;
      let rowSelected: Array<Invoice> =
        this.uigrid.gridApi.selection.getSelectedRows();
      let invKeyArr: Array<string> = Array<string>();
      let arrayIndex: Array<number> = Array<number>();
      for (let invoice of rowSelected) {
        index = this.uigrid.data.indexOf(invoice);
        invKeyArr.push(invoice.key);
        arrayIndex.push(index);
      }

      this.uploadService
        .deleteInvoice(invKeyArr)
        .then(function (data) {
          let error = false;
          for (let promise of data) {
            let tmp = promise.plain();

            if (tmp.status !== 0) {
              error = true;
            } else {
              //self.notification.primary("Suppression réussie!");
            }
          }
          if (error === false) {
            arrayIndex.reverse();
            for (index of arrayIndex) {
              self.uigrid.data.splice(index, 1);
              self.invoices = self.uigrid.data;
            }
          } else {
            throw new Error(status);
          }
          let droppingzone = document.getElementById("droppingZone");
          if (self.invoices.length == 0) {
            droppingzone.style.display = "flex";
          } else {
            droppingzone.style.display = "none";
          }
        })
        .catch(function (error) {
          self.notification.error("Erreur lors de la suppression");
          console.error("error ocurred");
          console.error(error);
        });
    }

    /**
     * When a user click on a pdf to view it, the first step is to determine if he is on android, iphone, ... or PC
     * @param invoice selected
     */
    public detectDevicePdfViewer(invoice: Invoice) {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        //URL PDF
        // window.location.replace("urlPdf");
        this.launchPdfViewerModal(invoice);
      } else {
        let fileExtension = invoice.name.substring(
          invoice.name.lastIndexOf(".")
        );
        if (fileExtension.toLowerCase() === ".pdf") {
          this.launchPdfViewerModal(invoice);
        } else {
          let fileLink =
            this.ENV.baseUrl +
            "/" +
            this.restApi.services.DBFile +
            "?sessionID=" +
            this.sessionService.session.sessionID +
            "&companyKey=" +
            this.sessionService.session.company.key +
            "&fileKey=" +
            invoice.fileKey +
            "&fileName=" +
            encodeURI(invoice.name) +
            "&isIE=false";

          window.open(fileLink, "_blank");
        }
      }
    }

    /**
     *
     * @param invoice
     */
    private launchPdfViewerModal(invoice) {
      //url in pdfViwerModal

      // check first if the pdf is ok, and then show the modal

      // we make the note text area possible to edit
      invoice.editableNote = true;

      this.$uibModal.open({
        templateUrl: "tpl/website/modals/pdfViewerModal.html",
        controller: "PdfViewerModalController",
        controllerAs: "pdfViewer",
        size: "lg",
        keyboard: "false",
        resolve: {
          r_data: function () {
            return {
              invoice: invoice,
            };
          },
          r_dataPMF: null,
        },
      });
    }

    /**
     * Supprime l'élément sélectionné et le remplace par des invoices
     * @param keepNote :  boolean renvoyé par "KeepNoteModal" pour savoir si l'utilisteur souhaite garder la note
     */
    public splitInvoice(keepNote: boolean) {
      let self = this;
      // let invoice:  Invoice;
      let rowSelected: Array<Invoice> =
        this.uigrid.gridApi.selection.getSelectedRows();
      this.uploadService
        .splitInvoice(rowSelected[0].key, keepNote)
        .then(function () {
          let index = self.uigrid.data.indexOf(rowSelected[0]);
          self.uigrid.data.splice(index, 1);

          // let arrayInvoices:  any = response.plain();
          // arrayInvoices = <Array<Invoice>> arrayInvoices.data;
          // for (let invoice of arrayInvoices) {
          //     self.uigrid.data.push(invoice);
          // }
        })
        .catch(function (error) {
          console.error("error ocurred");
          console.error(error);
        });
    }

    /**
     * Lorsqu'un utilisateur décide de merge ou split, si une note est présente, il faut lui demander si il souhaite la conserver
     * @param splitOrMerge :  cette méthode est appellée pour merge ou split, ce paramètre permet de différencier l'action split ou merge
     * @param uploadFolderKey :  folderKey selectionné.
     */
    public launchKeepNoteModal(splitOrMerge: string, uploadFolderKey: string) {
      let invoice: Invoice;
      let rowSelected: Array<Invoice> =
        this.uigrid.gridApi.selection.getSelectedRows();

      if (angular.isDefined(uploadFolderKey) && uploadFolderKey !== "") {
        this.uploadFolderSelected = uploadFolderKey;
      }

      for (invoice of rowSelected) {
        //check if a note is present in invoices selected
        if (
          invoice.note !== undefined &&
          invoice.note != null &&
          invoice.note !== ""
        ) {
          this.modalService.createKeepNoteModal(
            "tpl/website/modals/keepNoteModal.html",
            "KeepNoteModalController",
            "keepNoteModalController",
            splitOrMerge
          );
          return;
        }
      }

      //No notes in invoices selected => keepNote = false
      if (splitOrMerge === "split") {
        this.splitInvoice(false);
      } else {
        this.mergeInvoice(false);
      }
    }

    /**
     *
     * @param keepNote :  boolean renvoyé par "KeepNoteModal" pour savoir si l'utilisteur souhaite garder la note
     */
    public mergeInvoice(keepNote: boolean) {
      let self = this;

      let index: number;
      let rowsSelected: Array<Invoice> =
        this.uigrid.gridApi.selection.getSelectedRows();
      let invKeyArr: Array<string> = Array<string>();
      let arrayIndex: Array<number> = Array<number>();

      for (let invoice of rowsSelected) {
        index = this.uigrid.data.indexOf(invoice);
        invKeyArr.push(invoice.key);
        arrayIndex.push(index);
      }

      this.uploadService
        .mergeInvoices(
          invKeyArr,
          rowsSelected[0].name,
          keepNote,
          this.uploadFolderSelected
        )
        .then(function (response) {
          arrayIndex.reverse();
          for (index of arrayIndex) {
            self.uigrid.data.splice(index, 1);
          }
        })
        .then(function () {
          // délai pour refaire la requête et récupérer la liste mise à jour
          return self.$timeout(300);
        })
        .then(function () {
          //reload invoices
          self.refreshInvoices();
        })
        .catch(function (error) {
          console.error("error ocurred");
          console.error(error);
        });
    }

    public sendSelectedInvoices() {
      let self = this;
      let rowsSelected: Array<Invoice> =
        this.uigrid.gridApi.selection.getSelectedRows();
      let invKeyArr: string[] = [];
      let arrayIndex: number[] = [];
      let numberInvoice = 0;
      let invoiceWithoutNote = 0;
      for (let invoice of rowsSelected) {
        let index = this.uigrid.data.indexOf(invoice);
        invKeyArr.push(invoice.key);
        arrayIndex.push(index);
        numberInvoice++;
        if (
          invoice.note === null ||
          invoice.note === undefined ||
          invoice.note === ""
        ) {
          invoiceWithoutNote++;
        }
      }

      let body = "";
      let noteText = "";
      if (numberInvoice === 1) {
        body = self.$translate.instant("UPLOAD.TOSEND.send_1_invoice");
      } else {
        body =
          self.$translate.instant("UPLOAD.TOSEND.send_X_invoices_1") +
          numberInvoice +
          self.$translate.instant("UPLOAD.TOSEND.send_X_invoices_2");
      }

      if (invoiceWithoutNote > 0 && invoiceWithoutNote !== numberInvoice) {
        noteText = self.$translate.instant("UPLOAD.TOSEND.miss_note_2");
      } else if (
        invoiceWithoutNote > 0 &&
        invoiceWithoutNote === numberInvoice
      ) {
        noteText = self.$translate.instant("UPLOAD.TOSEND.no_note");
      } else {
        noteText = self.$translate.instant("UPLOAD.TOSEND.all_annoted_2");
      }

      let modalOptions = {
        closeButtonText: "MODAL.INVOICE_SEND.cancelBtn",
        actionButtonText: "MODAL.INVOICE_SEND.sendInvoices",
        headerText: "MODAL.INVOICE_SEND.header",
        bodyText: "MODAL.INVOICE_SEND.question",
        noteText: noteText,
        noteExist: invoiceWithoutNote,
        numberInvoices: numberInvoice,
      };

      this.modalService.showConfirm({}, modalOptions).then(function () {
        // get all ids from invoices selected
        self.uploadService
          .sendInvoice(invKeyArr)
          .then(function (response) {
            if (response.status === 0) {
              arrayIndex.reverse();
              for (let index of arrayIndex) {
                self.uigrid.data.splice(index, 1);
              }
              self.notification(
                self.$translate.instant("NOTIFICATIONS.UPLOAD.success")
              );
            } else {
              console.error(response.status);
            }
          })
          .catch(function (error) {
            console.error(error);
          });
      });
    }

    public editInvoiceName(row) {
      let self = this;
      let oldValue = row.entity.nameBeforeEdit;
      let newValue = row.entity.name;

      if (
        oldValue.length > 4 &&
        (oldValue.substring(oldValue.length - 4, oldValue.length) === ".pdf" ||
          oldValue.substring(oldValue.length - 4, oldValue.length) === ".PDF")
      ) {
        if (
          newValue.length < 4 ||
          (newValue.length > 4 &&
            (newValue.substring(newValue.length - 4, newValue.length) !==
              ".pdf" ||
              newValue.substring(newValue.length - 4, newValue.length) !==
                ".PDF"))
        ) {
          newValue += ".pdf";
        }
      }

      if (oldValue !== newValue) {
        if (
          newValue === "" ||
          newValue.toLowerCase() === ".pdf" ||
          newValue.toLowerCase() === ".png" ||
          newValue.toLowerCase() === ".xml" ||
          newValue.toLowerCase() === ".jpeg" ||
          newValue.toLowerCase() === ".jpg"
        ) {
          self.modalService.status(
            "MODAL.INVOICE_ERROR.noName",
            "MODAL.INVOICE_ERROR.title"
          );
          row.entity.name = oldValue;
          return;
        }

        let regex =
          /^([a-zA-Z0-9é_.°\{}[\]+°P \-!()\u00C0-\u00FF\- ]*)\.(\w+)$/;

        let regexName = /([\&|$*<>=~\\#"§\/])/;
        // let fileExtension  = /^.*\.(jpg|JPG|gif|GIF|doc|DOC|pdf|PDF)$/;

        //let regexName = /^([/\\:*?"<>]+)$/;
        let matches = newValue.match(regex);

        //Test regex
        if (matches) {
          //Test regex on filename
          let filename = matches[1];
          let extension = matches[2];
          if (regexName.test(filename)) {
            self.modalService.status(
              self.$translate.instant("MODAL.INVOICE_ERROR.correctName") +
                " " +
                filename,
              "MODAL.INVOICE_ERROR.title",
              true
            );
            row.entity.name = oldValue;
            return;
          } else {
            self.uploadService
              .renameInvoice(row.entity.key, newValue)
              .then(function () {
                self.notification.primary("Changement de nom réussi!"); // TODO I18N
                row.entity.name = newValue;
                row.entity.editNameFlag = false;
              })
              .catch(function (error) {
                self.modalService.status(
                  error.data,
                  "MODAL.INVOICE_ERROR.changingName"
                );
                row.entity.name = oldValue;
              });
          }
        } else {
          self.modalService.status(
            "MODAL.INVOICE_ERROR.errorDuringModification",
            "MODAL.INVOICE_ERROR.title"
          );
          row.entity.name = oldValue;
        }
      } else {
        row.entity.name = newValue;
        row.entity.editNameFlag = false;
      }
      row.entity.editNameFlag = false;
    }

    public refreshInvoices() {
      let self = this;
      self.uploadService
        .getInvoiceArr()
        //UNCOMMENT
        .then(function (response) {
          let index: number;
          // let rowsSelected: Array<Invoice> = self.uigrid.gridApi.selection.getSelectedRows();
          // let invSelectedArr: Array<string> = Array<string>();

          // for (let invoice of rowsSelected) {
          //   invSelectedArr.push(invoice.key);
          // }

          self.uigrid.data = response.data;
          self.invoices = self.uigrid.data;
          let droppingzone = document.getElementById("droppingZone");
          if (self.invoices.length == 0) {
            droppingzone.style.display = "flex";
          } else {
            droppingzone.style.display = "none";
          }
          self.uigrid.gridApi.grid.modifyRows(self.uigrid.data); // important !
          index = 0;

          for (let invoice of self.uigrid.data) {
            invoice.editNameFlag = false;
            if (
              invoice.uploaderKey === self.sessionService.session.member.key
            ) {
              invoice.uploaderName =
                self.$translate.instant("UPLOAD.TOSEND.me");
            } else {
              self.sessionService
                .getUserName(invoice.uploaderKey)
                .then(function (data) {
                  invoice.uploaderName = data;
                });
            }

            // if (invSelectedArr.indexOf(invoice.key) > -1) {
            self.uigrid.gridApi.selection.selectRow(self.uigrid.data[index]);
            // }
            index++;
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }

    public checkIfPDF(arrSelected): boolean {
      if (arrSelected !== undefined && arrSelected !== null) {
        for (let invoice of arrSelected) {
          let nameValue = invoice.name;
          if (
            nameValue.length < 4 ||
            nameValue
              .substring(nameValue.length - 4, nameValue.length)
              .toLowerCase() !== ".pdf"
          ) {
            return false;
          }
        }
        return true;
      }
    }

    public handleUploadFromComputer(fileInput: any) {
      return (e): void => {
        let arrayFiles: Array<File> = fileInput.files;
        if (arrayFiles.length > 0) {
          this.handleFileDrop(arrayFiles);
        }
      };
    }
    public handleFileDrop(files: any) {
      if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          let fileExtension = "";
          if (file.name) {
            fileExtension = file.name.substring(file.name.lastIndexOf("."));
          } else {
          }
          /* if (
            file.type !== "text/xml" &&
            file.type !== "application/xml" &&
            file.type !== "application/pdf" &&
            file.type !== "image/png" &&
            file.type !== "image/jpeg" &&
            file.type !== "image/pjpeg" &&
            file.type !== "image/jpg" &&
            this.allowedExtraExtension.indexOf(fileExtension) === -1
          ) {
            this.notification.error(
              this.$translate.instant("UPLOAD.MODAL.dragDrop.wrongFileType")
            );
            this.droppedFiles = [];
            break; */
          //} else {
          this.droppedFiles = files;
          //}
        }
        if (this.droppedFiles.length > 0) {
          if (this.uploadFolderActivate) {
            this.fileDropModal();
            let droppingzone = document.getElementById("droppingZone");
            if (this.invoices.length == 0) {
              droppingzone.style.display = "flex";
            } else {
              droppingzone.style.display = "none";
            }
          } else {
            this.launchDropUploadModal(this.droppedFiles);
          }
        }
      }
    }

    public fileDropModal() {
      let self = this;
      let fileArray: Array<File> = [];

      for (let i = 0; i < this.droppedFiles.length; i++) {
        const file = this.droppedFiles[i];
        fileArray.push(file);
      }
      this.$uibModal.open({
        templateUrl: "tpl/website/modals/fileDropModal.html",
        size: "md",
        resolve: {
          r_data: function () {
            return {
              fileList: self.droppedFiles,
              droppedFiles: fileArray,
              uploadFolderSelected: self.uploadFolderList[0].key,
              uploadFolders: self.uploadFolderList,
            };
          },
        },
        controllerAs: "dnd",
        controller: [
          "$uibModalInstance",
          "r_data",
          "$rootScope",
          function ($uibModalInstance, r_data, $rootScope) {
            this.fileList = r_data.fileList;
            this.droppedFiles = r_data.droppedFiles;
            this.uploadFolderSelected = r_data.uploadFolderSelected;
            this.uploadFolders = r_data.uploadFolders;
            this.upload = function (uploadFolderSelected) {
              self.uploadFolderSelected = uploadFolderSelected;
              self.launchDropUploadModal(self.droppedFiles);
              $uibModalInstance.close();
              let droppingzone = document.getElementById("droppingZone");
              if (self.invoices.length == 0) {
                droppingzone.style.display = "flex";
              } else {
                droppingzone.style.display = "none";
              }
            };
            this.cancel = function () {
              let droppingzone = document.getElementById("droppingZone");
              if (self.invoices.length == 0) {
                droppingzone.style.display = "flex";
              } else {
                droppingzone.style.display = "none";
              }
              $uibModalInstance.close();
            };
          },
        ],
      });
    }

    public launchDropUploadModal(fileArray: any) {
      let self = this;
      let arrayFiles: Array<File> = fileArray;
      let regexName = /([\&|$*<>=~\\#"§\/])/;
      let testok: boolean = true;
      let listFile: string = "";
      let file: File = null;
      let succesArrayFiles: Array<File> = new Array<File>();
      for (file of arrayFiles) {
        if (
          regexName.test(
            file.name.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, "")
          )
        ) {
          listFile += file.name + "<br>";
          testok = false;
        } else {
          succesArrayFiles.push(file);
        }
      }

      if (testok === false) {
        self.modalService.status(
          self.$translate.instant("MODAL.INVOICE_ERROR.correctName") +
            " " +
            listFile,
          "MODAL.INVOICE_ERROR.title",
          true
        );
      }
      if (succesArrayFiles.length > 0) {
        self.mapUploadError = null;
        self.$uibModal.open({
          templateUrl: "tpl/website/modals/uploadModal.html",
          controller: "UploadModalController",
          controllerAs: "uploadModalController",
          //Permet de bloquer le click en dehors du modal
          backdrop: "static",
          size: "md",
          resolve: {
            r_data: function () {
              return {
                fileInput: succesArrayFiles,
                uploadFolderKey: self.uploadFolderSelected,
              };
            },
            pmf_data: undefined,
            ipp_data: undefined,
          },
        });
      }
      document.getElementById("droppingZone").style.display = "none";
    }

    getNrOfSelectedItems() {
      if (this.uigrid.gridApi.selection.getSelectedRows()) {
        return this.uigrid.gridApi.selection.getSelectedRows().length;
      } else {
        return 0;
      }
    }

    showNoteModal(invoice) {
      let self = this;
      /* let note = "";
      if (invoice.note) {
        note = invoice.note;
      } else {
        note = "";
      } */
      let selectedDocument = invoice;
      this.$uibModal.open({
        templateUrl: "tpl/website/modals/invoiceNoteModal.html",
        controller: "invoiceNoteModalController",
        controllerAs: "invoiceNoteModalController",
        //Permet de bloquer le click en dehors du modal
        backdrop: "static",
        size: "md",
        resolve: {
          r_data: function () {
            return {
              //note: note,
              selectedDocument: selectedDocument,
            };
          },
        },
      });
    }

    showUploadfromComputer() {
      if (this.invoices.length == 0) {
        return true;
      } else {
        return false;
      }
    }

    initThumb(hoveredRow: any, filteredList: any) {
      this.thumbnailPopover.title = hoveredRow.entity.name;
      this.thumbnailPopover.imgUrl = "https://via.placeholder.com/1240x1753";

      /*  let index = this.getHoveredRowIndex(hoveredRow, filteredList);
      if (index <= 5) {
        this.thumbnailPopover.position = "auto right-top";
      } else {
        this.thumbnailPopover.position = "auto right-bottom";
      } */
    }

    getHoveredRowIndex(hoveredRow: any, filteredList: any) {
      let index = filteredList.findIndex(
        (i) => i.entity.key === hoveredRow.entity.key
      );
      return index;
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller("ToSendController", app.functionality.upload.ToSendController);
