/// <reference path="../../_app.ts" />

module app.functionality.user {

    export class PwCheckDirective implements ng.IDirective {
        
        public require = "ngModel";

        link = (scope: ng.IScope, ngModel: ng.INgModelController, attrs: ng.IAttributes, ctrl: any) => {

            let key1 = "ngModel"; //tslint doesn't allow access with string literals, so we use variables instead
            let me = attrs[key1]; //we can't use "attr.ngModel" directly with ng.IAttributes, please check angular.d.ts for more info

            let key2 = "pwCheck";
            let matchTo = attrs[key2];
            
            scope.$watchGroup([me, matchTo], function(value) {
                ctrl.$setValidity("pwmatch", value[0] === value[1]);
            });

        }

        static factory(): ng.IDirectiveFactory {
            const directive = () => new PwCheckDirective();

            return directive;
        }
    }
}

angular.module("app").directive("pwCheck", app.functionality.user.PwCheckDirective.factory());
