/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {
  import SessionService = app.functionality.common.session.SessionService;
  import LoggerService = app.functionality.common.logger.LoggerService;
  import TicketService = app.functionality.ticket.TicketService;

  export class AccountantModalController {
    private sessionID: string;
    private companyKey: string;

    public model;
    public key;

    private showMessaging: boolean = false;
    private accountingFirmName: string;
    private quickMessage: string = "";
    private profileImgUrl: string = ""; //https://st3.depositphotos.com/1767687/16607/v/600/depositphotos_166074422-stock-illustration-default-avatar-profile-icon-grey.jpg
    private accountantFullName: string = "John Doe";
    private phoneNumber: string = "+32467993078";
    private imgPlaceholderStyle: any;

    private calendarLink: string;
    private showAccountantName: boolean = false;

    static $inject = [
      "$uibModalInstance",
      "$translate",
      "SessionService",
      "TicketService",
      "Notification",
      "$rootScope",
      "$http",
      "$window",
      "r_data",
      "$translatePartialLoader",
      "$filter",
      "$sanitize",
      "LoggerService",
    ];

    constructor(
      public $uibModalInstance: any,
      private $translate: ngt.ITranslateService,
      private sessionService: SessionService,
      private ticketService: TicketService,
      private notification: any,
      private $rootScope: ng.IRootScopeService,
      private $http: ng.IHttpService,
      public $window: angular.IWindowService,
      public r_data: any,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      public $filter: ng.IFilterService,
      private $sanitize: ng.sanitize.ISanitizeService,
      private logger: LoggerService
    ) {
      $translatePartialLoader.addPart("customer/modals");

      this.sessionID = this.sessionService.session.sessionID;
      this.companyKey = this.sessionService.session.company.key;

      if (r_data.accountingFirmName != null) {
        this.accountingFirmName = r_data.accountingFirmName;
      }

      //HIGHLIGHT ACCOUNTANT
      if (
        this.sessionService.session != null &&
        this.sessionService.session.brandingData != null
      ) {
        //usePhoneOfFirm
        if (this.sessionService.session.brandingData.usePhoneOfFirm) {
          this.phoneNumber = this.sessionService.session.brandingData.phone;
        } else {
          this.phoneNumber = this.getPhoneOfResponsible();
        }
        //useCalendarLink
        if (this.sessionService.session.brandingData.useCalendarLink) {
          this.calendarLink = this.checkCalendarLink(
            this.sessionService.session.brandingData.calendar
          );
        } else {
          this.calendarLink = this.checkCalendarLink(
            this.getCalendarOfResponsible()
          );
        }
      }
      if (
        this.sessionService.session != null &&
        this.sessionService.session.responsableCompany != null
      ) {
        this.accountantFullName = this.sessionService.session.responsableCompany.fullName;

        //Profile Pic
        this.profileImgUrl = this.sessionService.session.responsableCompany.pictureLink;
      }
    }

    close() {
      this.showMessaging = false;
      this.$uibModalInstance.close();
    }

    getPhoneOfResponsible() {
      if (
        this.sessionService.session != null &&
        this.sessionService.session.responsableCompany != null
      ) {
        return this.sessionService.session.responsableCompany.mobile;
      }
    }
    getCalendarOfResponsible(): string {
      let calendarLink: string = "";
      if (
        this.sessionService.session != null &&
        this.sessionService.session.responsableCompany != null
      ) {
        calendarLink = this.sessionService.session.responsableCompany
          .calendarLink;
      }
      return calendarLink;
    }

    openAppointmentLink() {
      this.$window.open(this.calendarLink, "_blank");
    }

    switchToMessaging() {
      this.showMessaging = true;
    }
    backToNormal() {
      this.showMessaging = false;
    }

    sendMessage() {
      let newTicket = new app.model.Ticket();
      newTicket.status = 1; // OPEN Status
      newTicket.type = 1; // FROM CUSTOMER
      newTicket.senderKey = this.sessionService.session.member.key;

      newTicket.freeQuestion = this.quickMessage;
      newTicket.sendDate = new Date();
      newTicket.creationDate = new Date();
      newTicket.questionCategory = 999;

      newTicket.attachmentArr = [];
      newTicket.notifyUserArr = [];

      if (this.sessionService.isCustomer()) {
        newTicket.userFlag = 100;
        newTicket.accFlag = 0;
      } else {
        newTicket.userFlag = 0;
        newTicket.accFlag = 100;
      }

      newTicket.companyKey = this.companyKey;
      newTicket.answered = false;
      newTicket.authorKey = this.sessionService.session.member.key;

      this.showMessaging = false;
      let self = this;
      self.ticketService
        .addTicket(self.sessionID, newTicket)
        .then(function (data) {
          self.notification(
            self.$translate.instant("NOTIFICATIONS.TICKET.sendTicketSuccess")
          );
          self.quickMessage = "";
        })
        .catch(function (error) {
          self.notification(
            self.$translate.instant("NOTIFICATIONS.TICKET.sendTicketError")
          );
          self.logger.error(
            "AccountantModalController.ts",
            error,
            self.sessionService.session.member
          );
        });
    }

    makeinitial(fullName: string) {
      let nameTab: Array<string> = fullName.split(" ");
      if (nameTab) {
        nameTab.forEach((e, i) => {
          if (e == "") {
            nameTab.splice(i, 1);
          }
        });
      }
      if (nameTab.length >= 2) {
        return nameTab[0][0].toUpperCase() + nameTab[1][0].toUpperCase();
      } else if (nameTab.length == 1) {
        return nameTab[0][0].toUpperCase();
      } else {
        return "";
      }
    }
    hashCode(str: string) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    }
    intToRGB(i: number) {
      var c = (i & 0x00ffffff).toString(16).toUpperCase();
      return "00000".substring(0, 6 - c.length) + c;
    }

    makehexvalue(fullname: string) {
      return "#" + this.intToRGB(this.hashCode(fullname));
    }

    hexToRgb(hex: string) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    }

    getBestcontrastColor(hex: string) {
      var color = this.hexToRgb(hex);
      var red = color.r;
      var green = color.g;
      var blue = color.b;
      var value = red * 299 + green * 587 + blue * 114;
      if (value / 1000.0 < 125) return "white";
      else return "black";
    }

    getImgPlaceholderStyle() {
      let bgColor = this.makehexvalue(this.accountantFullName);
      let fontColor = this.getBestcontrastColor(bgColor);
      return { "background-color": bgColor, color: fontColor };
    }

    checkCalendarLink(link: string) {
      if (link !== "" && link !== null && link !== undefined) {
        if (link.indexOf("https://") == 0 || link.indexOf("http://") == 0) {
          return link;
        } else {
          return "https://" + link;
        }
      } else {
        return "";
      }
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "AccountantModalController",
    app.functionality.common.modals.AccountantModalController
  );
