/// <reference path="../../../_app.ts" />

module app.modules.accountList.directive {

    export interface IAccountListDirectiveScope extends ng.IScope {
        translateKey: string;
        data: any;
    }

    export class AccountListDirective implements ng.IDirective {
        restrict = "E";
        templateUrl = "js/modules/accountList/directive/accountListDirective.html";
        scope : {[boundProperty: string]: string} = {
            data: "=data",
            translateKey: "@translateKey"
        };

        controller =  app.modules.accountList.controller.AccountListController;
        controllerAs = "accListCtrl";

        constructor(private $translate: ngt.ITranslateService,
                    public $rootScope: any) {
        }

        static factory(): ng.IDirectiveFactory {
            const directive = ($translate: ngt.ITranslateService, $rootScope) =>
                new AccountListDirective($translate, $rootScope);
            directive.$inject = ["$translate", "$rootScope"];

            return directive;
        }
    }
}

angular.module("accountListModule").directive("accountList", app.modules.accountList.directive.AccountListDirective.factory());