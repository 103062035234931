/// <reference path="../../_app.ts" />

module app.functionality.profit {
  import PNLColumnConfig = app.model.parameter.PNLColumnConfig;

  export class ProfitService {
    private rest: restangular.IService;

    static $inject = ["RestService", "RESTAPI", "$http", "ENV", "$q"];

    constructor(
      restService: app.functionality.common.rest.RestService,
      private RESTAPI: app.config.constants.ConstantRestApi,
      private $http: ng.IHttpService,
      private ENV: ENV,
      private $q: ng.IQService
    ) {
      this.rest = restService.getRoot();
    }

    // old mock methods only used in ProfitServiceTest.ts
    getProfitByCompanyKey = (companyKey: string): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.base)
        .one("companyKey", companyKey)
        .get();
    };

    getAllThird = (
      sessionID: string,
      mustHadBudgetThird: boolean
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.cashFlow)
        .all(this.RESTAPI.services.profit.allThird)
        .one(sessionID + "&" + 1 + "&" + mustHadBudgetThird)
        .get();
    };

    getTurnoverAccountArr = (sessionID: string): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.sales)
        .all(this.RESTAPI.services.profit.turnoverAccount)
        .one(sessionID)
        .get();
    };

    getDashboardProfit = (sessionID: string): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.controller)
        .all(this.RESTAPI.services.profit.dashboardProfit)
        .one(sessionID)
        .get();
    };

    getTurnoverByBookyear = (
      sessionID: string,
      bookyear: number,
      account: any,
      third: string
    ): ng.IPromise<any> => {
      if (account === null || !angular.isDefined(account)) {
        account = "";
      }

      if (third === null || !angular.isDefined(third)) {
        third = "";
      }

      return this.rest
        .all(this.RESTAPI.services.profit.sales)
        .all(this.RESTAPI.services.profit.turnoverByBookyear)
        .one(sessionID + "&" + bookyear + "&" + account + "&" + third)
        .get();
    };

    getTop5Suppliers = (
      sessionID: string,
      interval: number
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.supplier)
        .all(this.RESTAPI.services.profit.top5supplier)
        .one(sessionID + "&" + interval)
        .get();
    };

    getTop5Customers = (
      sessionID: string,
      interval: number
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.customer)
        .all(this.RESTAPI.services.profit.top5customer)
        .one(sessionID + "&" + interval)
        .get();
    };

    getShortPnl = (sessionID: string): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.balanceMgmt)
        .all(this.RESTAPI.services.profit.shortPnl)
        .one(sessionID)
        .get();
    };

    getPNLBasedOnConfigREST = (
      sessionID: string,
      configStringArr: string
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.balanceMgmt)
        .all(this.RESTAPI.services.profit.getPNLBasedOnConfig)
        .one(sessionID + "&" + configStringArr)
        .get();
    };

    getBalance = (sessionID: string, bookyear: number): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.balanceMgmt)
        .all(this.RESTAPI.services.profit.balance)
        .one(sessionID + "&2&" + bookyear)
        .get();
    };

    getBalanceByPeriod = (
      sessionID: string,
      bookyear: number,
      period: any
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.balanceMgmt)
        .all(this.RESTAPI.services.profit.balanceByPeriod)
        .one(sessionID + "&2&" + bookyear + "&[" + period + "]")
        .get();
    };

    getIncome = (sessionID: string, bookyear: number): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.balanceMgmt)
        .all(this.RESTAPI.services.profit.balance)
        .one(sessionID + "&1&" + bookyear)
        .get();
    };

    getIncomeByPeriod = (
      sessionID: string,
      bookyear: number,
      period: any
    ): ng.IPromise<any> => {
      // what about the case of multiple periods?
      // let period is a number, or table
      // let periodConcerned
      return this.rest
        .all(this.RESTAPI.services.profit.balanceMgmt)
        .all(this.RESTAPI.services.profit.balanceByPeriod)
        .one(sessionID + "&1&" + bookyear + "&[" + period + "]")
        .get();
    };

    getISTemplate = (
      sessionID: string,
      templateKey: number
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.balanceMgmt)
        .all(this.RESTAPI.services.profit.balance)
        .one(sessionID + "&" + templateKey)
        .get();
    };

    // /getPNLBasedOnConfig/{sessionID}&{configStringArr}
    getPNLBasedOnConfig = (
      sessionID: string,
      configStringArr: string[]
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.balanceMgmt)
        .all(this.RESTAPI.services.profit.balance)
        .one(sessionID + "&" + configStringArr)
        .get();
    };

    // /addPNLColumnConfig
    addPNLColumnConfig = (
      sessionID: string,
      col: PNLColumnConfig
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.balanceMgmt)
        .all(this.RESTAPI.services.profit.addPNLColumnConfig)
        .customPUT({
          sessionID: sessionID,
          pnlCol: col,
        });
    };

    // /editPNLColumnConfig
    editPNLColumnConfig = (
      sessionID: string,
      PNLColumnConfig: PNLColumnConfig
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.balanceMgmt)
        .all(this.RESTAPI.services.profit.editPNLColumnConfig)
        .customPUT({
          sessionID: sessionID,
          pnlCol: PNLColumnConfig,
        });
    };

    editMutiplePNLColumnConfig = (
      sessionID: string,
      PNLColumnConfigArr: PNLColumnConfig[]
    ): ng.IPromise<any> => {
      let self = this;
      let promises = [];
      for (let column of PNLColumnConfigArr) {
        let request = self.editPNLColumnConfig(sessionID, column);
        promises.push(request);
      }
      return this.$q.all(promises);
    };

    // /deletePNLColumnConfig
    deletePNLColumnConfig = (
      sessionID: string,
      col: PNLColumnConfig
    ): ng.IPromise<any> => {
      return this.$http({
        url:
          this.ENV.baseUrl +
          "/" +
          this.RESTAPI.services.profit.balanceMgmt +
          "/" +
          this.RESTAPI.services.profit.deletePNLColumnConfig,
        method: "DELETE",
        data: {
          sessionID: sessionID,
          pnlCol: col,
        },
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      });
    };

    // /getDetailAccount/{sessionID}&{companyKey}&{companyName}&{accounts}&{bookyear}&{periodArr}&{totalAccount}
    getDetailAccount = (
      sessionID: string,
      companyKey: string,
      account: string,
      bookyear: number,
      periodArr: any,
      totalAccount: number
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.profit.balanceMgmt)
        .all(this.RESTAPI.services.profit.getDetailAccount)
        .one(
          sessionID +
            "&" +
            companyKey +
            "&" +
            account +
            "&" +
            bookyear +
            "&" +
            periodArr +
            "&" +
            totalAccount
        )
        .get();
    };
  }
}

angular
  .module("app")
  .service("ProfitService", app.functionality.profit.ProfitService);
