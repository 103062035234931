/// <reference path="../_app.ts" />

module app.model {

    export class TicketRecord {

        constructor() {
        }

        public userKey : string;
        public date : Date;

    }

}
