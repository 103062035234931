/// <reference path="../../../_app.ts" />

module app.functionality.common.utils {
  import SessionService = app.functionality.common.session.SessionService;

  export class AccountTools {
    static $inject = ["SessionService"];

    constructor(private sessionService: SessionService) {}

    public isPnl(account: number): boolean {
      let accountRoot: number = this.GetAccountRoot(account, 2);
      return accountRoot >= 60 && accountRoot < 80;
    }

    private LUXEMBOURG_ACTIVE = [
      102, 103, 20, 21, 22, 23, 3, 400, 4010, 4011, 4012, 4013, 4014, 4019,
      4020, 4021, 4022, 4023, 4024, 4029, 403, 404, 405, 406, 407, 408, 409, 41,
      42, 44113, 44123, 500, 501, 502, 503, 508, 509, 237, 51, 52, 53, 54, 56,
      57, 58, 59, 481, 484, 486,
    ];

    private LUXEMBOURG_PASSIVE = [
      101, 104, 105, 106, 11, 12, 130, 131, 132, 133, 134, 135, 136, 137, 1380,
      1381, 1382, 1383, 17, 141, 142, 143, 144, 145, 146, 147, 148, 149, 15, 16,
      18, 1911, 1912, 19211, 19212, 19221, 19222, 19311, 19312, 19321, 19322,
      194, 195, 196, 197, 5131, 514, 43, 440, 4410, 44110, 44111, 44112, 44120,
      44121, 44122, 4413, 4414, 4415, 4416, 4417, 4418, 4419, 4015, 4025, 4510,
      442, 443, 444, 445, 446, 447, 448, 449, 450, 4511, 4512, 4513, 4514, 4515,
      4516, 4517, 4518, 4519, 452, 453, 454, 455, 456, 457, 458, 459, 198, 199,
      46, 47, 483, 482, 485, 489,
    ];

    private FRANCE_PASSIVE = [
      100, 101, 102, 103, 108, 109, 104, 105, 107, 107, 1057, 106, 11, 12, 19,
      13, 14, 1671, 1672, 1674, 1676, 229, 15, 16, 17, 18, 426, 45, 51, 52, 54,
      55, 56, 57, 58, 4191, 40, 42, 43, 44, 457, 269, 279, 404, 405, 4084, 41,
      46, 47, 50, 452, 453, 454, 194, 4886, 480, 482, 483, 484, 485, 487, 488,
      4886, 489, 476, 477, 4787,
    ];

    public GetAccountRoot(account: number, nbRootDigit: number): number {
      return parseInt(("" + account).substring(0, nbRootDigit), 10);
    }

    public isActive(account: number, amount: number): boolean {
      if (this.sessionService.isAuthenticated()) {
        let company = this.sessionService.session.company;
        let lengthAccount = this.sessionService.session.company.accountNumDepth;
        if (Math.pow(10, lengthAccount - 1) > account) {
          // account with a "0" at first char
          return false;
        } else if (company.country === "Luxembourg") {
          // LUXEMBOURG
          // 5131 & 514
          if (
            (this.GetAccountRoot(account, 4) === 5131 ||
              this.GetAccountRoot(account, 3) === 514) &&
            amount >= 0
          ) {
            return true;
          } else if (
            (this.GetAccountRoot(account, 4) === 5131 ||
              this.GetAccountRoot(account, 3) === 514) &&
            amount < 0
          ) {
            return false;
          } else {
            let index = 5;
            while (index > 0) {
              let accountRoot = this.GetAccountRoot(account, index);
              if (this.LUXEMBOURG_ACTIVE.indexOf(accountRoot) !== -1) {
                return true;
              }
              index--;
            }
            return false;
          }
        } else if (company.country === "France") {
          return !this.isPassive(account, amount) && !this.isPnl(account); //TODO implement the correct condition (lazy)
        } else if (company.country === "Luxembourg") {
          // LUXEMBOURG
          // 5131 & 514
          if (
            (this.GetAccountRoot(account, 4) === 5131 ||
              this.GetAccountRoot(account, 3) === 514) &&
            amount >= 0
          ) {
            return true;
          } else if (
            (this.GetAccountRoot(account, 4) === 5131 ||
              this.GetAccountRoot(account, 3) === 514) &&
            amount < 0
          ) {
            return false;
          } else {
            let index = 5;
            while (index > 0) {
              let accountRoot = this.GetAccountRoot(account, index);
              if (this.LUXEMBOURG_ACTIVE.indexOf(accountRoot) !== -1) {
                return true;
              }
              index--;
            }
            return false;
          }
        } else {
          // BELGIUM
          let accountRoot: number = this.GetAccountRoot(account, 2);
          let accountRootThreeDigit: number = this.GetAccountRoot(account, 3);
          return (
            (account === 498000 && amount >= 0) || // octopus fixed vat account
            account === 498054 || // octopus fixed vat account
            account === 49859000 ||
            account === 49855000 ||
            account === 49864000 || //ACTAB
            (20 <= accountRoot && accountRoot < 42) ||
            (50 <= accountRoot && accountRoot < 59) ||
            (490 <= accountRootThreeDigit && accountRootThreeDigit < 492) ||
            (496 <= accountRootThreeDigit &&
              accountRootThreeDigit < 497 &&
              amount > 0) ||
            (499 <= accountRootThreeDigit &&
              accountRootThreeDigit < 500 &&
              amount > 0)
          );
        }
      } else {
        return false;
      }
    }

    public isPassive(account: number, amount: number): boolean {
      if (this.sessionService.isAuthenticated()) {
        let company = this.sessionService.session.company;
        let lengthAccount = this.sessionService.session.company.accountNumDepth;
        if (Math.pow(10, lengthAccount - 1) > account) {
          // account with a "0" at first char
          return false;
        } else if (company.country === "Luxembourg") {
          // LUXEMBOURG
          // 5131 & 514
          if (
            (this.GetAccountRoot(account, 4) === 5131 ||
              this.GetAccountRoot(account, 3) === 514) &&
            amount >= 0
          ) {
            return false;
          } else if (
            (this.GetAccountRoot(account, 4) === 5131 ||
              this.GetAccountRoot(account, 3) === 514) &&
            amount < 0
          ) {
            return true;
          } else {
            let index: number = 5;
            while (index > 0) {
              // if (Company.LUXEMBOURG_PASSIVE.contains(this.GetAccountRoot(account, index))) {
              //     return true;
              // }
              let accountRoot = this.GetAccountRoot(account, index);
              if (this.LUXEMBOURG_PASSIVE.indexOf(accountRoot) !== -1) {
                return true;
              }
              index--;
            }
            return false;
          }
        } else if (company.country === "France") {
          // FRANCE
          // exlude from passive ///  169 ,
          let excluded =
            this.GetAccountRoot(account, 3) === 169 ||
            this.GetAccountRoot(account, 8) === 40199999 ||
            this.GetAccountRoot(account, 8) === 41199999;

          let onlyIfDebit =
            this.GetAccountRoot(account, 3) === 109 ||
            this.GetAccountRoot(account, 2) === 40 ||
            this.GetAccountRoot(account, 2) === 41 ||
            this.GetAccountRoot(account, 2) === 42 ||
            this.GetAccountRoot(account, 2) === 43 ||
            this.GetAccountRoot(account, 2) === 44 ||
            this.GetAccountRoot(account, 2) === 45 ||
            this.GetAccountRoot(account, 2) === 46 ||
            this.GetAccountRoot(account, 2) === 47 ||
            this.GetAccountRoot(account, 2) === 18 ||
            this.GetAccountRoot(account, 3) === 480 ||
            this.GetAccountRoot(account, 3) === 482 ||
            this.GetAccountRoot(account, 3) === 483 ||
            this.GetAccountRoot(account, 3) === 484 ||
            this.GetAccountRoot(account, 3) === 485 ||
            this.GetAccountRoot(account, 3) === 488 ||
            this.GetAccountRoot(account, 3) === 489 ||
            this.GetAccountRoot(account, 2) === 50 ||
            this.GetAccountRoot(account, 2) === 51 ||
            this.GetAccountRoot(account, 2) === 52 ||
            this.GetAccountRoot(account, 2) === 54 ||
            this.GetAccountRoot(account, 2) === 55 ||
            this.GetAccountRoot(account, 2) === 56 ||
            this.GetAccountRoot(account, 2) === 57 ||
            this.GetAccountRoot(account, 2) === 58;

          let isPostponedAccount = this.isIncludeInRoot(account, 149);
          if (isPostponedAccount) {
            return true;
          }

          if (!excluded) {
            if (onlyIfDebit) {
              if (amount <= 0) {
                return true;
              } else {
                return false;
              }
            }
            let index: number = 4;
            while (index > 0) {
              let accountRoot = this.GetAccountRoot(account, index);
              if (this.FRANCE_PASSIVE.indexOf(accountRoot) !== -1) {
                return true;
              }
              index--;
            }
          } else {
            return false;
          }
        } else {
          // BELGIUM
          let accountRoot: number = this.GetAccountRoot(account, 2);
          let accountRootThreeDigit: number = this.GetAccountRoot(account, 3);
          return (
            (account === 498000 && amount < 0) || // octopus fixed vat account
            account === 498059 ||
            account === 498063 ||
            account === 498064 ||
            account === 498055 || // octopus fixed vat account
            account === 49854000 ||
            account === 49856000 ||
            account === 49863000 || //ACTAB
            (10 <= accountRoot && accountRoot < 20) ||
            (42 <= accountRoot && accountRoot < 49) ||
            (492 <= accountRootThreeDigit && accountRootThreeDigit < 494) ||
            (496 <= accountRootThreeDigit &&
              accountRootThreeDigit < 497 &&
              amount <= 0) ||
            (499 <= accountRootThreeDigit &&
              accountRootThreeDigit < 500 &&
              amount <= 0)
          );
        }
      } else {
        return false;
      }
    }

    public isIncludeInRoot(account: number, root: number): boolean {
      let lengthAccount = this.sessionService.session.company.accountNumDepth;
      if (Math.pow(10, lengthAccount - 1) <= account) {
        let reg: RegExp = new RegExp("^" + root);
        //let reg: RegExp = new RegExp("^" + root + ".(\\d)*");
        let matchResult: any[] = ("" + account).match(reg);

        return matchResult && matchResult.length > 0;
      } else {
        return false;
      }
    }

    public isSale(account: number): boolean {
      return this.isIncludeInRoot(account, 400);
    }

    public isPurchase(account: number): boolean {
      return this.isIncludeInRoot(account, 440);
    }

    public isIncludeInRootArr(account: number, rooArr: any[]): boolean {
      for (let i = 0; i < rooArr.length; i++) {
        if (this.isIncludeInRoot(account, rooArr[i])) {
          return true;
        }
      }
      return false;
    }
  }
}

angular
  .module("app")
  .service("AccountTools", app.functionality.common.utils.AccountTools);
