/// <reference path="../../_app.ts" />

module app.functionality.liquidity.vat {
  export class VATController {
    public bookyears: any[];
    public bookyearSelected: any;
    public periods: any[];
    public periodSelected: any;
    public trimesters: number[];
    public liquidityService;
    public scope: any;
    public initialData: any;
    public vatOverview: any;
    public currentTrimester: any;
    public gridData: any;
    public gridOptions: any;
    private typeOfVat: number;
    private vatRightText1A: any;
    private vatRightText1B: any;
    private vatRightText2A: any;
    private vatRightText2B: any;
    private vatRightText3: any;
    private nextVat: Date;
    public displayAMount: any;
    public r_thirds: any;
    public isQuarterly: any;

    static $inject = [
      "LiquidityService",
      "$scope",
      "r_vatOverview",
      "r_vatBookyears",
      "SessionService",
      "r_typeOfVat",
      "r_thirds",
      "$uibModal",
      "ENV",
      "$window",
      "$translate",
      "$translatePartialLoader",
    ];

    constructor(
      liquidity: LiquidityService,
      $scope: ng.IScope,
      r_vatOverview: any,
      r_vatBookyears: any,
      public sessionService: app.functionality.common.session.SessionService,
      r_typeOfVat: any,
      r_thirds: any,
      private $uibModal: any,
      private ENV: app.config.constants.environment.ConstantEnv,
      public $window: angular.IWindowService,
      private $translate: ngt.ITranslateService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService
    ) {
      $translatePartialLoader.addPart("customer/liquidity");

      this.isQuarterly = this.sessionService.session.company.vatMode === 1;
      this.liquidityService = liquidity;
      this.r_thirds = r_thirds;
      this.typeOfVat = r_typeOfVat;
      // *_typeOfVat varie en fonction de si on est dans ventes (1) ou achats (2)

      if (this.sessionService.session.company.importInfo.state !== 0) {
        if (r_vatBookyears !== null) {
          this.bookyears = r_vatBookyears;
          this.initDataFilters();
          this.setGridData(this.periodSelected);
        }
      }

      if (r_vatOverview !== null) {
        this.vatOverview = r_vatOverview;
        this.displayAMount = Math.abs(r_vatOverview[4]);
        if (this.vatOverview[4] >= 0) {
          //vatToRetrieve
          this.vatRightText1A = "LIQUIDITY.VAT.OVERVIEW.vatToRetrieve1A";
          this.vatRightText1B = "LIQUIDITY.VAT.OVERVIEW.vatToRetrieve1B";
          this.vatRightText2A = "LIQUIDITY.VAT.OVERVIEW.vatToRetrieve2A";
          this.vatRightText2B = "LIQUIDITY.VAT.OVERVIEW.vatToRetrieve2B";
          this.vatRightText3 = "LIQUIDITY.VAT.OVERVIEW.vatToRetrieve3";
        } else {
          //vatToPay
          this.vatRightText1A = "LIQUIDITY.VAT.OVERVIEW.vatToPaid1A";
          this.vatRightText1B = "LIQUIDITY.VAT.OVERVIEW.vatToPaid1B";
          this.vatRightText2B = "LIQUIDITY.VAT.OVERVIEW.vatToPaid2B";
          this.vatRightText2A = "LIQUIDITY.VAT.OVERVIEW.vatToPaid2A";
          this.vatRightText3 = "";
        }
      }

      let nbrDate = 20;

      if (
        this.sessionService.session.company &&
        this.sessionService.session.company.country != null
      ) {
        if (this.sessionService.session.company.country === "Luxembourg") {
          nbrDate = 15; // day of the vat for luxembourg
        }
        if (this.sessionService.session.company.country === "France") {
          nbrDate = 1; // day of the vat for luxembourg
        }
      }

      if (
        this.sessionService.session.company.importInfo !== null &&
        this.sessionService.session.company.importInfo.importDate !== null
      ) {
        let indice = this.whichQuarterAreWe(
          new Date(this.sessionService.session.company.importInfo.importDate),
          nbrDate
        );
        if (indice === 0) {
          indice = 4;
        }
        this.currentTrimester = "LIQUIDITY.VAT.OVERVIEW.subtitle_" + indice;
        this.nextVat = this.getVATDate(
          new Date(this.sessionService.session.company.importInfo.importDate),
          nbrDate
        );
      } else {
        let indice = this.whichQuarterAreWe(new Date(), nbrDate);
        if (indice === 0) {
          indice = 4;
        }
        this.currentTrimester = "LIQUIDITY.VAT.OVERVIEW.subtitle_" + indice;
        this.nextVat = this.getVATDate(new Date(), nbrDate);
      }

      this.scope = $scope;
    }

    public getCountryFromVat(vatNumber) {
      if (vatNumber.length === 10) {
        // LU12345678
        return "Luxembourg";
      } else if (vatNumber.length === 14) {
        //BE1234.123.123
        return "Belgique";
      } else {
        return null;
      }
    }

    public getVATDate(importDate: Date, limitDayOfQuarter) {
      let q1 = new Date(importDate.getFullYear() + "-01-" + limitDayOfQuarter);
      let q2 = new Date(importDate.getFullYear() + "-04-" + limitDayOfQuarter);
      let q3 = new Date(importDate.getFullYear() + "-07-" + limitDayOfQuarter);
      let q4 = new Date(importDate.getFullYear() + "-09-" + limitDayOfQuarter);
      let q5 = new Date(
        importDate.getFullYear() + 1 + "-01-" + limitDayOfQuarter
      );
      let result = null;
      let mom = importDate;
      if (mom < q1) {
        result = q1;
      } else if (mom < q2) {
        result = q2;
      } else if (mom < q3) {
        result = q3;
      } else if (mom < q4) {
        result = q4;
      } else if (mom < q5) {
        result = q5;
      }
      return result;
    }

    public initDataFilters(newBookyearSelected?) {
      if (angular.isDefined(newBookyearSelected)) {
        this.bookyearSelected = newBookyearSelected;
        this.generatePeriods();
      } else {
        this.bookyearSelected = this.bookyears[this.bookyears.length - 1];
        this.generatePeriods();
      }
    }

    // public selectRelatedBookyear(tempDate: Date) {
    //   let foundBookyear = null;
    //   let isQuarterly = this.sessionService.session.company.vatMode === 1;
    //   for (let i = 0; i < this.bookyears.length; i++) {
    //     let bookyear = this.bookyears[i];
    //     if (isQuarterly) {
    //       let quarterlyArr = bookyear.quarterlyArr;
    //       for (let j = 0; j < quarterlyArr.length; j++) {
    //         let quarterly = quarterlyArr[j];
    //         if (this.isPeriodsRelatedToDate(tempDate, quarterly.periodArr)) {
    //           foundBookyear = bookyear;
    //           break;
    //         }
    //       }
    //     } else {
    //       if (this.isPeriodsRelatedToDate(tempDate, bookyear.periodArr)) {
    //         foundBookyear = bookyear;
    //         break;
    //       }
    //     }
    //   }

    //   return foundBookyear;
    // }

    // public isPeriodsRelatedToDate(tempDate: Date, periodArr: any[]) {
    //   let isPeriodsRelatedToDateValue = false;
    //   for (let i = 0; i < periodArr.length; i++) {
    //     let period = periodArr[i];
    //     if (this.isPeriodRelatedToDate(tempDate, period)) {
    //       isPeriodsRelatedToDateValue = true;
    //       break;
    //     }
    //   }
    //   return isPeriodsRelatedToDateValue;
    // }

    // public isPeriodRelatedToDate(tempDate: Date, period) {
    //   return (
    //     period.year === tempDate.getFullYear() &&
    //     period.monthIndex === tempDate.getMonth()
    //   );
    // }

    public generatePeriods() {
      let isQuarterly = this.sessionService.session.company.vatMode === 1;

      if (isQuarterly) {
        this.periods = this.bookyearSelected.quarterlyArr;
        let trimIndex = 1; // to add the number
        this.periods.forEach(function (period) {
          period.trimIndex = "Trim : " + trimIndex;
          trimIndex++;
        });
      } else {
        // not quarterly, but by months/periods
        this.periods = this.bookyearSelected.periodArr;
        let trimIndex = 1; // to add the number
        this.periods.forEach(function (period) {
          period.trimIndex = "MONTH." + period.monthIndex;
          trimIndex++;
        });
      }
      let bookyearSelectedID = this.periods[0].bookyear;
      let currBookyear = this.sessionService.session.company.currBookyear;
      let tempDate: Date;
      if (
        this.sessionService.session.company.importInfo !== null &&
        this.sessionService.session.company.importInfo.importDate !== null
      ) {
        tempDate = new Date(
          this.sessionService.session.company.importInfo.importDate
        );
      } else {
        tempDate = new Date();
      }

      let nbrDate = 20;

      if (
        this.sessionService.session.company &&
        this.sessionService.session.company.country != null
      ) {
        if (this.sessionService.session.company.country === "Luxembourg") {
          nbrDate = 15; // day of the vat for luxembourg
        }
        if (this.sessionService.session.company.country === "France") {
          nbrDate = 1; // day of the vat for France
        }
      }

      if (isQuarterly) {
        if (currBookyear === bookyearSelectedID) {
          let quarter: number = this.whichQuarterAreWe(tempDate, nbrDate);

          this.periodSelected = this.periods[quarter];
        } else {
          // select the last period of the period array
          this.periodSelected = this.periods[this.periods.length - 1];
        }
      } else {
        // if the year selected is the one happening right now
        if (currBookyear === bookyearSelectedID) {
          let monthIndex = tempDate.getMonth();
          for (let i = 0; i < this.periods.length; i++) {
            let period = this.periods[i];
            if (period.monthIndex === monthIndex) {
              this.periodSelected = period;
              break;
            }
          }

          if (!this.periodSelected) {
            this.periodSelected = this.periods[this.periods.length - 1];
          }
        } else {
          // select the last period of the period array
          this.periodSelected = this.periods[this.periods.length - 1];
        }
      }
    }

    public whichQuarterAreWe(date: Date, limitDayOfQuarter) {
      let q1 = moment().set({
        year: date.getFullYear(),
        month: 0,
        day: limitDayOfQuarter,
      });
      let q2 = moment().set({
        year: date.getFullYear(),
        month: 3,
        day: limitDayOfQuarter,
      });
      let q3 = moment().set({
        year: date.getFullYear(),
        month: 6,
        day: limitDayOfQuarter,
      });
      let q4 = moment().set({
        year: date.getFullYear(),
        month: 9,
        day: limitDayOfQuarter,
      });
      let q5 = moment().set({
        year: date.getFullYear() + 1,
        month: 0,
        day: limitDayOfQuarter,
      });

      let momentDate = moment().set({
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
      });
      let result = 0;
      if (momentDate.isBefore(q1)) {
        // before the 20/01 ==> Q4
        result = 0;
      } else if (momentDate.isBefore(q2)) {
        // before the 20/4 ==> q1
        result = 1;
      } else if (momentDate.isBefore(q3)) {
        result = 2;
      } else if (momentDate.isBefore(q4)) {
        result = 3;
      } else if (momentDate.isBefore(q5)) {
        // before the 20/01/year+1 ==> q4
        result = 0;
      }
      return result;
    }

    public setGridData(newPeriodSelected) {
      let self = this;
      let sessionID = this.sessionService.session.sessionID;
      let isQuarterly = this.sessionService.session.company.vatMode === 1;
      let pArrIndex;
      if (isQuarterly) {
        pArrIndex = newPeriodSelected.periodArr;
      } else {
        pArrIndex = [newPeriodSelected];
      }

      self.liquidityService
        .getDetailVatOperation(sessionID, this.typeOfVat, pArrIndex)
        .then(function (data) {
          let returnData = data.plain();
          // set the fullName Third thanks to the reference "third" inside the data
          _.forEach(returnData.data, function (file) {
            let short = file.third;
            for (let j = 0, lenJ = self.r_thirds.length; j < lenJ; j++) {
              let third = self.r_thirds[j];
              if (third.reference === short) {
                file.thirdFullName = third.fullName;
                break;
              }
            }
          });

          self.gridData = returnData.data;
          self.gridOptions = self.setGridOptions(self.gridData);
        })
        .catch(function (error) {
          console.error("error in getDetailVatOperation");
          console.error(error);
        });
    }

    public changeBookyear(bookyearSelected) {
      this.initDataFilters(bookyearSelected);
      this.setGridData(this.periodSelected);
    }

    public openPDF(row) {
      let sessionID = this.sessionService.session.sessionID;
      let companyKey = this.sessionService.session.company.key;

      this.$window.open(
        this.ENV.baseUrl +
          "/FileMgr?sessionID=" +
          sessionID +
          "&companyKey=" +
          companyKey +
          "&journal=" +
          row.journalType +
          "&period=" +
          row.period +
          "&doc=" +
          row.docNumber +
          "&bookyear=" +
          row.bookyear,
        "_blank"
      );
    }

    public newTicket(row) {
      console.error(row.entity);
      if (row.entity != null) {
        row.entity.typeOfThird = this.typeOfVat;
      }
      this.$uibModal.open({
        templateUrl: "tpl/website/modals/newTicketModal.html",
        controller: "NewTicketModalController",
        controllerAs: "ticket",
        size: "md",
        backdrop: "static",
        keyboard: false,
        resolve: {
          r_data: function () {
            return {
              row: row,
            };
          },
        },
      });
    }

    public smallDetailModal(row) {
      let sessionID = this.sessionService.session.sessionID;
      let companyKey = this.sessionService.session.company.key;

      this.$uibModal.open({
        templateUrl: "tpl/website/modals/smallDetailModal.html",
        controller: "SmallDetailModalController",
        controllerAs: "smallDetail",
        size: "sm",
        resolve: {
          r_data: function () {
            return {
              row: row,
              sessionID: sessionID,
              companyKey: companyKey,
            };
          },
        },
      });
    }

    public setGridOptions(data) {
      return {
        columnDefs: [
          {
            field: "journalType",
            displayName: "LIQUIDITY.VAT.journal",
            width: "10%",
            enableFiltering: true,
            headerCellFilter: "translate",
            headerCellClass: "header-title",
            cellClass: "text-center",
            sort: {
              direction: "asc",
              priority: 0,
            },
          },
          {
            field: "docNumber",
            displayName: "LIQUIDITY.VAT.docNumber",
            width: "10%",
            enableFiltering: false,
            headerCellFilter: "translate",
            headerCellClass: "header-title",
            cellClass: "text-center",
            sort: {
              direction: "asc",
              priority: 1,
            },
          },
          {
            field: "operationDate",
            displayName: "LIQUIDITY.VAT.date",
            width: "10%",
            enableFiltering: true,
            headerCellFilter: "translate",
            headerCellClass: "header-title",
            cellClass: "text-center",
            cellFilter: "date:'dd/MM/yy'",
            sort: {
              direction: "asc",
              priority: 2,
            },
          },
          {
            field: "comment",
            displayName: "LIQUIDITY.VAT.description",
            cellTemplate: "tpl/grid/VAT/VATcomment.html",
            headerCellClass: "header-title",
            enableFiltering: true,
            headerCellFilter: "translate",
            width: "20%",
          },
          {
            field: "thirdFullName",
            displayName:
              this.typeOfVat == 1
                ? "LIQUIDITY.VAT.client"
                : "LIQUIDITY.VAT.supplier",
            cellTemplate: "tpl/grid/VAT/VATthird.html",
            headerCellClass: "header-title",
            width: "20%",
            enableFiltering: true,
            cellClass: "text-left",
            headerCellFilter: "translate",
          },
          {
            field: "amount",
            displayName: "LIQUIDITY.VAT.EVAT",
            enableFiltering: false,
            headerCellClass: "header-title",
            width: "10%",
            headerCellFilter: "translate",
            cellFilter: "number:2",
            cellClass: "text-right",
            cellTemplate:
              "<div class='ui-grid-cell-contents'><span ng-if='grid.appScope.vat.typeOfVat === 2'>{{ - COL_FIELD - row.entity.vatAmount | number:2 }}</span>" +
              "<span ng-if='grid.appScope.vat.typeOfVat === 1'>{{ COL_FIELD + row.entity.vatAmount CUSTOM_FILTERS }}</span></div>",
          },
          {
            field: "vatAmount",
            displayName: "LIQUIDITY.VAT.VAT",
            enableFiltering: false,
            headerCellClass: "header-title",
            width: "10%",
            headerCellFilter: "translate",
            cellClass: "text-right element",
            cellTemplate:
              "<div class='ui-grid-cell-contents'><span ng-if='grid.appScope.vat.typeOfVat === 2'>{{ COL_FIELD | number:2 }}</span>" +
              "<span ng-if='grid.appScope.vat.typeOfVat === 1'>{{ - COL_FIELD CUSTOM_FILTERS }}</span></div>",
          },
          {
            field: "allAmount",
            displayName: "LIQUIDITY.VAT.IVAT",
            enableFiltering: false,
            headerCellClass: "header-title",
            width: "10%",
            headerCellFilter: "translate",
            cellClass: "text-right",
            cellTemplate:
              "<div class='ui-grid-cell-contents'><span ng-if='grid.appScope.vat.typeOfVat === 2'>{{-row.entity.amount | number:2}}</span>" +
              "<span ng-if='grid.appScope.vat.typeOfVat === 1'>{{row.entity.amount | number:2}}</span></div>",
          },
        ],
        data: data,
        enableFiltering: false,
        enableColumnMenus: false,
        enableSorting: true,
        rowHeight: 40,
        columnFooterHeight: 40,
        virtualizationThreshold: data.length,
        flatEntityAccess: false,
        rowTemplate:
          "<div ng-click='grid.appScope.fnOne(row)' " +
          "ng-repeat='col in colContainer.renderedColumns track by col.colDef.name'" +
          " class='ui-grid-cell myRowTemplate' ui-grid-cell></div>",
      };
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller("VATController", app.functionality.liquidity.vat.VATController);
