/// <reference path="../../../_app.ts" />

module app.modules.helper.directive.helper {

    export interface IHelperDirectiveScope extends ng.IScope {
        position: string;
        content: any;
    }

    export class HelperDirective implements ng.IDirective {

        restrict = "E";
        replace = true;
        scope = false;
        
        public content;
        public position;

        /*scope = {
            position: "@position",
            content: "@content"
        };*/

        constructor(private $translate: ngt.ITranslateService, public $rootScope: any) {
        }

        template = function (element, attrs) {

            if (!angular.isDefined(attrs.position)) {
                attrs.position = "top-right";
            }

            if (!angular.isDefined(attrs.icon)) {
                attrs.icon = "glyphicon glyphicon-question-sign helper img-circle";
            }

            this.content = attrs.content;

            return "<span class='" + attrs.icon + "' tooltip-placement='" + attrs.position +
                "' uib-tooltip='" + this.content + "' tooltip-append-to-body='true' aria-hidden='true'></span>";

        };

        link = (scope: IHelperDirectiveScope, elem: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) => {

            elem.on("mouseenter", function() {
                elem.addClass("hoverIcon");
            });
            elem.on("mouseleave", function() {
                elem.removeClass("hoverIcon");
            });

        }

        static factory(): ng.IDirectiveFactory {
            const directive = ($translate: ngt.ITranslateService, $rootScope) => new HelperDirective($translate, $rootScope);
            directive.$inject = ["$translate", "$rootScope"];

            return directive;
        }
    }
}

angular.module("helperModule").directive("helper", app.modules.helper.directive.helper.HelperDirective.factory());
