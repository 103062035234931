/// <reference path="../../_app.ts" />

module app.functionality.budgetInsight {

    export class BudgetInsightService {
        private rest: restangular.IService;

        static $inject =  ["RestService","$q", "RESTAPI"];
        constructor(restService: app.functionality.common.rest.RestService,
                    private $q: ng.IQService,
                    private RESTAPI: app.config.constants.ConstantRestApi) {
            this.rest = restService.getRoot();
        }

        getAccounts = (companyKey: string,sessionID: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.budgetInsight.base)
                .all(this.RESTAPI.services.budgetInsight.getAccounts)
                .one(sessionID +"&"+companyKey).get();
        }

        getTransactionsForAccount = (sessionID: string,companyKey: string,accountKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.budgetInsight.base)
                .all(this.RESTAPI.services.budgetInsight.getTransactionsForAccount)
                .one(sessionID +"&"+companyKey+"&"+accountKey).get();
        }

        updateData = (sessionID: string,companyKey: string): ng.IPromise<any> => {
            let defered = this.$q.defer();
            let self = this;

            let request = this.rest.all(this.RESTAPI.services.budgetInsight.base).all(this.RESTAPI.services.budgetInsight.downloadTask);

            request
                .customPOST({
                    sessionID: sessionID,
                    companyKey: companyKey
                })
                .then(function(data) {
                    defered.resolve(data);
                })
                .catch(function(error) {
                    console.error(error);
                    console.error("error ocurred on updateData");
                    defered.reject(error);
                });
            return defered.promise;
        }

    }

}

angular.module("app").service("BudgetInsightService",  app.functionality.budgetInsight.BudgetInsightService);