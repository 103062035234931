/**
 * Created by François on 24-10-16.
 */
/// <reference path="../../_app.ts" />

module app.functionality.parameters {

    export class NotificationsService {

        private rest: restangular.IService;

        static $inject =  ["RestService", "RESTAPI"];
        constructor(restService: app.functionality.common.rest.RestService, private RESTAPI: app.config.constants.ConstantRestApi) {
            this.rest = restService.getRoot();
        }

        add = (userKey: string, data: any) => {
            this.rest.all(this.RESTAPI.services.user.notifications).one("id", userKey).customPOST(data)
                .catch(function(error) {
                    console.error("error on adding");
                    console.error(error);
                });
        }
    }

}

angular.module("app").service("NotificationsService", app.functionality.parameters.NotificationsService);