/// <reference path="../_app.ts" />

module app.model {

    // import model.AccountingEntry;
    // import model.Company;
    // import mx.collections.ArrayCollection;
    // import tools.AccountTools;

    import Company = app.model.Company;
    import AccountingEntry = app.model.AccountingEntry;

    export class Sale {

        public key: string;
        public status: number;
        private _accountingEntryArr: Array<any>;
        public parentBudgetKey: string;

        constructor() {
        }

        public hasAttachedDoc(c: Company): boolean {
            return false;
        }

        public getAttachedAcc(c: Company): AccountingEntry {
            return null;
        }

        public getMainMeaningAcc(c: Company): AccountingEntry {
            return null;
        }

        public accountingEntryArr(): Array<any> {
            return this._accountingEntryArr;
        }

        // public accountingEntryArr(value: Array): void {
        //     if (value) {
        //         foreach (entry: AccountingEntry in value) {
        //             entry.operation = this;
        //         }
        //     }
        //
        //     this._accountingEntryArr = value;
        // }
        //
        // public getCorrespondingCashEntry(): Array {
        //     // result : Array = new ArrayCollection();
        //     foreach (entry :AccountingEntry in accountingEntryArr) {
        //         if (AccountTools.isIncludeInRoot(entry.account, 5) && entry.status == 1) {
        //             result.addItem(entry);
        //         }
        //     }
        //     return result;
        // }
        //
        // public updateDueDate(newOp:Operation): void {
        //
        // }

    }

}