/// <reference path="../../_app.ts" />

module app.functionality.login {

    export class fallbackDirective implements ng.IDirective {

        restrict = "A";

        link = (scope: ng.IScope, ngModel: ng.INgModelController, attrs: ng.IAttributes, ctrl: any) => {

            let fallback = {
                link: function postLink(scope, iElement, iAttrs) {
                    iElement.bind("error", function() {
                        angular.element(this).attr("src", iAttrs.fallback);
                    });
                }
            };
            return fallback;
        }

        static factory() {
            const directive = () => new fallbackDirective();
            return directive;
        }
    }
}

angular.module("app").directive("fallback", app.functionality.login.fallbackDirective.factory());
