/// <reference path="../_app.ts" />

module app.model {

    export class Descriptor {

        /*
         * the key of the related object described by this instance
         */
        public relatedKey: string;

        /*
         * the name of the related object described by this instance. Could be empty
         */
        public relatedName: string;

        /*
         * the collection where we can get the related object described by this instance using the relatedKey
         */
        public relatedCollection: string;

        constructor() {
        }

    }
}
