/// <reference path="../../_app.ts" />

module app.model {
  export class Invoice {
    classStructure: any = {
      uploadDate: Date,
    };

    public key: string;
    public name: string;
    public uploadDate: Date;
    public numMonth: number;
    public uploader: string;
    public note: string;
    public companyKey: string;
    public sentStatus: number;
    public fileKey: string;
    public mongoFileKey: string;
    public url: string;
    public thumbnailLink: string;
    public uploadFolderKey: string;
    public uploaderKey: string;
    public s3Path: string;
    public sendDate: Date;
    public thumbnailS3Link: string;
    public invoiceKey: string;
    public title: string;

    constructor() {}

    // get key(): string {
    //     return this._key;
    // }
    //
    // set key(value: string) {
    //     this._key = value;
    // }
    //
    // get companyKey(): string {
    //     return this._companyKey;
    // }
    //
    // set companyKey(value: string) {
    //     this._companyKey = value;
    // }
    //
    // get sentStatus(): number {
    //     return this._sentStatus;
    // }
    //
    // set sentStatus(value: number) {
    //     this._sentStatus = value;
    // }
    //
    // get fileKey(): string {
    //     return this._fileKey;
    // }
    //
    // set fileKey(value: string) {
    //     this._fileKey = value;
    // }
    //
    // get mongoFileKey(): string {
    //     return this._mongoFileKey;
    // }
    //
    // set mongoFileKey(value: string) {
    //     this._mongoFileKey = value;
    // }
    //
    // get uploadDate(): Date {
    //     return this._uploadDate;
    // }
    //
    // set uploadDate(value: Date) {
    //     this._uploadDate = value;
    // }
    //
    // get name() : string {
    //     return this._name;
    // }
    //
    // set name(value : string) {
    //     this._name = value;
    // }
    //
    // get numMonth() : number {
    //     return this._numMonth;
    // }
    //
    // set numMonth(value : number) {
    //     this._numMonth = value;
    // }
    //
    // get uploader() : string {
    //     return this._uploader;
    // }
    //
    // set uploader(value : string) {
    //     this._uploader = value;
    // }
    //
    // get note() : string {
    //     return this._note;
    // }
    //
    // set note(value : string) {
    //     this._note = value;
    // }
    //
    // get url(): string {
    //     return this._url;
    // }
    //
    // set url(value: string) {
    //     this._url = value;
    // }
    //
    // get thumbnail(): string {
    //     return this._thumbnail;
    // }
    //
    // set thumbnail(value: string) {
    //     this._thumbnail = value;
    // }
    //
    // get uploadFolderKey(): string {
    //     return this._uploadFolderKey;
    // }
    //
    // set uploadFolderKey(value: string) {
    //     this._uploadFolderKey = value;
    // }
    //
    // get uploaderKey(): string {
    //     return this._uploaderKey;
    // }
    //
    // set uploaderKey(value: string) {
    //     this._uploaderKey = value;
    // }
  }
}
