/// <reference path="../../../../_app.ts" />

module app.functionality.invoicing.controllers.modal {

    import InvoicingInformation = app.model.invoicing.InvoicingInformation;
    import InvoicingClient = app.model.invoicing.InvoicingClient;
    import Invoice = app.model.invoicing.Invoice;

    export class SendMailModalController implements angular.IController {

        private client: InvoicingClient;
        private invgInfo: InvoicingInformation;
        private invoice: Invoice;

        private fields: {
            "sendingEmail": string,
            "sendingName": string,
            "emailAddress": string,
            "mailSubject": string,
            "mailBody": string
        };
        private fieldsName = ["sendingEmail", "sendingName", "emailAddress", "mailSubject", "mailBody"];

        private modifyMailSubject = false;
        private modifyMailBody = false;

        private invoiceKey: string = null;

        private invalidInputBooleans = {};

        private isSending: boolean = false;

        private lang: string;

        $onInit() {}

        static $inject = [
            "r_client",
            "r_invgInfo",
            "r_invoice",
            "r_invoiceKey",
            "$compile",
            "$uibModalInstance",
            "$translatePartialLoader",
            "$scope",
            "$timeout",
            "InvoicingEmailService",
            "SessionService"
        ];

        constructor (
            r_client: InvoicingClient,
            r_invgInfo: InvoicingInformation,
            r_invoice: Invoice,
            r_invoiceKey: string,
            private $compile: ng.ICompileService,
            private $uibModalInstance,
            private $translatePartialLoader: ngt.ITranslatePartialLoaderService,
            private $scope,
            private $timeout: ng.ITimeoutService,
            private invoicingEmailService: services.InvoicingEmailService,
            private sessionService: app.functionality.common.session.SessionService
        ) {
            this.$translatePartialLoader.addPart('customer/invoicing');

            this.client = new InvoicingClient();
            if (r_client != null) {
                angular.copy(r_client, this.client);
            } else {    // If sending a test mail

            }

            this.invgInfo = new InvoicingInformation();
            angular.copy(r_invgInfo, this.invgInfo);

            this.invoice = new Invoice();
            if (r_invoice != null) {
                angular.copy(r_invoice, this.invoice);
                this.invoice.invoiceDate = new Date(this.invoice.invoiceDate);
                if (this.invoice.dueDate != null) {
                    this.invoice.dueDate = new Date(this.invoice.dueDate);
                }
            } else {    // If sending a test mail
                this.invoice.type = "invoice";
                this.invoice.invoiceNumber = 100;
                this.invoice.invoiceDate = new Date();
                this.invoice.dueDate = new Date();
                this.invoice.exclVatTotal = 82.64;
                this.invoice.inclVatTotal = 99.99;
                this.invoice.structuredCommunication = new Date().getFullYear().toString() + this.invoice.invoiceNumber;
            }

            this.lang = this.sessionService.session.member.language.toLowerCase();

            if (r_invoiceKey != null) {
                this.fields = {
                    sendingEmail: this.invgInfo.sendingEmail,
                    sendingName: this.invgInfo.sendingName,
                    emailAddress: r_client.emailAddress,
                    mailSubject: this.invgInfo.localizedValues[this.lang].mailSubject,
                    mailBody: this.invgInfo.localizedValues[this.lang].mailBody
                };

                this.invoiceKey = r_invoiceKey;
            } else {    // If we are sending a test mail from InvoicingConfiguration
                this.fields = {
                    sendingEmail: this.invgInfo.sendingEmail,
                    sendingName: this.invgInfo.sendingName,
                    emailAddress: "",
                    mailSubject: this.invgInfo.localizedValues[this.lang].mailSubject,
                    mailBody: this.invgInfo.localizedValues[this.lang].mailBody
                };
            }

            // Timeout needed to avoid trouble with compiling
            $timeout(0).then(() => {
                this.compileInputVar('mailSubject');
                this.compileInputVar('mailBody');
            });
        }

        /**
         * Change the default values following the defined default variables
         */
        changeLanguage() {
            this.fields.mailSubject = this.invgInfo.localizedValues[this.lang].mailSubject;
            this.fields.mailBody = this.invgInfo.localizedValues[this.lang].mailBody;
            this.compileInputVar('mailSubject');
            this.compileInputVar('mailBody');
        }

        /**
         * Replaces variables with interpolated values
         * @param inputName The name of the input
         */
        compileInputVar(inputName: string): void {
            const variables = {
                mailSubject: [
                    "companyName",
                    "type",
                    "invoiceNumber"
                ],
                mailBody: [
                    "companyName",
                    "type",
                    "invoiceNumber",
                    "invoiceDate",
                    "dueDate",
                    "exclVatTotal",
                    "inclVatTotal",
                    "structuredCommunication",
                    "iban"
                ]
            };
            const re = new RegExp("\\[([a-zA-z]+)\\]", "g");

            let div = document.getElementById(inputName + "Div");
            let matchArr;
            let replacedText = this.fields[inputName];

            while ((matchArr = re.exec(this.fields[inputName])) !== null) {
                if (variables[inputName].indexOf(matchArr[1]) != -1) {
                    let value;
                    switch (matchArr[1]) {
                        case "companyName":
                            value = "{{ mailMdCtrl.sessionService.session.company.name }}";
                            break;
                        case "type":
                            value = "{{ 'INVOICING.SHARED.' + mailMdCtrl.invoice.type | translate }}";
                            break;
                        case "invoiceNumber":
                            value = "{{ mailMdCtrl.invoice.invoiceNumber }}";
                            break;
                        case "dueDate":
                        case "invoiceDate":
                            value = "{{ mailMdCtrl.invoice." + matchArr[1] + " | date : 'shortDate' }}";
                            break;
                        case "exclVatTotal":
                        case "inclVatTotal":
                            value = "{{ mailMdCtrl.invoice." + matchArr[1] + " | number : 2 }}";
                            break;
                        case "structuredCommunication":
                            value = "{{ mailMdCtrl.invoice.formatSC() }}";
                            break;
                        case "iban":
                            value = "{{ mailMdCtrl.invgInfo.iban }}";
                            break;
                    }
                    replacedText = replacedText.replace(matchArr[0], value);
                }
                div.innerHTML = replacedText;
                if (inputName == 'mailBody') {
                    div.innerHTML = div.innerHTML.replace(/\n/gi, "<br/>");
                }
                this.$compile(div)(this.$scope);
            }
        }

        addVariableToInput(variable: string, inputName: string): void {
            let input: HTMLTextAreaElement = document.getElementById(inputName + "Input") as HTMLTextAreaElement;
            if (input.value[input.selectionStart - 1] != undefined) {
                variable = " " + variable;
            }
            if (input.value[input.selectionEnd] != undefined && input.value[input.selectionEnd] != " ") {
                variable += " ";
            }

            if (this.fields[inputName]) {
                let before = this.fields[inputName].substring(0, input.selectionStart);
                let after = this.fields[inputName].substring(input.selectionEnd);
                this.fields[inputName] = before + variable + after;
            } else {
                this.fields[inputName] = variable;
            }
            this.compileInputVar(inputName);
        }

        /**
         * Verifies and validates each field of the form
         */
        validateForm() {
            let errorFlag: boolean = false;
            let errorsArr: string[] = [];

            this.fieldsName.forEach(name => {
                if (! this.fields[name]) {
                    errorFlag = true;
                    errorsArr.push(name);
                }
            });

            if (! errorFlag) {
                this.send();
            } else {
                errorsArr.forEach(errorProp => {
                    this.invalidInputBooleans[errorProp] = true;
                });
            }
        }

        public cancel() {
            this.$uibModalInstance.close(false);
        }

        /**
         * Sends an invoice mail or a test mail, depending on the value of invoiceKey
         * If invoiceKey is null, then we want a test mail, otherwise we want an invoice mail
         */
        public send() {
            this.isSending = true;

            this.fields.mailSubject = document.getElementById("mailSubjectDiv").innerHTML;
            this.fields.mailBody = document.getElementById("mailBodyDiv").innerHTML;

            if (this.invoiceKey) {
                this.invoicingEmailService.sendInvoice(this.sessionService.session.sessionID, this.invoiceKey, this.fields)
                    .then(res => {
                        this.$uibModalInstance.close(res);
                    });
            } else {
                this.invoicingEmailService.sendTestMail(this.sessionService.session.sessionID, this.fields)
                    .then(res => {
                        this.$uibModalInstance.close(res);
                    });
            }
        }
    }
}
angular.module("app").controller("SendMailModalController", app.functionality.invoicing.controllers.modal.SendMailModalController);