/// <reference path="../../_app.ts" />

module app.model.interface {

    export interface ICompanyAdditionalInformation {

        key: string;
        companyKey: string;

        fullName: string;
        senderName: string;
        senderMail: string;

        address: string;
        zip: string;
        city: string;
        country: string;

        iban: string;
    }

}