/// <reference path="../../_app.ts" />

module app.functionality.profit {
  import Exercice = app.model.Exercice;
  import ModalUtil = app.functionality.common.modals.ModalUtil;

  export class IncomeController {
    public gridData: any;
    public exerciceArr: any;
    public exerciceSelected: any;
    public periodArr: any;
    public periodSelected: any;
    public incomeData: any;
    public pdfExportModal: any;
    public csvExportModal: any;

    static $inject = [
      "r_income",
      "ProfitConverter",
      "$scope",
      "SessionService",
      "ProfitService",
      "$uibModal",
      "ModalUtil",
      "$translatePartialLoader",
    ];

    constructor(
      r_income: any,
      private profitConverter: app.functionality.common.converter.ProfitConverter,
      private $scope: ng.IScope,
      private sessionService: app.functionality.common.session.SessionService,
      private profitService: app.functionality.profit.ProfitService,
      public $uibModal: any,
      private modalUtil: ModalUtil,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService
    ) {
      $translatePartialLoader.addPart("customer/profit");
      this.incomeData = this.profitConverter.convertBalance(r_income.data, 2);
      this.gridData = this.setGridOptions(this.incomeData, $scope);
      this.initFilters();
    }

    /*
     * To launch pdf generator modal
     * */

    public pdfExport() {
      let self = this;
      if (this.sessionService.session.company.importInfo.state !== 0) {
        this.pdfExportModal = this.$uibModal.open({
          templateUrl: "tpl/website/modals/pdfModal.html",
          controller: "PdfModalController",
          controllerAs: "pdf",
          size: "sm",
          resolve: {
            r_fromTo: function () {
              return {
                sessionID: self.sessionService.session.sessionID,
                bookyear: self.exerciceSelected.bookyear,
                toPeriod: self.periodSelected.periodIndex,
                language: self.sessionService.session.member.language,
              };
            },
          },
        });
      }
    }

    /*
     * Method called when we want to send a message about a specific category
     * */

    public message(row) {
      this.modalUtil.message(row);
    }

    /*
     * Create grid options for pnl ui grid
     * */

    public setGridOptions(data, $scope) {
      return {
        columnDefs: [
          {
            field: "label",
            displayName: "PROFIT.INCOME.header-title",
            headerCellFilter: "translate",
            headerCellClass: "balance-grid-header-title",
            cellFilter: "translate",
            cellTemplate: "tpl/grid/IncomeStatement/keyTemplate.html",
          },
          {
            field: "solde",
            displayName: "PROFIT.INCOME.solde",
            headerCellFilter: "translate",
            headerCellClass: "balance-grid-header-title",
            cellFilter: "number:2",
            width: "20%",
            cellTemplate: "tpl/grid/IncomeStatement/valueTemplate.html",
          },
        ],
        data: data,
        showTreeExpandNoChildren: false,
        treeIndent: 1,
        enableFiltering: false,
        enableColumnMenus: false,
        enableSorting: false,
        showTreeRowHeader: false,
        rowHeight: 40,
        columnFooterHeight: 40,
        virtualizationThreshold: data.length,
        rowTemplate:
          "<div " +
          "ng-click='grid.appScope.fnOne(row)' " +
          "ng-repeat='col in colContainer.renderedColumns track by col.colDef.name'" +
          "class='ui-grid-cell' " +
          "ng-class=\"{'totalRow': row.entity.type === 3, 'subCatException': row.entity.subCatException === true}\" " +
          "ui-grid-cell>" +
          "</div>",
        onRegisterApi: (gridApi) => {
          $scope.gridApi = gridApi;
        },
      };
    }

    /*
     * to init the selections available on the two filters
     * */

    public initFilters() {
      if (this.sessionService.session.company.importInfo.state !== 0) {
        this.exerciceArr = this.sessionService.session.company.exerciceArr;
        this.exerciceSelected = this.exerciceArr[this.exerciceArr.length - 1];
        this.periodArr = this.getPeriodsArr(this.exerciceSelected);
        this.periodSelected = this.periodArr[this.periodArr.length - 1];
      }
    }

    /*
     * Method called when another bookyear is selected
     * call made to the server to get the new income associated with the new periods & bookyear
     * then response is converted
     * */

    public filterByBookyear() {
      let self = this;
      this.periodArr = this.getPeriodsArr(this.exerciceSelected);
      this.periodSelected = this.periodArr[this.periodArr.length - 1];
      this.profitService
        .getIncome(
          this.sessionService.session.sessionID,
          this.exerciceSelected.bookyear
        )
        .then(function (data) {
          let newData = data.plain();
          self.incomeData = self.profitConverter.convertBalance(
            newData.data,
            2
          );
          self.gridData.data = self.incomeData;
        })
        .catch(function (error) {
          console.error("error ocurred during getIncome in filterByBookyear");
          console.error(error);
        });
    }

    /*
     * Method called when another period is selected
     * call made to the server to get the new income associated with the new periods & bookyear
     * then response is converted
     * */

    public filterByPeriod() {
      let self = this;

      let periodArr = [];
      for (let i = 0; i < this.periodSelected.periodIndex; i++) {
        periodArr.push(i + 1);
      }

      this.profitService
        .getIncomeByPeriod(
          this.sessionService.session.sessionID,
          this.exerciceSelected.bookyear,
          periodArr
        )
        .then(function (data) {
          let newData = data.plain();
          self.incomeData = self.profitConverter.convertBalance(
            newData.data,
            2
          );
          self.gridData.data = self.incomeData;
        })
        .catch(function (error) {
          console.error("error ocurred during getIncome in filterByPeriod");
          console.error(error);
        });
    }

    // to launch modal window for having more detail about a category cell
    public detail(col, row) {
      let self = this;

      // we use JSON.stringify to output the array with the brackets, into a string. necessary for backend call
      let accounts = angular.isDefined(row.entity.accountArr)
        ? JSON.stringify(row.entity.accountArr)
        : JSON.stringify(row.entity.accNum);

      // we send the sum amount, to check the correctness of the value on backend
      let totalAccounts = 0;
      if (angular.isDefined(row.entity.solde)) {
        totalAccounts = row.entity.solde;
      }

      let lastIndex: number;
      if (this.periodSelected.periodIndex === 99) {
        lastIndex = 99;
      } else {
        lastIndex = this.periodSelected.periodIndex;
      }

      let periodArr = [];
      for (let i = 0; i < lastIndex; i++) {
        periodArr.push(i + 1);
      }

      if (this.periodSelected.periodIndex === 99) {
        periodArr.push(99);
      }

      this.profitService
        .getDetailAccount(
          this.sessionService.session.sessionID,
          this.sessionService.session.company.key,
          accounts,
          this.exerciceSelected.bookyear,
          JSON.stringify(periodArr),
          totalAccounts
        )
        .then(function (response) {
          if (
            angular.isDefined(response.data[0]) &&
            response.data[0].length === 0
          ) {
            // I18N
            self.modalUtil.status(
              "MODAL.COMPARISON_NO_DATA.message",
              "MODAL.COMPARISON_NO_DATA.title"
            );
          } else {
            self.modalUtil.detail(response.data, row, col, 3);
          }
        })
        .catch(function (error) {
          console.error("error on detail modal creation", error);
        });
    }

    public getPeriodsArr(ex: Exercice): any {
      let copy = [];
      let y = 0;

      if (ex.isQuarterlyMode) {
        _.forEach(ex.quarterlyArr, function (q) {
          let pArr = q.periodArr;
          let p1 = pArr[pArr.length - 1];
          y = p1.year;
          copy.push(p1);
        });

        let p2 = new app.model.Period();
        p2.monthIndex = -1;
        p2.periodIndex = 99;
        p2.year = y;
        copy.push(p2);
      } else {
        _.forEach(ex.periodArr, function (p3) {
          y = p3.year;
          copy.push(p3);
        });
        let p4 = new app.model.Period();
        p4.monthIndex = -1;
        p4.periodIndex = 99;
        p4.year = y;
        copy.push(p4);
      }
      return copy;
    }

    /*
     * To launch csv generator modal
     * */
    public csvExport() {
      let self = this;
      if (this.sessionService.session.company.importInfo.state !== 0) {
        this.csvExportModal = this.$uibModal.open({
          templateUrl: "tpl/website/modals/csvModal.html",
          controller: "CsvModalController",
          controllerAs: "csv",
          size: "sm",
          resolve: {
            r_fromTo: function () {
              return {
                sessionID: self.sessionService.session.sessionID,
                bookyear: self.exerciceSelected.bookyear,
                toPeriod: self.periodSelected.periodIndex,
                language: self.periodSelected.periodIndex,
              };
            },
          },
        });
      }
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller("IncomeController", app.functionality.profit.IncomeController);
