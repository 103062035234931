/// <reference path="../../../_app.ts" />

module app.menu.feedBack {

    export class FeedBackModalController {

        static $inject = ["$uibModalInstance"];

        public messageBody: string;

        constructor(public $uibModalInstance: any) {

        }

        public cancel() {
            this.$uibModalInstance.close();
        }

        public sendMessage() {
            this.$uibModalInstance.close();
        }
    }

}

angular.module("app").controller("FeedBackModalController", app.menu.feedBack.FeedBackModalController);