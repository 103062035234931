/// <reference path="../_app.ts" />

module app.model {

    export class AccountInformation {
        public key: string;
        public companyKey: string;
        public numero: number;
        public label: string;

        public getNumero(): number {
            return this.numero;
        }

        public setNumero(numero: number): void {
            this.numero = numero;
        }

        public getLabel(): string {
            return this.label;
        }

        public setLabel(label: string): void {
            this.label = label;
        }

        constructor() {
        }

    }

}