/// <reference path="../../_app.ts" />

module app.functionality.profit {
  export class ProfitController {
    public profit: any;
    public dataForLineChart: any;
    public pnl: any;
    public top5S: any;
    public top5C: any;
    public turnover: any;
    public totalPNL = 0;

    private isFrench: boolean;

    static $inject = [
      "ProfitService",
      "LineChartConverterForDashboard",
      "SessionService",
      "r_top5S",
      "r_top5C",
      "r_turnover",
      "r_pnl",
      "$translatePartialLoader",
    ];

    constructor(
      profit: ProfitService,
      public lineChartConverterForDashboard: app.modules.lineargraphs.converter.LineChartConverterForDashboard,
      private sessionService: app.functionality.common.session.SessionService,
      r_top5C: any,
      r_top5S: any,
      r_turnover: any,
      r_pnl: any,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService
    ) {
      $translatePartialLoader.addPart("customer/profit");

      this.turnover = r_turnover.data;
      if (
        this.sessionService.session.company.importInfo != null &&
        this.sessionService.session.company.importInfo.importDate != null
      ) {
        this.dataForLineChart = lineChartConverterForDashboard.convert(
          this.turnover.periodArr,
          new Date(this.sessionService.session.company.importInfo.importDate)
        );
      } else if (this.sessionService.session.company.importInfo.status === 0) {
        this.dataForLineChart = [{ date: moment().toDate(), amount: 0 }];
      } else {
        this.dataForLineChart = lineChartConverterForDashboard.convert(
          this.turnover.periodArr
        );
      }
      this.top5C = r_top5C.data;
      this.top5S = r_top5S.data;
      this.pnl = r_pnl.data.slice(0, r_pnl.data.length - 1);
      this.totalPNL = r_pnl.data.slice(-1)[0].solde;

      if (
        this.sessionService.session.company.country.toLowerCase() ===
          "france" &&
        this.sessionService.session.member.language.toLowerCase() === "fr"
      ) {
        this.isFrench = true;
      } else {
        this.isFrench = false;
      }
    }
  }
}

angular
  .module("app")
  .controller("ProfitController", app.functionality.profit.ProfitController);
