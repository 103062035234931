/// <reference path="../_app.ts" />

// import model.operation.Operation;

module app.model {

    export class AccountingEntry {

        public key: string;
        public status: number; // 0 == from export file;1 == generated

        public parentBudgetKey: string;
        // Description
        public journalType: string;
        public docNumber: number;
        public dbtype: number;
        public docOrder: string;

        public period: number;
        public bookyear: number;
        public account: number;

        //Dates
        public operationDate: Date;
        public encodingDate: Date;
        public dueDate: Date;

        // third
        public third: string;
        public comment: string;
        public vcs: string; // communication structuree

        // amount
        public amount: number;
        public vatAmount: number;
        public vat_name: string;

        public isMatched: boolean;
        public isDoc: boolean;
        public matchID: string;

        // public operation: Operation;

        constructor() {
        }

        // public hasAttachedDoc(): boolean {
        //     if (isDoc) {
        //         return true;
        //     }

        //     if (operation) {
        //         return operation.hasAttachedDoc();
        //     }

        //     return false;
        // }

        // public getAttachedAcc(): AccountingEntry {
        //     if (isDoc) {
        //         return this;
        //     }

        //     if (operation) {
        //         return operation.getAttachedAcc();
        //     }

        //     return null;
        // }

    }

}