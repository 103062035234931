/// <reference path="../_app.ts" />

module app.model {
  import AccountingEntryQuestion = app.model.AccountingEntryQuestion;
  import Invoice = app.model.Invoice;

  export class Ticket {
    constructor() {}

    public key: string;
    public target: AccountingEntryQuestion;
    public archive_invoice: Invoice;

    public status: number;
    public type: number;

    public accFlag: number;
    public userFlag: number;

    public closedReason: number;

    public senderKey: string;
    public authorKey: string;
    public companyKey: string;

    public answerTicketKeyArr: Array<any>;
    public assignedToUserKeyArr: Array<any>;

    public freeQuestion: string;

    public creationDate: Date;
    public sendDate: Date;
    public lastModificationDate: Date;

    public attachmentArr: Array<any>;

    public ticketRecordArr: Array<any>;

    public questionCategory: number;

    public answered: boolean;

    public lastAnswer: TicketAnswer;

    public notifyUserArr: Array<any>;

    public visibleSenderKey?: string;

    public tags: Array<string>;

    // public getLongDescription() : string {
    //     if (this.freeQuestion) {
    //         return this.freeQuestion;
    //     } else {
    //         return this.target.getLongDesc(this.questionCategory);
    //     }
    // }

    // public getShortDescription() : string {
    //     if (this.questionCategory !== TicketLabelMgr.OTHER && this.target) {
    //         return this.target.getShortDesc(this.questionCategory);
    //     } else if (this.target && this.target.third && this.target.third.length > 0) {
    //         let third : string = "(" + this.target.getThirdLabel().substr(0, 1).toUpperCase() +
    //                                 this.target.getThirdLabel().substr(1) + " " + this.target.third + ")";
    //         return third + " " + this.freeQuestion;
    //     } else {
    //         return this.freeQuestion;
    //     }
    // }

    // public getTargetDetail() : string {
    //     return this.target.getLongDesc(this.questionCategory);
    // }

    // public isToProcess() : boolean {
    //     if (this.type === this.FROM_ACCOUNTER && this.lastAnswer && this.lastAnswer.answererType === TicketAnswer.CUSTOMER_TYPE) {
    //         return true;
    //     } else if (this.type === this.FROM_CUSTOMER && !this.lastAnswer) {
    //         return true;
    //     } else if (this.type === this.FROM_CUSTOMER && this.lastAnswer &&
    //                 this.lastAnswer.answererType === this.TicketAnswer.CUSTOMER_TYPE) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }

    // publicisAnswered() : boolean {
    //     if (this.lastAnswer) {
    //         return this.lastAnswer.answererType === TicketAnswer.CUSTOMER_TYPE;
    //     } else {
    //         return false;
    //     }
    // }
  }
}
