/// <reference path="../../_app.ts" />

module app.functionality.profit {
  import SessionService = app.functionality.common.session.SessionService;
  import PNLColumnConfig = app.model.parameter.PNLColumnConfig;
  import ModalUtil = app.functionality.common.modals.ModalUtil;
  import ProfitConverter = app.functionality.common.converter.ProfitConverter;
  import ProfitService = app.functionality.profit.ProfitService;
  import Exercice = app.model.Exercice;

  export class ComparisonController implements angular.IController {
    $onInit() {}

    public pnlColumnConfigArr: Array<PNLColumnConfig>;
    public gridOptions: any;
    public gridDepth: number;
    public columnSelected: PNLColumnConfig;
    public gridApi: any;
    private dataComparison: Array<any>;

    public refresh: boolean;
    public csvExportForm: any;
    public actif = true;
    public passive = true;
    public income = true;
    public detailExport = true;

    public bookyear: number;
    public toPeriod: number;
    private sessionID: any;
    public language: string;
    public exerciceArr: any;
    public exerciceSelected: any;
    public periodArr: any;
    public periodSelected: any;
    public excelFormat = false;
    public excelFormatHelperText = "MODAL.CSV_EXPORT.excelFormatHelperText";
    public csvExportModal: any;

    static $inject = [
      "r_comparisonData",
      "SessionService",
      "ModalUtil",
      "$scope",
      "ProfitConverter",
      "ProfitService",
      "uiGridConstants",
      "$timeout",
      "$translate",
      "$translatePartialLoader",
      "$templateCache",
      "$http",
      //"r_fromTo",
      "$window",
      "ENV",
      "$uibModal",
    ];

    constructor(
      r_comparisonData: any,
      private sessionService: SessionService,
      private modalUtil: ModalUtil,
      private $scope: any,
      private profitConverter: ProfitConverter,
      private profitService: ProfitService,
      private uiGridConstants: uiGrid.IUiGridConstants,
      private $timeout: ng.ITimeoutService,
      private $translate: ngt.ITranslateService,
      private $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      public $templateCache: ng.ITemplateCacheService,
      private $http: ng.IHttpService,
      public $window: angular.IWindowService,
      private ENV: app.config.constants.environment.ConstantEnv,
      private $uibModal: any
    ) {
      $translatePartialLoader.addPart("customer/profit");
      if (this.sessionService.session.company.importInfo.state !== 0) {
        this.dataComparison = r_comparisonData[0];
        this.pnlColumnConfigArr = r_comparisonData[1];
        this.gridOptions = this.setGridOptions(
          this.dataComparison,
          this.$scope
        );
      }

      if (this.sessionService.session.company.importInfo.state !== 0) {
        this.exerciceArr = this.sessionService.session.company.exerciceArr;
        this.exerciceSelected = this.exerciceArr[this.exerciceArr.length - 1];
        this.periodArr = this.getPeriodsArr(this.exerciceSelected);
        this.periodSelected = this.periodArr[this.periodArr.length - 1];
      }
    }
    public getColumnDefs(): Array<any> {
      let self = this;
      let columnDefs = [];

      columnDefs.push({
        field: "label",
        displayName: "PROFIT.INCOME.header-title",
        headerCellFilter: "translate",
        headerCellClass: "balance-grid-header-title",
        enableColumnMenu: false,
        enableHiding: false,
        cellTemplate: "tpl/grid/comparison/comparisonLabel.html",
        width: "40%",
      });

      _(this.pnlColumnConfigArr).forEach((pnlColConfig: PNLColumnConfig) => {
        let displayNameForTranslation = pnlColConfig.title;
        let columnCompared = false;

        // to show percentage if the column is compared to another one
        if (pnlColConfig.key_PNLColumnConfigCompared != null) {
          columnCompared = true;
        }

        columnDefs.push({
          field: "key" + pnlColConfig.indexPosition,
          displayName: displayNameForTranslation,
          cellTemplate: "tpl/grid/comparison/comparisonKey.html",
          headerCellClass: "balance-grid-header-title",
          headerCellTemplate:
            '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
            "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
            "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\"> " +
            '<div role="button"tabindex="0"class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" ' +
            'col-index="renderIndex" title="TOOLTIP"> ' +
            '<span class="ui-grid-header-cell-label" ' +
            "ui-grid-one-bind-id-grid=\"col.uid + '-header-text'\"> " +
            "{{ col.displayName CUSTOM_FILTERS }} " +
            "</span>" +
            "<helper ng-hide='" +
            self.getColumnLabel(
              self.pnlColumnConfigArr,
              pnlColConfig.key_PNLColumnConfigCompared
            ) +
            "=== undefined' position='bottom-right' " +
            'content=\'{{"COMPARISON.comparedTo" | translate}} ' +
            self.getColumnLabel(
              self.pnlColumnConfigArr,
              pnlColConfig.key_PNLColumnConfigCompared
            ) +
            "'>" +
            "</helper>" +
            "</div>" +
            '<div role="button" tabindex="0" ui-grid-one-bind-id-grid="col.uid + \'-menu-button\'" ' +
            'class="ui-grid-column-menu-button" ng-if="grid.options.enableColumnMenus && !col.isRowHeader && col.colDef.enableColumnMenu !== false" ' +
            'ng-click="toggleMenu($event)" ng-class="{\'ui-grid-column-menu-button-last-col\': isLastCol}" ' +
            'ui-grid-one-bind-aria-label="i18n.headerCell.aria.columnMenuButtonLabel" ' +
            'aria-haspopup="true"> <i class="ui-grid-icon-angle-down" ' +
            'aria-hidden="true"> &nbsp; </i> ' +
            "</div> " +
            "<div ui-grid-filter></div> " +
            "</div>",
          menuItems: self.getMenuItems(),
          enableColumnMenu: true,
          enableHiding: false,
          width: "*",
          columnCompared: columnCompared,
          columnDefinition: pnlColConfig,
          comparisonField: "comparison" + pnlColConfig.indexPosition,
        });
      });

      return columnDefs;
    }

    public getColumnLabel(columns, targetColumnKey): string {
      if (angular.isDefined(targetColumnKey)) {
        for (let column of columns) {
          if (column.key === targetColumnKey) {
            return column.title;
          }
        }
      }
    }

    getMenuItems = () => {
      let self = this;
      let editTitle = this.$translate.instant("COMPARISON.editMenuTitle");
      self.$translate("COMPARISON.editMenuTitle").then(function (translation) {
        editTitle = translation;
      });

      let deleteTitle = this.$translate.instant("COMPARISON.deleteMenuTitle");
      self
        .$translate("COMPARISON.deleteMenuTitle")
        .then(function (translation) {
          deleteTitle = translation;
        });

      return [
        {
          title: editTitle,
          icon: "ui-grid-icon-pencil",
          action: function () {
            this.context.colSelected = this.context.col.colDef.columnDefinition;
            this.context.colList = self.pnlColumnConfigArr;
            self.modalUtil.openModal(
              "md",
              this.context,
              "tpl/website/profit/columnComparisonModal.html",
              "ComparisonColumnModalController",
              "comparisonColumn",
              self.getCallbackSuccessForEdit(),
              self.callbackCancelForEdit,
              "edit"
            );
          },
        },
        {
          title: deleteTitle,
          icon: "ui-grid-icon-cancel",
          action: function () {
            /*
             * we check is column is already compared
             * if so, we do not authorize the deletion.
             * if not, we show a confirmation modal
             * and link the appropriate callbacks
             *
             * */

            let columnCompared = false;
            let comparedIn = "";

            this.context.colSelected = this.context.col.colDef.columnDefinition;

            for (let column of self.pnlColumnConfigArr) {
              // the key in question
              if (
                column.key_PNLColumnConfigCompared ===
                this.context.colSelected.key
              ) {
                columnCompared = true;
                comparedIn = column.title;
              }
            }

            if (columnCompared) {
              let message =
                self.$translate.instant(
                  "MODAL.COMPARISON_DELETE_COLUMN_ERROR.title"
                ) +
                " <b>" +
                comparedIn +
                "</b> " +
                self.$translate.instant(
                  "MODAL.COMPARISON_DELETE_COLUMN_ERROR.deleteThisOneFirst"
                );
              self.modalUtil.status(
                message,
                "MODAL.COMPARISON_DELETE_COLUMN_ERROR.title",
                true
              );
            } else {
              let modalOptions = {
                closeButtonText: "MODAL.COMPARISON_DELETE_COLUMN.cancel",
                actionButtonText: "MODAL.COMPARISON_DELETE_COLUMN.confirmBtn",
                headerText: "MODAL.COMPARISON_DELETE_COLUMN.title",
                bodyText: "MODAL.COMPARISON_DELETE_COLUMN.confirmTxt",
              };

              self.modalUtil.showConfirm(
                {},
                modalOptions,
                self.getCallbackSuccessForDelete(this.context.col),
                self.callbackCancelForDelete,
                this.context.col.displayName
              );
            }
          },
        },
      ];
    };

    getGridMenuItems = () => {
      let self = this;

      let addTitle = this.$translate.instant("COMPARISON.addMenuTitle");

      return [
        {
          title: addTitle,
          icon: "ui-grid-icon-plus-squared",
          action: function () {
            self.modalUtil.openModal(
              "md",
              self.pnlColumnConfigArr,
              "tpl/website/profit/columnComparisonModal.html",
              "ComparisonColumnModalController",
              "comparisonColumn",
              self.getCallbackSuccessForAdd(self.pnlColumnConfigArr),
              self.callbackCancelForAdd,
              "add"
            );
          },
        },
      ];
    };

    public addColumn() {
      let self = this;

      if (this.sessionService.session.company.importInfo.state !== 0) {
        self.modalUtil.openModal(
          "md",
          self.pnlColumnConfigArr,
          "tpl/website/profit/columnComparisonModal.html",
          "ComparisonColumnModalController",
          "comparisonColumn",
          self.getCallbackSuccessForAdd(self.pnlColumnConfigArr),
          self.callbackCancelForAdd,
          "add"
        );
      }
    }

    getCallbackSuccessForDelete = (columnDeleted) => {
      let self = this;

      return () => {
        // select the correct pnlColumnConfig to delete
        for (let column of self.pnlColumnConfigArr) {
          if (columnDeleted.name === "key" + column.indexPosition) {
            self.columnSelected = column;
          }
        }
        self.pnlColumnConfigArr = _.reject(
          self.pnlColumnConfigArr,
          function (el) {
            return el.key === columnDeleted.key;
          }
        );
        // delete this column from the PnlColumnConfig
        self.profitService
          .deletePNLColumnConfig(
            self.sessionService.session.sessionID,
            self.columnSelected
          )
          .then(function () {
            self.refreshGrid();
          })

          .catch(function (error) {
            console.error(error);
          });
      };
    };

    callbackCancelForDelete = (result) => {};

    getCallbackSuccessForEdit = () => {
      let self = this;

      return (result) => {
        console.error(result);
        self.profitService
          .editPNLColumnConfig(self.sessionService.session.sessionID, result)
          .then(function () {
            self.refreshGrid();
          })
          .catch(function (error) {
            console.error(error);
          });
      };
    };

    callbackCancelForEdit = (result) => {};

    getCallbackSuccessForAdd = (colList) => {
      let self = this;

      return (result) => {
        self.profitService
          .addPNLColumnConfig(self.sessionService.session.sessionID, result)
          .then(function (response) {
            self.pnlColumnConfigArr.push(response.data[0]);
            self.sessionService.session.company.dashboardParam.pnlColumnConfigArr.push(
              response.data[0]
            );
            self.refreshGrid();
          })
          .catch(function (error) {
            console.error(error);
          });
      };
    };

    callbackCancelForAdd = () => {};

    private setGridDepth(data) {
      let depthElem = data.reduce(function (prev: any, current: any) {
        if (
          angular.isDefined(prev.$$treeLevel) &&
          angular.isDefined(current.$$treeLevel)
        ) {
          return prev.$$treeLevel > current.$$treeLevel ? prev : current;
        } else {
          return prev;
        }
      });
      this.gridDepth = depthElem.$$treeLevel + 1;
    }

    public setGridOptions(data, $scope) {
      let self = this;
      let getColumnDefs: Array<any> = this.getColumnDefs();
      let menuItems = this.getGridMenuItems();
      self.setGridDepth(data);
      return {
        enableSorting: false,
        enableColumnMenus: true,
        enableFiltering: false,
        showTreeExpandNoChildren: false,
        showTreeRowHeader: false,
        treeIndent: 0,
        enableGridMenu: false,
        gridMenuShowHideColumns: false,
        data: data,
        columnDefs: getColumnDefs,
        gridMenuCustomItems: menuItems,
        gridMenuTitleFilter: self.$translate,
        flatEntityAccess: false,
        rowHeight: 40,
        virtualizationThreshold: data.length,
        rowTemplate:
          "<div " +
          "ng-repeat='col in colContainer.renderedColumns track by col.colDef.name'" +
          "class='ui-grid-cell' " +
          "ng-class=\"{'totalRow': row.entity.type === 3, 'subCatException': row.entity.subCatException === true}\" " +
          "ui-grid-cell>" +
          "</div>",
        onRegisterApi: (gridApi) => {
          $scope.gridApi = gridApi;
        },
      };
    }

    // method used to force a grid refresh when add/edit/del a column
    public refreshGrid() {
      let self = this;
      this.profitService
        .getPNLBasedOnConfigREST(
          self.sessionService.session.sessionID,
          self.sessionService.session.company.dashboardParam.stringifyPnlArrOnParams(
            self.pnlColumnConfigArr
          )
        )
        .then(function (response) {
          self.dataComparison = _.values(response.data[0]);
          self.pnlColumnConfigArr = response.data[1];
          console.error(self.dataComparison);
          console.error(self.pnlColumnConfigArr);

          let convertedData = self.profitConverter.convertComparisonGrid(
            self.dataComparison,
            self.pnlColumnConfigArr
          );
          self.gridOptions = self.setGridOptions(convertedData, self.$scope);
          self.forceRefresh();
        })
        .catch(function (error) {
          console.error("error ocurred", error);
        });
    }

    // useful to toggle the ng-if placed on comparison grid
    public forceRefresh() {
      let self = this;
      self.refresh = true;
      self.$timeout(function () {
        self.refresh = false;
      }, 0);
    }

    // to launch modal window for having more detail about a category cell
    public detail(col, row) {
      let self = this;

      let colSelected = col.colDef.columnDefinition;

      // we use JSON.stringify to output the array with the brackets, into a string. necessary for backend call
      let accounts = angular.isDefined(row.entity.accountArr)
        ? JSON.stringify(row.entity.accountArr)
        : JSON.stringify(row.entity.accNum);

      // we send the sum amount, to check the correctness of the value on backend
      let totalAccounts = 0;

      if (angular.isDefined(row.entity[col.field])) {
        totalAccounts = row.entity[col.field];
      }

      let periodArray = [0];
      let periodArrREST;
      if (colSelected.periodArr == null || colSelected.periodArr.length === 0) {
        if (
          colSelected.computedPerriodArr == null ||
          colSelected.computedPerriodArr.length === 0
        ) {
          for (let i = 0; i < 100; i++) {
            periodArray.push(i + 1);
          }
          periodArrREST = JSON.stringify(periodArray);
        } else {
          periodArrREST = JSON.stringify(colSelected.computedPerriodArr);
        }

        //periodArrREST = [0,1,2,3,4,5,6,7,8,9,10,11,12,99];
      } else {
        periodArrREST = JSON.stringify(colSelected.periodArr);
      }

      let bookyear = colSelected.bookyear;
      if (
        bookyear == 0 &&
        (colSelected.periodArr == null || colSelected.periodArr.length === 0)
      ) {
        bookyear = colSelected.computedBookyear;
      }
      this.profitService
        .getDetailAccount(
          this.sessionService.session.sessionID,
          this.sessionService.session.company.key,
          accounts,
          bookyear,
          periodArrREST,
          totalAccounts
        )
        .then(function (response) {
          if (
            angular.isDefined(response.data[0]) &&
            response.data[0].length === 0
          ) {
            self.modalUtil.status(
              "MODAL.COMPARISON_NO_DATA.message",
              "MODAL.COMPARISON_NO_DATA.title"
            );
          } else {
            self.modalUtil.detail(response.data, row, colSelected, 2);
          }
        })
        .catch(function (error) {
          console.error("error on detail modal creation", error);
        });
    }

    /**
     *  To launch the modal to export the comparison csv
     */
    public export() {
      this.sessionID = this.sessionService.session.sessionID;
      this.bookyear = this.exerciceSelected.bookyear;
      this.toPeriod = this.periodSelected.periodIndex;
      this.language = this.periodSelected.periodIndex;

      let self = this;
      if (this.sessionService.session.company.importInfo.state !== 0) {
        this.csvExportModal = this.$uibModal.open({
          templateUrl: "tpl/website/modals/csvComparisonModal.html",
          controller: "CsvComparisonModalController",
          controllerAs: "csvComparison",
          size: "sm",
          resolve: {
            r_fromTo: function () {
              return {
                sessionID: self.sessionService.session.sessionID,
                bookyear: self.exerciceSelected.bookyear,
                toPeriod: self.periodSelected.periodIndex,
                language: self.sessionService.session.member.language,
              };
            },
          },
        });
      }
    }

    /**
     * Nécessaire pour l'export en CSV afin d'obtenir la période
     */
    public getPeriodsArr(ex: Exercice): any {
      let copy = [];
      let y = 0;

      if (ex.isQuarterlyMode) {
        _.forEach(ex.quarterlyArr, function (q) {
          let pArr = q.periodArr;
          let p1 = pArr[pArr.length - 1];
          y = p1.year;
          copy.push(p1);
        });

        let p2 = new app.model.Period();
        p2.monthIndex = -1;
        p2.periodIndex = 99;
        p2.year = y;
        copy.push(p2);
      } else {
        _.forEach(ex.periodArr, function (p3) {
          y = p3.year;
          copy.push(p3);
        });
        let p4 = new app.model.Period();
        p4.monthIndex = -1;
        p4.periodIndex = 99;
        p4.year = y;
        copy.push(p4);
      }
      return copy;
    }
  }
}

angular
  .module("app")
  .controller(
    "ComparisonController",
    app.functionality.profit.ComparisonController
  );
