/// <reference path="../../../_app.ts" />

module app.functionality.common.converter {
  
  export class TreeLevelConverter {
    
    constructor() {
    }
    
    convert = (dataWithTreeLevel: any): any => {
        // if error, change the row data field
        // _(dataWithTreeLevel.rowData).forEach(function(row) {
        _(dataWithTreeLevel).forEach(function(row) {
            if (row.treeLevel) {
                let newTreeLevel = "$$treeLevel";
                row[newTreeLevel] = row.treeLevel;
            }
        });
        
      return dataWithTreeLevel;
    }
         
  }
}

angular.module("app").service("TreeLevelConverter",  app.functionality.common.converter.TreeLevelConverter);