/// <reference path="../../_app.ts" />

module app.model.interface {

    export interface ISale extends IOperation  {

        vat: number;
        percentCash: Date;
        percentCB: number;
        percentCreditCard: number;
        percentVirement: number;
    }
}