/**
 * Created by mickael on 30/06/16.
 */
/// <reference path="../../_app.ts" />

module app.functionality.permanentfolder {

    export class PermanentFolderService {
        private rest: restangular.IService;

        static $inject =  ["RestService",
                            "$q",
                            "$http",
                            "ENV",
                            "RESTAPI",
                            "$sanitize",
                            "LoggerService",
                            "SessionService"];
        constructor(restService: app.functionality.common.rest.RestService,
                    private $q: ng.IQService,
                    private $http: ng.IHttpService,
                    private ENV: app.config.constants.environment.ConstantEnv,
                    private RESTAPI: app.config.constants.ConstantRestApi,
                    private $sanitize: ng.sanitize.ISanitizeService,
                    private logger: app.functionality.common.logger.LoggerService,
                    private sessionService: app.functionality.common.session.SessionService) {
            this.rest = restService.getRoot();
        }

        getFolders = (companyKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.permanentfolder.init).one(companyKey+"/PMF").get();
        }

        getTree = (companyKey: string,treeLevel: string, path: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.permanentfolder.init).one(companyKey+"/PMF?treeLevel="+treeLevel+"&path="+path).get();
        }

        getFolderPath =  (companyKey: string,treeLevel: string, path: string): string => {
            return "#/permanentFolder?treelevel="+(Number(treeLevel)+1)+"&path="+encodeURI(path).replace(/\//g,"~2F");
        }

        search = (companyKey: string,search: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.permanentfolder.init).one(companyKey+"/PMF?search="+search).get();
        }

        getOneDocument = (companyKey: string,modifiedPath: string, key: string, extensionDocument: string): string => {
            return "Companies/"+companyKey+"/PMF?path="+encodeURIComponent(modifiedPath)+"&key="+encodeURIComponent(key)+"&extensionDocument="+extensionDocument;
        }

        downloadOneDocument = (companyKey: string,modifiedPath: string, key: string, extensionDocument: string): string => {
            return "Companies/"+companyKey+"/PMF?path="+modifiedPath+"&key="+key+"&extensionDocument="+extensionDocument+"&download="+true;
        }

        deleteDocument = (companyKey: string,modifiedPath: string, treeLevel: string, name: string, isFolder: boolean): ng.IPromise<any> => {

            return this.$http({
                url: this.ENV.baseUrl + "/" + this.RESTAPI.services.permanentfolder.init + "/" +companyKey+"/PMF?treeLevel="+treeLevel+"&path="+this.$sanitize(encodeURI(modifiedPath))+"&name="+this.$sanitize(encodeURI(name))+"&isFolder="+isFolder,
                method: "DELETE",
                data: {
                },
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                }
            });
        }

        editNameDocument = (companyKey: string,modifiedPath: string,nameFile: string, treeLevel: string, newName: string, type: Boolean): ng.IPromise<any> => {
            let defered = this.$q.defer();
            let self = this;
            let request =  this.rest.all(this.RESTAPI.services.permanentfolder.init).one(companyKey+"/PMF");

            request.customPOST({
                    "treeLevel": treeLevel,
                    "path": encodeURI(modifiedPath),
                    "nameFile": encodeURI(nameFile),
                    "newName": encodeURI(newName),
                    "type": type
                })
                .then(function (data) {
                    defered.resolve(data);
                })
                .catch(function (error) {
                    self.logger.notifyException(error,self.sessionService.getCurrentSessionMember().key);
                    defered.reject(error);
                });

            return defered.promise;

        }

        sendMailToClients = (companyKey: string,contentMail: string, titleMail: string,isFolder: boolean,path: string ): ng.IPromise<any> => {
            let self = this;
            let form: any = new FormData();
            form.append("contentMail", encodeURI(contentMail));
            form.append("titleMail",encodeURI(titleMail));
            form.append("sessionID", this.sessionService.session.sessionID);
            form.append("companyKey", this.sessionService.session.company.key);
            form.append("path", path);
            form.append("isFolder", isFolder);

            return this.$http({
                    method: "post",
                    //url: self.ENV.baseUrl + "/Companies/"+companyKey+"/PMF",
                    url: self.ENV.baseUrl + "/PMF_Upload_Service",
                    data: form,
                    headers: {
                        "Content-Type": undefined
                    }
                }
            );

        }

        // path as to be already encodeURI
        createFolder = (companyKey: string,folderName: string, treeLevel: number,path: string): ng.IPromise<any> => {
            let self = this;
            let form: any = new FormData();

            form.append("level", treeLevel);
            form.append("folderName", encodeURI(folderName));
            form.append("path",path); // not encodeURI because the path come from the self.$stateParams.path who is ALREADY encodeURI
            form.append("type", true);
            form.append("sessionID", this.sessionService.session.sessionID);
            form.append("companyKey", this.sessionService.session.company.key);

            return this.$http({
                    method: "put",
                    //url: self.ENV.baseUrl + "/Companies/"+companyKey+"/PMF",
                    url: self.ENV.baseUrl + "/PMF_Upload_Service",
                    data: form,
                    headers: {
                        "Content-Type": undefined
                    }
                }
            );
        }

    }
}

angular.module("app").service("PermanentFolderService",  app.functionality.permanentfolder.PermanentFolderService);