/// <reference path="../../../_app.ts" />

module app.modules.lineargraphs.builder {
    import LineChartArea = app.modules.lineargraphs.chart.LineChartArea;
    
    export class LineChartAreaBuilder extends LineChartBaseBuilder {
       
        getLineChartGraph = (   idElementContainer: string, xScale: d3.time.Scale<any, any>, yScale: d3.scale.Linear<number, number>, 
                                xAxis: d3.svg.Axis, yAxis: d3.svg.Axis, dateFormater, 
                                elem, data, propertyX: string, propertyY: string, 
                                textXaxis: string, textYaxis: string, margin: {x: number, y: number}): LineChartArea => {
            return new LineChartArea(   idElementContainer, xScale, yScale, xAxis, yAxis, dateFormater,
                                        this.lineChartServiceUtil, propertyX, propertyY, elem, data,
                                        textXaxis, textYaxis, margin);
        }
          
    }
  
}

angular.module("linearChartModule").service("LineChartAreaBuilder", app.modules.lineargraphs.builder.LineChartAreaBuilder);