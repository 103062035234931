/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.services {

    /**
     * This service is used to share the selected date between the Overview view and the detailed view of a third.
     * The selected date is used to show only the invoices that are due at the date.
     */

    export class DateChangingService {

        private selectedDate: Date;

        static $inject = [
            "$uibModal",
            "SessionService"
        ];

        constructor(private $uibModal, private sessionService: app.functionality.common.session.SessionService) {
        }

        /**
         * This functions is used to get the stored date
         * if the date is before the import folder's date, we return the import folder's date
         * if the date is after today's date, we return today's date
         * These checks are done here and not in the setter because when loading the page for the first time, we need to get a correct date
         * @returns {Date}
         */
        public getDate(): Date {
            let importDate = new Date(this.sessionService.session.company.importInfo.importDate);
            let today = new Date();

            if (this.selectedDate && (this.selectedDate < importDate || this.selectedDate > today)) {
                this.$uibModal.open({
                    templateUrl: "tpl/website/invoiceReminder/modal/errorSelectedDateModal.html",
                    controller: "ErrorSelectedDateModalController",
                    controllerAs: "esdmC",
                    size: "md"
                }).result.then(function(data) {
                    return data;
                });
            }

            if (this.selectedDate == undefined) {
                this.selectedDate = importDate != undefined ? importDate : today;
            }
            else {
                if (importDate != undefined && this.selectedDate < importDate) {
                    this.selectedDate = importDate;
                }
                else {
                    if ((importDate == undefined && this.selectedDate < today) || this.selectedDate > today) {
                        this.selectedDate = today;
                    }
                }
            }

            return this.selectedDate;
        }

        /**
         * This function is used to store the date
         * @param {Date} date the date to store
         * @returns {Date} The date corrected according to the criteria explained in the getter
         */
        public setDate(date: Date): Date {
            this.selectedDate = date;
            return this.getDate();
        }
    }

    angular.module("app").service("DateChangingService",  app.functionality.invoiceReminder.services.DateChangingService);
}