/// <reference path="../../../_app.ts" />

module app.config.constants.roles {

    export class ConstantRoles {

        public ADMIN = "ADMIN";
        public ACCOUNTER = "ACCOUNTER";
        public ACCOUNTER_ADMIN = "ACCOUNTER_ADMIN";
        public ACCOUNTER_AS_USER = "ACCOUNTER_AS_USER";
        public ACCOUNTER_AS_IPP = "ACCOUNTER_AS_IPP";
        public GLOBAL_USER_API = "GLOBAL_USER_API";
        public CUSTOMER = "CUSTOMER";
        
    }

}

angular.module("app").
constant("ROLES", new app.config.constants.roles.ConstantRoles());