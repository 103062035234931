/// <reference path="../../_app.ts" />

namespace app.functionality.IPPForm {
  import IPPFormService = app.functionality.IPPForm.IPPFormService;
  import SessionService = app.functionality.common.session.SessionService;
  import IPPFormFactory = app.functionality.IPPForm.IPPFormFactory;
  import IPPFormSubStepsController = app.functionality.IPPForm.IPPFormSubStepsController;

  export class IPPFormStepsController implements angular.IController {
    static $inject = [
      "$translate",
      "$scope",
      "r_step",
      //            "r_data",
      "r_fieldsManager",
      "$stateParams",
      "IPPFormService",
      "$timeout",
      "$state",
      "$window",
      "$http",
      "$translatePartialLoader",
      "SessionService",
      "IPPFormFactory",
      "$uibModal",
      "formlyConfig",
      "LoggerService",
      "Notification",
    ];

    public step;
    public fieldsManager;
    public actualFieldsManager;
    public data;
    public username: string;
    public form;
    public firstInvalidStep;
    public legislation: string;
    /**
     * Ensemble des champs (un tableau de champs pour chaque view)
     * @type {IFieldArray}
     */
    public fields: AngularFormly.IFieldArray = [];
    constructor(
      public $translate: ngt.ITranslateService,
      private scope: ng.IScope,
      r_step: any,
      //r_data: any,
      r_fieldsManager: any,
      protected $stateParams: ng.ui.IStateParamsService,
      public iPPService: IPPFormService,
      private $timeout: ng.ITimeoutService,
      private $state: ng.ui.IStateService,
      public $window,
      protected $http: any,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private sessionService: app.functionality.common.session.SessionService,
      public IPPFactory: IPPFormFactory,
      private $uibModal: any,
      public formlyConfig: AngularFormly.IFormlyConfig,
      public logger: app.functionality.common.logger.LoggerService,
      public notification: any
    ) {
      $translatePartialLoader.addPart("ipp");

      this.username = this.sessionService.session.member.fullName;
      this.legislation = "BE";
      if (this.sessionService.session.member.legislation) {
        this.legislation = this.sessionService.session.member.legislation;
      }
      this.step = r_step;
      this.data = this.iPPService.data;
      this.form = this;
      //on attribue à activeStep le numéro du step en cours
      this.iPPService.activeStep = this.step.step;

      // if there's an accounter checking, we show the modal to edit the form, or just consult it
      if (
        this.$state.params.accountant === "true" &&
        !this.iPPService.alreadyCheckedAsAnAccounter
      ) {
        this.seeIppAsAccounter();
        this.iPPService.alreadyCheckedAsAnAccounter = true;
      }
      this.fieldsManager = r_fieldsManager;
      if (this.fieldsManager[0]) {
        this.actualFieldsManager = this.fieldsManager[0].steps.find(function (
          element
        ) {
          return element.step === this.step;
        },
        this.step);
      }

      //SPECIAL CASE FOR FINAL VALIDATION
      if (this.step.step == 700 && !this.iPPService.readingMode) {
        // we set the strict validators, to show empty and necessary validators in red
        this.formlyConfig.extras.errorExistsAndShouldBeVisibleExpression =
          "fc.$touched || fc.$invalid";
        this.iPPService.finalisationMode = true;

        // check if there have been a change in the missingAnnexe list
        this.updateMissingAnnexeList();
        this.firstInvalidStep = this.iPPService.getFirstInvalidStep();
      }

      // incompatible avec edit dans lequel on a besoin de retrouver un autre fieldsmanager
      /*
            this.fieldsManager = r_fieldsManager[0].steps.find(function (element) {
                return element.step === this.step;
            }, this.step);
            */

      this.fields = IPPFactory.factoryFields(
        this.step,
        this.actualFieldsManager,
        this.fields,
        this.data
      );
      /**
       * On ajoute ici tous les champs qui doivent pouvoir être traduit
       */
      let toTranslate: Array<any> = [];
      toTranslate.push(this.fields);

      /**
       * Si changement de langue alors on traduit
       */
      scope.$watch(
        () => {
          return $translate.use();
        },
        (newLang, oldLang) => {
          if (newLang && oldLang && newLang !== oldLang) {
            angular.forEach(toTranslate, function (x) {
              runExpressionFields(x);
            });
          }
        }
      );

      /**
       * lance un runxpression de tous les champs (nécessaire notamment pour refresh quand on traduit)
       * @param table
       */
      function runExpressionFields(table) {
        angular.forEach(table, function (x) {
          if (x.hasOwnProperty("fieldGroup")) {
            this.runExpressionFields(x.fieldGroup);
          } else {
            // x.runExpressions && x.runExpressions();
            x.runExpressions();
          }
        });
      }
    } //end constructor

    $onInit() {}

    public seeIppAsAccounter() {
      // use this line to devlop
      // if (!this.iPPService.isAccounter) {
      if (this.iPPService.isAccounter && this.data.status_declaration === 5) {
        this.iPPService.readingMode = true;
        this.$state.go("ipp.form.identification", {
          ippKey: this.iPPService.data.key,
        });
      } else {
        if (this.iPPService.isAccounter && !this.iPPService.data.closed) {
          this.iPPService
            .resetIppStateForAccounter(this.iPPService.data.key)
            .then((result) => {
              this.$state.reload();
            })
            .catch((err) => {
              console.error("error: ", err);
            });
        }
      }
    }

    //à changer
    public convert(key, model) {
      if (key.split(".")[0] === "dependent") {
        return model.dependent[key.split(".")[1]];
      }
      switch (model[key]) {
        case 1:
          return this.$translate.instant("IPP_FORM.FAMILY.yes");
        case 2:
          return this.$translate.instant("IPP_FORM.FAMILY.no");
        default:
          return model[key];
      }
    }

    public updateData(data, nextView) {
      this.IPPFactory.updateData(data, nextView);
    }

    public showValidation() {
      this.$uibModal
        .open({
          templateUrl: "tpl/IPPForm/confirmModal.html",
          controller: "ConfirmModalController",
          controllerAs: "confModalCtrl",
          //Permet de bloquer le click en dehors du modal
          backdrop: "static",
          //Permet de bloquer le escape
          keyboard: false,
          //modal-fit est une class définit dans le sass
          windowClass: "adjust-ipp-modal",
          size: "sm",
          resolve: {
            data: this.data,
          },
        })
        .result.then((data) => {
          this.updateFinalData(data, 0, true);
        });
    }

    /**
     * Mettre à jour les données sur le serveur
     * @param data les données à mettre  à jour
     * @param nextView la vue suivante
     */
    public updateFinalData(data, nextView, finalSubmit) {
      if (finalSubmit) {
        data.status_declaration = 2;
        data.closed = true;
        data.depot_client = Date.now();
        // save the missing annexe list
        data.missingAttachments = this.iPPService.missingAnnexesList;
      }

      this.iPPService
        .updateData(data, data.fiscalYear, nextView)
        .then(() => {
          if (finalSubmit) {
            this.iPPService.readingMode = true;
            this.iPPService.validationMode = false;
            this.notification.primary({
              title: this.$translate.instant(
                "IPP_FORM.NOTIFICATIONS.submittedFormTitle"
              ),
              message: this.$translate.instant("IPP_FORM.formValidated") + ".",
              templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html",
              delay: 3000,
            });
          }
        })
        .catch((err) => {
          this.notification.primary({
            title: this.$translate.instant(
              "IPP_FORM.NOTIFICATIONS.simpleErrorTitle"
            ),
            message: this.$translate.instant(
              "IPP_FORM.NOTIFICATIONS.simpleErrorMessage"
            ),
            templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
          });
          this.logger.error(
            "error in update data from ippFormService",
            err,
            this.sessionService.session.member
          );
        });
    }

    public updateMissingAnnexeList() {
      // check pour chaque missing annexe dans le model, si une data est là. si une data est là, on la reject du tableau.
      this.iPPService.missingAnnexesList = _.reject(
        this.iPPService.missingAnnexesList,
        (annexe) => {
          let fieldValue = this.iPPService.parcourObject(
            this.data,
            annexe.fieldKey
          );
          if (fieldValue instanceof Array) {
            if (fieldValue.length > 0) {
              return true;
            }
          }
          return fieldValue;
        }
      );
    }
  }
}

angular
  .module("app")
  .controller(
    "IPPFormStepsController",
    app.functionality.IPPForm.IPPFormStepsController
  );
