/// <reference path="../../_app.ts" />

module app.functionality.menu {
  export class MenuController {
    // public messageModal: any;
    public features: string;
    public tabFeatures: any;
    public showPMF: boolean = false;
    public showBI: boolean = false;
    public country: string;
    public showViaMail: boolean = false;

    // for displaying active class with blue background
    public categories = [
      false, // PROFIT
      false, // TREASURY
      false, // LIQUIDITY
      false, // INVOICE REMINDER
      false, // INVOICING
      false, // UPLOAD
      false, // PARAMETERS
    ];

    static $inject = [
      "MenuDisplay",
      "$uibModal",
      "ModalService",
      "TicketNotification",
      "SessionService",
      "FEATURES",
      "$translatePartialLoader",
      "$rootScope",
      "$window",
    ];

    constructor(
      public menuDisplay: app.config.values.MenuDisplay,
      public $uibModal: any,
      private modalService: app.functionality.common.modals.ModalService,
      public ticketNotification: app.config.values.TicketNotification,
      private sessionService: app.functionality.common.session.SessionService,
      private FEATURES: app.config.constants.roles.ConstantFeatures,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $rootScope: ng.IRootScopeService,
      private $window: ng.IWindowService
    ) {
      $translatePartialLoader.addPart("customer/menu");
      this.features = "";
      this.tabFeatures = FEATURES;
      if (
        this.sessionService.session.company != null &&
        this.sessionService.session.company.key != null
      ) {
        this.features =
          this.sessionService.session.member.featuresByComp[
            this.sessionService.session.company.key
          ];
        this.showPMF =
          this.sessionService.session.company.permanentFolder !== null &&
          this.sessionService.session.company.permanentFolder !== undefined &&
          (this.features.indexOf(this.tabFeatures.OTHERPMF) > -1 ||
            this.features.indexOf(this.tabFeatures.PM) > -1);
        this.showBI =
          this.sessionService.session.company.budgetInsightCredential !==
            null &&
          this.sessionService.session.company.budgetInsightCredential !==
            undefined;

        if (this.sessionService.session.subscription_plan.extras) {
          this.sessionService.session.subscription_plan.extras.find(
            (extra) => extra.labelKey === "IBM" && extra.activated
          ) != null
            ? (this.showViaMail = true)
            : (this.showViaMail = false);
        } else {
          this.showViaMail = false;
        }
      }
      this.menuDisplay.setSize =
        this.sessionService.session.member.discreetMenu;

      if (sessionService.session.company.country != null) {
        this.country = sessionService.session.company.country;
      } else {
        console.error("no country found");
      }
      this.setActiveCat();
    }

    updateSize() {
      this.menuDisplay.setSize = !this.menuDisplay.setSize;
      this.sessionService.updateDiscreetMenuFlag(this.menuDisplay.setSize);

      // event launched, catched in the linearGraphBaseDirective directive to update the size of the graph
      let w = angular.element(this.$window);
      w.triggerHandler("resize");

      let currentCategorySelected;
      let i;
      for (i = 0; i < this.categories.length; i++) {
        if (this.categories[i] === true) {
          currentCategorySelected = i;
          this.setActiveCat(i);
        }
      }
    }

    public setActiveCat(activeCat?: number) {
      this.categories = [false, false, false, false, false, false];
      if (activeCat != null && activeCat >= 0 && activeCat < 6) {
        //put the good category to active state
        this.categories[activeCat] = true;
      }
    }

    public newFeedBack() {
      this.modalService.createFeedBackMessage(
        "tpl/website/modals/feedBackMessage.html",
        "FeedBackModalController",
        "feedBackModalCtrl"
      );
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller("MenuController", app.functionality.menu.MenuController);
