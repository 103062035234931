/// <reference path="../../typings/globals/accounting/index.d.ts" />
/// <reference path="../../typings/globals/ag-grid/index.d.ts" />
/// <reference path="../../typings/globals/angular/index.d.ts" />
/// <reference path="../../typings/globals/angular-animate/index.d.ts" />
/// <reference path="../../typings/globals/angular-cookies/index.d.ts" />
/// <reference path="../../typings/globals/angular-formly/index.d.ts" />
// /// <reference path="../../typings/globals/angular-mocks/index.d.ts" />
// /// <reference path="../../typings/globals/angular-protractor/index.d.ts" />
/// <reference path="../../typings/globals/angular-resource/index.d.ts" />
/// <reference path="../../typings/globals/angular-route/index.d.ts" />
/// <reference path="../../typings/globals/angular-sanitize/index.d.ts" />
/// <reference path="../../typings/globals/angular-translate/index.d.ts" />
/// <reference path="../../typings/globals/angular-ui-router/index.d.ts" />
/// <reference path="../../typings/globals/assertion-error/index.d.ts" />
/// <reference path="../../typings/globals/bugsnag/index.d.ts" />
// /// <reference path="../../typings/globals/chai/index.d.ts" />
// /// <reference path="../../typings/globals/chai-as-promised/index.d.ts" />
// /// <reference path="../../typings/globals/chai-fuzzy/index.d.ts" />
/// <reference path="../../typings/globals/d3/index.d.ts" />
// /// <reference path="../../typings/globals/jasmine/index.d.ts" />
/// <reference path="../../typings/globals/jquery/index.d.ts" />
/// <reference path="../../typings/globals/lodash/index.d.ts" />
/// <reference path="../../typings/globals/moment/index.d.ts" />
// /// <reference path="../../typings/globals/moment-node/index.d.ts" />
/// <reference path="../../typings/globals/restangular/index.d.ts" />
/// <reference path="../../typings/globals/selenium-webdriver/index.d.ts" />
/// <reference path="../../typings/globals/ui-grid/index.d.ts" />

/// <reference path="./functionality/common/session/SessionService.ts" />
/// <reference path="./modules/lineargraphs/lineChartModule.ts" />
/// <reference path="./modules/lineargraphs/util/lineChartServiceUtil.ts" />
/// <reference path="./modules/lineargraphs/constant/lineChartConstants.ts" />
/// <reference path="./modules/lineargraphs/converter/lineChartConverterForDashBoard.ts" />
/// <reference path="./modules/lineargraphs/chart/lineChartBase.ts" />
/// <reference path="./modules/lineargraphs/chart/lineChart.ts" />
/// <reference path="./modules/lineargraphs/chart/lineChartArea.ts" />
/// <reference path="./modules/lineargraphs/builder/lineChartBaseBuilder.ts" />
/// <reference path="./modules/lineargraphs/builder/lineChartBuilder.ts" />
/// <reference path="./modules/lineargraphs/builder/lineChartAreaBuilder.ts" />
/// <reference path="./modules/lineargraphs/directive/lineChartBaseDirective.ts" />
/// <reference path="./modules/lineargraphs/directive/lineChartDirective.ts" />
/// <reference path="./modules/lineargraphs/directive/lineChartAreaDirective.ts" />

/// <reference path="./config/constants/config.ts" />
/// <reference path="./config/constants/environment/envconfig.ts" />
/// <reference path="./config/constants/roles/roles.ts" />
/// <reference path="./config/constants/roles/features.ts" />
/// <reference path="./model/constants/BookHistoricConst.ts" />
/// <reference path="./config/i18n/i18nConfig.ts" />
/// <reference path="./config/rest/restConfig.ts" />

/// <reference path="./config/values/menuDisplay.ts" />

/// <reference path="./functionality/common/converters/TreeLevelConverter.ts" />
/// <reference path="./functionality/common/converters/InstanceConverter.ts" />
/// <reference path="./functionality/common/converters/ProfitConverter.ts" />
/// <reference path="./functionality/common/rest/RestService.ts" />
/// <reference path="./functionality/common/i18n/HeaderController.ts" />
/// <reference path="./functionality/common/interceptors/HttpInterceptors.ts" />
/// <reference path="./functionality/common/modals/ModalService.ts" />
/// <reference path="./functionality/common/modals/ModalUtil.ts" />
/// <reference path="./functionality/common/filters/Filter.ts" />
/// <reference path="./functionality/common/utils/AccountTools.ts" />
/// <reference path="./functionality/common/utils/mime-types.ts" />

/// <reference path="./functionality/dashboard/DashboardController.ts" />

/// <reference path="./functionality/search/SearchController.ts" />
/// <reference path="./functionality/search/SearchService.ts" />

/// <reference path="./functionality/permanentfolder/PermanentFolderController.ts" />
/// <reference path="./functionality/permanentfolder/PermanentFolderService.ts" />
/// <reference path="./functionality/permanentfolder/AddPMFFolderController.ts" />

/// <reference path="./functionality/liquidity/LiquidityService.ts" />
/// <reference path="./functionality/liquidity/LiquidityOverviewController.ts" />
/// <reference path="./functionality/liquidity/VATController.ts" />
/// <reference path="./functionality/liquidity/ReceivableAndDebtorController.ts" />
/// <reference path="./functionality/liquidity/ReceivableAndDebtorService.ts" />
/// <reference path="./functionality/liquidity/LiquidityConverter.ts" />

/// <reference path="./model/interface/IThird.ts" />
/// <reference path="./model/interface/IAccountingEntry.ts" />
/// <reference path="./model/interface/IOperation.ts" />
/// <reference path="./model/interface/ISale.ts" />
/// <reference path="./model/interface/ILostPaiement.ts" />
/// <reference path="./model/interface/IContact.ts" />
/// <reference path="./model/interface/ITemplateReminder.ts" />
/// <reference path="./model/interface/IReminder.ts" />
/// <reference path="./model/interface/ICompanyAdditionalInformation.ts" />

/// <reference path="./model/invoiceReminder/Reminder.ts" />
/// <reference path="./model/invoiceReminder/ThirdWithOperations.ts" />

/// <reference path="./functionality/invoiceReminder/controllers/InvoiceReminderOverviewController.ts" />
/// <reference path="./functionality/invoiceReminder/controllers/InvoicesAndPaymentsController.ts" />
/// <reference path="./functionality/invoiceReminder/controllers/ContactUpdateInfoController.ts" />
/// <reference path="./functionality/invoiceReminder/controllers/SaleDetailController.ts" />
/// <reference path="./functionality/invoiceReminder/controllers/ThirdReminderHistoryController.ts" />
/// <reference path="./functionality/invoiceReminder/services/InvoiceReminderConverter.ts" />
/// <reference path="./functionality/invoiceReminder/services/InvoiceReminderService.ts" />
/// <reference path="./functionality/invoiceReminder/services/DateChangingService.ts" />
/// <reference path="./functionality/invoiceReminder/controllers/UpdateConfigurationController.ts" />
/// <reference path="./functionality/invoiceReminder/services/ModalQuickstartService.ts" />
/// <reference path="./functionality/invoiceReminder/controllers/modal/ModalQuickstartController.ts" />
/// <reference path="./functionality/invoiceReminder/controllers/modal/InvoiceReminderErrorSendingReminderModalController.ts" />
/// <reference path="./functionality/invoiceReminder/services/SendReminderService.ts" />
/// <reference path="./functionality/invoiceReminder/services/FilterService.ts" />

/// <reference path="./model/invoicing/Invoice.ts" />
/// <reference path="./model/invoicing/InvoicingClient.ts" />
/// <reference path="./model/invoicing/InvoicingInformation.ts" />

/// <reference path="./functionality/invoicing/controllers/InvoicingOverviewController.ts" />
/// <reference path="./functionality/invoicing/controllers/InvoicingEditionController.ts" />
/// <reference path="./functionality/invoicing/controllers/ClientOverviewController.ts" />
/// <reference path="./functionality/invoicing/controllers/ClientEditionController.ts" />
/// <reference path="./functionality/invoicing/controllers/InvoicingConfigurationController.ts" />
/// <reference path="./functionality/invoicing/controllers/modal/InvgConfModalController.ts" />
/// <reference path="./functionality/invoicing/services/InvoicingService.ts" />
/// <reference path="./functionality/invoicing/services/ClientService.ts" />
/// <reference path="./functionality/invoicing/services/InvoicingEmailService.ts" />

/// <reference path="./functionality/profit/ProfitService.ts" />
/// <reference path="./functionality/profit/ProfitController.ts" />
/// <reference path="./functionality/profit/BalanceController.ts" />
/// <reference path="./functionality/profit/TurnoverController.ts" />
/// <reference path="./functionality/profit/IncomeController.ts" />
/// <reference path="./functionality/profit/ComparisonController.ts" />

/// <reference path="./functionality/treasury/TreasuryService.ts" />
/// <reference path="./functionality/treasury/TreasuryController.ts" />
/// <reference path="./functionality/treasury/BankBalanceController.ts" />

/// <reference path="./functionality/common/modals/PdfModalController.ts" />
/// <reference path="./functionality/profit/DetailModalController.ts" />
/// <reference path="./functionality/common/modals/InfoModalController.ts" />
/// <reference path="./functionality/common/modals/NewTicketModalController.ts" />
/// <reference path="./functionality/common/modals/MultiCompanyModalController.ts" />
/// <reference path="./functionality/common/modals/NewMessageModalController.ts" />
/// <reference path="./functionality/common/modals/SmallDetailModalController.ts" />
/// <reference path="./functionality/common/modals/SupportModalController.ts" />
/// <reference path="./functionality/common/modals/AccountantModalController.ts" />

/// <reference path="./functionality/user/UserService.ts" />
/// <reference path="./functionality/user/UserController.ts" />
/// <reference path="./functionality/common/modals/PasswordModalController.ts" />
/// <reference path="./functionality/user/pwCheckDirective.ts" />

/// <reference path="./functionality/menu/MenuController.ts" />

/// <reference path="./functionality/ticket/TicketListController.ts" />
/// <reference path="./functionality/ticket/TicketService.ts" />
/// <reference path="./functionality/ticket/FileChangeDirective.ts" />
/// <reference path="./functionality/common/modals/TicketModalController.ts" />

/// <reference path="./functionality/upload/UploadService.ts" />
/// <reference path="./model/invoice/Invoice.ts" />
/// <reference path="./model/invoice/UploadFolder.ts" />
/// <reference path="./functionality/common/modals/PdfViewerModalController.ts" />
/// <reference path="./functionality/common/modals/KeepNoteModalController.ts" />
/// <reference path="./functionality/common/modals/UploadModalController.ts" />
/// <reference path="./functionality/common/modals/invoiceNoteModalController.ts" />
/// <reference path="./functionality/common/modals/ConfirmLeaveUploadPageController.ts" />

/// <reference path="./functionality/login/fallbackDirective.ts" />

/// <reference path="./model/Ticket.ts" />
/// <reference path="./functionality/common/modals/ResultUploadModalController.ts" />
/// <reference path="./model/AccountingEntryQuestion.ts" />
/// <reference path="./model/TicketAnswer.ts" />
/// <reference path="./model/TicketRecord.ts" />
/// <reference path="./model/Attachement.ts" />
/// <reference path="./model/Third.ts" />
/// <reference path="./model/AccountingEntry.ts" />
/// <reference path="./model/Company.ts" />
/// <reference path="./model/Exercice.ts" />
/// <reference path="./model/Descriptor.ts" />

/// <reference path="./model/Operation.ts" />
/// <reference path="./model/BookHistoric.ts" />
/// <reference path="./model/Session.ts" />
/// <reference path="./model/Member.ts" />
/// <reference path="./model/AccountingSession.ts" />
/// <reference path="./model/UserSession.ts" />
/// <reference path="./model/Period.ts" />
/// <reference path="./model/Quarterly.ts" />
/// <reference path="./model/AccountInformation.ts" />
/// <reference path="./model/Account.ts" />
/// <reference path="./model/Sale.ts" />
/// <reference path="./model/Purchase.ts" />
/// <reference path="./model/LostPaiement.ts" />
/// <reference path="./model/parameter/DashboardReport.ts" />
/// <reference path="./model/exchange/GroupAccount.ts" />
/// <reference path="./model/exchange/EntryAcc.ts" />

/// <reference path="./modules/accountList/accountListModule.ts" />
/// <reference path="./modules/selectbox/selectboxModule.ts" />
/// <reference path="./modules/selectbox/directive/selectboxDirective.ts" />
/// <reference path="./modules/accountList/directive/accountListDirective.ts" />
/// <reference path="./modules/accountList/controller/AccountListController.ts"/>
/// <reference path="./modules/helper/helperModule.ts" />
/// <reference path="./modules/helper/directive/helperDirective.ts" />
/// <reference path="./modules/ratio/ratioModule.ts" />
/// <reference path="./modules/ratio/directive/ratioDirective.ts" />
/// <reference path="./modules/tablePaging/tablePagingModule.ts" />
/// <reference path="./modules/tablePaging/directive/tablePagingDirective.ts" />
/// <reference path="./modules/tablePaging/controller/TablePagingController.ts" />

/// <reference path="./model/exchange/Status.ts" />
/// <reference path="./model/exchange/ResponseObject.ts" />

/// <reference path="./functionality/common/session/LoginController.ts" />
/// <reference path="./functionality/common/logger/LoggerService.ts" />

/// <reference path="./functionality/common/filters/DocumentFilter.ts" />
/// <reference path="./model/exchange/BalanceData.ts" />

/// <reference path="./functionality/IPPForm/IPPFormService.ts" />
/// <reference path="./functionality/IPPForm/IPPFormControllerIndex.ts" />
/// <reference path="./functionality/IPPForm/IPPFormArchive.ts" />
/// <reference path="./functionality/IPPForm/UploadIPPModalController.ts" />
/// <reference path="./functionality/IPPForm/IPPFormFactory.ts"/>
/// <reference path="./functionality/IPPForm/IPPFormStepsController.ts"/>
/// <reference path="./functionality/IPPForm/IPPFormSubStepsController.ts"/>
/// <reference path="./functionality/IPPForm/IPPFormAccount.ts"/>

/// <reference path="./model/Account.ts" />
/// <reference path="./model/AccountInformation.ts" />

/// <reference path="./model/parameter/CategoryDefinition.ts" />
/// <reference path="./model/parameter/DashBoardParameter.ts" />
/// <reference path="./model/parameter/PNLColumnConfig.ts" />

/// <reference path="./functionality/upload/ToSendController.ts" />
/// <reference path="./functionality/upload/ArchiveController.ts" />
/// <reference path="./functionality/upload/directive/DragDropDirective.ts" />

/// <reference path="./config/socket/SocketService.ts" />
/// <reference path="./model/constants/TicketConst.ts" />
