/// <reference path="../../_app.ts" />

module app.functionality.user {
  import UserService = app.functionality.user.UserService;
  import LoggerService = app.functionality.common.logger.LoggerService;
  import ModalService = app.functionality.common.modals.ModalService;

  export class UserController {
    public user_data: any;
    public password_form_data: any;
    public modalInstance: any;

    public languages = ["NL", "FR", "EN", "DE"];

    public language: any;

    public showPWD: boolean = false;
    public wrongPwFlag: boolean;

    static $inject = [
      "r_user_data",
      "UserService",
      "$uibModal",
      "$translate",
      "SessionService",
      "$state",
      "SearchService",
      "I18NCONSTANTS",
      "$translatePartialLoader",
      "ModalService",
      "Notification",
      "LoggerService",
      "tmhDynamicLocale",
    ];

    public phonePattern = /^\+(\d{2,3})\d{9}$/;

    constructor(
      r_user_data: any,
      private userService: UserService,
      public $uibModal: any,
      private $translate: ngt.ITranslateService,
      public sessionService: app.functionality.common.session.SessionService,
      private $state: ng.ui.IStateService,
      public searchService: app.functionality.search.SearchService,
      i18NCONSTANTS: app.config.constants.I18NConstants,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private modalService: ModalService,
      private notification: any,
      private logger: LoggerService,
      private tmhDynamicLocale
    ) {
      $translatePartialLoader.addPart("customer/parameters");
      this.user_data = r_user_data.data;
      this.language = this.sessionService.session.member.language;
      this.password_form_data = this.user_data;
      this.password_form_data.oldPassword = "";
      this.password_form_data.pass1 = "";
      this.password_form_data.pass2 = "";
      this.wrongPwFlag = false;
    }

    updateUserAccount() {
      let self = this;
      this.userService
        .updateUser(this.sessionService.session.sessionID, this.user_data)
        .then(function (data) {
          self.sessionService.session.member.language =
            self.user_data.language.toLowerCase();
          self.notification(
            self.$translate.instant("NOTIFICATIONS.ACCOUNT.changeSuccess")
          );
        })
        .catch(function (error) {
          self.notification.error(
            self.$translate.instant("NOTIFICATIONS.ACCOUNT.changeError")
          );
          self.logger.error(
            "UserController.ts",
            error,
            this.sessionService.session.member
          );
        });
    }

    public changeLanguage() {
      if (
        this.sessionService.session.company.country.toLowerCase() ===
          "france" &&
        this.user_data.language.toLowerCase() === "fr"
      ) {
        this.$translate.use("fr_fr");
        this.tmhDynamicLocale.set("fr_fr");
      } else {
        this.$translate.use(this.user_data.language.toLowerCase());
        this.tmhDynamicLocale.set(this.user_data.language.toLowerCase());
      }
      //update the typeahead with the appropriate expression (client, provider)
      // this.thirdsList = this.updateThirdListI18n(this.thirdsList);
    }

    openpwd() {
      this.showPWD = !this.showPWD;
      /* let self = this;
            this.modalInstance = this.$uibModal.open({
                templateUrl: "tpl/website/modals/changePwdModal.html",
                controller: "PasswordModalController",
                controllerAs: "passwordModal",
                size: "sm",
                resolve: {
                    passed_user_data: function () {
                        return self.user_data;
                    }
                }
            }); */
    }

    closepwd() {
      this.showPWD = false;
      this.password_form_data.oldPassword = "";
      this.password_form_data.pass1 = "";
      this.password_form_data.pass2 = "";
    }

    //PASSWORD FUNCTIONS
    public updatePassword() {
      let self = this;
      this.password_form_data.password = CryptoJS.SHA256(
        this.password_form_data.pass1
      ).toString();
      this.password_form_data.oldPassword = CryptoJS.SHA256(
        this.password_form_data.oldPassword
      ).toString();
      this.password_form_data.pass1 = undefined;
      this.password_form_data.pass2 = undefined;
      if (this.password_form_data.accesLevel == 0) {
        self.userService
          .updateUserPassword(
            self.sessionService.session.sessionID,
            self.password_form_data
          )
          .then(function (data) {
            self.sessionService.session.member.password =
              self.password_form_data.password;
            self.notification(
              self.$translate.instant("NOTIFICATIONS.ACCOUNT.changePassSuccess")
            );
            console.error("mdp modifié : ", data);
          })
          .catch(function (error) {
            self.notification.error(
              self.$translate.instant("NOTIFICATIONS.ACCOUNT.changePassError")
            );
          });
      } else {
        // do nothing, it means it's an accounter and we have no jwt to send
      }
      this.showPWD = false;
    }

    public checkPassInputs() {
      if (
        this.password_form_data.oldPassword === "" ||
        this.password_form_data.pass1 === "" ||
        this.password_form_data.pass2 === "" ||
        this.password_form_data.pass1 != this.password_form_data.pass2
      ) {
        return true;
      } else {
        return false;
      }
    }
    public logout() {
      this.sessionService.authenticated = false;

      let self = this;
      this.sessionService
        .logOut(this.sessionService.session.sessionID)
        .then(function () {
          self.sessionService.mapUploadFolder = undefined;
          self.sessionService.session = undefined;
          self.sessionService.authenticated = false;
          self.searchService.clientsThird = undefined;
          self.searchService.suppliersThird = undefined;
          self.$state.go("login");
          // self.$window.location.reload();
        })
        .catch(function (error) {
          console.error(error);
          console.error("error during log off");
        });
    }

    public async switchVersion() {
      let modalOptions = {
        closeButtonText: "MODAL.NOTIFICATIONS.cancelBtn",
        actionButtonText: "MODAL.NOTIFICATIONS.confirmBtn",
        headerText: "MODAL.NOTIFICATIONS.continue",
        bodyText: "MODAL.NOTIFICATIONS.confirmAction",
      };
      await this.modalService.showConfirm({}, modalOptions);
      await this.sessionService.switchVersion(
        this.sessionService.session.member.betaTester
      );
      this.logout();
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller("UserController", app.functionality.user.UserController);
