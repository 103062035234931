/// <reference path="../../_app.ts" />

module app.model.exchange {
    import Account = app.model.Account;

    export class EntryAcc {

        public acc: Account;
        public label: String;

        constructor() {
        }
    }
}