/// <reference path="../../../_app.ts" />

module app.functionality.invoicing.controllers {
  import InvoicingInformation = app.model.invoicing.InvoicingInformation;
  import InvoicingInformationIncrement = app.model.invoicing.InvoicingInformationIncrement;
  import InvoicingInformationVatRate = app.model.invoicing.InvoicingInformationVatRate;

  export class InvoicingConfigurationController implements angular.IController {
    private invgInfo: InvoicingInformation;

    private invalidInputBooleans = {};
    private requiredProperties = [
      "companyName",
      "address",
      "zip",
      "city",
      "country",
      "vatNumber",
    ]; // Values required for invoice creation or display

    private invoiceTextsLang: string =
      this.sessionService.session.member.language.toLowerCase(); // Initial value for the language selector for invoice texts
    private mailTextsLang: string =
      this.sessionService.session.member.language.toLowerCase(); // Initial value for the language selector for mail texts

    private selectedIncrement: number = null;
    private selectedVatRate: number = null;

    private activeTab: number;

    private accFirmIsFrench: boolean;
    private userLanguage: string =
      this.sessionService.session.member.language.toLowerCase();

    $onInit() {}

    static $inject = [
      "r_invgInfo",
      "$state",
      "$stateParams",
      "$translate",
      "$translatePartialLoader",
      "$uibModal",
      "InvoicingService",
      "Notification",
      "SessionService",
    ];

    constructor(
      private r_invgInfo: InvoicingInformation,
      private $state: ng.ui.IStateService,
      private $stateParams: ng.ui.IStateParamsService,
      private $translate: ngt.ITranslateService,
      private $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $uibModal,
      private invoicingService: services.InvoicingService,
      private notification: any,
      private sessionService: common.session.SessionService
    ) {
      $translatePartialLoader.addPart("customer/invoicing");

      this.invgInfo = new InvoicingInformation();
      angular.copy(r_invgInfo, this.invgInfo);

      this.activeTab = $stateParams.tab;

      if (
        this.sessionService.session.company.country.toLowerCase() === "france"
      ) {
        this.accFirmIsFrench = true;
        this.requiredProperties.push("siret", "legalForm", "rcs");
      } else {
        this.accFirmIsFrench = false;
      }

      this.fillDefaultValues();
    }

    /**
     * On page opening, for unfilled required fields, retrieves possibly relevant data from the user's company for each field
     */
    fillDefaultValues(): void {
      let company = this.sessionService.session.company;
      let firstConfig = !this.invgInfo.companyName; // True if it is the first time the user uses the configuration page, false otherwise

      this.requiredProperties.forEach((property) => {
        if (this.invgInfo[property] == null || this.invgInfo[property] == "") {
          let defaultValue = null;
          switch (property) {
            case "companyName":
              defaultValue = company.name;
              break;
            case "address":
              defaultValue = company.addresse;
              break;
            case "zip":
              defaultValue = company.postcode;
              break;
            case "city":
              defaultValue = company.town;
              break;
            case "country":
              defaultValue = company.country;
              break;
            case "vatNumber":
              defaultValue = company.numTVA;
              break;
            case "siret":
              defaultValue = company.siret;
              break;
            case "legalForm":
              defaultValue = company.legalForm;
              break;
            case "capital":
              defaultValue = company.capital ? parseFloat(company.capital) : 0;
              break;
            case "rcs":
              defaultValue = company.rcs;
              break;
          }
          this.invgInfo[property] = defaultValue;
        }
      });
    }

    /**
     * Encode the uploaded logo as a base 64 string
     */
    encodeLogoAsUrl(): void {
      let logoInput = document.getElementById("logoInput") as HTMLInputElement;
      let logoPreview = document.getElementById(
        "logoPreview"
      ) as HTMLImageElement;
      let logoErrorDiv = document.getElementById("logoErrorDiv");

      try {
        if (logoInput.files.length == 1) {
          let file = logoInput.files[0];

          if (file.size > 500000) {
            throw new Error(
              this.$translate.instant("INVOICING.CONFIGURATION.fileTooLarge")
            );
          }

          let reader = new FileReader();
          let self = this;

          reader.onloadend = function () {
            logoErrorDiv.innerHTML = "";
            self.invgInfo.base64Logo = reader.result.toString();
            logoPreview.src = reader.result.toString();
            logoInput.value = "";
          };
          reader.readAsDataURL(file);
        }
      } catch (e) {
        logoErrorDiv.innerHTML = e.message;
      }
    }

    /**
     * Adds a variable tag to an input
     * @param variable The selected variable name
     * @param inputName The target input name
     * @param language The currently defined language
     */
    addVariableToInput(variable: string, inputName: string, language: string) {
      let input: HTMLTextAreaElement = document.getElementById(
        inputName + "Input"
      ) as HTMLTextAreaElement;
      if (input.value[input.selectionStart - 1] != undefined) {
        variable = " " + variable;
      }
      if (
        input.value[input.selectionEnd] != undefined &&
        input.value[input.selectionEnd] != " "
      ) {
        variable += " ";
      }

      let value = this.invgInfo.localizedValues[language][inputName];

      if (!value) {
        this.invgInfo.localizedValues[language][inputName] = "";
        value = "";
      }

      let before = value.substring(0, input.selectionStart);
      let after = value.substring(input.selectionEnd);
      this.invgInfo.localizedValues[language][inputName] =
        before + variable + after;
    }

    /**
     * Selects or unselects the increment which has been clicked
     * @param index The index of the increment
     */
    selectIncrement(index: number): void {
      if (index > -1) {
        if (this.selectedIncrement === index) {
          this.selectedIncrement = null;
        } else {
          this.selectedIncrement = index;
        }
      }
    }

    /**
     * Creates a new increment with some default values and adds it to the InvoicingInformation
     */
    addIncrement(): void {
      let newIncrement = new InvoicingInformationIncrement();
      newIncrement.name =
        this.$translate.instant("INVOICING.CONFIGURATION.numbering") +
        " " +
        (this.invgInfo.increments.length + 1);
      newIncrement.type = "invoice";
      newIncrement.lastUseDate = "-1";
      newIncrement.lastUsedValue = 0;

      this.invgInfo.increments.push(newIncrement);
    }

    /**
     * Deletes the currently selected increment from the InvoicingInformation
     */
    deleteIncrement(): void {
      this.invgInfo.increments.splice(this.selectedIncrement, 1);
      this.selectedIncrement = null;
    }

    /**
     * Selects or unselects a vat rate
     * @param index The index of the target vat rate
     */
    selectVatRate(index: number): void {
      if (index > -1) {
        if (this.selectedVatRate === index) {
          this.selectedVatRate = null;
        } else {
          this.selectedVatRate = index;
        }
      }
    }

    /**
     * Adds a vat rate to the table and to the invgInfo object
     */
    addVatRate(): void {
      let newVatRate = new InvoicingInformationVatRate();
      newVatRate.name = "Taux n°" + (this.invgInfo.vatRates.length + 1);
      newVatRate.rate = 0;
      newVatRate.description = "";

      this.invgInfo.vatRates.push(newVatRate);
    }

    /**
     * Deletes a vat rate from both table and invgInfo
     */
    deleteVatRate(): void {
      this.invgInfo.vatRates.splice(this.selectedVatRate, 1);
      this.selectedVatRate = null;
    }

    /**
     * Opens a sendMailModal to send an example mail
     * This mail won't contain (as of dec. 2019) any attachment
     */
    sendTestMail(): void {
      let self = this;
      this.$uibModal
        .open({
          templateUrl: "tpl/website/invoicing/modal/sendMailModal.html",
          controller: "SendMailModalController",
          controllerAs: "mailMdCtrl",
          backdrop: "static",
          resolve: {
            r_client: () => {
              return null;
            },
            r_invgInfo: () => {
              return self.invgInfo;
            },
            r_invoiceKey: () => {
              return null;
            },
            r_invoice: () => {
              return null;
            },
          },
        })
        .result.then((res) => {
          if (res != false) {
            if (res.status == 0) {
              this.notification.success(
                this.$translate.instant("INVOICING.CONFIGURATION.testMailSent")
              );
            } else {
              this.notification.error(
                this.$translate.instant("INVOICING.CONFIGURATION.mailError")
              );
            }
          }
        });
    }

    /**
     * Verifies and validates each required fields of the page
     */
    validateForm(): void {
      let errorFlag: boolean = false;
      let errorsArr: string[] = [];

      this.requiredProperties.forEach((property) => {
        if (!this.invgInfo[property]) {
          errorFlag = true;
          errorsArr.push(property);
        } else if (property === "siret" && this.invgInfo[property]) {
          if (!this.verifySiret(this.invgInfo.siret)) {
            errorFlag = true;
            errorsArr.push(property);
          }
        } else if (property === "legalForm" && this.invgInfo[property]) {
          if (!this.verifyLegalForm(this.invgInfo.legalForm)) {
            errorFlag = true;
            errorsArr.push(property);
          }
        } else if (property === "rcs" && this.invgInfo[property]) {
          if (!this.verifyRCS(this.invgInfo.rcs)) {
            errorFlag = true;
            errorsArr.push(property);
          }
        }
      });

      /* check capital if company is french -> capital can be 0 or higher */
      if (
        this.sessionService.session.company.country.toLowerCase() === "france"
      ) {
        if (!this.verifyCapital(this.invgInfo.capital)) {
          errorFlag = true;
          errorsArr.push("capital");
        }
      }

      if (!errorFlag) {
        this.saveChanges();
      } else {
        this.notification.warning(
          this.$translate.instant("INVOICING.CONFIGURATION.emptyFieldsMsg")
        );
        errorsArr.forEach((errorProp) => {
          this.invalidInputBooleans[errorProp] = true;
        });
      }
    }

    /**
     * Calls invoicingService to save the changes in the DB
     */
    saveChanges(): void {
      this.invoicingService
        .updateInformation(this.sessionService.session.sessionID, this.invgInfo)
        .then((response) => {
          if (response.status == 0) {
            this.notification.success(
              this.$translate.instant("INVOICING.CONFIGURATION.savingSuccess")
            );
          } else {
            throw new Error(
              "The server returned the following status : " + response.status
            );
          }
        })
        .catch((e) => {
          console.warn(e);
          this.notification.error(
            this.$translate.instant("INVOICING.CONFIGURATION.savingError")
          );
        });
    }

    verifySiret(number: any) {
      if (isNaN(number) || number.length != 14) {
        return false;
      } else {
        let n = 0;
        let total = 0;
        for (let i = 13; i >= 0; i--) {
          let step = (number.charCodeAt(i) - 48) * (n + 1);
          total += step > 9 ? step - 9 : step;
          n = 1 - n;
        }
        if (total % 10 == 0) {
          return true;
        } else {
          return false;
        }
        //return total % 10 == 0 ? true : false;
      }
    }

    verifyLegalForm(text: string) {
      let letterNumber = /^[A-Za-zÀ-ÖØ-öø-ÿ]+$/; //^[a-zA-Z]+$/
      if (letterNumber.test(text)) {
        return true;
      } else {
        return false;
      }
    }
    verifyRCS(text: string) {
      var regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/; //^[a-zA-Z]
      if (regex.test(text)) {
        return true;
      } else {
        return false;
      }
    }
    verifyCapital(capital: number) {
      if (capital != undefined) {
        if (capital < 0) {
          return false;
        } else if (capital == 0) {
          return true;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }
}
angular
  .module("app")
  .controller(
    "InvoicingConfigurationController",
    app.functionality.invoicing.controllers.InvoicingConfigurationController
  );
