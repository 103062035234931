/// <reference path="../_app.ts" />

module app.model {
  import Exercice = app.model.Exercice;
  import DashBoardParameter = app.model.parameter.DashBoardParameter;
  import DashboardReport = app.model.parameter.DashboardReport;

  export class Company {
    classStructure: any = {
      importConnectorDesc: app.model.Descriptor,
      sendfileConfigDesc: app.model.Descriptor,
      accoutingFirmDesc: app.model.Descriptor,
      exerciceArr: {
        array: app.model.Exercice,
      },
      dashboardParam: app.model.parameter.DashBoardParameter,
      dashboardReport: app.model.parameter.DashboardReport,
    };

    NEW_IMPORT(): number {
      return 0;
    }
    COMPLETED_IMPORT(): number {
      return 1;
    }
    FAILED_IMPORT(): number {
      return 2;
    }
    OUTDATED_IMPORT(): number {
      return 3;
    }
    IMPORTING(): number {
      return 4;
    }

    //VAT MODE
    QUARTERLY_VAT_MODE(): number {
      return 1;
    }
    MONTHLY_VAT_MODE(): number {
      return 2;
    }
    NO_VAT_MODE(): number {
      return 3;
    }

    // COMPANY TYPE
    SPRL_TYPE(): number {
      return 0;
    }
    IPP_TYPE(): number {
      return 1;
    }
    SA_TYPE(): number {
      return 2;
    }
    ASBL_TYPE(): number {
      return 3;
    }
    AISBL_TYPE(): number {
      return 4;
    }
    SCS_TYPE(): number {
      return 5;
    }

    key: string;
    IDCompany: string;
    name: string;
    members: Array<any>;
    numTVA: string;
    addresse: string;
    town: string;
    postcode: string;
    creationDate: Date;

    // currAccountingSituation:AccountingSituation; //should be null, never sent by the server

    companyType: number;

    // WINBOOKS PARAM **/
    reference: string;
    currBookyear: number;
    exercice: string;

    // connector
    connector_key: string;

    // Diret Invoice Param
    directInvKey: string;

    // GRIVE PARAM
    gDriveDirID: string;
    incomeStatementTemplateKey: string;

    // cashflowParam:CashFlowParameter;
    dashboardParam: DashBoardParameter;
    dashboardReport: DashboardReport;
    // budget:Budget;

    scenariArr: Array<any>;

    // importInfo:ImportInfo = null;

    modelPaiementArr: Array<any>;

    vat_to_pay_AccountArr: Array<any>;

    vat_to_retrieve_AccountArr: Array<any>;

    vat_regul_AccountArr: Array<any>;

    vat_balance_AccountArr: Array<any>;

    isDataFromImport: boolean;
    tooBigData: boolean;

    exerciceArr: Array<Exercice>;
    vatMode: number;

    debugVariable: boolean;

    importConnectorDesc: Descriptor;
    sendfileConfigDesc: Descriptor;
    accoutingFirmDesc: Descriptor;

    accountNumDepth: number;

    bookUploadStateArr: Array<any>;

    public customer_AccountArr: any[];

    public supplier_AccountArr: any[];

    plan: number;

    country: string;

    constructor() {}

    giveIDCompany() {
      return this.IDCompany;
    }
  }
}
