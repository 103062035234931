/// <reference path="../_app.ts" />

module app.model {

    import Company = app.model.Company;
    import AccountingEntry = app.model.AccountingEntry;

    export class LostPaiement extends Operation {

        static $inject = ["AccountTools", "BookHistoric"];

        public amount: number;
        public dueDate: Date;
        public encodingDate: Date;
        public third: string;
        public comment: string;
        public type: number;

        constructor(public accountTools: app.functionality.common.utils.AccountTools, public bookHistoric: app.model.constants.BookHistoricConst) {
            super();
        }

        public updateDueDate(newOp: Operation): void {
            let s: LostPaiement = newOp as LostPaiement;
            this.dueDate = s.dueDate;
            this.accountingEntryArr = s.accountingEntryArr;
        }

        public getMainMeaningAcc(c: Company): AccountingEntry {
            if (this.accountingEntryArr) {
                this.accountingEntryArr.forEach(function(acc) {
                    if (((this.type === this.bookHistoric.SALE && this.accountTools.isIncludeInRootArr(acc.account, c.customer_AccountArr) && acc.dbtype === this.bookHistoric.BANK )
                        || (this.type === this.bookHistoric.PURCHASE && this.accountTools.isIncludeInRootArr(acc.account, c.supplier_AccountArr) && acc.dbtype === this.bookHistoric.BANK && acc.journalType !== null ))) {
                        return acc;
                    }
                });
            }
            return null;
        }

    }

}
