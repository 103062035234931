/// <reference path="../../_app.ts" />

module app.functionality.menu {

    export class MenuResize implements ng.IDirective {
       
        public windowHeight;

        constructor(private $window) {
        }

        link = (scope: ng.IScope, elem: ng.IAugmentedJQuery, attrs: ng.IAttributes) => {
            let w = angular.element(this.$window);

            let initializeHeightChange = (<any>w[0]).innerHeight;
            if (initializeHeightChange <= 730) {
                elem.addClass("lastList");
            } else if (initializeHeightChange > 730) {
                elem.removeClass("lastList");
            }
            
            w.bind("resize", () => {
                let heightChange = (<any>w[0]).innerHeight;
                if (heightChange <= 730) {
                    elem.addClass("lastList");
                } else if (heightChange > 730) {
                    elem.removeClass("lastList");
                }
                scope.$apply();
            });  
        }
        
        static factory(): ng.IDirectiveFactory {
            const directive = ($window) => 
                new MenuResize($window);
            directive.$inject = ["$window"];
            return directive;
        }
    }
}

angular.module("app").directive("menuResize", app.functionality.menu.MenuResize.factory());