/// <reference path="../../_app.ts" />

module app.model.invoiceReminder {

    import ILostPaiement = app.model.interface.ILostPaiement;
    import ISale = app.model.interface.ISale;
    import IReminder = app.model.interface.IReminder;
    import ITemplateReminder = app.model.interface.ITemplateReminder;
    import IContact = app.model.interface.IContact;

    /**
     * This class is used to create the reminder when the user wants to send a mail
     */
    export class Reminder implements IReminder {

        //private static MAX_LEVEL_REMINDER:number = 3;

        public key: string;
        public companyKey: string;
        public date: Date;
        public sales: ISale[];
        public payments: ILostPaiement[];
        public reference: string;
        public contact: IContact;

        public template: ITemplateReminder;

        //public level: number;

        constructor(companyKey: string, reference: string) {
            this.companyKey = companyKey;
            this.reference = reference;
            this.date = new Date();
            this.sales = [];
            this.payments = [];
            //this.level = 0;
        }

        public addSale(sale: ISale) {
            this.sales.push(sale);
        }

        public addPayment(payment: ILostPaiement) {
            this.payments.push(payment);
        }

        /**
         * This function returns the amount that is still needed to be paid
         * @returns {number} all the sales - all the payments
         */
        public getAmountLeft(): number {
            let amount = 0;

            this.sales.forEach(function (value) {
                amount+= value.amount;
            });

            this.payments.forEach(function (value) {
                amount += value.amount;
            });

            return amount;
        }

        /**
         * This function returns true if there is no sales selected
         * @returns {boolean} true if there is no sales selected
         */
        public isEmpty(): boolean {
            return this.sales.length == 0;
        }

        /**
         * This function calculates the level of the reminder.
         * The level used is the highest reminder number of a sale + 1
         */
       /* public setLevel() {
            let reminderNumber = 0;
            this.sales.forEach(function (value, index, array) {
               if (value.reminderNumber > reminderNumber) {
                   reminderNumber = value.reminderNumber;
               }
            });
            this.level = reminderNumber >= Reminder.MAX_LEVEL_REMINDER ? Reminder.MAX_LEVEL_REMINDER : reminderNumber+1;
        }*/
    }
}