/// <reference path="../../../_app.ts" />

module app.functionality.common.rest {

    interface IServices {
        root: restangular.IService;
    }

    class Services implements IServices {
        constructor(public root: restangular.IService) {
        }
    }

    export class RestService {

        private services: IServices;

        static $inject = ["Restangular", "ENV", "RESTAPI"];
        constructor(private Restangular: restangular.IService,
                    private ENV: app.config.constants.environment.ConstantEnv,
                    private RESTAPI: app.config.constants.ConstantRestApi) {
        }

        private getRestangular = (): restangular.IService => {
            let self = this;
            return this.Restangular.withConfig((RestangularConfigurer: restangular.IProvider) => {
                RestangularConfigurer.setBaseUrl(self.ENV.baseUrl);
            });
        }

        getRoot = (): restangular.IService => {
            if (_.isUndefined(this.services)) {
                this.services = new Services(this.getRestangular());
            }

            return this.services.root;
        }
    }

}

angular.module("app").service("RestService", app.functionality.common.rest.RestService);
