/// <reference path="../_app.ts" />

module app.model {

    // import controller.session.SessionMgr;

    // import mx.collections.ArrayCollection;

    export class Exercice {

        classStructure : any = {
            quarterlyArr: {
                array: app.model.Quarterly
            },
            periodArr: {
                array: app.model.Period
            },
            openingPeriod : app.model.Period,
            closingPeriod : app.model.Period
        };

        public key: string;

        public bookyear: number;
        public title: string;
        public shortTitle: string;
        public isQuarterlyMode: boolean;
        public periodArr: Array<Period>;

        public nbrPeriod: number;

        public nbrQuarterly: number;
        public quarterlyArr: Array<Quarterly>;

        public openingPeriod: Period;
        public closingPeriod: Period;

        constructor() {
        }

        //public function getCurrentPeriod():Period {
        //     var lastImportDate:Date = SessionMgr.getCurrentUserSession().company.importInfo.importDate;
        //     if (lastImportDate)
        //         if (isQuarterlyMode) {
        //             for each(var q:Quarterly in quarterlyArr) {
        //                 for each(var p:Period in q.periodArr) {
        //                     if (p.monthIndex == lastImportDate.getMonth() && p.year == lastImportDate.getFullYear())
        //                         return p;
        //                 }
        //             }
        //         }
        //         else {
        //             for each(var p2:Period in periodArr) {
        //                 if (p2.monthIndex == lastImportDate.getMonth() && p2.year == lastImportDate.getFullYear())
        //                     return p2;
        //             }
        //         }

        //     return null;
        // }

    }

}
