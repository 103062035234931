/// <reference path="../../../_app.ts" />

namespace app.functionality.upload {
  import UploadService = app.functionality.upload.UploadService;
  import ConstantEnv = app.config.constants.environment.ConstantEnv;
  import SessionService = app.functionality.common.session.SessionService;
  import ModalService = app.functionality.common.modals.ModalService;

  export class ConfirmSendModalController {
    private fileInput;
    private uploadFolderKey;
    public valueNow: any;
    public valueMax: any;
    public percent: any;
    public totalFiles: number;
    public countFile: number;
    public mapFiles: any;
    private mapUploadError: any;
    public accFirmKey: any;
    public typeFile: any;
    public ippKey: any;
    public fiscalYear: any;

    static $inject = [
      "$uibModalInstance",
      "r_data",
      "$http",
      "UploadService",
      "$rootScope",
      "ENV",
      "$sanitize",
      "SessionService",
    ];

    constructor(
      public $uibModalInstance: any,
      r_data: any,
      protected $http: any,
      private uploadService: UploadService,
      private $rootScope: ng.IRootScopeService,
      private ENV: ConstantEnv,
      private $sanitize: ng.sanitize.ISanitizeService,
      private sessionService: SessionService
    ) {}

    public cancel() {
      this.$uibModalInstance.close(false);
    }

    public confirm() {
      this.$uibModalInstance.close(true);
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "ConfirmSendModalController",
    app.functionality.upload.ConfirmSendModalController
  );
