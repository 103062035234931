/// <reference path="../../_app.ts" />

module app.model.exchange {
    import Account = app.model.Account;
    import AccountInformation = app.model.AccountInformation;

    export class BalanceData {

        classStructure : any = {
            accountArr: {
                array: app.model.Account
            },
            accountInfoArr: {
                array: app.model.AccountInformation
            }
        };

        public accountArr: Array<Account>;
        public accountInfoArr: Array<AccountInformation>;

        constructor() {
        }
    }
}