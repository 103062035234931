/// <reference path="../../../_app.ts" />

module app.modules.ratio.directive.ratio {

    export class RatioDirective implements ng.IDirective {

        restrict = "E";
        replace = true;
        scope = false;

        template = function (element, attrs) {

            if (!angular.isDefined(attrs.ratioStatus)) {
                attrs.ratioStatus = "Default status";
            }

            if (!angular.isDefined(attrs.ratioTitle)) {
                attrs.ratioTitle = "Default title";
            }

            if (!angular.isDefined(attrs.ratioContent)) {
                attrs.ratioContent = "Default value";
            }

            if (!angular.isDefined(attrs.helperContent)) {
                attrs.helperContent = "Default helper Content";
            }

            if (!angular.isDefined(attrs.helperPosition)) {
                attrs.helperPosition = "top-right";
            }

            if (!angular.isDefined(attrs.ratioVersion)) {
                attrs.ratioVersion = 1;
            }
            switch (attrs.ratioVersion) {
                case "1":
                    return "<div class='row ratioStyle'>" +
                        "<div class='col-md-5 text-left text-uppercase ratioContent'>" +
                        "<span ng-show='attrs.ratioStatus !== \"\"' class='ratioStatus'>" + attrs.ratioStatus + "</span></div>" +
                        "<div class='col-md-2 text-right text-uppercase ratioContent'><strong>" + attrs.ratioTitle + "</strong></div>" +
                        "<div class='col-md-3 text-right text-uppercase ratioContent'><strong>" + attrs.ratioContent + "</strong></div>" +
                        "<div class='col-md-2 text-center ratioContent'><helper position='" +
                        attrs.helperPosition + "' content='" + attrs.helperContent + "'></helper></div>" +
                        "</div>";
                case "2":
                    return "<div class='row'>" +
                        "<div class='col-md-8 text-left'>" + attrs.ratioTitle + "</div>" +
                        "<div class='col-md-3 text-right'><strong>" + attrs.ratioContent + "</strong></div>" +
                        "<div class='col-md-1 text-center'>" +
                        "<helper position='" + attrs.helperPosition + "' content='" + attrs.helperContent + "'></helper></div>" +
                        "</div>";
                case "3":
                    return "<div class='row'>" +
                        "<div class='col-md-6 text-right ratioContent'><strong>" + attrs.ratioTitle + "</strong></div>" +
                        "<div class='col-md-4 text-right ratioContent'><strong>" + attrs.ratioContent + "</strong></div>" +
                        "<div class='col-md-1 text-center ratioContent'>" +
                        "<helper position='" + attrs.helperPosition + "' content='" + attrs.helperContent + "'></helper></div>" +
                        "</div>";
                case "4":
                    return "<div class='row ratioRed'>" +
                        "<div class='col-md-6 text-right ratioContentWhite'><strong>" + attrs.ratioTitle + "</strong></div>" +
                        "<div class='col-md-4 text-right ratioContentWhite'><strong>" + attrs.ratioContent + "</strong></div>" +
                        "<div class='col-md-1 text-center ratioContentWhite'>" +
                        "<helper position='" + attrs.helperPosition + "' content='" + attrs.helperContent + "'></helper></div>" +
                        "</div>";
                case "5":
                    return "<div class='row ratioStyle'>" +
                        "<div class='col-md-7 text-right text-uppercase ratioContent'><strong>" + attrs.ratioTitle + "</strong></div>" +
                        "<div class='col-md-3 text-right text-uppercase ratioContent'><strong>" + attrs.ratioContent + "</strong></div>" +
                        "<div class='col-md-2 text-center ratioContent'><helper position='" +
                        attrs.helperPosition + "' content='" + attrs.helperContent + "'></helper></div>" +
                        "</div>";
                case "6":
                    return "<div class='col-lg-1'>" +
                        "<helper content='" + attrs.helperContent + "'></helper></div>" +
                        "</div>";
                case "7":
                    return "<div class='row'>" +
                        "<div class='col-md-11 text-right text-uppercase ratioContent'><strong>" + attrs.ratioContent + "</strong><br /></div>"+
                        "</div>";
                default:
                        return "<p>Please select a ratio version</p>";
            }

        };

        link = (scope: ng.IScope, elem: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) => {
            // TODO : Nothing to do ?
        }

        static factory(): ng.IDirectiveFactory {
            const directive = () => new RatioDirective();

            return directive;
        }
    }
}

angular.module("ratioModule").directive("ratio", app.modules.ratio.directive.ratio.RatioDirective.factory());
