/// <reference path="../../../_app.ts" />

module app.functionality.invoicing.controllers {
  import InvoicingClient = app.model.invoicing.InvoicingClient;
  import Invoice = app.model.invoicing.Invoice;
  import InvoiceLine = app.model.invoicing.InvoiceLine;
  import InvoicingInformation = app.model.invoicing.InvoicingInformation;

  export class InvoicingOverviewController implements angular.IController {
    private type: "invoice" | "creditNote";

    public clients: InvoicingClient[];
    private openingDay: Date; // First day of the client's fiscal year (to fill in the Date input "Du/From" on page loading)

    public invoices: Invoice[]; // Array containing all the client's invoices stored in the DB
    public filteredInvoices: Invoice[]; // Array containing the filtered invoices
    public checkedInvoices: Invoice[] = []; // Array containing invoices selected via checkboxes

    public allInvoicesChecked: boolean = false; // Boolean representing the state all invoices' checkboxes should be in
    public checkedInvoicesPaidCpt: number = 0; // Amount of paid invoices within checked invoices

    public invgInfo: InvoicingInformation;

    // Object containing all the filtering values, binded to the filter inputs
    public filter: {
      invoiceNumber?: any;
      type?: any;
      clientName?: any;
      status?: any;
      dateMin?: any;
      DateMax?: any;
      amountMin?: any;
      AmountMax?: any;
    };

    $onInit() {}

    static $inject = [
      "r_clients",
      "r_invoices",
      "r_openingPeriod",
      "r_invgInfo",
      "$translatePartialLoader",
      "$translate",
      "$scope",
      "$state",
      "$stateParams",
      "$uibModal",
      "SessionService",
      "Notification",
      "InvoicingService",
    ];

    constructor(
      r_clients: InvoicingClient[],
      r_invoices: Invoice[],
      r_openingPeriod: {
        bookyear: number;
        key: string;
        monthIndex: number;
        periodIndex: number;
        year: number;
      },
      r_invgInfo,
      private $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $translate: ngt.ITranslateService,
      private $scope,
      private $state: ng.ui.IStateService,
      private $stateParams: ng.ui.IStateParamsService,
      private $uibModal,
      private sessionService: common.session.SessionService,
      private notification: any,
      private invoicingService: services.InvoicingService
    ) {
      $translatePartialLoader.addPart("customer/invoicing");

      switch (this.$stateParams.type) {
        case "invoice":
          this.type = "invoice";
          break;
        case "creditNote":
          this.type = "creditNote";
          break;
        default:
          this.type = "invoice";
          break;
      }

      this.invgInfo = new InvoicingInformation();
      angular.copy(r_invgInfo, this.invgInfo);

      this.clients = r_clients;
      this.invoices = r_invoices;

      this.allInvoicesChecked = false;

      // Filter initialization to filter only invoices since the beginning of the fiscal year
      this.openingDay = new Date(
        r_openingPeriod.year,
        r_openingPeriod.monthIndex,
        1,
        0,
        0,
        0
      );
      this.filter = { dateMin: this.openingDay };
      this.applyFilter();
    }

    /**
     * Redirects the user to the configuration page if the configuration doesn't exist or isn't valid
     */
    redirectToEdition(invoice?: Invoice): void {
      if (this.invgInfo != null && this.invgInfo.isValidForInvoicing()) {
        if (invoice) {
          this.$state.go("websitelayout.headerandmenu.invoicingEdition", {
            invoiceKey: invoice.key,
            invoice: invoice,
          }); // Displays an existing invoice
        } else {
          this.$state.go("websitelayout.headerandmenu.invoicingEdition", {
            type: this.type,
          }); // New invoice edition
        }
      } else {
        try {
          let self = this;
          let msg = this.invgInfo == null ? "configMsg1_1" : "configMsg1_2";
          this.$uibModal
            .open({
              templateUrl: "tpl/website/invoicing/modal/invgConfModal.html",
              controller: "InvgConfModalController",
              controllerAs: "confMdCtrl",
              backdrop: "static",
              resolve: {
                r_title: function () {
                  return self.$translate.instant(
                    "INVOICING.OVERVIEW.goToConfiguration"
                  );
                },
                r_content: function () {
                  return (
                    "" +
                    "<p>" +
                    self.$translate.instant("INVOICING.OVERVIEW." + msg, {
                      type: self.$translate.instant(
                        "INVOICING.SHARED." + self.type
                      ),
                    }) +
                    "</p>" +
                    "<p>" +
                    self.$translate.instant("INVOICING.OVERVIEW.configMsg2") +
                    "<p>"
                  );
                },
                r_buttons: function () {
                  return {
                    cancel: {
                      text: self.$translate.instant("INVOICING.MODAL.cancel"),
                      class: "btn-default",
                    },
                    confirm: {
                      text: self.$translate.instant(
                        "INVOICING.OVERVIEW.goToConfiguration"
                      ),
                      class: "btn-warning",
                    },
                  };
                },
              },
            })
            .result.then((confirmation) => {
              if (confirmation) {
                this.$state.go(
                  "websitelayout.headerandmenu.invoicingConfiguration"
                );
              }
            });
        } catch (e) {}
      }
    }

    /**
     * Invoked when the topmost checkbox is clicked, checks (or unchecks) all others checkboxes according to the allInvoicesChecked variable
     */
    checkAllCurrentInvoices() {
      for (let i = 0; i < this.filteredInvoices.length; i++) {
        if (this.allInvoicesChecked && this.filteredInvoices[i].status == 2) {
          this.checkedInvoicesPaidCpt++;
        }

        let chbxElement = document.getElementById(
          "chbx" + i
        ) as HTMLInputElement;
        if (chbxElement) {
          chbxElement.checked = this.allInvoicesChecked;
        }
      }

      if (this.allInvoicesChecked) {
        angular.copy(this.filteredInvoices, this.checkedInvoices);
      } else {
        this.checkedInvoices = [];
        this.checkedInvoicesPaidCpt = 0;
      }
    }

    /**
     * Invoked when a checkbox is clicked, adds or removes the corresponding invoice from checkedInvoices
     */
    checkInvoice(index) {
      let position = -1;
      let isPaid: boolean = this.filteredInvoices[index].status == 2;

      for (let i = 0; i < this.checkedInvoices.length; i++) {
        if (this.checkedInvoices[i].key == this.filteredInvoices[index].key) {
          position = i;
          break;
        }
      }

      if (position == -1) {
        // If the invoice wasn't checked before
        this.checkedInvoices.push(this.filteredInvoices[index]);
        if (isPaid) {
          this.checkedInvoicesPaidCpt++;
        }
      } else {
        this.checkedInvoices.splice(position, 1);
        if (isPaid) {
          this.checkedInvoicesPaidCpt--;
        }
      }
    }

    /**
     * Uses the filtering values to filter the "invoices" array and stores the result in filteredInvoices
     */
    applyFilter(): void {
      this.filteredInvoices = this.invoices;

      for (let property in this.filter) {
        if (property.search("amount") != -1 || property.search("date") != -1) {
          if (property.search("amount") != -1) {
            if (property.search("Min") != -1) {
              this.filteredInvoices = this.filteredInvoices.filter(
                (invoice) => {
                  return invoice.inclVatTotal >= this.filter[property];
                }
              );
            } else if (
              property.search("Max") != -1 &&
              this.filter[property] != ""
            ) {
              this.filteredInvoices = this.filteredInvoices.filter(
                (invoice) => {
                  return invoice.inclVatTotal <= this.filter[property];
                }
              );
            }
          } else {
            if (property.search("Min") != -1) {
              this.filteredInvoices = this.filteredInvoices.filter(
                (invoice) => {
                  return (
                    new Date(invoice.invoiceDate).getTime() >=
                    new Date(this.filter[property]).getTime()
                  );
                }
              );
            } else if (
              property.search("Max") != -1 &&
              this.filter[property] != null
            ) {
              this.filteredInvoices = this.filteredInvoices.filter(
                (invoice) => {
                  return (
                    new Date(invoice.invoiceDate).getTime() <=
                    new Date(this.filter[property]).getTime() +
                      24 * 60 * 60 * 1000
                  );
                }
              );
            }
          }
        } else if (property == "clientName") {
          this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
            return (
              this.clients
                .filter((client) => {
                  return client.key == invoice.clientKey;
                })[0]
                .fullName.toLowerCase()
                .search(this.filter[property].toLowerCase()) != -1
            );
          });
        } else if (property == "status" && this.filter[property] == "1-late") {
          this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
            return (
              invoice[property] == 1 &&
              invoice.dueDate != null &&
              new Date(invoice.dueDate).getTime() < Date.now()
            );
          });
        } else {
          this.filteredInvoices = this.filteredInvoices.filter((invoice) => {
            return (
              invoice[property]
                .toString()
                .toLowerCase()
                .search(this.filter[property].toLowerCase()) != -1
            );
          });
        }
      }

      // Unchecks all invoices and checkboxes
      this.allInvoicesChecked = false;
      this.checkAllCurrentInvoices();
    }

    /**
     * Resets the filter to its original value, then applies it
     */
    resetFilters(): void {
      this.filter = { type: "invoice", dateMin: this.openingDay };
      this.applyFilter();
    }

    /**
     * Marks all checked invoices as paid, then gets back invoices from the server to refresh the view
     */
    markAsPaid(): void {
      try {
        let invoices = this.checkedInvoices;
        let self = this;
        for (let i = 0; i < invoices.length; i++) {
          this.invoicingService
            .updateStatus(
              this.sessionService.session.sessionID,
              this.checkedInvoices[i].key,
              2
            )
            .then((response) => {
              if (i == invoices.length - 1) {
                this.invoicingService
                  .getAllInvoices(
                    this.sessionService.session.sessionID,
                    this.sessionService.session.company.key,
                    this.type == "invoice"
                      ? 1
                      : this.type == "creditNote"
                      ? 2
                      : 0
                  )
                  .then((response) => {
                    this.invoices = response.plain().data;
                    this.applyFilter();
                  });
                this.checkedInvoices = [];
              }
            });
        }
      } catch (e) {
        console.warn(e);
        this.notification.error(
          this.$translate.instant("INVOICING.SHARED.operationFail")
        );
      }
    }

    /**
     * Send all checked invoices to the accounter, then gets back invoices from the server to refresh the view
     */
    sendToAcc(): void {
      let cantSend: boolean;
      for (let i = 0; i < this.checkedInvoices.length; i++) {
        const invoice = this.checkedInvoices[i];
        if (invoice.invoiceKey !== null) {
          cantSend = true;
          break;
        } else {
          cantSend = false;
        }
      }
      if (cantSend) {
        try {
          let self = this;
          let length = 0;
          let msg = this.invgInfo == null ? "configMsg1_1" : "configMsg1_2";
          this.$uibModal
            .open({
              templateUrl: "tpl/website/invoicing/modal/invgSendToAccConf.html",
              controller: "InvgConfSendToAccModalController",
              controllerAs: "sendToAccCtrl",
              backdrop: "static",
              resolve: {
                r_title: function () {
                  return self.$translate.instant(
                    "INVOICING.OVERVIEW.cantSendToAccountantTitle"
                  );
                },
                r_content: function () {
                  if (self.checkedInvoices.length > 1) {
                    return self.$translate.instant(
                      self.type === "invoice"
                        ? "INVOICING.OVERVIEW.cantSendInvoiceToAccountantMultiple"
                        : "INVOICING.OVERVIEW.cantSendCreditNoteToAccountantMultiple"
                    );
                  } else {
                    return self.$translate.instant(
                      self.type === "invoice"
                        ? "INVOICING.OVERVIEW.cantSendInvoiceToAccountantSingle"
                        : "INVOICING.OVERVIEW.cantSendCreditNoteToAccountantSingle"
                    );
                  }
                },
                r_cantSend: function () {
                  return cantSend;
                },
                r_buttons: function () {
                  //IF the array has more than one value show the confirm button to so that we can send those who have not been sent yet
                  if (self.checkedInvoices.length > 1) {
                    return {
                      cancel: {
                        text: self.$translate.instant("INVOICING.MODAL.cancel"),
                        class: "btn-default",
                      },
                      confirm: {
                        text: self.$translate.instant(
                          "INVOICING.OVERVIEW.confirm"
                        ),
                        class: "btn-warning",
                      },
                    };
                    //ELSE only show the cancel button
                  } else {
                    return {
                      cancel: {
                        text: self.$translate.instant("INVOICING.MODAL.cancel"),
                        class: "btn-default",
                      },
                    };
                  }
                },
              },
            })
            .result.then((confirmation) => {
              if (confirmation) {
                try {
                  length = this.checkedInvoices.length;
                  let invoices = this.checkedInvoices.filter((inv) => {
                    return inv.invoiceKey === null;
                  }); // get only the invoices that have not been send yet
                  let self = this;
                  let userInvoicesKeys: Array<String> = Array<string>();
                  for (let i = 0; i < invoices.length; i++) {
                    userInvoicesKeys.push(this.checkedInvoices[i].key);
                  }

                  this.invoicingService
                    .sendToAcc(
                      this.sessionService.session.sessionID,
                      userInvoicesKeys
                    )
                    .then((response) => {
                      this.invoicingService
                        .getAllInvoices(
                          this.sessionService.session.sessionID,
                          this.sessionService.session.company.key,
                          this.type == "invoice"
                            ? 1
                            : this.type == "creditNote"
                            ? 2
                            : 0
                        )
                        .then((response) => {
                          this.invoices = response.plain().data;
                          this.applyFilter();
                          if (length > 1) {
                            this.notification.success(
                              this.$translate.instant(
                                this.type === "invoice"
                                  ? "INVOICING.OVERVIEW.sendInvoiceToAccountantSuccessMultiple"
                                  : "INVOICING.OVERVIEW.sendCreditNoteToAccountantSuccessMultiple"
                              )
                            );
                          } else {
                            this.notification.success(
                              this.$translate.instant(
                                this.type === "invoice"
                                  ? "INVOICING.OVERVIEW.sendInvoiceToAccountantSuccessSingle"
                                  : "INVOICING.OVERVIEW.sendCreditNoteToAccountantSuccessSingle"
                              )
                            );
                          }
                        });
                      this.checkedInvoices = [];
                    });
                } catch (e) {
                  console.warn(e);
                  this.notification.error(
                    this.$translate.instant("INVOICING.SHARED.operationFail")
                  );
                }
              }
            });
        } catch (e) {
          console.warn(e);
        }
      } else {
        try {
          let self = this;
          let msg = this.invgInfo == null ? "configMsg1_1" : "configMsg1_2";
          this.$uibModal
            .open({
              templateUrl: "tpl/website/invoicing/modal/invgSendToAccConf.html",
              controller: "InvgConfSendToAccModalController",
              controllerAs: "sendToAccCtrl",
              backdrop: "static",
              resolve: {
                r_title: function () {
                  return self.$translate.instant(
                    "INVOICING.OVERVIEW.confirmSendToAccTitle"
                  );
                },
                r_content: function () {
                  if (self.checkedInvoices.length > 1) {
                    return self.$translate.instant(
                      self.type === "invoice"
                        ? "INVOICING.OVERVIEW.confirmSendInvoiceToAccContentMultiple"
                        : "INVOICING.OVERVIEW.confirmSendCreditNoteToAccContentMultiple"
                    );
                  } else {
                    return self.$translate.instant(
                      self.type === "invoice"
                        ? "INVOICING.OVERVIEW.confirmSendInvoiceToAccContentSingle"
                        : "INVOICING.OVERVIEW.confirmSendCreditNoteToAccContentSingle"
                    );
                  }
                },
                r_cantSend: function () {
                  return cantSend;
                },
                r_buttons: function () {
                  return {
                    cancel: {
                      text: self.$translate.instant("INVOICING.MODAL.cancel"),
                      class: "btn-default",
                    },
                    confirm: {
                      text: self.$translate.instant(
                        "INVOICING.OVERVIEW.confirm"
                      ),
                      class: "btn-warning",
                    },
                  };
                },
              },
            })
            .result.then((confirmation) => {
              if (confirmation) {
                try {
                  let invoices = this.checkedInvoices;
                  let self = this;
                  let userInvoicesKeys: Array<String> = Array<string>();
                  for (let i = 0; i < invoices.length; i++) {
                    userInvoicesKeys.push(this.checkedInvoices[i].key);
                  }

                  this.invoicingService
                    .sendToAcc(
                      this.sessionService.session.sessionID,
                      userInvoicesKeys
                    )
                    .then((response) => {
                      this.invoicingService
                        .getAllInvoices(
                          this.sessionService.session.sessionID,
                          this.sessionService.session.company.key,
                          this.type == "invoice"
                            ? 1
                            : this.type == "creditNote"
                            ? 2
                            : 0
                        )
                        .then((response) => {
                          this.invoices = response.plain().data;
                          this.applyFilter();
                          this.notification.success(
                            this.$translate.instant(
                              "INVOICING.SHARED.operationSuccess"
                            )
                          );
                        });
                      this.checkedInvoices = [];
                    });
                } catch (e) {
                  console.warn(e);
                  this.notification.error(
                    this.$translate.instant("INVOICING.SHARED.operationFail")
                  );
                }
              }
            });
        } catch (e) {
          console.warn(e);
        }
      }
    }

    /**
     * Deletes an invoice. Opens a modal to confirm, then gets back invoices from the server to refresh the view
     */
    delete(): void {
      try {
        let invoices = this.checkedInvoices;
        let self = this;
        let length = 0;
        this.$uibModal
          .open({
            templateUrl: "tpl/website/invoicing/modal/invgConfModal.html",
            controller: "InvgConfModalController",
            controllerAs: "confMdCtrl",
            backdrop: "static",
            resolve: {
              r_title: function () {
                return self.$translate.instant("INVOICING.MODAL.delete");
              },
              r_content: function () {
                return (
                  "" +
                  "<p>" +
                  self.$translate.instant("INVOICING.OVERVIEW.deleteMsg1", {
                    amount: invoices.length,
                    type: self.type,
                  }) +
                  ".</p>" +
                  "<p><u>" +
                  self.$translate.instant("INVOICING.OVERVIEW.deleteMsg2") +
                  "</u><p>"
                );
              },
              r_buttons: function () {
                return {
                  cancel: {
                    text: self.$translate.instant("INVOICING.MODAL.cancel"),
                    class: "btn-default",
                  },
                  confirm: {
                    text: self.$translate.instant("INVOICING.MODAL.delete"),
                    class: "btn-danger",
                  },
                };
              },
            },
          })
          .result.then((confirmation) => {
            if (confirmation) {
              for (let i = 0; i < this.checkedInvoices.length; i++) {
                this.invoicingService
                  .deleteInvoice(
                    this.sessionService.session.sessionID,
                    this.checkedInvoices[i].key
                  )
                  .then((response) => {
                    length = this.checkedInvoices.length;
                    if (i == this.checkedInvoices.length - 1) {
                      this.invoicingService
                        .getAllInvoices(
                          this.sessionService.session.sessionID,
                          this.sessionService.session.company.key,
                          this.type == "invoice"
                            ? 1
                            : this.type == "creditNote"
                            ? 2
                            : 0
                        )
                        .then((response) => {
                          this.invoices = response.plain().data;
                          this.applyFilter();
                          if (length > 1) {
                            this.notification.success(
                              this.$translate.instant(
                                this.type === "invoice"
                                  ? "INVOICING.OVERVIEW.deleteInvoiceSuccessMultiple"
                                  : "INVOICING.OVERVIEW.deleteCreditNoteSuccessMultiple"
                              )
                            );
                          } else {
                            this.notification.success(
                              this.$translate.instant(
                                this.type === "invoice"
                                  ? "INVOICING.OVERVIEW.deleteInvoiceSuccessSingle"
                                  : "INVOICING.OVERVIEW.deleteCreditNoteSuccessSingle"
                              )
                            );
                          }
                        });
                      this.checkedInvoices = [];
                    }
                  });
              }
            }
          });
      } catch (e) {
        console.warn(e);
        this.notification.error(
          this.$translate.instant("INVOICING.SHARED.operationFail")
        );
      }
    }

    /**
     * Returns a string corresponding to the status of the provided invoice (for pending invoices, compares to the due date to determine if the invoice is late)
     * @param invoice The invoice whose status is wanted
     */
    getInvoiceStatusClass(invoice: Invoice): string {
      switch (invoice.status) {
        case 0:
          return "draft";
        case 1:
          if (
            invoice.dueDate != null &&
            new Date(invoice.dueDate).getTime() < Date.now()
          ) {
            return "late";
          } else {
            return "pending";
          }
        case 2:
          return "paid";
      }
    }

    /**
     * Returns the client from its key
     * @param key The client's key
     */
    clientFromKey(key: string): InvoicingClient {
      try {
        let client = this.clients.filter((client) => {
          return client.key == key;
        })[0];
        if (client != undefined) {
          return client;
        } else {
          throw new Error("Client is undefined");
        }
      } catch (e) {
        return null;
      }
    }
  }
}

angular
  .module("app")
  .controller(
    "InvoicingOverviewController",
    app.functionality.invoicing.controllers.InvoicingOverviewController
  );
