/// <reference path="../../../_app.ts" />

module app.functionality.common.converter {

    export class InstanceConverter {

        constructor() {
        }

        public createInstanceFromJson<T>(objType: { new(): T; }, json: any) {

            let newObj = new objType();

            let classStructure;
            if (newObj.hasOwnProperty("classStructure")) {
                let classStructureStr = "classStructure";
                classStructure = newObj[classStructureStr];
            } else {
                classStructure = {};
            }

            for (const prop in json) {

                if (json.hasOwnProperty(prop)) {

                    if (newObj[prop] == null) {
                        if (classStructure[prop] == null) {
                            newObj[prop] = json[prop];
                        } else if (typeof classStructure[prop] === "object" && classStructure[prop].hasOwnProperty("array") ) {
                            let arrayClass = classStructure[prop].array;
                            let arr: Array<any> = [];
                            if (json[prop] != null) {
                                for (let i = 0; i < json[prop].length; i++) {
                                    let object = json[prop][i];
                                    let newObjectInArr = this.createInstanceFromJson(arrayClass, object);
                                    arr.push(newObjectInArr);
                                }
                                newObj[prop] = arr;
                            }
                        } else {
                            newObj[prop] = this.createInstanceFromJson(classStructure[prop], json[prop]);
                        }
                    }
                } else {
                    console.warn(`Property ${prop} not set because it already existed on the object.`);
                }
            }
            return newObj;
        }

    }
}

angular.module("app").service("InstanceConverter",  app.functionality.common.converter.InstanceConverter);