/// <reference path="../../_app.ts" />

module app.functionality.liquidity {

    export class ReceivableAndDebtorService {
        private rest: restangular.IService;

        static $inject =  ["RestService", "RESTAPI"];
        constructor(restService: app.functionality.common.rest.RestService, private RESTAPI: app.config.constants.ConstantRestApi) {
            this.rest = restService.getRoot();
        }

        getReceivablesByCompanyKey = (companyKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.liquidity.base)
                .one("companyKey", companyKey)
                .one(this.RESTAPI.services.liquidity.receivables).get();
        }

        getDebtorsByCompanyKey = (companyKey: string): ng.IPromise<any> => {
            return this.rest.all(this.RESTAPI.services.liquidity.base)
                .one("companyKey", companyKey)
                .one(this.RESTAPI.services.liquidity.debtors).get();
        }
        
    }
}

angular.module("app").service("ReceivableAndDebtorService",  app.functionality.liquidity.ReceivableAndDebtorService);
