/// <reference path="../../_app.ts" />

namespace app.functionality.IPPForm {
  import IPPFormService = app.functionality.IPPForm.IPPFormService;
  import SessionService = app.functionality.common.session.SessionService;
  import Member = app.model.Member;

  export class IPPFormSubStepsController implements angular.IController {
    static $inject = [
      "$uibModalInstance",
      "$translate",
      "formData",
      "r_step",
      "r_fieldsManager",
      "IPPFormService",
      "$translatePartialLoader",
      "SessionService",
      "IPPFormFactory",
      "$uibModal",
    ];
    public step;
    public fieldsManager;
    public actualFieldsManager;
    public itemToEdit;
    public isNew: boolean;
    public data;
    public title;
    public trigger;
    public labelKeyArr;
    public ancestor;
    /**
     * Ensemble des champs (un tableau de champs pour chaque view)
     * @type {IFieldArray}
     */
    public fields: AngularFormly.IFieldArray = [];
    constructor(
      public $uibModalInstance: any,
      public $translate: ngt.ITranslateService,
      public formData: any,
      r_step: any,
      r_fieldsManager: any,
      public iPPService: IPPFormService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private sessionService: app.functionality.common.session.SessionService,
      public IPPFactory: IPPFormFactory,
      private $uibModal: any
    ) {
      $translatePartialLoader.addPart("ipp");
      this.step = r_step;
      this.data = formData.data;
      this.labelKeyArr = formData.labelKeyArr;
      this.itemToEdit = angular.copy(formData.itemToEdit);
      this.isNew = formData.isNew;
      //on attribue à activeStep le numéro du step en cours
      this.iPPService.activeStep = this.step.step;

      this.fieldsManager = r_fieldsManager;
      if (this.fieldsManager[0]) {
        this.actualFieldsManager = this.fieldsManager[0].steps.find(function (
          element
        ) {
          return element.step === this.step;
        },
        this.step);
      }

      this.fields = IPPFactory.factoryFields(
        this.step,
        this.actualFieldsManager,
        this.fields,
        this.itemToEdit
      );

      /**
       * lance un runxpression de tous les champs (nécessaire notamment pour refresh quand on traduit)
       * @param table
       */
      function runExpressionFields(table) {
        angular.forEach(table, function (x) {
          if (x.hasOwnProperty("fieldGroup")) {
            this.runExpressionFields(x.fieldGroup);
          } else {
            // x.runExpressions && x.runExpressions();
            x.runExpressions();
          }
        });
      }
    } //end constructor

    $onInit() {}

    public updateData(data, nextView) {
      if (
        angular.isDefined(data.paying_child_support_amount) &&
        typeof data.paying_child_support_amount === "string"
      ) {
        data.paying_child_support_amount = parseFloat(
          data.paying_child_support_amount.replace(/,/g, ".")
        ).toFixed(2);
      }
      this.iPPService.updateData(data, data.fiscalYear, nextView);
    }

    public cancel() {
      if (this.data[this.formData.variable.keyModel] == null) {
        //we check the value of variable trigger wich is the question triggering the modal
        this.data[this.formData.trigger] = 2;
      }
      this.$uibModalInstance.dismiss("cancel");
    }

    public add() {
      this.itemToEdit.isValid = true;
      let dataArr = this.data[this.step.fields[0].ancestors];
      let index = 0;
      if (dataArr) {
        index = this.data[this.step.fields[0].ancestors].indexOf(
          this.formData.itemToEdit
        );
      }
      if (index !== -1) {
        this.iPPService.updateMissingAttachementList(
          this.itemToEdit,
          this.isNew,
          this.step.fields,
          this.labelKeyArr,
          index
        );
      }
      // to validate the modal data in validation mode (forcing reentry in the modal)

      this.$uibModalInstance.close(this.itemToEdit);
    }
  }
}

angular
  .module("app")
  .controller(
    "IPPFormSubStepsController",
    app.functionality.IPPForm.IPPFormSubStepsController
  );
