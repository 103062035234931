/// <reference path="../_app.ts" />

module app.model {

    export class Third {

        //type
        public static get CUSTOMER(): number { return 1; }
        public static get SUPPLIER(): number { return 2; }

        //FROM
        public static get FROM_CUSTOMER(): number { return 1; }
        public static get FROM_ACCOUNTER(): number { return 2; }
        public static get FROM_BUDGET(): number { return 3; }

        public static get ASSUJETTI(): number { return 1; }
        public static get EXONERE(): number { return 2; }
        public static get NON_ASSUJETTI(): number { return 3; }

        public static get VAT_CONTROL_OK(): number { return 1; }
        public static get VAT_CONTROL_NOK(): number { return 2; }

        public key: string;
        public companyKey: string;

        public type: number;
        public from: number;

        public reference: string;
        
        public fullName: string;

        public adresse: string;
        public zip: string;
        public city: string;
        public isoCountry: string;

        public vatCategory: number;
        public vat: string;

        // Control
        public vatControl: number;

        constructor() {
        }

    }

}
