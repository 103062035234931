/// <reference path="../../_app.ts" />

module app.functionality.ticket {
  import Ticket = app.model.Ticket;
  import SessionService = app.functionality.common.session.SessionService;
  import AccountingEntryQuestion = app.model.AccountingEntryQuestion;
  import ModalUtil = app.functionality.common.modals.ModalUtil;

  export class TicketListController {
    public messages: any;
    public tickets: Array<Ticket>;
    public gridOptions: any;
    public contacts: any;
    public selectedContact = null;
    public selectedStatus: number = 0;

    // to change with data stored in session
    public userKey = "123";
    public companyKey = "123";
    public showStatusActions = false;
    public memberKey: String;
    public toTreat = false;
    public treated = false;
    public resolved = false;

    public fromStatus;
    public toStatus;
    public fromProcessStatus;
    public toProcessStatus;

    public totalTickets: number;
    public currentPage = 1;
    public ticketsByPage = 16;
    public r_suppliers;
    public r_clients;

    static $inject = [
      "r_tickets",
      "TicketService",
      "$scope",
      "r_suppliers",
      "r_clients",
      "$stateParams",
      "SessionService",
      "$uibModal",
      "$templateCache",
      "$translatePartialLoader",
      "$filter",
      "$state",
      "$translate",
      "ModalUtil",
    ];

    constructor(
      r_tickets: any,
      public ticketService: TicketService,
      public $scope: ng.IScope,
      r_suppliers: any,
      r_clients: any,
      public $stateParams: ng.ui.IStateParamsService,
      private sessionService: SessionService,
      private $uibModal: any,
      public $templateCache: ng.ITemplateCacheService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $filter: ng.IFilterService,
      public $state: ng.ui.IStateService,
      private $translate: ngt.ITranslateService,
      private modalUtil: ModalUtil
    ) {
      $translatePartialLoader.addPart("customer/ticket");
      $translatePartialLoader.addPart("customer/modals");
      $translatePartialLoader.addPart("customer/upload");
      let parameters = <any>$stateParams;
      this.toTreat = parameters.toTreat === "true";
      this.treated = parameters.treated === "true";
      this.resolved = parameters.resolved === "true";
      this.currentPage = parameters.page;
      this.memberKey = parameters.memberKey;

      this.r_suppliers = r_suppliers;
      this.r_clients = r_clients;
      this.tickets = r_tickets.data.tickets;
      this.translateContactNames();

      this.totalTickets = r_tickets.data.nbTotalTickets;
      this.contacts = this.sessionService.ticketSenders;
      if (this.memberKey != null) {
        for (let member of this.contacts) {
          if (member.key === this.memberKey) {
            this.selectedContact = member;
            break;
          }
        }
      }

      // initialise the gridOptions object, it will be used by ui-grid
      this.gridOptions = this.setGridData(this.tickets, $scope);
    }

    public translateContactNames() {
      for (let ticket of this.tickets) {
        if (this.isValid(ticket.senderKey)) {
          if (ticket.visibleSenderKey) {
            this.sessionService
              .getUserName(ticket.visibleSenderKey)
              .then(function (data) {
                // to get the real name instead of a key
                ticket.senderKey = data;
              });
          } else {
            this.sessionService
              .getUserName(ticket.senderKey)
              .then(function (data) {
                // to get the real name instead of a key
                ticket.senderKey = data;
              });
          }
        }
      }
    }

    public isValid(hexString: String) {
      if (hexString == null) {
        return false;
      } else {
        let len = hexString.length;
        if (len != 24) {
          return false;
        } else {
          for (let i = 0; i < len; ++i) {
            let c = hexString.charCodeAt(i);
            if (
              (c < 48 || c > 57) &&
              (c < 97 || c > 102) &&
              (c < 65 || c > 70)
            ) {
              return false;
            }
          }

          return true;
        }
      }
    }

    // method which check if the ticket is unread to the user
    public isTicketUnread(ticket) {
      let self = this;
      let unreadTicket = false;
      // let alreadyViewed = false;

      if (ticket.status === 1) {
        if (ticket.ticketRecordArr && ticket.ticketRecordArr.length > 0) {
          let alreadyViewed = false;
          ticket.ticketRecordArr.forEach(function (tr) {
            if (tr.userKey === self.sessionService.session.member.key) {
              alreadyViewed = true;
              unreadTicket = tr.date < ticket.lastModificationDate;
              return;
            }
          });
          if (!alreadyViewed) {
            unreadTicket = true;
          }
        } else {
          unreadTicket = true;
        }
      } else {
        unreadTicket = false;
      }
      return unreadTicket;
    }

    public filterTickets() {
      if (!this.resolved) {
        this.fromStatus = 1;
        this.toStatus = 1;
      } else if (this.resolved && !this.toTreat && !this.treated) {
        this.fromStatus = 999;
        this.toStatus = 999;
      } else {
        this.fromStatus = 1;
        this.toStatus = 999;
      }

      if (!this.toTreat) {
        this.fromProcessStatus = 100;
        this.toProcessStatus = 100;
      } else {
        this.fromProcessStatus = 0;
        this.toProcessStatus = 0;
      }

      if (this.toTreat && this.treated) {
        this.fromProcessStatus = 0;
        this.toProcessStatus = 100;
      }

      let self = this;
      if (this.selectedContact !== null && this.selectedContact !== undefined) {
        let pageNumber = 1;

        if (this.memberKey === self.selectedContact.key) {
          pageNumber = self.currentPage;
        }

        this.memberKey = self.selectedContact.key;

        this.$state.go(
          "websitelayout.headerandmenu.ticket",
          { page: pageNumber, memberKey: self.selectedContact.key },
          {
            location: true,
            inherit: true,
            relative: this.$state.$current,
            notify: true,
            reload: true,
          }
        );
      } else {
        this.memberKey = "";

        this.$state.go(
          "websitelayout.headerandmenu.ticket",
          { page: this.currentPage, memberKey: this.memberKey },
          {
            location: true,
            inherit: true,
            relative: this.$state.$current,
            notify: true,
            reload: true,
          }
        );
      }
    }

    public updateTicketList(data) {
      let tempData = data.data;
      this.tickets = tempData.tickets;
      this.translateContactNames();
      if (!this.toTreat && !this.treated && !this.resolved) {
        this.gridOptions.data = [];
        this.totalTickets = 0;
      } else {
        this.gridOptions.data = this.tickets;
        this.totalTickets = tempData.nbTotalTickets;
      }
    }

    public newTicket() {
      let self = this;

      let modalInstance = this.$uibModal.open({
        templateUrl: "tpl/website/modals/newTicketModal.html",
        controller: "NewTicketModalController",
        controllerAs: "ticket",
        size: "md",
        backdrop: "static",
        resolve: {
          r_data: function () {
            return {
              row: null,
            };
          },
        },
      });

      modalInstance.result.then(function () {
        self.filterTickets();
      });
    }

    public updateTicketStatus() {
      let temp = <any>this.$scope;
      let rows = temp.gridApi.selection.getSelectedRows();

      let rowsToChange = [];
      _.forEach(rows, function (row) {
        rowsToChange.push(row.key);
      });

      let self = this;

      self.ticketService
        .updateStatusTickets(
          self.sessionService.session.sessionID,
          self.sessionService.session.company.key,
          self.selectedStatus,
          rowsToChange
        )
        .then(function (data) {
          // on check si tout s'est bien passé
          // reload all messages with pre-encoded filters
          self.filterTickets();
          self.showStatusActions = false;
          self.selectedStatus = 0;
          // self.grid.selection.selectAll = false;
        })
        .catch(function (error) {
          console.error("error ocurred");
          console.error(error);
        });
    }

    // TO TRANSLATE
    public getShortDesc(questionCategory: number, row: any): string {
      let desc = "";
      if (questionCategory !== 999) {
        if (questionCategory === 2) {
          desc += this.$translate.instant("MESSAGE.missingBill") + " ";
        } else if (questionCategory === 1) {
          desc += this.$translate.instant("MESSAGE.missingPaiement") + " ";
        } else if (questionCategory === 3) {
          desc += this.$translate.instant("MESSAGE.badTVA") + " ";
        }
      }

      if (row.third && row.third.length > 0) {
        if (row.typeOfThird === 1) {
          desc +=
            this.$translate.instant("MESSAGE.fromClient") +
            " " +
            this.getThirdFullName(row);
        } else if (row.typeOfThird === 2) {
          desc +=
            this.$translate.instant("MESSAGE.fromSupplier") +
            " " +
            this.getThirdFullName(row);
        }
      }

      if (questionCategory !== 3) {
        desc +=
          " " +
          this.$translate.instant("MESSAGE.of") +
          " " +
          Math.abs(row.amount) +
          "€ " +
          this.$translate.instant("MESSAGE.the") +
          " " +
          this.$filter("date")(row.operationDate);
      }

      return desc;
    }

    public generateTicketObject(row): string {
      let desc = "";
      let acc: AccountingEntryQuestion;

      if (row.questionCategory !== 999 && row.target) {
        return this.getShortDesc(row.questionCategory, row.target);
      } else if (
        row.target != null &&
        row.target.third &&
        row.target.third.length > 0
      ) {
        acc = row.target;
        desc += "(";
        if (acc.typeOfThird === 2) {
          desc += this.$translate.instant("MESSAGE.supplier") + " ";
        } else if (acc.typeOfThird === 1) {
          desc += this.$translate.instant("MESSAGE.client") + " ";
        }
        desc += this.getThirdFullName(acc) + ")";
        desc += " " + row.freeQuestion.replace(/(<([^>]+)>)/gi, "");
        desc += " : " + acc.comment;
      } else if (row.freeQuestion) {
        desc += row.freeQuestion.replace(/(<([^>]+)>)/gi, "");
      } else {
        desc += "Pas d'objet";
      }
      return desc;
    }

    public getThirdFullName(target: any): string {
      let nameThird = "";
      switch (target.typeOfThird) {
        case 1:
          nameThird = this.findThird(this.r_clients, target.third);
          break;
        case 2:
          nameThird = this.findThird(this.r_suppliers, target.third);
          break;
        default:
          nameThird = target.third;
          break;
      }

      return nameThird;
    }

    public findThird(thirds: any[], thirdRef: string) {
      for (let i = 0; i < thirds.length - 1; i++) {
        if (thirds[i].reference === thirdRef) {
          return thirds[i].fullName;
        }
      }

      return "";
    }

    public setGridData(tickets, $scope) {
      return {
        enableRowHeaderSelection: true,
        selectionRowHeaderWidth: 35,
        enableSelectAll: true,

        data: tickets,
        enableFiltering: false,
        enableColumnMenus: false,
        enableSorting: false,
        rowHeight: 40,
        columnDefs: [
          {
            displayName: "Contact Name",
            headerCellFilter: "translate",
            width: "20%",
            field: "senderKey",
            headerCellClass: "header-title",
            headerCellTemplate:
              "<div class='ui-grid-cell-contents' style='vertical-align: middle !important;'>" +
              "<select ng-if='!grid.appScope.ticketList.showStatusActions' " +
              "ng-model='grid.appScope.ticketList.selectedContact'" +
              //<select    ng-options="item    as item.label       for item    in items                             track by item.id"
              "ng-options='contact.fullName for contact in grid.appScope.ticketList.contacts track by contact.key'" +
              "ng-change='grid.appScope.ticketList.filterTickets()' class='form-control'>" +
              "<option value=''>{{'MESSAGE.filterBy' | translate}}</option>" +
              "</select>" +
              "<select ng-if='grid.appScope.ticketList.showStatusActions' " +
              "ng-model='grid.appScope.ticketList.selectedStatus' " +
              "ng-init=''" +
              "ng-change='grid.appScope.ticketList.updateTicketStatus()' class='form-control'>" +
              "<option value=''>   {{'MESSAGE.markAs' | translate}}  </option>" +
              "<option value='0'>  {{'MESSAGE.toTreat' | translate}} </option>" +
              "<option value='100'>{{'MESSAGE.treated' | translate}} </option>" +
              "<option value='999'>{{'MESSAGE.resolved' | translate}}</option>" +
              "</select>" +
              "</div>",
            cellTemplate: "tpl/grid/message/contactNameCell.html",
          },
          {
            displayName: "Object",
            headerCellFilter: "translate",
            field: "freeQuestion",
            cellTemplate: "tpl/grid/message/messageObject.html",
            headerCellClass: "header-title",
            headerCellTemplate:
              "<div class='ui-grid-cell-contents' style='vertical-align: middle !important;'>" +
              "<span ng-if='!grid.appScope.ticketList.showStatusActions'>" +
              "<a href='' ui-sref='websitelayout.headerandmenu.ticket({toTreat: !grid.appScope.ticketList.toTreat})'>" +
              '<span ng-class=\'{"btn-message-to-process messageStatusButton": grid.appScope.ticketList.toTreat === true, "btn-message-unselect-to-process messageStatusButton": grid.appScope.ticketList.toTreat === false}\' ' +
              "style='display:inline-block;line-height: 2;'>{{'MESSAGE.toTreat' | translate}}" +
              "</span>" +
              "</a>" +
              "<a href='' ui-sref='websitelayout.headerandmenu.ticket({treated: !grid.appScope.ticketList.treated})'>" +
              '<span ng-class=\'{"btn-message-processed messageStatusButton": grid.appScope.ticketList.treated === true, "btn-message-unselect-processed messageStatusButton": grid.appScope.ticketList.treated === false}\' ' +
              "style='display:inline-block;line-height: 2'>{{'MESSAGE.treated' | translate}}" +
              "</span>" +
              "</a>" +
              "<a href='' ui-sref='websitelayout.headerandmenu.ticket({resolved: !grid.appScope.ticketList.resolved})'>" +
              '<span ng-class=\'{"btn-message-resolved messageStatusButton": grid.appScope.ticketList.resolved === true, "btn-message-unselect-resolved messageStatusButton": grid.appScope.ticketList.resolved === false}\' ' +
              "style='display:inline-block;line-height: 2'>{{'MESSAGE.resolved' | translate}}" +
              "</span>" +
              "</a>" +
              "</span>" +
              "</div>",
          },
          {
            displayName: "MESSAGE.lastModified",
            headerCellFilter: "translate",
            headerCellClass: "header-title",
            cellTemplate: "tpl/grid/message/lastModified.html",
            width: "20%",
            cellFilter: "dateFrom",
            field: "lastModificationDate",
            // headerCellTemplate: "tpl/grid/message/lastModifiedHeader.html",
            headerCellTemplate:
              "<div style='text-align: right; padding-top:18px;' class='ui-grid-cell-contents text-right'>" +
              "{{col.displayName | translate}}" +
              "</div>",
          },
        ],
      };
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "TicketListController",
    app.functionality.ticket.TicketListController
  );
