/// <reference path="../../_app.ts" />

module app.functionality.search {
  import AccountingEntry = app.model.AccountingEntry;
  import ModalUtil = app.functionality.common.modals.ModalUtil;
  import LoggerService = app.functionality.common.logger.LoggerService;
  export class SearchController {
    public entries: Array<AccountingEntry>;

    //temp variable to simulate the permissions
    public authorizedAdvancedSearch = true;

    public bookyearList: any;
    public bookyearSelected;

    public thirdSelected: app.model.Third;

    public gridOptions: any;
    public results = false;
    public grid = false;

    public selectedButton = true;
    public name: string;
    public vcs: string;
    public minVal;
    public maxVal: number;
    public fromDate: any;
    public toDate: any;
    public errorDisplayName: string;

    public smallDetailModal: any;

    public totalOpened: number = 0;
    public totalCharged: number = 0;

    public showMultiResults = false;

    private accFirmIsFrench: boolean;

    static $inject = [
      "$stateParams",
      "SearchService",
      "SessionService",
      "$window",
      "$uibModal",
      "ENV",
      "ModalUtil",
      "$translatePartialLoader",
      "AccountTools",
      "ModalService",
      "LoggerService",
    ];

    constructor(
      public $stateParams: ng.ui.IStateParamsService,
      public searchService: app.functionality.search.SearchService,
      public sessionService: app.functionality.common.session.SessionService,
      public $window: angular.IWindowService,
      public $uibModal: any,
      private ENV: app.config.constants.environment.ConstantEnv,
      private modalUtil: ModalUtil,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private accountTools: app.functionality.common.utils.AccountTools,
      private modalService: app.functionality.common.modals.ModalService,
      private logger: LoggerService
    ) {
      $translatePartialLoader.addPart("customer/search");

      this.initThird(this.$stateParams);
      this.entries = [];
      this.gridOptions = this.getGridData(this.entries);
      this.initExerciceFilter();

      // temporary force to true
      this.isAdvancedSearchActivated();
      this.getAccountingEntries();

      if (
        this.sessionService.session.company.country.toLowerCase() === "france"
      ) {
        this.accFirmIsFrench = true;
      } else {
        this.accFirmIsFrench = false;
      }
    }

    public isAdvancedSearchActivated() {
      let companyKey = this.sessionService.session.company.key;
      let permissions =
        this.sessionService.session.member.featuresByComp[companyKey];

      if (permissions.indexOf("OTHERRS") !== -1) {
        this.authorizedAdvancedSearch = true;
      } else {
        this.authorizedAdvancedSearch = false;
      }
    }

    // ******************** FORMS ********************

    public initExerciceFilter() {
      this.bookyearList = this.sessionService.session.company.exerciceArr;
      this.bookyearSelected = this.bookyearList[this.bookyearList.length - 1];
    }

    public updateExerciceFilter() {
      let currentIndex = this.bookyearList.indexOf(this.bookyearSelected);
      if (!currentIndex || currentIndex === -1) {
        return;
      }

      // select precedent bookyear & update grid
      if (currentIndex !== 0) {
        this.bookyearSelected = this.bookyearList[currentIndex - 1];
        this.getAccountingEntries();
      }
    }

    public goToExerciceFilter() {
      let currentIndex = this.bookyearList.indexOf(this.bookyearSelected);
      if (!currentIndex || currentIndex === -1) {
        return;
      }

      // select precedent bookyear & update grid
      if (currentIndex !== 0) {
        this.bookyearSelected = this.bookyearList[currentIndex - 1];
      }
    }

    public initThird(stateParams) {
      this.thirdSelected = stateParams.third;
    }

    public fromPopUp = {
      opened: false,
    };

    public toPopUp = {
      opened: false,
    };

    public openFrom() {
      this.fromPopUp.opened = true;
    }

    public openTo() {
      this.toPopUp.opened = true;
    }

    public validForm() {
      // method called when form is launched
      // go to advancedSearch right after
      this.advancedSearch();
    }

    // ******************* MODALS *******************

    public launchNewMessageModal(row) {
      row.entity.typeOfThird = this.thirdSelected.type;
      this.modalUtil.message(row);
    }

    public launchSmallDetailModal(row) {
      let sessionID = this.sessionService.session.sessionID;
      let companyKey = this.sessionService.session.company.key;

      this.smallDetailModal = this.$uibModal.open({
        templateUrl: "tpl/website/modals/smallDetailModal.html",
        controller: "SmallDetailModalController",
        controllerAs: "smallDetail",
        size: "sm",
        backdrop: true,
        keyboard: true,
        modalFade: true,
        resolve: {
          r_data: function () {
            return {
              row: row,
              sessionID: sessionID,
              companyKey: companyKey,
            };
          },
        },
      });
    }

    public launchInfoModal(row) {
      this.modalService.createInfoModal(
        row,
        "tpl/website/modals/infoModal.html",
        "InfoModalController",
        "info",
        "sm"
      );
    }

    public openPdf(row) {
      let sessionID = this.sessionService.session.sessionID;
      let companyKey = this.sessionService.session.company.key;

      this.$window.open(
        this.ENV.baseUrl +
          "/FileMgr?sessionID=" +
          sessionID +
          "&companyKey=" +
          companyKey +
          "&journal=" +
          row.journalType +
          "&period=" +
          row.period +
          "&doc=" +
          row.docNumber +
          "&bookyear=" +
          row.bookyear,
        "_blank"
      );
    }

    // ********************* GRID DATA // ACCOUNTING ENTRIES   *********************

    public getAccountingEntries() {
      let self = this;
      let startDate;
      let endDate;

      if (!angular.isDefined(this.minVal)) {
        this.minVal = 0;
      }

      if (!angular.isDefined(this.maxVal)) {
        this.maxVal = 0;
      }

      if (!angular.isDefined(this.fromDate)) {
        startDate = "";
      } else {
        startDate = this.fromDate;
      }

      if (!angular.isDefined(this.toDate)) {
        endDate = "";
      } else {
        endDate = this.toDate;
      }

      if (this.thirdSelected !== undefined && this.thirdSelected !== null) {
        self.searchService
          .getAccountingEntriesByThird(
            this.sessionService.session.sessionID,
            true,
            this.thirdSelected.type,
            this.thirdSelected.reference,
            null,
            this.minVal,
            this.maxVal,
            startDate,
            endDate,
            this.bookyearSelected.bookyear
          )
          .then(function (data) {
            let rawAccountingEntries = data.plain();
            // if (rawAccountingEntries.data[0].length !== 0) {
            //catch possible error linked with status here later
            self.entries = rawAccountingEntries.data[0];
            //generate the grid with entries from service
            function sortOn(property) {
              return function (a, b) {
                if (a[property] < b[property]) {
                  return -1;
                } else if (a[property] > b[property]) {
                  return 1;
                } else {
                  return 0;
                }
              };
            }
            (self.entries as Array<AccountingEntry>).sort(
              sortOn("operationDate")
            );
            self.updateTotal(self.entries);
            self.gridOptions = self.getGridData(self.entries);
            // }
          })
          .catch(function (error) {
            self.logger.error(
              "SearchController.ts",
              error,
              this.sessionService.session.member
            );
          });
      }
    }

    public advancedSearch() {
      let sessionID = this.sessionService.session.sessionID;

      // if we search for a customer, then type = 1, if supplier, it's 2
      let type = this.selectedButton === true ? 1 : 2;

      let startDate = "";
      let endDate = "";

      if (angular.isDefined(this.fromDate) && this.fromDate !== 0) {
        startDate = this.fromDate.toISOString();
      }

      if (angular.isDefined(this.toDate) && this.toDate !== 0) {
        endDate = this.fromDate.toISOString();
      }

      let self = this;

      self.searchService
        .getAccountingEntriesWithAdvancedSearch(
          sessionID,
          null,
          type,
          this.name,
          this.vcs,
          this.minVal,
          this.maxVal,
          startDate,
          endDate,
          this.bookyearSelected.bookyear
        )
        .then(function (data) {
          let response = data.plain();
          if (response.status === 2003) {
            self.multiResultSearch(response.data);
          } else {
            self.logger.customError(
              "SearchController.ts",
              response.status,
              this.sessionService.session.member
            );
            self.logger.customError(
              "SearchController.ts",
              response.data,
              this.sessionService.session.member
            );
          }
        })
        .catch(function (error) {
          self.logger.error(
            "SearchController.ts",
            error,
            this.sessionService.session.member
          );
        });

      // then, update grid entries
    }

    public multiResultSearch(results) {
      this.errorDisplayName = this.name;

      this.showMultiResults = true;
      this.results = results[0];

      if (results[0].length === 1) {
        this.selectThird(results[0][0]);
      }
    }

    public selectThird(third) {
      this.thirdSelected = third;
      this.getAccountingEntries();
      this.showMultiResults = false;
    }

    public invertAmount(row) {
      if (
        (row.dbtype === 1 ||
          row.dbtype === 2 ||
          row.dbtype === 6 ||
          row.dbtype === 7) &&
        this.thirdSelected.type === 1
      ) {
        return true;
      } else {
        return (
          row.dbtype !== 1 &&
          row.dbtype !== 2 &&
          row.dbtype !== 6 &&
          row.dbtype !== 7 &&
          this.thirdSelected.type === 2
        );
      }
    }

    public getOperationType(accountingEntry) {
      if (
        this.accountTools.isIncludeInRootArr(accountingEntry.account, [
          "600",
          "440",
          "401",
        ])
      ) {
        return 2;
      } else if (
        this.accountTools.isIncludeInRootArr(accountingEntry.account, [
          "700",
          "400",
          "411",
        ])
      ) {
        return 1;
      } else {
        return 0; // lostpaiement
      }
    }

    //1, vente, 2 achat, 3 mouvement financier, (else) 4 op diverse
    public getIconType(accountingEntry) {
      if (this.getOperationType(accountingEntry) === 0) {
        return 3; // double fleche
      } else if (
        accountingEntry.dbtype === 3 ||
        accountingEntry.dbtype === 4 ||
        accountingEntry.dbtype === 5
      ) {
        return 4; // double fleche
      } else {
        return this.getOperationType(accountingEntry);
      }
    }

    // ********************* TOTAL (UNDER GRID)   *********************

    public updateTotal(entries) {
      let totalEntries = entries.length;
      this.totalCharged = 0;
      this.totalOpened = 0;
      for (let i = 0; i < totalEntries; i++) {
        // if the third selected is a supplier, we invert the amount

        if (this.thirdSelected.type === 1) {
          this.totalCharged += entries[i].amount;
        } else if (this.thirdSelected.type === 2) {
          this.totalCharged += -entries[i].amount;
        }

        if (entries[i].isMatched === false) {
          if (this.thirdSelected.type === 1) {
            this.totalOpened += entries[i].amount;
          } else if (this.thirdSelected.type === 2) {
            this.totalOpened += -entries[i].amount;
          }
        }
      }
    }

    // ******************** GRID CONFIGURATION ********************

    public getGridData(entries) {
      return {
        data: entries,
        enableFiltering: false,
        enableColumnMenus: false,
        enableSorting: false,
        rowHeight: 40,
        columnFooterHeight: 40,
        columnDefs: [
          {
            displayName: "SEARCH.TABLE.description",
            headerCellFilter: "translate",
            headerCellClass: "header-title",
            field: "comment",
            cellFilter: "vcsStruct",
            cellTemplate: "tpl/grid/search/descriptionCell.html",
          },
          {
            displayName: "SEARCH.TABLE.date",
            headerCellFilter: "translate",
            headerCellClass: "header-title",
            field: "operationDate",
            width: "17%",
            cellFilter: "date:'dd/MM/yyyy'",
          },
          {
            displayName: "SEARCH.TABLE.paid",
            headerCellFilter: "translate",
            headerCellClass: "header-title",
            field: "isMatched",
            width: "20%",
            cellTemplate: "tpl/grid/search/paidCell.html",
          },
          {
            displayName: "SEARCH.TABLE.amount",
            headerCellFilter: "translate",
            headerCellClass: "header-title text-right",
            headerCellTemplate:
              '<div role="columnheader" ng-class="{ \'sortable\': sortable }" ' +
              "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + '-header-text ' + col.uid + '-sortdir-text'\" " +
              "aria-sort=\"{{col.sort.direction == asc ? 'ascending' : ( col.sort.direction == desc ? 'descending' : (!col.sort.direction ? 'none' : 'other'))}}\">" +
              '<div role="button" tabindex="0" class="ui-grid-cell-contents ui-grid-header-cell-primary-focus" col-index="renderIndex" title="TOOLTIP">' +
              '<span class="ui-grid-header-cell-label" ui-grid-one-bind-id-grid="col.uid + \'-header-text\'">{{ col.displayName CUSTOM_FILTERS }}</span>' +
              "</div><div ui-grid-filter></div></div>",
            width: "15%",
            cellFilter: "number:2",
            cellTemplate: "tpl/grid/search/amount.html",
            field: "amount",
          },
        ],
      };
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller("SearchController", app.functionality.search.SearchController);
