/// <reference path="../../_app.ts" />

module app.functionality.liquidity {

    export class LiquidityOverviewController {

        public debtSuppliers: any;
        public top5Clients: any;
        public top5Suppliers: any;
        public vatOverview: any;
        public debtTotal = 0;
        public suppliers: any;
        public supplierTotal = 0;

        static $inject = [
            "r_debtSuppliers",
            "r_supplierBalance",
            "r_top5Clients",
            "r_top5Suppliers",
            "r_vatOverview",
            "$translatePartialLoader"
        ];

        constructor(r_debtSuppliers: any,
                    r_supplierBalance: any,
                    r_top5Clients: any,
                    r_top5Suppliers: any,
                    r_vatOverview: any,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {

            $translatePartialLoader.addPart("customer/liquidity");

            let self = this;
            this.debtSuppliers = r_debtSuppliers;
            this.suppliers = r_supplierBalance;

            this.debtSuppliers.forEach(function(debtElement) {
                self.debtTotal += debtElement;
            });

            this.suppliers.forEach(function(supplierElement) {
                self.supplierTotal += supplierElement;
            });

            this.top5Clients = r_top5Clients;
            this.top5Suppliers = r_top5Suppliers;
            this.vatOverview = r_vatOverview;
        }

    }

}

angular.module("app").controller("LiquidityOverviewController", app.functionality.liquidity.LiquidityOverviewController);
