/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {
  import SessionService = app.functionality.common.session.SessionService;
  import TicketService = app.functionality.ticket.TicketService;
  import AccountingEntryQuestion = app.model.AccountingEntryQuestion;
  import LoggerService = app.functionality.common.logger.LoggerService;
  import Invoice = app.model.Invoice;
  import MimeTypes = app.functionality.common.utils.MimeTypes;
  import LiquidityConverter = app.functionality.liquidity.LiquidityConverter;

  export class NewTicketModalController {
    public freeQuestion: any;
    public attachements: any;
    private sessionID: string;
    private companyKey: string;
    public notifyUser = false;

    public valueNow = 0;
    public valueMax = 0;
    public percent = 0;

    public totalFiles: number;
    public countFile: number = 0;

    // public mapFiles = new Map();

    public model;
    public key;
    // public map;

    public attachementList = [];
    public errorAttachementList = [];
    public showAttachements = false;

    public accQuest: AccountingEntryQuestion;
    public oppQuest: any;

    public ticketType: string;
    public nameUploader: string;
    public nameUploadFolder: string;
    public invoice: Invoice;

    private allowedExtraExtension: Array<string> = [];
    private fileAcceptedString: string =
      "application/pdf,image/png,image/jpeg,image/pjpeg,application/xml,text/xml";

    static $inject = [
      "$scope",
      "$uibModalInstance",
      "$translate",
      "SessionService",
      "TicketService",
      "UploadService",
      "Notification",
      "ENV",
      "$rootScope",
      "$http",
      "$window",
      "r_data",
      "$translatePartialLoader",
      "$filter",
      "$sanitize",
      "LoggerService",
      "MimeTypes",
      "LiquidityConverter",
      "ModalService",
    ];

    constructor(
      public $scope: ng.IScope,
      public $uibModalInstance: any,
      private $translate: ngt.ITranslateService,
      private sessionService: SessionService,
      private ticketService: TicketService,
      private uploadService: app.functionality.upload.UploadService,
      private notification: any,
      private ENV: app.config.constants.environment.ConstantEnv,
      private $rootScope: ng.IRootScopeService,
      private $http: ng.IHttpService,
      public $window: angular.IWindowService,
      public r_data: any,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      public $filter: ng.IFilterService,
      private $sanitize: ng.sanitize.ISanitizeService,
      private logger: LoggerService,
      private mime: MimeTypes,
      private liquidityConverter: LiquidityConverter,
      private modalService: ModalService
    ) {
      $translatePartialLoader.addPart("customer/modals");
      $translatePartialLoader.addPart("customer/upload");

      this.sessionID = this.sessionService.session.sessionID;
      this.companyKey = this.sessionService.session.company.key;

      if (this.sessionService.session.company.allowedUploadExtension) {
        this.allowedExtraExtension =
          this.sessionService.session.company.allowedUploadExtension;
        for (let i = 0; i < this.allowedExtraExtension.length; i++) {
          const extraExtension = this.allowedExtraExtension[i];
          this.fileAcceptedString +=
            "," + this.mime.getMimeType(extraExtension);
        }
      }

      this.countFile = this.uploadService.countFile;
      $scope.$on("displayResultUpload", (event, mapUploadError: any) => {
        this.launchResultUploadModal(mapUploadError);
      });

      $scope.$on("sendAnyway", (event, payload: any) => {
        this.totalFiles = payload.files.length;
        this.uploadService.totalFiles = payload.files.length;

        for (let file of payload.files) {
          this.valueMax = this.valueMax + file.size;
          this.sendFile(file, payload.forceUpload);
        }
      });

      if (r_data.invoice != null) {
        // ticket from archive
        this.invoice = r_data.invoice;
        this.ticketType = "archive";
        if (
          r_data.name !== undefined &&
          r_data.name !== null &&
          r_data !== ""
        ) {
          this.nameUploader = r_data.name;
        }
        if (r_data.uploadFolderName !== undefined) {
          this.nameUploadFolder = r_data.uploadFolderName;
        }
      } else if (r_data.row !== null) {
        if (angular.isDefined(r_data.row.entity.accNum)) {
          this.ticketType = "balanceOrCpt";
          // we're in balance & cpt case
          this.oppQuest = r_data.row.entity;
        } else if (angular.isDefined(r_data.row.entity.third)) {
          this.ticketType = "accEntry";
          // we clicked on an accounting entry question
          this.newAccountingEntryQuestion(r_data.row.entity);
        }
      }
    }

    public newAccountingEntryQuestion(operation) {
      let acc = operation;
      if (operation.cashEntry) {
        acc = operation.cashEntry;
      }
      this.accQuest = new app.model.AccountingEntryQuestion();

      // Description*
      this.accQuest.journalType = acc.journalType;
      this.accQuest.docNumber = acc.docNumber;
      this.accQuest.dbtype = acc.dbtype;

      this.accQuest.period = acc.period;
      this.accQuest.bookyear = acc.bookyear;
      this.accQuest.account = acc.account;

      // Dates
      this.accQuest.operationDate = acc.operationDate;
      this.accQuest.encodingDate = acc.encodingDate;
      this.accQuest.dueDate = acc.dueDate;

      // third
      this.accQuest.third = acc.third;
      this.accQuest.comment = acc.comment;
      this.accQuest.vcs = acc.vcs;

      // amount
      this.accQuest.amount = acc.amount;
      this.accQuest.vatAmount = acc.vatAmount;
      this.accQuest.vat_name = acc.vat_name;

      this.accQuest.isMatched = acc.isMatched;
      this.accQuest.isDoc = acc.isDoc;
      this.accQuest.matchID = acc.matchID;
      this.accQuest.docOrder = acc.docOrder;

      this.accQuest.typeOfThird = operation.typeOfThird;
      this.accQuest.companyKey = operation.companyKey;

      if (angular.isDefined(acc.thirdFullName)) {
        this.accQuest.thirdFullName = acc.thirdFullName;
      }
    }

    public newTicket() {
      let newTicket = new app.model.Ticket();
      newTicket.status = 1; // OPEN Status
      newTicket.type = 1; // FROM CUSTOMER
      newTicket.senderKey = this.sessionService.session.member.key;

      // generate the description and append it to the freeQuestion
      if (angular.isDefined(this.oppQuest)) {
        let desc =
          this.$translate.instant("MODAL.MESSAGE.ticketConcernsAccount") +
          " " +
          this.oppQuest.label +
          " " +
          this.$translate.instant("MODAL.MESSAGE.accountWithNumber") +
          " " +
          this.oppQuest.accNum +
          " " +
          this.$translate.instant("MODAL.MESSAGE.withTheAmountOf") +
          " " +
          this.$filter("number")(this.oppQuest.solde, 2) +
          " €.";

        this.freeQuestion = this.freeQuestion + " - " + desc;
      }

      //newTicket.freeQuestion = this.$sanitize(this.freeQuestion);
      newTicket.freeQuestion = this.freeQuestion;
      newTicket.sendDate = new Date();
      newTicket.creationDate = new Date();
      newTicket.questionCategory = 999;
      newTicket.tags = [];
      newTicket.tags.push("category_Free");

      if (angular.isDefined(this.invoice)) {
        newTicket.archive_invoice = this.invoice;
        newTicket.questionCategory = 800;
      }

      if (this.sessionService.isCustomer()) {
        newTicket.userFlag = 100;
        newTicket.accFlag = 0;
      } else {
        newTicket.userFlag = 0;
        newTicket.accFlag = 100;
      }

      newTicket.attachmentArr = this.attachementList;
      newTicket.companyKey = this.companyKey;
      newTicket.answered = false;
      newTicket.notifyUserArr = [];
      newTicket.authorKey = this.sessionService.session.member.key;

      if (angular.isDefined(this.accQuest)) {
        newTicket.target = this.accQuest;
      }

      if (this.notifyUser === true) {
        newTicket.notifyUserArr.push(this.sessionService.session.member.key);
      }

      this.attachementList = [];
      this.showAttachements = false;

      let self = this;
      self.ticketService
        .addTicket(self.sessionID, newTicket)
        .then(function (data) {
          self.$uibModalInstance.close();
          self.notification(
            self.$translate.instant("NOTIFICATIONS.TICKET.sendTicketSuccess")
          );
          //closing the modal will got us to update the ticket list and we'll see the new one on the list
          self.freeQuestion = "";
        })
        .catch(function (error) {
          self.notification(
            self.$translate.instant("NOTIFICATIONS.TICKET.sendTicketError")
          );
          self.logger.error(
            "NewTicketModalController.ts",
            error,
            self.sessionService.session.member
          );
        });
    }

    public uploadFiles($event, files) {
      this.showAttachements = true;

      this.uploadService.totalFiles = files.length;
      this.countFile = files.length;

      let self = this;
      _.forEach(files, function (file) {
        self.sendFile(file, true);
      });
      $event.target.value = "";
    }

    /**
     * Create the form for the server and send to the server
     * Each events are handle in this method
     * @param file
     */

    private sendFile(file: File, forceUpload?: boolean) {
      // let sessionID = this.sessionService.session.sessionID;
      let self = this;
      let form: any = new FormData();
      // form.append("filenameURI", this.$sanitize(file.name));
      form.append(
        "filenameURI",
        this.$sanitize(
          encodeURI(this.ENV.removeSpecialCharFromFilename(file.name))
        )
      );
      form.append("convertToSWF", "false");
      form.append("sessionID", self.sessionID);
      form.append("companyKey", self.companyKey);
      form.append("typeUpload", "1");
      form.append("file", file);

      if (forceUpload) {
        form.append("forceUpload", true); //if we want to send a duplicate file again -> true
      } else {
        form.append("forceUpload", false);
      }

      this.uploadService
        .uploadFile(
          form,
          file,
          this.sessionService.session.company.allowedUploadExtension
        )
        .then(function (data) {
          self.completeHandler(data.data);
          self.countFile--;
        })
        .catch(function (error) {
          //let errorCode = self.uploadService.printError(file);

          self.countFile--;

          self.uploadService.errorHandler(file, error);

          self.logger.error(
            "NewTicketModalController.ts",
            error,
            self.sessionService.session.member
          );
        });
    }

    /**
     * When an invoice is correctly sent to the server,
     * the server send back an XML file with the invoice data.
     * The new invoice is created but a confirmation has to be received by the sever.
     * The confirmation contains the key of the new invoice.
     * The new invoice is sent to ToSendController to update the ui-grid data via $rootscope
     * @returns {(response:any)=>undefined}
     */
    public completeHandler(response) {
      let self = this;

      let oParser = new DOMParser();
      let xmlDocument: Document = oParser.parseFromString(response, "text/xml");
      let mongoFileKey =
        xmlDocument.getElementsByTagName("FileKey")[0].childNodes[0].nodeValue;
      let fileName =
        xmlDocument.getElementsByTagName("FileName")[0].childNodes[0].nodeValue;

      self.attachementList.push({
        name: fileName,
        fileKey: mongoFileKey,
        senderKey: self.sessionService.session.member.key,
      });
      self.totalFiles = self.attachementList.length;

      //self.uploadService.displayResultUpload();
    }

    public removeAttachement(attachement) {
      let self = this;
      this.attachementList = _.reject(this.attachementList, function (el) {
        return el.fileKey === attachement.fileKey;
      });
      this.uploadService
        .deleteFileKey(attachement.fileKey)
        .then(function (data) {
          for (let promise of data) {
            let tmp = promise.plain();

            if (tmp.status !== 0) {
              self.logger.error(
                "NewTicketModalController.ts",
                tmp,
                self.sessionService.session.member
              );
            }
          }
        })
        .catch(function (error) {
          self.logger.error(
            "NewTicketModalController.ts",
            error,
            self.sessionService.session.member
          );
        });
      this.totalFiles = this.attachementList.length;
      if (this.attachementList.length === 0) {
        this.showAttachements = false;
      }
    }

    public openAttachement(attachement) {
      this.$window.open(
        this.ENV.baseUrl +
          "/DBFileMgr?sessionID=" +
          this.sessionID +
          "&companyKey=" +
          this.companyKey +
          "&fileName=" +
          attachement.name +
          "&fileKey=" +
          attachement.fileKey,
        "_blank"
      );
    }

    public close() {
      let self = this;
      this.$uibModalInstance.close();
      if (this.attachementList.length !== 0) {
        for (let attachement of this.attachementList) {
          this.uploadService
            .deleteFileKey(attachement.fileKey)
            .then(function (data) {
              for (let promise of data) {
                let tmp = promise.plain();

                if (tmp.status !== 0) {
                  self.logger.error(
                    "NewTicketModalController.ts",
                    tmp,
                    self.sessionService.session.member
                  );
                }
              }
            })
            .catch(function (error) {
              self.logger.error(
                "NewTicketModalController.ts",
                error,
                self.sessionService.session.member
              );
            });
        }
      }
    }

    public launchResultUploadModal(mapUploadError: any) {
      this.modalService.createResultUploadModal(
        "tpl/website/modals/resultUploadModal.html",
        "ResultUploadModalController",
        "resultUploadModalController",
        mapUploadError,
        true
      );
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "NewTicketModalController",
    app.functionality.common.modals.NewTicketModalController
  );
