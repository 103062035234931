/// <reference path="../../../_app.ts" />

module app.functionality.common.filters {

    import BookHistoricConst = app.model.constants.BookHistoricConst;
    export class DocumentFilter {

        static detailDocumentFilter(): Function {
            return (input, row) => {
                return row.entity.journalType + " N° " + row.entity.docNumber;
            };
        }

        static detailDescriptionFilter(bookConst: BookHistoricConst): Function {
            return (input, row) => {

                    let str = "";
                    let tempA = "";
                    let tempB = "";

                    if (row.entity.third && row.entity.third.length > 0) {
                        str = row.entity.third;
                    }

                    if (row.entity.comment && row.entity.comment.length > 0 && ((row.entity.third && row.entity.third !== row.entity.comment) || !row.entity.third)) {
                        tempA = row.entity.comment;
                    }

                    if (row.entity.vcs && row.entity.vcs.length > 0 && row.entity.dbtype === bookConst.OPD) {
                        tempB = row.entity.vcs;
                    }

                    if (tempA.length > 0) {
                        str += " | " + tempA;
                    }

                    if (tempA.length > 0 && tempB.length > 0) {
                        str += " , " + tempB;
                    } else if (tempB.length > 0) {
                        str += " | " + tempB;
                    }

                    if (tempA.length > 0 || tempB.length > 0) {
                        str += "";
                    }

                return str;
            };
        }

    }
        DocumentFilter.detailDescriptionFilter.$inject =  ["BookHistoricConst"];
}
angular.module("app").filter("detailDocumentFilter", app.functionality.common.filters.DocumentFilter.detailDocumentFilter);
angular.module("app").filter("detailDescriptionFilter", app.functionality.common.filters.DocumentFilter.detailDescriptionFilter);