/// <reference path="../../_app.ts" />

module app.model.budgetInsight {

    export class BITransaction {

        public key: string;
        public accountingFirmKey: string;
        public companyKey: string;
        public creationDate : Date;
        public idAccount: string;
        public idMember: string;
        public description: string;
        public amount: number;
        public transactionTime: Date;
        public json: string;

        constructor() {
        }

    }

}