/// <reference path="../../../_app.ts" />

module app.functionality.treasury.wcr {

    export class WcrController {

        protected data: any;
        public total = 0;

        static $inject = [
            "r_wcr",
            "$translatePartialLoader"
        ];

        constructor(r_wcr: any,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {
            $translatePartialLoader.addPart("customer/treasury");
            this.initTotal(r_wcr.data);
            this.data = r_wcr.data;
        }

        public initTotal(data) {
            for (let i = 0; i < data.length; i++) {
                if (!isNaN(data[i].solde)) {
                    this.total = this.total + data[i].solde;
                }
            }
        }
    }
}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("WcrController", app.functionality.treasury.wcr.WcrController);