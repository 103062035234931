/// <reference path="../../_app.ts" />

module app.functionality.profit {

    import SessionService = app.functionality.common.session.SessionService;
    import ENV = app.config.constants.environment.ConstantEnv;

    export class DetailModalController {

        public gridData: any;
        public row: any;
        public col: any;
        public gridOptions: any;
        public catName: string;
        public catNumber: number;

        static $inject = [
            "r_data",
            "$uibModalInstance",
            "$translate",
            "ModalUtil",
            "$uibModal",
            "SessionService",
            "$window",
            "ENV",
            "$translatePartialLoader"
        ];

        constructor(r_data,
                    public $uibModalInstance: any,
                    private $translate: ngt.ITranslateService,
                    private modalUtil: app.functionality.common.modals.ModalUtil,
                    private $uibModal: any,
                    private sessionService: SessionService,
                    private $window: ng.IWindowService,
                    private env: ENV,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {
            $translatePartialLoader.addPart("customer/modals");
            $translatePartialLoader.addPart("customer/profit");
            this.gridData = r_data.gridData;
            this.row = r_data.row;
            this.col = r_data.col;

            // set the category name and number for title display
            this.catNumber = this.row.entity.accNum;
            this.catName = this.gridData[1][this.catNumber];

            if (this.catNumber == null && this.catName == null) {
                if(this.row.entity.keyLangage !== null && this.row.entity.keyLangage !== undefined) {
                    // Spécial case where the user clic for the detail of a category, not just an account
                    if (r_data.state === 0) {
                        this.catName = this.$translate.instant("PROFIT.BALANCE.ACTIVE"+"."+this.row.entity.keyLangage);
                    } else if (r_data.state === 1) {
                        this.catName = this.$translate.instant("PROFIT.BALANCE.PASSIVE."+this.row.entity.keyLangage);
                    } else if (r_data.state === 2) { // Comparison
                        this.catName = this.$translate.instant("PROFIT.INCOME.GRID."+this.row.entity.keyLangage);
                    } else if (r_data.state === 3) { // incomeController
                        this.catName = this.$translate.instant("PROFIT.INCOME.GRID."+this.row.entity.keyLangage);
                    }
                } else {
                    this.catName = this.row.entity.label;
                }
            }

            // put the name of the account inside the object
            let self = this;
            angular.forEach(this.gridData[0], function(value, key) {
                value.nameAccount = self.gridData[1][value.account];
                if(value.comment != null && value.comment !== "") {
                    value.description = value.comment;
                } else if((value.comment == null || value.comment === "") && value.third != null) {
                    value.description = value.third;
                }

                // invert amount
                if (r_data.state === 3) {
                    value.amount = -value.amount;
                }

            });

            this.gridOptions = this.setGridOptions(this.gridData[0]);
        }

        public setGridOptions(data) {
            return {
                columnDefs: [
                    {
                        displayName: "MODAL.DETAILS.document",
                        headerCellFilter: "translate",
                        headerCellClass: "header-title text-left",
                        headerCellTemplate: "<div role=\"columnheader\" " +
                        "ng-class=\"{ \'sortable\': sortable }\" " +
                        "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + \'-header-text \' + col.uid + \'-sortdir-text\'\"" +
                        " aria-sort=\"{{col.sort.direction == asc ? \'ascending\' : ( col.sort.direction == desc ? \'descending\' : (!col.sort.direction ? \'none\' : \'other\'))}}\">" +
                        "<div role=\"button\" tabindex=\"0\" class=\"ui-grid-cell-contents ui-grid-header-cell-primary-focus\" col-index=\"renderIndex\" title=\"TOOLTIP\">" +
                        "<span class=\"ui-grid-header-cell-label\" ui-grid-one-bind-id-grid=\"col.uid + \'-header-text\'\">{{ col.displayName CUSTOM_FILTERS }}</span>" +
                        "</div><div ui-grid-filter></div></div>",
                        field: "key",
                        // width: "25%",
                        cellTemplate: "tpl/grid/detailModal/documentTemplate.html",
                        cellFilter : "detailDocumentFilter"
                    },
                    {
                        displayName: "MODAL.DETAILS.description",
                        headerCellFilter: "translate",
                        headerCellClass: "header-title text-left",
                        headerCellTemplate: "<div role=\"columnheader\" ng-class=\"{ \'sortable\': sortable }\" " +
                        "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + \'-header-text \' + col.uid + \'-sortdir-text\'\" " +
                        "aria-sort=\"{{col.sort.direction == asc ? \'ascending\' : ( col.sort.direction == desc ? \'descending\' : (!col.sort.direction ? \'none\' : \'other\'))}}\">" +
                        "<div role=\"button\" tabindex=\"0\" class=\"ui-grid-cell-contents ui-grid-header-cell-primary-focus\" " +
                        "col-index=\"renderIndex\" title=\"TOOLTIP\"><span class=\"ui-grid-header-cell-label\" " +
                        "ui-grid-one-bind-id-grid=\"col.uid + \'-header-text\'\">{{ col.displayName CUSTOM_FILTERS }}</span></div><div ui-grid-filter></div></div>",
                        width: "45%",
                        cellTemplate: "tpl/grid/detailModal/descriptionTemplate.html",
                        // cellFilter: "detailDescriptionFilter:row",
                        cellFilter : "detailDescriptionFilter",
                        field: "description"
                    },
                    {
                        displayName: "MODAL.DETAILS.date",
                        headerCellFilter: "translate",
                        headerCellClass: "header-title text-center",
                        headerCellTemplate: "<div role=\"columnheader\" ng-class=\"{ \'sortable\': sortable }\" " +
                        "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + \'-header-text \' + col.uid + \'-sortdir-text\'\" " +
                        "aria-sort=\"{{col.sort.direction == asc ? \'ascending\' : ( col.sort.direction == desc ? \'descending\' : (!col.sort.direction ? \'none\' : \'other\'))}}\">" +
                        "<div role=\"button\" tabindex=\"0\" class=\"ui-grid-cell-contents ui-grid-header-cell-primary-focus\" col-index=\"renderIndex\" " +
                        "title=\"TOOLTIP\"><span class=\"ui-grid-header-cell-label\" ui-grid-one-bind-id-grid=\"col.uid + \'-header-text\'\">{{ col.displayName CUSTOM_FILTERS }}</span>" +
                        "</div><div ui-grid-filter></div></div>",
                        field: "operationDate",
                        width: "15%",
                        cellFilter: "date:'dd/MM/yyyy'",
                        cellClass: "text-center"
                    },
                    {
                        displayName: "MODAL.DETAILS.amount",
                        headerCellFilter: "translate",
                        headerCellClass: "header-title text-right",
                        headerCellTemplate: "<div role=\"columnheader\" ng-class=\"{ \'sortable\': sortable }\" " +
                        "ui-grid-one-bind-aria-labelledby-grid=\"col.uid + \'-header-text \' + col.uid + \'-sortdir-text\'\"" +
                        " aria-sort=\"{{col.sort.direction == asc ? \'ascending\' : ( col.sort.direction == desc ? \'descending\' : (!col.sort.direction ? \'none\' : \'other\'))}}\">" +
                        "<div role=\"button\" tabindex=\"0\" class=\"ui-grid-cell-contents ui-grid-header-cell-primary-focus\" col-index=\"renderIndex\" " +
                        "title=\"TOOLTIP\"><span class=\"ui-grid-header-cell-label\" ui-grid-one-bind-id-grid=\"col.uid + \'-header-text\'\">{{ col.displayName CUSTOM_FILTERS }}</span>" +
                        "</div><div ui-grid-filter></div></div>",
                        width: "15%",
                        field: "amount",
                        cellFilter: "number:2",
                        cellClass: "text-right"
                    }
                ],
                data: data,
                enableFiltering: false,
                enableColumnMenus: false,
                enableSorting: true,
                rowHeight: 40,
                virtualizationThreshold: data.length,
            };
        }

        public message(row) {
            this.modalUtil.message(row);
        }

        public openPdf(row) {
            let sessionID = this.sessionService.session.sessionID;
            let companyKey = this.sessionService.session.company.key;

            this.$window.open(this.env.baseUrl + "/FileMgr?sessionID=" + sessionID +
                "&companyKey=" + companyKey +
                "&journal=" + row.journalType +
                "&period=" + row.period +
                "&doc=" + row.docNumber +
                "&bookyear=" + row.bookyear, "_blank"
            );
        }

        public close() {
            this.$uibModalInstance.close();
        }

    }

}

angular
    .module("app")
    .controller("DetailModalController", app.functionality.profit.DetailModalController);
