/// <reference path="../../../../_app.ts" />

module app.functionality.invoiceReminder.controllers.modal {

    /**
     * This modal is used to display a message to the user until he updated the additional information of its company
     * It should be displayed when the user use the functionnality for the first time in order to guide him.
     */

    export class ModalQuickstartController  implements angular.IController {

        $onInit() {
        }

        static $inject = [
            "$uibModalInstance",
            "$translatePartialLoader"
        ];

        constructor(public $uibModalInstance,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {

            $translatePartialLoader.addPart("customer/modals");
        }

        public cancel() {
            this.$uibModalInstance.dismiss();
        }

    }

    //noinspection TypeScriptValidateTypes
    angular.module("app").controller("ModalQuickstartController", app.functionality.invoiceReminder.controllers.modal.ModalQuickstartController);
}