/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {

    import SessionService = app.functionality.common.session.SessionService;
    import SearchService = app.functionality.search.SearchService;

    export class MultiCompanyModalController {

        public companyList = [];
        public arrayAccountingFirm = [];
        public member: any;
        public companyObject: any;
        public switchCompany: boolean;

        public companySelectedFlag: boolean;

        static $inject = ["r_data",
                          "switchCompany",
                          "$uibModalInstance",
                          "SessionService",
                          "SearchService","$translate", "$state","tmhDynamicLocale"];
        constructor(r_data: any,
                    switchCompany: boolean,
                    public $uibModalInstance: any,
                    private sessionService: SessionService,
                    private searchService: SearchService,
                    private $translate: ngt.ITranslateService,
                    private state: ng.ui.IStateService,
                    private tmhDynamicLocale) {

            let self = this;
            this.companySelectedFlag = false;
            this.companyObject = r_data.data[1];
            this.member = r_data.data[0];
            this.$translate.use(this.member.language.toLowerCase());
            this.tmhDynamicLocale.set(this.member.language.toLowerCase());
            // we get an object, which every attribute is the key of the company, and the value of this attribute is the name of the company
            // basically we convert the array into an object with two attributes for easier iteration on the modal view
            this.switchCompany = switchCompany;
            // If we switch Company, we need to reload the page (but not if it is the first connexion)

            /*Object.keys(this.companyObject).forEach(function(key) {
                let company = {
                    key: key,
                    name: self.companyObject[key]
                };
                self.companyList.push(company);
            });*/
            function sortOn(property) {
                return function(a, b) {
                    if(a[property] < b[property]) {
                        return -1;
                    } else if(a[property] > b[property]) {
                        return 1;
                    } else {
                        return 0;
                    }
                };
            }

            Object.keys(this.companyObject).forEach(function(key) {
                let firm = {
                    firmName: key,
                    data: []
                };

                Object.keys(self.companyObject[key]).forEach(function(keyCompany) {
                    let company = {
                        key: keyCompany,
                        name: (self.companyObject[key])[keyCompany]
                    };
                    firm.data.push(company);
                });
                firm.data.sort(sortOn("name"));

                self.arrayAccountingFirm.push(firm);
            });

            self.arrayAccountingFirm.sort(sortOn("name"));
        }

        public chooseCompany(companyKey) {
            let self = this;
            self.companySelectedFlag = true;
            this.sessionService.doLogin(self.member, companyKey, "BE", false)
                .then(function(response) {
                    // authentification successful, redirect to dashboard
                    // self.state.go("websitelayout.headerandmenu");
                    //self.state.go("websitelayout.headerandmenu.dashboard");
                    self.sessionService.manageBetaTester(response);
                    if (self.switchCompany) {
                        self.sessionService.mapUploadFolder = undefined;
                        self.searchService.clientsThird = undefined;
                        self.searchService.suppliersThird = undefined;
                        self.state.reload();

                    }
                    self.sessionService.initUploadFolderName();
                    self.$uibModalInstance.close();
                }).catch(function(error) {
                    // error, status modal error?
                    console.error("error on chooseCompany call");
                    console.error(error);
                });
        }
    }

}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("MultiCompanyModalController", app.functionality.common.modals.MultiCompanyModalController);
