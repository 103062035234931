/// <reference path="../../../../_app.ts" />

module app.functionality.invoiceReminder.controllers.modal {

    import ReminderWithErrorCode = app.functionality.invoiceReminder.services.ReminderWithErrorCode;
    import InvoiceReminderService = app.functionality.invoiceReminder.services.InvoiceReminderService;
    import SessionService = app.functionality.common.session.SessionService;
    import SendReminderService = app.functionality.invoiceReminder.services.SendReminderService;
    import IReminder = app.model.interface.IReminder;

    /**
     * This modal is used to warn the user of the reminders sending that have failed
     * If the error is due to an unknown email, the user can type it in the modal and update to related contact
     * This modal allows the user to try again to send the reminders
     */

    export class InvoiceReminderErrorSendingReminderModalController  implements angular.IController {

        public errorsToDisplay: ReminderWithErrorCode[];
        public isLoading: boolean;

        private sessionID;

        $onInit() {
        }

        static $inject = [
            "$uibModalInstance",
            "$translatePartialLoader",
            "errorsToDisplay",
            "InvoiceReminderService",
            "SessionService",
            "SendReminderService",
            "$q"
        ];

        constructor(private $uibModalInstance,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService,
                    errorsToDisplay: ReminderWithErrorCode[],
                    private invoiceReminderService: InvoiceReminderService,
                    private sessionService: SessionService,
                    private sendReminderService: SendReminderService,
                    private $q) {

            let self = this;
            $translatePartialLoader.addPart("customer/modals");
            this.errorsToDisplay = errorsToDisplay;

            this.sessionID = sessionService.session.sessionID;
            this.isLoading = false;

            this.errorsToDisplay.forEach(function (value) {
                self.invoiceReminderService.getContactInfo(self.sessionService.session.sessionID, value.reminder.reference)
                    .then(function (data) {
                        value.reminder.contact = data.plain().data;
                    });
            });
        }

        /**
         * This function is used to make the modal disappeared
         */
        public cancel() {
            this.$uibModalInstance.dismiss();
        }

        /**
         * This function is called when the user wants to try again to send the reminder
         * It updates the contact data if the user has completed the email and call the service SendReminder
         */
        public sendAgain() {
            this.isLoading = true;
            let self = this;
            let promises = [];
            let allReminders: IReminder[] = [];

            self.errorsToDisplay.forEach(function(value) {
               if (value.reminder.contact.contactEmail != null && value.reminder.contact.contactEmail != "") {
                   promises.push(self.invoiceReminderService.updateContactInfo(self.sessionService.session.sessionID, value.reminder.contact));
               }
               allReminders.push(value.reminder);
            });

            self.$q.all(promises).then( function() {
                self.sendReminderService.sendReminders(allReminders, allReminders[0].template);
                self.$q.all(promises).then(function () {
                    self.isLoading = false;
                    self.$uibModalInstance.close("ok");
                });
            });
        }

    }

    //noinspection TypeScriptValidateTypes
    angular.module("app").controller("InvoiceReminderErrorSendingReminderModalController", app.functionality.invoiceReminder.controllers.modal.InvoiceReminderErrorSendingReminderModalController);
}