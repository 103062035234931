/// <reference path="../../_app.ts" />

module app.functionality.profit {

    export class TurnoverController {
        public profit: any;
        public dataForLineChart: any;

        public pnl: any;
        public top5S: any;
        public top5C: any;
        public turnover: any;

        public bookyearSelected;
        public accountSelected;
        public thirdSelected;

        //to populate the select options
        public bookyearList: any;
        public accountList: any; // ALL Account for all year
        public currentAccountList: any; // account for param selected
        public thirdList: any; // ALL third for all year
        public currentThirdList: any; // third for param selected

        static $inject = [
            "ProfitService",
            "LineChartConverterForDashboard",
            "SessionService",
            "r_turnover",
            "r_third",
            "r_account",
            "$translatePartialLoader"
        ];

        constructor(public profitService: ProfitService,
                    public lineChartConverterForDashboard: app.modules.lineargraphs.converter.LineChartConverterForDashboard,
                    private sessionService: app.functionality.common.session.SessionService,
                    r_turnover: any,
                    r_third: any,
                    r_account: any,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {
            $translatePartialLoader.addPart("customer/profit");

            if(this.sessionService.session.company.importInfo != null && this.sessionService.session.company.importInfo.importDate != null) {
                this.turnover = r_turnover;
                this.bookyearList = this.sessionService.session.company.exerciceArr;
                this.bookyearSelected = this.bookyearList[this.bookyearList.length - 1];
                this.accountList = r_account;
                this.currentAccountList = r_account;
                this.thirdList = r_third;
                this.currentThirdList = r_third;
                this.dataForLineChart = this.lineChartConverterForDashboard.convert(this.turnover.periodArr,new Date(this.sessionService.session.company.importInfo.importDate));
            } else if (this.sessionService.session.company.importInfo.state !== 0) {
                this.turnover = r_turnover;
                this.bookyearList = this.sessionService.session.company.exerciceArr;
                this.bookyearSelected = this.bookyearList[this.bookyearList.length - 1];
                this.accountList = r_account;
                this.currentAccountList = r_account;
                this.thirdList = r_third;
                this.currentThirdList = r_third;

                this.dataForLineChart = this.lineChartConverterForDashboard.convert(this.turnover.periodArr);
                this.bookyearSelected = this.sessionService.session.company.currBookyear; //or the last one ine the bookyear section
                this.updateChartData();
            }
            if(this.bookyearList) {
                let tempArr = [...this.bookyearList];
                tempArr.sort((a, b) => {
                    if (a.bookyear < b.bookyear) {
                      return 1;
                    } else if (b.bookyear < a.bookyear) {
                      return -1;
                    } else {
                      return 0;
                    }
                  });
                this.bookyearSelected = tempArr[0].bookyear;
                this.changeBookyear();
            }
        }

        public changeBookyear() {
            this.accountSelected = null;
            this.thirdSelected = null;
            this.updateChartData();
        }

        public changeAccount() {
            this.thirdSelected = null;
            this.updateChartData();
        }

        public updateChartData() {
            let self = this;

            self.profitService.getTurnoverByBookyear(self.sessionService.session.sessionID, self.bookyearSelected, self.accountSelected, self.thirdSelected)
                .then(function(data) {
                    let returnData = data.plain();
                    self.turnover = returnData.data;
                    if(self.sessionService.session.company.importInfo != null && self.sessionService.session.company.importInfo.importDate != null) {
                        self.dataForLineChart = self.lineChartConverterForDashboard.convert(self.turnover.periodArr,new Date(self.sessionService.session.company.importInfo.importDate));
                    } else {
                        self.dataForLineChart = self.lineChartConverterForDashboard.convert(self.turnover.periodArr);
                    }
                    if(self.accountSelected === null || self.accountSelected === undefined || self.accountSelected === 0) {
                        // If we have not select an account, we update the list in the dropdown
                        self.currentAccountList = self.filterAccount(self.turnover.accountArr);
                    }
                    if(self.thirdSelected === null || self.thirdSelected === undefined || self.thirdSelected === "") {
                        // If we have not select a third, we update the list in the dropdown
                        self.currentThirdList = self.filterThird(self.turnover.thirdArr);
                    }
                    return self.dataForLineChart;
                })
                .catch(function(error) {
                    console.error("error ocurred");
                });
        }

        /*
         Filter the third to put in the dropdown according to the filter
         The server return only the reference of the third to show, so we filter with the
         array of ALL third we receive in the constructor
         */
        public filterThird(thirds: any) {
            let newListThird = [];
            for (let i = 0, len = this.thirdList.length; i < len; i++) {
                let thirdOrig = this.thirdList[i];
                for (let j = 0, lenJ = thirds.length; j < lenJ; j++) {
                    let third = thirds[j];
                    if(third !== null && thirdOrig !== null && third === thirdOrig.reference) {
                        newListThird.push(thirdOrig);
                        break;
                    }
                }
            }
            return newListThird;
        }

        /*
         Filter the account to put in the dropdown according to the filter
         The server return only the numero of the account to show, so we filter with the
         array of ALL account we receive in the constructor
         */
        public filterAccount(accounts: any) {
            accounts  = this.removeDuplicate(accounts);
            let newListAccount = [];
            for (let i = 0, len = this.accountList.length; i < len; i++) {
                let accountOrig = this.accountList[i];
                for (let j = 0, lenJ = accounts.length; j < lenJ; j++) {
                    let account = accounts[j];
                    if(account !== null && accountOrig !== null && account === accountOrig.numero && newListAccount.indexOf(accountOrig) < 0) {
                        newListAccount.push(accountOrig);
                        break;
                    }
                }
            }
            return newListAccount;
        }

        public removeDuplicate(accounts: any) {
            let uniqueListAccount = [];
            for (let j = 0, lenJ = accounts.length; j < lenJ; j++) {
                let inside = false;
                for (let i = 0, len = uniqueListAccount.length; i < len; i++) {
                    if(uniqueListAccount[i] === accounts[j]) {
                        inside = true;
                        break;
                    }
                }
                if(inside === false) {
                    uniqueListAccount.push(accounts[j]);
                }
            }
            return uniqueListAccount;
        }

    }

}

angular.module("app").controller("TurnoverController", app.functionality.profit.TurnoverController);
