/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {

    // import IWindowService = angular.IWindowService

    export class CsvComparisonModalController {

        public bookyear: number;
        public toPeriod: number;
        public actif = false;
        public passive = false;
        public income = false;
        public detail = true;
        private sessionID: any;
        public language: string;
        public excelFormat = false;
        public comparison = true;

        static $inject = [
            "r_fromTo",
            "$uibModalInstance",
            "$window",
            "$translate",
            "ENV",
            "$translatePartialLoader"
        ];

        public helperText = "MODAL.CSV_EXPORT.detailHelperText";
        public excelFormatHelperText = "MODAL.CSV_EXPORT.excelFormatHelperText";

        constructor(r_fromTo: any,
                    public $uibModalInstance: any,
                    public $window: angular.IWindowService,
                    private $translate: ngt.ITranslateService,
                    private ENV: app.config.constants.environment.ConstantEnv,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {
            $translatePartialLoader.addPart("customer/modals");
            this.sessionID = r_fromTo.sessionID;
            this.bookyear = r_fromTo.bookyear;
            this.toPeriod = r_fromTo.toPeriod;
            this.language = r_fromTo.language;
        }

        /**
         * Requête avec les bonnes données pour obtenir le fichier CSV contenant le tableau des comptes et des montants selon les périodes sélectionnées dans la vue.
         */
        public export() {

            // ADD ENV VARIABLES
            this.$window.open(this.ENV.baseUrl+"/CSVGenerator?sessionID=" + this.sessionID +
                "&actif=" + false +
                "&passive=" + false +
                "&income=" + false +
                "&detail=" + this.detail +
                "&bookyear=" + this.bookyear +
                "&toPeriod=" + this.toPeriod +
                "&language=" + this.language +
                "&excelFormat=" + this.excelFormat +
                "&comparison=" + true, "_blank");
            this.$uibModalInstance.close();
        }

        public cancel() {
            this.$uibModalInstance.close();
        }
    }
}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("CsvComparisonModalController", app.functionality.common.modals.CsvComparisonModalController);
