/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.controllers {

    import ITemplateReminder = app.model.interface.ITemplateReminder;
    import ThirdWithOperations = app.model.invoiceReminder.ThirdWithOperations;
    import FilterService = app.functionality.invoiceReminder.services.FilterService;
    
    export class TemplateParametersController implements angular.IController {
        
        public thirdsWithOperations: ThirdWithOperations[];
        public pageTitle: string;
        public sessionID: string;

        /**
         * Contains all the data of the user's templates
         */
        public templatesData: ITemplateReminder[];

        public isLoading: boolean;

        $onInit() {
        }

        static $inject = [
            "r_dataThirdsWithOperations",
            "r_userTemplates",
            "r_title",
            "SessionService",
            "$translatePartialLoader",
            "InvoiceReminderService",
            "Notification",
            "$translate",
            "ModalQuickstartService",
            "SendReminderService",
            "FilterService",
            "$scope"
        ];

        constructor(r_dataThirdsWithOperations: ThirdWithOperations[],
                    r_userTemplates: ITemplateReminder[],
                    r_title: string,
                    private sessionService: app.functionality.common.session.SessionService,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService,
                    private invoiceReminderService: app.functionality.invoiceReminder.services.InvoiceReminderService,
                    private notification: any,
                    private $translate,
                    modalQuickstartService: app.functionality.invoiceReminder.services.ModalQuickstartService,
                    private sendReminderService: app.functionality.invoiceReminder.services.SendReminderService,
                    private filterService: FilterService,
                    $scope) {
            
            let self = this;
            $translatePartialLoader.addPart("customer/invoiceReminder");
            
            self.thirdsWithOperations = [];
            self.pageTitle = r_title;
            self.sessionID = self.sessionService.session.sessionID;
            self.templatesData = r_userTemplates.sort((a, b) => {
                if (a.templateNumber > b.templateNumber) {
                  return 1;
                } else if (a.templateNumber < b.templateNumber) {
                  return -1;
                } else if (a.templateNumber == b.templateNumber) {
                  return 0;
                }
              });

            $scope.$watch(() => this.filterService.thirdsWithOperations,
            (newValue: ThirdWithOperations[]) => {
                self.thirdsWithOperations = newValue;
            });
            this.filterService.initService(r_dataThirdsWithOperations, true);

            modalQuickstartService.showDialogIfQuickstart();
        }

        /**
         * This function is used to test if the form is incomplete
         * @param {app.model.interface.ITemplateReminder} template the template which is currently edited
         * @returns {boolean} true if the some fields of the form are incomplete
         */
        public incompleteTemplate(template: ITemplateReminder): boolean {
            return template.name == undefined || template.name == "" ||
                   template.subject == undefined || template.subject == "";
        }

        /**
         * This function is used to update the given template
         * @param {app.model.interface.ITemplateReminder} template the template that should be updated
         */
        public updateTemplate(template: ITemplateReminder) {
            let self = this;

            if (self.incompleteTemplate(template)) {
                return;
            }

            self.isLoading = true;
            this.invoiceReminderService.updateTemplate(self.sessionService.session.sessionID, template)
                .then(function (response) {
                    self.isLoading = false;
                    if (response.status == 0) {
                        self.notification.primary({
                            title: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.updateDone"),
                            message: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATETEMPLATEFORM.templateUpdated")
                            // templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html"
                        });
                        // allows the service to get the updated templates
                        self.sendReminderService.getTemplates();

                    }
                    else {
                        if (response.status == 408) {
                            self.notification.primary({
                                title: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATETEMPLATEFORM.errorUpdateTemplate"),
                                message: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATETEMPLATEFORM.missingFieldsTemplate"),
                                // templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
                                delay: null
                            });
                        }
                        else {
                            self.notification.primary({
                                title: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATETEMPLATEFORM.errorUpdateTemplate"),
                                message: self.$translate.instant("NOTIFICATIONS.INVOICEREMINDER.UPDATETEMPLATEFORM.updateTemplateFailed"),
                                // templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
                                delay: null
                            });
                        }
                    }
                });

        }

        public sendTestEmail(template: ITemplateReminder) {
            this.sendReminderService.sendTestEmail(this.thirdsWithOperations[0], template);
        }
    }
}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("TemplateParametersController", app.functionality.invoiceReminder.controllers.TemplateParametersController);