/// <reference path="../../../_app.ts" />

module app.modules.selectbox.directive.selectbox {

    export class SelectBoxDirective implements ng.IDirective {

        restrict = "E";
        replace = true;
        scope = false;

        template = function (element, attrs) {

            if (!angular.isDefined(attrs.defaultLabel)) {
                attrs.defaultLabel = "";
            }

            return "<div>" +
                "<select class='form-control selectBox' name='" + attrs.name + "' " +
                "ng-model='" + attrs.ngModel + "' ng-init='" + attrs.ngInit + "' " +
                "ng-change='" + attrs.ngChange + "'  ng-options='" + attrs.optexp
                + "'" + ((attrs.required) ? " required" : "") + "></select>" +
                "</div>";

        };

        link = (scope: ng.IScope, elem: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) => {
            let myModel = "ngModel", defaultLabel = "defaultLabel";
            scope.$watch(attrs[myModel], function () {
                let documentResult = elem[0];
                let wrappedDocumentResult = angular.element(documentResult);
                let options = wrappedDocumentResult.find("option");
                _(options).forEach(function(value) {
                    let selector = angular.element(value);
                    if (value.selected === true) {
                        selector.html(attrs[defaultLabel] + " selected : "  + value.text);
                    } else {
                        selector.text(value.label);
                    }
                });

            });
        }

        static factory(): ng.IDirectiveFactory {
            const directive = () => new SelectBoxDirective();

            return directive;
        }
    }
}

angular.module("selectBoxModule").directive("selectBox", app.modules.selectbox.directive.selectbox.SelectBoxDirective.factory());
