/// <reference path="../../_app.ts" />

module app.model.invoicing {
  export class InvoicingInformation {
    key: string;
    companyKey: string;

    companyName: string;
    address: string;
    zip: string;
    city: string;
    country: string;
    phone: string;

    iban: string;
    bic: string;
    vatNumber: string;

    siret?: string;
    legalForm?: string;
    capital?: number = 0;
    rcs?: string;

    base64Logo: string;

    defaultDaysToDueDate: number;
    defaultMonthsToDueDate: number;
    increments: InvoicingInformationIncrement[];
    vatRates: InvoicingInformationVatRate[];
    localizedValues: Map<string, InvoicingInformationLocalizedValues>;

    sendingEmail: string;
    sendingName: string;

    constructor() {}

    isValidForInvoicing(): boolean {
      if (
        this.companyName &&
        this.address &&
        this.city &&
        this.zip &&
        this.vatNumber
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  export class InvoicingInformationIncrement {
    lastUsedValue: number;
    name: string;
    type: string;
    lastUseDate: Date | string;

    constructor() {}

    useNextValue(): number {
      this.lastUsedValue = this.lastUsedValue + 1;
      this.lastUseDate = new Date();
      return this.lastUsedValue;
    }
  }

  export class InvoicingInformationVatRate {
    name: string;
    rate: number;
    description: string;

    constructor() {}
  }

  export class InvoicingInformationLocalizedValues {
    headingText: string;
    footerText: string;
    filename: string;
    mailSubject: string;
    mailBody: string;

    constructor() {}
  }
}
