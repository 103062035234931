/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {

    import IWindowService = angular.IWindowService;

    export class InfoModalController {

        public row: any;
        public third: string;
        public amount: number;
        public vatAmount: number;
        public comment: string;
        public operationDate: string;
        public dueDate: string;
        public journal: string;
        public docNumber: number;
        public bookyear: number;
        public period: number;

        static $inject = [
            "r_data",
            "$uibModalInstance",
            "$translate",
            "$window",
            "SessionService",
            "ENV"
        ];

        constructor(r_data: any,
                    public $uibModalInstance: any,
                    private $translate: ngt.ITranslateService,
                    public $window: IWindowService,
                    private sessionService : app.functionality.common.session.SessionService,
                    private ENV: app.config.constants.environment.ConstantEnv) {

            this.row = r_data.row;

            this.third = this.row.third;
            this.amount = this.row.amount;
            this.vatAmount = this.row.vatAmount;
            this.comment = this.row.comment;
            this.operationDate = this.row.operationDate;
            this.dueDate = this.row.dueDate;
            this.journal = this.row.journal;
            this.docNumber = this.row.docNumber;
            this.bookyear = this.row.bookyear;
            this.period = this.row.period;

        }

        public openPDF() {
            let sessionID = this.sessionService.session.sessionID;
            let companyKey = this.sessionService.session.company.key;
            let accConcerned = this.row.accountingEntryArr[this.row.idAcc];

            this.$window.open(this.ENV.baseUrl + "/FileMgr?sessionID=" + sessionID +
                "&companyKey=" + companyKey +
                "&journal=" + accConcerned.journalType +
                "&period=" + accConcerned.period +
                "&doc=" + accConcerned.docNumber +
                "&bookyear=" + accConcerned.bookyear, "_blank"
            );
        }

        public close() {
            this.$uibModalInstance.close();
        }

    }

}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("InfoModalController", app.functionality.common.modals.InfoModalController);
