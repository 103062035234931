/// <reference path="../_app.ts" />

// import controller.AccesMgr;
// import controller.MyDateFormatter;
// import controller.community.TicketLabelMgr;
// import controller.session.SessionMgr;

// import model.AccountingEntry;
// import model.BookHistoric;

module app.model {

// [RemoteClass(alias="model.share.AccountingEntryQuestion")]
export class AccountingEntryQuestion {

    // Description
    public journalType : string;
    public docNumber : number;
    public dbtype : number;

    public period : number;
    public bookyear : number;
    public account : number;

    //Dates
    public operationDate : Date;
    public encodingDate : Date;
    public dueDate : Date;

    // third
    public third : string;
    public comment : string;
    public vcs : string;

    // amount
    public amount : number;
    public vatAmount : number;
    public vat_name : string;

    public isMatched : boolean;
    public isDoc : boolean;
    public matchID : string;
    public docOrder : string;
    public typeOfThird : number;
    public companyKey : string;

    constructor () {
    }

    public toString() : string {
        return this.amount + "";
    }

    // public copy(acc : AccountingEntry) : void {
    //     this.journalType = acc.journalType;
    //     this.docNumber = acc.docNumber;
    //     this.dbtype = acc.dbtype;
    //     this.period = acc.period;
    //     this.bookyear = acc.bookyear;
    //     this.account = acc.account;
    //     this.operationDate = acc.operationDate;
    //     this.encodingDate = acc.encodingDate;
    //     this.dueDate = acc.dueDate;

    //     this.third = acc.third;
    //     this.comment = acc.comment;

    //     this.amount = acc.amount;
    //     this.vatAmount = acc.vatAmount;
    //     this.vat_name = acc.vat_name;
    //     this.isMatched = acc.isMatched;
    //     this.matchID = acc.matchID;
    //     this.docOrder = acc.docOrder;
    // }

    // public clone() : AccountingEntryQuestion {
    //     let e : AccountingEntryQuestion;

    //     e.journalType = this.journalType;
    //     e.docNumber = this.docNumber;
    //     e.dbtype = this.dbtype;
    //     e.period = this.period;
    //     e.bookyear = this.bookyear;
    //     e.account = this.account;
    //     e.operationDate = this.operationDate;
    //     e.encodingDate = this.encodingDate;
    //     e.dueDate = this.dueDate;

    //     e.third = this.third;
    //     e.comment = this.comment;
    //     e.isDoc = this.isDoc;
    //     e.amount = this.amount;
    //     e.vatAmount = this.vatAmount;
    //     e.vat_name = this.vat_name;
    //     e.isMatched = this.isMatched;
    //     e.matchID = this.matchID;
    //     e.docOrder = this.docOrder;
    //     e.typeOfThird = this.typeOfThird;
    //     e.companyKey = this.companyKey;
    //     return e;
    // }

    // public getShortDesc(questionCategory : number) : string {
    //     let desc = "";
    //     if (questionCategory !== TicketLabelMgr.OTHER) {
    //         if (questionCategory === TicketLabelMgr.LOST_INVOICE) {
    //             desc += "Facture manquante ";
    //         } else if (questionCategory === TicketLabelMgr.LOST_PAIEMENT) {
    //             desc += "Paiement manquant ";
    //         } else if (questionCategory === TicketLabelMgr.WRONG_TVA) {
    //             desc += "Numéro de TVA incorrect ";
    //         }
    //     }
    //
    //     if (this.third && this.third.length > 0) {
    //         if (this.typeOfThird === 1) {
    //             desc += " du client " + this.third;
    //         } else if (this.typeOfThird === 2) {
    //             desc += " du fournisseur " + this.third;
    //         }
    //     }
    //
    //     if (questionCategory !== TicketLabelMgr.WRONG_TVA) {
    //         desc += " de " + Math.abs(this.amount) + "€ le " + MyDateFormatter.FullFormat(this.operationDate);
    //     }
    //
    //     return desc;
    // }
    //
    // public getLongDesc(questionCategory : number) : string {
    //     if (AccesMgr.isAccounter(SessionMgr.getCurrentSession().accesLevel)) {
    //         return this.getLongDescAccounter(questionCategory);
    //     } else {
    //         return this.getLongDescCustomer(questionCategory);
    //     }
    // }
    //
    // public getLongDescAccounter(questionCategory : number) : string {
    //     let desc = "";
    //     if (questionCategory !== TicketLabelMgr.OTHER) {
    //         if (questionCategory === TicketLabelMgr.LOST_INVOICE) {
    //             desc += "Facture manquante : ";
    //         } else if (questionCategory === TicketLabelMgr.LOST_PAIEMENT) {
    //             desc += "Paiement manquant : ";
    //         } else if (questionCategory === TicketLabelMgr.WRONG_TVA) {
    //             desc += "Numéro de TVA incorrect : ";
    //         }
    //     } else {
    //         desc += "Ce message";
    //     }
    //
    //     desc += " concerne l'écriture sur le journal " + this.journalType +
    //     " n° " + this.docNumber +
    //     " de " + this.amount + "€";
    //
    //     if (this.third && this.third.length > 0) {
    //         desc += " du " + this.getThirdLabel() + " " + this.third;
    //     }
    //
    //     if (this.dbtype === BookHistoric.BANK) {
    //         desc += " sur le compte " + this.journalType;
    //     }
    //
    //     desc += " à la date du " + MyDateFormatter.FullFormat(this.operationDate);
    //
    //     if (this.comment && this.comment.length > 0) {
    //         desc += " avec la description : " + this.comment;
    //     }
    //
    //     desc += ".";
    //     return desc;
    // }
    //
    // public getThirdLabel() : string {
    //     if (this.third && this.third.length > 0) {
    //         if (this.typeOfThird === 1) {
    //             return "client";
    //         } else if (this.typeOfThird === 2) {
    //             return "fournisseur";
    //         } else {
    //             return "";
    //         }
    //     } else {
    //         return "";
    //     }
    // }
    //
    // public getLongDescCustomer(questionCategory : number) : string {
    //     let desc = "";
    //
    //     if (questionCategory === TicketLabelMgr.LOST_PAIEMENT) {
    //         desc += "Pourriez-vous nous envoyer la preuve de paiement de " + Math.abs(this.amount) + "€";
    //         if (this.third && this.third.length > 0) {
    //             desc += " du " + this.getThirdLabel() + " " + this.third;
    //         }
    //
    //         desc += " effectué le " + MyDateFormatter.FullFormat(this.operationDate);
    //
    //         if (this.comment && this.comment.length > 0) {
    //             desc += " avec la description : " + this.comment;
    //         }
    //
    //         desc += " ?";
    //     } else if (questionCategory === TicketLabelMgr.LOST_INVOICE) {
    //         desc += "Pourriez-vous nous envoyer la facture correspondant au paiement de " + Math.abs(this.amount) + "€";
    //
    //         if (this.third && this.third.length > 0) {
    //             desc += " du " + this.getThirdLabel() + " " + this.third;
    //         }
    //
    //         desc += " effectué le " + MyDateFormatter.FullFormat(this.operationDate);
    //
    //         if (this.comment && this.comment.length > 0) {
    //             desc += " avec la communication : " + this.comment;
    //         }
    //
    //         desc += " ?";
    //     } else if (questionCategory === TicketLabelMgr.WRONG_TVA) {
    //
    //         desc += "Nous avons constaté un mauvais numéro de TVA pour la facture du " + this.getThirdLabel() + " " + this.third +
    //         " effectuée le " + MyDateFormatter.FullFormat(this.operationDate);
    //
    //         if (this.comment && this.comment.length > 0) {
    //             desc += " avec la communication : " + this.comment;
    //         }
    //
    //         desc += ". Pouvez-vous nous fournir son bon numéro de TVA ?";
    //     } else {
    //         desc += "Ce message concerne une opération de " + Math.abs(this.amount) + "€";
    //
    //         if (this.third && this.third.length > 0) {
    //             desc += " du " + this.getThirdLabel() + " " + this.third;
    //         }
    //
    //         desc += " effectuée le " + MyDateFormatter.FullFormat(this.operationDate);
    //
    //         if (this.comment && this.comment.length > 0) {
    //             desc += " avec comme description : " + this.comment;
    //         }
    //
    //         desc += ".";
    //     }
    //     return desc;
    // }
}

}