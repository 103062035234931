/// <reference path="../../../_app.ts" />

module app.functionality.upload {
  export class DragDropDirective implements ng.IDirective {
    //restrict: "A";

    public scope: any = {
      filesToUpload: "=",
      dropFile: "&",
      noInvoices: "=",
    };

    droppingzone = document.getElementById("droppingZone");
    invoiceTable = document.getElementById("myTable");

    controller = ($scope) => {
      $scope.files = [];
    };

    link = (scope, element, attributes, controller, transclude) => {
      element.bind("dragover", (e) => {
        if (e != null) {
          e.preventDefault();
        }
        e.dataTransfer.effectAllowed = "copy";
        e.dataTransfer.dropEffect = "copy";
        element.attr("class", "dropzone-over");
      });
      element.bind("dragenter", (e) => {
        if (e != null) {
          e.preventDefault();
        }
        e.dataTransfer.effectAllowed = "copy";
        e.dataTransfer.dropEffect = "copy";
        element.attr("class", "dropzone-over");
      });
      element.bind("dragleave", (e) => {
        if (e != null) {
          e.preventDefault();
        }
        e.dataTransfer.effectAllowed = "copy";
        e.dataTransfer.dropEffect = "copy";
        element.attr("class", "dropzone");
      });
      element.bind("drop", (e) => {
        element.attr("class", "dropzone");
        if (this.droppingzone) {
          if (!scope.noInvoices) {
            this.droppingzone.style.display = "none";
          } else {
            this.droppingzone.style.display = "flex";
          }
        }
        if (e != null) {
          e.preventDefault();
          e.stopPropagation();
        }
        scope.filesToUpload = e.dataTransfer.files;
        scope.dropFile({ files: scope.filesToUpload });
      });
    };

    static factory(): ng.IDirectiveFactory {
      const directive = () => new DragDropDirective();

      return directive;
    }
  }
}

angular
  .module("app")
  .directive("dragDrop", app.functionality.upload.DragDropDirective.factory());
