/// <reference path="../../../_app.ts" />

module app.modules.lineargraphs.chart {
  export class LineChartArea extends LineChartBase {
    defineChartGraph() {
      return this.lineChartServiceUtil.defineArea(
        this.xScale,
        this.yScale,
        this.propertyX,
        this.propertyY,
        this.heightInner
      );
    }

    drawLineChart() {
      this.lineChartServiceUtil.drawLineAreaChart(
        this.canvas,
        this.geometryDef,
        this.data
      );
    }
  }
}
