/// <reference path="../../../_app.ts" />

namespace app.functionality.common.session {
  import RestService = app.functionality.common.rest.RestService;
  import ConstantRestApi = app.config.constants.ConstantRestApi;
  import InstanceConverter = app.functionality.common.converter.InstanceConverter;
  import TicketService = app.functionality.ticket.TicketService;
  import ENV = app.config.constants.environment.ConstantEnv;
  import TicketNotification = app.config.values.TicketNotification;
  import LoggerService = app.functionality.common.logger.LoggerService;
  import Member = app.model.Member;
  import ModalUtil = app.functionality.common.modals.ModalUtil;

  export class SessionService {
    private rest: restangular.IService;

    // *** SERVER VERSION *** change if needed
    private serverVersion: string;
    // *** USED IN LOGIN
    private username: string;
    private password: string;

    // *** USED IN TICKETS
    public accountantList: any;
    public clientList: any;
    public ticketSenders: any;
    public invoiceReminders: any[];

    public session: any;
    public authenticated = false;
    public roles: Array<string> = [];

    // USED FOR AUTHORIZATION CHECK
    public toState: any;
    public toStateParams: any;

    public returnToState: any;
    public returnToStateParams: any;

    public CSRFSetted: boolean;
    public fromDirectUrl: boolean;
    public mapUploadFolder: any;

    static $inject = [
      "RestService",
      "RESTAPI",
      "$q",
      "$http",
      "$state",
      "InstanceConverter",
      "TicketService",
      "$uibModal",
      "$window",
      "ENV",
      "TicketNotification",
      "LoggerService",
      "ModalUtil",
      "$translate",
      "tmhDynamicLocale",
      "FEATURES",
    ];

    constructor(
      restService: RestService,
      private RESTAPI: ConstantRestApi,
      private $q: ng.IQService,
      public $http: ng.IHttpService,
      public state: ng.ui.IStateService,
      private instanceConverter: InstanceConverter,
      private ticketService: TicketService,
      public $uibModal: any,
      public $window: ng.IWindowService,
      private env: ENV,
      private ticketNotification: TicketNotification,
      private loggerService: LoggerService,
      private modalUtil: ModalUtil,
      private $translate: ngt.ITranslateService,
      private tmhDynamicLocale,
      private FEATURES: app.config.constants.roles.ConstantFeatures
    ) {
      this.CSRFSetted = false;
      this.fromDirectUrl = false;
      this.session = undefined;
      this.rest = restService.getRoot();
      this.serverVersion = this.env.getVersion();
      this.initRolesTab();
    }

    // those lists are used in the ticket view
    private initAccountersAndClientsLists() {
      let self = this;
      this.getAllAccounterFromCompany(
        this.session.sessionID,
        this.session.accountingFirmKey
      )
        .then(function (data) {
          self.accountantList = data.data;
        })
        .catch(function (error) {
          console.error(
            "error ocurred for getAllAccounterFromCompany in sessionService"
          );
          console.error(error);
        });
      this.getAllClientsFromCompany(
        this.session.sessionID,
        this.session.company.key
      )
        .then(function (data) {
          self.clientList = data.data;
        })
        .catch(function (error) {
          console.error(
            "error ocurred for getAllClientsFromCompany in sessionService"
          );
          console.error(error);
        });
      this.ticketService
        .getAllTicketSenders(
          this.session.sessionID,
          this.session.company.key,
          0,
          999,
          0,
          100
        )
        .then(function (data) {
          self.ticketSenders = data.data;
        })
        .catch(function (error) {
          console.error(
            "error ocurred for getAllTicketSenders in sessionService"
          );
          console.error(error);
        });

      // sessionID: string, companyKey: string, fromStatus: number, toStatus: number,
      //     fromProcessStatus: number, toProcessStatus: number
    }

    private initNewMessageIcon() {
      let self = this;
      self.ticketService
        .getNbTicketClientHasToProcess(
          self.session.sessionID,
          self.session.company.key,
          1,
          -1,
          1
        )
        .then(function (data) {
          let tempData = data.data;
          if (tempData[0] > 0) {
            self.ticketNotification.icon = true;
          } else {
            self.ticketNotification.icon = false;
          }
        })
        .catch(function (error) {
          console.error("error ocurred");
        });
    }

    getAllAccounterFromCompany = (
      sessionID: string,
      accountFirmKey: string
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.session.admin)
        .all(this.RESTAPI.services.session.accounterCompany)
        .one(sessionID + "&" + accountFirmKey)
        .get();
    };

    getAllClientsFromCompany = (
      sessionID: string,
      companyKey: string
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.session.admin)
        .all(this.RESTAPI.services.session.memberCompany)
        .one(sessionID + "&" + companyKey)
        .get();
    };

    getSpecificMember = (userKey: string): ng.IPromise<any> => {
      let sessionID = this.session.sessionID;
      return this.rest
        .all(this.RESTAPI.services.ticket.community)
        .all(this.RESTAPI.services.session.specificMember)
        .one(sessionID + "&" + userKey)
        .get();
    };

    public setUsername(username) {
      this.username = username;
    }

    public setPassword(password) {
      this.password = password;
    }

    //create the roles tab with the data in the member class
    private initRolesTab() {
      let member = new app.model.Member();
      this.roles[member.CUSTOMER()] = "CUSTOMER";
      this.roles[member.ACCOUNTER()] = "ACCOUNTER";
      this.roles[member.ACCOUNTER_ADMIN()] = "ACCOUNTER_ADMIN";
      this.roles[member.ACCOUNTER_AS_USER()] = "ACCOUNTER_AS_USER";
      this.roles[member.ACCOUNTER_AS_IPP()] = "ACCOUNTER_AS_IPP";
      this.roles[member.GLOBAL_USER_API()] = "GLOBAL_USER_API";
      this.roles[member.ADMIN()] = "ADMIN";
    }

    // method to check if user have been logged even once since app startup
    public isIdentityResolved() {
      return angular.isDefined(this.session);
    }

    // method to check if user is logged-in successfully
    public isAuthenticated() {
      return this.authenticated;
    }

    // check user level
    public isInLevel(role) {
      // we may use another variable to check the level
      if (!this.authenticated || !this.session.member.accesLevel) {
        return false;
      }
      return this.roles.indexOf(role) !== -1;
    }

    // check user level, we compare with the strings
    public isInAnyLevel(role) {
      if (!this.authenticated || !this.session.member.accesLevel) {
        return false;
      }
      if (
        this.roles != null &&
        this.roles != undefined &&
        this.roles.length == 0
      ) {
        return true;
      }
      for (let i = 0; i < this.roles.length; i++) {
        if (this.isInLevel(role)) {
          return true;
        }
      }
      return false;
    }

    // check if current user in session is a cutomer
    public isCustomer(): boolean {
      if (!this.authenticated) {
        return false;
      }

      return (
        this.session.member.accesLevel === 0 ||
        this.session.member.accesLevel === 250
      );
    }

    // check if current user in session is an accounter
    public isAccounter(): boolean {
      if (!this.authenticated) {
        return false;
      }

      return (
        this.session.member.accesLevel === 200 ||
        this.session.member.accesLevel === 299
      );
    }

    // authenticate the user, get his data through method
    public authenticate(userData) {
      if (userData.accesLevel === 0 || userData.accesLevel === 250 || userData.accesLevel==275) {
        this.session = this.instanceConverter.createInstanceFromJson(
          app.model.UserSession,
          userData
        );
      } else if (
        userData.accesLevel === 200 ||
        userData.accesLevel === 299 ||
        userData.accesLevel === 1000
      ) {
        this.session = this.instanceConverter.createInstanceFromJson(
          app.model.AccountingSession,
          userData
        );
      } else {
        this.session = userData;
        let member = {
          key: "12345",
          username: "SYSTEM",
          email: "SYSTEM@skwarel.com",
        };
        this.loggerService.customError(
          "SessionService.ts",
          this.session,
          member
        );
        console.error("ERROR AUTHENTICATED CATCHED", this.session);
      }

      if (this.session != null) {
        this.$translate.use(this.session.member.language.toLowerCase());
        this.tmhDynamicLocale.set(this.session.member.language.toLowerCase());
      }

      this.authenticated = angular.isDefined(this.session);
    }

    public getSessionBasedOnId(sessionID: string): ng.IPromise<any> {
      // if we really want to refresh the session, we set it to undefined to get another session from backend
      this.session = undefined;
      let defered = this.$q.defer();
      let self = this;

      this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .all(this.RESTAPI.services.session.getSession)
        .one(sessionID)
        .get()
        .then(function (data) {
          let dataResolved = data.plain();

          if (dataResolved.status === 0) {
            self.setUsername(dataResolved.data.member.username);
            self.setPassword(dataResolved.data.member.password);
            self.authenticate(dataResolved.data);
            // once the session is set, we initialize the clients and accounters lists
            if (
              self.session.company != null &&
              self.session.company.key != null
            ) {
              self.initAccountersAndClientsLists();
              self.initUploadFolderName();
              self.initNewMessageIcon();
            }
            self.initIntercom();
          } else {
            // temporary solution, when reloaded, page go to login automaticaly when special status is detected
            self.state.go("login");
          }

          // passage dans mydata proxy manager
          defered.resolve(data);

          // once the session is set, we initialize the clients and accounters lists
          // self.initAccountersAndClientsLists();
        })
        .catch(function (error) {
          console.error("error ocurred on getSession");
          console.error(error);
          defered.reject(error);
        });
      return defered.promise;
    }

    /*redirectDueToBranding = (url) => {
		 let self = this;
		 console.error("hello ? ");
		 self.$window.location.href = "https://"+url;
		 return () => {
		 console.error("hello 2  ? ");
		 self.$window.location.href = "https://"+url;
		 };
		 }*/

    //GET SESSION WITH CREDENTIALS
    public getSession(force: boolean): ng.IPromise<any> {
      let defered = this.$q.defer();
      let self = this;

      // if we really want to refresh the session, we set it to undefined to get another session from backend
      if (force === true) {
        this.session = undefined;
      }

      // if it is defined, we get the data back
      if (angular.isDefined(this.session)) {
        // simulate a remote object with status from server
        let returnData = {
          status: 0,
          data: this.session,
        };
        defered.resolve(returnData);
        return defered.promise;
      }

      let request = this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .all(this.RESTAPI.services.session.login);

      request
        .customPOST({
          username: this.username,
          password: this.password,
          timezoneName: "BE",
          version: this.serverVersion,
        })
        .then(function (data) {
          let dataResolved = data.plain();
          if (dataResolved === 407) {
            self.modalUtil
              .status(
                self.$translate.instant("MODAL.WRONG_URL.body_1") +
                  dataResolved.data +
                  " " +
                  self.$translate.instant("MODAL.WRONG_URL.body_2"),
                "MODAL.WRONG_URL.title",
                true
              )
              .then(function () {
                self.$window.location.href = "https://" + dataResolved.data;
              })
              .catch(function (error) {});
            return;
          }

          // check branding url
          let url = self.$window.location.hostname;

          // case of multicompany
          if (dataResolved.status === 2001) {
            // self.modalService.createMultiCompanyModal();
            if (this.session != null) {
              self.$translate.use(dataResolved[0].language.toLowerCase());
              self.tmhDynamicLocale.set(dataResolved[0].language.toLowerCase());
            }
            if (dataResolved.data[0].betaTester === false) {
              self.$uibModal.open({
                templateUrl: "tpl/website/modals/companyList.html",
                controller: "MultiCompanyModalController",
                controllerAs: "mc",
                size: "sm",
                backdrop: "static",
                keyboard: false,
                windowClass: "companylist-modal",
                resolve: {
                  r_data: function () {
                    return {
                      data: dataResolved.data,
                    };
                  },
                  switchCompany: false,
                },
              });
            } else {
              self.$uibModal.open({
                templateUrl: "tpl/website/modals/companyList.html",
                controller: "MultiCompanyModalController",
                controllerAs: "mc",
                size: "sm",
                backdrop: "static",
                keyboard: false,
                windowClass: "companylist-modal",
                resolve: {
                  r_data: function () {
                    return {
                      data: dataResolved.data,
                    };
                  },
                  switchCompany: false,
                },
              });
            }
          } else if (dataResolved.status === 2006) {
            self.authenticate(dataResolved.data);
          } else if (dataResolved.status === 0) {
            self.authenticate(dataResolved.data);
            if (
              dataResolved.data.accesLevel === 0 ||
              dataResolved.data.accesLevel === 250
            ) {
              // once the session is set, we initialize the clients and accounters lists
              if (
                self.session.company !== null &&
                self.session.company !== undefined
              ) {
                self.initAccountersAndClientsLists();
                self.initUploadFolderName();
              }
              self.initIntercom();
            }
          }
          // else {
          //     // temporary solution, when reloaded, page go to login automaticaly when special status is detected
          //     self.state.go("login");
          // }
          // passage dans mydata proxy manager
          defered.resolve(dataResolved);

          // once the session is set, we initialize the clients and accounters lists
          // TODO : not sure if it is good idea to comment that
          // self.initAccountersAndClientsLists();
        })
        .catch(function (error) {
          console.error("error ocurred on getSession", error);
          defered.reject(error);
        });

      return defered.promise;
    }

    public initIntercom() {
      let self = this;
      if(self.session && self.session.member){
        self
          .getIntercomData(this.session.member.key)
          .then(function (data) {
            let user_hash = data.plain();
            (<any>self.$window).Intercom("boot", {
              app_id: "giomerin",
              type: "user",
              email: self.session.member.mail,
              user_id: self.session.member.key,
              id: self.session.member.key,
              current_user: self.session.member.key,
              user_hash: user_hash.data,
              custom_launcher_selector: "#Intercom",
            });
          })
          .catch(function (error) {
            console.error("Cannot init Intercom");
            console.error(error);
          });
      }
    }

    getIntercomData = (memberKey: string): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.intercom.base)
        .all(this.RESTAPI.services.intercom.getIntercomData)
        .one(memberKey)
        .get();
    };

    doLogin = (
      member: any,
      companyKey: string,
      timezoneName: string,
      accountantAsUser: boolean
    ): ng.IPromise<any> => {
      let self = this;
      let defered = this.$q.defer();

      let request = this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .all(this.RESTAPI.services.session.doLogin);

      request
        .customPOST({
          member: member,
          companyKey: companyKey,
          timezoneName: "BE",
          accountantAsUser: accountantAsUser,
        })
        .then(function (data) {
          let dataResolved = data.plain();
          self.authenticate(dataResolved.data);
          if (
            self.session.company != null &&
            self.session.company.key != null
          ) {
            self.initAccountersAndClientsLists();
            self.initUploadFolderName();
            self.initNewMessageIcon();
          }

          self.initIntercom();
          defered.resolve(data);
        })
        .catch(function (error) {
          console.error(error);
          console.error("error ocurred on Do login");
          defered.reject(error);
        });

      return defered.promise;
    };

    // /logoutUser/{sessionID}

    getBIToken = (sessionID: string): ng.IPromise<any> => {
      let defered = this.$q.defer();

      this.rest
        .all("BIMgr")
        .all("askToken")
        .one(this.session.sessionID)
        .get()
        .then(function (data) {
          let dataResolved = data.plain();
          defered.resolve(dataResolved);
        })
        .catch(function (error) {
          defered.reject(error);
          console.error("error occurred on getMyCompaniesLinked");
        });

      return defered.promise;
    };

    logOut = (sessionID: string): ng.IPromise<any> => {
      (<any>this.$window).Intercom("shutdown");
      let request = this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .all(this.RESTAPI.services.session.logoutUser)
        .one(sessionID);
      document.title = "Skwarel";
      return request.customPOST();
    };

    confirmRegulation = (
      sessionID: string,
      firstname: string,
      lastname: string,
      mail: string,
      language: string,
      pwd: string,
      oldPassword
    ): ng.IPromise<any> => {
      let request = this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .all(this.RESTAPI.services.session.regulation);
      return request.customPUT({
        firstname: firstname,
        lastname: lastname,
        mail: mail,
        password: pwd,
        sessionID: sessionID,
        language: language,
        oldPassword: oldPassword,
      });
    };

    public switchVersion(betaTester: boolean) {
      return this.$http.put(`api/customer/member/switchBetaTester`, betaTester);
    }

    public getMyCompaniesLinked(): ng.IPromise<any> {
      let defered = this.$q.defer();

      this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .all(this.RESTAPI.services.session.companyLinked)
        .one(this.session.sessionID + "&" + this.session.member.key)
        .get()
        .then(function (data) {
          let dataResolved = data.plain();
          defered.resolve(dataResolved);
        })
        .catch(function (error) {
          defered.reject(error);
          console.error("error occurred on getMyCompaniesLinked");
        });

      return defered.promise;
    }

    public createMultiCompanyModal(): ng.IPromise<any> {
      let defered = this.$q.defer();

      this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .all(this.RESTAPI.services.session.getCompanyMap)
        .one(this.session.sessionID + "&" + this.session.member.key)
        .get()
        .then(function (data) {
          let dataResolved = data.plain();
          defered.resolve(dataResolved);
        })
        .catch(function (error) {
          defered.reject(error);
          console.error("error occurred on createMultiCompanyModal");
        });

      return defered.promise;
    }

    public refreshAccessToken(): ng.IPromise<any> {
      return this.rest
        .one(this.RESTAPI.services.session.refreshAccessToken)
        .get()
        .catch(function (error) {});
    }

    public me(): ng.IPromise<any> {
      let self = this;
      let defered = this.$q.defer();

      if (angular.isDefined(this.session)) {
        if (this.session.company && this.session.company.country) {
          if (
            this.session.company.country.toLowerCase() === "france" &&
            this.session.member.language.toLowerCase() === "fr"
          ) {
            this.$translate.use("fr_fr");
          } else {
            self.$translate.use(this.session.member.language.toLowerCase());
          }
        } else {
          self.$translate.use(this.session.member.language.toLowerCase());
        }
        if (self.session.company != null && self.session.company.key != null) {
          self.initAccountersAndClientsLists();
          self.initUploadFolderName();
          self.initNewMessageIcon();
        }
        self.initIntercom();
        self.tmhDynamicLocale.set(this.session.member.language.toLowerCase());
        defered.resolve(this.session);
        self.fromDirectUrl = false;
        return defered.promise;
      }

      //self.fromDirectUrl = true;
      if (this.$window.location.href.search("v2") > 0) {
        return this.rest
          .all(this.RESTAPI.services.session.baseurl)
          .one(this.RESTAPI.services.session.meV2)
          .get()
          .then(function (response) {
            self.authenticate(response);
            // once the session is set, we initialize the clients and accounters lists
            defered.resolve(response);
            return defered.promise;
          })
          .catch(function (err) {
            defered.reject(err);
            return defered.promise;
          });
      }

      return this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .one(this.RESTAPI.services.session.me)
        .get()
        .then(function (response) {
          self.authenticate(response);
          // once the session is set, we initialize the clients and accounters lists
          if (
            self.session.company != null &&
            self.session.company.key != null
          ) {
            self.initAccountersAndClientsLists();
            self.initUploadFolderName();
            self.initNewMessageIcon();
          }
          self.initIntercom();
          defered.resolve(response);
          return defered.promise;
        })
        .catch(function (err) {
          defered.reject(err);
          return defered.promise;
        });
    }

    public getCSRF(): ng.IPromise<any> {
      let self = this;
      let defered = this.$q.defer();

      if (this.CSRFSetted === true) {
        defered.resolve();
        return defered.promise;
      }

      return this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .one(this.RESTAPI.services.session.getVersion)
        .get()
        .then(function (data) {
          let dataResolved = data.plain();
          self.CSRFSetted = true;
          // self.serverVersion = dataResolved.version;
          // HERE WE GOT THE SERVER VERSION. TODO : Necessary to use that?
          defered.resolve(dataResolved);
          return defered.promise;
        })
        .catch(function (error) {
          defered.reject(error);
          return defered.promise;
        });
    }

    private arrayContains(userLevel, authorizedLevelForState) {
      return authorizedLevelForState.indexOf(userLevel) > -1;
    }

    public hasAllFeature(
      userFeature: string,
      features: Array<string>
    ): boolean {
      let result = true;
      if (features == null) {
        return result;
      }
      angular.forEach(features, function (value, key) {
        if (userFeature.indexOf(value) === -1) {
          result = false;
        }
      });
      return result;
    }

    // GET SESSION WITH JWT TOKEN
    public authorize() {
      let self = this;

      return self
        .getCSRF()
        .then(function () {
          return self.me();
        })
        .then(function () {
          let isAuthenticated = self.isAuthenticated();
          if (isAuthenticated && !self.isInAnyLevel(self.toState.data.roles)) {
            let userLevel: string = self.roles[self.session.member.accesLevel];
            let authorizedLevelForState: Array<string> =
              self.toState.data.roles;
            let userFeature: string = "";
            if (
              self.session.company != null &&
              self.session.company.key != null
            ) {
              userFeature =
                self.session.member.featuresByComp[self.session.company.key];
            }
            let temp: Array<string> = self.toState.data.features;
            let notFeature: Array<string> = self.toState.data.notFeatures;
            // check if the string level from userSession is defined in the string tab passed to the state in the router
            if (
              self.arrayContains(userLevel, authorizedLevelForState) &&
              self.hasAllFeature(userFeature, temp) &&
              (notFeature == null ||
                self.hasAllFeature(userFeature, notFeature) === false)
            ) {
              let brand = self.session.brandingData;
              if (
                self.session.accountingFirmBranding > 0 &&
                brand != null &&
                brand.iconPath != null
              ) {
                let link = document.createElement("link");
                let oldLink = document.getElementById("dynamic-favicon");
                link.id = "dynamic-favicon";
                link.rel = "shortcut icon";
                link.href =
                  "https://s3-eu-west-1.amazonaws.com/" + brand.iconPath;
                if (oldLink) {
                  document.head.removeChild(oldLink);
                }
                document.head.appendChild(link);
              }

              if (
                self.session.company &&
                self.session.company.key &&
                self.session.accountingFirmBranding > 0 &&
                brand != null &&
                self.session.company != null &&
                self.session.company.name != null
              ) {
                document.title = self.session.company.name + " - Skwarel";
              } else {
                document.title = "Skwarel";
              }

              if (self.fromDirectUrl === true) {
                self.fromDirectUrl = false;
                self.state.go(self.toState, self.toStateParams);
              } else {
                return;
              }
            } else if (
              isAuthenticated &&
              (userLevel === "ACCOUNTER_AS_IPP" ||
                (self.session.member.tax_form == true &&
                  self.session.company == null))
            ) {
              // Special case : only access to IPP, nothing else
              let branding = self.session.branding;
              if (
                self.session.accountingFirmBranding > 0 &&
                branding &&
                branding.iconPath
              ) {
                let link = document.createElement("link");
                let oldLink = document.getElementById("dynamic-favicon");
                link.id = "dynamic-favicon";
                link.rel = "shortcut icon";
                link.href =
                  "https://s3-eu-west-1.amazonaws.com/" + branding.iconPath;
                if (oldLink) {
                  document.head.removeChild(oldLink);
                }
                document.head.appendChild(link);
              }
              self.state.transitionTo(
                "ipp.form.home",
                {},
                {
                  reload: true,
                  inherit: false,
                  notify: true,
                }
              );
            } else if (isAuthenticated) {
              let branding = self.session.branding;
              if (
                self.session.accountingFirmBranding > 0 &&
                branding &&
                branding.iconPath
              ) {
                let link = document.createElement("link");
                let oldLink = document.getElementById("dynamic-favicon");
                link.id = "dynamic-favicon";
                link.rel = "shortcut icon";
                link.href =
                  "https://s3-eu-west-1.amazonaws.com/" + branding.iconPath;
                if (oldLink) {
                  document.head.removeChild(oldLink);
                }
                document.head.appendChild(link);
              }
              // We go back to the dashboard AND we reload
              // so if we switch company and go to an impossible state, we refresh the app due to switch and go back to dashboard

              self.state.transitionTo(
                self.getTransitionToHome(
                  self.session.member.defaultHomePage,
                  self.session.company.key
                ),
                {},
                {
                  reload: true,
                  inherit: false,
                  notify: true,
                }
              );
              // self.state.go(self.returnToState, self.returnToStateParams);
              // todo : also pass the Params
              // should remain on the precedent state, and not go to login
            } else {
              // user is not authenticated
              // save the state he wanted to go, go to login state, then send them back there
              console.error("user is not signed in, go to login page");
              console.error("not authorized and not signed");

              // todo: check old parameters&
              self.state.go("login");
            }
          } else {
            self.state.go("login");
          }
        })
        .catch(function (err) {
          console.error("error during authorization", err);
          if (err.status === 407) {
            self.modalUtil
              .status(
                self.$translate.instant("MODAL.WRONG_URL.body_1") +
                  err.data +
                  " " +
                  self.$translate.instant("MODAL.WRONG_URL.body_2"),
                "MODAL.WRONG_URL.title",
                true
              )
              .then(function () {
                self.$window.location.href = "https://" + err.data;
              })
              .catch(function (error) {});
            return;
          } else if (err.status === 403) {
            /*if(this.returnToState === undefined || this.returnToState.url == "^"){
						 self.state.go(self.toState, self.toStateParams);
						 }else{
						 self.state.go("login");
						 }*/
            self.state.transitionTo(
              "login",
              {},
              {
                reload: true,
                inherit: true,
                notify: true,
              }
            );
          }
        });
    }

    public getTransitionToHome(homepages, companyKey) {
      let reference = "";
      if (
        homepages != undefined &&
        companyKey != undefined &&
        homepages[companyKey] != undefined
      ) {
        reference = homepages[companyKey];
      }
      switch (reference) {
        case "DASHBOARD01":
          return "websitelayout.headerandmenu.dashboard";
        case "PROFIT01":
          return "websitelayout.headerandmenu.mainProfit";
        case "PROFIT02":
          return "websitelayout.headerandmenu.balance";
        case "PROFIT03":
          return "websitelayout.headerandmenu.income";
        case "PROFIT04":
          return "websitelayout.headerandmenu.turnover";
        case "PROFIT05":
          return "websitelayout.headerandmenu.comparison";
        case "PROFIT06":
          return "websitelayout.headerandmenu.purchase";
        case "PROFIT07":
          return "websitelayout.headerandmenu.sale";
        case "TREASURY01":
          return "websitelayout.headerandmenu.mainTreasury";
        case "TREASURY02":
          return "websitelayout.headerandmenu.wcr";
        case "TREASURY03":
          return "websitelayout.headerandmenu.wc";
        case "TREASURY04":
          return "websitelayout.headerandmenu.bankBalance";
        case "LIQUIDITY01":
          return "websitelayout.headerandmenu.liquidityOverview";
        case "LIQUIDITY02":
          return "websitelayout.headerandmenu.receivable";
        case "LIQUIDITY03":
          return "websitelayout.headerandmenu.debtor";
        case "LIQUIDITY04":
          return "websitelayout.headerandmenu.vatOverview";
        case "INVOICING01":
          return "websitelayout.headerandmenu.invoicingOverview";
        case "INVOICEREMINDER01":
          return "websitelayout.headerandmenu.invoiceReminderOverview";
        case "OTHERU":
          return "websitelayout.headerandmenu.toSend";
        case "OTHERARCH":
          return "websitelayout.headerandmenu.archive";
        case "OTHERM":
          return "websitelayout.headerandmenu.ticket";
        case "PMF":
        case "OTHERPMF":
          return "websitelayout.headerandmenu.permanentfolder";
        case "OTHERRS":
          return "websitelayout.headerandmenu.search";
        default:
          return "websitelayout.headerandmenu.dashboard";
      }
    }

    public resetPwd(mail): ng.IPromise<any> {
      let self = this;
      return self.getCSRF().then(function () {
        return self.rest
          .all(self.RESTAPI.services.session.baseurl)
          .one(self.RESTAPI.services.session.resetPwd)
          .customPOST({
            mail: mail,
          });
      });
    }

    public isPartOf(userKey, list) {
      let userName = "";
      for (let user of list) {
        if (user && user.key === userKey) {
          userName = user.fullName;
          break;
        }
      }
      return userName;
    }

    /**
     * Download folders available for the user
     */
    public getUploadFolderName(keyFolder) {
      let self = this;
      if (
        this.session.company.multiFolderEnable === false ||
        self.mapUploadFolder === undefined ||
        self.mapUploadFolder === null
      ) {
        return undefined;
      } else {
        return self.mapUploadFolder[keyFolder];
      }
    }

    /**
     * get the map of all upload folders
     * @returns the map of all uploadFolders
     */
    public getUploadFolderMap() {
      let self = this;
      if (
        this.session.company.multiFolderEnable === false ||
        self.mapUploadFolder === undefined ||
        self.mapUploadFolder === null
      ) {
        return undefined;
      } else {
        return self.mapUploadFolder;
      }
    }

    public initUploadFolderName() {
      let self = this;
      self
        .initUploadFolder(true)
        .then(function (data) {
          let tmpData = data.plain();
          let uploadFolderList = tmpData.data;
          self.mapUploadFolder = {};
          for (let uploadFolder of uploadFolderList) {
            self.mapUploadFolder[uploadFolder.key] = uploadFolder.name;
          }
        })
        .catch(function (error) {
          console.error("error ocurred");
          console.error(error);
        });
    }

    initUploadFolder = (showArchived: boolean): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.upload.accounting)
        .all(this.RESTAPI.services.upload.getUploadFolder)
        .one(
          this.session.sessionID +
            "&" +
            this.session.company.key +
            "&" +
            showArchived
        )
        .get();
    };

    public getUserName(input): any {
      let defered = this.$q.defer();

      if (!angular.isDefined(input) || input === null) {
        defered.resolve("Utilisateur supprimé");
        return defered.promise;
      }

      if (input === this.session.member.key) {
        defered.resolve(this.session.member.fullName);
        return defered.promise;
      }

      let isClient = this.isPartOf(input, this.clientList);
      let isAccountant = this.isPartOf(input, this.accountantList);

      if (isClient !== "") {
        defered.resolve(isClient);
      } else if (isAccountant !== "") {
        defered.resolve(isAccountant);
      } else {
        this.getSpecificMember(input)
          .then(function (data) {
            let returnData = data.plain();
            defered.resolve(returnData.data.fullName);
          })
          .catch(function (error) {
            defered.reject(error);
          });
      }
      return defered.promise;
    }

    public isUrlAccordingToBranding() {}

    public manageBetaTester(response, force?) {
      let self = this;

      if (response.status === 0 || angular.isDefined(force)) {
        let accessLevel = -1;
        let member = this.session.member;

        if (angular.isDefined(this.session)) {
          accessLevel = this.session.member.accesLevel;
        } else {
          return;
        }
        if (
          this.session.member.regulation == null ||
          this.session.member.regulation == false
        ) {
          let interf = self.modalUtil.checkInfo(this.session, response, force);
          interf.result.catch(function () {
            self
              .logOut(self.session.sessionID)
              .then(function () {
                self.mapUploadFolder = null;
                self.session = null;
                self.authenticated = false;
                document.title = "Skwarel";
                self.state.go("login", {}, { reload: true }); // to reload the page
              })
              .catch(function (error) {
                self.mapUploadFolder = null;
                self.session = null;
                self.authenticated = false;
                document.title = "Skwarel";
                self.state.go("login", {}, { reload: true });
              });
          });
          return;
        }

        // CHECK FOR BETA
        if (accessLevel === 200 || accessLevel === 299) {
          if (this.$window.location.href.search("v2") > 0) {
            //if come from a v2 page, go back to that page
            //this.$window.location.href = "http://localhost/v2/messaging/";
            this.$window.location.href = this.$window.location.href;
          } else {
            // if not comming from v2 page, go to v2 landing page
            this.$window.location.href =
              "/v2/" + (self.session.company.immo ? "clients" : "");
          }
        } else if (accessLevel < 200 || accessLevel === 250) {
          if (
            member.tax_form == true &&
            (self.session.company == null || self.session.company.key == null)
          ) {
            // Transition to IPP
            self.state.go("ipp.form.home");
          } else {
            if (member.betaTester === false) {
              if (
                /*self.returnToState !== undefined && self.returnToState.templateUrl == "tpl/website/login/login.html"
							 &&*/ self.fromDirectUrl == true &&
                self.toState !== undefined &&
                self.toState.name !== "websitelayout.headerandmenu.dashboard"
              ) {
                self.fromDirectUrl = false;
                self.state.go(self.toState, self.toStateParams);
              } else {
                self.state.go(
                  self.getTransitionToHome(
                    self.session.member.defaultHomePage,
                    self.session.company.key
                  )
                );
              }
            } else {
              this.$window.location.href = "/customer";
              localStorage.setItem("companyKey", self.session.company.key);
            }
          }
        } else if (accessLevel === 1000 && member.betaTester === false) {
          this.$window.location.href = "/adminOLD";
        } else if (accessLevel === 1000 && member.betaTester === true) {
          this.$window.location.href = "/admin";
        } else if (member.betaTester === false && accessLevel === 275) {
          self.state.go("ipp.form.home");
        } else {
          this.loggerService.customError(
            "SessionService.ts",
            "NO ACCURATE ACCESSLEVEL, SESSION DELETED",
            this.session.member
          );
          this.session = undefined;
          this.authenticated = false;
          this.state.go("login");
        }
      } else if (response.status === 2006) {
        let accessLevel = -1;
        let member = this.session.member;

        if (angular.isDefined(this.session)) {
          accessLevel = this.session.member.accesLevel;
        } else {
          return;
        }
        if (member.regulation == null || member.regulation == false) {
          let interf = self.modalUtil.checkInfo(this.session, response, true);
          interf.result.catch(function () {
            self
              .logOut(self.session.sessionID)
              .then(function () {
                self.mapUploadFolder = null;
                self.session = null;
                self.authenticated = false;
                document.title = "Skwarel";
                self.state.go("login", {}, { reload: true }); // to reload the page
              })
              .catch(function (error) {
                self.mapUploadFolder = null;
                self.session = null;
                self.authenticated = false;
                document.title = "Skwarel";
                self.state.go("login", {}, { reload: true });
              });
          });
          return;
        } else {
          // waiting status
          this.$window.location.href = "/acc";
        }
      } else if (response.status === 407) {
        self.modalUtil
          .status(
            self.$translate.instant("MODAL.WRONG_URL.body_1") +
              response.data +
              " " +
              self.$translate.instant("MODAL.WRONG_URL.body_2"),
            "MODAL.WRONG_URL.title",
            true
          )
          .then(function () {
            self.$window.location.href = "https://" + response.data;
          })
          .catch(function (error) {});
        return;
      }
    }

    public updateDiscreetMenuFlag(activate: boolean): ng.IPromise<any> {
      let defered = this.$q.defer();
      let self = this;

      let request = this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .all(this.RESTAPI.services.session.discreetMenu);

      request
        .customPOST({
          sessionID: this.session.sessionID,
          activate: activate,
        })
        .then(function (data) {
          defered.resolve(data);
          self.session.member.discreetMenu = activate;
        })
        .catch(function (error) {
          console.error(error);
          console.error("error ocurred on updateDiscreetMenuFlag");
          defered.reject(error);
        });

      return defered.promise;
    }

    public updateNumberOfDataToDisplay(number: number): ng.IPromise<any> {
      let defered = this.$q.defer();
      let self = this;

      let request = this.rest
        .all(this.RESTAPI.services.session.baseurl)
        .all(this.RESTAPI.services.session.numberOfDataToDisplay);

      request
        .customPOST({
          sessionID: this.session.sessionID,
          numberOfDataToDisplay: number,
        })
        .then(function (data) {
          defered.resolve(data);
          self.session.member.numberOfDataToDisplay = number;
        })
        .catch(function (error) {
          console.error(error);
          console.error("error ocurred on updateNumberOfDataToDisplayFlag");
          defered.reject(error);
        });

      return defered.promise;
    }

    public getCurrentSessionMember(): Member {
      if (this.session == null) {
        return null;
      } else {
        return this.session.member;
      }
    }

    public getSubscriptionPlan(): any {
      if (!this.session) {
        return null;
      } else {
        return this.session.subscription_plan;
      }
    }

    public hasMessagingFeature(): boolean {
      if (this.session.member) {
        let features =
          this.session.member.featuresByComp[this.session.company.key];
        if (features.indexOf(this.FEATURES.OTHERM) > -1) {
          return true;
        } else {
          return false;
        }
      }
    }

    public getInvoiceReminders() {
      return this.invoiceReminders;
    }

    public isInvoiceRemindersInit(): boolean {
      return (
        this.invoiceReminders !== undefined &&
        this.invoiceReminders !== null &&
        this.invoiceReminders.length > 0
      );
    }

    public setInvoiceReminders(invoiceReminders: any[]) {
      this.invoiceReminders = invoiceReminders;
    }
  }
}

angular
  .module("app")
  .service("SessionService", app.functionality.common.session.SessionService);
