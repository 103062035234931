/**
 * Created by mickael on 22/06/16.
 */
/// <reference path="../../_app.ts" />

/**
 * Module of the permanent folder's functionnality
 * @module
 * @name permanentfolder
 */

module app.functionality.permanentfolder {
  import UploadService = app.functionality.upload.UploadService;
  import ModalUtil = app.functionality.common.modals.ModalUtil;

  /**
   * This class is the controller of the permanent folder's functionnality
   * @class
   * @name PermanentFolderController
   */
  export class PermanentFolderController {
    // private data: any;
    private gridOptions: any;
    private isInSearch: Boolean = false;
    public companyKey: String;
    public pmfGED: Boolean = false;
    public recherche: string = "";
    public currentPath: string = "";
    public isSimpleUser: boolean = true;
    public currentSorting: number = 0;
    public sortOptions = ["nameAZ", "nameZA", "dateCr", "dateDe"];

    static $inject = [
      "$scope",
      "$window",
      "$state",
      "r_folders",
      "$uibModal",
      "ENV",
      "ModalUtil",
      "PermanentFolderService",
      "Restangular",
      "$http",
      "$compile",
      "SessionService",
      "$translatePartialLoader",
      "$stateParams",
      "UploadService",
      "$timeout",
      "Notification",
      "$sanitize",
      "$translate",
    ];

    /**
     * This is the constructor of the controller. This function is called each time the page is requested, by a window.open for instance
     * @constructs
     * @param $scope  - Then AngularJS' scope
     * @param $window - The javascript window object
     * @param $state
     * @param folders
     * @param $uibModal
     * @param ENV
     * @param permanentFolderService - The reference to the service of the permanent folder functionnality
     * @param restangular - The restangular object used to create REST requests
     * @param $http - The javascript http object
     * @param $compile - The AngularJS object compile, used for processing and create HTML tag in typescript
     * @param sessionService
     * @param $translatePartialLoader
     * @param $stateParams - Object use for accessible the parameters in the url
     * @param $timeout - We use timeout in order to manage the displaying of the  bootstrap alerts
     * @param notification
     * @param $translate
     */

    constructor(
      protected $scope: any,
      protected $window: any,
      protected $state: ng.ui.IStateService,
      public folders: any,
      public $uibModal: any,
      private ENV: ENV,
      public modalUtil: ModalUtil,
      public permanentFolderService: app.functionality.permanentfolder.PermanentFolderService,
      protected restangular: restangular.IService,
      protected $http: any,
      protected $compile: ng.ICompileService,
      public sessionService: app.functionality.common.session.SessionService,
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      protected $stateParams: ng.ui.IStateParamsService,
      private uploadService: UploadService,
      protected $timeout: ng.ITimeoutService,
      protected notification: any,
      protected $sanitize: ng.sanitize.ISanitizeService,
      private $translate: ngt.ITranslateService
    ) {
      $translatePartialLoader.addPart("customer/permanentFolder");

      // we use a variable "folders" because we make an asynchronous call through REST methods. Thus the data may not be loaded when the application is launched, hence a ui-grid exception is raised
      this.gridOptions = this.setGridOptions(folders);
      this.updateGrid(folders);

      // this.treeLevelRequestFromStateParams();
      this.buildArianePathFromStateParams();
      this.processLastChild();
      this.companyKey = this.sessionService.session.company.key;
      this.pmfGED =
        this.sessionService.session.company.permanentFolder.pmfConnector
          .type === 0 ||
        this.sessionService.session.company.permanentFolder.pmfConnector
          .type === 3;
      this.isSimpleUser = this.sessionService.session.member.accesLevel === 0;

      let fileInput: any = null;

      fileInput = document.querySelector("#fileInput");
      fileInput.addEventListener("change", this.launchUploadModal(fileInput));
      this.uploadService.fileInput = fileInput;
      let self = this;
      $scope.$on("addPMFFile", function (event, bool: Boolean) {
        self.treeLevelRequestFromVariable(
          self.$stateParams.treelevel,
          self.$stateParams.path
        );
      });

      $scope.status = {
        isopen: false,
      };

      $scope.toggleDropdown = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.status.isopen = !$scope.status.isopen;
      };
    }

    /**
     * PROGRESS BAR
     * @param fileInput :  liste des file à envoyer
     * @returns {(e: any)=>void}
     */
    public launchUploadModal(fileInput: any) {
      let self = this;

      return function (e): void {
        let differentname = self.checkNameExist(fileInput, undefined, true);
        if (differentname === true) {
          if (fileInput.files.length > 0) {
            let path = decodeURI(self.$stateParams.path);

            if (path === "root") {
              path = "";
            }
            self.$uibModal.open({
              templateUrl: "tpl/website/modals/uploadModal.html",
              controller: "UploadModalController",
              controllerAs: "uploadModalController",
              //Permet de bloquer le click en dehors du modal
              backdrop: "static",
              size: "md",
              resolve: {
                pmf_data: function () {
                  return {
                    fileInput: fileInput,
                    level: self.$stateParams.treelevel,
                    path: path,
                  };
                },
                r_data: undefined,
                ipp_data: undefined,
              },
            });
          }
        }
      };
    }

    public checkNameExist(fileInput, newName, isData): Boolean {
      if (fileInput !== undefined && fileInput.files.length > 0) {
        for (let file of fileInput.files) {
          let name = file.name;
          for (let fileGrid of this.folders) {
            if (name === fileGrid.name) {
              this.notification.primary(
                this.$translate.instant("PERMANENTFOLDER.nameExist") +
                  " : " +
                  name
              );
              return false;
            }
          }
        }
        return true;
      } else if (fileInput === undefined && newName !== undefined) {
        let count = 0;
        for (let fileGrid of this.folders) {
          if (newName === fileGrid.name) {
            count++;

            if (count > 1) {
              // when changing name, it change also in fileGrid, so we will have 1 occurence of same name
              return false;
            }
          }
        }
        return true;
      } else {
        return true;
      }
    }

    /**
     * A method for a REST call to the server using the parameters in the url of the application. The server, according to lazy loading, provides in JSON format the corresponding data
     * @name treeLevelRequestFromStateParams
     * @function
     */

    public treeLevelRequestFromStateParams() {
      let self = this; // this is not recognised in the context of a promise, therefore we use it by reference with self

      //a call to the method getTree, processing the lazy loading of the data using the parameters in the url
      self.permanentFolderService
        .getTree(
          self.sessionService.session.company.key,
          this.$stateParams.treelevel,
          this.$stateParams.path
        )
        .then(function (data) {
          self.updateGrid(data);
          self.folders = data;
        })
        .catch(function (error) {
          console.error("error ocurred");
        });
    }

    /**
     * A method for a REST call to the server using the parameters in the url of the application. The server, according to lazy loading, provides in JSON format the corresponding data
     * @name treeLevelRequestFromVariable
     * @function
     */

    public treeLevelRequestFromVariable(treelevel, path) {
      // TODO USE ROUTE INSTEAD OF RESTANGULAR.ALLURL";
      let self = this;
      //a call to the method getTree, processing the lazy loading of the data using the parameters treelevel and path
      self.permanentFolderService
        .getTree(self.sessionService.session.company.key, treelevel, path)
        .then(function (data) {
          self.updateGrid(data);
          self.folders = data;
        })
        .catch(function (error) {
          console.error("error ocurred");
        });
    }

    /**
     * Builds the ariane's thread using the parameters in the url, treelevel and path
     * @name buildArianePathFromStateParams
     * @function
     */

    public buildArianePathFromStateParams() {
      let pathAriane: any;
      let elementAriane: any;
      let elementAngularAriane: any;
      let rootFolder: any;
      let i: number;
      let stringTmp: string;
      let id: any;
      let classe: string;
      let elementToAppend: any;
      let hrefTmp: any;

      // We get the path from the parameter in the url. It has been processed so as the "/" are now ".". In order to get the name of each folder, we split with "."
      let path = "path";
      pathAriane = decodeURI(this.$stateParams[path]).split("/");

      elementAriane = document.getElementById("ariane");
      elementAngularAriane = angular.element(elementAriane);
      elementAngularAriane.empty();
      hrefTmp = "#permanentFolder?treelevel=" + 0 + "&path=" + "root";
      elementToAppend =
        "<a href='" +
        hrefTmp +
        "' ng-click=\"pf.arianeUpdateWhenClickedOn(0, root)\"><i style='vertical-align: middle' class='material-icons'>folder_special</i></a> "; // This is the root element of the ariane's thread
      rootFolder = this.$compile(angular.element(elementToAppend))(this.$scope); // We use compile in order to produce the HTML tag required
      elementAngularAriane.append(rootFolder); // The first element of the thread is the root
      stringTmp = ""; // We use a temporary string to build the thread

      for (i = 0; i < pathAriane.length; i++) {
        //pathAriane[i] = escape(pathAriane[i]);
        pathAriane[i] = pathAriane[i].replace(/'/g, "%27");
        if (i > 0) {
          stringTmp += "/";
        }

        if (i === pathAriane.length - 1) {
          stringTmp = ""; // We don't want a dot at the very end of the string
        }

        stringTmp += pathAriane[i];

        if (
          pathAriane[i] == null ||
          pathAriane[i] == "" ||
          pathAriane[i] == " "
        ) {
          console.error(pathAriane[i] + "  " + i);
          break;
        }

        id = i + 1; // We want to lazy load the treelevel right after the last folder
        classe = stringTmp;

        hrefTmp = "#permanentFolder?treelevel=" + id + "&path=" + classe;

        let treelevel = "treelevel";

        //this.$stateParams[path] = escape(this.$stateParams[path]);
        this.$stateParams[path] = this.$stateParams[path].replace(/'/g, "%27");

        if (this.$stateParams[treelevel] === "0") {
          elementToAppend =
            "<a href='" +
            hrefTmp +
            "' ng-click=\"pf.arianeUpdateWhenClickedOn('" +
            this.$stateParams[treelevel] +
            "','" +
            this.$stateParams[path] +
            ') "> </a>';
        } else {
          if (i == pathAriane.length - 1) {
            // last one, no balise <a>
            elementToAppend =
              //  "<a>" + decodeURI(unescape(pathAriane[i])) + "</a>";
              "<a>" + decodeURI(pathAriane[i]) + "</a>";
          } else {
            elementToAppend =
              "<a href='" +
              hrefTmp +
              "' ng-click=\"pf.arianeUpdateWhenClickedOn('" +
              this.$stateParams[treelevel] +
              "','" +
              this.$stateParams[path] +
              "') \">" +
              //decodeURI(unescape(pathAriane[i])) +
              decodeURI(pathAriane[i]) +
              "</a>";
          }
          let elementToAppendAngular = this.$compile(
            angular.element(elementToAppend)
          )(this.$scope);
          let tmp: any;
          tmp = "<span>/</span>";
          let tmpAngular = this.$compile(angular.element(tmp))(this.$scope);
          tmpAngular.css({ "margin-left": "5px", "margin-right": "5px" }); // We create the spaces between the element in the thread
          elementAngularAriane.append(tmpAngular);
          elementAngularAriane.append(elementToAppendAngular);
        }
      }
    }

    /**
     * Fetches the last element in the ariane's thread , disables the click and changes its color
     * @name processLastChild
     * @function
     */

    public processLastChild() {
      let elementAriane: any;
      let lastChild: any;
      let lastChildAngular: any;
      elementAriane = document.getElementById("ariane");
      lastChild = elementAriane.lastChild;
      lastChildAngular = angular.element(lastChild);
      lastChildAngular.css({ "pointer-events": "none", color: "black" }); // "pointer-events : none" disables the events that can be triggered by clicking on the element
    }

    /**
     * Puts the data from the JSON file in the ui-grid
     * @name setGridOptions
     * @param myData - The data received from the server (JSON file with the folders and files)
     * @returns {{columnDefs: ({displayName: string, field: string, cellTemplate: string}|
     *                          {displayName: string, width: string, field: string, cellFilter: string}|
     *                          {displayName: string, width: string, field: string})[],
     *                          data: any,
     *                          enableFiltering: boolean,
     *                          enableColumnMenus: boolean,
     *                          enableSorting: boolean,
     *                          enableColumnResize: boolean}}
     */

    public setGridOptions(myData) {
      return {
        columnDefs: [
          {
            displayName: "PERMANENTFOLDER.GRID.name",
            field: "name",
            cellTemplate: "tpl/grid/permanentfolder/nameCellTemplate.html",
            headerCellFilter: "translate",
            headerCellClass: "pmf-grid-header-title",
          },
        ],
        data: myData,
        enableFiltering: false,
        enableColumnMenus: false,
        enableSorting: true,
        enableColumnResize: true,
        showHeader: false,
        showFooter: false,
      };
    }

    /**
     * Changes the content of the ui-grid by updating the informations according to the user's requests and actions
     * @name updateGrid
     * @param data
     */
    public updateGrid(data) {
      data.sort(function (a, b) {
        if (
          (a.isData === false && b.isData === false) ||
          (a.isData === true && b.isData === true)
        ) {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          }
          return 0;
        } else if (a.isData === false && b.isData === true) {
          return -1;
        } else if (a.isData === true && b.isData === false) {
          return 1;
        }
      });
      this.currentSorting = 0;
      this.gridOptions.data = data;
      this.folders = data;
    }

    /**
     * Method called when the user clicks on an element in the ui-grid. If it's a folder, a request is sent to the server in order to process the appropriate lazy loading. If it's a data, the corresponding file is fetched from Amazon S3
     * @name openDocument
     * @param path - The path used to make the request to Amazon S3
     * @param key - The name of the data (if the element is a data)
     * @param isData - A boolean, true if the element is a data, false if it's a folder
     * @param treelevel - The position of the element in the tree
     */
    public openDocument(path, key, isData, treelevel) {
      let modifiedPath = this.computeModifiedPath(path);

      if (isData) {
        // If it's a data, we want to display the content
        let keyTmp = key.split("."); // We check if the key - which is the name of the data - contains dot, in order to identify the extension
        let extensionDocument: string;

        if (keyTmp.length > 2) {
          // If the name of the data contains several dots, we want to make sure we identify correctly the extension by taking the very last element
          key = "";
          let i: number;
          for (i = 0; i < keyTmp.length - 1; i++) {
            let format: any;
            if (i === keyTmp.length - 2) {
              format = ""; // We don't want an extra dot at the end
            } else {
              format = ".";
            }
            key = key + keyTmp[i] + format;
          }
        } else {
          key = keyTmp[0]; // The name of the data is the first part
        }
        extensionDocument = keyTmp[keyTmp.length - 1]; // And the extension the other one
        // this.$window.open("http://localhost:8080/amazonRequestCtrl/getOneDocument/" + modifiedPath + "/" + key + "/" + extensionDocument, "_blank"); // We REST call a method on the server, getOneDocument
        let self = this;
        this.$uibModal.open({
          templateUrl: "tpl/website/modals/pdfViewerModal.html",
          controller: "PdfViewerModalController",
          controllerAs: "pdfViewer",
          size: "lg",
          keyboard: "false",
          resolve: {
            r_dataPMF: function () {
              return (
                self.ENV.baseUrl +
                "/" +
                self.permanentFolderService.getOneDocument(
                  self.sessionService.session.company.key,
                  modifiedPath,
                  key,
                  extensionDocument
                )
              );
            },
            r_data: null,
          },
        });
      } else {
        // If it's a folder, we want to lazy load the next corresponding part of the tree of data
        // let self = this;
        treelevel += 1;
        this.treeLevelRequestFromVariable(treelevel, modifiedPath);
      }
    }

    /**
     * Deals with updating the informations of the ariane's thread when the user clicks on an element of this thread
     * @name arianeUpdateWhenClickedOn
     * @param id - The unique identifier of the ariane's thread element, also corresponding to the treelevel
     * @param path - The path associeted to the element, used for the lazy loading
     */

    public arianeUpdateWhenClickedOn(id, path) {
      // let self = this;
      let treelevel: number;
      // let pathAriane: string;
      let elementAriane: any;
      let elementAngularAriane: any;

      treelevel = id;
      elementAriane = document.getElementById("ariane");
      elementAngularAriane = angular.element(elementAriane);
      if (path === "root" || treelevel === 0) {
        // If we click on the root folder in the ariane's thread, we want to erase all elements except the root
        let rootFolder: any;

        elementAngularAriane.empty();
        rootFolder = this.$compile(
          angular.element(
            "<a  href='' ng-click='pf.arianeUpdateWhenClickedOn(0,root); $event.preventDefault()'><i style='vertical-align: middle' class='material-icons'>folder_special</i></a> "
          )
        )(this.$scope);
        elementAngularAriane.children().remove();
        elementAngularAriane.append(rootFolder);
        this.treeLevelRequestFromVariable(0, "root"); // We get back to the root of the tree by lazing loading the treelevel 0
      } else {
        // If we click on an element of the ariane's thread different from the root
        let j: number;
        // let tmp: any;
        treelevel = +treelevel; // the treelevel also corresponds to the position in the thread, we can use it to identify which elements are supposed to be deleted
        j = treelevel;

        for (j; j < elementAriane.childNodes.length; j++) {
          // The aim is to erase everything coming after the element we've clicked on
          if (2 * j + 2 < elementAriane.childNodes.length) {
            elementAriane.removeChild(elementAriane.childNodes[2 * j + 2]); // We want to make sure we delete both the name of the folder
          }
          if (2 * j + 1 < elementAriane.childNodes.length) {
            elementAriane.removeChild(elementAriane.childNodes[2 * j + 1]); // and the "/"
          }
        }

        this.treeLevelRequestFromVariable(treelevel, path); // And we lazy load
      }
    }

    /**
     * Method for making a search request on the files to the server. It's called when the user presses the enter button in the search bar or clicks on the search icon
     * @name searchFile
     */
    public searchFile() {
      let self = this;
      if (
        this.recherche !== "" &&
        this.recherche !== " " &&
        this.recherche !== null
      ) {
        // First thing is to check the validity of the search

        let search = this.recherche;
        //a call to the method getTree, processing the lazy loading of the data using the parameters treelevel and path

        self.permanentFolderService
          .search(self.sessionService.session.company.key, search)
          .then(
            function (data) {
              if (data.length === 0) {
                self.notification.error(
                  self.$translate.instant("PERMANENTFOLDER.noMatch")
                );
              } else {
                self.isInSearch = true;
                self.arianeWhenMatchSearch();
                self.updateGrid(data); // We display the content of the successful search in the grid
              }
            },
            function (error) {
              console.error("Error when searching");
              console.error(error);
            }
          );
      } else {
        self.notification.error(
          self.$translate.instant("PERMANENTFOLDER.nonEmptySearch")
        );
      }
    }

    /**
     * Resets the ariane's thread when files matche the user's request. It sets a clickable element used by the user to get back to the root of the permanent folder
     * @name arianeWhenMatchSearch
     */

    public arianeWhenMatchSearch() {
      let elementAriane: any;
      let elementAngularAriane: any;

      elementAriane = document.getElementById("ariane");
      elementAngularAriane = angular.element(elementAriane);
      elementAngularAriane.empty();
      elementAngularAriane.append(
        this.$compile(
          angular.element(
            "<a  href='' ng-click='pf.arianeUpdateWhenClickedOn(0,root); pf.isInSearch = false; $event.preventDefault(); pf.processLastChild()'>" +
              "<i style='vertical-align: middle' class='material-icons'>folder_special</i></a> "
          )
        )(this.$scope)
      );
      elementAngularAriane.append(
        this.$compile(
          angular.element(
            "<p>" +
              this.$translate.instant("PERMANENTFOLDER.nonEmptySearch") +
              " :</p>"
          )
        )(this.$scope)
      );
    }

    /**
     * Makes possible the navigation in the browser using the arrows and gives a sharable URI to a context in the permanent folder
     * @name changeUrl
     * @param treelevel
     * @param path
     * @param data
     */

    public changeUrl(treelevel, path, data) {
      let modifiedPath: string;

      modifiedPath = this.computeModifiedPath(path);

      if (!data) {
        treelevel += 1; // We want to lazy load the next treelevel
        this.$state.go(
          "websitelayout.headerandmenu.permanentfolder",
          { treelevel: treelevel, path: encodeURI(path) },
          { reload: true }
        );
      } else if (this.isInSearch) {
        this.$state.go(
          "websitelayout.headerandmenu.permanentfolder",
          { treelevel: treelevel, path: this.$sanitize(encodeURI(path)) },
          { reload: true }
        );
      }
      // this.$window.open("http://localhost:5000/#/permanentFolder?treelevel=" + treelevel + "&path=" + modifiedPath, "_self");
    }

    /**
     * Processes the path of an element in order to make it usable in the REST requests to the server
     * @name computeModifiedPath
     * @param path
     * @returns {String} - A path where the "/" are replaced by "."
     */

    public computeModifiedPath(path) {
      let modifiedPath: string;
      let i: number;
      let tmp: any;

      tmp = path.split("/"); // We get the different elements in the path
      modifiedPath = "";

      for (i = 0; i < tmp.length - 1; i++) {
        let format: any;
        if (i === tmp.length - 2) {
          format = ""; // We don't want an extra dot at the end of our final string
        } else {
          format = "/";
        }
        modifiedPath = modifiedPath + tmp[i] + format;
      }

      return modifiedPath;
    }

    public emptySearchInput() {
      if (angular.isDefined(this.recherche)) {
        if (this.recherche.length !== 0) {
          this.recherche = "";
        }
      }
    }

    /**
     * Delete file
     * @name changeUrl
     * @param treelevel
     * @param path
     */
    public deleteFile(treelevel, path, nameFile, isFolder) {
      let title;
      let body;
      if (isFolder === true) {
        title = "deleteFolderTitle";
        body = "confirmDeleteFolderTxt";
      } else {
        title = "deleteElementTitle";
        body = "confirmDeleteElementTxt";
      }
      let modalOptions = {
        closeButtonText: "MODAL.DEFAULT.cancelBtn",
        actionButtonText: "MODAL.DEFAULT.confirmBtn",
        headerText: "MODAL.PMF." + title,
        bodyText: "MODAL.PMF." + body,
      };
      let self = this;
      this.modalUtil.showConfirm(
        {},
        modalOptions,
        self.confirmDelete(treelevel, path, nameFile, isFolder),
        self.callbackCancelForDelete
      );
    }

    callbackCancelForDelete = (result) => {};

    confirmDelete = (treelevel, path, nameFile, isFolder) => {
      let self = this;
      return () => {
        let modifiedPath: string;
        modifiedPath = self.computeModifiedPath(path);

        self.permanentFolderService
          .deleteDocument(
            self.sessionService.session.company.key,
            encodeURI(modifiedPath),
            treelevel,
            encodeURI(nameFile),
            isFolder
          )
          .then(
            function (data) {
              if (data != null && data.data === true) {
                if (isFolder === true) {
                  // if delete folder, we need to refresh grid with path not counting the deleted folder
                  self.treeLevelRequestFromVariable(
                    treelevel,
                    self.getParentFolderPath(path)
                  );
                } else {
                  self.treeLevelRequestFromVariable(treelevel, path);
                }
              } else {
                self.treeLevelRequestFromVariable(treelevel, path);
                //TODO : show error box
              }
            },
            function (error) {
              console.error("Error when searching");
              console.error(error);
            }
          );
      };
    };

    /**
     * Rename the file
     * @name changeUrl
     * @param treelevel
     * @param path
     */
    public updateName(treelevel, path, nameFile, newName, isData) {
      let differentname = this.checkNameExist(undefined, newName, isData);
      if (differentname === true) {
        let modifiedPath: string;
        let self = this;
        modifiedPath = self.computeModifiedPath(path);
        if (newName !== nameFile) {
          if (isData === true) {
            this.permanentFolderService
              .editNameDocument(
                self.sessionService.session.company.key,
                modifiedPath,
                nameFile,
                treelevel,
                newName,
                false
              )
              .then(
                function (data) {
                  if (data != null && data === true) {
                    self.treeLevelRequestFromVariable(treelevel, path);
                  } else {
                    //TODO : show error box
                  }
                },
                function (error) {
                  console.error("Error when searching");
                  console.error(error);
                }
              );
          } else {
            // folder
            this.permanentFolderService
              .editNameDocument(
                self.sessionService.session.company.key,
                modifiedPath,
                nameFile,
                treelevel,
                newName,
                true
              )
              .then(
                function (data) {
                  if (data != null && data === true) {
                    self.treeLevelRequestFromVariable(
                      treelevel,
                      self.getParentFolderPath(path)
                    );
                  } else {
                    //TODO : show error box
                  }
                },
                function (error) {
                  console.error("Error when searching");
                  console.error(error);
                }
              );
          }
        } else {
          if (isData === false) {
            self.treeLevelRequestFromVariable(
              treelevel,
              self.getParentFolderPath(path)
            );
          } else {
            self.treeLevelRequestFromVariable(treelevel, path);
          }
        }
      } else {
        if (isData === false) {
          this.treeLevelRequestFromVariable(
            treelevel,
            this.getParentFolderPath(path)
          );
        } else {
          this.treeLevelRequestFromVariable(treelevel, path);
        }
        this.notification.primary(
          this.$translate.instant("PERMANENTFOLDER.nameExist") + " : " + newName
        );
      }
    }

    public getParentFolderPath(folderPath: String) {
      if (folderPath === "") {
        return "";
      }
      if (folderPath.charAt(folderPath.length - 1) === "/") {
        folderPath = folderPath.substring(0, folderPath.length - 1);
      }
      folderPath = folderPath.substring(0, folderPath.lastIndexOf("/"));
      return folderPath;
    }

    public sendMailToClients(insideData) {
      let self = this;
      let result = this.$uibModal.open({
        templateUrl: "/tpl/website/permanentfolder/WarnClientPMFModal.html",
        controller: "WarnClientPMFController",
        controllerAs: "WarnClientPMFCtrl",
        //modal-fit est une class définit dans le sass
        windowClass: "modal-fitmailpmf",
        resolve: {
          r_data: function () {
            return {
              insideData: insideData,
            };
          },
        },
      }).result;

      result.then(function (form) {
        if (
          form.content != null &&
          form.content.length > 0 &&
          form.title != null &&
          form.title.length > 0
        ) {
          let httpPath: string;
          if (form.isFolder) {
            httpPath = self.permanentFolderService.getFolderPath(
              self.sessionService.session.company.key,
              form.treeLevel,
              form.path
            );
          } else {
            httpPath = self.permanentFolderService.getFolderPath(
              self.sessionService.session.company.key,
              form.treeLevel - 1 + "",
              form.path
            );

            /*let extensionDocument: string;
                        let keyTmp = form.filename.split(".");
                        var key = ""; // filename without path and without extension
                        if (keyTmp.length > 2) { // If the name of the data contains several dots, we want to make sure we identify correctly the extension by taking the very last element
                            key = "";
                            let i: number;
                            for (i = 0; i < keyTmp.length - 1; i++) {
                                let format: any;
                                if (i === keyTmp.length - 2) {
                                    format = ""; // We don't want an extra dot at the end
                                } else {
                                    format = ".";
                                }
                                key = key + keyTmp[i] + format;
                            }
                        } else {
                            key = keyTmp[0]; // The name of the data is the first part
                        }
                        extensionDocument = keyTmp[keyTmp.length - 1]; // And the extension the other one
                        form.path = form.path.substring(0, form.path.length - 1);
                        //httpPath = self.ENV.baseUrl+"/"+self.permanentFolderService.getOneDocument(self.sessionService.session.company.key,form.path,key,extensionDocument);
                        httpPath = self.ENV.baseUrl+"/"+"Companies/"+self.sessionService.session.company.key+"/PMF?path="+encodeURIComponent(form.path)+"&key="+encodeURI(key)+"&extensionDocument="+extensionDocument;
                        */
          }

          self.permanentFolderService
            .sendMailToClients(
              self.sessionService.session.company.key,
              form.content,
              form.title,
              form.isFolder,
              httpPath
            )
            .then(
              function (data) {
                self.$state.reload();
              },
              function (error) {
                console.error("Error when searching");
                console.error(error);
              }
            );
        }
      });
    }

    public createFolder() {
      let self = this;

      let result = this.$uibModal.open({
        templateUrl: "/tpl/website/permanentfolder/addPMFfolderModal.html",
        controller: "AddPMFFolderController",
        controllerAs: "addFolderPMFCtrl",
        //modal-fit est une class définit dans le sass
        windowClass: "modal-fitpmf",
      }).result;

      result.then(function (folderName: string) {
        if (folderName != null && folderName.length > 0) {
          let differentname = self.checkNameExist(undefined, folderName, false);
          if (differentname === true) {
            let path: string = self.$stateParams.path;
            if (path === "root") {
              path = "";
            }

            self.permanentFolderService
              .createFolder(
                self.sessionService.session.company.key,
                folderName,
                self.$stateParams.treelevel,
                path
              )
              .then(
                function (data) {
                  self.$state.reload();
                },
                function (error) {
                  console.error("Error when searching");
                  console.error(error);
                }
              );
          } else {
            self.notification.primary(
              self.$translate.instant("PERMANENTFOLDER.nameExist") +
                " : " +
                folderName
            );
          }
        }
      });
    }
    public changeSortOption(option: string) {
      this.currentSorting = this.sortOptions.indexOf(option);
      this.sortElements();
    }
    public sortElements() {
      let sortedFolders = JSON.parse(JSON.stringify(this.folders));
      // 0: nameAZ; 1: nameZA; 2: dateCr; 3: dateDe;
      switch (this.currentSorting) {
        case 0:
          this.gridOptions.data = sortedFolders;
          break;
        case 1:
          this.gridOptions.data = sortedFolders.reverse();
          break;
        case 2:
          sortedFolders.sort((a, b) => {
            if (
              (a.isData === false && b.isData === false) ||
              (a.isData === true && b.isData === true)
            ) {
              return +a.date - +b.date;
            }
          });
          this.gridOptions.data = sortedFolders;
          break;
        case 3:
          this.gridOptions.data = sortedFolders
            .sort((a, b) => {
              if (
                (a.isData === false && b.isData === false) ||
                (a.isData === true && b.isData === true)
              ) {
                return +a.date - +b.date;
              }
            })
            .reverse();
          break;
        default:
          this.gridOptions.data = sortedFolders;
          break;
      }
      //this.setGridOptions(this.folders);
    }
  }
}

angular
  .module("app")
  .controller(
    "PermanentFolderController",
    app.functionality.permanentfolder.PermanentFolderController
  );

angular.module("app").directive("myEnter", function () {
  return function (scope, element, attrs) {
    element.bind("keydown keypress", function (event) {
      if (event.which === 13) {
        let myEnter = "myEnter";
        scope.$apply(function () {
          scope.$eval(attrs[myEnter]);
        });

        event.preventDefault();
      }
    });
  };
});
