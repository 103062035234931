/// <reference path="../_app.ts" />

module app.model {

    // import model.Company;
    // import mx.collections.ArrayCollection;
    // import tools.AccountTools;

    import AccountingEntry = app.model.AccountingEntry;
    import Company = app.model.Company;

    export class Operation {

        public key: string;
        public status: number;
        public accountingEntryArr: Array <AccountingEntry>;
        public parentBudgetKey: string;
        
        public accountTools: app.functionality.common.utils.AccountTools;
        public bookHistoric: app.model.constants.BookHistoricConst;

        public hasAttachedDoc(c: Company): boolean {
            return false;
        }

        public getAttachedAcc(c: Company): AccountingEntry {
            return null;
        }

        public getMainMeaningAcc(c: Company): AccountingEntry {
            return null;
        }

        // public accountingEntryArr(): any[] {
        //     return  this.accountingEntryArr;
        // }

        // public accountingEntryArr(value: any[]): void {
        //     if (value) {
        //         for each (let entry: AccountingEntry in value) {
        //             entry.operation = this;
        //         }
        //     }
        //
        //     this._accountingEntryArr = value;
        // }
        //
        // public getCorrespondingCashEntry(): any[] {
        //     let result: any[];
        //     foreach (let entry:AccountingEntry in accountingEntryArr) {
        //         if (AccountTools.isIncludeInRoot(entry.account, 5) && entry.status == 1)
        //             result.addItem(entry);
        //     }
        //     return result;
        // }
        //
        // public updateDueDate(newOp: Operation): void {
        // }

        constructor() {
        }

    }

}