/// <reference path="../../../_app.ts" />

namespace app.functionality.common.modals {
  import SessionService = app.functionality.common.session.SessionService;
  import TicketService = app.functionality.ticket.TicketService;
  import TicketAnswer = app.model.TicketAnswer;
  import LoggerService = app.functionality.common.logger.LoggerService;
  import MimeTypes = app.functionality.common.utils.MimeTypes;

  export class TicketModalController {
    // ******************* VARIABLES FOR UPLOAD ****************

    private fileInput;
    private uploadFolderKey;

    public valueNow = 0;
    public valueMax = 0;
    public percent = 0;

    public totalFiles: number = 0;
    public countFile: number = 0;

    public mapFiles: any;

    public model;
    public key;
    public map;

    public attachementList = [];
    public showAttachements = false;

    // *********************************************************

    public grid: any;
    public row: any;
    private sessionID: string;
    private companyKey: string;
    public ticket: app.model.Ticket;
    public ticketAnswers: Array<app.model.TicketAnswer>;
    public freeAnswer: string;
    public showAnwsers: boolean;

    public selectedToTreat: boolean;
    public selectedTreated: boolean;
    public selectedResolved: boolean;

    public notifyUser: boolean;

    public canClose: boolean;
    public cannotCloseAndIsClosed: boolean;
    public isClosed: boolean;

    public longDescInvoice: string;
    public r_clients;
    public r_suppliers;

    private allowedExtraExtension: Array<string> = [];
    private fileAcceptedString: string =
      "application/pdf,image/png,image/jpeg,image/pjpeg,application/xml,text/xml";

    static $inject = [
      "$scope",
      "r_data",
      "r_clients",
      "r_suppliers",
      "$uibModalInstance",
      "$uibModal",
      "$translate",
      "$location",
      "$anchorScroll",
      "SessionService",
      "TicketService",
      "$http",
      "$rootScope",
      "$window",
      "ENV",
      "UploadService",
      "ModalService",
      "TicketConst",
      "$translatePartialLoader",
      "$filter",
      "Notification",
      "$sanitize",
      "LoggerService",
      "MimeTypes",
    ];

    constructor(
      public $scope: ng.IScope,
      r_data: any,
      r_clients: any,
      r_suppliers: any,
      public $uibModalInstance: any,
      private $uibModal: any,
      private $translate: ngt.ITranslateService,
      private location: ng.ILocationService,
      private anchorScroll: ng.IAnchorScrollService,
      private sessionService: SessionService,
      private ticketService: TicketService,
      private $http: ng.IHttpService,
      private $rootScope: ng.IRootScopeService,
      public $window: angular.IWindowService,
      private ENV: app.config.constants.environment.ConstantEnv,
      private uploadService: app.functionality.upload.UploadService,
      private modalService: app.functionality.common.modals.ModalService,
      public ticketConst = new app.model.constants.TicketConst(),
      $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $filter: ng.IFilterService,
      private notification: any,
      private $sanitize: ng.sanitize.ISanitizeService,
      private logger: LoggerService,
      private mime: MimeTypes
    ) {
      $translatePartialLoader.addPart("customer/modals");
      $translatePartialLoader.addPart("customer/upload");

      this.ticket = r_data.ticket;
      this.r_suppliers = r_suppliers;
      this.r_clients = r_clients;
      if (
        this.ticket.archive_invoice !== undefined &&
        this.ticket.archive_invoice !== null
      ) {
        this.getDescArchivedInvoice(this.ticket.archive_invoice);
      }
      this.translateCName();

      this.ticketAnswers = r_data.ticketAnswers;
      this.sessionID = this.sessionService.session.sessionID;
      this.companyKey = this.sessionService.session.company.key;
      this.setupStatusButtons();
      this.initNotifyUserCheckBox();
      this.updateTicketRecordArr();

      if (this.sessionService.session.company.allowedUploadExtension) {
        this.allowedExtraExtension =
          this.sessionService.session.company.allowedUploadExtension;
        for (let i = 0; i < this.allowedExtraExtension.length; i++) {
          const extraExtension = this.allowedExtraExtension[i];
          this.fileAcceptedString +=
            "," + this.mime.getMimeType(extraExtension);
        }
      }

      $scope.$on("displayResultUpload", (event, mapUploadError: any) => {
        this.launchResultUploadModal(mapUploadError);
      });

      $scope.$on("sendAnyway", (event, payload: any) => {
        //this.test(forceUpload);
        this.totalFiles = payload.files.length;
        this.uploadService.totalFiles = payload.files.length;

        for (let file of payload.files) {
          this.valueMax = this.valueMax + file.size;
          this.sendFile(file, payload.forceUpload);
        }
      });

      if (this.sessionService.isCustomer()) {
        this.canClose = this.ticket.type === this.ticketConst.FROM_CUSTOMER;
      } else {
        this.canClose = true;
      }

      this.isClosed = this.ticket.status === 999;
      this.cannotCloseAndIsClosed = this.isClosed && !this.canClose;

      this.fileInput = "";
      this.uploadFolderKey = "";
      this.model = "";
      this.key = "";

      // link the two percent variables
      this.percent = this.uploadService.percent;

      this.showAnwsers = this.ticketAnswers.length > 0;
      this.translateContactNames();
      this.getVisibleSenderKeyAsName();
      this.goToBottom();
    }

    public isAccountant(): boolean {
      return this.sessionService.session.member.accesLevel >= 200;
    }
    public translateCName() {
      let self = this;

      if (this.isValid(self.ticket.senderKey)) {
        self.sessionService
          .getUserName(self.ticket.senderKey)
          .then(function (data) {
            // to get the real name instead of a key
            self.ticket.senderKey = data;
          });
      }
    }

    public isValid(hexString: String) {
      if (hexString == null) {
        return false;
      } else {
        let len = hexString.length;
        if (len != 24) {
          return false;
        } else {
          for (let i = 0; i < len; ++i) {
            let c = hexString.charCodeAt(i);
            if (
              (c < 48 || c > 57) &&
              (c < 97 || c > 102) &&
              (c < 65 || c > 70)
            ) {
              return false;
            }
          }

          return true;
        }
      }
    }

    public updateTicketRecordArr() {
      let self = this;
      let alreadyInTheList = false;
      this.ticket.ticketRecordArr.forEach(function (tr: any) {
        if (tr.userKey === self.sessionService.session.member.key) {
          alreadyInTheList = true;
          tr.date = Date.now();
        }
      });
      if (alreadyInTheList === false) {
        let tr = {
          userKey: self.sessionService.session.member.key,
          date: Date.now(),
        };
        self.ticket.ticketRecordArr.push(tr);
      }
    }

    public seeAccountingEntryTarget() {
      this.$window.open(
        this.ENV.baseUrl +
          "/FileMgr?sessionID=" +
          this.sessionID +
          "&companyKey=" +
          this.companyKey +
          "&journal=" +
          this.ticket.target.journalType +
          "&period=" +
          this.ticket.target.period +
          "&doc=" +
          this.ticket.target.docNumber +
          "&bookyear=" +
          this.ticket.target.bookyear,
        "_blank"
      );
    }

    public seeAInvoice() {
      this.$window.open(
        this.ENV.baseUrl +
          "/GDriveFileMgr?sessionID=" +
          this.sessionID +
          "&companyKey=" +
          this.companyKey +
          "&fileName=" +
          this.ticket.archive_invoice.name +
          "&fileKey=" +
          this.ticket.archive_invoice.fileKey +
          "&isAttachment=" +
          false,
        "_blank"
      );
    }

    public translateContactNames() {
      for (let answer of this.ticketAnswers) {
        this.sessionService
          .getUserName(answer.answererKey)
          .then(function (data) {
            // to get the real name instead of a key
            answer.answererKey = data;
          });
      }
    }

    public getVisibleSenderKeyAsName() {
      for (let answer of this.ticketAnswers) {
        if (answer.visibleSenderKey) {
          this.sessionService
            .getUserName(answer.visibleSenderKey)
            .then(function (data) {
              // to get the real name instead of a key
              answer.visibleSenderKey = data;
            });
        }
      }
    }

    public setupStatusButtons() {
      if (this.ticket.status === this.ticketConst.CLOSED_STATUS) {
        this.updateStatusHeader(this.ticketConst.CLOSED_STATUS);
      } else {
        if (this.sessionService.isCustomer()) {
          this.updateStatusHeader(this.ticket.userFlag);
        } else {
          this.updateStatusHeader(this.ticket.accFlag);
        }
      }
    }

    public updateStatusHeader(status: number) {
      if (status === this.ticketConst.PROCESSED) {
        this.selectedTreated = true;
        this.selectedResolved = false;
        this.selectedToTreat = false;
      } else if (status === this.ticketConst.TO_PROCESS) {
        this.selectedTreated = false;
        this.selectedResolved = false;
        this.selectedToTreat = true;
      } else if (status === this.ticketConst.CLOSED_STATUS) {
        this.selectedTreated = false;
        this.selectedResolved = true;
        this.selectedToTreat = false;
      } else {
        this.selectedTreated = false;
        this.selectedResolved = false;
        this.selectedToTreat = false;
      }
    }

    public switchTicketStatus(newStatus: number, rowToChange) {
      let self = this;
      this.ticketService
        .updateStatusTickets(
          this.sessionID,
          this.companyKey,
          newStatus,
          rowToChange
        )
        .then(function (data) {
          self.updateStatusHeader(newStatus);
          // self.notification(self.$translate.instant("NOTIFICATIONS.TICKET.statusChangeSuccess"));
          if (newStatus === self.ticketConst.CLOSED_STATUS) {
            self.close();
          }
        })
        .catch(function (error) {
          self.logger.error(
            "TicketModalController.ts",
            error,
            self.sessionService.session.member
          );
        });
    }

    public changeStatus(newStatus: number) {
      // put the rowKey into a tab, usefull for the restCall
      let rowToChange = [this.ticket.key];
      let actualStatus = this.ticket.status;

      if (
        newStatus === this.ticketConst.TO_PROCESS ||
        newStatus === this.ticketConst.PROCESSED
      ) {
        if (
          (actualStatus === this.ticketConst.CLOSED_STATUS &&
            ((this.ticket.type === this.ticketConst.FROM_CUSTOMER &&
              this.sessionService.isCustomer()) ||
              this.sessionService.isAccounter())) ||
          actualStatus !== this.ticketConst.CLOSED_STATUS
        ) {
          //  if ticket is closed and that we are the type of the author, we can change the status
          // else everyone can change his own status
          this.switchTicketStatus(newStatus, rowToChange);
        } else {
          // ticket is closed and we don't have the authorization to reopen it, not authorized message
          // I18N
          this.modalService.status(
            "Only the author of the ticket can re-open it.",
            "Not authorized"
          );
        }
      } else if (newStatus === this.ticketConst.CLOSED_STATUS) {
        // accoutant user can close all tickets and only customer can close a customer ticket
        if (
          (this.ticket.type === this.ticketConst.FROM_CUSTOMER &&
            this.sessionService.isCustomer()) ||
          this.sessionService.isAccounter()
        ) {
          this.switchTicketStatus(newStatus, rowToChange);
        } else {
          // we don't have the authorization to close it since we are not the author, not authorized message
          // I18N
          this.modalService.status(
            "Only the author of the ticket can close it.",
            "Not authorized"
          );
        }
      }
    }

    public initNotifyUserCheckBox() {
      this.notifyUser = false;
      let memberKey = this.sessionService.session.member.key;

      if (this.ticket.notifyUserArr != null) {
        let arrSize = this.ticket.notifyUserArr.length;

        for (let i = 0; i < arrSize; i++) {
          if (this.ticket.notifyUserArr[i] === memberKey) {
            this.notifyUser = true;
          }
        }
      } else {
        this.notifyUser = false;
      }
    }

    public addAnswer() {
      if (
        (this.freeAnswer !== undefined && this.freeAnswer.length > 0) ||
        (this.attachementList !== undefined && this.attachementList.length > 0)
      ) {
        let newTicketAnswer: TicketAnswer = new app.model.TicketAnswer();
        if (this.sessionService.isCustomer()) {
          newTicketAnswer.answererType = this.ticketConst.FROM_CUSTOMER;
        } else {
          newTicketAnswer.answererType = this.ticketConst.FROM_ACCOUNTER;
        }
        newTicketAnswer.answererKey = this.sessionService.session.member.key;
        newTicketAnswer.authorKey = this.sessionService.session.member.key;
        newTicketAnswer.freeAnswer = this.freeAnswer;
        newTicketAnswer.answerDate = new Date(); //which format?

        if (this.sessionService.isCustomer()) {
          newTicketAnswer.answerCategory = 1;
        } else {
          newTicketAnswer.answerCategory = 0;
        }
        newTicketAnswer.attachmentArr = this.attachementList;

        this.attachementList = [];
        this.showAttachements = false;

        let self = this;

        self.ticketService
          .addTicketAnswer(
            self.sessionID,
            self.companyKey,
            this.ticket.key,
            newTicketAnswer
          )
          .then(function (data) {
            //push the answer in the ticketAnswer array for display
            let answer: TicketAnswer = data.data;
            self.sessionService
              .getUserName(answer.answererKey)
              .then(function (fullName: string) {
                // to get the real name instead of a key
                answer.answererKey = fullName;
              });
            self.ticketAnswers.push(answer);
            //set back the answer in the text area to nothing
            self.freeAnswer = "";
            self.showAnwsers = true;
            self.changeStatus(self.ticketConst.PROCESSED);
            self.goToBottom();
            self.notification(
              self.$translate.instant("NOTIFICATIONS.TICKET.addResponseSuccess")
            );
            self.ticket.answered = true;
          })
          .catch(function (error) {
            self.notification.error(
              self.$translate.instant("NOTIFICATIONS.TICKET.addResponseError")
            );
            self.logger.error(
              "TicketModalController",
              error,
              self.sessionService.session.member
            );
          });
      } else {
        let self = this;
        // nothing in the message
        self.notification.error(
          self.$translate.instant("NOTIFICATIONS.TICKET.addResponseError")
        );
      }
    }

    public updateNotifyUser() {
      let self = this;

      let sessionID = this.sessionService.session.sessionID;
      let ticketKey = this.ticket.key;
      let memberKey = this.sessionService.session.member.key;
      let remove = !this.notifyUser;

      self.ticketService
        .updateNotifyUser(sessionID, ticketKey, memberKey, remove)
        .catch(function (error) {
          self.logger.error(
            "TicketModalController.ts",
            error,
            self.sessionService.session.member
          );
        });
    }

    public uploadFiles($event, files) {
      this.showAttachements = true;

      this.uploadService.totalFiles = files.length;
      this.countFile = files.length;

      let self = this;
      _.forEach(files, function (file) {
        //self.countFile++;
        self.sendFile(file, true);
      });
      $event.target.value = "";
    }

    public close() {
      let self = this;
      this.$uibModalInstance.close();
      if (this.attachementList.length !== 0) {
        for (let attachement of this.attachementList) {
          this.uploadService
            .deleteFileKey(attachement.fileKey)
            .then(function (data) {
              for (let promise of data) {
                let tmp = promise.plain();

                if (tmp.status !== 0) {
                  self.logger.error(
                    "TicketModalController.ts",
                    tmp,
                    self.sessionService.session.member
                  );
                }
              }
            })
            .catch(function (error) {
              self.logger.error(
                "TicketModalController.ts",
                error,
                self.sessionService.session.member
              );
            });
        }
      }
    }
    public isAnswerIncomplete() {
      return (
        (this.ticket.questionCategory === 1 ||
          this.ticket.questionCategory === 2) &&
        this.attachementList.length < 1 &&
        !this.ticket.answered
      );
    }
    public openConfirmModal() {
      this.$uibModal
        .open({
          templateUrl: "tpl/website/modals/confirmSendModal.html",
          controller: "ConfirmSendModalController",
          controllerAs: "confirmController",
          size: "sm",
          backdrop: "static",
          resolve: {
            r_data: function () {
              return {
                row: null,
              };
            },
          },
        })
        .result.then((confirmation) => {
          if (confirmation) {
            this.addAnswer();
          }
        });
    }

    //to automatically scroll to the bottom of the modal, to always show the latest messages from the list
    public goToBottom() {
      this.location.hash("modalBottom");
      this.anchorScroll();
    }

    public removeAttachement(attachement) {
      let self = this;
      this.attachementList = _.reject(this.attachementList, function (el) {
        return el.fileKey === attachement.fileKey;
      });
      this.uploadService
        .deleteFileKey(attachement.fileKey)
        .then(function (data) {
          for (let promise of data) {
            let tmp = promise.plain();

            if (tmp.status !== 0) {
              console.error("error ocurred");
              self.logger.error(
                "TicketModalController.ts",
                tmp,
                self.sessionService.session.member
              );
            }
          }
        })
        .catch(function (error) {
          self.logger.error(
            "TicketModalController.ts",
            error,
            self.sessionService.session.member
          );
        });
      this.totalFiles = this.attachementList.length;
      if (this.attachementList.length === 0) {
        this.showAttachements = false;
      }
    }

    public openAttachement(attachement) {
      if (!attachement.sendedToStorages) {
        this.$window.open(
          this.ENV.baseUrl +
            "/DBFileMgr?sessionID=" +
            this.sessionID +
            "&companyKey=" +
            this.companyKey +
            "&fileName=" +
            attachement.name +
            "&fileKey=" +
            attachement.fileKey,
          "_blank"
        );
      } else {
        this.$window.open(
          this.ENV.baseUrl +
            "/GDriveFileMgr?sessionID=" +
            this.sessionID +
            "&companyKey=" +
            this.companyKey +
            "&fileName=" +
            attachement.name +
            "&fileKey=" +
            attachement.fileKey +
            "&isAttachment=" +
            true,
          "_blank"
        );
      }
    }

    public getThirdLabel(target): string {
      if (target.third && target.third.length > 0) {
        if (target.typeOfThird === 1) {
          return (
            this.$translate.instant("MESSAGE.DESCRIPTION.of") +
            " " +
            this.$translate.instant("MESSAGE.client")
          );
        } else if (target.typeOfThird === 2) {
          return (
            this.$translate.instant("MESSAGE.DESCRIPTION.of") +
            " " +
            this.$translate.instant("MESSAGE.supplier")
          );
        } else {
          return this.$translate.instant("MESSAGE.DESCRIPTION.van");
        }
      } else {
        return "";
      }
    }

    public getDescArchivedInvoice(invoice) {
      let self = this;
      let desc = "";
      desc +=
        this.$translate.instant("MODAL.MESSAGE.TicketInvoiceArchived_1") +
        " " +
        invoice.name +
        " ";
      desc +=
        this.$translate.instant("MODAL.MESSAGE.TicketInvoiceArchived_1B") +
        " " +
        new Date(invoice.uploadDate).getDate() +
        " " +
        this.$filter("shortMonthLabel")(invoice.uploadDate) +
        " " +
        new Date(invoice.uploadDate).getFullYear() +
        " ";
      self.sessionService
        .getUserName(invoice.uploaderKey)
        .then(function (data) {
          if (invoice.uploadFolderKey) {
            let temp = self.sessionService.getUploadFolderName(
              invoice.uploadFolderKey
            );
            if (temp !== undefined) {
              desc +=
                self.$translate.instant(
                  "MODAL.MESSAGE.TicketInvoiceArchived_2"
                ) +
                " " +
                temp +
                " ";
            }
          }

          if (data !== undefined && data !== "") {
            desc += self.$translate.instant("MODAL.MESSAGE.par") + " " + data;
          }
          self.longDescInvoice = desc;
        });
    }

    public getLongDescCustomer(questionCategory: number, target: any): string {
      let desc = "";
      let nameThird = "";
      if (target.third && target.third.length > 0) {
        if (target.typeOfThird === 0 || target.typeOfThird === 1) {
          let i = 0;
          for (i = 0; i < this.r_clients.length; i++) {
            if (this.r_clients[i].reference === target.third) {
              nameThird = this.r_clients[i].fullName;
              break;
            }
          }
        }
        if (nameThird == "") {
          if (target.typeOfThird === 0 || target.typeOfThird === 2) {
            let j = 0;
            for (j = 0; j < this.r_suppliers.length; j++) {
              if (this.r_suppliers[j].reference === target.third) {
                nameThird = this.r_suppliers[j].fullName;
                break;
              }
            }
          }
        }
        if (nameThird == "") {
          nameThird = target.third;
        }
      }

      if (questionCategory === 1) {
        desc +=
          this.$translate.instant("MESSAGE.DESCRIPTION.salesProofStart") +
          " " +
          Math.abs(target.amount) +
          "€";
        desc +=
          " " +
          this.$translate.instant("MESSAGE.DESCRIPTION.of") +
          " " +
          new Date(target.operationDate).getDate() +
          " " +
          this.$filter("shortMonthLabel")(target.operationDate) +
          " " +
          new Date(target.operationDate).getFullYear() +
          " ";

        if (target.third && target.third.length > 0) {
          desc += " " + this.getThirdLabel(target) + " " + nameThird;
        }

        if (target.comment && target.comment.length > 0) {
          desc +=
            " " +
            this.$translate.instant("MESSAGE.DESCRIPTION.withDescriptionOf") +
            " : " +
            target.comment;
        }

        desc += " ?";
      } else if (questionCategory === 2) {
        desc +=
          this.$translate.instant("MESSAGE.DESCRIPTION.missingInvoiceStart") +
          " " +
          Math.abs(target.amount) +
          "€";

        if (target.third && target.third.length > 0) {
          desc += " " + this.getThirdLabel(target) + " " + nameThird;
        }

        desc +=
          " " +
          this.$translate.instant("MESSAGE.DESCRIPTION.doneOn") +
          " " +
          new Date(target.operationDate).getDate() +
          " " +
          this.$filter("shortMonthLabel")(target.operationDate) +
          " " +
          new Date(target.operationDate).getFullYear() +
          " ";

        if (target.comment && target.comment.length > 0) {
          desc +=
            " " +
            this.$translate.instant("MESSAGE.DESCRIPTION.withCommunicationOf") +
            " : " +
            target.comment;
        }

        desc += " ?";
      } else if (questionCategory === 3) {
        desc +=
          " " +
          this.$translate.instant("MESSAGE.DESCRIPTION.wrongVatStart") +
          " " +
          this.getThirdLabel(target) +
          " " +
          nameThird +
          " " +
          this.$translate.instant("MESSAGE.DESCRIPTION.doneOnFem") +
          " " +
          new Date(target.operationDate).getDate() +
          " " +
          this.$filter("shortMonthLabel")(target.operationDate) +
          " " +
          new Date(target.operationDate).getFullYear() +
          " ";

        if (target.comment && target.comment.length > 0) {
          desc +=
            " " +
            this.$translate.instant("MESSAGE.DESCRIPTION.withCommunicationOf") +
            " : " +
            target.comment;
        }

        desc +=
          ". " + this.$translate.instant("MESSAGE.DESCRIPTION.wrongVatEnd");
      } else {
        desc +=
          " " +
          this.$translate.instant("MESSAGE.DESCRIPTION.operationStart") +
          " " +
          Math.abs(target.amount) +
          "€";
        if (target.third && target.third.length > 0) {
          desc += " " + this.getThirdLabel(target) + " " + nameThird;
        }

        desc +=
          " " +
          this.$translate.instant("MESSAGE.DESCRIPTION.doneOn") +
          " " +
          new Date(target.operationDate).getDate() +
          " " +
          this.$filter("shortMonthLabel")(target.operationDate) +
          " " +
          new Date(target.operationDate).getFullYear() +
          " ";

        if (target.comment && target.comment.length > 0) {
          desc +=
            " " +
            this.$translate.instant("MESSAGE.DESCRIPTION.withDescriptionOf") +
            " : " +
            target.comment;
        }

        desc += ".";
      }
      return desc;
    }

    /**
     * Create the form for the server and send to the server
     * Each events are handle in this method
     * @param file
     */
    private sendFile(file: File, forceUpload?: boolean) {
      let self = this;
      let form: any = new FormData();
      form.append(
        "filenameURI",
        this.$sanitize(
          encodeURI(this.ENV.removeSpecialCharFromFilename(file.name))
        )
      );
      form.append("convertToSWF", "false");
      form.append("typeUpload", "1");
      form.append("sessionID", this.sessionService.session.sessionID);
      form.append("companyKey", this.sessionService.session.company.key);
      form.append("file", file);

      if (forceUpload) {
        form.append("forceUpload", true); //if we want to send a duplicate file again -> true
      } else {
        form.append("forceUpload", false);
      }

      this.uploadService
        .uploadFile(
          form,
          file,
          this.sessionService.session.company.allowedUploadExtension
        )
        .then(function (data) {
          self.completeHandler(data);
          self.countFile--;
        })
        .catch(function (error) {
          let errorCode = self.uploadService.printError(file);
          self.uploadService.errorHandler(file, error);
          self.countFile--;
          /*self.totalFiles = self.attachementList.length; */
          /* if (errorCode != null) {
            self.notification.error({
              message: self.$translate.instant(errorCode) + " : " + file.name,
              delay: false,
            });
          } else {
            self.notification.error({
              message:
                self.$translate.instant("UPLOAD.TOSEND.ERROR_UPLOAD.server") +
                " : " +
                file.name,
              delay: false,
            });
          } */
        });
    }

    /**
     * When an invoice is correctly sent to the server,
     * the server send back an XML file with the invoice data.
     * The new invoice is created but a confirmation has to be received by the sever.
     * The confirmation contains the key of the new invoice.
     * The new invoice is sent to ToSendController to update the ui-grid data via $rootscope
     * @returns {(response:any)=>undefined}
     */
    public completeHandler(response) {
      let self = this;

      let oParser = new DOMParser();
      let xmlDocument: Document = oParser.parseFromString(
        response.data,
        "text/xml"
      );
      let mongoFileKey =
        xmlDocument.getElementsByTagName("FileKey")[0].childNodes[0].nodeValue;
      let fileName =
        xmlDocument.getElementsByTagName("FileName")[0].childNodes[0].nodeValue;

      self.attachementList.push({
        name: fileName,
        fileKey: mongoFileKey,
        senderKey: self.sessionService.session.member.key,
      });

      self.totalFiles = self.attachementList.length;

      // self.map.set(mongoFileKey, fileName);
      if (self.model[self.key] === null || self.model[self.key] === undefined) {
        self.model[self.key] = [mongoFileKey];
      } else {
        self.model[self.key].push(mongoFileKey);
      }
      // self.$rootScope.$broadcast("addIPPFormFile", mongoFileKey);
      self.uploadService.displayResultUpload();
    }

    /**
     * Download the current message in a PDF file
     */
    public pdfExport() {
      this.sessionID = this.sessionService.session.sessionID;
      this.$window.open(
        this.ENV.baseUrl +
          "/TicketGenerator?sessionID=" +
          this.sessionID +
          "&ticketKey=" +
          this.ticket.key,
        "_blank"
      );
    }
    public launchResultUploadModal(mapUploadError: any) {
      this.modalService.createResultUploadModal(
        "tpl/website/modals/resultUploadModal.html",
        "ResultUploadModalController",
        "resultUploadModalController",
        mapUploadError,
        true
      );
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "TicketModalController",
    app.functionality.common.modals.TicketModalController
  );
