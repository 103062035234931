/// <reference path="../../../_app.ts" />

module app.modules.lineargraphs.directive {
  import LineChartServiceUtil = app.modules.lineargraphs.util.LineChartServiceUtil;
  import LineChartBaseBuilder = app.modules.lineargraphs.builder.LineChartBaseBuilder;

  export interface ILineChartDirectiveScope extends ng.IScope {
    idElementContainer: string;
    title: string;
    translateKey: string;
    data: any;
    size: string;
    chartType: string;
  }

  export class LineChartBaseDirective implements ng.IDirective {
    restrict = "E";
    replace = false;
    templateUrl = "js/modules/lineargraphs/directive/lineChartDirective.html";
    scope: { [boundProperty: string]: string } = {
      data: "=chartData",
      idElementContainer: "@idElementContainer",
      title: "@chartTitle",
      translateKey: "@chartTranslate",
      size: "@size",
      chartType: "@chartType",
    };
    private propertyX = "date";
    propertyY = "amount";
    margin = {
      x: 60,
      y: 40,
    };

    constructor(
      private $window,
      private chartBuilder: LineChartBaseBuilder,
      private lineChartServiceUtil: LineChartServiceUtil,
      private $translate: ngt.ITranslateService,
      public $rootScope: any
    ) {}

    link = (
      scope: ILineChartDirectiveScope,
      elem: ng.IAugmentedJQuery,
      attrs: ng.IAttributes
    ) => {
      let data = this.lineChartServiceUtil.createGapInDataAtStart(scope.data);
      let canvasWidthAndHeight =
        this.lineChartServiceUtil.getWidthAndHeightFromElementById(
          scope.idElementContainer
        );

      let tempMaxValue = this.lineChartServiceUtil.maxAmount(data);
      let tempMinValue = this.lineChartServiceUtil.minAmount(data);
      let textYaxis;
      if (tempMaxValue > 999999) {
        tempMaxValue = Math.round(tempMaxValue / 1000000);
        textYaxis = tempMaxValue + "M €";
      } else if (tempMaxValue > 999) {
        tempMaxValue = Math.round(tempMaxValue / 1000);
        textYaxis = tempMaxValue + "k €";
      } else {
        tempMaxValue = Math.round(tempMaxValue);
        textYaxis = tempMaxValue + " €";
      }

      let textYaxisMin = "";
      if (tempMinValue >= 0) {
        textYaxisMin = "0";
      } else if (tempMinValue > -1000) {
        tempMinValue = Math.round(tempMinValue);
        textYaxisMin = tempMinValue + " €";
      } else if (tempMinValue > -1000000) {
        tempMinValue = Math.round(tempMinValue / 1000);
        textYaxisMin = tempMinValue + "k €";
      } else {
        tempMinValue = Math.round(tempMinValue / 1000000);
        textYaxisMin = tempMinValue + "M €";
      }

      let self = this;

      let chart;
      // let chart =
      //     this.chartBuilder.buildChart(   scope.idElementContainer, elem, scope.data,
      //         this.propertyX, this.propertyY, scope.title, textYaxis, this.margin);

      this.$translate(scope.translateKey).then(function (translation) {
        chart = self.chartBuilder.buildChart(
          scope.idElementContainer,
          elem,
          scope.data,
          self.propertyX,
          self.propertyY,
          textYaxisMin,
          textYaxis,
          self.margin
        );
        chart.draw(canvasWidthAndHeight, true, scope.size, scope.chartType);
      });

      let w = angular.element(this.$window);
      let tempValue;

      scope.$watch("data", function (newValue, oldValue) {
        let svgResult = chart.getSVG();
        if (svgResult != null) {
          self.lineChartServiceUtil.removeParentSvg(svgResult, elem);
          data = self.lineChartServiceUtil.createGapInDataAtStart(scope.data);
          canvasWidthAndHeight =
            self.lineChartServiceUtil.getWidthAndHeightFromElementById(
              scope.idElementContainer
            );
          tempValue = self.lineChartServiceUtil.maxAmount(data);
          tempMinValue = self.lineChartServiceUtil.minAmount(data);
          if (tempValue > 999999) {
            tempValue = Math.round(tempValue / 1000000);
            textYaxis = tempValue + "M €";
          } else if (tempValue > 999) {
            tempValue = Math.round(tempValue / 1000);
            textYaxis = tempValue + "k €";
          } else {
            tempValue = Math.round(tempValue);
            textYaxis = tempValue + " €";
          }

          let textYaxisMin;
          if (tempMinValue >= 0) {
            textYaxisMin = "0";
          } else if (tempMinValue > -1000) {
            tempMinValue = Math.round(tempMinValue);
            textYaxisMin = tempMinValue + " €";
          } else if (tempMinValue > -1000000) {
            tempMinValue = Math.round(tempMinValue / 1000);
            textYaxisMin = tempMinValue + "k €";
          } else {
            tempMinValue = Math.round(tempMinValue / 1000000);
            textYaxisMin = tempMinValue + "M €";
          }

          chart = self.chartBuilder.buildChart(
            scope.idElementContainer,
            elem,
            scope.data,
            self.propertyX,
            self.propertyY,
            textYaxisMin,
            textYaxis,
            self.margin
          );
          chart.draw(canvasWidthAndHeight, true, scope.size, scope.chartType);
        }
      });

      w.bind("resize", () => {
        canvasWidthAndHeight =
          this.lineChartServiceUtil.getWidthAndHeightFromElementById(
            scope.idElementContainer
          );
        if (canvasWidthAndHeight != null) {
          chart.draw(canvasWidthAndHeight, false, scope.size, scope.chartType);
        }
      });
    };
  }
}
