/// <reference path="../../../../_app.ts" />

module app.functionality.invoiceReminder.controllers.modal {

    import ITemplateReminder = app.model.interface.ITemplateReminder;

    /**
     * This modal is used when the user wants to send a reminder
     * It asks the user for a confirmation and for the template to use.
     */

    export class SendingConfirmationModalController  implements angular.IController {

        public thirdNames: string[];
        public templates: ITemplateReminder[];
        public warningMessage: string;
        public selectedTemplate: number;

        $onInit() {
        }

        static $inject = [
            "thirdNames",
            "templates",
            "warningMessage",
            "$uibModalInstance",
            "$translatePartialLoader"
        ];

        constructor(thirdNames: string[],
                    templates: ITemplateReminder[],
                    warningMessage: string,
                    public $uibModalInstance,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {

            $translatePartialLoader.addPart("customer/modals");
            this.thirdNames = thirdNames;
            this.templates = templates.sort((a, b) => {
                if (a.templateNumber > b.templateNumber) {
                  return 1;
                } else if (a.templateNumber < b.templateNumber) {
                  return -1;
                } else if (a.templateNumber == b.templateNumber) {
                  return 0;
                }
              });;
            this.warningMessage = warningMessage;
        }

        public cancel() {
            this.$uibModalInstance.close(undefined);
            //this.$uibModalInstance.dismiss();
        }

        public confirm() {
            this.$uibModalInstance.close(this.getTemplateByNumber(this.selectedTemplate));
        }

        /**
         * This function is used to get a template by its number
         * The value attributes of the checkboxes in the html is the template number
         * When the user confirm the modal, we want to return the template to the SendReminderService.
         * Then, we need to get the template corresponding to the value of the selectedCheckboxes
         * @param {number} templateNumber the number of the template
         * @returns {app.model.interface.ITemplateReminder} the template corresponding to the number
         */
        public getTemplateByNumber(templateNumber: number): ITemplateReminder {
            for (let i = 0; i < this.templates.length; i++) {
                if (this.templates[i].templateNumber == templateNumber) {
                    return this.templates[i];
                }
            }
        }

    }

    //noinspection TypeScriptValidateTypes
    angular.module("app").controller("SendingConfirmationModalController", app.functionality.invoiceReminder.controllers.modal.SendingConfirmationModalController);
}