namespace app.config.routes {
  import SocketService = app.config.socket.SocketService;
  import ProfitService = app.functionality.profit.ProfitService;
  import TreasuryService = app.functionality.treasury.TreasuryService;
  import LiquidityService = app.functionality.liquidity.LiquidityService;
  import UserService = app.functionality.user.UserService;
  import SessionService = app.functionality.common.session.SessionService;
  import LiquidityConverter = app.functionality.liquidity.LiquidityConverter;
  import TreeLevelConverter = app.functionality.common.converter.TreeLevelConverter;
  import TicketService = app.functionality.ticket.TicketService;
  import PermanentFolderService = app.functionality.permanentfolder.PermanentFolderService;
  import UploadService = app.functionality.upload.UploadService;
  import SearchService = app.functionality.search.SearchService;
  import IPPFormService = app.functionality.IPPForm.IPPFormService;
  import ProfitConverter = app.functionality.common.converter.ProfitConverter;
  import TicketNotification = app.config.values.TicketNotification;
  import InvoiceReminderConverter = app.functionality.invoiceReminder.services.InvoiceReminderConverter;
  import InvoiceReminderService = app.functionality.invoiceReminder.services.InvoiceReminderService;
  import BudgetInsightService = app.functionality.budgetInsight.BudgetInsightService;
  import InvoicingService = app.functionality.invoicing.services.InvoicingService;
  import ClientService = app.functionality.invoicing.services.ClientService;

  export class RouterConfigurer {
    constructor(
      private $stateProvider: ng.ui.IStateProvider,
      private $urlRouterProvider: ng.ui.IUrlRouterProvider,
      private ROLES: app.config.constants.roles.ConstantRoles,
      private FEATURES: app.config.constants.roles.ConstantFeatures
    ) {
      this.init();
    }

    init = () => {
      this.$urlRouterProvider.otherwise(function ($injector) {
        let $state = $injector.get("$state");

        $state.go("login");
      });

      // LOGIN

      this.$stateProvider.state("login", {
        url: "/?Mode&result",
        templateUrl: "tpl/website/login/login.html",
        controller: "LoginController",
        controllerAs: "login",
        resolve: {
          r_sessionID: [
            function () {
              return "";
            },
          ],
          r_me: [
            "SessionService",
            "$state",
            function (session: SessionService, $state) {
              return session
                .me()
                .then(function (data) {
                  let response = data.plain();
                  let force = true;
                  // to force the managing
                  return session.manageBetaTester(response, force);
                })
                .catch(function (error) {
                  // console.error("NewTicketModalController.ts", error,"");
                });
            },
          ],
        },
        data: {
          roles: [],
        },
      });

      // WEBSITE
      this.$stateProvider.state("websitelayout", {
        abstract: true,
        templateUrl: "tpl/layouts/website_layout.html",
        controller: "MenuController",
        controllerAs: "menu",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
        },
        resolve: {
          authorize: [
            "SessionService",
            function (session: SessionService) {
              return session.authorize();
            },
          ],
        },
      });

      // WEBSITE | LAYOUT
      this.$stateProvider.state("websitelayout.headerandmenu", {
        abstract: true,
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
        },
        resolve: {
          r_clients: [
            "SearchService",
            "SessionService",
            function (
              searchService: SearchService,
              sessionService: SessionService
            ) {
              return searchService
                .getAllThirds(sessionService.session.sessionID, 1, false)
                .then(function (data) {
                  let r_tab = data.plain();
                  return r_tab.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_suppliers: [
            "SearchService",
            "SessionService",
            function (
              searchService: SearchService,
              sessionService: SessionService
            ) {
              return searchService
                .getAllThirds(sessionService.session.sessionID, 2, false)
                .then(function (data) {
                  let r_tab = data.plain();
                  return r_tab.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_branding: [
            "SearchService",
            "SessionService",
            function (
              searchService: SearchService,
              sessionService: SessionService
            ) {
              /*return searchService.getIconBranding(sessionService.session.sessionID, sessionService.session.company.accoutingFirmDesc.relatedKey)
               .then(function(data) {
               let r_tab = data.plain();
               return r_tab.data;
               })
               .catch(function(error) {
               console.error("router.ts", error, sessionService.session.member);
               });*/
              return null;
            },
          ],
        },
        views: {
          header: {
            templateUrl: "tpl/website/header/header.html",
            controller: "HeaderController",
            controllerAs: "header",
          },

          menu: {
            templateUrl: "tpl/website/menu/menu.html",
            controller: "MenuController",
            controllerAs: "menu",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.dashboard", {
        url: "/dashboard",

        resolve: {
          r_turnover: [
            "ProfitService",
            "SessionService",
            function (
              profitService: ProfitService,
              sessionService: SessionService
            ): any {
              if (
                sessionService.session != null &&
                sessionService.session.company != null &&
                sessionService.session.company.dashboardReport != null &&
                sessionService.session.company.dashboardReport.periodArr != null
              ) {
                // do not make the call, the info are in the dashboardReport
                return new Object();
              } else {
                return profitService
                  .getTurnoverByBookyear(
                    sessionService.session.sessionID,
                    sessionService.session.company.currBookyear,
                    "",
                    ""
                  )
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              }
            },
          ],
          r_profit: [
            "ProfitService",
            "SessionService",
            function (
              profitService: ProfitService,
              sessionService: SessionService
            ): any {
              if (
                sessionService.session != null &&
                sessionService.session.company != null &&
                sessionService.session.company.dashboardReport != null &&
                sessionService.session.company.dashboardReport.start != null &&
                sessionService.session.company.dashboardReport.turnover !=
                  null &&
                sessionService.session.company.dashboardReport.cost != null
              ) {
                // do not make the call, the info are in the dashboardReport
                return new Object();
              } else {
                return profitService
                  .getDashboardProfit(sessionService.session.sessionID)
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              }
            },
          ],
          r_treasury: [
            "TreasuryService",
            "SessionService",
            function (
              treasuryService: TreasuryService,
              sessionService: SessionService
            ): any {
              if (
                sessionService.session != null &&
                sessionService.session.company != null &&
                sessionService.session.company.dashboardReport != null &&
                sessionService.session.company.dashboardReport.solde != null &&
                sessionService.session.company.dashboardReport.bfr != null &&
                sessionService.session.company.dashboardReport.cashburn !=
                  null &&
                sessionService.session.company.dashboardReport.fr != null
              ) {
                // do not make the call, the info are in the dashboardReport
                return new Object();
              } else {
                return treasuryService
                  .getDashboardTreasury(sessionService.session.sessionID)
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              }
            },
          ],
          r_liquidity: [
            "LiquidityService",
            "SessionService",
            function (
              liquidityService: LiquidityService,
              sessionService: SessionService
            ): any {
              if (
                sessionService.session != null &&
                sessionService.session.company != null &&
                sessionService.session.company.dashboardReport != null &&
                sessionService.session.company.dashboardReport
                  .liquiditySuppliers != null &&
                sessionService.session.company.dashboardReport
                  .liquidityClients != null &&
                sessionService.session.company.dashboardReport.vat != null
              ) {
                // do not make the call, the info are in the dashboardReport
                return new Object();
              } else {
                return liquidityService
                  .getDashboardLiquidity(sessionService.session.sessionID)
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              }
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/dashboard/dashboard.html",
            controller: "DashboardController",
            controllerAs: "dc",
          },
        },
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.DASHBOARD01],
        },
      });

      this.$stateProvider.state("tempSession", {
        url: "/RequestCustomerView/:contactId",
        templateUrl: "tpl/website/login/login.html",
        controller: "LoginController",
        controllerAs: "tempSession",
        resolve: {
          r_sessionID: [
            "$stateParams",
            function ($stateParams: ng.ui.IStateParamsService) {
              let param = <any>$stateParams;
              return param.contactId;
            },
          ],
        },
        data: {
          roles: [],
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.permanentfolder", {
        url: "/permanentFolder?treelevel&path",
        params: {
          treelevel: "0",
          path: "root",
        },
        data: {
          roles: [this.ROLES.CUSTOMER, this.ROLES.ACCOUNTER_AS_USER],
          features: null,
        },
        resolve: {
          r_folders: [
            "$stateParams",
            "PermanentFolderService",
            "SessionService",
            function (
              $stateParams: ng.ui.IStateParamsService,
              permanentFolderService: PermanentFolderService,
              sessionService: SessionService
            ) {
              return permanentFolderService
                .getTree(
                  sessionService.session.company.key,
                  $stateParams.treelevel,
                  $stateParams.path
                )
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  //TODO refactor using loggersevice
                  console.error("Error while loading folders");
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/permanentfolder/permanentfolder.html",
            controller: "PermanentFolderController",
            controllerAs: "pf",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.search", {
        url: "/search",
        params: {
          third: null,
        },
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: null,
        },
        resolve: {
          // r_entries: ["SearchService", "SessionService", "$stateParams", function(searchService: SearchService,
          //                                                        sessionService: SessionService,
          //                                                        $stateParams : ng.ui.IStateParamsService) {
          //     let bookyearList = sessionService.session.company.exerciceArr;
          //     let lastBookyearNumber = bookyearList[this.bookyearList.length - 1].bookyear;
          //     return searchService.getAccountingEntriesByThird()
          //         .then(function(data) {
          //             return data.plain();
          //         })
          //         .catch(function(error) {
          //             console.error("Error while loading folders");
          //         });
          // }]
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/search/search.html",
            controller: "SearchController",
            controllerAs: "search",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.budgetInsight", {
        url: "/budgetInsight",
        params: {
          third: null,
        },
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: null,
        },
        resolve: {
          r_accounts: [
            "BudgetInsightService",
            "SessionService",
            "$stateParams",
            function (
              budgetInsightService: BudgetInsightService,
              sessionService: SessionService,
              $stateParams: ng.ui.IStateParamsService
            ) {
              let companyKey = sessionService.session.company.key;
              let sessionID = sessionService.session.sessionID;
              return budgetInsightService
                .getAccounts(companyKey, sessionID)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error("Error while loading folders");
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/budgetInsight/budgetInsight.html",
            controller: "BudgetInsightController",
            controllerAs: "budgetInsight",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.mainProfit", {
        url: "/profit/overview",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.PROFIT01],
        },
        resolve: {
          r_top5S: [
            "ProfitService",
            "SessionService",
            function (
              profitService: ProfitService,
              sessionService: SessionService
            ) {
              return profitService
                .getTop5Suppliers(sessionService.session.sessionID, 5)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],

          r_top5C: [
            "ProfitService",
            "SessionService",
            function (
              profitService: ProfitService,
              sessionService: SessionService
            ) {
              return profitService
                .getTop5Customers(sessionService.session.sessionID, 5)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],

          r_turnover: [
            "ProfitService",
            "SessionService",
            function (
              profitService: ProfitService,
              sessionService: SessionService
            ) {
              return profitService
                .getTurnoverByBookyear(
                  sessionService.session.sessionID,
                  sessionService.session.company.currBookyear,
                  "",
                  ""
                )
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_pnl: [
            "ProfitService",
            "SessionService",
            function (
              profitService: ProfitService,
              sessionService: SessionService
            ) {
              return profitService
                .getShortPnl(sessionService.session.sessionID)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/profit/profit.html",
            controller: "ProfitController",
            controllerAs: "profit",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.mainTreasury", {
        url: "/treasury/overview",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.TREASURY01],
        },
        resolve: {
          r_bankBalance: [
            "TreasuryService",
            "SessionService",
            function (
              treasuryService: TreasuryService,
              sessionService: SessionService
            ) {
              let thisYear: any;
              if (
                sessionService.session.company.importInfo !== null &&
                sessionService.session.company.importInfo.importDate !== null
              ) {
                let tempDate: Date = new Date(
                  sessionService.session.company.importInfo.importDate
                );
                thisYear = tempDate.getFullYear();
              } else {
                thisYear = new Date().getFullYear();
              }

              return treasuryService
                .getBankGraph(sessionService.session.sessionID, "", thisYear)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],

          r_wcr: [
            "TreasuryService",
            "SessionService",
            function (
              treasuryService: TreasuryService,
              sessionService: SessionService
            ) {
              return treasuryService
                .getDetailWCR(sessionService.session.sessionID)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],

          r_wc: [
            "TreasuryService",
            "SessionService",
            function (
              treasuryService: TreasuryService,
              sessionService: SessionService
            ) {
              return treasuryService
                .getDetailWC(sessionService.session.sessionID)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],

          r_cashburn: [
            "TreasuryService",
            "SessionService",
            function (
              treasuryService: TreasuryService,
              sessionService: SessionService
            ) {
              return treasuryService
                .getDetailCashburn(sessionService.session.sessionID)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/treasury/treasury.html",
            controller: "TreasuryController",
            controllerAs: "treasury",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.wcr", {
        url: "/treasury/wcr",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.TREASURY02],
        },
        resolve: {
          r_wcr: [
            "TreasuryService",
            "SessionService",
            function (
              treasuryService: TreasuryService,
              sessionService: SessionService
            ) {
              return treasuryService
                .getDetailWCR(sessionService.session.sessionID)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/treasury/wcr.html",
            controller: "WcrController",
            controllerAs: "wcr",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.wc", {
        url: "/treasury/wc",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.TREASURY03],
        },
        resolve: {
          r_wc: [
            "TreasuryService",
            "SessionService",
            function (
              treasuryService: TreasuryService,
              sessionService: SessionService
            ) {
              return treasuryService
                .getDetailWC(sessionService.session.sessionID)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/treasury/wc.html",
            controller: "WcController",
            controllerAs: "wc",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.bankBalance", {
        url: "/treasury/bankBalance",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.TREASURY04],
        },
        resolve: {
          r_bankBalance: [
            "TreasuryService",
            "SessionService",
            function (
              treasuryService: TreasuryService,
              sessionService: SessionService
            ) {
              let thisYear: any;
              if (
                sessionService.session.company.importInfo !== null &&
                sessionService.session.company.importInfo.importDate !== null
              ) {
                let tempDate: Date = new Date(
                  sessionService.session.company.importInfo.importDate
                );
                thisYear = tempDate.getFullYear();
              } else {
                thisYear = new Date().getFullYear();
              }

              return treasuryService
                .getBankGraph(sessionService.session.sessionID, "", thisYear)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_bankAccounts: [
            "TreasuryService",
            "SessionService",
            function (
              treasuryService: TreasuryService,
              sessionService: SessionService
            ) {
              return treasuryService
                .getBankAccounts(sessionService.session.sessionID)
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/treasury/bankBalance.html",
            controller: "BankBalanceController",
            controllerAs: "balance",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.ticket", {
        url: "/ticket/:toTreat/:treated/:resolved/:page/:memberKey",
        params: {
          toTreat: "true",
          treated: "false",
          resolved: "false",
          page: "1",
          memberKey: "",
        },
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.OTHERM],
        },
        resolve: {
          r_thirds: [
            "SessionService",
            "SearchService",
            function (
              sessionService: SessionService,
              searchService: SearchService
            ) {
              return searchService
                .getAllThirds(sessionService.session.sessionID, 2, false)
                .then(function (dataThird) {
                  let r_tab = dataThird.plain();
                  return r_tab.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_clients: [
            "SessionService",
            "SearchService",
            function (
              sessionService: SessionService,
              searchService: SearchService
            ) {
              return searchService
                .getAllThirds(sessionService.session.sessionID, 1, false)
                .then(function (dataThird) {
                  let r_tab = dataThird.plain();
                  return r_tab.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_tickets: [
            "TicketService",
            "SessionService",
            "TicketNotification",
            "$stateParams",
            function (
              ticketService: TicketService,
              sessionService: SessionService,
              ticketNotification: TicketNotification,
              $stateParams: ng.ui.IStateParamsService
            ) {
              let sessionID = sessionService.session.sessionID;
              let companyKey = sessionService.session.company.key;
              let parameters = <any>$stateParams;
              let fromStatus = 1;
              let toStatus = 1;
              let fromProcessStatus = 0;
              let toProcessStatus = 0;
              let memberKey = parameters.memberKey;
              if (parameters.resolved === "false") {
                fromStatus = 1;
                toStatus = 1;
              } else if (
                parameters.resolved === "true" &&
                parameters.toTreat === "false" &&
                parameters.treated === "false"
              ) {
                fromStatus = 999;
                toStatus = 999;
              } else {
                fromStatus = 1;
                toStatus = 999;
              }

              if (parameters.toTreat === "false") {
                fromProcessStatus = 100;
                toProcessStatus = 100;
              } else {
                fromProcessStatus = 0;
                toProcessStatus = 0;
              }

              if (
                parameters.toTreat === "true" &&
                parameters.treated === "true"
              ) {
                fromProcessStatus = 0;
                toProcessStatus = 100;
              }

              if (
                parameters.toTreat === "false" &&
                parameters.treated === "false" &&
                parameters.resolved === "false"
              ) {
                fromProcessStatus = -1;
                toProcessStatus = -1;
                fromStatus = -1;
                toStatus = -1;
              }

              let nbTicketsToView = 16;
              let nbTicketsToSkip = (parameters.page - 1) * nbTicketsToView;
              let ascending = false;

              if (memberKey != null && memberKey !== "") {
                return ticketService
                  .filterTicketsByUser(
                    sessionService.session.sessionID,
                    sessionService.session.company.key,
                    fromStatus,
                    toStatus,
                    fromProcessStatus,
                    toProcessStatus,
                    nbTicketsToSkip,
                    nbTicketsToView,
                    false,
                    0,
                    memberKey
                  )
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              } else {
                ticketService
                  .getNbTicketClientHasToProcess(
                    sessionID,
                    companyKey,
                    1,
                    -1,
                    1
                  )
                  .then(function (data) {
                    let tempData = data.data;
                    if (tempData[0] > 0) {
                      ticketNotification.icon = true;
                    } else {
                      ticketNotification.icon = false;
                    }
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
                return ticketService
                  .getTickets(
                    sessionID,
                    companyKey,
                    fromStatus,
                    toStatus,
                    fromProcessStatus,
                    toProcessStatus,
                    nbTicketsToSkip,
                    nbTicketsToView,
                    ascending
                  )
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              }
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/ticket/ticketList.html",
            controller: "TicketListController",
            controllerAs: "ticketList",
          },
        },
      });

      this.$stateProvider.state(
        "websitelayout.headerandmenu.ticket.viewTicket",
        {
          url: "/view/:ticketKey",
          params: {
            ticketKey: "0",
            ticket: "null",
          },
          resolve: {
            r_ticketAnswers: [
              "TicketService",
              "SessionService",
              "$stateParams",
              function (
                ticketService: TicketService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let sessionID = sessionService.session.sessionID;
                let companyKey = sessionService.session.company.key;
                let parameters = <any>$stateParams;

                return ticketService
                  .getTicketAnswers(
                    sessionID,
                    companyKey,
                    parameters.ticketKey,
                    parameters.ticket.answerTicketKeyArr
                  )
                  .then(function (data) {
                    let answers = data.plain();
                    return answers.data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.OTHERM],
          },
          onEnter: [
            "SessionService",
            "$uibModal",
            "$stateParams",
            "$location",
            "$state",
            "TicketService",
            "r_ticketAnswers",
            "SearchService",
            function (
              sessionService: SessionService,
              $uibModal,
              $stateParams: ng.ui.IStateParamsService,
              $location: ng.ILocationService,
              $state: ng.ui.IStateService,
              ticketService: TicketService,
              r_ticketAnswers: any,
              searchService: SearchService
            ) {
              let ticket = "ticket";
              let modalInstance = $uibModal.open({
                templateUrl: "tpl/website/modals/ticketModal.html",
                controller: "TicketModalController",
                controllerAs: "ticket",
                size: "lg",
                backdrop: "static",
                resolve: {
                  r_suppliers: [
                    "SessionService",
                    "SearchService",
                    function (
                      sessionService: SessionService,
                      searchService: SearchService
                    ) {
                      return searchService
                        .getAllThirds(
                          sessionService.session.sessionID,
                          2,
                          false
                        )
                        .then(function (dataThird) {
                          let r_tab = dataThird.plain();
                          return r_tab.data;
                        })
                        .catch(function (error) {
                          console.error(
                            "router.ts",
                            error,
                            sessionService.session.member
                          );
                        });
                    },
                  ],
                  r_clients: [
                    "SessionService",
                    "SearchService",
                    function (
                      sessionService: SessionService,
                      searchService: SearchService
                    ) {
                      return searchService
                        .getAllThirds(
                          sessionService.session.sessionID,
                          1,
                          false
                        )
                        .then(function (dataThird) {
                          let r_tab = dataThird.plain();
                          return r_tab.data;
                        })
                        .catch(function (error) {
                          console.error(
                            "router.ts",
                            error,
                            sessionService.session.member
                          );
                        });
                    },
                  ],
                  r_data: function (): any {
                    if (
                      $stateParams[ticket] !== null &&
                      $stateParams[ticket] !== "null" &&
                      $stateParams[ticket] !== undefined
                    ) {
                      return {
                        ticket: $stateParams[ticket],
                        ticketAnswers: r_ticketAnswers,
                      };
                    } else {
                      let sessionID = sessionService.session.sessionID;
                      let companyKey = sessionService.session.company.key;
                      let parameters = <any>$stateParams;

                      return ticketService
                        .getTicket(sessionID, companyKey, parameters.ticketKey)
                        .then(function (data) {
                          let answers = data.plain();
                          return {
                            ticket: answers.data[0],
                            ticketAnswers: answers.data[1],
                          };
                        })
                        .catch(function (error) {
                          console.error(
                            "router.ts",
                            error,
                            sessionService.session.member
                          );
                        });
                    }
                  },
                },
              });

              // launched when modal is closed with cross
              modalInstance.result.then(() => {
                $state.go(
                  "websitelayout.headerandmenu.ticket",
                  {},
                  {
                    location: true,
                    inherit: true,
                    relative: $state.$current,
                    notify: true,
                    reload: true,
                  }
                );
              });

              // launched when modal is closed with escape key
              modalInstance.result.catch(() => {
                $state.go(
                  "websitelayout.headerandmenu.ticket",
                  {},
                  {
                    location: true,
                    inherit: true,
                    relative: $state.$current,
                    notify: true,
                    reload: true,
                  }
                );
              });
            },
          ],
        }
      );

      this.$stateProvider.state("websitelayout.headerandmenu.account", {
        url: "/account",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: null,
        },
        resolve: {
          r_user_data: [
            "UserService",
            "SessionService",
            function (
              userService: UserService,
              sessionService: SessionService
            ) {
              return userService
                .getUserByKey(
                  sessionService.session.sessionID,
                  sessionService.session.member.key
                )
                .then(function (data) {
                  return data.plain();
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/account/account.html",
            controller: "UserController",
            controllerAs: "user",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.balance", {
        url: "/profit/balance",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.PROFIT02],
        },
        resolve: {
          r_balance: [
            "ProfitService",
            "TreeLevelConverter",
            "SessionService",
            function (
              profitService: ProfitService,
              treeLevelConverter: TreeLevelConverter,
              sessionService: SessionService
            ) {
              return profitService
                .getBalance(
                  sessionService.session.sessionID,
                  sessionService.session.company.currBookyear
                )
                .then(function (data) {
                  return treeLevelConverter.convert(data.plain());
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/profit/balance.html",
            controller: "BalanceController",
            controllerAs: "balance",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.income", {
        url: "/profit/income",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.PROFIT03],
        },
        resolve: {
          r_income: [
            "ProfitService",
            "TreeLevelConverter",
            "SessionService",
            function (
              profitService: ProfitService,
              treeLevelConverter: TreeLevelConverter,
              sessionService: SessionService
            ) {
              return profitService
                .getIncome(
                  sessionService.session.sessionID,
                  sessionService.session.company.currBookyear
                )
                .then(function (data) {
                  return treeLevelConverter.convert(data.plain());
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/profit/income.html",
            controller: "IncomeController",
            controllerAs: "income",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.comparison", {
        url: "/profit/comparison",
        data: {
          roles: [
            this.ROLES.ACCOUNTER_AS_USER,
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
          ],
          features: [this.FEATURES.PROFIT05],
        },
        resolve: {
          r_comparisonData: [
            "SessionService",
            "ProfitService",
            "ProfitConverter",
            function (
              sessionService: SessionService,
              profitService: ProfitService,
              profitConverter: ProfitConverter
            ) {
              let dashboardParam;
              if (sessionService.session.company.dashboardParam != null) {
                dashboardParam =
                  sessionService.session.company.dashboardParam.stringifyPnlArr();
              } else {
                dashboardParam = "";
              }

              return profitService
                .getPNLBasedOnConfigREST(
                  sessionService.session.sessionID,
                  dashboardParam
                )
                .then(function (response) {
                  let dataArr = response.plain();
                  let columns = dataArr.data[1];
                  let dataToConvert = _.values(dataArr.data[0]);

                  let dataResolved = [];

                  if (sessionService.session.company.importInfo.state !== 0) {
                    dataResolved[0] = profitConverter.convertComparisonGrid(
                      dataToConvert,
                      columns
                    );
                    dataResolved[1] = columns;
                  }

                  return dataResolved;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/profit/comparison.html",
            controller: "ComparisonController",
            controllerAs: "comparisonCtrl",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.purchase", {
        url: "/profit/vat/purchase",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.PROFIT06],
        },
        resolve: {
          r_vatBookyears: [
            "LiquidityService",
            "SessionService",
            function (
              liquidityService: LiquidityService,
              sessionService: SessionService
            ) {
              return liquidityService
                .getAllVATPeriod(sessionService.session.sessionID)
                .then(function (data) {
                  let returnData = data.plain();
                  return returnData.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_thirds: [
            "SearchService",
            "SessionService",
            function (
              searchService: SearchService,
              sessionService: SessionService
            ) {
              return searchService
                .getAllThirds(sessionService.session.sessionID, 2, false)
                .then(function (data) {
                  let r_tab = data.plain();
                  return r_tab.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_vatOverview: function () {
            return null;
          },
          r_typeOfVat: function () {
            return 2;
          },
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/liquidity/vat/vat.html",
            controller: "VATController",
            controllerAs: "vat",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.sale", {
        url: "/profit/vat/sale",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.PROFIT07],
        },
        resolve: {
          r_vatBookyears: [
            "LiquidityService",
            "SessionService",
            function (
              liquidityService: LiquidityService,
              sessionService: SessionService
            ) {
              return liquidityService
                .getAllVATPeriod(sessionService.session.sessionID)
                .then(function (data) {
                  let returnData = data.plain();
                  return returnData.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_thirds: [
            "SearchService",
            "SessionService",
            function (
              searchService: SearchService,
              sessionService: SessionService
            ) {
              return searchService
                .getAllThirds(sessionService.session.sessionID, 1, false)
                .then(function (data) {
                  let r_tab = data.plain();
                  return r_tab.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_vatOverview: function () {
            return null;
          },
          r_typeOfVat: function () {
            return 1;
          },
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/liquidity/vat/vat.html",
            controller: "VATController",
            controllerAs: "vat",
          },
        },
      });

      this.$stateProvider.state(
        "websitelayout.headerandmenu.liquidityOverview",
        {
          url: "/liquidity/overview",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.LIQUIDITY01],
          },
          resolve: {
            r_debtSuppliers: [
              "LiquidityService",
              "SessionService",
              function (
                liquidityService: LiquidityService,
                sessionService: SessionService
              ) {
                return liquidityService
                  .getCustomerGlobalBalance(
                    sessionService.session.sessionID,
                    "[15,30,90]"
                  )
                  .then(function (data) {
                    let returnData = data.plain();
                    return returnData.data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_supplierBalance: [
              "LiquidityService",
              "SessionService",
              function (
                liquidityService: LiquidityService,
                sessionService: SessionService
              ) {
                return liquidityService
                  .getSupplierGlobalBalance(
                    sessionService.session.sessionID,
                    "[15,30,90]"
                  )
                  .then(function (data) {
                    let returnData = data.plain();
                    return returnData.data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_top5Clients: [
              "LiquidityService",
              "SessionService",
              function (
                liquidityService: LiquidityService,
                sessionService: SessionService
              ) {
                return liquidityService
                  .getTopNCustomerDebt(sessionService.session.sessionID, 5)
                  .then(function (data) {
                    let returnData = data.plain();
                    return returnData.data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_top5Suppliers: [
              "LiquidityService",
              "SessionService",
              function (
                liquidityService: LiquidityService,
                sessionService: SessionService
              ) {
                return liquidityService
                  .getTopNSupplierDebt(sessionService.session.sessionID, 5)
                  .then(function (data) {
                    let returnData = data.plain();
                    return returnData.data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_vatOverview: [
              "LiquidityService",
              "SessionService",
              function (
                liquidityService: LiquidityService,
                sessionService: SessionService
              ) {
                return liquidityService
                  .getVATSolde(sessionService.session.sessionID)
                  .then(function (data) {
                    let returnData = data.plain();
                    return returnData.data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
          views: {
            "main@websitelayout": {
              templateUrl: "tpl/website/liquidity/liquidityOverview.html",
              controller: "LiquidityOverviewController",
              controllerAs: "overview",
            },
          },
        }
      );

      this.$stateProvider.state("websitelayout.headerandmenu.receivable", {
        url: "/liquidity/receivable",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.LIQUIDITY02],
        },
        resolve: {
          r_gridData: [
            "LiquidityService",
            "SessionService",
            "LiquidityConverter",
            "SearchService",
            function (
              liquidityService: LiquidityService,
              sessionService: SessionService,
              liquidityConverter: LiquidityConverter,
              searchService: SearchService
            ) {
              return liquidityService
                .getCustomerBalance(sessionService.session.sessionID)
                .then(function (data) {
                  return searchService
                    .getAllThirds(sessionService.session.sessionID, 1, false)
                    .then(function (dataThird) {
                      let r_tab = dataThird.plain();
                      let returnData = data.plain();
                      if (
                        sessionService.session.company.importInfo !== null &&
                        sessionService.session.company.importInfo.importDate !==
                          null
                      ) {
                        let tempDate: Date = new Date(
                          sessionService.session.company.importInfo.importDate
                        );
                        return liquidityConverter.convert(
                          returnData.data,
                          0,
                          tempDate,
                          r_tab.data
                        );
                      } else {
                        return liquidityConverter.convert(
                          returnData.data,
                          0,
                          new Date(),
                          r_tab.data
                        );
                      }
                    })
                    .catch(function (error) {
                      console.error(
                        "router.ts",
                        error,
                        sessionService.session.member
                      );
                    });
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_title: function () {
            return "LIQUIDITY.RECEIVABLE.clients";
          },
          r_thirds: [
            "SearchService",
            "SessionService",
            function (
              searchService: SearchService,
              sessionService: SessionService
            ) {
              return searchService
                .getAllThirds(sessionService.session.sessionID, 1, false)
                .then(function (data) {
                  let r_tab = data.plain();
                  return r_tab.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_typeOfThird: function () {
            return 1;
          },
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/liquidity/receivableAndDebtor.html",
            controller: "ReceivableAndDebtorController",
            controllerAs: "rd",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.debtor", {
        url: "/liquidity/debtor",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.LIQUIDITY03],
        },
        resolve: {
          r_gridData: [
            "LiquidityService",
            "SessionService",
            "LiquidityConverter",
            "SearchService",
            function (
              liquidityService: LiquidityService,
              sessionService: SessionService,
              liquidityConverter: LiquidityConverter,
              searchService: SearchService
            ) {
              return liquidityService
                .getSupplierBalance(sessionService.session.sessionID)
                .then(function (data) {
                  return searchService
                    .getAllThirds(sessionService.session.sessionID, 2, false)
                    .then(function (dataThird) {
                      let r_tab = dataThird.plain();
                      let returnData = data.plain();

                      if (
                        sessionService.session.company.importInfo !== null &&
                        sessionService.session.company.importInfo.importDate !==
                          null
                      ) {
                        let tempDate: Date = new Date(
                          sessionService.session.company.importInfo.importDate
                        );
                        let debug1 = liquidityConverter.convert(
                          returnData.data,
                          1,
                          tempDate,
                          r_tab.data
                        );
                        return debug1;
                      } else {
                        let debug2 = liquidityConverter.convert(
                          returnData.data,
                          1,
                          new Date(),
                          r_tab.data
                        );
                        return debug2;
                      }
                    })
                    .catch(function (error) {
                      console.error(
                        "router.ts",
                        error,
                        sessionService.session.member
                      );
                    });
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_thirds: [
            "SearchService",
            "SessionService",
            function (
              searchService: SearchService,
              sessionService: SessionService
            ) {
              return searchService
                .getAllThirds(sessionService.session.sessionID, 2, false)
                .then(function (data) {
                  let r_tab = data.plain();
                  return r_tab.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_title: function () {
            return "LIQUIDITY.DEBTOR.suppliers";
          },
          r_typeOfThird: function () {
            return 2;
          },
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/liquidity/receivableAndDebtor.html",
            controller: "ReceivableAndDebtorController",
            controllerAs: "rd",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.vatOverview", {
        url: "/liquidity/vat/overview",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.LIQUIDITY04],
        },
        resolve: {
          r_vatOverview: [
            "LiquidityService",
            "SessionService",
            function (
              liquidityService: LiquidityService,
              sessionService: SessionService
            ) {
              return liquidityService
                .getVATSolde(sessionService.session.sessionID)
                .then(function (data) {
                  let returnData = data.plain();
                  return returnData.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_thirds: function () {
            return null;
          },
          r_vatBookyears: function () {
            return null;
          },
          r_typeOfVat: function () {
            return 0;
          },
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/liquidity/vat/vatOverview.html",
            controller: "VATController",
            controllerAs: "vat",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.turnover", {
        url: "/profit/turnover",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.PROFIT04],
        },
        resolve: {
          r_turnover: [
            "ProfitService",
            "SessionService",
            function (
              profitService: ProfitService,
              sessionService: SessionService
            ) {
              return profitService
                .getTurnoverByBookyear(
                  sessionService.session.sessionID,
                  sessionService.session.company.currBookyear,
                  "",
                  ""
                )
                .then(function (data) {
                  return data.plain().data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_third: [
            "ProfitService",
            "SessionService",
            function (
              profitService: ProfitService,
              sessionService: SessionService
            ) {
              return profitService
                .getAllThird(sessionService.session.sessionID, false)
                .then(function (data) {
                  return data.plain().data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
          r_account: [
            "ProfitService",
            "SessionService",
            function (
              profitService: ProfitService,
              sessionService: SessionService
            ) {
              return profitService
                .getTurnoverAccountArr(sessionService.session.sessionID)
                .then(function (data) {
                  return data.plain().data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/profit/turnover.html",
            controller: "TurnoverController",
            controllerAs: "turnover",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.notifications", {
        url: "/notifications",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
        },
        resolve: {
          r_notifications: [
            "SessionService",
            "UserService",
            function (
              sessionService: SessionService,
              userService: UserService
            ) {
              return sessionService.session.member;
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/parameters/notifications.html",
            controller: "NotificationsController",
            controllerAs: "notifCtrl",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.toSend", {
        url: "/toSend",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.OTHERU],
        },
        resolve: {
          r_invoiceList: [
            "UploadService",
            "SessionService",
            function (
              uploadService: UploadService,
              sessionService: SessionService
            ) {
              return (
                uploadService
                  .getInvoiceArr()
                  //UNCOMMENT
                  .then(function (data) {
                    let returnData = data.plain();
                    return returnData;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  })
              );
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/upload/toSend.html",
            controller: "ToSendController",
            controllerAs: "toSendController",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.archive", {
        url: "/archive",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.OTHERARCH],
        },
        resolve: {
          r_invoicesByMonth: [
            "UploadService",
            "SessionService",
            function (
              uploadService: UploadService,
              sessionService: SessionService
            ) {
              let thisYear = moment().year();

              return uploadService
                .getSentInvoiceByMonth(thisYear)
                .then(function (response) {
                  let data = response.plain();
                  return data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/upload/archive.html",
            controller: "ArchiveController",
            controllerAs: "archiveController",
          },
        },
      });

      this.$stateProvider.state("websitelayout.headerandmenu.configuration", {
        url: "/configuration",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.OTHERARCH],
        },
        resolve: {
          r_uploadFolders: [
            "UploadService",
            "SessionService",
            function (
              uploadService: UploadService,
              sessionService: SessionService
            ) {
              return sessionService
                .initUploadFolder(false)
                .then(sessionService.getUploadFolderMap());
            },
          ],
          r_mailAWS: [
            "UploadService",
            "SessionService",
            function (
              uploadService: UploadService,
              sessionService: SessionService
            ) {
              return uploadService.getMailAWS();
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/upload/configuration.html",
            controller: "ConfigurationController",
            controllerAs: "configurationController",
          },
        },
      });

      // ****************************** IPP ROUTING ******************************

      this.$stateProvider.state("ipp", {
        abstract: true,
        url: "/tax",
        template: "<ui-view/>",
        onEnter: [
          "$window",
          "SessionService",
          "$translate",
          "$translatePartialLoader",
          function (
            $window: ng.IWindowService,
            session: SessionService,
            $translate: ngt.ITranslateService,
            $translatePartialLoader
          ) {
            $translatePartialLoader.addPart("ipp");
            $window.document.title =
              $translate.instant("IPP_FORM.title") +
              " - " +
              session.session.member.fullName;
          },
        ],
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
            this.ROLES.ACCOUNTER_AS_IPP,
          ],
        },
        resolve: {
          authorize: [
            "SessionService",
            function (session: SessionService) {
              return session.authorize();
            },
          ],
        },
      });

      this.$stateProvider
        .state("ipp.form", {
          url: "/form",
          templateUrl: "tpl/IPPForm/index.html",
          controller: "IPPFormControllerIndex",
          controllerAs: "IPPFormCtrl",
          onEnter: [
            "$window",
            "SessionService",
            "$translate",
            "$translatePartialLoader",
            function (
              $window: ng.IWindowService,
              session: SessionService,
              $translate: ngt.ITranslateService,
              $translatePartialLoader
            ) {
              $translatePartialLoader.addPart("ipp");
              $window.document.title =
                $translate.instant("IPP_FORM.title") +
                " - " +
                session.session.member.fullName;
            },
          ],
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
              this.ROLES.ACCOUNTER_AS_IPP,
            ],
          },
          resolve: {
            r_data: [
              "IPPFormService",
              "SessionService",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService
              ) {
                return IPPFormSer.getData()
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
          params: {
            view: null,
          },
        })
        .state("ipp.form.home", {
          url: "/home",
          onEnter: [
            "$window",
            "SessionService",
            "$translate",
            "$translatePartialLoader",
            function (
              $window: ng.IWindowService,
              session: SessionService,
              $translate: ngt.ITranslateService,
              $translatePartialLoader
            ) {
              $translatePartialLoader.addPart("ipp");
              $window.document.title =
                $translate.instant("IPP_FORM.title") +
                " - " +
                session.session.member.fullName;
            },
          ],
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
              this.ROLES.ACCOUNTER_AS_IPP,
            ],
          },
          views: {
            IPPFormStep: {
              templateUrl: "tpl/IPPForm/accueil.html",
              controller: "IPPFormControllerIndex",
              controllerAs: "IPPFormCtrl",
            },
          },
        })
        .state("ipp.form.account", {
          url: "/account",
          onEnter: [
            "$window",
            "SessionService",
            "$translate",
            "$translatePartialLoader",
            function (
              $window: ng.IWindowService,
              session: SessionService,
              $translate: ngt.ITranslateService,
              $translatePartialLoader
            ) {
              $translatePartialLoader.addPart("ipp");
              $window.document.title =
                $translate.instant("IPP_FORM.title") +
                " - " +
                session.session.member.fullName;
            },
          ],
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
              this.ROLES.ACCOUNTER_AS_IPP,
            ],
          },
          resolve: {
            r_user_data: [
              "UserService",
              "SessionService",
              function (
                userService: UserService,
                sessionService: SessionService
              ) {
                return userService
                  .getUserByKey(
                    sessionService.session.sessionID,
                    sessionService.session.member.key
                  )
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
          views: {
            IPPFormStep: {
              templateUrl: "tpl/IPPForm/account.html",
              controller: "IPPFormAccountController",
              controllerAs: "IPPFormCtrl",
            },
          },
        })
        .state("ipp.form.archive", {
          url: "/archive",
          onEnter: [
            "$window",
            "SessionService",
            "$translate",
            "$translatePartialLoader",
            function (
              $window: ng.IWindowService,
              session: SessionService,
              $translate: ngt.ITranslateService,
              $translatePartialLoader
            ) {
              $translatePartialLoader.addPart("ipp");
              $window.document.title =
                $translate.instant("IPP_FORM.title") +
                " - " +
                session.session.member.fullName;
            },
          ],
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
              this.ROLES.ACCOUNTER_AS_IPP,
            ],
          },
          resolve: {
            r_data: [
              "IPPFormService",
              "SessionService",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService
              ) {
                return IPPFormSer.getAllData()
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
          views: {
            IPPFormStep: {
              templateUrl: "tpl/IPPForm/archiveList.html",
              controller: "IPPFormArchiveController",
              controllerAs: "IPPFormCtrl",
            },
          },
        })
        .state("ipp.form.identification", {
          url: "/identification/:ippKey?accountant",
          params: {
            ippKey: "",
          },
          onEnter: [
            "$window",
            "SessionService",
            "$translate",
            "$translatePartialLoader",
            function (
              $window: ng.IWindowService,
              session: SessionService,
              $translate: ngt.ITranslateService,
              $translatePartialLoader
            ) {
              $translatePartialLoader.addPart("ipp");
              $window.document.title =
                $translate.instant("IPP_FORM.title") +
                " (1/6) - " +
                session.session.member.fullName;
            },
          ],
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
              this.ROLES.ACCOUNTER_AS_IPP,
            ],
          },
          views: {
            IPPFormStep: {
              templateUrl: "tpl/IPPForm/identification.html",
              controller: "IPPFormStepsController",
              controllerAs: "IPPFormCtrl",
            },
          },
          resolve: {
            r_step: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getStep(100, ippKey)
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_data: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getDataFor(ippKey)
                  .then(function (data) {
                    IPPFormSer.refreshData(data.plain());
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_fieldsManager: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                return IPPFormSer.getFieldsManager()
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
        })
        .state("ipp.form.family", {
          url: "/family/:ippKey?accountant",
          params: {
            ippKey: "",
          },
          onEnter: [
            "$window",
            "SessionService",
            "$translate",
            "$translatePartialLoader",
            function (
              $window: ng.IWindowService,
              session: SessionService,
              $translate: ngt.ITranslateService,
              $translatePartialLoader
            ) {
              $translatePartialLoader.addPart("ipp");
              $window.document.title =
                $translate.instant("IPP_FORM.title") +
                " (2/6) - " +
                session.session.member.fullName;
            },
          ],
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
              this.ROLES.ACCOUNTER_AS_IPP,
            ],
          },
          views: {
            IPPFormStep: {
              templateUrl: "tpl/IPPForm/family.html",
              controller: "IPPFormStepsController",
              controllerAs: "IPPFormCtrl",
            },
          },
          resolve: {
            r_step: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getStep(200, ippKey)
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_data: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getDataFor(ippKey)
                  .then(function (data) {
                    IPPFormSer.refreshData(data.plain());
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_fieldsManager: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                return IPPFormSer.getFieldsManager()
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
        })
        .state("ipp.form.estateIncome", {
          url: "/income/:ippKey?accountant",
          params: {
            ippKey: "",
          },
          onEnter: [
            "$window",
            "SessionService",
            "$translate",
            "$translatePartialLoader",
            function (
              $window: ng.IWindowService,
              session: SessionService,
              $translate: ngt.ITranslateService,
              $translatePartialLoader
            ) {
              $translatePartialLoader.addPart("ipp");
              $window.document.title =
                $translate.instant("IPP_FORM.title") +
                " (3/6) - " +
                session.session.member.fullName;
            },
          ],
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
              this.ROLES.ACCOUNTER_AS_IPP,
            ],
          },
          views: {
            IPPFormStep: {
              templateUrl: "tpl/IPPForm/estateIncome.html",
              controller: "IPPFormStepsController",
              controllerAs: "IPPFormCtrl",
            },
          },
          resolve: {
            r_step: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getStep(300, ippKey)
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_data: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getDataFor(ippKey)
                  .then(function (data) {
                    IPPFormSer.refreshData(data.plain());
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_fieldsManager: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                return IPPFormSer.getFieldsManager()
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
        })
        .state("ipp.form.remuneration", {
          url: "/remuneration/:ippKey?accountant",
          params: {
            ippKey: "",
          },
          onEnter: [
            "$window",
            "SessionService",
            "$translate",
            "$translatePartialLoader",
            function (
              $window: ng.IWindowService,
              session: SessionService,
              $translate: ngt.ITranslateService,
              $translatePartialLoader
            ) {
              $translatePartialLoader.addPart("ipp");
              $window.document.title =
                $translate.instant("IPP_FORM.title") +
                " (4/6) - " +
                session.session.member.fullName;
            },
          ],
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
              this.ROLES.ACCOUNTER_AS_IPP,
            ],
          },
          views: {
            IPPFormStep: {
              templateUrl: "tpl/IPPForm/remuneration.html",
              controller: "IPPFormStepsController",
              controllerAs: "IPPFormCtrl",
            },
          },
          resolve: {
            r_step: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getStep(400, ippKey)
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_data: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getDataFor(ippKey)
                  .then(function (data) {
                    IPPFormSer.refreshData(data.plain());
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_fieldsManager: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                return IPPFormSer.getFieldsManager()
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
        })
        .state("ipp.form.deductible_expenses", {
          url: "/expenses/:ippKey?accountant",
          params: {
            ippKey: "",
          },
          onEnter: [
            "$window",
            "SessionService",
            "$translate",
            "$translatePartialLoader",
            function (
              $window: ng.IWindowService,
              session: SessionService,
              $translate: ngt.ITranslateService,
              $translatePartialLoader
            ) {
              $translatePartialLoader.addPart("ipp");
              $window.document.title =
                $translate.instant("IPP_FORM.title") +
                " (5/6) - " +
                session.session.member.fullName;
            },
          ],
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
              this.ROLES.ACCOUNTER_AS_IPP,
            ],
          },
          views: {
            IPPFormStep: {
              templateUrl: "tpl/IPPForm/deductible_expenses.html",
              controller: "IPPFormStepsController",
              controllerAs: "IPPFormCtrl",
            },
          },
          resolve: {
            r_step: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getStep(500, ippKey)
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_data: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getDataFor(ippKey)
                  .then(function (data) {
                    IPPFormSer.refreshData(data.plain());
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_fieldsManager: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                return IPPFormSer.getFieldsManager()
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
        })
        .state("ipp.form.divers", {
          url: "/divers/:ippKey?accountant",
          params: {
            ippKey: "",
          },
          onEnter: [
            "$window",
            "SessionService",
            "$translate",
            "$translatePartialLoader",
            function (
              $window: ng.IWindowService,
              session: SessionService,
              $translate: ngt.ITranslateService,
              $translatePartialLoader
            ) {
              $translatePartialLoader.addPart("ipp");
              $window.document.title =
                $translate.instant("IPP_FORM.title") +
                " (6/6) - " +
                session.session.member.fullName;
            },
          ],
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
              this.ROLES.ACCOUNTER_AS_IPP,
            ],
          },
          views: {
            IPPFormStep: {
              templateUrl: "tpl/IPPForm/divers.html",
              controller: "IPPFormStepsController",
              controllerAs: "IPPFormCtrl",
            },
          },
          resolve: {
            r_step: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getStep(600, ippKey)
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_data: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getDataFor(ippKey)
                  .then(function (data) {
                    IPPFormSer.refreshData(data.plain());
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_fieldsManager: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                return IPPFormSer.getFieldsManager()
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
        })
        .state("ipp.form.validation", {
          url: "/final/:ippKey?accountant",
          params: {
            ippKey: "",
          },
          onEnter: [
            "$window",
            "SessionService",
            "$translate",
            "$translatePartialLoader",
            function (
              $window: ng.IWindowService,
              session: SessionService,
              $translate: ngt.ITranslateService,
              $translatePartialLoader
            ) {
              $translatePartialLoader.addPart("ipp");
              $window.document.title =
                $translate.instant("IPP_FORM.title") +
                " (Final) - " +
                session.session.member.fullName;
            },
          ],
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
              this.ROLES.ACCOUNTER_AS_IPP,
            ],
          },

          views: {
            IPPFormStep: {
              templateUrl: "tpl/IPPForm/finalValidation.html",
              controller: "IPPFormStepsController",
              controllerAs: "IPPFormCtrl",
            },
          },
          resolve: {
            r_step: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;
                return IPPFormSer.getStep(700, ippKey)
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_data: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                let parameters = <any>$stateParams;
                let ippKey = parameters.ippKey;

                return IPPFormSer.getDataFor(ippKey)
                  .then(function (data) {
                    IPPFormSer.refreshData(data.plain());
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_fieldsManager: [
              "IPPFormService",
              "SessionService",
              "$stateParams",
              function (
                IPPFormSer: IPPFormService,
                sessionService: SessionService,
                $stateParams: ng.ui.IStateParamsService
              ) {
                return IPPFormSer.getFieldsManager()
                  .then(function (data) {
                    return data.plain();
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
        });

      /*************************************** InvoiceReminder Routing **************************************/

      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoiceReminderOverview",
        {
          url: "/invoiceReminder/overview",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICEREMINDER01],
          },
          resolve: {
            r_dataThirdsWithOperations: [
              "InvoiceReminderService",
              "SessionService",
              "InvoiceReminderConverter",
              function (
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService,
                invoiceReminderConverter: InvoiceReminderConverter
              ) {
                if (sessionService.isInvoiceRemindersInit()) {
                  return sessionService.getInvoiceReminders();
                } else {
                  return invoiceReminderService
                    .getAllThirdsWithOperations(
                      sessionService.session.sessionID
                    )
                    .then(function (data) {
                      return invoiceReminderConverter.getThirdsWithOperations(
                        data.plain().data
                      );
                    })
                    .catch(function (error) {
                      console.error(
                        "router.ts",
                        error,
                        sessionService.session.member
                      );
                    });
                }
              },
            ],
            r_title: function () {
              return "INVOICEREMINDER.OVERVIEW.title";
            },
          },
          views: {
            "main@websitelayout": {
              templateUrl:
                "tpl/website/invoiceReminder/invoiceReminderOverview.html",
              controller: "InvoiceReminderOverviewController",
              controllerAs: "irC",
            },
          },
        }
      );
      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoiceReminderContactInfoForm",
        {
          url: "/invoiceReminder/contactInfoForm?reference",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICEREMINDER01],
          },
          resolve: {
            r_contactData: [
              "$stateParams",
              "InvoiceReminderService",
              "SessionService",
              function (
                $stateParams: ng.ui.IStateParamsService,
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService
              ) {
                return invoiceReminderService
                  .getContactInfo(
                    sessionService.session.sessionID,
                    $stateParams.reference
                  )
                  .then(function (data) {
                    return data.plain().data;
                  })
                  .catch(function (error) {
                    console.error("Error while loading folders");
                  });
              },
            ],
            r_previousPage: [
              "$state",
              function ($state) {
                let currentStateData = {
                  name: $state.current.name,
                  params: $state.params,
                  url: $state.href($state.current.name, $state.params),
                };
                return currentStateData;
              },
            ],
            r_title: function () {
              return "INVOICEREMINDER.CONTACTINFOFORM.title";
            },
          },
          views: {
            "main@websitelayout": {
              templateUrl: "tpl/website/invoiceReminder/contactInfoForm.html",
              controller: "ContactUpdateInfoController",
              controllerAs: "cuiC",
            },
          },
        }
      );
      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoiceReminderInvoicesAndPaymentsPage",
        {
          url: "/invoiceReminder/invoicesAndPayments?reference",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICEREMINDER01],
          },
          resolve: {
            r_dataThirdWithOperations: [
              "InvoiceReminderService",
              "SessionService",
              "InvoiceReminderConverter",
              "$stateParams",
              "$window",
              function (
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService,
                invoiceReminderConverter: InvoiceReminderConverter,
                $stateParams,
                $window
              ) {
                return invoiceReminderService
                  .getThirdWithOperations(
                    sessionService.session.sessionID,
                    $stateParams.reference
                  )
                  .then(function (data) {
                    if (data.plain().status == 0) {
                      return invoiceReminderConverter.getThirdWithOperations(
                        data.plain().data
                      );
                    } else {
                      $window.location.href = "/#/invoiceReminder/overview";
                    }
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
          views: {
            "main@websitelayout": {
              templateUrl:
                "tpl/website/invoiceReminder/invoicesAndPayments.html",
              controller: "InvoicesAndPaymentsController",
              controllerAs: "ipC",
            },
          },
        }
      );
      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoiceReminderTemplateParameters",
        {
          url: "/invoiceReminder/templateParameters",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICEREMINDER01],
          },
          resolve: {
            r_userTemplates: [
              "InvoiceReminderService",
              "SessionService",
              function (
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService
              ) {
                return invoiceReminderService
                  .getUserTemplates(sessionService.session.sessionID)
                  .then(function (data) {
                    return data.plain().data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_dataThirdsWithOperations: [
              "InvoiceReminderService",
              "SessionService",
              "InvoiceReminderConverter",
              function (
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService,
                invoiceReminderConverter: InvoiceReminderConverter
              ) {
                return invoiceReminderService
                  .getAllThirdsWithOperations(sessionService.session.sessionID)
                  .then(function (data) {
                    return invoiceReminderConverter.getThirdsWithOperations(
                      data.plain().data
                    );
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_title: function () {
              return "INVOICEREMINDER.TEMPLATEPARAMETERS.title";
            },
          },
          views: {
            "main@websitelayout": {
              templateUrl:
                "tpl/website/invoiceReminder/templateParameters.html",
              controller: "TemplateParametersController",
              controllerAs: "tpC",
            },
          },
        }
      );
      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoiceReminderConfiguration",
        {
          url: "/invoiceReminder/configuration",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICEREMINDER01],
          },
          resolve: {
            r_companyAdditionalData: [
              "InvoiceReminderService",
              "SessionService",
              function (
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService
              ) {
                return invoiceReminderService
                  .getCompanyAdditionalInformation(
                    sessionService.session.sessionID
                  )
                  .then(function (data) {
                    return data.plain().data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_title: function () {
              return "INVOICEREMINDER.CONFIGURATIONFORM.title";
            },
          },
          views: {
            "main@websitelayout": {
              templateUrl: "tpl/website/invoiceReminder/configurationForm.html",
              controller: "UpdateConfigurationController",
              controllerAs: "ucC",
            },
          },
        }
      );
      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoiceReminderContactList",
        {
          url: "/invoiceReminder/contactList",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICEREMINDER01],
          },
          resolve: {
            r_contacts: [
              "InvoiceReminderService",
              "SessionService",
              function (
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService
              ) {
                return invoiceReminderService
                  .getAllContactsOfUser(sessionService.session.sessionID)
                  .then(function (data) {
                    return data.plain().data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_dataThirdsWithOperations: [
              "InvoiceReminderService",
              "SessionService",
              "InvoiceReminderConverter",
              function (
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService,
                invoiceReminderConverter: InvoiceReminderConverter
              ) {
                if (sessionService.isInvoiceRemindersInit()) {
                  return sessionService.getInvoiceReminders();
                } else {
                  return invoiceReminderService
                    .getAllThirdsWithOperations(
                      sessionService.session.sessionID
                    )
                    .then(function (data) {
                      return invoiceReminderConverter.getThirdsWithOperations(
                        data.plain().data
                      );
                    })
                    .catch(function (error) {
                      console.error(
                        "router.ts",
                        error,
                        sessionService.session.member
                      );
                    });
                }
              },
            ],
            r_title: function () {
              return "INVOICEREMINDER.CONTACTLIST.title";
            },
          },
          views: {
            "main@websitelayout": {
              templateUrl: "tpl/website/invoiceReminder/contactList.html",
              controller: "ContactListController",
              controllerAs: "clC",
            },
          },
        }
      );
      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoiceReminderOperationDetail",
        {
          url: "/invoiceReminder/operationDetail?key?operationKey",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICEREMINDER01],
          },
          resolve: {
            r_saleData: [
              "InvoiceReminderService",
              "SessionService",
              "$stateParams",
              function (
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService,
                $stateParams
              ) {
                return invoiceReminderService
                  .getSale(sessionService.session.sessionID, $stateParams.key)
                  .then(function (data) {
                    return data.plain().data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_remindersData: [
              "InvoiceReminderService",
              "SessionService",
              "$stateParams",
              function (
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService,
                $stateParams
              ) {
                return invoiceReminderService
                  .getRemindersByOperationKey(
                    sessionService.session.sessionID,
                    $stateParams.operationKey
                  )
                  .then(function (data) {
                    return data.plain().data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_title: function () {
              return "INVOICEREMINDER.SALEDETAIL.title";
            },
          },
          views: {
            "main@websitelayout": {
              templateUrl: "tpl/website/invoiceReminder/saleDetail.html",
              controller: "SaleDetailController",
              controllerAs: "sdC",
            },
          },
        }
      );
      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoiceReminderThirdReminderHistory",
        {
          url: "/invoiceReminder/ThirdReminderHistory?reference",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICEREMINDER01],
          },
          resolve: {
            r_remindersData: [
              "InvoiceReminderService",
              "SessionService",
              "$stateParams",
              function (
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService,
                $stateParams
              ) {
                return invoiceReminderService
                  .getRemindersByReference(
                    sessionService.session.sessionID,
                    $stateParams.reference
                  )
                  .then(function (data) {
                    return data.plain().data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_dataThirdWithOperations: [
              "InvoiceReminderService",
              "SessionService",
              "InvoiceReminderConverter",
              "$stateParams",
              function (
                invoiceReminderService: InvoiceReminderService,
                sessionService: SessionService,
                invoiceReminderConverter: InvoiceReminderConverter,
                $stateParams
              ) {
                return invoiceReminderService
                  .getThirdWithOperations(
                    sessionService.session.sessionID,
                    $stateParams.reference
                  )
                  .then(function (data) {
                    return invoiceReminderConverter.getThirdWithOperations(
                      data.plain().data
                    );
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_title: function () {
              return "INVOICEREMINDER.THIRDREMINDERHISTORY.title";
            },
          },
          views: {
            "main@websitelayout": {
              templateUrl:
                "tpl/website/invoiceReminder/thirdReminderHistory.html",
              controller: "ThirdReminderHistoryController",
              controllerAs: "trhC",
            },
          },
        }
      );

      /*************************************** Invoicing Routing ***************************************/

      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoicingOverview",
        {
          url: "/invoicing/overview/:type",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICING01],
          },
          resolve: {
            r_clients: [
              "ClientService",
              "SessionService",
              function (
                clientService: ClientService,
                sessionService: SessionService
              ) {
                return clientService
                  .getAllInvoicingClients(
                    sessionService.session.sessionID,
                    sessionService.session.company.key
                  )
                  .then(function (data) {
                    let r_tab = data.plain();
                    return r_tab.data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_invoices: [
              "$stateParams",
              "InvoicingService",
              "SessionService",
              function (
                $stateParams: ng.ui.IStateParamsService,
                invoicingService: InvoicingService,
                sessionService: SessionService
              ) {
                return invoicingService
                  .getAllInvoices(
                    sessionService.session.sessionID,
                    sessionService.session.company.key,
                    $stateParams.type == "creditNote" ? 2 : 1
                  )
                  .then(function (data) {
                    return data.plain().data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_openingPeriod: [
              "SessionService",
              function (sessionService: SessionService) {
                try {
                  let lastBookyear = 0;
                  let lastOpeningPeriod;
                  if (sessionService.session.company.exerciceArr) {
                    sessionService.session.company.exerciceArr.forEach(
                      (exercice) => {
                        if (exercice.bookyear > lastBookyear) {
                          lastOpeningPeriod = exercice.openingPeriod;
                        }
                      }
                    );
                  } else {
                    let d = new Date();
                    lastOpeningPeriod = {
                      monthIndex: d.getMonth(),
                      year: d.getFullYear(),
                    };
                  }
                  return lastOpeningPeriod;
                } catch (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                }
              },
            ],
            r_invgInfo: [
              "InvoicingService",
              "SessionService",
              function (
                invoicingService: InvoicingService,
                sessionService: SessionService
              ) {
                return invoicingService
                  .getInformation(
                    sessionService.session.sessionID,
                    sessionService.session.company.key
                  )
                  .then(function (data) {
                    return data.plain().data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
          views: {
            "main@websitelayout": {
              templateUrl: "tpl/website/invoicing/invoicingOverview.html",
              controller: "InvoicingOverviewController",
              controllerAs: "invgOvCtrl",
            },
          },
        }
      );
      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoicingEdition",
        {
          url: "/invoicing/edition/:invoiceKey",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICING01],
          },
          params: {
            type: null,
            invoice: null,
            isCopy: false,
          },
          resolve: {
            r_invoice: [
              "$stateParams",
              "InvoicingService",
              "SessionService",
              function (
                $stateParams: ng.ui.IStateParamsService,
                invoicingService: InvoicingService,
                sessionService: SessionService
              ) {
                if ($stateParams.invoiceKey != "") {
                  return invoicingService
                    .getInvoice(
                      sessionService.session.sessionID,
                      $stateParams.invoiceKey
                    )
                    .then(function (data) {
                      return data.plain().data;
                    })
                    .catch(function (error) {
                      console.error(
                        "router.ts",
                        error,
                        sessionService.session.member
                      );
                    });
                } else {
                  return null;
                }
              },
            ],
            r_clients: [
              "ClientService",
              "SessionService",
              function (
                clientService: ClientService,
                sessionService: SessionService
              ) {
                return clientService
                  .getAllInvoicingClients(
                    sessionService.session.sessionID,
                    sessionService.session.company.key
                  )
                  .then(function (data) {
                    let r_tab = data.plain();
                    return r_tab.data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
            r_invgInfo: [
              "InvoicingService",
              "SessionService",
              function (
                invoicingService: InvoicingService,
                sessionService: SessionService
              ) {
                return invoicingService
                  .getInformation(
                    sessionService.session.sessionID,
                    sessionService.session.company.key
                  )
                  .then(function (data) {
                    return data.plain().data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
          views: {
            "main@websitelayout": {
              templateUrl: "tpl/website/invoicing/invoicingEdition.html",
              controller: "InvoicingEditionController",
              controllerAs: "invgEdCtrl",
            },
          },
        }
      );
      this.$stateProvider.state("websitelayout.headerandmenu.invoicingClient", {
        url: "/invoicing/client",
        data: {
          roles: [
            this.ROLES.ADMIN,
            this.ROLES.CUSTOMER,
            this.ROLES.ACCOUNTER_AS_USER,
          ],
          features: [this.FEATURES.INVOICING01],
        },
        resolve: {
          r_clients: [
            "ClientService",
            "SessionService",
            function (
              clientService: ClientService,
              sessionService: SessionService
            ) {
              return clientService
                .getAllInvoicingClients(
                  sessionService.session.sessionID,
                  sessionService.session.company.key
                )
                .then(function (data) {
                  let r_tab = data.plain();
                  return r_tab.data;
                })
                .catch(function (error) {
                  console.error(
                    "router.ts",
                    error,
                    sessionService.session.member
                  );
                });
            },
          ],
        },
        views: {
          "main@websitelayout": {
            templateUrl: "tpl/website/invoicing/clientOverview.html",
            controller: "ClientOverviewController",
            controllerAs: "clientOvCtrl",
          },
        },
      });
      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoicingClientEdition",
        {
          url: "/invoicing/client/edition/:clientKey",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICING01],
          },
          params: {
            client: null,
          },
          resolve: {
            r_client: [
              "$stateParams",
              "ClientService",
              "SessionService",
              function (
                $stateParams: ng.ui.IStateParamsService,
                clientService: ClientService,
                sessionService: SessionService
              ) {
                if ($stateParams.clientKey != "") {
                  return clientService
                    .getInvoicingClient(
                      sessionService.session.sessionID,
                      $stateParams.clientKey
                    )
                    .then(function (data) {
                      return data.plain().data;
                    })
                    .catch(function (error) {
                      console.error(
                        "router.ts",
                        error,
                        sessionService.session.member
                      );
                    });
                } else {
                  return null;
                }
              },
            ],
            r_clients: [
              "SearchService",
              "SessionService",
              function (
                searchService: SearchService,
                sessionService: SessionService
              ) {
                return searchService
                  .getAllThirds(sessionService.session.sessionID, 1, false)
                  .then(function (data) {
                    let r_tab = data.plain();
                    return r_tab.data;
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
          views: {
            "main@websitelayout": {
              templateUrl: "tpl/website/invoicing/clientEdition.html",
              controller: "ClientEditionController",
              controllerAs: "clientEdCtrl",
            },
          },
        }
      );
      this.$stateProvider.state(
        "websitelayout.headerandmenu.invoicingConfiguration",
        {
          url: "/invoicing/configuration",
          data: {
            roles: [
              this.ROLES.ADMIN,
              this.ROLES.CUSTOMER,
              this.ROLES.ACCOUNTER_AS_USER,
            ],
            features: [this.FEATURES.INVOICING01],
          },
          params: {
            tab: 0,
          },
          resolve: {
            r_invgInfo: [
              "InvoicingService",
              "SessionService",
              function (
                invoicingService: InvoicingService,
                sessionService: SessionService
              ) {
                return invoicingService
                  .getInformation(
                    sessionService.session.sessionID,
                    sessionService.session.company.key
                  )
                  .then(function (data) {
                    let invgInfo = data.plain().data;
                    if (invgInfo != null) {
                      return invgInfo;
                    } else {
                      return invoicingService
                        .createInformation(
                          sessionService.session.sessionID,
                          sessionService.session.company.key
                        )
                        .then((response) => {
                          return response.plain().data;
                        })
                        .catch((error) => {
                          console.error(
                            "router.ts",
                            error,
                            sessionService.session.member
                          );
                        });
                    }
                  })
                  .catch(function (error) {
                    console.error(
                      "router.ts",
                      error,
                      sessionService.session.member
                    );
                  });
              },
            ],
          },
          views: {
            "main@websitelayout": {
              templateUrl: "tpl/website/invoicing/invoicingConfiguration.html",
              controller: "InvoicingConfigurationController",
              controllerAs: "invgConfCtrl",
            },
          },
        }
      );
    };
  }
}

angular.module("app").config([
  "$stateProvider",
  "$urlRouterProvider",
  "ROLES",
  "FEATURES",
  (
    $stateProvider: ng.ui.IStateProvider,
    $urlRouterProvider: ng.ui.IUrlRouterProvider,
    ROLES: app.config.constants.roles.ConstantRoles,
    FEATURES: app.config.constants.roles.ConstantFeatures
  ) => {
    return new app.config.routes.RouterConfigurer(
      $stateProvider,
      $urlRouterProvider,
      ROLES,
      FEATURES
    );
  },
]);

angular.module("app").run([
  "$rootScope",
  "$state",
  "$stateParams",
  "SessionService",
  "TicketService",
  "TicketNotification",
  "SocketService",
  "$translate",
  "$locale",
  (
    $rootScope: ng.IRootScopeService,
    $state: ng.ui.IStateService,
    $stateParams: ng.ui.IStateParamsService,
    sessionService: app.functionality.common.session.SessionService,
    ticketService: app.functionality.ticket.TicketService,
    ticketNotification: app.config.values.TicketNotification,
    socketService: SocketService,
    $translate: ngt.ITranslateService,
    $locale: ng.ILocaleService
  ) => {
    $rootScope.$on("$stateChangeError", console.log.bind(console));
    $rootScope.$on(
      "$stateChangeStart",
      function (event, toState, toParams, fromState, fromParams) {
        // track the state the user wants to go to;
        // authorization service needs this

        if (
          sessionService.toState === undefined ||
          toState.templateUrl !== "tpl/website/login/login.html"
        ) {
          sessionService.toState = toState;
          sessionService.toStateParams = toParams;
          //console.error("oui !"+toState.templateUrl);
          if (
            toState.templateUrl === undefined ||
            toState.templateUrl !== "tpl/website/login/login.html"
          ) {
            //console.error(sessionService.fromDirectUrl);
            sessionService.fromDirectUrl = true;
          }
        }

        sessionService.returnToState = fromState;
        sessionService.returnToStateParams = fromParams;

        // if the session is resolved, do an
        // authorization check immediately. otherwise,
        // it'll be done when the state it resolved.

        if (sessionService.isIdentityResolved()) {
          sessionService.authorize();
        }
        if (
          sessionService.session != null &&
          sessionService.session.member != null &&
          toState.url != null &&
          toState.url != "/?Mode&result"
        ) {
          // we ignore the state "login" to avoid spam
          socketService.sendMessage(
            "/app/sendState/" + sessionService.session.sessionID,
            toState.url
          );
        }
      }
    );

    $rootScope.$on("$stateChangeSuccess", function () {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });

    $rootScope.$on("$translatePartialLoaderStructureChanged", function () {
      $translate.refresh();
    });
  },
]);

// bugsnag integration
angular
  .module("exceptionOverride", [])
  .factory("$exceptionHandler", function () {
    return function (exception, cause) {
      if (Bugsnag) {
        Bugsnag.notifyException(exception, <any>{
          diagnostics: { cause: cause },
        });
      }
    };
  });

//general config
angular
  .module("app")
  .config(function (NotificationProvider, $locationProvider) {
    NotificationProvider.setOptions({
      delay: 3000,
      startTop: 20,
      startRight: 15,
      verticalSpacing: 20,
      horizontalSpacing: 20,
      positionX: "right",
      positionY: "top",
    });

    //to remove the # in the urls
    // $locationProvider.html5Mode(true);
  });
angular.module("app").config(function (tmhDynamicLocaleProvider) {
  tmhDynamicLocaleProvider.localeLocationPattern(
    "assets/locale/angular-locale_{{locale}}.js"
  );
  tmhDynamicLocaleProvider.defaultLocale("fr");
});
