/// <reference path="../../_app.ts" />

module app.model {
    export class UploadFolder {

        private _name: string;
        private _reference: string;
        private _type: string;
        private _key: string;
        private _companyKey: string;
        private _creationDate: Date;
        private _archived: boolean;
        private _winbooksID: string;

        get name(): string {
            return this._name;
        }

        set name(value: string) {
            this._name = value;
        }

        get reference(): string {
            return this._reference;
        }

        set reference(value: string) {
            this._reference = value;
        }

        get type(): string {
            return this._type;
        }

        set type(value: string) {
            this._type = value;
        }

        get key(): string {
            return this._key;
        }

        set key(value: string) {
            this._key = value;
        }

        get companyKey(): string {
            return this._companyKey;
        }

        set companyKey(value: string) {
            this._companyKey = value;
        }

        get creationDate(): Date {
            return this._creationDate;
        }

        set creationDate(value: Date) {
            this._creationDate = value;
        }

        get archived(): boolean {
            return this._archived;
        }

        set archived(value: boolean) {
            this._archived = value;
        }

        get winbooksID(): string {
            return this._winbooksID;
        }

        set winbooksID(value: string) {
            this._winbooksID = value;
        }
    }

}