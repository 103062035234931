/// <reference path="../../_app.ts" />

module app.model.branding {

    export class Branding {

        constructor() {
        }

        public iconPath: string ;
        public iconHomepagePath: string ;
        //public iconMailPath: string ;
        //public faviconPath: string ;
        //public pdfGeneratorPath: string ;

        public subDomain: string;

        public getIconPath(): string {
            return this.iconPath;
        }

        public setIconPath(iconPath: string): void {
            this.iconPath = iconPath;
        }

        public getIconHomepagePath(): string {
            return this.iconHomepagePath;
        }

        public setIconHomepagePath(iconHomepagePath: string): void {
            this.iconHomepagePath = iconHomepagePath;
        }
/*
        public getIconMailPath(): string {
            return this.iconMailPath;
        }

        public setIconMailPath(iconMailPath: string): void {
            this.iconMailPath = iconMailPath;
        }

        public getFaviconPath(): string {
            return this.faviconPath;
        }

        public setFaviconPath(faviconPath: string): void {
            this.faviconPath = faviconPath;
        }
*/
        public setSubDomain(subDomain: string): void {
            this.subDomain = subDomain;
        }

        public getSubDomain(): string {
            return this.subDomain;
        }

    }

}