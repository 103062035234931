/// <reference path="../../_app.ts" />

module app.model.constants {

    export class TicketConst {
        DRAFT_STATUS = 0;
        OPENED_STATUS: number = 1;
        CLOSED_STATUS: number = 999;
        FROM_CUSTOMER: number = 1;
        FROM_ACCOUNTER: number = 0;

        CLOSED_C_REASON: number = 1;

        TO_PROCESS: number = 0;
        PROCESSED: number = 100;
    }
}

angular.module("app").
constant("TicketConst", new app.model.constants.TicketConst());