/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {

    export class StatusModalController {

        public title: string;
        public message: string;
        public alreadyTranslated: boolean;

        static $inject = [
            "r_data",
            "$uibModalInstance",
            "$translatePartialLoader"
        ];

        constructor(r_data: any, public $uibModalInstance: any,$translatePartialLoader: ngt.ITranslatePartialLoaderService) {
            this.title = r_data.title;
            this.message = r_data.message;
            if (r_data.alreadyTranslated) {
                this.alreadyTranslated = true;
            }
            $translatePartialLoader.addPart("customer/modals");
        }
        
        public close() {
            this.$uibModalInstance.close();
        }
    }
}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("StatusModalController", app.functionality.common.modals.StatusModalController);
