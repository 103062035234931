/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {

    export class NewMessageModalController {

        public row: any;

        static $inject = [
            "r_data",
            "$uibModalInstance",
            "$translatePartialLoader"
        ];

        constructor(r_data: any,
                    public $uibModalInstance: any,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {
            $translatePartialLoader.addPart("customer/modals");
            this.row = r_data.row;
            // this.message = r_data.message;
        }

        public cancel() {
            this.$uibModalInstance.close();
        }
    }

}

angular.module("app").controller("NewMessageModalController",  app.functionality.common.modals.NewMessageModalController);
