/// <reference path="../../../_app.ts" />

module app.modules.lineargraphs.constant {

    export class ConstantLinearGraphs {
        public unitsOf = 1000;
    }

    export class ConstantConfiguration {
        static constantLinearGraphs = new ConstantLinearGraphs();
    }
}

angular.module("linearChartModule")
    .constant("ConstantLinearGraphs", app.modules.lineargraphs.constant.ConstantConfiguration.constantLinearGraphs);