/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.services {

    import ThirdWithOperations = app.model.invoiceReminder.ThirdWithOperations;

    /**
     * This service is used to create ThirdWithOperations object based on the JSON we get from the server
     */
    export class InvoiceReminderConverter {

        constructor() {
        }

        /**
         * This function is used to create an Array of ThirdWithOperations
         * @param {app.model.invoiceReminder.ThirdWithOperations[]} jsonArrayOfThirdsWithOperations The ThirdWithOperations[] we get from the server as a JSON
         * @returns {any[]} An array of ThirdWithOperations
         */
        public getThirdsWithOperations(jsonArrayOfThirdsWithOperations: ThirdWithOperations[]) {
            let self = this;
            let returnTab = [];

            jsonArrayOfThirdsWithOperations.forEach(function (value: ThirdWithOperations) {
                returnTab.push(self.getThirdWithOperations(value));
            });

            return returnTab;
        }

        /**
         * This function is used to create a ThirdWithOperations based on a JSON we get from the server
         * @param {app.model.invoiceReminder.ThirdWithOperations} jsonThirdWithOperations A ThirdWithOperations as a JSON (got from the back)
         * @returns {app.model.invoiceReminder.ThirdWithOperations} A ThirdWithOperations Javascript object
         */
        public getThirdWithOperations(jsonThirdWithOperations: ThirdWithOperations) {
            let tWO = new ThirdWithOperations(jsonThirdWithOperations.third);
            tWO.lostPaiements = jsonThirdWithOperations.lostPaiements;
            tWO.sales = jsonThirdWithOperations.sales;

            return tWO;
        }
    }

}

angular.module("app").service("InvoiceReminderConverter",  app.functionality.invoiceReminder.services.InvoiceReminderConverter);