/// <reference path="../../_app.ts" />

namespace app.config.constants {
	export class I18NConstants {
		public i18nDefaultLanguage = "fr";
	}

	export class ConstantRestApi {
		public timeout: any = {
			timeout: 100000
		};

		public services: any = {
			budgetInsight: {
				base: "BIMgr",
				getAccounts: "getAccounts",
				getTransactionsForAccount: "getTransactionsForAccount",
				downloadTask: "downloadTask"
			},
			session: {
				baseurl: "sessionMgr",
				session: "session",
				login: "login",
				doLogin: "doLogin",
				companyLinked: "getMyCompaniesLinked",
				accounterCompany: "getAccounterCompany",
				admin: "adminMgmt",
				memberCompany: "getMembersCompany",
				specificMember: "getMember",
				logoutUser: "logoutUser",
				regulation: "regulation",
				getCompanyMap: "companyLinked",
				editMember: "editProfil",
				getSession: "getSessionFromSessionId",
				refreshAccessToken: "refreshAccessToken",
				getVersion: "getVersion",
				me: "me",
				meV2: "meV2",
				resetPwd: "resetPwd",
				discreetMenu: "updateDiscreetMenuPreference",
				numberOfDataToDisplay: "updateNumberOfDataToDisplay"
			},

			liquidity: {
				controller: "liquidityController",
				customer: "customerMgmt",
				supplier: "supplierMgmt",
				getCustomerGlobalBalance: "getCustomerGlobalBalance",
				getSupplierGlobalBalance: "getSupplierGlobalBalance",
				getTopNCustomerDebt: "getTopNCustomerDebt",
				getTopNSupplierDebt: "getTopNSupplierDebt",
				getCustomerBalance: "getCustomerBalance",
				getSupplierBalance: "getSupplierBalance",
				dashboardLiquidity: "getDashboardLiquidity",
				base: "liquidity",
				receivables: "receivables",
				debtors: "debtors",
				VATMgmt: "VATMgmt",
				getVATSolde: "getVATSolde",
				getAllVATPeriod: "getAllVATPeriod",
				getDetailVatOperation: "getDetailVatOperation",
				overview: {
					debtSuppliers: "debtSuppliers",
					top5Clients: "top5Clients",
					top5Suppliers: "top5Suppliers",
					vatOverview: "vatOverview"
				},
				vat: {
					purchase: "purchase",
					sale: "sale"
				},
				accountingEntry: "accountingEntry"
			},

			invoiceReminder: {
				contact: "contactMgmt",
				emailReminder: "emailReminderMgmt",
				templateReminder: "templateReminderMgmt",
				invoiceReminderOperations: "invoiceReminderOperationsMgmt",
                companyAdditionalInformation: "companyAdditionalInformationMgmt",
				getContactInfo: "getContactInfo",
				updateContactInfo: "updateContactInfo",
				sendReminder: "sendReminder",
				getUserTemplates: "getUserTemplates",
				updateTemplate: "updateTemplate",
                getCompanyAdditionalInformation: "getCompanyAdditionalInformation",
                updateCompanyAdditionalInformation: "updateCompanyAdditionalInformation",
                getAllContactsOfUser: "getAllContactsOfUser",
                getAllThirdsWithOperations: "getAllThirdsWithOperations",
                getThirdWithOperations: "getThirdWithOperations",
                getSale: "getSale",
                getRemindersByOperationKey: "getRemindersByOperationKey",
                getRemindersByReference: "getRemindersByReference",
                getReminderPDF: "getReminderPDF"
			},

			invoicing: {
				invoicing: "invoicingMgmt",
				getAllInvoices: "getAllInvoices",
				getInvoice: "getInvoice",
				addInvoice: "addInvoice",
				updateInvoice: "updateInvoice",
				deleteInvoice: "deleteInvoice",
				sendInvoiceToAccounter: "sendInvoiceToAccounter",
				updateStatus: "updateStatus",
				getInformation: "getInformation",
				createInformation: "createInformation",
				updateInformation: "updateInformation",
				email: "invoicingEmailMgmt",
				sendInvoice: "sendInvoice",
				sendTestMail: "sendTestMail",
				invoicingPDF: "invoicingPDFMgmt",
				getInvoicePDF: "getInvoicePDF",
				client: "invoicingClientMgmt",
				getAllInvoicingClients: "getAllInvoicingClients",
				getInvoicingClient: "getInvoicingClient",
				addInvoicingClient: "addInvoicingClient",
				updateInvoicingClient: "updateInvoicingClient",
				deleteInvoicingClient: "deleteInvoicingClient",
				archiveInvoicingClient: "archiveInvoicingClient"
			},

			permanentfolder: {
				init: "Companies",
				object: "PMF",
				base: "folders"
			},

			scenario: {
				base: "scenarioMgmt",
				feedback: "feedback"
			},

			profit: {
				base: "profit",
				accounts: "accounts",
				clients: "clients",
				pnl: "pnl",
				top5C: "top5C",
				top5S: "top5S",
				active: "active",
				passive: "passive",
				exercise: "exercise",
				controller: "profitController",
				dashboardProfit: "getDashboardProfit",
				cashFlow: "cashFlowParamMgmt",
				allThird: "getAllThird",
				sales: "salesMgmt",
				turnoverByBookyear: "getTurnoverByBookyear",
				turnoverAccount: "getTurnoverAccountArr",
				supplier: "supplierMgmt",
				top5supplier: "getTopNBestSupplier",
				customer: "customerMgmt",
				top5customer: "getTopNBestCustomer",
				balanceMgmt: "balanceMgmt",
				balance: "getBalance",
				balanceByPeriod: "getBalanceByPeriod",
				shortPnl: "getShortDetailPNL",
				getPNLBasedOnConfig: "getPNLBasedOnConfig",
				addPNLColumnConfig: "addPNLColumnConfig",
				editPNLColumnConfig: "editPNLColumnConfig",
				deletePNLColumnConfig: "deletePNLColumnConfig",
				getDetailAccount: "getDetailAccount"
			},

			treasury: {
				controller: "treasuryController",
				dashboardTreasury: "getDashboardTreasury",
				base: "treasury",
				bankBalance: "bankBalance",
				treasuryKPI: "treasuryKPIMgmt",
				wcr: "getDetailBFR",
				wc: "getDetailFR",
				cashburn: "cashburnMgmt",
				getDetail: "getDetailCashburn",
				bank: "bankMgmt",
				getGraphData: "getDetailSoldeByMonthToYear",
				bankAccounts: "getBankAccountArr"
			},

			user: {
				base: "account",
				baseurl: "user",
				list: "list",
				notifications: "notifications",
				getMember: "getMember",
				community: "communityMgr"
			},

			IPPForm: {
				baseurl: "taxform",
				ipps: "ipps",
				annexes: "annexes",
				mail: "mail",
				view: "view",
				data: "data",
				recent: "recent",
				updateData: "updateData",
				keys: "requiredFields",
				fileName: "fileName",
				deleteFile: "deleteFile",
				accept: "accept",
				deny: "deny",
				attachments: "attachments",
				subViewFields: "subViewKeys",
				fieldsManager: "fieldsManager",
				welcomeText: "welcomeText"
			},

			upload: {
				base: "upload",
				invoice: "invoiceMgr",
				getInvoiceArr: "getInvoiceArr",
				addInvoice: "addInvoice",
				accounting: "accountingMgr",
				getUploadFolder: "getUploadFolder",
				deleteInvoice: "deleteInvoice",
				deleteFileKey: "deleteFileKey",
				renameInvoice: "renameInvoice",
				setNote: "setNote",
				deleteNote: "deleteNote",
				sendInvoice: "sendInvoicesToVI",
				mergeInvoices: "mergeInvoices",
				mergeInvoicesInFolder: "mergeInvoicesInFolder",
				splitInvoice: "splitInvoice",
				getSentInvoiceByMonth: "getSentInvoiceByMonth",
				getInvoiceForMonth: "getInvoiceForMonth",
				searchForFiles: "searchForFiles",
				downloadInvoices: "downloadInvoices"
			},

			ranges: {
				baseurl: "treasuryapp",
				ranges: "ranges",
				get: {
					base: "get"
				},
				delete: {
					base: "get"
				},
				add: {
					base: "get"
				}
			},

			message: {
				base: "message",
				contacts: "contacts",
				status: "status"
			},

			ticket: {
				community: "communityMgr",
				list: "list",
				contacts: "contacts",
				addTicket: "addTicket",
				ticketSenders: "getAllTicketSenders",
				getTickets: "getTicketsByStatusAndRange",
				fitlerTickets: "filterTicketsByUser",
				ticketAnswers: "getTicketAnswers",
				getTicket: "getTicket",
				statusTicket: "UpdateStatusTickets",
				addAnswer: "addTicketAnswer",
				updateNotifyUser: "updateNotifyUser",
				getNbTicketClientHasToProcess: "getNbTicketClientHasToProcess"
			},

			search: {
				base: "search",
				third: "third",
				entries: "entries",
				cashflow: "cashFlowParamMgmt",
				allThirds: "getAllThird",
				historic: "historicMgmt",
				accountingEntries: "searchInvoice",
				advancedAccountingEntries: "advancedSearchInvoice"
			},

			branding: {
				base: "brandingMgr",
				getIcon: "getIcon"
			},

			intercom: {
				base: "intercomMgr",
				getIntercomData: "getIntercomData"
			},
			DBFileViewer: "DBFileViewer",
			DBFile: "DBFileMgr",
			GDriveFileMgr: "GDriveFileMgr"
		};
	}

	export class ConstantConfiguration {
		static restApi = new ConstantRestApi();
		static i18NConstants = new I18NConstants();
	}
}

angular
	.module("app")
	.constant("RESTAPI", app.config.constants.ConstantConfiguration.restApi)
	.constant("I18NCONSTANTS", app.config.constants.ConstantConfiguration.i18NConstants);

// angular.module("app")
//     .config(function(RestangularProvider : restangular.IProvider){
//         RestangularProvider.setDefaultHeaders("{'Content-Type': 'charset=UTF-8'}")
//     });
