/// <reference path="../../../_app.ts" />

module app.functionality.invoicing.controllers {
  import InvoicingClient = app.model.invoicing.InvoicingClient;

  export class ClientOverviewController implements angular.IController {
    private clients: InvoicingClient[]; // The clients of the user's company
    private filteredClients: InvoicingClient[]; // Array containing the filtered invoices
    private checkedClients: InvoicingClient[] = null; // The currently selected clients (via the checkboxes)

    public filter: {
      clientName?: string;
      vatNumber?: string;
      city?: string;
      country?: string;
      archived?: boolean;
    };

    $onInit() {}

    static $inject = [
      "r_clients",
      "$translatePartialLoader",
      "$translate",
      "$scope",
      "$uibModal",
      "SessionService",
      "Notification",
      "InvoicingService",
      "ClientService",
    ];

    constructor(
      private r_clients: InvoicingClient[],
      private $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $translate: ngt.ITranslateService,
      private $scope,
      private $uibModal,
      private sessionService: common.session.SessionService,
      private notification: any,
      private invoicingService: services.InvoicingService,
      private clientService: services.ClientService
    ) {
      this.clients = r_clients;

      $translatePartialLoader.addPart("customer/invoicing");

      this.filter = { archived: false };
      this.applyFilter();
    }

    /**
     * Uses the filtering values to filter the "clients" array and stores the result in "filteredClients"
     */
    applyFilter(): void {
      this.filteredClients = this.clients;
      this.checkedClients = [];

      for (let property in this.filter) {
        if (property == "archived") {
          if (this.filter[property] == false) {
            this.filteredClients = this.filteredClients.filter((client) => {
              if (client[property] == false) {
                return true;
              }
            });
          }
        } else if (this.filter[property].length > 0) {
          this.filteredClients = this.filteredClients.filter((client) => {
            if (client[property] != null) {
              return (
                client[property]
                  .toString()
                  .toLowerCase()
                  .search(this.filter[property].toLowerCase()) != -1
              );
            } else {
              return false;
            }
          });
        }
      }
    }

    checkClient(index: number): void {
      let position = -1;

      for (let i = 0; i < this.checkedClients.length; i++) {
        if (this.checkedClients[i].key == this.filteredClients[index].key) {
          position = i;
          break;
        }
      }

      if (position == -1) {
        this.checkedClients.push(this.filteredClients[index]);
      } else {
        this.checkedClients.splice(position, 1);
      }
    }

    /**
     * Archives a client, asks confirmation before doing so, then gets back clients from the server to refresh the view
     * @param doArchive True if the client should be archived, false if the client is already archived and should be restored
     */
    archive(doArchive: boolean): void {
      try {
        let clients = this.checkedClients;
        let clientNames = "";
        clients.forEach((client, idx) => {
          clientNames += client.fullName;
          if (idx + 1 != clients.length) {
            clientNames += ", ";
          }
        });

        let self = this;
        this.$uibModal
          .open({
            templateUrl: "tpl/website/invoicing/modal/invgConfModal.html",
            controller: "InvgConfModalController",
            controllerAs: "confMdCtrl",
            backdrop: "static",
            resolve: {
              r_title: () => {
                return self.$translate.instant(
                  "INVOICING.CLIENT.OVERVIEW." +
                    (doArchive ? "archive" : "restore")
                );
              },
              r_content: () => {
                return (
                  "<p>" +
                  self.$translate.instant(
                    "INVOICING.CLIENT.OVERVIEW." +
                      (doArchive ? "archiveMsg1" : "restoreMsg1"),
                    { clientNames: clientNames }
                  ) +
                  "</p>" +
                  "<p>" +
                  self.$translate.instant(
                    "INVOICING.CLIENT.OVERVIEW." +
                      (doArchive ? "archiveMsg2" : "restoreMsg2"),
                    { count: clients.length }
                  ) +
                  "</p>"
                );
              },
              r_buttons: () => {
                return {
                  cancel: {
                    text: self.$translate.instant("INVOICING.MODAL.cancel"),
                    class: "btn-default",
                  },
                  confirm: {
                    text: self.$translate.instant(
                      "INVOICING.CLIENT.OVERVIEW." +
                        (doArchive ? "archive" : "restore")
                    ),
                    class: "btn-warning",
                  },
                };
              },
            },
          })
          .result.then((confirmation) => {
            if (confirmation) {
              for (let i = 0; i < clients.length; i++) {
                this.clientService
                  .archiveInvoicingClient(
                    this.sessionService.session.sessionID,
                    this.checkedClients[i].key,
                    doArchive
                  )
                  .then((response) => {
                    if (i == clients.length - 1) {
                      this.clientService
                        .getAllInvoicingClients(
                          this.sessionService.session.sessionID,
                          this.sessionService.session.company.key
                        )
                        .then((responseGet) => {
                          this.notification.success(
                            this.$translate.instant(
                              "INVOICING.SHARED.operationSuccess"
                            )
                          );
                          this.clients = responseGet.plain().data;
                          this.applyFilter();
                        });
                      this.checkedClients = [];
                    }
                  });
              }
            }
          });
      } catch (e) {
        console.warn(e);
        this.notification.error(
          this.$translate.instant("INVOICING.SHARED.operationFail")
        );
      }
    }
  }
}
angular
  .module("app")
  .controller(
    "ClientOverviewController",
    app.functionality.invoicing.controllers.ClientOverviewController
  );
