/// <reference path="../../../_app.ts" />

module app.modules.lineargraphs.chart {

    export class LineChartBase {
        protected svg: d3.Selection<d3.selection.Group>;
        protected canvas: d3.Selection<d3.selection.Group>;
        protected heightInner: number;
        protected widthInner : number;
        protected canvasHeight: number;
        protected canvasWidth: number;
        protected geometryDef;
        private selectorTooltip: string;
        private pixelDiffToRedraw = 30;

        constructor(protected idElementContainer: string,
                    protected xScale: d3.time.Scale<any, any>, 
                    protected yScale: d3.scale.Linear<number, number>,
                    protected xAxis: d3.svg.Axis,
                    protected yAxis: d3.svg.Axis,
                    protected dateFormatter,
                    protected lineChartServiceUtil: app.modules.lineargraphs.util.LineChartServiceUtil,
                    protected propertyX: string,
                    protected propertyY: string,
                    protected elem, 
                    protected data,
                    protected textXaxis: string,
                    protected textYaxis: string,
                    protected margin: {x: number, y: number}) {
             this.selectorTooltip = "#" + idElementContainer + " .mytool";
        }

        needRedraw(oldSize: number, newSize: number): boolean {
            return Math.abs(oldSize - newSize) > this.pixelDiffToRedraw;
        }

        draw = (canvasWidthAndHeight: {width: number}, forceDrawing: boolean, size: string, chartType: string ) => {
            if ( forceDrawing || this.needRedraw(this.canvasWidth, canvasWidthAndHeight.width)) {
                if (angular.isDefined(size)) {
                    if (size === "lg") {
                        this.canvasHeight = canvasWidthAndHeight.width / 3; // change this number to get a taller graph
                    } else if (size === "sm") {
                        this.canvasHeight = canvasWidthAndHeight.width / 3.8; // change this number to get a taller graph
                    }
                } else {
                    this.canvasHeight = canvasWidthAndHeight.width / 3.4; // change this number to get a taller graph
                }
                this.canvasWidth = canvasWidthAndHeight.width;
                this.widthInner = this.canvasWidth - this.margin.x * 2;
                this.heightInner = this.canvasHeight - this.margin.y * 2;
                if (angular.isDefined(chartType)) {
                    if (chartType === "area") {
                        this.geometryDef = this.lineChartServiceUtil.defineArea(this.xScale, this.yScale, this.propertyX, this.propertyY, this.heightInner);
                    }
                } else {
                    this.geometryDef = this.lineChartServiceUtil.defineLine(this.xScale, this.yScale, this.propertyX, this.propertyY);
                }
                // this.geometryDef = this.defineChartGraph();

                // Range the scales
                this.lineChartServiceUtil.rangeScale(this.xScale, 0, this.widthInner);
                this.lineChartServiceUtil.rangeScale(this.yScale, this.heightInner, 0);

                //  Create the canvas
                this.svg = this.lineChartServiceUtil.defineSvg(this.svg, this.elem);
                this.canvas = this.lineChartServiceUtil.defineCanvas(this.svg, this.canvasWidth, this.canvasHeight, this.margin);

                if (angular.isDefined(chartType)) {
                    if (chartType === "area") {
                        this.lineChartServiceUtil.drawLineAreaChart(this.canvas, this.geometryDef, this.data);
                    }
                } else {
                    // this.drawLineChart();
                    // this.lineChartServiceUtil.drawLineAreaChart(this.canvas, this.geometryDef, this.data);
                    this.lineChartServiceUtil.drawLineChart(this.canvas, this.geometryDef, this.data);
                }

                let translateX = 0;
                if (this.textXaxis !== undefined && this.textXaxis !== "0") {
                    // need to calculate the height to put the X line to 0, because some data are bellow 0
                    // heightInner = 100%
                    let max = this.lineChartServiceUtil.maxAmount(this.data);
                    let min = this.lineChartServiceUtil.minAmount(this.data);
                    let total = (max - min);
                    let oneUnit = this.heightInner / total;
                    translateX = oneUnit * (min);
                }

                //  Add x-axis
                let callxAxis = this.lineChartServiceUtil.addXAxis(this.canvas, this.xAxis, this.heightInner,translateX);
                this.lineChartServiceUtil.cleanChartTitle(this.canvas);
                //  Add text to x-axis
                if (this.textXaxis !== undefined && this.textXaxis !== "0") {
                    this.lineChartServiceUtil.addXAxisText(callxAxis,this.textXaxis,translateX);
                }
                //  Add y-axis
                let callyAxis = this.lineChartServiceUtil.addYAxis(this.canvas, this.yAxis);
                //  Add text to y-axis
                this.lineChartServiceUtil.addYAxisText(callyAxis, this.textYaxis);

                // Add the tooltip
                this.lineChartServiceUtil.addCircleAndTooltip(  this.selectorTooltip,
                                                                this.canvas, this.data, this.xScale, this.yScale,
                                                                this.propertyX, this.propertyY, this.dateFormatter);
            }
        }

        // changeTextXAxis = (text: string ) => {
            // this.lineChartServiceUtil.cleanChartTitle(this.canvas);
            // this.lineChartServiceUtil.setTextXAxis(this.canvas, this.widthInner / 2, this.heightInner + this.margin.y, text);
        // }

        getSVG() {
            return this.svg;
        }
        
        defineChartGraph() { throw new Error("LineChartBase : This method is abstract and must be overrided."); }
        
        drawLineChart() { throw new Error("LineChartBase : This method is abstract and must be overrided."); }
    }
    
}