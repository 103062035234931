/// <reference path="../../_app.ts" />

module app.model.exchange {

    export const enum Status {

        SUCCES = 0,
            
        //member error > 400
        BAD_AUTHENTIFICATION = 401,
        NOT_ALLOWED = 402,
        NOT_LOGGED = 403,
        NO_COMPANY_ASSIGNED = 404,
        NO_ACCOUNTING_SITUATION = 405,
        NO_CORRESPONDING_MAIL = 406,

        //registration error > 500
        FIELD_ALREADY_EXIST = 501,

        //Version error > 600
        VERSION_OUTDATED = 601,

        //Upload error > 700
        UPLOAD_FAILED = 701,

        //server error > 1000
        SERVER_ERROR = 1001,
        NULL_POINTER_EXCEPTION = 1002,
        TOO_BIG_REQUEST = 1003,

        //ALERT > 2000
        ALERT_MULTI_COMPANIES = 2001,
        COMPANY_CURRENTLY_USED = 2002,
        MULTI_THIRD_SEARCH = 2003,
        REFINE_SEARCH = 2004,
        ALREADY_SENDED = 2005,
        ALERT_WAITING_STATUS = 2006
    }
}
