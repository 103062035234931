/// <reference path="../_app.ts" />

module app.model {

    export class Account {

        constructor() {
        }

        public key: string;
        public companyKey: string;
        public numero: number;
        public solde: number;
        public report: number;

        public comparison: number; //only use in profit comparison, never store in DB

        public getNumero(): number {
            return this.numero;
        }

        public setNumero(numero: number): void {
            this.numero = numero;
        }

        public getSolde(): number {
            return this.solde;
        }

        public applyAccountingEntry(acc: AccountingEntry): void {
            this.solde += acc.amount;
        }

        public unapplyAccountingEntry(acc: AccountingEntry): void {
            this.solde -= acc.amount;
        }

    }

}