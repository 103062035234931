/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {

    export class SmallDetailModalController {

        public row: any;
        public sessionID: string;
        public companyKey: string;

        static $inject = [
            "r_data",
            "$uibModalInstance",
            "$translate",
            "$window",
            "ENV",
            "$translatePartialLoader"
        ];

        constructor(r_data: any,
                    public $uibModalInstance: any,
                    private $translate: ngt.ITranslateService,
                    public $window: ng.IWindowService,
                    private ENV : app.config.constants.environment.ConstantEnv,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {
            $translatePartialLoader.addPart("customer/modals");
            this.row = r_data.row;
            this.sessionID = r_data.sessionID;
            this.companyKey = r_data.companyKey;
        }

        public openPdf() {
            this.$window.open(this.ENV.baseUrl + "/FileMgr?sessionID=" + this.sessionID +
                "&companyKey=" + this.companyKey +
                "&journal=" + this.row.journalType +
                "&period=" + this.row.period +
                "&doc=" + this.row.docNumber +
                "&bookyear=" + this.row.bookyear, "_blank"
            );
        }

        public close() {
            this.$uibModalInstance.close();
        }

    }

}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("SmallDetailModalController", app.functionality.common.modals.SmallDetailModalController);
