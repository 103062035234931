/// <reference path="../_app.ts" />
module app.model {
    
    export class BookHistoric {
        /*public static const SALES:int = 1;
        public static const PURCHASES:int = 2;
        public static const BANK:int = 3;
        public static const OTHER:int = 4;
        public static const OPD:int = 5;*/
        SALES(): number { return 1; }
        PURCHASES(): number { return 2; }
        BANK(): number { return 3; }
        OTHER(): number { return 4; }
        OPD(): number { return 5; }

        public key: string;
        public name: string;
        public description: string;
        public type: number;

        public entriesArr: Array<any>;

        constructor() {
        }
    }

}