/// <reference path="../../../_app.ts" />

module app.modules.lineargraphs.converter {
  import ConstantLinearGraphs = app.modules.lineargraphs.constant.ConstantLinearGraphs;
  import LineChartServiceUtil = app.modules.lineargraphs.util.LineChartServiceUtil;

  export class LineChartConverterForDashboard {
    static $inject = ["ConstantLinearGraphs", "LineChartServiceUtil"];

    constructor(
      private constantLinearGraphs: ConstantLinearGraphs,
      private lineChartServiceUtil: LineChartServiceUtil
    ) {}

    public convert = (dataIn: any, maxDate?: Date) => {
      let dataOut: any[] = [];
      let self = this;
      let max = 0;
      _(dataIn).forEach((data) => {
        if (data.amount > max) {
          max = data.amount;
        }
      });

      _(dataIn).forEach((data) => {
        let result;
        if (angular.isDefined(data.noTrace)) {
          // if (data.noTrace === true) {
          // }
        } else if (
          maxDate.getFullYear() === data.year &&
          data.month < maxDate.getMonth()
        ) {
          result = { date: moment().toDate() };
          result.amount = Math.round(data.amount);
          result.date = self.lineChartServiceUtil.getLastDayOfMonth(
            data.year,
            data.month
          );
          dataOut.push(result);
        } else if (
          maxDate.getFullYear() === data.year &&
          data.month === maxDate.getMonth()
        ) {
          result = { amount: 0, date: moment().toDate() };
          result.amount = Math.round(data.amount);
          result.date = maxDate;
          dataOut.push(result);
        } else {
          result = { amount: 0, date: moment().toDate() };
          result.amount = Math.round(data.amount);
          result.date = self.lineChartServiceUtil.getLastDayOfMonth(
            data.year,
            data.month
          );
          dataOut.push(result);
        }
      });

      return _.orderBy(dataOut, ["date"], ["asc"]);
    };

    public convertForMainTreasury = (
      dataIn: any,
      year: number,
      maxDate?: Date
    ) => {
      let dataOut: any[] = [];
      let self = this;

      _(dataIn).forEach((data) => {
        let result;
        if (angular.isDefined(data.noTrace)) {
          // nothing to do
        } else if (!angular.isDefined(data.amount)) {
          // nothing to do
        } else if (
          maxDate !== undefined &&
          maxDate !== null &&
          maxDate.getFullYear() === year &&
          data.month == maxDate.getMonth()
        ) {
          result = { amount: 0, date: moment().toDate() };
          result.amount = Math.round(data.amount);
          result.date = maxDate;
          dataOut.push(result);
        } else if (
          maxDate === undefined ||
          maxDate === null ||
          (maxDate.getFullYear() === year && data.month <= maxDate.getMonth())
        ) {
          result = { amount: 0, date: moment().toDate() };
          result.amount = Math.round(data.amount);
          result.date = self.lineChartServiceUtil.getLastDayOfMonth(
            year,
            data.month
          );
          dataOut.push(result);
        } else {
          result = { date: moment().toDate() };
          result.amount = Math.round(data.amount);
          result.date = self.lineChartServiceUtil.getLastDayOfMonth(
            year,
            data.month
          );
          dataOut.push(result);
        }
      });
      return _.orderBy(dataOut, ["date"], ["asc"]);
    };

    public convertForMonthSelection = (
      dataIn: any,
      year: number,
      month: number
    ) => {
      let dataOut: any[] = [];
      let self = this;

      _(dataIn).forEach((data) => {
        let result = { amount: 0, date: moment().toDate() };
        result.amount = Math.round(data.amount / 1); // 1 was self.constantLinearGraphs.unitsOf
        result.date = self.lineChartServiceUtil.getDateByDay(
          year,
          month,
          data.day
        );
        dataOut.push(result);
      });

      return _.orderBy(dataOut, ["date"], ["asc"]);
    };
  }
}

angular
  .module("linearChartModule")
  .service(
    "LineChartConverterForDashboard",
    app.modules.lineargraphs.converter.LineChartConverterForDashboard
  );
