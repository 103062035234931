/// <reference path="../../../_app.ts" />

module app.modules.lineargraphs.builder {
    import LineChartBase = app.modules.lineargraphs.chart.LineChartBase;
    
    export class LineChartBaseBuilder {
       
        static $inject = ["LineChartServiceUtil"];
        constructor(protected lineChartServiceUtil: app.modules.lineargraphs.util.LineChartServiceUtil) {
        }
        
        buildChart = (  idElementContainer: string, elem, data: any, propertyX: string, propertyY: string,
                        textXaxis: string, textYaxis: string, margin: {x: number, y: number}): LineChartBase => {
            let dateFormater = d3.time.format("%b %Y");
            // Create the scales
            let xScale = this.lineChartServiceUtil.getTimeScale();
            let yScale = this.lineChartServiceUtil.getLinearScale();

            // Define the axes
            let xAxis = this.lineChartServiceUtil.getAxis(xScale, "bottom", 0);
            let yAxis = this.lineChartServiceUtil.getAxis(yScale, "left", 0);

            // this loop is done to remove white space on the graph
            let log = [];
            angular.forEach(data, function(value, key) {
                if (value.amount !== "gap") {
                    this.push(value);
                }
            }, log);

            this.lineChartServiceUtil.setAxisTickFormat(xAxis, this.lineChartServiceUtil.getFormaterAxisX(log, propertyX, propertyY));

            // Scale the range of the data
            this.lineChartServiceUtil.setDomainTimeScale(xScale, log, propertyX);
            this.lineChartServiceUtil.setDomainInLinearScale(yScale, log, propertyY);
            
            return this.getLineChartGraph(  idElementContainer, xScale, yScale, xAxis, yAxis, dateFormater,
                                            elem, log, propertyX, propertyY, textXaxis, textYaxis, margin);
        }
        
        getLineChartGraph = (   idElementContainer: string, xScale: d3.time.Scale<any, any>, yScale: d3.scale.Linear<number, number>, 
                                xAxis: d3.svg.Axis, yAxis: d3.svg.Axis, dateFormater, 
                                elem, data, propertyX: string, propertyY: string, 
                                textXaxis: string, textYaxis: string, margin: {x: number, y: number}): LineChartBase => {
            return null;
        }
          
    }
  
}