/// <reference path="../../_app.ts" />
module app.functionality.parameters {

    import NotificationsService =  app.functionality.parameters.NotificationsService;
    import UserService = app.functionality.user.UserService;

    export class NotificationsController {

        public dataNotif: any;
        public selectedOption: string;
        public showNotifMAJ: boolean = false;
        public showNotifMSG: boolean = false;

        options = ["HEADER.NOTIFICATIONS.FREQUENCY.01", "HEADER.NOTIFICATIONS.FREQUENCY.02", "HEADER.NOTIFICATIONS.FREQUENCY.03"];

        static $inject = [
            "r_notifications",
            "NotificationsService",
            "$translate",
            "UserService",
            "SessionService",
            "$translatePartialLoader"
        ];

        constructor(r_notifications: any,
                    private service: NotificationsService,
                    private $translate: ngt.ITranslateService,
                    private userService: UserService,
                    public sessionService: app.functionality.common.session.SessionService,
                    $translatePartialLoader: ngt.ITranslatePartialLoaderService) {
            $translatePartialLoader.addPart("customer/parameters");

            this.dataNotif = r_notifications;
            if (this.dataNotif.mailPreferenceActivity === 0) {
                this.selectedOption = "HEADER.NOTIFICATIONS.FREQUENCY.01";
            }
            if (this.dataNotif.mailPreferenceActivity === 1) {
                this.selectedOption = "HEADER.NOTIFICATIONS.FREQUENCY.02";
            }
            if (this.dataNotif.mailPreferenceActivity === 2) {
                this.selectedOption = "HEADER.NOTIFICATIONS.FREQUENCY.03";
            }

            for(let i in this.sessionService.session.member.featuresByComp) {
                let permissions = this.sessionService.session.member.featuresByComp[i];
                if (permissions.indexOf("PROFIT") !== -1 || permissions.indexOf("TREASURY") !== -1 || permissions.indexOf("LIQUIDITY") !== -1 || permissions.indexOf("DASHBOARD") !== -1 || permissions.indexOf("OTHERRS") !== -1) {
                    this.showNotifMAJ = true; // see MAJ notif in at least one of the company ==> show it
                }
                if (permissions.indexOf("OTHERM") !== -1) {
                    this.showNotifMSG = true;
                }
            }

        }

        isSelected(x: boolean): string {
            if (x) {
                return "bold";
            }
        }

        isNotSelected(x: boolean): string {
            if (!x) {
                return "bold";
            }
        }

        public save() {
            if (this.selectedOption === "HEADER.NOTIFICATIONS.FREQUENCY.01") {
                this.dataNotif.mailPreferenceActivity = 0;
            }
            if (this.selectedOption === "HEADER.NOTIFICATIONS.FREQUENCY.02") {
                this.dataNotif.mailPreferenceActivity = 1;
            }
            if (this.selectedOption === "HEADER.NOTIFICATIONS.FREQUENCY.03") {
                this.dataNotif.mailPreferenceActivity = 2;
            }

            this.userService.updateUser(this.sessionService.session.sessionID, this.dataNotif);
            this.sessionService.session.member = this.dataNotif;
        }
    }
}

angular.module("app").controller("NotificationsController", app.functionality.parameters.NotificationsController);