/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.services {

    import ThirdWithOperations = app.model.invoiceReminder.ThirdWithOperations;

    /**
     * This service is used to share the selected date between the Overview view and the detailed view of a third.
     * The selected date is used to show only the invoices that are due at the date.
     */

    export class FilterService {

        /**
         * Represents all the data of the thirds and their operations that we get from the server
         */
        private allThirdsWithOperations: ThirdWithOperations[];

        /**
         * Represents the thirds with operations that should be displayed (used with the filter system)
         */
        public thirdsWithOperations: ThirdWithOperations[];

        /**
         * Used to call only once the method getMaxDaysLateForAllThirdWithOperations
         */
        public maxDaysLate: number;
        /**
         * Used to call only once the method getMaxReminderNumberForAllThirdWithOperations
         */
        public maxReminderNumber: number;

        /**
         * All the filters from the view are located in the controller in order to fix bugs when they are undefined
         */
        public daysLateHighFilter: string;
        public daysLateLowFilter: string;
        public reminderNumberHighFilter: string;
        public reminderNumberLowFilter: string;
        public thirdNameFilter: string;

        /**
         * Current type of the filters
         *  - Interval has two values : a lower (lowFilter) and a higher (highFilter)
         *  - Exact has one value which is stored in lowFilter
         */
        public daysLateFilterType: string;
        public reminderNumberFilterType: string;

        /**
         * The date selected by the user as a reference to determine if a sale is late
         */
        public selectedDate: Date;

        static $inject = [
            "SessionService",
            "DateChangingService"
        ];

        constructor(private sessionService: app.functionality.common.session.SessionService,
                    private dateChangingService: app.functionality.invoiceReminder.services.DateChangingService) {

        }

        /**
         * This function is used to initialize the service
         * It should be called on each page using the filters
         * @param {app.model.invoiceReminder.ThirdWithOperations[]} thirdsWithOperations The data to filter
         * @param {boolean} updateFiltersValues  true if you want the filters values to be updated at the initialization of the page
         *                                       The max values for the filters are determined based on the data we get for the current page
         *                                       If the current high value of the filter is higher than the max, we can set it to the max
         *                                       In some case, we want to keep the filters values from the previous page.
         *                                       For example, On the Detailed Paged, we want to keep the values of the filters the user selected on the Overview Page.
         *                                       Then the boolean should be false.
         */
        public initService(thirdsWithOperations: ThirdWithOperations[], updateFiltersValues: boolean,callBack?: Function) {
            this.allThirdsWithOperations = this.thirdsWithOperations = thirdsWithOperations;

            // We get the ThirdWithOperations and their sales that are due at the selected date
            this.selectedDate = this.dateChangingService.getDate();
            this.changeSelectedDate(updateFiltersValues);

            // We set a default value for all the filters. It is usefull when the page is refreshed
            if (this.daysLateFilterType         == undefined) { this.daysLateFilterType       = "interval"; }
            if (this.reminderNumberFilterType   == undefined) { this.reminderNumberFilterType = "interval"; }
            if (this.daysLateHighFilter         == undefined) { this.daysLateHighFilter       = ""; }
            if (this.reminderNumberHighFilter   == undefined) { this.reminderNumberHighFilter = ""; }
            if (this.thirdNameFilter            == undefined) { this.thirdNameFilter          = ""; }

            if (this.daysLateLowFilter          == undefined && this.daysLateFilterType       == "interval") { this.daysLateLowFilter = ""; }
            if (this.reminderNumberLowFilter    == undefined && this.reminderNumberFilterType == "interval") { this.reminderNumberLowFilter  = ""; }

            this.restrictData(callBack);
        }

        /**
         * This function is used to keep only the sales that are due based on the date chosen by the user
         */
        public changeSelectedDate(updateFilters: boolean) {
            let self = this;

            // We get a corrected date if the one chosen was not good
            self.selectedDate = self.dateChangingService.setDate(self.selectedDate);

            self.initThirdsWithOperationsAtSelectedDate();

            // we test if the max are undefined in case of refresh
            if (self.maxDaysLate == undefined || self.maxReminderNumber == undefined || updateFilters) {
                self.maxDaysLate = self.getMaxDaysLateForAllThirdWithOperations();
                self.maxReminderNumber = self.getMaxReminderNumberForAllThirdWithOperations();
            }

            if (updateFilters) {
                // Mise à jour des filtres
                if (+self.daysLateHighFilter > self.maxDaysLate) {
                    self.daysLateHighFilter = "" + self.maxDaysLate;
                }

                if (+self.reminderNumberHighFilter > self.maxReminderNumber) {
                    self.reminderNumberHighFilter = "" + self.maxReminderNumber;
                }
            }
            this.restrictData();
        }

        /**
         * This function is used to keep only the thirds that have due sales at the selected date
         */
        public initThirdsWithOperationsAtSelectedDate() {
            let self = this;
            self.thirdsWithOperations = [];

            self.allThirdsWithOperations.forEach(function (value: ThirdWithOperations) {
                if (value.getMaxDueAmount(self.selectedDate) > 0) {
                    let tWO = new ThirdWithOperations(value.third);
                    tWO.lostPaiements = value.lostPaiements;
                    let hasSale = false;

                    value.sales.forEach(function (sale) {
                        if (value.isSaleDue(sale, self.selectedDate)) {
                            tWO.sales.push(sale);
                            hasSale = true;
                        }
                    });

                    if (hasSale) {
                        self.thirdsWithOperations.push(tWO);
                    }
                }
            });
        }

        /**
         * This function is used to get only the invoices that correspond to the filters given by the user
         */
        public restrictData(callBack?: Function) {
            let verifDaysLateFilter = this.daysLateLowFilter !== undefined;
            let verifReminderNumberFilter = this.reminderNumberLowFilter !== undefined;
            let verifThirdNameFilter = this.thirdNameFilter !== undefined;

            let self = this;

            if (verifThirdNameFilter || verifDaysLateFilter || verifReminderNumberFilter) {

                let comparatorFunction = function (lowFilter, highFilter, filterType, a) {
                    if (filterType == "interval" ) {
                        if (lowFilter === "") {
                            lowFilter = 0;
                        }
                        if (highFilter === "") {
                            highFilter = 999999999999;
                        }
                        return lowFilter <= a && highFilter >= a;
                    }
                    return lowFilter == a;
                };

                self.thirdsWithOperations = [];
                self.allThirdsWithOperations.forEach(function (thirdWithOperation) {
                    let tWO = new ThirdWithOperations(thirdWithOperation.third);
                    tWO.lostPaiements = thirdWithOperation.lostPaiements;
                    let hasSale = false;

                    if (thirdWithOperation.getMaxDueAmount(self.selectedDate) > 0) {
                        thirdWithOperation.sales.forEach(function (sale) {
                            if (thirdWithOperation.isSaleDue(sale, self.selectedDate)
                                && ((verifThirdNameFilter && thirdWithOperation.third.fullName.toLowerCase().indexOf(self.thirdNameFilter.toLowerCase()) != -1) || !verifThirdNameFilter)
                                && ((verifDaysLateFilter
                                    && verifReminderNumberFilter
                                    && comparatorFunction(self.daysLateLowFilter, self.daysLateHighFilter, self.daysLateFilterType, thirdWithOperation.getDaysLate(sale, self.selectedDate))
                                    && comparatorFunction(self.reminderNumberLowFilter, self.reminderNumberHighFilter, self.reminderNumberFilterType, sale.reminderNumber))
                                    || (verifDaysLateFilter && !verifReminderNumberFilter && comparatorFunction(self.daysLateLowFilter, self.daysLateHighFilter, self.daysLateFilterType, thirdWithOperation.getDaysLate(sale, self.selectedDate)))
                                    || (verifReminderNumberFilter && !verifDaysLateFilter && comparatorFunction(self.reminderNumberLowFilter, self.reminderNumberHighFilter, self.reminderNumberFilterType, sale.reminderNumber)))) {

                                tWO.sales.push(sale);
                                tWO.checkAllSales(true);
                                hasSale = true;
                            }
                        });
                    }
                    tWO.checkAllPayments(true);

                    if (hasSale) {
                        self.thirdsWithOperations.push(tWO);
                    }
                });
            }
            else {
                self.initThirdsWithOperationsAtSelectedDate();
            }

            if(callBack!=null) {
                callBack();
            }
        }

        /**
         * This function is used to correctly set the filter when the user switch between the exact filter and the interval filter
         */
        public switchDaysLateFilterType() {
            let self = this;
            if (this.daysLateFilterType == "exact") {
                self.resetDaysLateFilterToInterval();
            }
            else {
                self.resetDaysLateFilterToExact();
            }
        }

        /**
         * This function is used to correctly set the filter when the user switch between the exact filter and the interval filter
         */
        public switchReminderNumberFilterType() {
            let self = this;
            if (this.reminderNumberFilterType == "exact") {
                self.resetReminderNumberFilterToInterval();
            }
            else {
                self.resetReminderNumberFilterToExact();
            }
        }

        /**
         * This function returns the highest number of late days for all the third (used to set the max of the filter)
         * @returns {number} the highest number of late days for all the third
         */
        public getMaxDaysLateForAllThirdWithOperations(): number {
            let ret = 0;
            let self = this;
            this.allThirdsWithOperations.forEach(function (value) {
                let temp = value.getMaxDaysLate(self.selectedDate);
                if (temp > ret) {
                    ret = temp;
                }
            });
            return ret;
        }

        /**
         * This function returns the highest number of reminder for all the third (used to set the max of the filter)
         * @returns {number} the highest number of reminder for all the third
         */
        public getMaxReminderNumberForAllThirdWithOperations(): number {
            let ret = 0;
            this.allThirdsWithOperations.forEach(function (value) {
                let temp = value.getMaxReminder();
                if (temp > ret) {
                    ret = temp;
                }
            });
            return ret;
        }

        /**
         * This function returns the total due amount for all the third
         * @returns {number} returns the total due amount for all the third
         */
        public getDueAmountSumForAllThirdWithOperations(): number {
            let ret = 0;
            let self = this;
            this.thirdsWithOperations.forEach(function (value) {
                ret+= value.getMaxDueAmount(self.selectedDate);
            });
            return ret;
        }

        /**
         * This function is used to reset the reminder number filter into the Interval type
         */
        public resetReminderNumberFilterToInterval() {
            this.reminderNumberHighFilter = "";
            this.reminderNumberLowFilter = "";
            this.reminderNumberFilterType = "interval";
            this.restrictData();
        }

        /**
         * This function is used to reset the days late filter into the Interval type
         */
        public resetDaysLateFilterToInterval() {
            this.daysLateHighFilter = "";
            this.daysLateLowFilter = "";
            this.daysLateFilterType = "interval";
            this.restrictData();
        }

        /**
         * This function is used to reset the reminder number filter into the Exact type
         */
        public resetReminderNumberFilterToExact() {
            this.reminderNumberFilterType = "exact";
            this.reminderNumberHighFilter = undefined;
            this.reminderNumberLowFilter = undefined;
            this.restrictData();
        }

        /**
         * This function is used to reset the days late filter into the Interval type
         */
        public resetDaysLateFilterToExact() {
            this.daysLateFilterType = "exact";
            this.daysLateHighFilter = undefined;
            this.daysLateLowFilter = undefined;
            this.restrictData();
        }

        /**
         * This function is used to reset all filters
         */
        public resetAllFilters() {
            this.resetDaysLateFilterToExact();
            this.resetDaysLateFilterToInterval();
            this.resetReminderNumberFilterToExact();
            this.resetReminderNumberFilterToInterval();
            this.selectedDate = new Date(this.sessionService.session.company.importInfo.importDate);
            this.thirdNameFilter = "";
        }

        /**
         * This function is used to update the max reminder number value if we send a reminder that was already the max reminder sent
         */
        public updateMaxReminderNumber() {
            if (this.maxReminderNumber <= this.getMaxReminderNumberForAllThirdWithOperations()) {
                this.maxReminderNumber = this.getMaxReminderNumberForAllThirdWithOperations();
            }
        }

    }

    angular.module("app").service("FilterService",  app.functionality.invoiceReminder.services.FilterService);
}