/// <reference path="../../../../_app.ts" />

module app.functionality.invoiceReminder.controllers.modal {

  import ITemplateReminder = app.model.interface.ITemplateReminder;

  /**
   * This modal is used when the user wants to send a test reminder
   * It asks the user for a test email address.
   */

  export class TestModalController  implements angular.IController {

      public thirdNames: string[];
      public template: ITemplateReminder;
      public warningMessage: string;
      public testEmailAddress: string;

      $onInit() {
      }

      static $inject = [
          "thirdNames",
          "template",
          "warningMessage",
          "defaultEmail",
          "$uibModalInstance",
          "$translatePartialLoader"
      ];

      constructor(thirdNames: string[],
                  template: ITemplateReminder,
                  warningMessage: string,
                  defaultEmail: string,
                  public $uibModalInstance,
                  $translatePartialLoader: ngt.ITranslatePartialLoaderService) {

          $translatePartialLoader.addPart("customer/modals");
          this.thirdNames = thirdNames;
          this.template = template;
          this.warningMessage = warningMessage;
          this.testEmailAddress = defaultEmail;
      }

      public cancel() {
          this.$uibModalInstance.close(undefined);
          //this.$uibModalInstance.dismiss();
      }

      public confirm() {
          this.$uibModalInstance.close(this.testEmailAddress);
      }

  }

  //noinspection TypeScriptValidateTypes
  angular.module("app").controller("TestModalController", app.functionality.invoiceReminder.controllers.modal.TestModalController);
}