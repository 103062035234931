/// <reference path="../../../_app.ts" />

module app.functionality.common.modals {
  import SessionService = app.functionality.common.session.SessionService;

  export class SupportModalController {
    public description: string;

    private accFirmIsFrench: boolean;

    static $inject = [
      "$uibModalInstance",
      "SessionService",
      "Notification",
      "$translate",
      "TicketService",
    ];

    public messageBody: string;

    constructor(
      public $uibModalInstance: any,
      private sessionService: SessionService,
      private notification: any,
      private $translate: ngt.ITranslateService,
      private ticketService: app.functionality.ticket.TicketService
    ) {
      this.initDescription();
    }

    public initDescription() {
      if (
        this.sessionService.session.company &&
        this.sessionService.session.company.country &&
        this.sessionService.session.company.country.toLowerCase() === "france"
      ) {
        this.description =
          this.$translate.instant("MODAL.SUPPORT.skwarelDescription") +
          " " +
          "+33 1 76 38 01 21" +
          ".";
      } else {
        this.description =
          this.$translate.instant("MODAL.SUPPORT.skwarelDescription") +
          " " +
          "+32 2 808 70 95" +
          ".";
      }
    }

    public close() {
      this.$uibModalInstance.close();
    }

    public sendMessage() {
      let self = this;
      let sessionID = self.sessionService.session.sessionID;

      self.ticketService
        .sendFeedback(sessionID, self.messageBody)
        .then(function () {
          self.notification(
            self.$translate.instant("NOTIFICATIONS.FEEDBACK.success")
          );
        })
        .catch(function (error) {
          self.notification.error(
            self.$translate.instant("NOTIFICATIONS.FEEDBACK.error")
          );
        });
      this.$uibModalInstance.close();
    }
  }
}

angular
  .module("app")
  .controller(
    "SupportModalController",
    app.functionality.common.modals.SupportModalController
  );
