/// <reference path="../../_app.ts" />

module app.functionality.liquidity {
  
  export class LiquidityService {
    private rest: restangular.IService;
    
    static $inject =  ["RestService", "RESTAPI"];
    
    constructor(restService: app.functionality.common.rest.RestService, private RESTAPI: app.config.constants.ConstantRestApi) {
      this.rest = restService.getRoot();
    }
    
    getLiquidityByCompanyKey = (companyKey: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.base).one("companyKey", companyKey).get();
    }
    
    getPurchaseByCompanyKey = (companyKey: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.vat.purchase).one("companyKey", companyKey).get();
    }

    getPurchaseByBookyear = (companyKey: string, bookyear: number, trimester: number): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.vat.purchase).one("companyKey", companyKey)
          .one("bookyear", bookyear).one("trimester", trimester).get();
    }

    getSaleByCompanyKey = (companyKey: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.vat.sale).one("companyKey", companyKey).get();
    }

    getSaleByBookyear = (companyKey: string, bookyear: number, trimester: number): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.vat.sale).one("companyKey", companyKey)
          .one("bookyear", bookyear).one("trimester", trimester).get();
    }

    getAccountingEntry = (companyKey: string, transactionId: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.accountingEntry)
          .one("companyKey", companyKey)
          .one("transaction", transactionId).get();
    }

    getDashboardLiquidity = (sessionID: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.controller)
          .all(this.RESTAPI.services.liquidity.dashboardLiquidity)
          .one(sessionID).get();
    }

    getCustomerGlobalBalance = (sessionID: string, intervalArr: any): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.customer)
          .all(this.RESTAPI.services.liquidity.getCustomerGlobalBalance)
          .one(sessionID + "&" + intervalArr).get();
    }

    getSupplierGlobalBalance = (sessionID: string, intervalArr: any): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.supplier)
          .all(this.RESTAPI.services.liquidity.getSupplierGlobalBalance)
          .one(sessionID + "&" + intervalArr).get();
    }

    getTopNCustomerDebt = (sessionID: string,  topN: any): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.customer)
          .all(this.RESTAPI.services.liquidity.getTopNCustomerDebt)
          .one(sessionID + "&" + topN).get();
    }

    getTopNSupplierDebt = (sessionID: string,  topN: any): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.supplier)
          .all(this.RESTAPI.services.liquidity.getTopNSupplierDebt)
          .one(sessionID + "&" + topN).get();
    }

    getVATSolde = (sessionID: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.VATMgmt)
          .all(this.RESTAPI.services.liquidity.getVATSolde)
          .one(sessionID).get();
    }

    getCustomerBalance = (sessionID: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.customer)
          .all(this.RESTAPI.services.liquidity.getCustomerBalance)
          .one(sessionID).get();
    }

    getSupplierBalance = (sessionID: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.supplier)
          .all(this.RESTAPI.services.liquidity.getSupplierBalance)
          .one(sessionID).get();
    }

    getAllVATPeriod = (sessionID: string): ng.IPromise<any> => {
      return this.rest.all(this.RESTAPI.services.liquidity.VATMgmt)
          .all(this.RESTAPI.services.liquidity.getAllVATPeriod)
          .one(sessionID).get();
    }

    getDetailVatOperation = (sessionID: string, typeOfVat: number, pArrIndex: any): ng.IPromise<any> => {

      let pArrIndexRest = [];
      let pArrBookyear = [];
      if(pArrIndex !== null && pArrIndex !== undefined) {
          for (let i = 0; i < pArrIndex.length; i++) {
            pArrIndexRest.push(pArrIndex[i].periodIndex);
            pArrBookyear.push(pArrIndex[i].bookyear);
          }
      }

      return this.rest.all(this.RESTAPI.services.liquidity.VATMgmt)
          .all(this.RESTAPI.services.liquidity.getDetailVatOperation)
          .one(sessionID + "&" + typeOfVat + "&[" + pArrBookyear + "]&[" + pArrIndexRest + "]&0&0").get();
    }
    
  }
}

angular.module("app").service("LiquidityService",  app.functionality.liquidity.LiquidityService);