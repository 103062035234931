/// <reference path="../../_app.ts" />

module app.model.exchange {
    import EntryAcc = app.model.exchange.EntryAcc;

    export class GroupAccount {

        classStructure : any = {
            accountArr: {
                array: app.model.exchange.EntryAcc
            }
        };
        public solde: number;
        public accountArr: Array<EntryAcc>;
        public groupName: String;
        public keyLanguage: String;

        constructor() {
        }
    }
}