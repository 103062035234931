/// <reference path="../../../_app.ts" />

namespace app.functionality.upload {
  import Invoice = app.model.Invoice;
  import UploadService = app.functionality.upload.UploadService;

  export class InvoiceNoteModalController {
    private note: string;
    private selectedDocument: Invoice;

    static $inject = [
      "$uibModalInstance",
      "r_data",
      "$http",
      "UploadService",
      "Notification",
      "$translate",
    ];

    constructor(
      public $uibModalInstance: any,
      r_data: any,
      protected $http: any,
      private uploadService: UploadService,
      private notification: any,
      private $translate: ngt.ITranslateService
    ) {
      if (r_data) {
        this.selectedDocument = r_data.selectedDocument;
      }
      if (this.selectedDocument.note) {
        this.note = this.selectedDocument.note;
      } else {
        this.note = "";
      }
    }

    add() {
      let self = this;
      if (this.note !== this.selectedDocument.note) {
        if (this.note === "") {
          this.uploadService
            .deleteNote(this.selectedDocument.key)
            .then((response) => {
              this.selectedDocument.note = undefined;
              this.note = undefined;
              this.notification.primary(
                this.$translate.instant("NOTIFICATIONS.UPLOAD.deletedNote")
              );
              return;
            })
            .catch((error) => {});
        } else {
          this.uploadService
            .updateNote(this.selectedDocument.key, encodeURI(this.note))
            .then((data) => {
              if (data.plain().status === 0) {
                //Update note
                this.selectedDocument.note = this.note;
                this.notification.primary(
                  this.$translate.instant("NOTIFICATIONS.UPLOAD.savedNote")
                );
              } else {
                this.notification.error(
                  this.$translate.instant("NOTIFICATIONS.UPLOAD.errorOnNote")
                );
                throw new Error(status);
              }
            })
            .catch((error) => {});
        }
      }
      this.cancel();
    }
    cancel() {
      this.$uibModalInstance.close();
    }
  }
}

//noinspection TypeScriptValidateTypes
angular
  .module("app")
  .controller(
    "invoiceNoteModalController",
    app.functionality.upload.InvoiceNoteModalController
  );
