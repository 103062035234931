/// <reference path="../../_app.ts" />

module app.functionality.ticket {

    export class FileChangeDirective implements ng.IDirective {

        restrict = "A";

        link = (scope: ng.IScope, elem: ng.IAugmentedJQuery, attrs: ng.IAttributes, ctrl: any) => {

            // Get the function provided in the file-change attribute.
            // Note the attribute has become an angular expression,
            // which is what we are parsing. The provided handler is
            // wrapped up in an outer function (attrHandler) - we'll
            // call the provided event handler inside the handler()
            // function below.

            let key = "fileChange";

            let attrHandler = this.$parse(attrs[key]);

            // This is a wrapper handler which will be attached to the
            // HTML change event.
            let handler = function (e) {

                scope.$apply(function () {
                    // Execute the provided handler in the directive's scope.
                    // The files variable will be available for consumption
                    // by the event handler.
                    attrHandler(scope, { $event: e, files: e.target.files });
                });
            };

            // Attach the handler to the HTML change event
            elem[0].addEventListener("change", handler, false);
        }

        constructor(public $parse: ng.IParseService) {
        }

        static factory(): ng.IDirectiveFactory {
            const directive = ($parse: ng.IParseService) => new FileChangeDirective($parse);
            directive.$inject = ["$parse"];

            return directive;
        }
    }
}

angular.module("app").directive("fileChange", app.functionality.ticket.FileChangeDirective.factory());
