/// <reference path="../../_app.ts" />

module app.functionality.profit {
  import Exercice = app.model.Exercice;
  // import Period = app.model.Period;
  import SessionService = app.functionality.common.session.SessionService;
  import PNLColumnConfig = app.model.parameter.PNLColumnConfig;
  import Period = app.model.Period;

  export class ComparisonColumnModalController implements angular.IController {
    $onInit() {}

    public context: any;
    public columnSelected: any;
    public titleLabel: any;
    public selected: any;
    public type: any;
    public colList: any;

    // exercice select
    public exerciceArr: any;
    public exerciceSelected: Exercice;

    // month/period select
    public periodArr: any;
    public periodSelected: any;

    // quarter select
    public quarterlyArr: any;
    public quarterlySelected: any;

    // select which column to compare
    public compareToList: any;
    public compareToSelected: any = "";

    // first row selection
    public exerciceButton: boolean = false;
    public quarterlyButton: boolean = false;
    public periodButton: boolean = false;

    // second row selection
    public inProgressButton: boolean = false;
    public fixedButton: boolean = false;

    //openingPeriodCheckBox
    public showAddSpecialPeriod: boolean = false;
    public addSpecialPeriodSelected: boolean = true;
    public addSpecialPeriodLabel: string;

    public isQuarterly: boolean = false;

    public btnLabel: string;

    static $inject = [
      "$uibModalInstance",
      "r_context",
      "r_type",
      "SessionService",
      "$translate",
      "$filter",
    ];

    constructor(
      private $uibModalInstance: any,
      r_context: any,
      r_type: any,
      public sessionService: SessionService,
      private $translate: ngt.ITranslateService,
      public $filter: any
    ) {
      if (angular.isDefined(r_context)) {
        this.context = r_context;

        if (angular.isDefined(this.context.colList)) {
          this.compareToList = this.context.colList;
        } else {
          this.compareToList = this.context;
        }
      }

      if (angular.isDefined(r_type)) {
        this.type = r_type;
      }

      this.initFilters();

      // this.setOptions();
      if (this.isEdit()) {
        this.btnLabel = "COMPARISON.MODAL.ADDCOLUMN.editBtn";
        this.titleLabel = "COMPARISON.MODAL.ADDCOLUMN.titleEdit";
        this.columnSelected = this.context.colSelected;
        this.initButtonsForEdit();
      } else {
        this.titleLabel = "COMPARISON.MODAL.ADDCOLUMN.titleAdd";
        this.btnLabel = "COMPARISON.MODAL.ADDCOLUMN.addBtn";
        this.showAddSpecialPeriod = true;
      }

      this.updateSpecialPeriod();
    }

    private isEdit() {
      return this.type === "edit";
    }

    private initButtonsForEdit() {
      // fixed or inProgress buttons init
      if (this.columnSelected.currentViewEnabled) {
        this.inProgressButton = true;
        this.fixedButton = false;
      } else {
        this.fixedButton = true;
        this.inProgressButton = false;
      }

      this.exerciceArr = this.sessionService.session.company.exerciceArr;

      for (let exercice of this.exerciceArr) {
        if (this.columnSelected.bookyear === exercice.bookyear) {
          this.exerciceSelected = exercice;
          break;
        }
      }

      this.periodArr = this.getPeriodsArr(this.exerciceSelected);
      this.periodSelected = this.periodArr[0];

      this.quarterlyArr = this.getPeriodsArr(this.exerciceSelected, true);
      this.quarterlySelected = this.quarterlyArr[0];

      // buttons init and exercice/quarter/month select init
      if (this.columnSelected.typeView === 1) {
        this.exerciceButton = true;
        this.addSpecialPeriodSelected = false;
        this.showAddSpecialPeriod = false;
      } else if (this.columnSelected.typeView === 2) {
        this.quarterlyButton = true;
        if (this.fixedButton) {
          for (let quarter of this.quarterlyArr) {
            let found = false;
            for (let i: number = 0; i < quarter.periodArr.length; i++) {
              let period: Period = quarter.periodArr[i];
              for (
                let j: number = 0;
                j < this.columnSelected.periodArr.length;
                j++
              ) {
                if (this.columnSelected.periodArr[j] === period.periodIndex) {
                  found = true;
                  break;
                }
              }
            }
            if (found) {
              if (this.columnSelected.periodArr.length > 3) {
                this.addSpecialPeriodSelected = true;
                this.showAddSpecialPeriod = true;
              } else {
                this.addSpecialPeriodSelected = false;
                this.showAddSpecialPeriod = false;
              }
              this.quarterlySelected = quarter;
              break;
            }
          }
        }
      } else if (this.columnSelected.typeView === 3) {
        this.periodButton = true;
        if (this.fixedButton) {
          for (let i: number = 0; i < this.periodArr.length; i++) {
            let period: Period = this.periodArr[i];
            let found = false;
            for (
              let j: number = 0;
              j < this.columnSelected.periodArr.length;
              j++
            ) {
              if (this.columnSelected.periodArr[j] === period.periodIndex) {
                this.periodSelected = period;
                break;
              }
            }
            if (found) {
              if (this.columnSelected.periodArr.length > 1) {
                this.addSpecialPeriodSelected = true;
                this.showAddSpecialPeriod = true;
              } else {
                this.addSpecialPeriodSelected = false;
                this.showAddSpecialPeriod = false;
              }
              break;
            }
          }
        }
      }

      // compareTo select init
      if (this.columnSelected.key_PNLColumnConfigCompared !== null) {
        for (let column of this.compareToList) {
          if (column.key === this.columnSelected.key_PNLColumnConfigCompared) {
            this.compareToSelected = column;
            break;
          }
        }
      }
    }

    public initFilters() {
      //to init the selections available on the two filters
      this.exerciceArr = this.sessionService.session.company.exerciceArr;
      this.exerciceSelected = this.exerciceArr[0];

      if (this.exerciceSelected.isQuarterlyMode) {
        this.isQuarterly = true;
      }

      this.periodArr = this.getPeriodsArr(this.exerciceSelected);
      this.periodSelected = this.periodArr[0];
      this.quarterlyArr = this.getPeriodsArr(this.exerciceSelected, true);
      this.quarterlySelected = this.quarterlyArr[0];
    }

    public updateFilters() {
      this.periodArr = this.getPeriodsArr(this.exerciceSelected);
      this.periodSelected = this.periodArr[0];
      this.quarterlyArr = this.getPeriodsArr(this.exerciceSelected, true);
      this.quarterlySelected = this.quarterlyArr[0];
    }

    public updateFirstRowSelection(clickedButton) {
      if (clickedButton === "exercice") {
        this.exerciceButton = true;
        this.quarterlyButton = false;
        this.periodButton = false;
      } else if (clickedButton === "quarterly") {
        this.exerciceButton = false;
        this.quarterlyButton = true;
        this.periodButton = false;
      } else if (clickedButton === "period") {
        this.exerciceButton = false;
        this.quarterlyButton = false;
        this.periodButton = true;
      } else {
        this.exerciceButton = false;
        this.quarterlyButton = false;
        this.periodButton = false;
      }
    }

    public updateSpecialPeriod() {
      if (this.periodButton) {
        let indexSelection = this.periodArr.indexOf(this.periodSelected);
        if (
          indexSelection === 0 ||
          indexSelection === this.periodArr.length - 1
        ) {
          this.addSpecialPeriodSelected = true;
          this.showAddSpecialPeriod = true;
          if (indexSelection === 0) {
            this.addSpecialPeriodLabel = "COMPARISON.MODAL.addOpening";
          } else {
            this.addSpecialPeriodLabel = "COMPARISON.MODAL.addClosing";
          }
        } else {
          this.addSpecialPeriodSelected = false;
          this.showAddSpecialPeriod = false;
        }
      } else if (this.quarterlyButton) {
        let indexSelection = this.quarterlyArr.indexOf(this.quarterlySelected);
        if (
          indexSelection === 0 ||
          indexSelection === this.quarterlyArr.length - 1
        ) {
          this.addSpecialPeriodSelected = true;
          this.showAddSpecialPeriod = true;
          if (indexSelection === 0) {
            this.addSpecialPeriodLabel = "COMPARISON.MODAL.addOpening";
          } else {
            this.addSpecialPeriodLabel = "COMPARISON.MODAL.addClosing";
          }
        } else {
          this.addSpecialPeriodSelected = false;
          this.showAddSpecialPeriod = false;
        }
      }
    }

    public updateSecondRowSelection(clickedButton) {
      if (clickedButton === "inProgress") {
        this.inProgressButton = true;
        this.fixedButton = false;
      } else if (clickedButton === "fixed") {
        this.inProgressButton = false;
        this.fixedButton = true;
      } else {
        this.inProgressButton = false;
        this.fixedButton = false;
      }
    }

    private getPeriodsArr(ex: Exercice, forceQuarterly?): any {
      let copy = [];

      if (forceQuarterly) {
        _.forEach(ex.quarterlyArr, function (q) {
          copy.push(q);
        });
      } else {
        _.forEach(ex.periodArr, function (p) {
          copy.push(p);
        });
      }
      return copy;
    }

    ok = () => {
      // process the form and send the data to generate new column
      let newPnlColumn = new PNLColumnConfig();

      if (this.isEdit()) {
        newPnlColumn.indexPosition = this.context.colSelected.indexPosition;
        newPnlColumn.key = this.context.colSelected.key;
      } else {
        newPnlColumn.indexPosition = this.compareToList.length + 1;
      }

      newPnlColumn.dataSource = 1; // always on account
      newPnlColumn.currentViewEnabled = !this.fixedButton; // false = fixe, true = en cours
      newPnlColumn.bookyear = this.exerciceSelected.bookyear;

      // put some logic to generate the keys
      if (this.exerciceButton) {
        newPnlColumn.typeView = 1;
        if (this.inProgressButton == true) {
          newPnlColumn.title = this.$translate.instant(
            "COMPARISON.exInProgress"
          );
          newPnlColumn.bookyear = 0;
          newPnlColumn.computedBookyear = 0;
        } else {
          newPnlColumn.periodArr = this.convertToPeriodArr(
            this.getPeriodsArr(this.exerciceSelected)
          );
          newPnlColumn.title = this.exerciceSelected.title;
        }

        // loop through those cat and delete the useless numbers
      } else if (this.quarterlyButton) {
        // newPnlColumn.title = this.quarterlySelected;
        newPnlColumn.typeView = 2;
        if (this.inProgressButton == true) {
          newPnlColumn.title = this.$translate.instant(
            "COMPARISON.trimInProgress"
          );
          newPnlColumn.bookyear = 0;
          newPnlColumn.computedBookyear = 0;
        } else {
          let index = this.quarterlyArr.indexOf(this.quarterlySelected);
          newPnlColumn.periodArr = this.convertToPeriodArr(
            this.quarterlySelected.periodArr
          );
          if (this.addSpecialPeriodSelected) {
            if (index === 0) {
              newPnlColumn.periodArr.push(
                this.exerciceSelected.openingPeriod.periodIndex
              );
            } else if (index === this.quarterlyArr.length - 1) {
              newPnlColumn.periodArr.push(
                this.exerciceSelected.closingPeriod.periodIndex
              );
            }
          }
          newPnlColumn.title = this.$filter("quarterlabel")(
            this.quarterlySelected
          );
          // loop through those cat and delete the useless numbers
        }
      } else if (this.periodButton) {
        newPnlColumn.typeView = 3;
        if (this.inProgressButton == true) {
          newPnlColumn.title = this.$translate.instant(
            "COMPARISON.monthInProgress"
          );
          newPnlColumn.bookyear = 0;
          newPnlColumn.computedBookyear = 0;
        } else {
          let index = this.periodArr.indexOf(this.periodSelected);

          newPnlColumn.periodArr = [this.periodSelected.periodIndex];
          if (this.addSpecialPeriodSelected) {
            if (index === 0) {
              newPnlColumn.periodArr.push(
                this.exerciceSelected.openingPeriod.periodIndex
              );
            } else if (index === this.periodArr.length - 1) {
              newPnlColumn.periodArr.push(
                this.exerciceSelected.closingPeriod.periodIndex
              );
            }
          }
          newPnlColumn.title = this.$filter("periodlabel")(this.periodSelected);
        }
      }

      if (
        this.compareToSelected !== "" &&
        this.compareToSelected !== null &&
        this.compareToSelected !== undefined
      ) {
        newPnlColumn.key_PNLColumnConfigCompared = this.compareToSelected.key;
      }
      this.$uibModalInstance.close(newPnlColumn);
    };

    cancel = () => {
      this.$uibModalInstance.dismiss("cancel EditOrArr");
    };

    // function used to get the good format of periodArr sended to the server
    private convertToPeriodArr(data) {
      let periodArr: number[] = [];
      for (let period of data) {
        if (
          this.exerciceButton ||
          period.periodIndex !== -1 ||
          period.periodIndex !== 99
        ) {
          periodArr.push(period.periodIndex);
        }
      }
      return periodArr;
    }
  }
}

angular
  .module("app")
  .controller(
    "ComparisonColumnModalController",
    app.functionality.profit.ComparisonColumnModalController
  );
