/// <reference path="../../../_app.ts" />

module app.functionality.common.converter {
  export class ProfitConverter {
    static $inject = ["SessionService", "AccountTools", "$filter"];

    private dashboardParam: any; //todo, instantiate with model
    public dataPassive: any;

    constructor(
      private sessionService: app.functionality.common.session.SessionService,
      private accountTools: app.functionality.common.utils.AccountTools,
      private $filter: ng.IFilterService
    ) {
      this.dashboardParam = this.sessionService.session.company.dashboardParam;
    }

    public getAccountName(numero, accountInfoArr): string {
      let result = "";
      _(accountInfoArr).forEach(function (accInfo) {
        if (accInfo.numero === numero) {
          result = accInfo.label;
          return false;
        }
      });
      return result;
    }

    public acceptAccount(numero, category): boolean {
      if (category.rejectedAccounts) {
        for (let account of category.rejectedAccounts) {
          /*
                 Does this number is include in the root account
                 i.e : numero 621100 & root 62 OK
                 i.e : numero 645100 & root 62 KO
                 */
          if (this.accountTools.isIncludeInRoot(numero, account)) {
            return false;
          }
        }
      }

      for (let account of category.accountArr) {
        /*
                 Does this number is include in the root account
                 i.e : numero 621100 & root 62 OK
                 i.e : numero 645100 & root 62 KO
                 */
        if (this.accountTools.isIncludeInRoot(numero, account)) {
          return true;
        }
      }

      return false;
    }

    // check if category has a subCategory defined
    private hasSubCategory(cat) {
      return (
        angular.isDefined(cat.subCategory) &&
        cat.subCategory !== null &&
        cat.subCategory.length > 0
      );
    }

    private isAccountOfTheRightType(
      accountNumber: number,
      accountSolde: number,
      type: number
    ): boolean {
      switch (type) {
        case 0:
          return this.accountTools.isActive(accountNumber, accountSolde);
        case 1:
          return this.accountTools.isPassive(accountNumber, accountSolde);
        case 2:
          return this.accountTools.isPnl(accountNumber);
        default:
          return this.accountTools.isPnl(accountNumber);
      }
    }

    private initCategoryAccordingToType(typeOfConvert: number): any {
      switch (typeOfConvert) {
        case 0:
          return this.initCategoryTable(
            this.dashboardParam.categoryActiveBalanceSheetArr
          );
        case 1:
          return this.initCategoryTable(
            this.dashboardParam.categoryPassiveBalanceSheetArr
          );
        case 2:
          return this.initCategoryTable(this.dashboardParam.categoryIncomeArr);
        default:
          return this.initCategoryTable(this.dashboardParam.categoryIncomeArr);
      }
    }

    private initAccountRow(
      account: any,
      columnIndex: number,
      isComparison: boolean,
      accountInfoArr: any
    ): any {
      if (!this.accountTools.isActive(account.numero, account.solde)) {
        //we reverse the sign of the amount only for account in passive and pnl
        account.solde = -account.solde;
      }

      let accountName: string = this.getAccountName(
        account.numero,
        accountInfoArr
      );

      let accountRow = {
        label: accountName,
        accNum: account.numero,
        solde: account.solde,
      };

      if (isComparison) {
        // adding the key index (key1, key2...) for column display
        let keyIndex = "key" + columnIndex;
        accountRow[keyIndex] = account.solde;
      }

      return accountRow;
    }

    convertBalance = (dataForBalanceView: any, typeOfConvert: number): any => {
      let dataBalance: any = this.initCategoryAccordingToType(typeOfConvert);

      //for every line of data from the server
      for (let account of dataForBalanceView.accountArr) {
        let precision: number = 0.001;
        let accountHasSufficientAmount: boolean =
          Math.abs(account.solde) > precision;
        // check if account contains at least 0.1€
        if (
          accountHasSufficientAmount &&
          this.isAccountOfTheRightType(
            account.numero,
            account.solde,
            typeOfConvert
          )
        ) {
          let accountRow = this.initAccountRow(
            account,
            1,
            false,
            dataForBalanceView.accountInfoArr
          );

          // loop on every row currently in the grid
          let i: number = 0;
          while (i < dataBalance.length) {
            let cat = dataBalance[i];
            if (
              angular.isDefined(cat.type) &&
              this.acceptAccount(account.numero, cat)
            ) {
              // we are sure that the row is a category and that the cat accept the account
              // i is updated with the next row index to continue insert after the category
              i = this.insertAccountIntoCategory(
                accountRow,
                cat,
                dataBalance,
                i
              );
            } else if (angular.isDefined(cat.type)) {
              // cat doesn't accept the account
              i = i + cat.nbAccInserted + cat.nbSubCat + 1; // jump directly to the next category
            } else {
              i++;
              /*console.error("index ="+ i);
                            console.error(accountRow);
                            console.error(cat);*/
            }
          }
        }
      }

      return dataBalance;
    };

    /**
     * This function insert an account into the appropriate category
     * @param accountRow the account formatted to display in the grid
     * @param category a category that accept the account
     * @param dataBalance the data structure to insert account in
     * @param categoryIndex index of the category in the dataBalance
     * @param columnIndex a number that identify the column
     * @param columns
     * @returns {number} index of the next category in the datastructure
     */
    private insertAccountIntoCategory(
      accountRow: any,
      category: any,
      dataBalance: any,
      categoryIndex: number,
      columnIndex: number = 0,
      columns: any = null
    ): number {
      let isAlreadyInserted = this.isAlreadyInsert(accountRow);
      if (category.type !== 3 && !isAlreadyInserted) {
        // we do not insert account into a total category
        this.updateCategoryInfo(accountRow, columnIndex, columns, category);
        if (!this.hasSubCategory(category)) {
          // we can insert the accountRow directly into the category BASE CASE
          // insert account under all previously inserted account
          let insertIndex = categoryIndex + category.nbAccInserted;
          this.insertAccountRow(
            insertIndex,
            accountRow,
            dataBalance,
            category,
            categoryIndex,
            columnIndex,
            columns
          );
          return insertIndex + 1;
        } else {
          // we need to check trough all subCategory to insert the accountRow RECURSIVE CASE
          let maxIndex: number =
            categoryIndex + category.nbAccInserted + category.nbSubCat;
          let subCatIndex: number = categoryIndex + 1;
          let isInserted: boolean = false;
          while (subCatIndex < maxIndex) {
            let subCat = dataBalance[subCatIndex];
            if (
              angular.isDefined(subCat.type) &&
              this.acceptAccount(accountRow.accNum, subCat)
            ) {
              this.insertAccountIntoCategory(
                accountRow,
                subCat,
                dataBalance,
                subCatIndex,
                columnIndex,
                columns
              );
              // we return maxIndex +1  because do not need to look further into the other subCat
              // so we can jump directly to another category
              return maxIndex + 1;
            } else {
              subCatIndex =
                subCatIndex + subCat.nbAccInserted + subCat.nbSubCat + 1;
            }
          }

          if (!isInserted) {
            this.insertAccountRow(
              maxIndex,
              accountRow,
              dataBalance,
              category,
              categoryIndex,
              columnIndex,
              columns,
              true
            );
          }
          // the account has been inserted into the "mother" category
          return maxIndex + 1;
        }
      } else if (isAlreadyInserted) {
        if (category.type === 3) {
          this.updateCategoryInfo(accountRow, columnIndex, columns, category);
        }

        // if account is already inserted into a previous category we return the index
        // of the next category to keep walking throught our data structure
        return categoryIndex + category.nbAccInserted + category.nbSubCat + 1;
      } else {
        return categoryIndex + 1;
      }
    }

    private isAlreadyInsert(accountRow: any): boolean {
      //if we have a labelIndentation, it means that we have already inserted the account into a category
      let labelIndentation: string = "labelIndentation";
      return angular.isDefined(accountRow[labelIndentation]);
    }

    private updateCategoryInfo(
      accountRow: any,
      columnIndex: number,
      columns: any,
      category: any
    ) {
      category.solde += accountRow.solde;

      if (columnIndex > 0) {
        let keyIndex = "key" + columnIndex;
        category[keyIndex] += accountRow.solde;

        let accountFound: boolean = false;
        for (let account of category.accountInserted) {
          if (account === accountRow.accNum) {
            accountFound = true;
            break;
          }
        }

        if (!accountFound) {
          category.accountInserted.push(accountRow.accNum);
          if (category.type !== 3) {
            category.nbAccInserted = category.nbAccInserted + 1;
          }
        }

        // compute comparison for sums
        let actualColumn = columns[columnIndex - 1];
        if (angular.isDefined(actualColumn)) {
          if (actualColumn.key_PNLColumnConfigCompared !== null) {
            let targetColumnField =
              "key" +
              this.getColumnIndexPosition(
                columns,
                actualColumn.key_PNLColumnConfigCompared
              );
            let comparisonField = "comparison" + columnIndex;

            let amountCompared = category[targetColumnField];
            let amount = category[keyIndex];

            category[comparisonField] = this.computeVariation(
              amount,
              amountCompared
            );
          }
        }

        for (let otherColumn of columns) {
          if (
            otherColumn.key_PNLColumnConfigCompared !== null &&
            otherColumn.key_PNLColumnConfigCompared === actualColumn.key
          ) {
            let targetColumnField = "key" + otherColumn.indexPosition;
            let otherColumnComparisonIndex =
              "comparison" + otherColumn.indexPosition;

            let amount = category[targetColumnField];
            let amountCompared = category[keyIndex];

            category[otherColumnComparisonIndex] = this.computeVariation(
              amount,
              amountCompared
            );
          }
        }
      } else {
        category.accountInserted.push(accountRow.accNum);
        if (category.type !== 3) {
          category.nbAccInserted = category.nbAccInserted + 1;
        }
      }
    }

    private insertAccountRow(
      indexOfInsert: number,
      accountRow: any,
      dataStructure,
      category: any,
      categoryIndex: number,
      columnIndex: number,
      columns: any,
      insertInParentCat: boolean = false
    ) {
      let treeLevel = "$$treeLevel";
      let depth = category[treeLevel] + 1;

      let labelIndentation: string = "labelIndentation"; // to add padding in UI for account
      accountRow[labelIndentation] = depth;

      if (insertInParentCat) {
        // Using attribute to insert the account correctly so that ui-grid doen't intrerpret
        // this account like include in the last sub category
        accountRow[treeLevel] = depth;
        let accountInParentCat: string = "accountInParentCat";
        accountRow[accountInParentCat] = true;
        accountRow[labelIndentation] = depth - 1;
      }

      if (columnIndex > 0) {
        // comparisonCase
        // row already present in grid, we update it to have the new column data
        let accountIndex = this.findAccountInCategory(
          accountRow.accNum,
          categoryIndex,
          category,
          dataStructure
        );

        let actualColumn = columns[columnIndex - 1];
        if (accountIndex !== -1) {
          // already in grid, we add an attribute to the existing element (we got the index from the filter)
          let keyIndex = "key" + columnIndex;
          dataStructure[accountIndex][keyIndex] = accountRow.solde;

          if (angular.isDefined(actualColumn)) {
            this.updateComparisonValue(
              actualColumn,
              columnIndex,
              columns,
              dataStructure,
              accountIndex,
              keyIndex
            );
          }
        } else {
          // not in grid, we push it
          dataStructure.splice(indexOfInsert, 0, accountRow);
        }
      } else {
        dataStructure.splice(indexOfInsert, 0, accountRow);
      }
    }

    private updateComparisonValue(
      actualColumn: any,
      columnIndex: number,
      columns: any,
      dataStructure: any,
      accountIndex: number,
      keyIndex: string
    ) {
      if (actualColumn.key_PNLColumnConfigCompared !== null) {
        let targetColumnField =
          "key" +
          this.getColumnIndexPosition(
            columns,
            actualColumn.key_PNLColumnConfigCompared
          );
        let comparisonField = "comparison" + columnIndex;

        // get column index in this context
        let amountCompared = dataStructure[accountIndex][targetColumnField];
        let amount = dataStructure[accountIndex][keyIndex];
        dataStructure[accountIndex][comparisonField] = this.computeVariation(
          amount,
          amountCompared
        );
      }

      for (let otherColumn of columns) {
        if (
          otherColumn.key_PNLColumnConfigCompared !== null &&
          otherColumn.key_PNLColumnConfigCompared === actualColumn.key
        ) {
          let targetColumnField = "key" + otherColumn.indexPosition;
          let otherColumnComparisonIndex =
            "comparison" + otherColumn.indexPosition;

          // get column index in this context
          let amount = dataStructure[accountIndex][targetColumnField];
          let amountCompared = dataStructure[accountIndex][keyIndex];
          dataStructure[accountIndex][
            otherColumnComparisonIndex
          ] = this.computeVariation(amount, amountCompared);
        }
      }
    }

    private findAccountInCategory(
      accountNumero: number,
      categoryIndex: any,
      category: any,
      dataStructure
    ): number {
      let endIndex =
        categoryIndex + category.nbAccInserted + category.nbSubCat + 1;
      for (let i = categoryIndex + 1; i < endIndex; i++) {
        let row = dataStructure[i];
        if (!this.isCategory(row) && row.accNum === accountNumero) {
          // Check if the row is an accountRow
          return i;
        }
      }
      return -1;
    }

    private computeVariation(amount: number, amountCompared: number): number {
      if (!angular.isDefined(amountCompared)) {
        amountCompared = 0;
      }

      if (!angular.isDefined(amount)) {
        amount = 0;
      }

      let variation: number = 0;
      if (amountCompared !== 0) {
        variation = ((amount - amountCompared) / amountCompared) * 100;
      }

      return variation;
    }

    private isCategory(value: any): boolean {
      return angular.isDefined(value.type);
    }

    convertComparisonGrid = (comparisonData: any, columnData: any): any => {
      // for each column, do a convertIncomeStatement
      let dataIncome = this.initCategoryTable(
        this.dashboardParam.categoryIncomeArr,
        comparisonData.length
      );

      for (let i = 0; i < comparisonData.length; i++) {
        this.convertComparisonColumn(
          comparisonData[i],
          dataIncome,
          i + 1,
          columnData
        );
      }
      return dataIncome;
    };

    convertComparisonColumn = (
      balanceData: any,
      dataIncome: any,
      columnIndex: number,
      columns: any
    ): any => {
      for (let account of balanceData.accountArr) {
        let precision: number = 0.001; // NOTE :
        let accountHasSufficientAmount: boolean =
          Math.abs(account.solde) > precision;
        if (
          accountHasSufficientAmount &&
          this.accountTools.isPnl(account.numero)
        ) {
          let accountRow = this.initAccountRow(
            account,
            columnIndex,
            true,
            balanceData.accountInfoArr
          );
          // for every category
          let i: number = 0;
          while (i < dataIncome.length) {
            // cat is the current category
            let cat = dataIncome[i];
            // if it is a category
            if (
              angular.isDefined(cat.type) &&
              this.acceptAccount(account.numero, cat)
            ) {
              // we are sure that the row is a category and that the cat accept the account
              // i is updated with the next row index to continue insert after the category
              i = this.insertAccountIntoCategory(
                accountRow,
                cat,
                dataIncome,
                i,
                columnIndex,
                columns
              );
            } else if (angular.isDefined(cat.type)) {
              // cat doesn't accept the account
              i = i + cat.nbAccInserted + cat.nbSubCat + 1; // jump directly to the next category
            }
          }
        }
      }
      return dataIncome;
    };

    private getColumnIndexPosition(columns, targetColumnKey): string {
      for (let column of columns) {
        if (column.key === targetColumnKey) {
          return column.indexPosition;
        }
      }
    }

    /**
     * This method is used to initialized all the category that we received
     * from the backend to :
     *          - Add an attribute 'treelevel' that is used to apply a css class on the row in ui-grid to show
     *          - Add an attribute comparison if nbrColumn > 0
     *          - Add an attribute key(i) to identify the column
     * a 'chevron' in the grid
     * @param categoryDefinitionArr all the category that should be initialized
     * @param nbrColumn the amount of column to initialize
     * @returns {Array}
     */
    public initCategoryTable(categoryDefinitionArr, nbrColumn: number = 0) {
      let self = this;
      let output = [];
      let depth = 0;

      _(categoryDefinitionArr).forEach(function (cat) {
        self.initSubCat(cat, depth, output, nbrColumn);
        depth = 0;
      });

      return output;
    }

    public initSubCat(cat, depth, output, nbrColumn): number {
      let self = this;

      let newArg = "$$treeLevel";
      cat[newArg] = depth;

      for (let i = 1; i < nbrColumn + 1; i++) {
        let keyIndex = "key" + i;
        cat[keyIndex] = 0;
      }

      for (let j = 1; j < nbrColumn + 1; j++) {
        let comparisonIndex = "comparison" + j;
        cat[comparisonIndex] = 0;
      }

      let nbAccInserted = "nbAccInserted";
      cat[nbAccInserted] = 0;

      let nbSubCat = "nbSubCat";
      cat[nbSubCat] = 0;

      let solde = "solde";
      cat[solde] = 0;

      let accountInserted = "accountInserted";
      cat[accountInserted] = [];

      output.push(cat);

      if (cat.subCategory != null) {
        depth++;
        cat.nbSubCat = cat.subCategory.length;
        _(cat.subCategory).forEach(function (subCategory) {
          cat.nbSubCat += self.initSubCat(
            subCategory,
            depth,
            output,
            nbrColumn
          );
        });

        return cat.nbSubCat;
      }

      return 0;
    }
  }
}

angular
  .module("app")
  .service(
    "ProfitConverter",
    app.functionality.common.converter.ProfitConverter
  );
