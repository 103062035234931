/// <reference path="../../_app.ts" />
namespace app.functionality.IPPForm {
  import Attachement = app.model.Attachement;
  import Member = app.model.Member;

  export class IPPFormService implements angular.IController {
    static $inject = [
      "RestService",
      "RESTAPI",
      "$state",
      "SessionService",
      "$stateParams",
      "formlyConfig",
      "ModalService",
      "$uibModal",
      "Notification",
      "$window",
      "ENV",
      "LoggerService",
      "$translate",
    ];
    public nbrOfStep;
    public requiredFields;
    public data;
    public model;
    public readingMode: boolean;
    public validationMode: boolean;
    // flag used to toggle new input, when adding new element via modal, during validation mode
    public addNewElementDuringValidationMode: boolean = false;
    public activeStep;
    public firstInvalidStep;
    public fileNameMap: any;
    // validator boolean, that gets triggered when the user has already been to the last step
    public finalisationMode: boolean = false;
    // the table containing all attachements file keys
    public attachementKeyList = [];
    public subViewFields = [];
    // the table with all the missing annexes that we show to the user when he submit the forms.
    public missingAnnexesList = [];
    // little condition to only show the accounter modal once
    public alreadyCheckedAsAnAccounter = false;
    public isAccounter: boolean;
    private rest: restangular.IService;
    private sessionService: app.functionality.common.session.SessionService;
    private mapUploadError: any;
    public m: Member;

    constructor(
      restService: app.functionality.common.rest.RestService,
      private RESTAPI: app.config.constants.ConstantRestApi,
      private $state: ng.ui.IStateService,
      sessionService: app.functionality.common.session.SessionService,
      protected $stateParams: ng.ui.IStateParamsService,
      public formlyConfig: AngularFormly.IFormlyConfig,
      private modalService: app.functionality.common.modals.ModalService,
      private $uibModal: any,
      public notification: any,
      public $window: angular.IWindowService,
      private ENV: app.config.constants.environment.ConstantEnv,
      public logger: app.functionality.common.logger.LoggerService,
      public $translate: ngt.ITranslateService
    ) {
      this.rest = restService.getRoot();
      this.sessionService = sessionService;
      //variable qui permet de récupérer le parametre literalAccess dans l'url => Voir la variable activeStep
      let literalAccess = "step"; // to pass ts lint
      this.activeStep = $stateParams[literalAccess];

      this.isAccounter =
        this.sessionService.session.member.accesLevel !== 0 ? true : false;
      this.m = sessionService.getCurrentSessionMember();

      this.initDataVar().then(() => {
        this.initRequiredFields();
        this.nbrOfStep = 900;
        this.fileNameMap = {};
        this.fileNameMap.size = 0;
        let self = this;

        this.mapUploadError = {};
        this.mapUploadError.size = 0;

        this.getAttachementKeyList().then((data) => {
          this.attachementKeyList = data.plain();
        });

        this.getSubViewFields().then((data) => {
          this.subViewFields = data.plain();
        });

        /**
         * Config relative à la mise en page
         */
        formlyConfig.extras.errorExistsAndShouldBeVisibleExpression =
          "fc.$touched && fc.$invalid";
        formlyConfig.disableWarnings = true;
        formlyConfig.setWrapper({
          overwriteOk: true,
          name: "horizontalBootstrap",
          template: `
					<!-- label du field -->
                    <label for='{{::id}}'
						   class='styleLabelIPPForm control-label'
							ng-class="{'ipp-reading-mode-label': ${self.readingMode}}">
					    {{to.label}}{{to.required ? '*' : ''}}
					</label>
					<!-- tooltip du field, si présent -->
					<i ng-if="to.tooltip != null"
                        class="control-label-help glyphicon glyphicon-question-sign"
                        uib-tooltip="{{to.tooltip.content}}"
                        tooltip-is-open="true">
                    </i>
					<!-- description si il y en a une -->
                    <description ng-show='to.descriptions != null' ng-if="!${self.readingMode} && ${self.data.status_declaration} !== 5"
                                 class='styleDescriptionIPPForm control-label' >
                        {{to.descriptions}}
                    </description>
                    <a ng-show='to.link' ng-href="{{to.link.url}}" target="_blank">{{to.link.title}}</a>
					<!-- Ajout du template si il y a en a un (Template définit dans les setType juste en dessous) -->
					    <formly-transclude></formly-transclude>
                    <!-- Affichage de l'erreur -->
                    <div class='form-group' ng-if="!${self.readingMode} && ${self.data.status_declaration} !== 5"
                         ng-class='{"has-error": showError}'>
                        <ul class='list-unstyled text-danger small'
                            ng-messages='fc.$error'
                            ng-show='options.validation.errorExistsAndShouldBeVisible'>
                            <li ng-repeat='(name, message) in ::options.validation.messages'
                                ng-message='{{::name | translate }}'>
						        {{ message(fc.$viewValue, fc.$modelValue, this) }}
						    </li>
						</ul>
                        <div class='text-muted small'
                             ng-if='to.pending && fc.$pending'>
						    {{::to.pending }}
						</div>
                    </div>`,
        });

        formlyConfig.setWrapper({
          overwriteOk: true,
          name: "horizontalBootstrapBig",
          template: `
                    <!-- label du field -->
                    <label for='{{::id}}'
                           class='styleLabelIPPForm control-label' ng-class="{'has-error': showError}" >
                        {{to.label}}{{to.required ? '*' : ''}}
                    </label>
                    <!--  description si il y en a une -->
                    <description ng-show='to.description != null'
                                 class='styleDescriptionIPPForm control-label'>
                        {{to.description}}
                    </description>
                    <!-- Ajout du template si il y a en a un (Template définit dans les setType juste en dessous) -->
                        <formly-transclude></formly-transclude>
                    <!-- Affichage de l'erreur -->
                    <div class='form-group'
                         ng-class='{\"has-error\": showError}'>
                        <ul class='list-unstyled text-danger small'
                            ng-messages='fc.$error'
                            ng-show='options.validation.errorExistsAndShouldBeVisible'>
                            <li ng-repeat='(name, message) in ::options.validation.messages'
                                ng-message='{{::name | translate }}'>
                                {{ message(fc.$viewValue, fc.$modelValue, this) }}
                            </li>
                        </ul>
                        <div class='text-muted small'
                             ng-if='to.pending && fc.$pending'>
                            {{::to.pending }}
                        </div>
                    </div>`,
        });

        formlyConfig.setType({
          name: "horizontalInput",
          overwriteOk: true,
          extends: "input",
          template: `
						<input class="form-control ipp-input-field" ng-model="model[options.key]"
							ng-if="(!readingMode && newDataFromThisYear) || (!readingMode && !validationMode) || (dataToShow == null && options.formControl.$valid !== true) || addNewElementDuringValidationMode || (validationMode && (dataToShow == null || dataToShow == 0))">
						<div ng-show="dataToShow != null && !addNewElementDuringValidationMode">
							<div ng-show="(readingMode || (validationMode && !newDataFromThisYear && dataToShow != 0))">
								<span class="ipp-input-answer">{{ dataToShow }}</span>
							</div>
							<div ng-show="validationMode && !readingMode  && !newDataFromThisYear && (dataToShow !== 0 && validationMode)" class="validation-checkbox">
							<label class="validation-label" ng-click="newDataFromThisYear=!newDataFromThisYear; updateDataToShow();">
								<i ng-show="!newDataFromThisYear" class="material-icons validation-checkbox-icon">
									check_box_outline_blank
								</i>
								<i ng-show="newDataFromThisYear" class="material-icons validation-checkbox-icon validation-check">
									check_box
								</i>
								{{'IPP_FORM.TEMPLATES.hasChangedSinceLastYear' | translate}}
								</label>
							</div>
						</div>
						<!-- case of a not necessary reponse, and empty, in reading mode-->
						<div ng-if="dataToShow == null && options.formControl.$valid === true && readingMode">
							<span class="ipp-input-answer ipp-response-placeholder">{{'IPP_FORM.noAnswer' | translate}}</span>
						</div>
					`,
          wrapper: ["horizontalBootstrap"],
          controller: [
            "$scope",
            "IPPFormService",
            ($scope, ippFormService: IPPFormService) => {
              $scope.addNewElementDuringValidationMode =
                ippFormService.addNewElementDuringValidationMode;
              $scope.newDataFromThisYear = false;
              $scope.dataToShow = ippFormService.parcourObject(
                $scope.model,
                $scope.options.key
              );
              $scope.model[$scope.options.key] = $scope.dataToShow;
              $scope.updateDataToShow = function () {
                $scope.dataToShow = ippFormService.parcourObject(
                  $scope.model,
                  $scope.options.key
                );
              };
              $scope.readingMode = ippFormService.readingMode;
              $scope.validationMode = ippFormService.validationMode;
            },
          ],
        });

        formlyConfig.setType({
          name: "radioInline",
          overwriteOk: true,
          wrapper: ["horizontalBootstrap"],
          template: `
						<div class="radio-group ipp-radio" ng-class="{'has-error': showError}" ng-show="formStatus !== 5 && ((!readingMode && newDataFromThisYear)
							|| (!readingMode && !validationMode) || (!readingMode && (model[options.key] == null || model[options.key] == undefined)) || addNewElementDuringValidationMode || (validationMode && (model[options.key] === 0 || !newDataFromThisYear)))">
							<label ng-repeat="(key, option) in to.options"
								class="radio-inline" ng-class="{'has-error': showError}" >
								<input type="radio"
									id="{{id + '_'+ $index}}"
									tabindex="0"
									ng-value="option[to.valueProp || 'value']"
									formly-custom-validation="options.validators"
									ng-model="model[options.key]">
								{{option[to.labelProp || 'name']}}
							</label>
                        </div>
						<div ng-show="formStatus !== 5 && model[options.key] != null && !addNewElementDuringValidationMode && !(validationMode && (model[options.key] === 0 || !newDataFromThisYear))">
							<div ng-show="readingMode || (validationMode && !newDataFromThisYear)">
								<span ng-if="model[options.key] === 0" class="ipp-radio-no-response">{{'IPP_FORM.noAnswer' | translate}}</span>
								<span ng-if="model[options.key] === 1" class="ipp-radio-yes">{{getAnswerLabel(to.options, model[options.key])}}</span>
								<span ng-if="model[options.key] === 2" class="ipp-radio-no">{{getAnswerLabel(to.options, model[options.key])}}</span>
							</div>
							<div ng-show="validationMode && !readingMode && !newDataFromThisYear" class="validation-checkbox">
								<label class="validation-label" ng-click="newDataFromThisYear=!newDataFromThisYear">
									<i ng-show="!newDataFromThisYear" class="material-icons validation-checkbox-icon">
											check_box_outline_blank
									</i>
									<i ng-show="newDataFromThisYear" class="material-icons validation-checkbox-icon validation-check">
											check_box
									</i>
								{{'IPP_FORM.TEMPLATES.hasChangedSinceLastYear' | translate}}
								</label>
							</div>
                        </div>
                        <div ng-if="formStatus !== 5 && readingMode && (model[options.key] == null || model[options.key] == undefined)">
                            <span class="ipp-radio-no-response">{{'IPP_FORM.noAnswer' | translate}}</span>
						</div>
                        <div ng-if="readingMode && formStatus === 5">
							<span class="ipp-input-answer ipp-response-placeholder">{{'IPP_FORM.noAnswer' | translate}}</span>
						</div>
					`,
          extends: "radio",
          controller: [
            "$scope",
            "IPPFormService",
            function ($scope, ippFormService: IPPFormService) {
              $scope.addNewElementDuringValidationMode =
                ippFormService.addNewElementDuringValidationMode;
              $scope.readingMode = ippFormService.readingMode;
              $scope.formStatus = ippFormService.data.status_declaration;
              $scope.validationMode = ippFormService.validationMode;
              $scope.getAnswerLabel = (options, value) => {
                let answerLabel = "";
                // finding a way to get out of this weird object structure
                if (options != null && value != null) {
                  let tableOfResult = Object.keys(options).map(function (key) {
                    return options[key];
                  });
                  for (let result of tableOfResult) {
                    if (result.value === value) {
                      answerLabel = result.name;
                    }
                  }
                }
                return answerLabel;
              };
            },
          ],
        });

        formlyConfig.setType({
          name: "radioInlineWithChildren",
          overwriteOk: true,
          template: `
						<div class="radio-group ipp-radio" ng-show="!readingMode && formStatus !== 5">
							<label ng-repeat="(key, option) in to.options"
								class="radio-inline">
								<input type="radio"
									id="{{id + '_'+ $index}}"
									tabindex="0"
									ng-value="option[to.valueProp || 'value']"
									formly-custom-validation="options.validators"
									ng-model="model[options.key]">
								{{option[to.labelProp || 'name']}}
							</label>
						</div>
						<div ng-show="readingMode && formStatus !== 5">
							<span ng-if="model[options.key] === 0" class="ipp-radio-no-response">{{'IPP_FORM.noAnswer' | translate}}</span>
							<span ng-if="model[options.key] === 1" class="ipp-radio-yes">{{'IPP_FORM.yes' | translate}}</span>
							<span ng-if="model[options.key] === 2" class="ipp-radio-no">{{'IPP_FORM.no' | translate}}</span>
                        </div>
                        <div ng-if="readingMode && formStatus === 5">
                            <span class="ipp-input-answer ipp-response-placeholder">{{'IPP_FORM.noAnswer' | translate}}</span>
                        </div>
					`,
          extends: "radio",
          wrapper: ["horizontalBootstrap"],
          controller: [
            "$scope",
            "IPPFormService",
            function ($scope, ippFormService: IPPFormService) {
              $scope.readingMode = ippFormService.readingMode;
              $scope.validationMode = ippFormService.validationMode;
              $scope.formStatus = ippFormService.data.status_declaration;
            },
          ],
        });

        formlyConfig.setType({
          name: "buttonFile",
          overwriteOk: true,
          extends: "input",
          template: `
						<div ng-if="!readingMode && formStatus !== 5">
							<button class="btn ipp-grid-btn-add"
									ng-if="!to.data.closed && (!model[options.key] || model[options.key].length == 0)"
									ng-click="addFile(to.modelKeyArray)">
								<i class="glyphicon glyphicon-plus"></i>
								<!--  trouver un moyen de gérer les différents noms d'attributs dans ce formulaire -->
								{{'IPP_FORM.TEMPLATES.addDocument' | translate}}
							</button>
							<input ng-required="to.required"
								ng-model="model[options.key]"
								type="file"
								id="{{to.modelKeyArray}}"
								multiple
								accept="application/pdf"
								style="display: none;"/>
						</div>
						<table class="table ipp-document-grid table-condensed" ng-if="!readingMode && model[options.key].length > 0">
							<thead>
								<tr>
									<th class="ipp-grid-header">
									{{'IPP_FORM.TEMPLATES.documents' | translate}}
									</th>
									<th>
										<button class="btn btn-xs ipp-grid-btn-add pull-right"
												ng-if="!to.data.closed"
												ng-click="addFile(to.modelKeyArray)">
												<i class="glyphicon glyphicon-plus"></i>
											{{'IPP_FORM.TEMPLATES.add' | translate}}
										</button>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr ng-repeat="file in model[options.key]" class="ipp-grid-row">
									<td>
										<a target="_blank" class="ipp-grid-document-name" href="" ng-href="/api/IPPFormUploadMgr?sessionID={{to.sessionID}}&senderKey={{file.senderKey}}&fileKey={{file.fileKey}}">
											<i class="material-icons skwarel-ipp-material-icons">attach_file</i>{{file.name}}
										</a>
									</td>
									<td>
										<button class="btn btn-xs ipp-grid-btn-delete pull-right" ng-click="remove(options.key,file,model)">

											<i class="material-icons skwarel-ipp-material-icons ipp-grid-delete-icon" tooltip-placement="right" tooltip-popup-delay="1000" uib-tooltip="Supprimer">delete</i>
										</button>
									</td>
								</tr>
							</tbody>
						</table>
						<div ng-if="readingMode && formStatus !== 5">
							<div ng-show="model[options.key].length > 0" ng-repeat="file in model[options.key]" class="ipp-input-answer">
								<a target="_blank"
									ng-href="/api/IPPFormUploadMgr?sessionID={{to.sessionID}}&senderKey={{file.senderKey}}&fileKey={{file.fileKey}}">
									<i class="material-icons skwarel-ipp-material-icons">attach_file</i>
									{{file.name}}</a>
							</div>
							<div ng-show="model[options.key] == null" class="ipp-radio-no ipp-input-answer ipp-response-placeholder">
								<span>{{'IPP_FORM.TEMPLATES.missingDocNeedToAdd' | translate}}</span>
							</div>
                        </div>
                        <div ng-if="readingMode && formStatus === 5">
							<span class="ipp-input-answer ipp-response-placeholder">{{'IPP_FORM.noAnswer' | translate}}</span>
						</div>
					`,
          wrapper: ["horizontalBootstrap"],
          controller: [
            "$scope",
            "IPPFormService",
            function ($scope, ippFormService: IPPFormService) {
              $scope.formStatus = ippFormService.data.status_declaration;
              $scope.remove = (key, file, model) => {
                self.confirmDocumentDelete().then(() => {
                  self.deleteFile(file, self.data.key);
                  let index = model[key].indexOf(file);
                  model[key].splice(index, 1);
                  if (model[key].length === 0) {
                    model[key] = null;
                  }
                });
              };

              $scope.addFile = function (modelKeyArray) {
                document.getElementById(modelKeyArray).click();
              };
              $scope.readingMode = ippFormService.readingMode;
              $scope.validationMode = ippFormService.validationMode;
            },
          ],
        });

        //noinspection TypeScriptValidateTypes
        formlyConfig.setType({
          overwriteOk: true,
          name: "datepicker",
          template: `
						<div class="input-group"
							ng-if="(!readingMode && formStatus !== 5) && ((!readingMode && newDataFromThisYear) || (!readingMode && !validationMode)
							|| model[options.key] == null || addNewElementDuringValidationMode  || (validationMode && model[options.key] == null))">

							<input type="text"
								id="{{::id}}"
								name="{{::id}}"
								ng-model="model[options.key]"
								class="form-control ipp-input-field"
								ng-click="datepicker.open($event)"
								is-open="datepicker.opened"
								uib-datepicker-popup="{{format}}"
                                clear-text = "{{clear}}"
                                close-text="{{close}}"
                                current-text= "{{today}}"
								/>

							<span class="input-group-btn">
									<button type="button"
											class="btn btn-default ipp-datepicker-btn"
											ng-click="datepicker.open($event)">
										<i class="glyphicon glyphicon-calendar"></i>
									</button>
							</span>

						</div>
						<div ng-show="formStatus !== 5 && model[options.key] != null && !addNewElementDuringValidationMode">
							<div class="ipp-input-answer" ng-show="(readingMode || (validationMode && !newDataFromThisYear))">
								{{ model[options.key] | date:"dd/MM/yyyy"}}
							<div ng-show="validationMode && !readingMode  && !newDataFromThisYear" class="validation-checkbox">
									<label class="validation-label" ng-click="newDataFromThisYear=!newDataFromThisYear; updateDataToShow();">
										<i ng-show="!newDataFromThisYear" class="material-icons validation-checkbox-icon">
												check_box_outline_blank
										</i>
										<i ng-show="newDataFromThisYear" class="material-icons validation-checkbox-icon validation-check">
												check_box
										</i>
									{{'IPP_FORM.TEMPLATES.hasChangedSinceLastYear' | translate}}
									</label>
							</div>
                        </div>
                        <div ng-if="readingMode && formStatus === 5">
							<span class="ipp-input-answer ipp-response-placeholder">{{'IPP_FORM.noAnswer' | translate}}</span>
						</div>
						`,
          wrapper: ["horizontalBootstrap"],
          controller: [
            "$scope",
            "IPPFormService",
            ($scope, ippFormService: IPPFormService) => {
              //format of the date conversion
              $scope.format = "dd/MM/yyyy";
              $scope.formStatus = ippFormService.data.status_declaration;
              //we create a new object date with the date in the DB
              if ($scope.model[$scope.options.key]) {
                $scope.dt = new Date($scope.model[$scope.options.key]);
                $scope.model[$scope.options.key] = $scope.dt;
              }

              // we reassign the date in the DB with the new conform object
              //this trick resolves the problem of the date not appearing in the datepicker
              let lang = ippFormService.m.language;
              let locale = "";
              switch (lang) {
                case "FR":
                  locale = "fr";
                  $scope.clear = "Effacer";
                  $scope.close = "Fermer";
                  $scope.today = "Aujourd'hui";
                  break;
                case "EN":
                  locale = "en";
                  $scope.clear = "Clear";
                  $scope.close = "Close";
                  $scope.today = "Today";
                  break;
                case "NL":
                  locale = "nl";
                  $scope.clear = "Verwijderen";
                  $scope.close = "Sluiten";
                  $scope.today = "Vandaag";
                  break;
                default:
                  locale = "fr";
                  $scope.clear = "Effacer";
                  $scope.close = "Fermer";
                  $scope.today = "Aujourd'hui";
                  break;
              }

              $scope.datepickerOptions = {
                "clear-text": $scope.clear,
                "close-text": $scope.close,
                "current-text": $scope.today,
              };
              $scope.datepicker = {};
              $scope.datepicker.opened = false;
              $scope.datepicker.open = function ($event) {
                $scope.datepicker.opened = !$scope.datepicker.opened;
              };

              $scope.addNewElementDuringValidationMode =
                ippFormService.addNewElementDuringValidationMode;
              $scope.readingMode = ippFormService.readingMode;
              $scope.validationMode = ippFormService.validationMode;
            },
          ],
        });

        formlyConfig.setType({
          name: "horizontalCheckbox",
          overwriteOk: true,
          extends: "checkbox",
          wrapper: ["horizontalBootstrap"],
        });

        formlyConfig.setType({
          name: "horizontalRadio",
          overwriteOk: true,
          extends: "radio",
          wrapper: ["horizontalBootstrap"],
        });

        formlyConfig.setType({
          name: "horizontalSelect",
          overwriteOk: true,
          wrapper: ["horizontalBootstrap"],
          template: `
						<div ng-if="formStatus !== 5" class="form-group" ng-class='{"has-error": showError}'>
							<select class="form-control ipp-input-field select-padding" ng-model="model[options.key]"
								ng-if="(!readingMode && newDataFromThisYear) || (!readingMode && !validationMode)
									|| model[options.key] == null  || addNewElementDuringValidationMode || newSelectionMadeDuringValidationMode">
								 <option ng-hide="to.notNull" value="0">{{to.nullDisplay}}</option>
							</select>
							<!-- select used if this is used as a child select in validation mode -->
							<select class="form-control ipp-input-field select-padding" ng-model="model[options.key]"
								ng-if="validationMode && model[options.key] === 0" ng-change="changingValueDuringValidationMode()">
								 <option ng-hide="to.notNull" value="0">{{to.nullDisplay}}</option>
							</select>
							<div ng-show="dataToShow !== 0">
								<div ng-show="(readingMode || (validationMode && !newDataFromThisYear && !newSelectionMadeDuringValidationMode)) && model[options.key] != null && !addNewElementDuringValidationMode">
									<span class="ipp-input-answer">
										<!-- weird trick to show the value -->
										<span ng-repeat="(key, value) in options.templateOptions.options">
												<span ng-show="value.value === dataToShow">{{value.name}}</span>
										</span>
									</span>
								</div>
								<div ng-show="validationMode && !readingMode && !newDataFromThisYear && !newSelectionMadeDuringValidationMode && !addNewElementDuringValidationMode" class="validation-checkbox">
										<label class="validation-label" ng-click="newDataFromThisYear=!newDataFromThisYear; updateDataToShow();">
											<i ng-show="!newDataFromThisYear" class="material-icons validation-checkbox-icon">
													check_box_outline_blank
											</i>
											<i ng-show="newDataFromThisYear" class="material-icons validation-checkbox-icon validation-check">
													check_box
											</i>
										{{'IPP_FORM.TEMPLATES.hasChangedSinceLastYear' | translate}}
										</label>
								</div>
							</div>
                        </div>
                        <div ng-if="readingMode && formStatus === 5">
							<span class="ipp-input-answer ipp-response-placeholder">{{'IPP_FORM.noAnswer' | translate}}</span>
						</div>
						`,
          controller: [
            "$scope",
            "IPPFormService",
            "$translate",
            function (
              $scope,
              ippFormService: IPPFormService,
              $translate: ngt.ITranslateService
            ) {
              $scope.formStatus = ippFormService.data.status_declaration;
              $scope.dataToShow = ippFormService.parcourObject(
                $scope.model,
                $scope.options.key
              );

              $scope.newSelectionMadeDuringValidationMode = false;
              $scope.addNewElementDuringValidationMode =
                ippFormService.addNewElementDuringValidationMode;
              $scope.readingMode = ippFormService.readingMode;
              $scope.validationMode = ippFormService.validationMode;
              // dirty trick to show a new select box if a new value is needed for a child select in validation mode.
              $scope.changingValueDuringValidationMode = () => {
                if ($scope.model[$scope.options.key] != 0) {
                  $scope.newSelectionMadeDuringValidationMode = true;
                }
              };
            },
          ],
          defaultOptions(options) {
            let ngOptions =
              options.templateOptions.ngOptions ||
              `option[to.valueProp || 'value'] as option[to.labelProp || 'name'] group by option[to.groupProp || 'group'] for option in to.options`;
            return {
              ngModelAttrs: {
                [ngOptions]: {
                  value: options.templateOptions.optionsAttr || "ng-options",
                },
              },
            };
          },
        });

        formlyConfig.setType({
          name: "horizontalTextarea",
          overwriteOk: true,
          extends: "textarea",
          templateUrl: "tpl/IPPForm/textAreaForm.html",
          wrapper: ["horizontalBootstrap"],
        });

        formlyConfig.setType({
          name: "textAreaMoreInfo",
          overwriteOk: true,
          template: `
						<div class="radio-group">
							<textarea id="{{::id}}" ng-if="!readingMode"
									name="{{::id}}"
									class="form-control ipp-input-field"
									ng-model="model[options.key]"
									rows="10"
									cols="10"
									style="resize:none"
									placeholder="{{'IPP_FORM.placeHolderMoreInfo' | translate}}">
							</textarea>
							<div ng-if="readingMode" class="ipp-input-answer bottom-spacing">
								{{model[options.key]}}
								<span class="ipp-response-placeholder" ng-if="model[options.key] == null">
									{{'IPP_FORM.TEMPLATES.noRemark' | translate}}
								</span>
							</div>
						</div>
					`,
          defaultOptions: {
            ngModelAttrs: {
              rows: { attribute: "rows" },
              cols: { attribute: "cols" },
            },
          },
          wrapper: ["horizontalBootstrap"],
          controller: [
            "$scope",
            "IPPFormService",
            function ($scope, ippFormService: IPPFormService) {
              $scope.readingMode = ippFormService.readingMode;
              $scope.validationMode = ippFormService.validationMode;
            },
          ],
        });

        formlyConfig.setType({
          name: "textAreaMoreInfoBig",
          overwriteOk: true,
          extends: "textarea",
          templateUrl: "tpl/IPPForm/textAreaIPPMoreInfo.html",
          wrapper: ["horizontalBootstrapBig"],
        });

        formlyConfig.setType({
          name: "grid",
          template: `
						<table class="table ipp-document-grid table-condensed" ng-if="formStatus !== 5 && !readingMode">
							<thead>
								<tr>
									<th class="ipp-grid-header">{{to.label}}</th>
									<th>
									 <button class="btn btn-xs ipp-grid-btn-add pull-right"
												ng-if="!to.data.closed"
												ng-click="to.ctrl.edit({}, true, to.variableObject, to.step, to.data, to.fieldsManager, to.ancestor,to.labelKeyArr)">
				<!--
										<button class="btn btn-xs ipp-grid-btn-add pull-right"
												ng-if="!to.variableObject.isClosed"
												ng-click="to.ctrl.edit({}, true, to.variableObject)">
		-->
											<i class="glyphicon glyphicon-plus"></i>
											{{'IPP_FORM.TEMPLATES.add' | translate}}
										</button>
									</th>
								</tr>
							</thead>
							<tbody>
								<!--<tr ng-repeat="item in to.ctrl.model[to.modelKeyArray]" class="ipp-grid-row">-->

								<tr ng-repeat="item in to.data[to.modelKeyArray]" class="ipp-grid-row">
									<td>

									<a href="" class="ipp-grid-document-name"
										ng-click="to.ctrl.edit(item, false,to.variableObject, to.step, to.data, to.fieldsManager,to.ancestor,to.labelKeyArr)">
										<!--
										<a href="" class="ipp-grid-document-name"
										ng-click="to.ctrl.edit(item, false,to.variableObject)">-->
											<i class="material-icons skwarel-ipp-material-icons">{{to.icon}}</i>
											<span ng-repeat="field in to.labelKeyArr"
												ng-show="to.hasSubKey">
												<span ng-repeat="subKey in field.subKeyArr">
													{{item[field.key][subKey]}}
												</span>
											</span>
											<span ng-repeat="field in to.labelKeyArr"
												ng-show="!to.hasSubKey">
												{{item[field.key]}}
											</span>
										</a>
										<i ng-if="!item.isValid"
												class="material-icons skwarel-ipp-material-icons icon-red-ipp"
												tooltip-placement="right" uib-tooltip="{{tooltipTranslation}}">
												error
										</i>
									</td>
									<td>
									<button class="btn btn-xs ipp-grid-btn-delete pull-right" ng-click="to.ctrl.delete(item, to.step, to.data, to.ancestor,to.modelKeyArray)">
											<i class="material-icons skwarel-ipp-material-icons ipp-grid-delete-icon" tooltip-placement="right" tooltip-popup-delay="1000" uib-tooltip="Supprimer">delete</i>

										<!-- <button class="btn btn-xs ipp-grid-btn-delete pull-right" ng-click="remove(options.key,file,model)">
											<i class="material-icons skwarel-ipp-material-icons ipp-grid-delete-icon" tooltip-placement="right" tooltip-popup-delay="1000" uib-tooltip="Supprimer">delete</i>
										</button> -->
									</td>
								</tr>
							</tbody>
						</table>
						<ul ng-if="formStatus !== 5 && readingMode" class="ipp-answer-list">
							<li ng-repeat="item in to.data[to.modelKeyArray]">
								<a href=""
									ng-click="to.ctrl.edit(item, false,to.variableObject, to.step, to.data, to.fieldsManager,to.ancestor,to.labelKeyArr)">
									<i class="material-icons skwarel-ipp-material-icons">{{to.icon}}</i>
									<span ng-repeat="field in to.labelKeyArr"
										ng-show="to.hasSubKey">
										<span ng-repeat="subKey in field.subKeyArr">
											{{item[field.key][subKey]}}
										</span>
									</span>
									<span ng-repeat="field in to.labelKeyArr"
										ng-show="!to.hasSubKey">
										{{item[field.key]}}
									</span>
								</a>
							</li>
                        </ul>
                        <div ng-if="readingMode && formStatus === 5">
							<span class="ipp-input-answer ipp-response-placeholder">{{'IPP_FORM.noAnswer' | translate}}</span>
						</div>
					`,
          controller: [
            "$scope",
            "IPPFormService",
            "$translate",
            function (
              $scope,
              ippFormService: IPPFormService,
              $translate: ngt.ITranslateService
            ) {
              $scope.readingMode = ippFormService.readingMode;
              $scope.formStatus = ippFormService.data.status_declaration;
              $scope.validationMode = ippFormService.validationMode;
              $scope.tooltipTranslation = $translate.instant(
                "IPP_FORM.TEMPLATES.toComplete"
              );
              // method used to differenciate the types of items in the list, and assign them a specific icon
              $scope.hasProperty = function (item, property) {
                return property in item;
              };
            },
          ],
        });
        formlyConfig.setType({
          name: "horizontalInputGrid",
          templateUrl: "tpl/IPPForm/horizontalInputGrid.html",
          controller: [
            "$scope",
            "IPPFormService",
            function ($scope, ippFormService: IPPFormService) {
              $scope.readingMode = ippFormService.readingMode;
              $scope.validationMode = ippFormService.validationMode;

              $scope.getTranslations = function (label) {
                return label[ippFormService.m.language];
              };
              $scope.addInput = function (labelKeyArr) {
                let newItem = {};
                labelKeyArr.forEach(function (o) {
                  newItem[o.key] = "";
                });
                if (!$scope.model[$scope.options.templateOptions.modelKeyArr]) {
                  $scope.model[$scope.options.templateOptions.modelKeyArr] = [];
                }
                $scope.model[$scope.options.templateOptions.modelKeyArr].push(
                  newItem
                );
              };
              if (!$scope.readingMode && !$scope.validationMode) {
                $scope.addInput($scope.options.templateOptions.labelKeyArr);
              }
              $scope.delete = function (item) {
                let index =
                  $scope.model[
                    $scope.options.templateOptions.modelKeyArr
                  ].indexOf(item);
                $scope.model[$scope.options.templateOptions.modelKeyArr].splice(
                  index,
                  1
                );
              };
            },
          ],
        });
      });
    }

    $onInit() {}

    public refreshData(data) {
      this.data = data;
      this.model = this.data;

      if (this.readingMode) {
        this.validationMode = false;
      } else {
        this.validationMode = this.model.validation_mode;
      }
    }

    /**
     * Return la vue numéro view de la fiduciaire userKey
     * @param view
     * @returns {IPromise<any>|IPromise<T>}
     */
    public getStep = (step: number, ippKey: String): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.IPPForm.baseurl)
        .one(
          this.RESTAPI.services.IPPForm.view +
            "/" +
            ippKey +
            "?accountingFirmKey=" +
            this.sessionService.session.accountingFirmKey +
            "&step=" +
            step
        )
        .get();
    };

    /**
     * Récupère les datas de l'appel de méthode
     */
    public getFieldsManager = (): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.IPPForm.baseurl)
        .one(
          "/" +
            this.sessionService.session.member.key +
            "/" +
            this.RESTAPI.services.IPPForm.fieldsManager
        )
        .get();
    };

    getAttachmentsKeys = (): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.IPPForm.baseurl)
        .one(this.RESTAPI.services.IPPForm.attachments)
        .get();
    };

    /**
     * Met à jour les données data de l'année year et change de vur si nécessaire (si view != null)
     * @param data
     * @param year
     * @param step
     * @returns {any}
     */
    public updateData = (
      data: any,
      year: number,
      step: number
    ): ng.IPromise<any> => {
      if (data.key == null) {
        this.notification.primary({
          title: this.$translate.instant(
            "IPP_FORM.NOTIFICATIONS.criticalErrorTitle"
          ),
          message: this.$translate.instant(
            "IPP_FORM.NOTIFICATIONS.criticalErrorMessage"
          ),
          templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
        });
        this.logger.customError(
          "IPP FORM SERVICE",
          "null ipp data",
          this.sessionService.session.member
        );
        // reload the app after 3 second.
        setTimeout(() => this.$state.reload(), 3000);
      } else if (step === null) {
        return this.rest
          .all(this.RESTAPI.services.IPPForm.baseurl)
          .one(
            this.sessionService.session.member.key +
              "/" +
              this.RESTAPI.services.IPPForm.ipps +
              "/" +
              data.key
          )
          .customPUT(data);
      } else if (this.readingMode === true) {
        if (step === 100) {
          this.$state.go("ipp.form.identification", {
            ippKey: data.key,
            accountant: true,
          });
        } else if (step === 200) {
          this.$state.go("ipp.form.family", {
            ippKey: data.key,
            accountant: true,
          });
        } else if (step === 300) {
          this.$state.go("ipp.form.estateIncome", {
            ippKey: data.key,
            accountant: true,
          });
        } else if (step === 400) {
          this.$state.go("ipp.form.remuneration", {
            ippKey: data.key,
            accountant: true,
          });
        } else if (step === 500) {
          this.$state.go("ipp.form.deductible_expenses", {
            ippKey: data.key,
            accountant: true,
          });
        } else if (step === 600) {
          this.$state.go("ipp.form.divers", {
            ippKey: data.key,
            accountant: true,
          });
        } else if (step === 700) {
          this.$state.go("ipp.form.validation", {
            ippKey: data.key,
            accountant: true,
          });
        } else {
          this.$state.go("ipp.form.home", {}, { reload: true });
        }
      } else {
        return this.rest
          .all(this.RESTAPI.services.IPPForm.baseurl)
          .one(
            this.sessionService.session.member.key +
              "/" +
              this.RESTAPI.services.IPPForm.ipps +
              "/" +
              data.key
          )
          .customPUT(data)
          .then(() => {
            if (step === 100) {
              this.$state.go("ipp.form.identification", { ippKey: data.key });
            } else if (step === 200) {
              this.$state.go("ipp.form.family", { ippKey: data.key });
            } else if (step === 300) {
              this.$state.go("ipp.form.estateIncome", { ippKey: data.key });
            } else if (step === 400) {
              this.$state.go("ipp.form.remuneration", { ippKey: data.key });
            } else if (step === 500) {
              this.$state.go("ipp.form.deductible_expenses", {
                ippKey: data.key,
              });
            } else if (step === 600) {
              this.$state.go("ipp.form.divers", { ippKey: data.key });
            } else if (step === 700) {
              this.$state.go("ipp.form.validation", { ippKey: data.key });
            } else {
              this.$state.go("ipp.form.home", {}, { reload: true });
            }
            // this.$state.go("^", { step: step });
            if (!this.readingMode && this.activeStep != 700) {
              this.notification.primary({
                title: this.$translate.instant(
                  "IPP_FORM.NOTIFICATIONS.savedDataTitle"
                ),
                message: this.$translate.instant(
                  "IPP_FORM.NOTIFICATIONS.savedDataMessage"
                ),
                templateUrl: "tpl/IPPForm/notificationSuccessTemplate.html",
              });
            }
          })
          .catch((err) => {
            if (err) {
              console.error("err on update data", err);
              this.notification.primary({
                title: this.$translate.instant(
                  "IPP_FORM.NOTIFICATIONS.simpleErrorTitle"
                ),
                message: this.$translate.instant(
                  "IPP_FORM.NOTIFICATIONS.simpleErrorMessage"
                ),
                templateUrl: "tpl/IPPForm/notificationErrorTemplate.html",
              });
              this.logger.error(
                "error in update data from ippFormService",
                err,
                this.sessionService.session.member
              );
            }
          });
      }
    };

    /**
     * Récupère les données de l'utilisateur userKey de l'année year
     * @returns {IPromise<any>|IPromise<T>}
     */
    public getData = (): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.IPPForm.baseurl)
        .one(
          this.sessionService.session.member.key +
            "/" +
            this.RESTAPI.services.IPPForm.ipps +
            "/" +
            this.RESTAPI.services.IPPForm.recent
        )
        .get();
    };

    public getDataFor = (key: String): ng.IPromise<any> => {
      /*if(this.data != null && this.data != undefined && key === this.data.key){
                // do nothing
                return undefined;
            }else{
                return this.rest
                    .all(this.RESTAPI.services.IPPForm.baseurl)
                    .one(this.sessionService.session.member.key + "/" + this.RESTAPI.services.IPPForm.ipps + "/" + key)
                    .get();
            }*/
      return this.rest
        .all(this.RESTAPI.services.IPPForm.baseurl)
        .one(
          this.sessionService.session.member.key +
            "/" +
            this.RESTAPI.services.IPPForm.ipps +
            "/" +
            key
        )
        .get();
    };

    public getAllData = (): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.IPPForm.baseurl)
        .one(
          this.sessionService.session.member.key +
            "/" +
            this.RESTAPI.services.IPPForm.ipps
        )
        .get();
    };

    public getFile = (
      ippKey: String,
      filename: String,
      typeFile: Number
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.IPPForm.baseurl)
        .one(
          this.sessionService.session.member.key +
            "/" +
            this.RESTAPI.services.IPPForm.ipps +
            "/" +
            ippKey +
            "/" +
            this.RESTAPI.services.IPPForm.annexes +
            "?filename=" +
            filename +
            "&type=" +
            typeFile +
            "&sessionID=" +
            this.sessionService.session.sessionID
        )
        .get();
    };

    public warnResponsableAnnexe = (ippKey: string): ng.IPromise<any> => {
      return this.rest
        .all(
          this.RESTAPI.services.IPPForm.baseurl +
            "/" +
            this.sessionService.session.member.key +
            "/" +
            this.RESTAPI.services.IPPForm.ipps +
            "/" +
            ippKey +
            "/" +
            this.RESTAPI.services.IPPForm.annexes +
            "/" +
            this.RESTAPI.services.IPPForm.mail
        )
        .post({ ippKey: ippKey });
    };

    public acceptProposition = (
      ippKey: string,
      acceptation_message_data: string
    ): ng.IPromise<any> => {
      return this.rest
        .all(
          this.RESTAPI.services.IPPForm.baseurl +
            "/" +
            this.sessionService.session.member.key +
            "/" +
            this.RESTAPI.services.IPPForm.ipps +
            "/" +
            ippKey +
            "/" +
            this.RESTAPI.services.IPPForm.accept
        )
        .post({
          ippKey: ippKey,
          acceptation_message: acceptation_message_data,
        });
    };

    public denyProposition = (
      ippKey: string,
      denyMessage: string
    ): ng.IPromise<any> => {
      return this.rest
        .all(
          this.RESTAPI.services.IPPForm.baseurl +
            "/" +
            this.sessionService.session.member.key +
            "/" +
            this.RESTAPI.services.IPPForm.ipps +
            "/" +
            ippKey +
            "/" +
            this.RESTAPI.services.IPPForm.deny
        )
        .post({ ippKey: ippKey, deny_message: denyMessage });
    };

    // helper method to check if object has a specific property. used to show an icon with specific modal data, such as a bank account, or a child, or a house.
    public hasProp(obj, prop) {
      return Object.prototype.hasOwnProperty.call(obj, prop);
    }

    /**
         * @returns {IPromise<TResult>}
         .one(
         this.RESTAPI.services.IPPForm.initData +
         "/" +
         this.sessionService.session.sessionID +
         "&" +
         year +
         "&" +
         this.sessionService.session.member.key +
         "&" +
         this.sessionService.session.company.key
         )
         * Return les champs requis
         * @returns {IPromise<any>|IPromise<T>}
         */
    public getRequiredFields = (ippFormKey: String): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.IPPForm.baseurl)
        .one(
          this.sessionService.session.member.key +
            "/" +
            this.RESTAPI.services.IPPForm.ipps +
            "/" +
            ippFormKey +
            "/" +
            this.RESTAPI.services.IPPForm.keys
        )
        .get();
    };

    /**
     * Supprime le fichier dont la mongoFileKey est attachement dans la db
     * @param attachement
     * @returns {IPromise<any>}
     */
    public deleteFile = (
      attachement: Attachement,
      ippKey: String
    ): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.IPPForm.baseurl)
        .one(
          this.sessionService.session.member.key +
            "/" +
            this.RESTAPI.services.IPPForm.ipps +
            "/" +
            ippKey +
            "?fileKey=" +
            attachement.fileKey +
            "&senderKey=" +
            attachement.senderKey
        )
        .remove();
    };

    /**
     * Supprime le fichier dont la mongoFileKey est attachement dans la db
     * @param attachement
     * @returns {IPromise<any>}
     */
    public deleteAnnexe = (
      ippKey: string,
      fileName: string,
      fileType: number
    ): ng.IPromise<any> => {
      return this.rest
        .all(
          this.RESTAPI.services.IPPForm.baseurl +
            "/" +
            this.sessionService.session.member.key +
            "/" +
            this.RESTAPI.services.IPPForm.ipps +
            "/" +
            ippKey +
            "/annexes"
        )
        .post({ filename: fileName, type: fileType });
    };

    public confirmDocumentDelete() {
      let modalInstance = this.$uibModal.open({
        template: `
					<div class="modal-header ipp-modal-header">
						<h3 class="modal-title">
							{{'IPP_FORM.CONFIRM.title' | translate}}
						</h3>
					</div>
					<div class="modal-body">
						<p>{{'IPP_FORM.MODAL.areYouSure' | translate}}</p>
					</div>
					<div class="modal-footer ipp-modal-footer">
						<button class="btn ipp__btn ipp__btn-back"
								ng-click="cancel()">
							{{'IPP_FORM.ID.no' | translate}}
						</button>
						<button ng-disabled="familyModalFrom.$invalid"
								class="btn ipp__btn ipp__btn-submit"
								ng-click="confirm()">
							{{'IPP_FORM.ID.yes' | translate}}
						</button>
					</div>
				`,
        controller: [
          "$scope",
          "$uibModalInstance",
          function ($scope, $uibModalInstance) {
            $scope.cancel = function () {
              $uibModalInstance.dismiss("cancel");
            };

            $scope.confirm = function () {
              $uibModalInstance.close("confirm");
            };
          },
        ],
        size: "sm",
        backdrop: "static",
      });

      // launched when modal is closed with cross
      return modalInstance.result;
    }

    public completeUploadIPP(
      fieldKey: string,
      mongoFileKey: string,
      fileName: string,
      model: any
    ) {
      let self = this;
      let attachement: Attachement = new Attachement();
      attachement.fileKey = mongoFileKey;
      attachement.name = fileName;
      attachement.senderKey = self.sessionService.session.member.key;

      if (model[fieldKey] == null) {
        model[fieldKey] = [attachement];
      } else {
        model[fieldKey].push(attachement);
      }

      self.updateData(self.model, self.data.fiscalYear, null);
    }

    /**
     * Return le champ appartenant à la vue  view et de clé key
     * @param view
     * @param key
     * @returns {any}
     */
    public getField(view: any, key) {
      let i = 0;
      for (i; i < view.length; i++) {
        if (view[i].key_field === key) {
          return view[i];
        }
      }
      return view[i];
    }

    /**
     * Return the attachements list for the current ipp form.
     * will be useful to check if there's any file missing before confirm
     * Todo : incorporate the call
     *
     * @returns {IPromise<any>|IPromise<T>}
     */

    public getAttachementKeyList = (): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.IPPForm.baseurl)
        .one(
          this.RESTAPI.services.IPPForm.attachments +
            "?accountingFirmKey=" +
            this.sessionService.session.accountingFirmKey +
            "&fiscalYear=" +
            this.data.fiscalYear
        )
        .get();

      // // temp tab to test function
      // return ["justifExpense", "bank_statements", "doc_remuneration_independent"];
    };

    public getSubViewFields = (): ng.IPromise<any> => {
      return this.rest
        .all(this.RESTAPI.services.IPPForm.baseurl)
        .one(
          this.RESTAPI.services.IPPForm.subViewFields +
            "?accountingFirmKey=" +
            this.sessionService.session.accountingFirmKey +
            "&fiscalYear=" +
            this.data.fiscalYear
        )
        .get();

      // // temp tab to test function
      // return ["justifExpense", "bank_statements", "doc_remuneration_independent"];
    };

    public isInAttachementKeyList(key_field: string): boolean {
      let found = false;
      for (let attachmentKey of this.attachementKeyList) {
        if (attachmentKey == key_field) {
          found = true;
          break;
        }
      }

      return found;
    }
    /**
     * Récupère la vue invalide la plus petite
     */
    public getFirstInvalidStep() {
      let minInvalidView = this.nbrOfStep;
      let isInvalid: boolean = true;

      angular.forEach(this.requiredFields, (requiredField) => {
        let fieldvalue = null;
        isInvalid = true;
        // weird, ancestors always equals to null, maybe server problem?
        // looping through required fields
        // not sure if the requiredFields include a field from modals
        if (requiredField.field.ancestors != null) {
          let ancestorData = this.data[requiredField.field.ancestors];
          if (ancestorData) {
            for (let i = 0; i < ancestorData.length; i++) {
              let item = ancestorData[i];

              fieldvalue = this.parcourObject(
                item,
                requiredField.field.key_field
              );
              isInvalid =
                isInvalid &&
                this.checkFieldValidity(
                  fieldvalue,
                  requiredField.field.condition,
                  item
                );
              if (
                isInvalid &&
                this.isInAttachementKeyList(requiredField.field.key_field)
              ) {
                isInvalid = false;
                // the method is called a few times, and we don't want to duplicate the key in the tab
                let alreadyInTheList = false;
                for (let missingAnnexe of this.missingAnnexesList) {
                  if (
                    missingAnnexe.fieldKey === requiredField.field.key_field
                  ) {
                    alreadyInTheList = true;
                  }
                }
                if (!alreadyInTheList) {
                  this.missingAnnexesList.push(
                    this.getLabelObjectMissingAttachement(
                      requiredField.field,
                      item,
                      i,
                      requiredField.field.labelKeyArr
                    )
                  );
                }
              }
            }
          } else {
            isInvalid = false;
          }
        } else {
          fieldvalue = this.parcourObject(
            this.data,
            requiredField.field.key_field
          );
          isInvalid = this.checkFieldValidity(
            fieldvalue,
            requiredField.field.condition,
            this.data
          );
        }

        if (isInvalid) {
          // if the invalid field is a attachement, we push it in the tab of missing annexes, and therefore we don't count it as a real invalid field
          if (this.isInAttachementKeyList(requiredField.field.key_field)) {
            // the method is called a few times, and we don't want to duplicate the key in the tab
            let alreadyInTheList = false;
            for (let missingAnnexe of this.missingAnnexesList) {
              if (missingAnnexe.fieldKey === requiredField.field.key_field) {
                alreadyInTheList = true;
              }
            }
            if (!alreadyInTheList) {
              this.missingAnnexesList.push(
                this.getLabelObjectMissingAttachement(requiredField.field)
              );
            }
          } else {
            if (requiredField.step < minInvalidView) {
              minInvalidView = requiredField.step;
            }
          }
        }
      });

      // CHECKING FOR MODAL VALIDITY

      angular.forEach(this.subViewFields, (subViewField) => {
        let key = subViewField.modelKeyArr;
        if (this.data[key] != null) {
          for (let item of this.data[key]) {
            if (!item.isValid) {
              if (minInvalidView > subViewField.subStep) {
                minInvalidView = subViewField.subStep;
              }
            }
          }
        }
      });

      return minInvalidView;
    }

    /**
     * Parcours l'objet passé en paramètre et retourne l'objet correpondant à la clé passée en paramètre
     */
    public parcourObject(object: Object, key): any {
      if (object != null) {
        if (object[key] == null) {
          let childKey = key.split(".");
          if (childKey.length === 1) {
            return null;
          }
          let res = "";
          let first = childKey[0];
          for (let i = 1; i < childKey.length; i++) {
            if (i < childKey.length - 1) {
              res = res.concat(childKey[i]) + ".";
            } else {
              res = res.concat(childKey[i]);
            }
          }

          return this.parcourObject(object[first], res);
        } else {
          return object[key];
        }
      } else {
        return null;
      }
    }

    /**
     * Permet de résoudre un ensemble de condition retourne un booléen représentant le resultat de l'évaluation de la condition
     */

    public resolveSetOfConditionsModal(hideExpression, model): boolean {
      let self = this;
      if (hideExpression.operator === "&&") {
        for (let i = 0; i < hideExpression.conditions.length; i++) {
          let cAnd = hideExpression.conditions[i];

          if (cAnd.operator !== null) {
            let needToHideOP = self.resolveSetOfConditionsModal(cAnd, model);
            if (!needToHideOP) {
              return false;
            }
          } else {
            if (!cAnd.rootCondition) {
              let isConditionFullfiled = self.resolveCondition(
                cAnd,
                model[cAnd.key_condition]
              );
              if (!isConditionFullfiled) {
                return false;
              }
            } else if (cAnd.rootCondition) {
              let isConditionFullfiled = self.resolveCondition(
                cAnd,
                self.data[cAnd.key_condition]
              );
              if (!isConditionFullfiled) {
                return false;
              }
            }
          }
        }
        return true;
      } else if (hideExpression.operator === "||") {
        for (let i = 0; i < hideExpression.conditions.length; i++) {
          let cOR = hideExpression.conditions[i];

          if (cOR.operator !== null) {
            let needToHideOP = self.resolveSetOfConditionsModal(cOR, model);
            if (needToHideOP) {
              return true;
            }
          } else {
            if (!cOR.rootCondition) {
              let isConditionFullfiled = self.resolveCondition(
                cOR,
                model[cOR.key_condition]
              );
              if (isConditionFullfiled) {
                return true;
              }
            } else if (cOR.rootCondition) {
              let isConditionFullfiled = self.resolveCondition(
                cOR,
                self.data[cOR.key_condition]
              );
              if (isConditionFullfiled) {
                return true;
              }
            }
          }
        }

        return false;
      } else {
        if (!hideExpression.rootCondition) {
          return self.resolveCondition(
            hideExpression,
            model[hideExpression.key_condition]
          );
        } else if (hideExpression.rootCondition) {
          return self.resolveCondition(
            hideExpression,
            self.data[hideExpression.key_condition]
          );
        }
      }
    }

    /**
     * Réinitialise tous les enfants d'un objet, si objet imbriqué (ex:person.firstName) on reset l'avant dernier objet (ici person)
     * à 0 si il s'agit d'un nombre
     * à un tableau vide si c'est un tableau
     * à null sinon
     */
    public reinitializeChildren(object) {
      let self = this;
      angular.forEach(object.children, function (key) {
        let keySplit;
        if (key.split(".").length === 1) {
          keySplit = key.split(".")[0];
        } else {
          keySplit = key.split(".")[key.split(".").length - 2];
        }
        if (self.data[keySplit] != undefined) {
          if (typeof self.data[keySplit] === "number") {
            self.data[keySplit] = 0;
          } else {
            self.data[keySplit] = null;
          }
        }
      });
    }

    /**
     * Réinitialise tous les enfants d'un objet, si objet imbriqué (ex:person.firstName) on reset l'avant dernier objet (ici person)
     * à 0 si il s'agit d'un nombre
     * à un tableau vide si c'est un tableau
     * à null sinon
     */
    public reinitializeChildrenModal(object, model) {
      angular.forEach(object.children, function (key) {
        let keySplit;
        if (key.split(".").length === 1) {
          keySplit = key.split(".")[0];
        } else {
          keySplit = key.split(".")[key.split(".").length - 2];
        }
        if (model[keySplit] != undefined) {
          if (typeof model[keySplit] === "number") {
            model[keySplit] = 0;
          } else {
            model[keySplit] = null;
          }
        }
      });
    }

    /**
     * Résoud une condition
     * @param condition
     * @param value
     * @returns {boolean}
     */
    public resolveCondition(condition, value) {
      if (value) {
        // value = valeur récupérée du serveur à modifier dans l'appel de la fonction
        switch (condition.comparator) {
          case "==":
            return value + "" === condition.conditionValue;
          case "!=":
            return value + "" !== condition.conditionValue;
          case "<":
            return value + "" < condition.conditionValue;
          case ">":
            return value + "" > condition.conditionValue;
        }
      } else {
        return true;
      }
    }

    public getColor(step) {
      if (step === this.activeStep) {
        return "btn-warning";
      } else {
        return "btn-primary";
      }
    }

    /**
     * Return true si c'est invalide (le champ est vide alors qu'il doit être affiché (voir condition))
     * @param fieldValue
     * @param condition
     * @returns {boolean}
     */
    public checkFieldValidity(fieldValue, condition, model): boolean {
      return (
        (fieldValue === null || fieldValue === 0 || fieldValue.length === 0) &&
        (condition === null ||
          !this.resolveSetOfConditionsModal(condition, model))
      );
    }

    public getFields(table, res: Array<any>) {
      angular.forEach(table, function (x) {
        if (x.hasOwnProperty("fieldGroup")) {
          this.getFields(x.fieldGroup, res);
        } else {
          res.push(x);
        }
      });
    }

    public launchIPPUploadModal(fileInput: any, fieldKey: string, model: any) {
      if (fileInput.files.length > 0) {
        this.mapUploadError = null;
        this.$uibModal.open({
          templateUrl: "tpl/website/modals/uploadModal.html",
          controller: "UploadIPPModalController",
          controllerAs: "uploadModalController",
          //Permet de bloquer le click en dehors du modal
          backdrop: "static",
          size: "sm",
          resolve: {
            r_data: function () {
              return {
                fileInput: fileInput,
                fieldKey: fieldKey,
                model: model,
              };
            },
            ipp_data: undefined,
            pmf_data: undefined,
          },
        });
      }
    }

    /**
     *
     * @param mapUploadError map contenant le fichier en erreur et la cause
     */
    public displayResultUpload(mapUploadError: Map<File, string>) {
      this.mapUploadError = mapUploadError;
      this.launchResultUploadModal();
    }

    /**
     * Affichage de erreurs de l'upload grâce à un modal.
     */
    public launchResultUploadModal() {
      this.modalService.createResultUploadModal(
        "tpl/website/modals/resultUploadModal.html",
        "ResultUploadModalController",
        "resultUploadModalController",
        this.mapUploadError
      );
    }

    /**
     * method used in formly modals, to enable/disable the Validation Btn
     * only if the missing fields from the current form is not an attachement file
     */
    public missingFieldsAreOnlyAttachements(formErrors) {
      let result = true;
      if (formErrors != null) {
        for (let error of formErrors) {
          if (!(error.$name.search("buttonFile") !== -1)) {
            result = false;
            return result;
          }
        }
      }
      return result;
    }

    public updateMissingAttachementList(
      formModel,
      isNew,
      fields,
      labelKeyArr,
      index
    ) {
      for (let field of fields) {
        let fieldvalue = this.parcourObject(formModel, field.key_field);
        let isInValid = this.checkFieldValidity(
          fieldvalue,
          field.condition,
          formModel
        );
        if (
          field.type === "buttonFile" &&
          field.required === true &&
          isInValid
        ) {
          if (!isNew) {
            this.removeMissingAttachement(index, field.key_field);
          }
          if (formModel[field.key_field] == null) {
            this.missingAnnexesList.push(
              this.getLabelObjectMissingAttachement(
                field,
                formModel,
                index,
                labelKeyArr
              )
            );
          }
        } else if (
          field.type === "buttonFile" &&
          field.required === true &&
          !isInValid &&
          !isNew
        ) {
          this.removeMissingAttachement(index, field.key_field);
        }
      }
    }

    public getLabelObjectMissingAttachement(
      field,
      item?,
      index?,
      labelKeyArr?
    ) {
      let labelObject = {};
      labelObject.fieldKey = field.key_field;
      if (index != null) {
        labelObject.index = index;
      }

      let label = this.getTranslation(field.missing);
      if (labelKeyArr) {
        label += " - ";
        for (let labelKey of labelKeyArr) {
          if (labelKey.hasOwnProperty("subKeyArr")) {
            let subKeyArr = labelKey.subKeyArr;
            for (let subLabelKey of subKeyArr) {
              label += item[labelKey.key][subLabelKey] + " ";
            }
          } else {
            label += item[labelKey.key] + " ";
          }
        }
      }
      labelObject.label = label;
      return labelObject;
    }

    public getTranslation(label): string {
      return label[this.m.language];
    }

    public removeMissingAttachement(indexElement, fieldKey) {
      let elementsToRemove = [];
      if (this.missingAnnexesList) {
        for (let i = 0; i < this.missingAnnexesList.length; i++) {
          let missingAnnexe = this.missingAnnexesList[i];
          if (
            missingAnnexe.fieldKey == fieldKey &&
            missingAnnexe.index == indexElement
          ) {
            elementsToRemove.push(i);
          }
        }

        while (elementsToRemove.length > 0) {
          let indexToRemove = elementsToRemove.pop();
          this.missingAnnexesList.splice(indexToRemove, 1);
        }
      }
    }

    public downloadIPP(ippKey, accFirmKey) {
      // ADD ENV VARIABLES
      this.$window.open(
        this.ENV.baseUrl +
          "/IPPFormUploadMgr?sessionID=" +
          this.sessionService.session.sessionID +
          "&senderKey=" +
          this.sessionService.session.member.key +
          "&ippKey=" +
          ippKey +
          "&typeFile=1&levelFile=1",
        "_blank"
      );
    }

    public seeIPP(ippKey, archive?) {
      if (
        (this.isAccounter && this.data.status_declaration === 5) ||
        archive === true
      ) {
        this.readingMode = true;
        this.$state.go("ipp.form.identification", { ippKey: ippKey });
      } else {
        if (this.isAccounter && !this.data.closed) {
          // use this line to devlop
          // if (!this.isAccounter) {
          // propose the choice to edit or consult the declaration, as an accounter
          this.$uibModal.open({
            template: `
						<div class="modal-header ipp-modal-header">
							<h3 class="modal-title">
								{{'IPP_FORM.MODAL.consultAsAccountantTitle' | translate}}
							</h3>
						</div>
						<div class="modal-body">
							<p>{{'IPP_FORM.MODAL.consultAsAccountantText' | translate}}</p>
							<p>{{'IPP_FORM.MODAL.consultAsAccountantQuestion' | translate}}</p>
						</div>
						<div class="modal-footer ipp-modal-footer">
							<button class="btn ipp__btn ipp__btn-back pull-left"
									ng-click="cancel()">
									{{'IPP_FORM.MODAL.cancel' | translate}}
							</button>
							<span class="pull-right">
								<button class="btn ipp__btn ipp__btn-back"
										ng-click="edit()">
										{{'IPP_FORM.MODAL.editDeclaration' | translate}}
								</button>
								<button class="btn ipp__btn ipp__btn-submit"
										ng-click="consult()">
										{{'IPP_FORM.MODAL.consultDeclaration' | translate}}
								</button>
							</span>
						</div>
					`,
            resolve: {
              r_ippKey: [() => ippKey],
            },
            controller: [
              "r_ippKey",
              "$scope",
              "$uibModalInstance",
              "$state",
              "IPPFormService",
              function (
                r_ippKey,
                $scope,
                $uibModalInstance,
                $state,
                IPPFormService
              ) {
                $scope.cancel = function () {
                  $uibModalInstance.dismiss("cancel");
                };

                $scope.consult = function () {
                  $state.go("ipp.form.identification", { ippKey: r_ippKey });
                  IPPFormService.readingMode = true;
                  $uibModalInstance.close("confirm");
                };

                $scope.edit = function () {
                  $state.go("ipp.form.identification", { ippKey: r_ippKey });
                  IPPFormService.readingMode = false;
                  $uibModalInstance.close("confirm");
                };
              },
            ],
            size: "md",
            backdrop: "static",
          });
        } else {
          // normal client ou formulaire en closed
          this.readingMode = this.data.closed;
          this.$state.go("ipp.form.identification", { ippKey: ippKey });
          // CHECK WHICH DATA IS LOADED, AND HOW TO UPDATE THE STATE HERE
        }
      }
    }

    public resetIppStateForAccounter(ippKey) {
      // propose the choice to edit or consult the declaration, as an accounter
      return this.$uibModal.open({
        template: `
						<div class="modal-header ipp-modal-header">
							<h3 class="modal-title">
								{{'IPP_FORM.MODAL.consultAsAccountantTitle' | translate}}
							</h3>
						</div>
						<div class="modal-body">
							<p>{{'IPP_FORM.MODAL.consultAsAccountantText' | translate}}</p>
							<p>{{'IPP_FORM.MODAL.consultAsAccountantQuestion' | translate}}</p>
						</div>
						<div class="modal-footer ipp-modal-footer">
							<span class="pull-right">
								<button class="btn ipp__btn ipp__btn-back"
										ng-click="edit()">
										{{'IPP_FORM.MODAL.editDeclaration' | translate}}
								</button>
								<button class="btn ipp__btn ipp__btn-submit"
										ng-click="consult()">
										{{'IPP_FORM.MODAL.consultDeclaration' | translate}}
								</button>
							</span>
						</div>
					`,
        resolve: {
          r_ippKey: [() => ippKey],
        },
        controller: [
          "r_ippKey",
          "$scope",
          "$uibModalInstance",
          "$state",
          "IPPFormService",
          function (
            r_ippKey,
            $scope,
            $uibModalInstance,
            $state,
            IPPFormService
          ) {
            $scope.consult = function () {
              // $state.go("ipp.form.identification", { ippKey: r_ippKey });
              IPPFormService.readingMode = true;
              $uibModalInstance.close("consult");
            };

            $scope.edit = function () {
              // $state.go("ipp.form.identification", { ippKey: r_ippKey });
              IPPFormService.readingMode = false;
              $uibModalInstance.close("edit");
            };
          },
        ],
        size: "md",
        backdrop: "static",
        keyboard: false,
      }).result;
    }

    private initRequiredFields() {
      this.getRequiredFields(this.model.key).then((data) => {
        this.requiredFields = data.data;
        // sort the fields by their step page
        this.requiredFields.sort((a, b) => a.step - b.step);
      });
    }

    private initDataVar() {
      return this.getData().then((data) => {
        this.data = data.plain()[0];
        this.model = this.data;
        this.missingAnnexesList = [];
        if (this.data.missingAttachments) {
          this.missingAnnexesList = this.data.missingAttachments;
        }
        // FORCED TO DEBUG
        this.validationMode = false;

        this.readingMode = this.model.closed;

        if (this.readingMode) {
          this.validationMode = false;
        } else {
          this.validationMode = this.model.validation_mode;
        }
      });
    }
  }
}

angular
  .module("app")
  .service("IPPFormService", app.functionality.IPPForm.IPPFormService);
