/// <reference path="../../../_app.ts" />

module app.functionality.common.filters {

    export class Filter {

        static periodLabel($translate: ngt.ITranslateService): Function {
            return (input) => {
                let periodLabel = [
                    "PROFIT.BALANCE.PERIOD.january",
                    "PROFIT.BALANCE.PERIOD.february",
                    "PROFIT.BALANCE.PERIOD.march",
                    "PROFIT.BALANCE.PERIOD.april",
                    "PROFIT.BALANCE.PERIOD.may",
                    "PROFIT.BALANCE.PERIOD.june",
                    "PROFIT.BALANCE.PERIOD.july",
                    "PROFIT.BALANCE.PERIOD.august",
                    "PROFIT.BALANCE.PERIOD.september",
                    "PROFIT.BALANCE.PERIOD.october",
                    "PROFIT.BALANCE.PERIOD.november",
                    "PROFIT.BALANCE.PERIOD.december"
                ];
                periodLabel[-1] = "PROFIT.BALANCE.ending";
                let result = $translate.instant(periodLabel[input.monthIndex]) + " (" + input.year + ")";
                return result;
            };
        }

        static exerciceLabel($translate: ngt.ITranslateService): Function {
            return (input) => {
                let year = input.title.substr(input.title.length - 4);
                let result = $translate.instant("HEADER.exercice") + " " + year;
                return result;
            };
        }

        static monthLabel($translate: ngt.ITranslateService): Function {
            return (input) => {
                let periodLabel = [
                    "PROFIT.BALANCE.PERIOD.january",
                    "PROFIT.BALANCE.PERIOD.february",
                    "PROFIT.BALANCE.PERIOD.march",
                    "PROFIT.BALANCE.PERIOD.april",
                    "PROFIT.BALANCE.PERIOD.may",
                    "PROFIT.BALANCE.PERIOD.june",
                    "PROFIT.BALANCE.PERIOD.july",
                    "PROFIT.BALANCE.PERIOD.august",
                    "PROFIT.BALANCE.PERIOD.september",
                    "PROFIT.BALANCE.PERIOD.october",
                    "PROFIT.BALANCE.PERIOD.november",
                    "PROFIT.BALANCE.PERIOD.december",
                    "PROFIT.BALANCE.PERIOD.all"
                ];
                let month = periodLabel[input];
                let result = $translate.instant(month);
                return result;
            };
        }

        static dateLabel($translate: ngt.ITranslateService): Function {
            return (input) => {
                let periodLabel = [
                    "DASHBOARD.january",
                    "DASHBOARD.february",
                    "DASHBOARD.march",
                    "DASHBOARD.april",
                    "DASHBOARD.may",
                    "DASHBOARD.june",
                    "DASHBOARD.july",
                    "DASHBOARD.august",
                    "DASHBOARD.september",
                    "DASHBOARD.october",
                    "DASHBOARD.november",
                    "DASHBOARD.december"
                ];
                let dateGiven = new Date(input);
                let month = periodLabel[dateGiven.getMonth()];
                return month;
            };
        }

        static shortMonthLabel($translate: ngt.ITranslateService): Function {
            return (input) => {
                let periodLabel = [
                    "UPLOAD.ARCHIVES.SHORTMONTH.0",
                    "UPLOAD.ARCHIVES.SHORTMONTH.1",
                    "UPLOAD.ARCHIVES.SHORTMONTH.2",
                    "UPLOAD.ARCHIVES.SHORTMONTH.3",
                    "UPLOAD.ARCHIVES.SHORTMONTH.4",
                    "UPLOAD.ARCHIVES.SHORTMONTH.5",
                    "UPLOAD.ARCHIVES.SHORTMONTH.6",
                    "UPLOAD.ARCHIVES.SHORTMONTH.7",
                    "UPLOAD.ARCHIVES.SHORTMONTH.8",
                    "UPLOAD.ARCHIVES.SHORTMONTH.9",
                    "UPLOAD.ARCHIVES.SHORTMONTH.10",
                    "UPLOAD.ARCHIVES.SHORTMONTH.11"
                ];
                let month = periodLabel[new Date(input).getMonth()];
                let result = $translate.instant(month);
                return result;
            };
        }

        static quarterLabel($translate: ngt.ITranslateService): Function {
            return (input) => {
                let quarterLabel = [
                    "PROFIT.BALANCE.QUARTER.first",
                    "PROFIT.BALANCE.QUARTER.first",
                    "PROFIT.BALANCE.QUARTER.first",
                    "PROFIT.BALANCE.QUARTER.second",
                    "PROFIT.BALANCE.QUARTER.second",
                    "PROFIT.BALANCE.QUARTER.second",
                    "PROFIT.BALANCE.QUARTER.third",
                    "PROFIT.BALANCE.QUARTER.third",
                    "PROFIT.BALANCE.QUARTER.third",
                    "PROFIT.BALANCE.QUARTER.fourth",
                    "PROFIT.BALANCE.QUARTER.fourth",
                    "PROFIT.BALANCE.QUARTER.fourth"
                ];

                let result = $translate.instant(quarterLabel[input.periodArr[0].monthIndex]) + " (" + input.periodArr[0].year + ")";
                return result;
            };
        }

        static vcsStruct(): Function {
            return (input) => {
                if (input.length === 12) {
                    return input.substr(0, 3) + "/" + input.substr(3, 4) + "/" + input.substr(7, 5);
                } else {
                    return "vcs wrong format";
                }
            };
        }

        static dateFrom($translate: ngt.ITranslateService): Function {
            return (input) => {

                // todo, tenir en compte de la translationa
                // NOW, ILYA, MINUTES, HOUR , DAY, MONTH, YEAR

                let dateee = new Date(input);
                input = dateee.getTime();

                let Today = new Date().getTime();

                let _60Sec : number = 1000 * 60;
                let _60Min : number = _60Sec * 60;
                let _day : number = _60Min * 24;
                let _month : number = _day * 31;
                let _year : number = _month * 12;
                let math : number = Today - input;
                let textDate = "";

                if (math <= _60Sec) {
                    return $translate.instant("FILTER.DATEFROM.justNow");
                } else if (math > _60Sec && math <= _60Min) {
                    let minutes : number = Math.round(math / _60Sec);
                    textDate += $translate.instant("FILTER.DATEFROM.beforeDateValue")
                        +" "+ minutes+" "
                        + $translate.instant("FILTER.DATEFROM.afterDateValue");
                    if (minutes === 1) {
                        return textDate + $translate.instant("FILTER.DATEFROM.minute");
                    } else {
                        return textDate + $translate.instant("FILTER.DATEFROM.minutes");
                    }
                } else if (math > _60Min && math <= _day) {
                    let hours : number = Math.round(math / _60Min);
                    textDate +=   $translate.instant("FILTER.DATEFROM.beforeDateValue")
                                +" "+ hours +" "
                                + $translate.instant("FILTER.DATEFROM.afterDateValue");
                    if (hours === 1) {
                        return textDate + $translate.instant("FILTER.DATEFROM.hour");
                    } else {
                        return textDate + $translate.instant("FILTER.DATEFROM.hours");
                    }
                } else if (math > _day && math <= _month) {
                    let days : number = Math.round(math / _day);
                    textDate +=   $translate.instant("FILTER.DATEFROM.beforeDateValue")
                        + " "+days + " "
                        + $translate.instant("FILTER.DATEFROM.afterDateValue");
                    if (days === 1) {
                        return textDate + $translate.instant("FILTER.DATEFROM.day");
                    } else {
                        return textDate + $translate.instant("FILTER.DATEFROM.days");
                    }
                } else if (math > _month && math <= _year) {
                    let month : number = Math.round(math / _month);
                    textDate +=   $translate.instant("FILTER.DATEFROM.beforeDateValue")
                        +" "+ month +" "
                        + $translate.instant("FILTER.DATEFROM.afterDateValue");
                    if (month === 1) {
                        return textDate + $translate.instant("FILTER.DATEFROM.month");
                    } else {
                        return textDate + $translate.instant("FILTER.DATEFROM.months");
                    }
                } else if (math > _year) {
                    let _yearB : number = Math.round(math / _year);
                    textDate +=   $translate.instant("FILTER.DATEFROM.beforeDateValue")
                        + " "+_yearB +" "
                        + $translate.instant("FILTER.DATEFROM.afterDateValue");
                    if (_yearB === 1) {
                        return textDate + $translate.instant("FILTER.DATEFROM.year");
                    } else {
                        return textDate + $translate.instant("FILTER.DATEFROM.years");
                    }
                }
            };
        }

        static ticketDescription($filter: ng.IFilterService): Function {
            return (input) => {

                let desc = "";

                desc += "Ce message concerne une opération de <b>" + $filter("number")(input.amount, 2) + "€</b>";

                if (input.third && input.third.length > 0) {
                    desc += " de  <b>" + input.third + "</b>";
                }

                desc += " effectuée le <b>" + $filter("date")(input.operationDate) + "</b>";

                if (input.comment && input.comment.length > 0) {
                    desc += " avec comme description : \"<b>" + input.comment + "</b>\"";
                }

                desc += ".";

                return desc;
            };
        }

        static comparison($filter: ng.IFilterService): Function {
            return (input, col) => {
                let columnIndex = col.replace("key","");
                let comparisonConcerned = "comparison" + columnIndex;
                let keyConcerned = "key" + columnIndex;

                // get column index in this context
                let amountCompared = input[comparisonConcerned];
                let amount = input[keyConcerned];

                if (!angular.isDefined(amountCompared)) {
                  amountCompared = 0;
                }

                if(!angular.isDefined(amount)) {
                    amount = 0;
                }

                if (amountCompared !== 0) {
                    let variation: number  = ((amount - amountCompared) / amountCompared) * 100;
                    return  $filter("number")(variation, 0);
                }
            };
        }

        // use case for a regex as a filter
        static switchToHttp(): Function {
            return (input) => {

                if (!input) {
                    return;
                }
                input = input.replace(/^https:\/\//i, "http://");
                return input;
            };
        }

        // // use case for a regex as a filter
        static sizeFilter($translate: ngt.ITranslateService): Function {
             return (size) => {
                // I18N translation
                if (isNaN(size)) {
                    size = 0;
                }

                if(size===0) {
                    return "";
                }

                /*if (size < 1024) {
                    return size + " "+$translate.instant("FILTER.SIZE.bytes");
                }*/

                size /= 1024;

                if (size < 1) {
                    return size.toFixed(2) + " "+$translate.instant("FILTER.SIZE.kbytes");
                } else if(size < 1024) {
                    return size.toFixed(0) + " "+$translate.instant("FILTER.SIZE.kbytes");
                }

                size /= 1024;

                if (size < 1024) {
                    return size.toFixed(2) + " "+$translate.instant("FILTER.SIZE.mbytes");
                }

                size /= 1024;

                if (size < 1024) {
                    return size.toFixed(2) +" "+ $translate.instant("FILTER.SIZE.gbytes");
                }

                size /= 1024;

                return size.toFixed(2) + " "+$translate.instant("FILTER.SIZE.tbytes");
            };
        }
    }

    Filter.dateFrom.$inject =  ["$translate"];
    Filter.periodLabel.$inject =  ["$translate"];
    Filter.exerciceLabel.$inject =  ["$translate"];
    Filter.monthLabel.$inject =  ["$translate"];
    Filter.dateLabel.$inject =  ["$translate"];
    Filter.shortMonthLabel.$inject =  ["$translate"];
    Filter.quarterLabel.$inject =  ["$translate"];
    Filter.sizeFilter.$inject = ["$translate"];
    Filter.ticketDescription.$inject =  ["$filter"];
}

angular.module("app").filter("periodlabel", app.functionality.common.filters.Filter.periodLabel);
angular.module("app").filter("exercicelabel", app.functionality.common.filters.Filter.exerciceLabel);
angular.module("app").filter("quarterlabel", app.functionality.common.filters.Filter.quarterLabel);
angular.module("app").filter("vcsStruct", app.functionality.common.filters.Filter.vcsStruct);
angular.module("app").filter("monthLabel", app.functionality.common.filters.Filter.monthLabel);
angular.module("app").filter("dateLabel", app.functionality.common.filters.Filter.dateLabel);
angular.module("app").filter("shortMonthLabel", app.functionality.common.filters.Filter.shortMonthLabel);
angular.module("app").filter("dateFrom", app.functionality.common.filters.Filter.dateFrom);
angular.module("app").filter("ticketDescription", app.functionality.common.filters.Filter.ticketDescription);
angular.module("app").filter("switchToHttp", app.functionality.common.filters.Filter.switchToHttp);
angular.module("app").filter("comparison", app.functionality.common.filters.Filter.comparison);
angular.module("app").filter("sizeFilter", app.functionality.common.filters.Filter.sizeFilter);