/// <reference path="../../../_app.ts" />

module app.functionality.invoiceReminder.controllers {

    import IReminder = app.model.interface.IReminder;
    import InvoiceReminderService = app.functionality.invoiceReminder.services.InvoiceReminderService;
    import Reminder = app.model.invoiceReminder.Reminder;
    import ThirdWithOperations = app.model.invoiceReminder.ThirdWithOperations;

    /**
     * This controller is used with the HTML page thirdHistoryController.html
     * This page contains all the reminder of a given third (identified by its reference passed in the URL)
     */
    export class ThirdReminderHistoryController implements angular.IController {

        /**
         * Contains all the reminders of the specific third
         */
        private reminders: IReminder[];

        /**
         * Contains all the informations on the third (used to get its company name)
         * Here, we could replace this by just the IThird object related to the third
         */
        private thirdWithOperations: ThirdWithOperations;
        private pageTitle: string;

        $onInit() {
        }

        static $inject = [
            "r_dataThirdWithOperations",
            "r_remindersData",
            "r_title",
            "$translatePartialLoader",
            "InvoiceReminderService",
            "SessionService",
            "$window",
            "ENV"
        ];

        constructor(r_dataThirdWithOperations: ThirdWithOperations,
                    r_remindersData: IReminder[],
                    r_title: string,
                    $translatePartialLoader,
                    private invoiceReminderService: InvoiceReminderService,
                    private sessionService: app.functionality.common.session.SessionService,
                    private $window,
                    private ENV: app.config.constants.environment.ConstantEnv) {

            $translatePartialLoader.addPart("customer/invoiceReminder");
            this.thirdWithOperations = r_dataThirdWithOperations;
            this.reminders = r_remindersData;
            this.pageTitle = r_title;
        }

        /**
         * Create a date object to be displayed in the view
         * @param {string} date The date to format
         * @returns {Date} An object that can be displayed in the view
         */
        public getFormatedDate(date: string): Date {
            return new Date(date);
        }

        /**
         * This function is used to create a new window to display the reminder as a pdf in it
         * @param {app.model.invoiceReminder.Reminder} reminder the reminder to display
         */
        public displayPDF(reminder: Reminder) {
            this.$window.open(this.ENV.baseUrl
                + "/reminderHistory/getReminderPDF"
                + "/" + this.sessionService.session.sessionID
                + "/" + reminder.key);
        }
    }
}

//noinspection TypeScriptValidateTypes
angular.module("app").controller("ThirdReminderHistoryController", app.functionality.invoiceReminder.controllers.ThirdReminderHistoryController);