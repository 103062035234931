/// <reference path="../../_app.ts" />

module app.model.parameter {

    export class CategoryDefinition {

        classStructure : any = {
            subCategory: {
                array: app.model.parameter.CategoryDefinition
            }
        };

        public key: string;
        public isRoot: boolean;
        public type: number;
        public label: string;
        public accountArr: Array <number>;
        public thirdArr: Array <string>;
        public subCategory: Array <CategoryDefinition>;

        constructor() {
        }

        static get INCOMES(): number {return 1; }
        static get EXPENSE(): number {return 2; }
        static get TOTAL(): number {return 3; }
        static get ACTIVE(): number {return 4; }
        static get PASSIVE(): number {return 5; }
    }
}