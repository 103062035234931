/// <reference path="../../_app.ts" />

module app.config.values {

    export class MenuDisplay {
        public setSize = false;
    }

    export class TicketNotification {
        public icon = false;
    }

}

angular.module("app").
value("MenuDisplay", new app.config.values.MenuDisplay());

angular.module("app").
value("TicketNotification", new app.config.values.TicketNotification());