/// <reference path="../../../_app.ts" />

module app.modules.lineargraphs.directive {

    import LineChartServiceUtil = app.modules.lineargraphs.util.LineChartServiceUtil;
    import LineChartBuilder = app.modules.lineargraphs.builder.LineChartBuilder;
    
    export class LineChartDirective extends LineChartBaseDirective {
        
        static factory(): ng.IDirectiveFactory {
            const directive = ($window, chartBuilder: LineChartBuilder, lineChartServiceUtil: LineChartServiceUtil,
                               $translate: ngt.ITranslateService, $rootScope) =>
                new LineChartDirective($window, chartBuilder, lineChartServiceUtil, $translate, $rootScope);
            directive.$inject = ["$window", "LineChartBuilder", "LineChartServiceUtil", "$translate", "$rootScope"];
            return directive;
        }
    }
}

angular.module("linearChartModule").directive("linearChart", app.modules.lineargraphs.directive.LineChartDirective.factory());
