/// <reference path="../../_app.ts" />

module app.model.budgetInsight {

    export class BIAccount {

        public key: string;
        public idAccount: string;
        public name: string;
        public accountingFirmKey: string;
        public companyKey: string;
        public creationDate: Date;
        public lastPumpipng: Date;
        public idMember: string;
        public amount: string;
        public iban: string;

        constructor() {
        }

    }

}