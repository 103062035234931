/// <reference path="../../../../_app.ts" />

module app.functionality.invoicing.controllers.modal {
  export class InvgConfSendToAccModalController implements angular.IController {
    /**
     * OVERVIEW
     * This modal aims to provide a simple interface to confirm an action
     * It returns either true or false, and displays custom data passed through resolve
     */

    private title: string; // The title of modal
    private content: string; // The content of the modal, an HTML string
    private cantSend: boolean; // The content of the modal, an HTML string
    private buttons: {
      cancel: {
        text: string;
        class: string;
      };
      confirm: {
        text: string;
        class: string;
      };
    };

    $onInit() {}

    static $inject = [
      "r_title",
      "r_content",
      "r_cantSend",
      "r_buttons",
      "$uibModalInstance",
      "$translatePartialLoader",
      "$scope",
      "$compile",
    ];

    constructor(
      private r_title: string,
      private r_content: string,
      private r_cantSend: boolean,
      private r_buttons: any,
      private $uibModalInstance,
      private $translatePartialLoader: ngt.ITranslatePartialLoaderService,
      private $scope,
      private $compile: ng.ICompileService
    ) {
      $translatePartialLoader.addPart("customer/modals");

      this.title = r_title;
      this.content = r_content;
      this.cantSend = r_cantSend;
      this.buttons = r_buttons;
    }

    public cancel() {
      this.$uibModalInstance.close(false);
    }

    public confirm() {
      this.$uibModalInstance.close(true);
    }
  }
}
angular
  .module("app")
  .controller(
    "InvgConfSendToAccModalController",
    app.functionality.invoicing.controllers.modal
      .InvgConfSendToAccModalController
  );
