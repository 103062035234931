/// <reference path="../../../_app.ts" />

module app.functionality.upload {

    export class KeepNoteModalController {

        public mergeOrSplit: string;

        static $inject = ["$uibModalInstance", "r_data", "$rootScope"];
        constructor( public $uibModalInstance: any, r_data: any, private $rootScope: ng.IRootScopeService ) {
            this.mergeOrSplit = r_data.splitOrMerge;
        }

        public yes () {
            if ( this.mergeOrSplit === "split" ) {
                this.$rootScope.$broadcast("split", true);

            } else {
                this.$rootScope.$broadcast("merge", true);

            }
            this.$uibModalInstance.close();
        }
        public no () {
            if (this.mergeOrSplit === "split") {
                this.$rootScope.$broadcast("split", false);

            } else {
                this.$rootScope.$broadcast("merge", false);

            }
            this.$uibModalInstance.close();
        }

    }

}

angular.module("app").controller("KeepNoteModalController", app.functionality.upload.KeepNoteModalController);
